<template>
	<b-col lg="12" class="p-0">
		<b-row class="m-0">
			<b-col lg="6" sm="12">
				<b-form-group :label="$t('address')">
					<b-form-input placeholder="_" v-model="address.street.$model"
						:state="$_validateState(address.street)" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6" sm="12">
				<b-form-group :label="$t('postal-code')">
					<b-form-input placeholder="_" v-model="address.postalCode.$model"
						:state="$_validateState(address.postalCode)" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6" sm="12">
				<MultipleSelect v-model="address.country.$model" :searchable="true" track="name"
					:options="countries" :validation="address.country" :label="$t('country')" />
			</b-col>
			<b-col lg="6" sm="12">
				<b-form-group :label="$t('city')">
					<b-form-input placeholder="_" v-model="address.city.$model"
						:state="$_validateState(address.city)" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6" sm="12">
				<b-form-checkbox class="mt-4" v-model="sameAddress" v-if="locationAddressIsFilled">
					{{ $t('resume-the-general-address') }}
				</b-form-checkbox>
			</b-col>
		</b-row>
	</b-col>
</template>
<script>
import countriesMixins from '@/mixins/countries.js';
export default {
	mixins : [
		countriesMixins
	],
	props : {
		location : {
			type     : Object,
			required : true
		},
		address : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			sameAddress : false,
		};
	},
	computed : {
		locationAddress() {
			return this.location.address.$model;
		},
		locationAddressIsFilled() {
			const address = this.location.address.$model;
			return address.city || address.street || address.code;
		},
	},
	watch : {
		sameAddress(val) {
			if(val) {
				this.address.$model = this.locationAddress;
			} else {
				this.address.$model = {
					postalCode    : null,
					country    : null,
					city       : null,
					street     : null
				};
			}
			this.address.$reset();
		}
	}
};
</script>