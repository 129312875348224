<template>
	<div id="create-partner">
		<div class="d-flex justify-content-between mb-4">
			<h1>
				{{ $t('search-partner') }}
			</h1>
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-identification class="primary d-flex justify-content-between">
				{{ $t('information') }}
				<Icon icon="chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-identification" visible accordion="accordion">
				<b-card-body>
					<b-row>
						<b-col lg="6">
							<b-form-group :label="$t('company-type')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('siren')">
								<b-form-input type="number" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('intra-community-vat-number')">
								<b-form-input type="number" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('street')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('city')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('department-or-region')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>
						</b-col>
						<b-col lg="6">
							<b-form-group :label="$t('company-name')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('nic')">
								<b-form-input type="number" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('code-company')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('complement-address')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('postal-code')">
								<b-form-input type="number" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('country')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>
						</b-col>
					</b-row>
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-contact class="primary d-flex justify-content-between">
				{{ $t('add-contact') }}
				<Icon icon="chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-contact" accordion="accordion">
				<b-card-body>
					<b-row>
						<b-col lg="6">
							<b-form-group :label="$t('lastname')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('email')">
								<b-form-input type="email" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('job')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>
						</b-col>
						<b-col lg="6">
							<b-form-group :label="$t('firstname')">
								<b-form-input type="text" placeholder="_" />
							</b-form-group>

							<b-form-group :label="$t('phone')">
								<b-form-input type="number" placeholder="_" />
							</b-form-group>
						</b-col>
					</b-row>
					<b-col lg="12" class="d-flex justify-content-end ml-2">
						<b-button class="btn-icon-only" variant="primary">
							<Icon icon="add" />
						</b-button>
					</b-col>
				</b-card-body>
			</b-collapse>
		</div>
		<div class="d-flex justify-content-center mt-3">
			<b-button class="btn-icon" variant="primary"
				@click="$router.push({name: 'PartnerList'})">
				<Icon icon="save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</div>
</template>

<script>
export default {
	name : 'CreatePartner',
};
</script>