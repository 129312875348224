<template>
	<div class="destination">
		<div class="container-info" :class="{'selected': isSelected}"
			@click="$emit('set-selected', 'destination')">
			{{ destination.name }}
		</div>
	</div>
</template>
<script>
export default {
	props : {
		isSelected : {
			required : true,
			type     : Boolean
		},
		destination : {
			required : true,
			type     : Object
		}
	}
};
</script>