<template>
	<b-card no-body>
		<b-card-header class="d-flex justify-content-between align-items-center">
			<div>
				{{ $t('transport-plan') }}
			</div>
			<div class="d-flex text-initial" v-if="$route.name != 'VisualizationContracts'">
				<div class="d-flex align-items-center" v-if="isOwner">
					<legend class="mb-0 mr-2">
						{{ $t('charger') }}
					</legend>
					<b-form-checkbox disabled v-model="transportPlan.validatedCharger" class="success" switch
						size="lg" :id="'checkboxCharger'+index" />
				</div>
				<div class="d-flex ml-2 align-items-center" v-if="$route.name == 'EditContracts'">
					<legend class="mb-0 mr-2">
						{{ $t('provider') }}
					</legend>
					<b-form-checkbox disabled v-model="transportPlan.validatedOperator" class="success"
						switch size="lg" :id="'checkboxProvider'+index" />
				</div>
			</div>
		</b-card-header>
		<b-card-body>
			<ul>
				<li v-for="(route, index) in getUserRoutes" :key="index">
					<div class="d-flex align-items-center">
						<Icon v-for="(type, a) in getAllSegmentsTypeIcon(route)" :key="a"
							:icon="type" />
						{{ route.name }}
					</div>
					<p>
						<span v-for="(segment, i) in route.services.filter(s=>s.type=='segment')" :key="i">

							{{ segment.segment.origin.name }} >
							<span v-if="i == (route.services.filter(s=>s.type=='segment').length - 1)">
								{{ segment.segment.destination.name }}
							</span>
						</span>
					</p>
					<p>
						{{ route.handlingUnitPrice }} €/{{ $t('handle-unit') }}
					</p>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	props : {
		getUserRoutes : {
			required : true,
			type     : Array
		},
		getUserLocations : {
			type     : Array,
			required : true
		},
		getUserSegments : {
			type     : Array,
			required : true
		},
		isOwner : {
			required : true,
			type     : Boolean
		}
	},
	data() {
		return {
			transportPlan : {
				validatedOperator : false,
				validatedCharger   : false,
			}
		};
	},
	computed : {
		getSegmentsAndLocations() {
			const segmentsAndLocations = [];
			for (let a = 0; a < this.getUserRoutes.length; a++) {
				const route = this.getUserRoutes[a];
				const segmentServices = route.services.filter(s=>s.type == 'segment');
				for (let i = 0; i < segmentServices.length; i++) {
					const segment = segmentServices[i].segment;
					const originLocation = this.getUserLocations.find((x) => x.id == segment.origin.id);
					if(originLocation && !segmentsAndLocations.find((x) => x.id == originLocation.id)) {
						segmentsAndLocations.push(originLocation);
					}
					const destinationLocation = this.getUserLocations.find((x) => x.id == segment.destination.id);
					if(destinationLocation  && !segmentsAndLocations.find((x) => x.id == destinationLocation.id)) {
						segmentsAndLocations.push(destinationLocation);
					}
					const segmentFind = this.getUserSegments.find((x) => x.routeId == route.routeId);
					if(segmentFind) {
						segmentsAndLocations.push(segmentFind);
					}
				}
			}
			return segmentsAndLocations;
		}
	},
	created() {
		if(this.getUserRoutes.length) {
			if(this.getUserRoutes.every((x) => x.validatedCharger == true)) {
				this.transportPlan.validatedCharger = true;
			}
			if(this.getUserRoutes.every((x) => x.validatedOperator == true)) {
				this.transportPlan.validatedOperator = true;
			}
		}
	},
	watch : {
		getSegmentsAndLocations : {
			handler(val) {
				if(val.every((x) => x.validatedOperator == true)) {
					this.transportPlan.validatedOperator = true;
				} else {
					this.transportPlan.validatedOperator = false;
				}
				if(val.every((x) => x.validatedCharger == true)) {
					this.transportPlan.validatedCharger = true;
				} else {
					this.transportPlan.validatedCharger = false;
				}
			},
			deep : true
		},
		transportPlan : {
			handler(val) {
				if(this.isOwner) {
					if(val.validatedCharger) {
						for (let i = 0; i < this.getUserRoutes.length; i++) {
							const route = this.getUserRoutes[i];
							route.validatedCharger = true;
						}
					} else {
						for (let i = 0; i < this.getUserRoutes.length; i++) {
							const route = this.getUserRoutes[i];
							route.validatedCharger = false;
						}
					}
				} else {
					if(val.validatedOperator) {
						for (let i = 0; i < this.getUserRoutes.length; i++) {
							const route = this.getUserRoutes[i];
							route.validatedOperator = true;
						}
					} else {
						for (let i = 0; i < this.getUserRoutes.length; i++) {
							const route = this.getUserRoutes[i];
							route.validatedOperator = false;
						}
					}
				}
			},
			deep : true
		},
		routes : {
			handler(val) {
				if(this.isOwner) {
					for (let i = 0; i < val.length; i++) {
						const route = val[i];
						if(!route.validatedCharger) {
							this.transportPlan.validatedCharger = false;
							return;
						}
					}
					this.transportPlan.validatedCharger = true;
				} else {
					for (let i = 0; i < val.length; i++) {
						const route = val[i];
						if(!route.validatedOperator) {
							this.transportPlan.validatedOperator = false;
							return;
						}
					}
					this.transportPlan.validatedOperator = true;
				}
			},
			deep : true
		}
	},
	methods : {
		getAllSegmentsTypeIcon(route) {
			const segments = route.services.filter(s=>s.type == 'segment').map(s=>s.segment);
			const opt = [
				{
					text : 'ROAD',
					icon : 'truck'
				},
				{
					text : 'RAIL',
					icon : 'train'
				},
				{
					text : 'AIR',
					icon : 'airplane'
				},
				{
					text : 'SEA',
					icon : 'boat'
				},
				{
					text : 'RIVER',
					icon : 'river'
				},
			];
			const types = [];
			for (let i = 0; i < segments.length; i++) {
				const icon = opt.find((x) => x.text == segments[i].typology).icon;
				const find = types.findIndex((x) => x == icon);
				if(find == -1) {
					types.push(icon);
				}
			}
			return types;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
ul {
	display: flex;
	flex-direction: column;
}
li {
	display: flex;
	padding: 0.5rem 0;
	align-items: center;
	justify-content: space-between;
	border-bottom: 2px solid $light-grey;
	font-weight: 600;
	text-transform: initial;
}
li > div {
	min-width: 150px;
}
</style>