<template>
	<div id="identification">
		<b-card-header class="primary">
			{{ $t('identification') }}
		</b-card-header>
		<b-card-body>
			<Actor v-for="(actor, index) in $v.actors.$each.$iter" :key="index" :actor="actor"
				:index="index" @remove-actor="removeActor" :actors="actors" />
			<b-button variant="primary" class="btn-icon mt-3" id="add-actor" @click="addActor">
				<Icon icon="add-partner" />
				{{ $t('add-an-actor') }}
			</b-button>
		</b-card-body>
		<div class="mt-3 d-flex justify-content-end">
			<b-button variant="primary" @click="nextStep" :disabled="!actors.length" id="next-identification">
				{{ $t('next') }}
			</b-button>
		</div>
	</div>
</template>
<script>
import  Actor  from './identification/Actor.vue';
import { validateTextRequired, validatePostalCode, validateRequired, validateVAT, validateSiren, validateNic,
	validateEmail, validatePhone } from '@/utils/plugins/validations/vuelidate.js';
export default {
	components : {
		Actor
	},
	props : {
		actors : {
			type     : Array,
			required : true,
		}
	},
	validations() {
		return {
			actors : {
				$each : {
					type    : validateRequired,
					name    : validateTextRequired,
					address : {
						street     : validateTextRequired,
						postalCode    : validatePostalCode,
						city       : validateTextRequired,
						country    : validateRequired,
					},
					tva   : validateVAT,
					siret : {
						siren : { ...validateSiren,
							...{
								sirenMustBeNew : (value) => {
									const filter = this.actors.filter(x => x.siret.siren == value);
									if(filter.length > 1) return false;
									return true;
								}
							} },
						nic : validateNic,
					},
					validateForm : {},
					companyId    : {},
					contacts     : {
						$each : {
							lastname  : validateTextRequired,
							firstname : validateTextRequired,
							email     : validateEmail,
							phone     : validatePhone,
							address   : {
								street     : validateTextRequired,
								postalCode : validatePostalCode,
								city       : validateTextRequired,
								country    : validateRequired
							},
							validateForm : {}
						}
					},
				}
			}
		};
	},
	async created() {
		if(this.$route.name == 'CreateContract' && this.actors.length == 0) {
			const id = this.user._id;
			let user = null;
			await this.$store.dispatch('user/getInformation', id).then((res) => {
				user = res.data;
			});
			if(user && user.company && user.company._id) {
				let company = null;
				await this.$store.dispatch('company/getCompanyById', user.company._id).then((res) => {
					company = res.data;
				});
				if(company) {
					const actor = {
						companyId         : company._id,
						validateForm      : false,
						contacts          : [],
						address           : company.address,
						type              : company.type,
						name              : company.name,
						siret             : company.siret,
						tva               : company.tva,
						validatedOperator : true,
						validatedCharger   : true,
						calendarValidate  : false,
						owner             : true,
					};
					let phone;
					if(Array.isArray(user.phone)) phone = user.phone[0];
					const contact = {
						firstname    : user.firstname,
						lastname     : user.lastname,
						email        : user.email,
						phone        : phone,
						address      : user.address,
						validateForm : false
					};
					actor.contacts.push(contact);
					this.actors.push(actor);
					if(!this.$v.actors.$each.$iter[0].$invalid) {
						this.actors[0].validateForm = true;
					}
					if(!this.$v.actors.$each.$iter[0].contacts.$each.$iter[0].$invalid) {
						this.actors[0].contacts[0].validateForm = true;
					}
				}
			}
		}
	},
	methods : {
		addActor() {
			this.actors.push({
				companyId : Date.now().toString(),
				type      : null,
				name      : null,
				address   : {
					street     : null,
					postalCode : null,
					city       : null,
					country    : null
				},
				tva   : null,
				siret : {
					siren : null,
					nic   : null,
				},
				validateForm      : false,
				validatedCharger   : false,
				validatedOperator : false,
				contacts          : [],
				owner             : false,
				calendarValidate  : false,
			});
		},
		removeActor(index) {
			this.actors.splice(index, 1);
		},
		nextStep() {
			const actors = this.$v.actors;
			actors.$touch();
			if(!actors.$invalid) {
				for (let i = 0; i < actors.$model.length; i++) {
					const actor = actors.$model[i];
					actor.validateForm = true;
					for (let a = 0; a < actor.contacts.length; a++) {
						const contact = actor.contacts[a];
						contact.validateForm = true;
					}
				}
				this.$emit('go-to-step', 'transport-plan');
			}
		}
	},
	computed : {
		getActorsList() {
			return [
				'Chargeur',
				'Prestataire'
			];
		}
	}
};
</script>
<style lang="scss" scoped>
#add-actor {
	white-space: nowrap;
}
</style>
<i18n>
{
	"fr": {
		"add-an-actor": "Ajouter un acteur"
	},
	"en": {
		"add-an-actor": "Add an actor"
	}
}
</i18n>