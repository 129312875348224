export default {
	computed : {
		trucksType() {
			return [
				{
					value            : 1,
					text             : this._i18n.t('truckType1'),

				},

				{
					value              : 2,
					text             : this._i18n.t('truckType2'),

				},

				{
					value              : 3,
					text             : this._i18n.t('truckType3'),

				},
				{
					value              : 4,
					text             : this._i18n.t('truckType4'),

				},
				{
					value              : 5,
					text             : this._i18n.t('truckType5'),

				},
				{
					value              : 6,
					text             : this._i18n.t('truckType6'),

				},

				{
					value              : 7,
					text             : this._i18n.t('truckType7'),

				},
				{
					value              : 8,
					text             : this._i18n.t('truckType8'),

				},
				{
					value              : 9,
					text             : this._i18n.t('truckType9'),

				},
				{
					value              : 10,
					text             : this._i18n.t('truckType10'),

				},
				{
					value              : 11,
					text             : this._i18n.t('truckType11'),

				},
				{
					value              : 12,
					text             : this._i18n.t('truckType12'),

				},
				{
					value              : 13,
					text             : this._i18n.t('truckType13'),

				},
				{
					value              : 14,
					text             : this._i18n.t('truckType14'),

				},
				{
					value              : 15,
					text             : this._i18n.t('truckType15'),
				},
				{
					value              : 16,
					text             : this._i18n.t('truckType16'),
				},
				{
					value              : 17,
					text             : this._i18n.t('truckType17'),
				},
				{
					value              : 18,
					text             : this._i18n.t('truckType18'),
				},
				{
					value              : 19,
					text             : this._i18n.t('truckType19'),
				},
				{
					value              : 20,
					text             : this._i18n.t('truckType20'),
				},
				{
					value              : 21,
					text             : this._i18n.t('truckType21'),
				},
				{
					value              : 22,
					text             : this._i18n.t('truckType22'),
				},
				{
					value              : 23,
					text             : this._i18n.t('truckType23'),

				},
				{
					value              : 24,
					text             : this._i18n.t('truckType24'),

				},
				{
					value              : 25,
					text             : this._i18n.t('truckType25'),

				},
				{
					value              : 26,
					text             : this._i18n.t('truckType26'),

				},
				{
					value              : 27,
					text             : this._i18n.t('truckType27'),
				},

			];
		}
	}
};
