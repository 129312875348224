<template>
	<main id="contracts">
		<router-view />
	</main>
</template>

<script>
export default {
	name : 'Contracts',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('contracts'),
		};
	},
	mounted() {
		this.$root.$on('bv::collapse::state', this.activeBreadcrumb);
		document.getElementById('main-content').style.scrollBehavior = 'smooth';
	},
	beforeDestroy() {
		this.$root.$off('bv::collapse::state', this.activeBreadcrumb);
	},
	methods : {
		activeBreadcrumb(collapseId, isJustShown) {
			var breadcrumb = document.getElementById('breadcrumb-' + collapseId);
			if(!breadcrumb) return;
			if(isJustShown) {
				if(collapseId != 'identification') {
					document.getElementById('main-content').scrollTop = 0;
				}
				breadcrumb.classList.add('active');
			} else {
				breadcrumb.classList.remove('active');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ .card-body > div {
	&:not(:first-of-type) {
		margin-top: 1.25rem;
	}
}
/deep/ .title-components {
	font-weight: 600;
	color: var(--primary);
	text-transform: uppercase;
	margin-bottom: 1rem;
}
/deep/ #terminals ul,
/deep/ #identification #segments ul,
/deep/ #calendar ul,
/deep/ #identification #transport-plan ul {
	display: flex;
	flex-direction: column;
}
/deep/ #identification ul {
	display: flex;
}
/deep/ #identification #terminals ul li,
/deep/ #identification #segments ul li,
/deep/ #identification #calendar ul li,
/deep/ #identification #transport-plan ul li,
/deep/ #identification #wagons ul li {
	display: flex;
	width: 100%;
	margin-top: 4px;
	padding: 5px;
	align-items: center;
	&:first-of-type {
		margin-top: 0;
	}
}
/deep/ .identification-components {
	display: flex;
	flex-direction: column;
	height: 100%;
	&:not(:first-of-type) {
		margin-top: 1rem;
	}
}
/deep/ .identification-components > .card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
