<template>
	<div class="progress-bar" :class="{'loading': loading}">
		<div class="progress-percentage" :style="
			'width:' + getPercentage + '%;' + 'background-color: ' + color + ';'
		" />
	</div>
</template>
<script>
export default {
	props : {
		percentage : {
			type     : String,
			required : true
		},
		color : {
			type     : String,
			required : true
		},
		loading : {
			type     : Boolean,
			required : false,
			default() {
				return true;
			}
		}
	},
	data() {
		return {
			isMounted : false
		};
	},
	computed : {
		getPercentage() {
			if(this.isMounted) {
				return this.percentage;
			} else {
				return 0;
			}
		}
	},
	watch : {
		loading(val) {
			if(!val) {
				this.isMounted = true;
			}
		}
	},
	mounted() {
		if(!this.loading) {
			setTimeout(() => {
				this.isMounted = true;
			}, 0);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.progress-bar {
	width: 100%;
	background-color: $light-grey;
	height: 15px;
	position: relative;
	border-radius: $border-radius;
}
.loading.progress-bar {
	cursor: wait;
	&:after {
		content: '';
		background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
		animation: skeleton-animate-wave 1.75s linear infinite;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@keyframes skeleton-animate-wave {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(100%);
	}
}
.loading .progress-percentage {
	width: 0 !important;
}
.progress-percentage {
	width: 0;
	height: 100%;
	border-radius: $border-radius;
	transition: 1s;
}
</style>