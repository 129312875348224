import { HTTP } from '../../http-common';

const state = () => ({
	register : null,
});

const getters = {
	register : state => state.register,
};

const mutations = {
	register(state, payload) {
		state.register = payload;
	}
};
const actions = {
	LOAD() {
		return HTTP.get('/registers').then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async CREATE(context, payload) {
		await HTTP.post('/registers', payload).then(() => {
			context.commit('register', null);
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async VALIDATE(context, id) {
		HTTP.get('/registers/' + id + '/validate').then(() => {
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async UPDATE(context, register) {
		const { _id, ...registerWithoutId } = register;
		await HTTP.put('/registers/' + _id, registerWithoutId)
			.then().catch(err => {
				throw err.response.data;
			});
	},
	async DELETE(context, id) {
		await HTTP.delete('registers/' + id)
			.then().catch(err => {
				throw err.response.data;
			});
	},
	getRegister(context, id) {
		return HTTP.get('/registers/' + id).then((res) => {
			return res.data;
		}).catch((err) => {
			throw err.response.data;
		});
	}
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};