<template>
	<div class="bar-on-bar">
		<div class="bar" :style="getStyleUnitsComplete(road)">
			<span>{{ road.unitsComplete }}</span>
		</div>
		<div class="bar" />
	</div>
</template>
<script>
export default {
	props : {
		road : {
			type     : Object,
			required : true
		}

	},
	methods : {
		getStyleUnitsComplete(road) {
			const width = (road.unitsComplete / road.unitsPlanned).toFixed(2) * 100;
			return 'width:' + width + '%';
		}
	}
};
</script>
<i18n>
{
    "fr": {
        "units-planned": "unités planifiées"
    },
    "en" : {
        "units-planned": "planned units"
    }
}
</i18n>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.bar {
	height: 25px;
	width: 100%;
	display: flex;
	align-items: center;
	position: relative;
	color: var(--primary);
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
	background-color: $grey;
}
.bar:first-of-type {
	background-color: var(--primary);
}
.bar span {
	top: 0;
	right: -30px;
	position: absolute;
}
p {
	color: var(--secondary);
}
</style>