<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('code-order')">
			<b-form-input placeholder="_" v-model="code" name="codeOrder" />
		</b-form-group>
	</b-col>
</template>
<script>
export default {

	data() {
		return {
			code : null,
		};
	},
	created() {
		const query = this.$route.query['code'];
		if(query) {
			this.code = query;
		}
	},
	methods : {
		reset() {
			this.code = null;
		},
	},
	watch : {
		code(code) {
			this.$parent.setFilter(code, 'code');
		}
	}
};
</script>