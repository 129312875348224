<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('departure-date-until')">
			<DatePicker v-model="departureDateUntil" />
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	data() {
		return {
			departureDateUntil : null,
		};
	},
	created() {
		const query = this.$route.query['departureDateUntil'];
		if(query) {
			this.departureDateUntil = query;
		}
	},
	watch : {
		departureDateUntil(departureDateUntil) {
			this.$parent.setFilter(departureDateUntil, 'departureDateUntil');
		}
	},
	methods : {
		reset() {
			this.departureDateUntil = null;
		}
	}
};
</script>