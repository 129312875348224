<template>
	<div class="typology">
		<div class="container-info" :class="{'selected': isSelected}"
			@click="$emit('set-selected', 'typology')">
			{{ $t(typology) }}
		</div>
	</div>
</template>
<script>
export default {
	props : {
		isSelected : {
			required : true,
			type     : Boolean
		},
		typology : {
			required : true,
			type     : String
		}
	}
};
</script>