<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('departure-date-since')">
			<DatePicker v-model="departureDateSince" />
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	data() {
		return {
			departureDateSince : null,
		};
	},
	created() {
		const query = this.$route.query['departureDateSince'];
		if(query) {
			this.departureDateSince = query;
		}
	},
	watch : {
		departureDateSince(departureDateSince) {
			this.$parent.setFilter(departureDateSince, 'departureDateSince');
		}
	},
	methods : {
		reset() {
			this.departureDateSince = null;
		}
	}
};
</script>