<template>
	<div id="wagons">
		<Table :items="wagons" :fields="fields" :loading="loading" @load="load" :title="$t('wagons')"
			:pagination="pagination">
			<template v-slot:buttons>
				<b-button variant="primary" @click="$router.push({name: 'CreateWagon'})">
					{{ $t('add-wagon') }}
				</b-button>
				<b-button variant="primary">
					{{ $t('add-wagons') }}
				</b-button>
			</template>
			<template v-slot:filters>
				<Search />
				<Status />
				<Group />
			</template>
			<template v-slot:action="wagon">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="$router.push({name: 'ViewWagon', params: {id: wagon.item._id}})">
								<Icon icon="eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon" id="editbutton"
								@click="$router.push({name: 'UpdateWagon', params: {id: wagon.item._id}})">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<b-button variant="primary" class="btn-round-icon" @click="update(wagon.item)">
									<Icon icon="on" v-if="wagon.item.active" />
									<Icon icon="off" v-else />
								</b-button>
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<Icon icon="comments" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</Table>
	</div>
</template>

<script>
import { Search, Status } from '@/components/filters/components';
import Group from '@/components/filters/components/wagons/Group.vue';
export default {
	name       : 'Wagons',
	components : {
		Search,
		Status,
		Group
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('wagons'),
		};
	},
	methods : {
		load(query) {
			this.$store.dispatch('wagons/loadWagons', query).then((res) => {
				if (res.pagination && res.pagination.length > 0) {
					this.wagons = res.results;
					this.pagination.totalRows = res.pagination[0].count;
				}
				this.loading = false;
			}).catch((err) => {
				this.$_makeToast('danger', err, this._i18n.t('error'));
				this.loading = false;
			});
			this.pagination.totalRows = this.wagons.length;
		},
		update(item) {
			this.$bvModal.msgBoxConfirm(this._i18n.t('change-wagon-state'), {
				title       : this._i18n.t('confirmation'),
				size        : 'sm',
				buttonSize  : 'sm',
				okVariant   : 'success',
				headerClass : 'p-2 border-bottom-0',
				footerClass : 'p-2 border-top-0',
				centered    : true,
				okTitle     : this._i18n.t('yes'),
				cancelTitle : this._i18n.t('no'),
			})
				.then(value => {
					if (value) {
						item.active = !item.active;
						const { _id, ...payload } = item;
						this.$store.dispatch('wagons/updateWagons', {
							payload,
							_id
						}).then(()=> {
							this.load();
						});
					}
				});
		},
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('code'),
					key      : 'code',
					sortable : true,
				},
				{
					label    : this._i18n.t('trafic-type'),
					key      : 'type',
					sortable : true,
				},
				{
					label    : this._i18n.t('family'),
					key      : 'family',
					sortable : true,
				},
				{
					label    : this._i18n.t('evp'),
					key      : 'TEU',
					sortable : true,
				},
				{
					label    : this._i18n.t('mileage'),
					key      : 'kmUsage',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'status',
					sortable : true,
				},
				{
					label    : this._i18n.t('group'),
					key      : 'group',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},
	data() {
		return {
			loading    : true,
			pagination : {
				perPage     : 20,
				totalRows  	: null,
				currentPage : 1,
			},
			wagons : []
		};
	},
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>