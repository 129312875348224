<template>
	<b-row>
		<b-col lg="8" md="12">
			<b-row>
				<b-col lg="6" sm="12">
					<b-form-group :label="$t('gps-coordinates')">
						<b-row>
							<b-col lg="6" class="py-0">
								<b-form-input placeholder="_" v-model="modal.geoLocation.coordinates.
										longitude.$model" name="gpsx" type="number"
									:state="$_validateState(modal.geoLocation.coordinates.longitude)" />
								<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
							</b-col>
							<b-col lg="6" class="py-0">
								<b-form-input placeholder="_" v-model="modal.geoLocation.coordinates.
										latitude.$model" name="gpsy"
									:state="$_validateState(modal.geoLocation.coordinates.latitude)" />
								<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
							</b-col>
						</b-row>
					</b-form-group>
				</b-col>
				<b-col lg="6" sm="12">
					<b-form-group :label="$t('number-of-docks')">
						<b-form-input placeholder="_" type="number" v-model="modal.platforms.$model"
							:state="$_validateState(modal.platforms)" name="docksnumber" />
						<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
					</b-form-group>
				</b-col>
				<b-col lg="6" sm="12">
					<b-form-group :label="$t('platform-length')">
						<b-form-input placeholder="_" type="number" v-model="modal.length.$model"
							:state="$_validateState(modal.length)" name="length" />
						<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
					</b-form-group>
				</b-col>
				<b-col lg="6" sm="12">
					<b-form-group :label="$t('treatment-capacity')">
						<b-form-input placeholder="_" type="number" v-model="modal.capacity.$model"
							:state="$_validateState(modal.capacity)" name="roadtonnage" />
						<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
					</b-form-group>
				</b-col>

				<Address :location="location" :address="modal.address" />
			</b-row>
		</b-col>
		<b-col lg="4" md="12">
			<Schedules :schedules="modal.openingHours.$model" :title="$t('opening-hours-days')"
				:multiple="true" @set-schedules="setSchedules" :id="index.toString()" />
			<b-form-checkbox value="true" class="mt-3" v-model="sameOpeningHours"
				v-if="mainOpeningHoursIsContainsHours">
				{{ $t('copy-the-general-schedules') }}
			</b-form-checkbox>
		</b-col>
	</b-row>
</template>
<script>
import modalMixins from './modalMixins.js';
export default {
	mixins : [modalMixins]
};
</script>