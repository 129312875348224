<template>
	<b-row>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('number-of-km')">
				<b-form-input placeholder="_" type="number" v-model="segment.technicalConstraint.numberKm" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('number-evp-max')">
				<b-form-input placeholder="_" type="number" v-model="segment.operationalConstraint.maxEvp" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('maximum-speed')">
				<b-form-input placeholder="_" type="number" v-model="segment.technicalConstraint.maxSpeed" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('max-length')">
				<b-form-input placeholder="_" type="number" v-model="segment.operationalConstraint.maxLength" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('max-tonnage')">
				<b-form-input placeholder="_" type="number" v-model="segment.operationalConstraint.maxWeight" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('energy-source')">
				<b-form-select :options="getSourcesOptions" v-model="segment.technicalConstraint.energySource">
					<template v-slot:first>
						<b-form-select-option :value="null" disabled>
							_
						</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<MultipleSelect :options="[]" :label="$t('calculation-engine')"
				v-model="segment.technicalConstraint.calculationEngine" />
		</b-col>
		<b-col lg="6" sm="12">
			<MultipleSelect :options="[]" :label="$t('composition-code')"
				v-model="segment.technicalConstraint.compositionCode" />
		</b-col>
		<b-col lg="6" sm="12">
			<MultipleSelect :options="[]" :label="$t('indicator')"
				v-model="segment.technicalConstraint.indicator" />
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('number-of-engines')">
				<b-form-input placeholder="_" type="number" v-model="segment.technicalConstraint.engineNumber" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<MultipleSelect :options="[]" :label="$t('CS/TCT/UI')" v-model="segment.technicalConstraint.TCT" />
		</b-col>
		<!--b-col lg="6" sm="12">
			<b-form-group :label="$t('train-path-application-number')">
				<b-form-input placeholder="_" type="number" v-model="segment.technicalConstraint.energySource" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('train-path-number')">
				<b-form-input placeholder="_" type="number" />
			</b-form-group>
		</b-col-->
	</b-row>
</template>
<script>
export default {
	props : {
		segment : {
			required : true,
			type     : Object
		},
	},
	computed : {
		getSourcesOptions() {
			return  [
				{
					text  :  this._i18n.t('electric_fr'),
					value : 'electric_fr',
				},
				{
					text  :  this._i18n.t('electric_eu'),
					value : 'electric_eu',
				},
				{
					text  :  this._i18n.t('gazole'),
					value : 'gazol',
				},
				{
					text  :  this._i18n.t('mixed'),
					value : 'mixed',
				},
			];
		},
	}

};
</script>