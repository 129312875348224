<template>
	<div id="renting">
		<b-row>
			<b-col lg="3">
				<b-form-group :label="$t('company')">
					<b-form-input placeholder="_" type="text" v-model="renting.company.$model"
						:state="$_validateState(renting.company)" name="rentingCompany" />
					<b-form-invalid-feedback>{{ $t('company-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('daily-price')">
					<b-form-input placeholder="_" type="number" v-model="renting.cost.$model"
						:state="$_validateState(renting.cost)" name="cost" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('start-date-contract')">
					<DatePicker v-model="renting.startContract.$model"
						:state="$_validateState(renting.startContract)" name="startContract" id="startContract" />
					<b-form-invalid-feedback>{{ $t('date-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('end-date-contract')">
					<DatePicker v-model="renting.endContract.$model"
						:state="$_validateState(renting.endContract)" name="endContract" id="endContract" />
					<b-form-invalid-feedback>{{ $t('date-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="12" class="d-flex justify-content-end">
				<b-button class="btn-icon-only" variant="primary" @click="addRenting()" id="addRenting">
					<Icon icon="add" />
				</b-button>
			</b-col>
		</b-row>
		<b-table head-variant="light" :striped="true" hover responsive
			v-if="items.length" :fields="fields" :items="items" class="mt-4"
			:current-page="pagination.currentPage" :per-page="pagination.perPage"
			id="rentingTable">
			<template #cell(startContract)="data">
				{{ data.item.startContract | getDateMonthYear }}
			</template>
			<template #cell(endContract)="data">
				{{ data.item.endContract | getDateMonthYear }}
			</template>
			<template #cell(action)>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button v-if="true" variant="primary" class="btn-round-icon">
								<Icon icon="on" />
							</b-button>
							<b-button v-else variant="primary" class="btn-round-icon">
								<Icon icon="off" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
export default {
	name       : 'Renting',
	components : {
		Pagination,
	},
	props : {
		renting : {
			type     : Object,
			required : true,
		},
		rentingList : {
			type     : Array,
			required : false,
			default  :  function() {
				return [];
			}
		}
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('company'),
					key      : 'company',
					sortable : true,
				},
				{
					label    : this._i18n.t('daily-price'),
					key      : 'cost',
					sortable : true,
				},
				{
					label    : this._i18n.t('start-date-contract'),
					key      : 'startContract',
					sortable : true,
				},
				{
					label    : this._i18n.t('end-date-contract'),
					key      : 'endContract',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		},
		items() {
			if (this.rentingList) return this.rentingList;
			return [];
		}
	},

	data() {
		return {
			pagination : {
				perPage     : 10,
				currentPage : 1,
			}
		};
	},

	methods : {
		addRenting() {
			this.renting.$touch();
			if(!this.renting.$invalid && !this.renting.$erro) {
				this.items.push(this.renting.$model);
				this.renting.$model = {
					company         : null,
					cost            : null,
					startContract  : null,
					endContract    : null
				};
				this.$emit('renting', this.items);
				this.renting.$reset();
			}
		}
	}
};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>