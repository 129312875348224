<template>
	<div class="autocomplete">
		<b-form-input placeholder="_" :value="value" @input="onInput" :state="state" @keydown.enter="enter"
			@keydown.down="down($event)" @keydown.up="up($event)" />
		<slot name="invalid-feeback" />
		<ul v-if="open">
			<li v-for="(item, i) in suggestions" :key="i" :class="{'active': isActive(i)}" @click="setInputValue(item)">
				<div v-if="displayValue">
					{{ item[displayValue] }}
				</div>
				<div v-else-if="typeof item == 'object'">
					<div v-for="(key, z) in getKeys(item)" :key="z">
						{{ item[key] }}
					</div>
				</div>
				<div v-else>
					{{ item }}
				</div>
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	props : {
		value : {
			required : false,
			type     : String,
			default() {
				return null;
			}
		},
		state : {
			required : false,
			type     : Boolean,
			default() {
				return null;
			}
		},
		suggestions : {
			required : true,
			type     : Array
		},
		type : {
			required : false,
			type     : String,
			default() {
				return 'text';
			}
		},
		setValue : {
			required : false,
			type     : String,
			default() {
				return null;
			}
		},
		displayValue : {
			required : false,
			type     : String,
			default() {
				return null;
			}
		}
	},
	data() {
		return {
			open    : false,
			current : 0
		};
	},
	methods : {
		onInput(value) {
			this.$emit('input', value);
			if (this.open == false) {
				this.open = true;
				this.current = 0;
			}
		},
		setInputValue(value) {
			let val = value;
			if(this.setValue) {
				val = value[this.setValue];
			}
			this.$emit('input', val);
			this.open = false;
			this.current = 0;
		},
		getKeys(objet) {
			return Object.keys(objet);
		},
		enter() {
			let val = this.suggestions[this.current];
			if(this.setValue) {
				val = val[this.setValue];
			}
			this.$emit('input', val);
			this.open = false;
		},
		up($event) {
			$event.preventDefault();
			if(this.current > 0)
				this.current--;
		},
		down($event) {
			$event.preventDefault();
			if(this.current < this.suggestions.length - 1)
				this.current++;
		},
		isActive(index) {
			return this.current == index;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.no-border {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
ul {
	display: flex;
	flex-direction: column;
}
li {
	width: 100%;
	background-color: $white;
	cursor: pointer;
	padding: 0.375rem 0.75rem;
	border: 1px solid #ced4da;
	&:hover {
		background-color: var(--primary);
		color: $white;
	}
	&:last-of-type {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
}
li.active {
	background-color: var(--primary);
	color: $white;
}

</style>