<template>
	<div class="contact">
		<div class="d-flex justify-content-between">
			<h5>{{ $t('contact') }} N°{{ getIndex }}</h5>
			<div class="d-flex">
				<b-button v-if="contact.validateForm.$model" variant="outline-primary" class="btn-icon" @click="edit">
					<Icon icon="edit" />
					{{ $t('edit') }}
				</b-button>
				<b-button variant="outline-danger" class="btn-icon ml-1" @click="deleteContact">
					<Icon icon="trash" />
					{{ $t('delete') }}
				</b-button>
			</div>
		</div>
		<p class="text-information" v-if="contact.validateForm.$model">
			<span>{{ contact.lastname.$model }} {{ contact.firstname.$model }}</span>
			<span>{{ contact.phone.$model }}</span>
			<span>{{ contact.email.$model }}</span>
			<span>{{ contact.address.street.$model }}, {{ contact.address.city.$model }},
				{{ contact.address.postalCode.$model }}, {{ contact.address.country.$model.name }} </span>
		</p>
		<b-row v-else>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('lastname')">
					<b-form-input placeholder="_" v-model="contact.lastname.$model"
						:state="$_validateState(contact.lastname)" />
					<b-form-invalid-feedback>
						{{ $t('field-text-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('firstname')">
					<b-form-input placeholder="_" v-model="contact.firstname.$model"
						:state="$_validateState(contact.firstname)" />

					<b-form-invalid-feedback>
						{{ $t('field-text-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('email')">
					<b-form-input placeholder="_" v-model="contact.email.$model"
						:state="$_validateState(contact.email)" />
					<b-form-invalid-feedback>
						{{ $t('email-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('phone')">
					<b-form-input placeholder="_" v-model="contact.phone.$model"
						:state="$_validateState(contact.phone)" />
					<b-form-invalid-feedback>
						{{ $t('phone-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('address')">
					<b-form-input placeholder="_" v-model="contact.address.street.$model"
						:state="$_validateState(contact.address.street)" />
					<b-form-invalid-feedback>
						{{ $t('postal-code-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('postal-code')">
					<b-form-input placeholder="_" v-model="contact.address.postalCode.$model"
						:state="$_validateState(contact.address.postalCode)" />
					<b-form-invalid-feedback>
						{{ $t('postal-code-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('city')">
					<b-form-input placeholder="_" v-model="contact.address.city.$model"
						:state="$_validateState(contact.address.city)" />
					<b-form-invalid-feedback>
						{{ $t('field-text-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<MultipleSelect :options="countries" :searchable="true" v-model="contact.address.country.$model"
					track="name" :validation="contact.address.country" :label="$t('country')" />
			</b-col>
			<b-col lg="6" md="6" sm="12">
				<b-form-checkbox v-model="sameAddress">
					{{ $t('same-address-company') }}
				</b-form-checkbox>
			</b-col>
			<b-col lg="12" class="d-flex align-items-end justify-content-end">
				<b-button variant="primary" class="btn" @click="submit">
					{{ $t('valid') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import countriesMixins from '@/mixins/countries.js';
export default {
	props : {
		index : {
			required : true,
			type     : String
		},
		contact : {
			required : true,
			type     : Object
		},
		actor : {
			required : true,
			type     : Object
		}
	},
	mixins   : [countriesMixins],
	computed : {
		getIndex() {
			return parseInt(this.index) + 1;
		},
		getAddressContact() {
			return this.contact.address.$model;
		},
		getAdressActor() {
			return this.actor.address.$model;
		}
	},
	data() {
		return {
			sameAddress : false,
		};
	},
	created() {
		const addressContact = this.contact.address;
		if(addressContact.country.$model && !addressContact.country.$model.alpha2) {
			addressContact.country.$model = this.findCountry(addressContact.country.$model);
		}
		if(!addressContact.city && !addressContact.country && !addressContact.postalCode && !addressContact.street) {
			const addressActor = this.actor.address.$model;
			if(addressActor.city && addressActor.country && addressActor.postalCode && addressActor.street) {
				this.sameAddress = true;
			}
		}
	},
	methods : {
		deleteContact() {
			this.contact.$reset();
			this.$emit('delete-contact', this.index);
		},
		submit() {
			this.contact.$touch();
			if(!this.contact.$invalid) {
				this.contact.validateForm.$model = true;
			}
		},
		edit() {
			this.contact.validateForm.$model = false;
			this.contact.$reset();
		}
	},
	watch : {
		getAddressContact : {
			handler(val) {
				if(JSON.stringify(val) != JSON.stringify(this.getAdressActor)) {
					this.sameAddress = false;
				} else {
					this.sameAddress = true;
				}
			},
			deep : true
		},
		sameAddress(val) {
			if(val) {
				this.contact.address.$model = this.$_clone(this.actor.address.$model);
				this.contact.$reset();
			} else {
				this.$nextTick(() => {
					this.contact.$reset();
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
h5 {
	margin-bottom: 0;
	font-weight: bold;
}
.contact {
	border-bottom: 2px solid $light-grey;
	padding-bottom: 1rem;
}
</style>