import { HTTP } from '../../http-common';

const actions = {
	get(context, data) {
		return HTTP.get('/scheduledSegments?startDate=' + data.startDate +
		'&endDate=' + data.endDate + '&transportationSegment=' + data.segment._id)
			.then(res => {
				return res.data;
			}).catch(err => {
				throw err.response.data.message;
			});
	}
};

export default {
	namespaced : true,
	actions,
};
