<template>
	<div id="calendar">
		<b-card no-body>
			<b-card-header class="primary">
				{{ $t('calendar') }}
			</b-card-header>
			<b-card-body>
				<form>
					<b-row>
						<b-col lg="3" md="6" sm="12">
							<b-form-group :label="$t('date-planning')">
								<DatePicker v-model="$v.calendar.planning.$model"
									:state="$_validateState($v.calendar.planning)" id="datePlanning" />
								<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
						<b-col lg="3" md="6" sm="12">
							<b-form-group :label="$t('start-date')">
								<DatePicker v-model="$v.calendar.start.$model"
									:state="$_validateState($v.calendar.start)" :max="getMaximumStartDate"
									id="startDate" />
								<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
						<b-col lg="3" md="6" sm="12">
							<b-form-group :label="$t('end-date')" :disabled="!$v.calendar.start.$model">
								<DatePicker v-model="$v.calendar.end.$model" :min="getMinimumEndDate"
									:state="$_validateState($v.calendar.end)" id="endDate" />
								<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
						<b-col lg="3" md="6" sm="12">
							<b-form-group :label="$t('pre-invoice-conditions')">
								<b-form-select v-model="$v.calendar.preInvocing.$model"
									:options="preInvoicingOptions" :state="$_validateState($v.calendar.preInvocing)"
									name="preInvoiceConditions">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>
											_
										</b-form-select-option>
									</template>
								</b-form-select>
							</b-form-group>
						</b-col>
						<b-col lg="3" md="6" sm="12">
							<b-form-group :label="$t('send-order')">
								<b-form-input type="number" v-model="$v.calendar.sendProviderDays.$model"
									:state="$_validateState($v.calendar.sendProviderDays)" name="sendOrder" />
								<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
							</b-form-group>
						</b-col>
					</b-row>
				</form>
			</b-card-body>
		</b-card>
		<div class="mt-3 d-flex justify-content-end">
			<b-button variant="info" @click="$emit('go-to-step', 'transport-plan')">
				{{ $t('previous') }}
			</b-button>
			<b-button variant="primary" class="ml-1" @click="submit" :disabled="$v.$invalid" id="next-calendar">
				{{ $t('next') }}
			</b-button>
		</div>
	</div>
</template>
<script>
import { validateRequired } from '@/utils/plugins/validations/vuelidate';
import moment from 'moment';
export default {
	props : {
		calendar : {
			type     : Object,
			required : true
		},
		isOwner : {
			type     : Boolean,
			required : true,
		}
	},
	validations : {
		calendar : {
			planning         : validateRequired,
			start            : validateRequired,
			end              : validateRequired,
			preInvocing      : validateRequired,
			sendProviderDays : validateRequired
		}
	},
	computed : {
		preInvoicingOptions() {
			return [
				{
					value : 'weekly',
					text  : this._i18n.t('weekly')
				},
				{
					value : 'monthly',
					text  : this._i18n.t('monthly')
				},
				{
					value : 'quarterly',
					text  : this._i18n.t('quarterly')
				},
				{
					value : 'semiannually',
					text  : this._i18n.t('semiannually')
				}
			];
		},
		getMinimumEndDate() {
			const start = this.calendar.start;
			if(start) {
				return moment(start).add(1, 'days').format('YYYY-MM-DD');
			}
			return null;
		},
		getMaximumStartDate() {
			const end = this.calendar.end;
			if(end) {
				return moment(end).subtract(1, 'days').format('YYYY-MM-DD');
			}
			return null;
		}
	},
	methods : {
		submit() {
			this.$v.$touch();
			if(!this.$v.$invalid) {
				for (let i = 0; i < Object.keys(this.calendar).length; i++) {
					const key = Object.keys(this.calendar)[i];
					if(['planning', 'start', 'end'].includes(key)) {
						const object = this.calendar[key];
						this.calendar[key] = moment(object)._d;
					}
				}
				if(this.isOwner) {
					this.$emit('go-to-step', 'pricing-condition');
				} else {
					this.$emit('go-to-step', 'validation');
				}
			}
		},
	}
};
</script>
<i18n>
{
	"fr": {
		"send-order": "Envoi commandes au prestataire (J-)"
	},
	"en": {
		"send-order": "Sends orders to the provider (D-)"
	}
}
</i18n>