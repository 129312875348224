<template>
	<b-modal :id="id" centered :size="size" :scrollable="scrollable" :hide-footer="hideFooter">
		<template #modal-title>
			<h5>{{ title }}</h5>
		</template>
		<template #modal-header-close="{close}">
			<Icon icon="cross" @click="close()" />
		</template>
		<slot name="content" />
		<template #modal-footer="{cancel}">
			<div v-if="getFooterSlot">
				<slot name="footer" />
			</div>
			<div v-else class="child-mx-2">
				<b-button variant="info" @click="cancel">
					{{ $t('cancel') }}
				</b-button>
				<b-button variant="primary" @click="confirm()" id="acknowledged">
					{{ $t('acknowledged') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script type="javascript">
export default {
	props : {
		id : {
			type     : String,
			required : true
		},
		title : {
			type     : String,
			required : false,
			default  : ''
		},
		size : {
			type     : String,
			required : false,
			default  : 'md'
		},
		scrollable : {
			type     : Boolean,
			required : false,
			default  : false
		},
		hideFooter : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	methods : {
		confirm() {
			this.$bvModal.hide(this.id);
			this.$emit('confirm');
		}
	},
	computed : {
		getFooterSlot() {
			return this.$slots.footer;
		}
	}
};
</script>