<template>
	<main id="trains" />
</template>

<script>
export default {
	name : 'Trains',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('trains'),
		};
	},
};
</script>
