<template>
	<form @submit="submit" autocomplete="off">
		<b-form-group :label="$t('company-name')">
			<b-form-input v-model="$v.register.company.name.$model"
				:state="$_validateState($v.register.company.name)" name="companyname" />
			<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('company-type')">
			<b-form-select :options="companyTypes" v-model="$v.register.company.type.$model"
				:state="$_validateState($v.register.company.type)" name="companytype">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
			<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('siren')">
			<b-input placeholder="_" type="number" v-model="$v.register.company.siret.siren.$model"
				:state="$_validateState($v.register.company.siret.siren)" name="siren" />
			<b-form-invalid-feedback>{{ $t('siren-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('nic')">
			<b-input placeholder="_" type="number" v-model="$v.register.company.siret.nic.$model"
				:state="$_validateState($v.register.company.siret.nic)" name="nic" />
			<b-form-invalid-feedback>{{ $t('nic-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('vat-number')">
			<b-input placeholder="_" type="text" v-model="$v.register.company.tva.$model"
				:state="$_validateState($v.register.company.tva)" name="tva" />
			<b-form-invalid-feedback>{{ $t('vat-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<div class="w-100 mt-3 d-flex justify-content-end">
			<b-button variant="primary" class="mb-3 w-100" type="submit" @click="submit" id="nextsociety">
				{{ $t('next') }}
			</b-button>
		</div>
	</form>
</template>

<script>
import { validateTextRequired, validateVAT, validateSiren, validateNic } from '@/utils/plugins/validations/vuelidate';
import { required } from 'vuelidate/lib/validators';
import  companyMixins  from '@/mixins/company.js';
export default {
	name   : 'Society',
	mixins : [companyMixins],
	props  : {
		register : {
			type     : Object,
			required : true,
		}
	},
	validations : {
		register : {
			company : {
				name  : validateTextRequired,
				type  : {
					required,
				},
				siret : {
					siren : validateSiren,
					nic   : validateNic,
				},
				tva : validateVAT
			},
		}
	},
	methods : {
		submit($event) {
			$event.preventDefault();
			this.$v.register.$touch();
			if (!this.$v.register.$invalid) {
				this.$store.commit('registers/register', this.register);
				this.$emit('go-to-step', 'address');
			}
		}
	}
};
</script>