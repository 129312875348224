<template>
	<div class="d-flex" v-if="pagination.totalRows">
		<b-pagination v-model="pagination.currentPage" :total-rows="pagination.totalRows"
			:per-page="pagination.perPage" first-number last-number />
		<div id="per-page" class="d-flex align-items-center">
			<b-form-select v-model="pagination.perPage"
				:options="options" class="width-content" />
			<p class="text-grey text-initial">
				{{ $t('per-page') }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name  : 'Pagination',
	props : {
		pagination : {
			type     : Object,
			required : true
		},
	},
	computed : {
		options() {
			return [
				10, 20, 50, 100
			];
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ .page-item .page-link {
	font-weight: 600;
	border-radius: $border-radius;
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: none;
}
/deep/ .page-item:first-child {
	display: none;
}
/deep/ .page-item:last-child {
	display: none;
}
.row {
	align-items: baseline;
	justify-content: center;
}
.custom-select {
	margin: 0 0.5rem;
}

@media screen and (max-width: 370px) {
	.row {
		flex-direction: column;
		align-items: center;
	}
	#per-page {
		margin-top: 0.5rem;
	}
	.custom-select {
		margin-left: 0;
	}
}
</style>
