<template>
	<main id="update-collaborator">
		<h1 class="mb-4">
			{{ $t('update-collaborator') }}
		</h1>
		<b-row>
			<b-col xl="12">
				<b-card no-body id="viewcollaborator">
					<b-card-header class="primary" />
					<b-card-body>
						<CollaboratorsFields :collaborator="$v.collaborator" type="view" />
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row>
			<b-col xl="6" class="d-flex justify-content-end">
				<b-button class="btn-icon mt-3" variant="info"
					@click="$router.push({name: 'CollaboratorList'})">
					<Icon icon="arrow-back" id="backToList" />
					{{ $t('return') }}
				</b-button>
			</b-col>
		</b-row>
	</main>
</template>

<script>
import CollaboratorsFields from '../collaborators/components/CollaboratorsFields.vue';
import { validateEmail, validateTextRequired, validateText, validateRequired, validatePhone,
	validatePostalCode, validateConfirmEmail, validatePassword,
	validateConfirmPassword } from '@/utils/plugins/validations/vuelidate';
import countriesMixins from '@/mixins/countries.js';

export default {
	name       : 'ViewCollaborator',
	components : {
		CollaboratorsFields
	},
	mixins : [countriesMixins],
	async created() {
		await this.$store.dispatch('collaborator/READBYFORCEID', this.$route.params.id).then((res) => {
			this.collaborator = res.data;
			this.collaborator.phone = this.collaborator.phone.join(';');
			this.collaborator.scope = this.collaborator.scope.join(';');
			if (this.collaborator.address.country) {
				const country = this.collaborator.address.country;
				this.collaborator.address.country = this.countries.find(cnt => cnt.alpha2 === country);
			}
		});
	},

	data() {
		return {
			collaborator : {}
		};
	},
	validations() {
		const validations = {
			collaborator : {
				firstname    : validateTextRequired,
				lastname     : validateTextRequired,
				email        : validateEmail,
				confirmEmail : validateConfirmEmail,
				password     : validatePassword,
				repassword   : validateConfirmPassword,
				phone        : validatePhone,
				language     : validateRequired,
				scope        : validateRequired,
				emailconf     : validateRequired,
				address      : {
					street     : validateTextRequired,
					country    : validateRequired,
					city       : validateTextRequired,
					postalCode : validatePostalCode,
					state      : validateText,
					lieu       : validateText,

				}
			}
		};
		return validations;
	},

};
</script>