<template>
	<div class="multiple-select w-100" :class="{'tag': multiple}">
		<b-form-group v-if="label && !validation" :label="label">
			<multi-select :value="value" @input="onInput" @search-change="onSearch" @open="onOpen"
				select-label="" :placeholder="placeholder" :searchable="searchable" :label="track"
				:track-by="track" :options="options" :hide-selected="true" :multiple="multiple"
				:disabled="disabled" :loading="loading">
				<template slot="afterList" v-if="button && search">
					<div class="multiselect__btn" @click="clickBtn">
						<span>{{ button.text }}</span>
						<span v-if="button.displayValue"> : {{ search }}</span>
					</div>
				</template>
				<template slot="noResult">
					<div>
						{{ $t('no-result') }}
					</div>
				</template>
				<template v-if="icon" slot="option" slot-scope="props">
					<div class="d-flex align-items-center">
						<Icon :icon="props.option.icon" />
						<span>{{ $t(props.option.text) }}</span>
					</div>
				</template>
				<template v-if="icon" slot="singleLabel" slot-scope="props">
					<div class="d-flex align-items-center">
						<Icon :icon="props.option.icon" />
						<span>{{ $t(props.option.text) }}</span>
					</div>
				</template>
			</multi-select>
		</b-form-group>
		<b-form-group v-if="label && validation" :label="label"
			:class="{ 'form-group--error': validation.$error,
				'form-group--valid': validation.$dirty
					&& !validation.$error }">
			<multi-select @input="onInput" @search-change="onSearch"
				:value="value" @close="validateVal" @open="onOpen"
				select-label="" :placeholder="placeholder" :searchable="searchable" :label="track"
				:track-by="track" :options="options" :hide-selected="true" :multiple="multiple"
				:disabled="disabled" :loading="loading">
				<template slot="afterList" v-if="button && search">
					<div class="multiselect__btn" @click="clickBtn">
						<span>{{ button.text }}</span>
						<span v-if="button.displayValue"> : {{ search }}</span>
					</div>
				</template>
				<template slot="noResult">
					<div>
						{{ $t('no-result') }}
					</div>
				</template>
				<template v-if="icon" slot="option" slot-scope="props">
					<div class="d-flex align-items-center">
						<Icon :icon="props.option.icon" />
						<span>{{ $t(props.option.text) }}</span>
					</div>
				</template>
				<template v-if="icon" slot="singleLabel" slot-scope="props">
					<div class="d-flex align-items-center">
						<Icon :icon="props.option.icon" />
						<span>{{ $t(props.option.text) }}</span>
					</div>
				</template>
			</multi-select>

			<b-form-invalid-feedback>
				{{ $t('field-required-err') }}
			</b-form-invalid-feedback>
		</b-form-group>
		<multi-select v-if="!label" :value="value" @input="onInput" @search-change="onSearch"
			select-label="" :placeholder="placeholder" :searchable="searchable" :label="track"
			:track-by="track" :options="options" :hide-selected="true" :multiple="multiple"
			@open="onOpen" :disabled="disabled" :loading="loading">
			<template slot="afterList" v-if="button && search">
				<div class="multiselect__btn" @click="clickBtn">
					<span>{{ button.text }}</span>
					<span v-if="button.displayValue"> : {{ search }}</span>
				</div>
			</template>
			<template slot="noResult">
				<div>
					{{ $t('no-result') }}
				</div>
			</template>
			<template v-if="icon" slot="option" slot-scope="props">
				<div class="d-flex align-items-center">
					<Icon :icon="props.option.icon" />
					<span>{{ $t(props.option.text) }}</span>
				</div>
			</template>
			<template v-if="icon" slot="singleLabel" slot-scope="props">
				<div class="d-flex align-items-center">
					<Icon :icon="props.option.icon" />
					<span>{{ $t(props.option.text) }}</span>
				</div>
			</template>
		</multi-select>
	</div>
</template>
<script>
export default {
	name  : 'MultipleSelect',
	props : {
		icon : {
			required : false,
			type     : Boolean,
			default  : false
		},
		loading : {
			required : false,
			type     : Boolean,
			default  : false,
		},
		multiple : {
			required : false,
			type     : Boolean,
			default  : false
		},
		track : {
			required : false,
			type     : String,
			default  : ''
		},
		options : {
			required : true,
			type     : Array
		},
		label : {
			required : false,
			type     : String,
			default  : ''
		},
		searchable : {
			required : false,
			type     : Boolean,
			default   : false
		},
		validation : {
			required : false,
			type     : Object,
			default() {
				return null;
			}
		},
		value : {
			required : false,
			type     : null,
			default() {
				return null;
			}
		},
		placeholder : {
			required : false,
			type     : String,
			default() {
				return '_';
			}
		},
		setValue : {
			required : false,
			type     : String,
			default() {
				return null;
			}
		},
		disabled : {
			required : false,
			type     : null,
			default() {
				return false;
			}
		},
		button : {
			required : false,
			type     : Object,
			default() {
				return {};
			}
		},
		name : {
			required : false,
			type     : String,
			default() {
				return '';
			}
		},
	},
	data() {
		return{
			val    : null,
			search : '',
		};
	},
	computed : {
	},
	methods : {
		onInput(value) {
			if(this.setValue) {
				this.$emit('input', value[this.setValue]);
			} else {
				this.$emit('input', value);
			}
		},
		onOpen() {
			this.$emit('on-open');
		},
		onSearch(value) {
			this.search = value;
		},
		clickBtn() {
			this.$emit('click-btn', this.search);
		},
		validateVal() {
			if(!this.value) {
				this.$el.getElementsByClassName('form-group')[0].classList.add('form-group--error');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.multiselect__btn {
	padding: 12px;
	line-height: 16px;
	cursor: pointer;
	font-weight: 600;
	background-color: $light-grey;
	color: var(--primary);
	text-transform: initial;
}
/deep/.multiselect__select {
	display: none;
}
</style>