<template>
	<div id="identification">
		<b-row>
			<b-col lg="6">
				<b-form-group :label="$t('code')">
					<b-form-input placeholder="_" type="number" v-model="identification.code.$model"
						:state="$_validateState(identification.code)" name="code" />
					<b-form-invalid-feedback>{{ $t('code-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('length')+('(m)')">
					<b-form-input placeholder="_" type="number" v-model="identification.length.$model"
						:state="$_validateState(identification.length)" name="length" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('weight-brakes')+('(T)')">
					<b-form-input placeholder="_" type="number" v-model="identification.brakingWeight.$model"
						:state="$_validateState(identification.brakingWeight)" name="brakingWeight" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('family')">
					<b-form-input placeholder="_" type="text" v-model="identification.family.$model"
						:state="$_validateState(identification.family)" name="family" />
					<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('evp')">
					<b-form-input placeholder="_" type="number" v-model="identification.TEU.$model"
						:state="$_validateState(identification.TEU)" name="TEU" />
					<b-form-invalid-feedback>{{ $t('evp-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6">
				<b-form-group :label="$t('type')">
					<b-form-input placeholder="_" type="number" v-model="identification.type.$model"
						:state="$_validateState(identification.type)" name="type" />
					<b-form-invalid-feedback>{{ $t('type-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('number-axles')">
					<b-form-input placeholder="_" type="number" v-model="identification.axesNumber.$model"
						:state="$_validateState(identification.axesNumber)" name="axesNumber" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('tare')+('(T)')">
					<b-form-input placeholder="_" type="number" v-model="identification.tare.$model"
						:state="$_validateState(identification.tare)" name="tare" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('payload')+('(T)')">
					<b-form-input placeholder="_" type="number" v-model="identification.payload.$model"
						:state="$_validateState(identification.payload)" name="payload" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>

				<b-form-group :label="$t('current-mileage')">
					<b-form-input placeholder="_" type="number" v-model="identification.kmUsage.$model"
						:state="$_validateState(identification.kmUsage)" name="kmUsage" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name  : 'Identification',
	props : {
		identification : {
			type     : Object,
			required : true,
		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>