<template>
	<b-card no-body>
		<b-card-header class="d-flex justify-content-between align-items-center">
			{{ $t('pricing-condition') }}
			<div class="d-flex">
				<b-button variant="outline-danger" class="btn-icon btn-input mr-2"
					@click="deleteCondition" v-if="selectedCondition">
					<Icon icon="trash" />
					{{ $t('delete') }}
				</b-button>
				<b-button variant="outline-light" class="btn-icon btn-input"
					@click="editCondition" v-if="selectedCondition">
					<Icon icon="edit" />
					{{ $t('edit') }}
				</b-button>
			</div>
		</b-card-header>
		<b-card-body>
			<b-form>
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<MultipleSelect :options="segmentsTypes" track="text" :icon="true" :label="$t('typology')"
							v-model="$v.condition.typology.$model" :validation="$v.condition.typology" id="typology" />
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<MultipleSelect :options="getCompagnies" track="companyName" :searchable="true"
							v-model="$v.condition.responsibility.$model" :validation="$v.condition.responsibility"
							:label="$t('responsibility')" id="responsibility" />
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('type-of-trip')">
							<b-form-select v-model="$v.condition.trajectType.$model" :options="getTypeOfTrip"
								:state="$_validateState($v.condition.trajectType)" name="trajectType">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>
										_
									</b-form-select-option>
								</template>
							</b-form-select>
							<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('type-of-penalties')">
							<b-form-select v-model="$v.condition.penaltyType.$model" :options="getTypeOfPenalities"
								:state="$_validateState($v.condition.penaltyType)" name="penaltyType">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>
										_
									</b-form-select-option>
								</template>
							</b-form-select>
							<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('units-of-penalties')">
							<b-form-select v-model="$v.condition.unitsPenalties.$model" :options="getUnitsPenalties"
								:state="$_validateState($v.condition.unitsPenalties)" name="unitsPenalties">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>
										_
									</b-form-select-option>
								</template>
							</b-form-select>
							<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('percentage-road')">
							<b-form-input type="number" v-model="$v.condition.percentage.$model" placeholder="_"
								:state="$_validateState($v.condition.percentage)" name="percentageRoad" />
							<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="12" sm="12" class="d-flex align-items-end justify-content-end">
						<b-button variant="primary" class="btn-icon btn-input"
							@click="addCondition" v-if="!selectedCondition" id="addPricing">
							<Icon icon="add" />
							{{ $t('add') }}
						</b-button>
					</b-col>
				</b-row>
			</b-form>
		</b-card-body>
	</b-card>
</template>
<script>
import { validateRequired } from '@/utils/plugins/validations/vuelidate.js';
export default {
	props : {
		actors : {
			required : true,
			type     : Array,
		},
		selectedCondition : {
			required : false,
			type     : Object,
			default() {
				return {};
			}
		},
		segments : {
			required : true,
			type     : Array,
		}
	},
	data() {
		return {
			condition : {
				typology          : null,
				responsibility    : null,
				penaltyType        : null,
				unitsPenalties    : null,
				percentage        : null,
				validatedOperator : true,
				trajectType       : null,
				validatedCharger   : true,
				range             : [],
				index             : null,
			},
		};
	},
	validations : {
		condition : {
			typology          : validateRequired,
			responsibility    : validateRequired,
			trajectType       : validateRequired,
			penaltyType       : validateRequired,
			unitsPenalties    : validateRequired,
			percentage        : validateRequired,
			validatedCharger  : {},
			validatedOperator : {}

		}
	},
	watch : {
		selectedCondition(val) {
			if(val) {
				this.condition = this.$_clone(val);
				this.$nextTick(() => {
					this.$v.$reset();
				});
			} else {
				this.reset();
			}
		}
	},
	computed : {
		segmentsTypes() {
			const types = [];
			for (let i = 0; i < this.segments.length; i++) {
				const seg = this.segments[i];
				const type = {
					icon  : this.findIconType(seg.typology),
					text : seg.typology
				};
				types.push(type);
			}
			return types;
		},
		getCompagnies() {
			return this.actors.map(actor=> {
				return {
					companyId   : actor.companyId,
					companyName : actor.name || actor.companyName
				};
			});
		},
		getUnitsPenalties() {
			return  [
				{
					value : 'time',
					text  : this._i18n.t('time')
				},
				{
					value : 'threshold',
					text  : this._i18n.t('threshold')
				},
			];
		},
		getTypeOfTrip() {
			return [
				{
					value : 'full',
					text  : this._i18n.t('full'),
				},
				{
					value : 'empty',
					text  : this._i18n.t('empty')
				},
				{
					value : 'all',
					text  : this._i18n.t('all')
				}
			];
		},
		getTypeOfPenalities() {
			return [
				{
					value : 'delay',
					text  : this._i18n.t('delay')
				},
				{
					value : 'cancellation',
					text  : this._i18n.t('cancellation')
				},
				{
					value : 'modification',
					text  : this._i18n.t('modification')
				}
			];
		},
	},
	methods : {
		editCondition() {
			this.condition.typology = this.condition.typology.text;
			this.$emit('edit-condition', this.condition);
		},
		deleteCondition() {
			this.$emit('delete-condition', this.condition._id);
			this.reset();
		},
		findIconType(text) {
			const opt = [
				{
					text : 'ROAD',
					icon : 'truck'
				},
				{
					text : 'RAIL',
					icon : 'train'
				},
				{
					text : 'AIR',
					icon : 'airplane'
				},
				{
					text : 'SEA',
					icon : 'boat'
				},
				{
					text : 'RIVER',
					icon : 'river'
				},
			];
			const find = opt.find((x) => x.text == text);
			if(find)
				return find.icon;
		},
		addCondition() {
			this.$v.condition.$touch();
			if(!this.$v.condition.$invalid) {
				const condition = this.condition;
				//condition.id = Date.now();
				this.$emit('add-condition', this.$_clone(condition));
				this.reset();
			}
		},
		reset() {
			Object.assign(this.$data, this.$options.data.apply(this));
			this.$nextTick(() => {
				this.$v.$reset();
			});
		}
	},
};
</script>
<i18n>
{
	"fr":{
		"percentage-road": "% de trajet"
	},
	"en":{
		"percentage-road": "% of trip"
	}
}
</i18n>