<template>
	<div class="actor-informations">
		<h5 v-if="actor.validateForm.$model">
			{{ actor.name.$model }}
		</h5>
		<h5 v-else>
			{{ $t('actor') }}
		</h5>
		<p class="text-information" v-if="actor.validateForm.$model">
			<span>{{ $t('siren') }} : {{ actor.siret.siren.$model }}</span>
			<span>{{ $t('nic') }} : {{ actor.siret.nic.$model }}</span>
			<span>{{ $t('vat-number') }} : {{ actor.tva.$model }}</span>
			<span>{{ actor.address.street.$model }}, {{ actor.address.city.$model }},
				{{ actor.address.postalCode.$model }}, {{ actor.address.country.$model.name }}</span>
		</p>
		<b-row v-else>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('siren')" id="siren">
					<Autocomplete type="number" v-model="actor.siret.siren.$model" display-value="name"
						:state="$_validateState(actor.siret.siren)" :suggestions="actorsSuggestions"
						set-value="siret">
						<template #invalid-feeback>
							<b-form-invalid-feedback>
								{{ $t('siren-err') }}
							</b-form-invalid-feedback>
						</template>
					</Autocomplete>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('name')">
					<b-form-input placeholder="_" v-model="actor.name.$model"
						:state="$_validateState(actor.name)" name="name" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('nic')">
					<b-form-input type="number" placeholder="_" v-model="actor.siret.nic.$model"
						:state="$_validateState(actor.siret.nic)" name="nic" />
					<b-form-invalid-feedback>
						{{ $t('nic-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('vat-number')">
					<b-form-input placeholder="_" v-model="actor.tva.$model"
						:state="$_validateState(actor.tva)" name="tva" />
					<b-form-invalid-feedback>
						{{ $t('vat-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('address')">
					<b-form-input placeholder="_" v-model="actor.address.street.$model"
						:state="$_validateState(actor.address.street)" name="address" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('postal-code')">
					<b-form-input placeholder="_" v-model="actor.address.postalCode.$model"
						:state="$_validateState(actor.address.postalCode)" name="postalcode" />
					<b-form-invalid-feedback>{{ $t('postal-code-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('city')">
					<b-form-input placeholder="_" v-model="actor.address.city.$model"
						:state="$_validateState(actor.address.city)" name="city" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<MultipleSelect :options="countries" v-model="actor.address.country.$model" :label="$t('country')"
					track="name" :validation="actor.address.country" :searchable="true" id="optionsCountry" />
			</b-col>
			<b-col lg="12" class="d-flex align-items-end justify-content-end">
				<b-button variant="primary" @click="submit" id="valid-actor">
					{{ $t('valid') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import Autocomplete from '@/components/common/Autocomplete.vue';
import countriesMixins from '@/mixins/countries.js';
export default {
	components : {
		Autocomplete
	},
	mixins : [
		countriesMixins
	],
	props : {
		actor : {
			required : true,
			type     : Object
		},
		actors : {
			required : true,
			type     : Array
		}
	},
	data() {
		return {
			actorsSuggestions : []
		};
	},
	created() {
		const address = this.actor.address.$model;
		if(address.country && !address.country.alpha2) {
			address.country = this.findCountry(address.country);
		}
	},
	computed : {

		siren() {
			return this.actor.siret.siren.$model;
		}
	},
	watch : {
		async siren(val) {
			if(val && val.length > 2 && val.length < 9) {
				const actors = [];
				this.$store.dispatch('company/getCompanyListBySiren', val).then((res) => {
					for (let i = 0; i < res.data.length; i++) {
						const e = res.data[i];
						e['siret'] = this.$_clone(e.siret.siren);
						const find = this.actors.find((x) => x.siret.siren == e.siret);
						if(!find) {
							actors.push(e);
						}
					}
					this.actorsSuggestions = actors;
				});
			} else if(val.length == 9) {
				const find = this.actors.filter((x) => x.siret.siren == val);
				if(find.length > 1) {
					return this.$_makeToast('danger', this._i18n.t('this-actor-is-already-present-in-the-contract'),
						this._i18n.t('error'));
				} else {
					const companyFind = this.actorsSuggestions.find((x) => x.siret == val);
					if(companyFind) {
						await this.$store.dispatch('company/getCompanyById', companyFind._id).then((res) => {
							const company = res.data;
							const model = this.actor.$model;
							model.companyId = companyFind._id;
							company.address.country = this.findCountry(company.address.country);
							model.address = company.address;
							model.name = company.name;
							model.tva = company.tva;
							model.siret.nic = company.siret.nic;
							model.type = company.type;
							this.actor.$touch();
						});
					}
				}
			} else {
				this.actorsSuggestions = [];
			}
		}
	},
	methods : {
		submit() {
			this.actor.$touch();
			if(!this.actor.$invalid) {
				this.actor.validateForm.$model = true;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
h5 {
	margin-bottom: 0;
	font-weight: bold;
}
.actor-informations {
	border-bottom: 2px solid $light-grey;
	padding-bottom: 1rem;
}
</style>
<i18n>
{
	"fr": {
		"this-actor-is-already-present-in-the-contract": "Cet acteur est déjà présent dans le contract."
	},
	"en": {
		"this-actor-is-already-present-in-the-contract": "This actor is already present in the contract."
	}
}
</i18n>