<template>
	<b-row>
		<b-col lg="6" sm="12">
			<MultipleSelect :options="actors" :searchable="true" :label="$t('operator')"
				track="name" v-model="segment.operator" id="operator" />
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('internal-reference')">
				<b-form-input placeholder="_" v-model="segment.internalReference" name="internalReference" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('type-of-transport-units')">
				<b-form-select v-model="segment.volumetry.transportUnit" :options="transportsUnits"
					name="typeTransportUnits">
					<template v-slot:first>
						<b-form-select-option :value="null" disabled>
							_
						</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('number-of-transport-units')">
				<b-form-input placeholder="_" type="number" v-model="segment.volumetry.units"
					name="numberTransportUnits" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('handling-per-transport-units')">
				<b-form-input placeholder="_" type="number" v-model="segment.volumetry.handlingPerTransport"
					name="handlingTransportUnits" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('number-weekly')">
				<b-form-input placeholder="_" type="number" v-model="segment.volumetry.periodicity"
					name="numberWeekly" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('max-weight-per-transport-unit')">
				<b-form-input placeholder="_" type="number" v-model="segment.volumetry.maxWeightPerTU"
					name="maxWeightTransportUnit" />
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('max-volume-per-transport-unit')">
				<b-form-input placeholder="_" type="number" v-model="segment.volumetry.maxVolumePerTU"
					name="maxVolumeTransportUnit" />
			</b-form-group>
		</b-col>

		<b-col lg="6" sm="12">
			<b-form-group :label="$t('dangerous-goods')">
				<b-form-radio-group v-model="segment.operationalConstraint.allowDangerous"
					:options="dangerousGoodsOptions" name="radiodangerous" id="radiodangerous" />
			</b-form-group>
		</b-col>
		<b-col lg="6" md="12">
			<Schedules :schedules="segment.timetable" theme="light"
				:title="$t('circulation-hours-days')" :typology="segment.typology"
				@set-schedules="setSchedules" id="typology-form" />
		</b-col>
	</b-row>
</template>
<script>
import transportUnitsMixins  from '@/mixins/list/transportUnits.js';

import Schedules from './Schedules.vue';
export default {
	components : {
		Schedules,
	},
	props : {
		segment : {
			required : true,
			type     : Object
		},
		actors : {
			required : true,
			type     : Array,
		}
	},
	mixins : [
		transportUnitsMixins
	],
	methods : {
		setSchedules(schedules) {
			this.segment.timetable = schedules;
		},
	},
	computed : {
		dangerousGoodsOptions() {
			return [
				{
					text  : this._i18n.t('yes'),
					value : 'yes'
				},
				{
					text  : this._i18n.t('no'),
					value : 'no'
				}
			];
		}
	}
};
</script>