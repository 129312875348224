import Moment from 'moment';
const Informations = {
	methods : {
		getDaysPlusTransportPlan(transportPlan) {
			if(transportPlan.timetable && transportPlan.timetable.departure_time) {
				const hours_minutes = transportPlan.timetable.departure_time.split(':');
				const start = Moment().startOf('month').hour(hours_minutes[0]).minute(hours_minutes[1]);
				let end = this.$_clone(start);
				end.add(transportPlan.timetable.duration, 'minutes');
				end = Moment(end).hour(23);
				return end.diff(start, 'days');
			}
		}
	}
};
export default Informations;