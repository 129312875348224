<template>
	<div>
		<b-row>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('type-of-handling-units')">
					<b-form-select v-model="order.handlingUnitType.$model" :options="handlingUnits">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								_
							</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('number-of-handling-units')">
					<b-form-input placeholder="_" type="number" v-model="order.handlingUnitNumber.$model"
						:state="$_validateState(order.handlingUnitNumber)" name="handlingUnitNumber" />
					<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('price-per-handling-unit')">
					<b-form-input placeholder="_" type="number" v-model="order.handlingUnitPrice.$model"
						:state="$_validateState(order.handlingUnitPrice)" name="price" />
					<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('transit-time-j+')">
					<b-form-input typer="number" v-model="order.transitTime.$model" placeholder="_"
						:state="$_validateState(order.transitTime)" />
					<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>
<script>

import handlingUnitsMixins from '@/mixins/list/handlingUnits.js';
export default {

	mixins : [
		handlingUnitsMixins
	]
	,
	props : {
		order : {
			type     : Object,
			required : true,
		}
	},
};
</script>
<i18n>
{
	"fr": {
		"circulation-field-err": "Ce champ est requis et doit contenir au moins 1 jour."
	},
	"en": {
		"circulation-field-err": "This field is required and must be contain one day."
	}
}
</i18n>