<template>
	<div id="contactFields">
		<b-row>
			<b-col lg="6">
				<b-form-group :label="$t('lastname')">
					<b-form-input type="text" placeholder="_" v-model="$v.contact.lastname.$model"
						:state="$_validateState($v.contact.lastname)" name="lastname" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6">
				<b-form-group :label="$t('email')">
					<b-form-input type="email" placeholder="_" v-model="$v.contact.mail.$model"
						:state="$_validateState($v.contact.mail)" name="emailcontact" />
					<b-form-invalid-feedback>{{ $t('email-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6">
				<b-form-group :label="$t('job')">
					<b-form-input type="text" placeholder="_" v-model="$v.contact.job.$model"
						:state="$_validateState($v.contact.job)" name="job" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6">
				<b-form-group :label="$t('firstname')">
					<b-form-input type="text" placeholder="_" v-model="$v.contact.firstname.$model"
						:state="$_validateState($v.contact.firstname)" name="firstname" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="6">
				<b-form-group :label="$t('phone')">
					<b-form-input type="number" placeholder="_" v-model="$v.contact.phone.$model"
						:state="$_validateState($v.contact.phone)" name="phone" />
					<b-form-invalid-feedback>{{ $t('phone-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
		<b-col lg="12" class="d-flex justify-content-end ml-2" v-if="edition">
			<b-button class="btn-icon mr-1" variant="info" @click="cancelEdit()">
				<Icon icon="cross" />
				{{ $t('cancel') }}
			</b-button>
			<b-button class="btn-icon" variant="primary" @click="confirmEdit()">
				<Icon icon="edit" />
				{{ $t('edit') }}
			</b-button>
		</b-col>
		<b-col lg="12" class="d-flex justify-content-end ml-2" v-else>
			<b-button class="btn-icon" variant="primary" @click="addContact()" id="buttonaddcontact">
				<Icon icon="add-person" />
				{{ $t('add-contact') }}
			</b-button>
		</b-col>
		<b-table head-variant="light" :striped="true" hover responsive selectable @row-selected="onRowSelected"
			v-if="location.contacts.$model && location.contacts.$model.length"
			:fields="fields" :items="location.contacts.$model" class="mt-4"
			:current-page="pagination.currentPage" :per-page="pagination.perPage" select-mode="single"
			ref="contactTable">
			<template #cell(action)="data">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon" @click="editContact(data.item)">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon" @click="deleteContact(data.item)">
								<Icon icon="trash" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import { validateTextRequired, validatePhone, validateEmail }
	from '@/utils/plugins/validations/vuelidate.js';
export default {
	name       : 'ContactFields',
	components : {
		Pagination
	},
	props : {
		location : {
			type     : Object,
			required : true,
		},
	},

	validations : {
		contact : {
			lastname  : validateTextRequired,
			mail      : validateEmail,
			job       : validateTextRequired,
			firstname : validateTextRequired,
			phone     : validatePhone,
		}
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('lastname'),
					key      : 'lastname',
					sortable : true,
				},
				{
					label    : this._i18n.t('email'),
					key      : 'mail',
					sortable : true,
				},
				{
					label    : this._i18n.t('job'),
					key      : 'job',
					sortable : true,
				},
				{
					label    : this._i18n.t('firstname'),
					key      : 'firstname',
					sortable : true,
				},
				{
					label    : this._i18n.t('phone'),
					key      : 'phone',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		},
	},

	data() {
		return {
			pagination : {
				perPage     : 10,
				currentPage : 1,
			},
			edition      : false,
			selectedRows : [],
			contact      : {
				lastname  : null,
				mail      : null,
				job       : null,
				firstname : null,
				phone     : null
			}
		};
	},
	methods : {
		addContact() {
			this.$v.contact.$touch();
			if(!this.$v.$invalid && this.checkContact()) {
				this.location.contacts.$model.push(this.contact);
				this.resetForm();
			}
		},
		resetForm() {
			Object.assign(this.$data, this.$options.data.apply(this));
			this.$nextTick(() => {
				this.$v.$reset();
			});
		},
		checkContact() {
			const findEmail = this.location.contacts.$model.findIndex((x) => {
				return x.mail == this.contact.mail;
			});
			if(findEmail != -1) {
				this.$_makeToast('danger', this._i18n.t('contact-email-already-present'), this._i18n.t('error'));
				return false;
			}
			const findPhone = this.location.contacts.$model.findIndex((x) => {
				return x.phone == this.contact.phone;
			});
			if(findPhone != -1) {
				this.$_makeToast('danger', this._i18n.t('contact-phone-already-present'), this._i18n.t('error'));
				return false;
			}
			return true;
		},
		editContact(contact) {
			this.edition = true;
			this.contact = this.$_clone(contact);
		},
		cancelEdit() {
			this.edition = false;
			this.resetForm();
		},
		confirmEdit() {
			if(!this.$v.$invalid && this.checkContact) {
				const findIndex = this.location.contacts.$model.findIndex((x) => {
					return x.mail == this.contact.mail;
				});
				this.location.contacts.$model[findIndex] = this.contact;
				this.resetForm();
				this.$refs.contactTable.refresh();
			}
		},
		deleteContact(contact) {
			const findIndex = this.location.contacts.$model.findIndex((x) => {
				return x.mail == contact.mail;
			});
			this.location.contacts.$model.splice(findIndex, 1);
		},
		onRowSelected(items) {
			this.selectedRows = items;
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>
<i18n>
{
	"fr": {
		"contact-phone-already-present": "Ce téléphone est déjà attribué à un contact.",
		"contact-email-already-present": "Cet email est déjà attribué à un contact."
	},
	"en": {
		"contact-phone-already-present": "This phone is already assigned to a contact.",
		"contact-email-already-present": "This email is already assigned to a contact."
	}
}
</i18n>