<template>
	<div class="destination" :class="{'active': value.name}">
		<div class="container-info" :class="{'selected': isSelected}" v-if="value.name"
			@click="$emit('set-selected', 'destination')">
			{{ value.name }}
			<div class="d-flex" @click.stop="clearDestination" v-if="canDeleteDestination">
				<Icon icon="cross" />
			</div>
		</div>
		<MultipleSelect v-else :options="getLocations" :placeholder="$t('arrival-terminal')" track="name"
			:button="{text: $t('create-a-terminal')}" @click-btn="addLocation" :searchable="true"
			v-model="destinationClone" />
	</div>
</template>
<script>
import uniqid from 'uniqid';
export default {
	name  : 'Destination',
	props : {
		locations : {
			required : true,
			type     : Array,
		},
		value : {
			required : true,
			type     : null
		},
		canDeleteDestination : {
			required : true,
			type     : Boolean
		},
		originName : {
			required : true,
			type     : String
		},
		isSelected : {
			required : true,
			type     : Boolean
		},
	},
	data() {
		return {
			destinationClone : null,
		};
	},
	methods : {
		addLocation(location) {
			const id = uniqid();
			this.$emit('push-location', location, id);
			this.destinationClone = {
				name           : location,
				locationIdtemp : id
			};
		},
		clearDestination() {
			this.destinationClone = null;
			this.$emit('set-selected', null);
		}
	},
	computed : {
		getLocations() {
			const index = this.locations.findIndex((x) => {
				return x.name == this.originName;
			});
			const locations = this.$_clone(this.locations);
			if(index != -1) {
				locations.splice(index, 1);
			}
			return locations;
		}
	},
	watch : {
		destinationClone(val) {
			if(val) {
				this.$emit('input', val);
			} else {
				this.$emit('input', {});
			}
		},
	},
	created() {
		if(this.value.name)
			this.destinationClone = this.value;
	}
};
</script>