import Moment from 'moment';
const VerticalVisualization = {
	methods : {
		getDaysPlusArrival(transportPlan, currentSegment) {
			if(transportPlan.timetable && transportPlan.timetable.departure_time) {
				const hours_minutes = transportPlan.timetable.departure_time.split(':');
				const start = Moment().startOf('month').hour(hours_minutes[0]).minute(hours_minutes[1]);
				start.set({ second : 0 });
				const end = Moment.utc(this.$_clone(start));
				const segments = transportPlan.segments;
				for (let i = 0; i < segments.length; i++) {
					const segment = segments[i];
					let interoperability_time = 0;
					if(i != 0) {
						interoperability_time = segment.handling.time;
					}
					if(currentSegment._id == segment._id) {
						end.add(segment.timetable.duration, 'minutes');
						end.add(interoperability_time, 'hours');
						return end.format('d') - start.format('d');
					} else {
						end.add(segment.timetable.duration, 'minutes');
						end.add(interoperability_time, 'hours');
					}
				}
			}
		},
		getDaysPlusDeparture(transportPlan, currentSegment) {
			if(transportPlan.timetable && transportPlan.timetable.departure_time) {
				const hours_minutes = transportPlan.timetable.departure_time.split(':');
				const start = Moment().startOf('month').hour(hours_minutes[0]).minute(hours_minutes[1]);
				start.set({ second : 0 });
				const end = this.$_clone(start);
				const segments = transportPlan.segments;
				for (let i = 0; i < segments.length; i++) {
					const segment = segments[i];
					let interoperability_time = 0;
					if(i != 0) {
						interoperability_time = segment.handling.time;
					}
					if(currentSegment._id == segment._id) {
						end.add(interoperability_time, 'hours');
						return end.format('d') - start.format('d');
					} else {
						end.add(segment.timetable.duration, 'minutes');
						end.add(interoperability_time, 'hours');
					}
				}
			}
		},
		getHandlingTimePerTruck(segment) {
			return Math.round(segment.handling.time / segment.handling.units);
		},
	}
};
export default VerticalVisualization;