<template>
	<div class="origin">
		<div class="container-info" :class="{'selected': isSelected}"
			@click="$emit('set-selected', 'origin')">
			{{ origin.name }}
		</div>
	</div>
</template>
<script>
export default {
	props : {
		isSelected : {
			required : true,
			type     : Boolean
		},
		origin : {
			required : true,
			type     : Object
		}
	}
};
</script>