<template>
	<main id="add-collaborator">
		<div class="d-flex justify-content-between align-items-center">
			<h1 class="mb-4">
				{{ $t('create-collaborator') }}
			</h1>
			<b-button class="btn-icon" variant="info"
				@click="$router.push({name: 'CollaboratorList'})">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-card no-body>
			<b-card-header class="primary" />
			<b-card-body>
				<CollaboratorsFields :collaborator="$v.collaborator" type="create" />
			</b-card-body>
		</b-card>
		<div class="d-flex justify-content-center">
			<b-button class="btn-icon mt-3" variant="primary"
				@click="saveCollaborator" id="saveCollaborator">
				<Icon icon="save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</main>
</template>

<script>
import CollaboratorsFields from '../collaborators/components/CollaboratorsFields.vue';
import { validateEmail, validateTextRequired, validateText, validateRequired, validatePhone,
	validatePostalCode, validateConfirmEmail, validatePassword,
	validateConfirmPassword } from '@/utils/plugins/validations/vuelidate';


export default {
	name       : 'CreateCollaborator',
	components : {
		CollaboratorsFields
	},

	data() {
		return {

			collaborator : {
				email        : null,
				confirmEmail : null,
				password     : null,
				repassword   : null,
				firstname    : null,
				lastname     : null,
				phone        : null,
				language     : null,
				scope        : null,
				emailconf     : true,
				address      : {
					street     : null,
					city       : null,
					postalCode : null,
					country    : null,
				}
			},

		};
	},
	validations() {
		const validations = {
			collaborator : {
				firstname    : validateTextRequired,
				lastname     : validateTextRequired,
				email        : validateEmail,
				confirmEmail : validateConfirmEmail,
				password     : validatePassword,
				repassword   : validateConfirmPassword,
				phone        : validatePhone,
				language     : validateRequired,
				scope        : validateRequired,
				emailconf     : validateRequired,
				address      : {
					street     : validateTextRequired,
					country    : validateRequired,
					city       : validateTextRequired,
					postalCode : validatePostalCode,
					state      : validateText,
					lieu       : validateText,

				}
			}
		};
		return validations;
	},
	methods : {
		saveCollaborator() {
			this.$v.collaborator.$touch();
			if (!this.$v.collaborator.$invalid) {
				this.$store.commit('collaborator/set', this.collaborator);
				const collaborator = this.setcollaboratorInformation();
				this.$store.dispatch('collaborator/CREATE', collaborator)
					.then(() => {
						this.$router.push({ name : 'CollaboratorList' });
					})
					.catch((err) => {
						this.$_makeToast('danger',
							this._i18n.t(err),
							this._i18n.t('error')
						);
					});
			}
		},
		setcollaboratorInformation() {
			const collaborator = this.$_clone(this.collaborator);
			const phones = [];
			const scopes = [];
			phones[0] = collaborator.phone.slice();
			scopes[0] = collaborator.scope.slice();

			collaborator.phone = phones;
			collaborator.scope = scopes;

			if(collaborator.address && collaborator.address.country) {
				collaborator.address.country = collaborator.address.country.alpha2;
			}


			delete collaborator.sameAddress;
			delete collaborator.confirmEmail;
			return collaborator;
		}
	}
};
</script>
<style lang="scss" scoped>
.card-body {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.card-body fieldset {
	width: calc(50% - 30px);
	margin: 0 15px 1rem 15px;
}
</style>