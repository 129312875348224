<template>
	<main id="finance">
		<Table :items="items" :fields="fields" :loading="loading" @load="load()" :pagination="pagination"
			:title="$t('finance')">
			<template v-slot:filters>
				<Clients />
				<Roads />
				<Year />
				<PeriodType />
				<PeriodDef />
			</template>
			<template v-slot:action="data">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li v-if="data.item.preinvoice_status !== 'WAIT'">
							<b-button variant="primary" class="btn-round-icon">
								<Icon icon="download" />
							</b-button>
						</li>
						<li v-if="data.item.preinvoice_status === 'WAIT'">
							<b-button variant="primary" class="btn-round-icon"
								@click="$bvModal.show('modal-add-pre-invoice')">
								<Icon icon="receipt" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
			<template v-slot:route="data">
				<span v-if="data.item.route"> {{ data.item.route.sites | route }} </span>
				<span v-else> {{ $t('all') }}</span>
			</template>
			<template v-slot:cost="data">
				<span v-if="data.item.route"> {{ data.item.route.cost }} €</span>
				<span v-else> {{ data.item.cost }} € </span>
			</template>
			<template v-slot:transported_weight="data">
				<span> {{ data.item.transported_weight }} T </span>
			</template>
			<template v-slot:preinvoice_status="data">
				<span> {{ $t(data.item.preinvoice_status) }} </span>
			</template>
			<template v-slot:period_type="data">
				{{ $t(data.item.period_type) }} {{ data.item.period_number }}
			</template>
			<template v-slot:total_cost="data">
				<span v-if="data.item.route"> {{ data.item.route.cost * data.item.transported_weight }} €</span>
				<span v-else> {{ data.item.cost * data.item.transported_weight }} €</span>
			</template>
			<template v-slot:preinvoice_generation_date="data">
				{{ data.item.preinvoice_generation_date | getDateMonthYear }}
			</template>
			<template v-slot:preinvoice_send_date="data">
				{{ data.item.preinvoice_send_date | getDateMonthYear }}
			</template>
			<template v-slot:preinvoice_valid_date="data">
				{{ data.item.preinvoice_valid_date | getDateMonthYear }}
			</template>
		</Table>
		<Modal id="modal-add-pre-invoice" centered :title="$t('addPreInvoice')">
			<template v-slot:content>
				<p class="text-initial">
					{{ $t('ask-preinvoice') }}
				</p>
			</template>
		</Modal>
	</main>
</template>

<script>
import { Clients, Roads } from '@/components/filters/components';
import { Year, PeriodDef, PeriodType } from '@/components/filters/components/finance';
import * as data from '@/utils/fakeData/ERAMET/mockPreinvoice.json';
const preinvoiceMocker = data.default;
export default {
	name       : 'Finance',
	components : {
		Clients,
		Roads,
		Year,
		PeriodDef,
		PeriodType,
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('trains'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('period-type'),
					key      : 'period_type',
					sortable : true,
				},
				{
					label    : this._i18n.t('client'),
					key      : 'client.name',
					sortable : true,
				},
				{
					label    : this._i18n.t('routes'),
					key      : 'route',
					sortable : true,
				},
				{
					label    : this._i18n.t('transported-weight'),
					key      : 'transported_weight',
					sortable : true,
				},
				{
					label : this._i18n.t('unit-cost'),
					key   : 'cost',
				},
				{
					label : this._i18n.t('total-cost'),
					key   : 'total_cost',
				},
				{
					label : this._i18n.t('preinvoice-code'),
					key   : 'preinvode_code',
				},
				{
					label : this._i18n.t('preinvoice-status'),
					key   : 'preinvoice_status',
				},
				{
					label : this._i18n.t('preinvoice-generation-date'),
					key   : 'preinvoice_generation_date',
				},
				{
					label : this._i18n.t('preinvoice-send-date'),
					key   : 'preinvoice_send_date',
				},
				{
					label : this._i18n.t('preinvoice-valid-date'),
					key   : 'preinvoice_valid_date',
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			loading          : false,
			items            : preinvoiceMocker,
			preinvoiceModal  : false,
			pagination       : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	},
	methods : {
		load() {

		}
	},
	created() {
		this.pagination.totalRows = this.items.length;
	},
	filters : {
		route(value) {
			return value.map(site=> site.name).join(' > ');
		}
	}
};
</script>
