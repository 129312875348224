import { HTTP } from '../../http-common';
import SecureLS from 'secure-ls';
var ls = new SecureLS();
const state = () => ({
	user : null,
});

const getters = {
	get : state => state.user || ls.get('user')
};

const mutations = {
	set : (state, user) => {
		state.user = user;
		ls.set('user', user);
	}
};
const actions = {
	async LOGIN(context, user) {
		const basic = 'basic ' + btoa(`${user.email}:${user.password}`);
		await HTTP.get('/users/login', {
			headers        : {
				Authorization : basic
			}
		}).then(res => {
			context.commit('set', res.data);
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	LOGOUT(context) {
		context.commit('set', null);
	},
	async getInformation(context, id) {
		return await HTTP.get('/users/' + id);
	}
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};