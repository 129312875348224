<template>
	<form @submit="submit" autocomplete="off">
		<b-form-group :label="$t('address')">
			<b-input placeholder="_" v-model="$v.register.company.address.street.$model"
				:state="$_validateState($v.register.company.address.street)" name="address" />
			<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('city')">
			<b-input placeholder="_" v-model="$v.register.company.address.city.$model"
				:state="$_validateState($v.register.company.address.city)" name="city" />
			<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('postal-code')">
			<b-input placeholder="_" v-model="$v.register.company.address.postalCode.$model"
				:state="$_validateState($v.register.company.address.postalCode)" name="postalcode" />
			<b-form-invalid-feedback>{{ $t('postal-code-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('country')" :class="{ 'form-group--error': $v.register.company.address.country.$error,
			'form-group--valid': $v.register.company.address.country.$dirty
				&& !$v.register.company.address.country.$error }" name="country">
			<multi-select v-model="$v.register.company.address.country.$model"
				:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
				select-label="" placeholder="_" :searchable="true" label="name" track-by="name"
				:options="countries" :hide-selected="false">
				<template slot="noResult">
					{{ $t('no-result') }}
				</template>
			</multi-select>
			<b-form-invalid-feedback>
				{{ $t('field-required-err') }}
			</b-form-invalid-feedback>
		</b-form-group>
		<div class="d-flex justify-content-between w-100 mt-3">
			<b-button variant="info" class="w-49" @click="previousStep">
				{{ $t('previous') }}
			</b-button>
			<b-button variant="primary" class="w-49" type="submit" @click="submit" id="nextaddress">
				{{ $t('next') }}
			</b-button>
		</div>
	</form>
</template>

<script>
import { validateTextRequired, validateRequired, validatePostalCode } from '@/utils/plugins/validations/vuelidate';
import countriesMixins from '@/mixins/countries.js';
export default {
	name     : 'SocietyAddress',
	mixins : [countriesMixins],
	props  : {
		register : {
			type     : Object,
			required : true
		},
	},
	validations : {
		register : {
			company : {
				address    : {
					street     : validateTextRequired,
					city       : validateTextRequired,
					country    : validateRequired,
					postalCode : validatePostalCode
				}
			}
		}
	},
	methods : {
		previousStep() {
			this.$emit('go-to-step', 'company');
		},
		submit($event) {
			$event.preventDefault();
			this.$v.register.$touch();
			if (!this.$v.register.$invalid) {
				this.$store.commit('registers/register', this.register);
				this.$emit('go-to-step', 'user');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/.multiselect__content-wrapper {
	bottom: 100%;
	max-height: 200px !important;
	border-top-left-radius: $border-radius;
	border-bottom-left-radius: 0;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: 0;
}
/deep/.multiselect--active .multiselect__tags {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: $border-radius !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: $border-radius !important;
}
</style>