import { HTTP } from '../../http-common';

const state = () => ({
	locations : [],
	location  : null
});

const getters = {
	list     : state => state.locations,
	location : state => state.location
};

const mutations = {
	SET(state, payload) {
		state.locations = payload;
	},
	location(state, payload) {
		state.location = payload;
	}
};

const actions = {
	async createLocations(context, payload) {
		await HTTP.post('/locations', payload).then().catch(err => {
			throw err.response.data.message;
		});
	},

	loadLocations(ontext, query) {
		let url = '/locations';
		if(query) {
			url += query;
		}
		return HTTP.get(url).then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async update(context, payload) {
		await HTTP.put('/locations/' + payload._id, payload.location).then(() => {
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async getLocation(context, _id) {
		await HTTP.get('/locations/' + _id).then((res) => {
			context.commit('location', res.data);
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async delete(context, _id) {
		await HTTP.delete('/locations/' + _id).then().catch(err => {
			throw err.response.data.message;
		});
	},
	getLocationsByCompanyId(context, companyId) {
		return HTTP.get('/locations?handlerId=' + companyId).then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	}
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};