<template>
	<div>
		<b-button variant="primary" v-b-toggle="'pricing'" class="btn-collapse">
			{{ $t('pricing-conditions') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="pricing" accordion="accordion">
			<b-card>
				<div class="technical-informations w-100" v-if="selectedCondition
					&& selectedCondition.unitsPenalties == 'time'">
					<ul>
						<li>
							<span>{{ $t(selectedCondition.penaltyType) }}
								<div v-if="selectedCondition.range && selectedCondition.range[0]"
									class="text-lowercase ml-1">
									{{ $t('in') }} {{ $t(selectedCondition.range[0].periodicity) }}
								</div>
							</span>
							<span v-for="(value, index) in selectedCondition.range" :key="index">
								{{ value.init }} - {{ value.fin }}
							</span>
						</li>
						<li>
							<span>{{ $t('penality') }}
								<div v-if="selectedCondition.range && selectedCondition.range[0]"
									class="text-lowercase ml-1">
									{{ $t('in') }} {{ $t(selectedCondition.range[0].type) }}
								</div>
							</span>
							<span v-for="(value, index) in selectedCondition.range" :key="index">
								{{ value.price }}
							</span>
						</li>
					</ul>
				</div>
				<div class="technical-range w-100" v-if="selectedCondition &&
					selectedCondition.unitsPenalties == 'threshold'">
					<ul>
						<li v-for="(value, index) in selectedCondition.range.list" :key="index">
							<span>{{ $t('threshold') }}</span>
							<span>{{ value.init }}%</span>
							<span>{{ $t(value.periodicity) }}</span>
							<span>{{ value.price }} €</span>
							<span>{{ $t(value.value) }}</span>
						</li>
					</ul>
				</div>
				<Results v-if="rates.length" :rates="rates" @on-selected-row="onSelectedRow"
					:segments="segments" />
			</b-card>
		</b-collapse>
	</div>
</template>
<script>
import { Results } from '../create/pricing/';
export default {
	components : {
		Results
	},
	props : {
		segments : {
			type     : Array,
			required : true
		},
		rates : {
			type     : Array,
			required : true,
		}
	},
	data() {
		return {
			selectedCondition : {}
		};
	},
	methods : {
		onSelectedRow(item) {
			this.selectedCondition = item;
		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.technical-informations {
	max-width: 100%;
	overflow-x: auto;
}
.technical-informations li span {
	width: 20% !important;
	display: flex;
	justify-content: center;
}
</style>