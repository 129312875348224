<template>
	<div id="state-order">
		<Header />
		<b-card-body class="rounded">
			<b-row>
				<b-col v-if="operation.segment" lg="6" md="12">
					<div class="group-inline">
						<label>{{ $t('segment') }}</label>
						<div class="content text-overflow">
							{{ operation.segment.origin.name }} > {{ operation.segment.destination.name }}
						</div>
					</div>
				</b-col>
				<b-col lg="6" md="12">
					<div class="group-inline">
						<label>{{ $t('site') }}</label>
						<div class="content">
							{{ operation.location.name }}
						</div>
					</div>
				</b-col>
			</b-row>
			<form>
				<b-row>
					<b-col xs="6">
						<div class="group-inline">
							<label for="transport-unit">{{ $t(operation.segment.volumetry.transportUnit) }}</label>
							<b-form-input type="number" class="content" id="transport-unit" placeholder="_"
								:value="operation.segment.volumetry.units" />
						</div>
					</b-col>
					<!-- <b-col xs="6">
						<div class="group-inline">
							<label for="pallets">{{ $t('pallets') }}</label>
							<b-form-input type="number" class="content" id="pallets" placeholder="_" value="800" />
						</div>
					</b-col> -->
					<b-col lg="12">
						<div class="group-inline">
							<label for="incidents">{{ $t('incidents') }}</label>
							<div class="content justify-content-between">
								<div>{{ operation.incidents.length }}</div>
								<div v-if="operation.incidents.length" class="pointer d-flex align-items-center"
									@click="$router.push('/operation/list-incidents')">
									<Icon icon="chevron-right" />
								</div>
							</div>
						</div>
					</b-col>
					<b-col sm="6">
						<b-form-group :label="$t('date')">
							<b-form-input v-model="state.date" type="date" placeholder="_"
								:min="toDateInput(new Date())" />
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group :label="$t('hour')">
							<b-form-input v-model="state.time" type="time" placeholder="_" />
						</b-form-group>
					</b-col>
				</b-row>
			</form>
			<ul class="child-mt-1 mt-3">
				<li>
					<b-button variant="white" class="btn-round-icon">
						<Icon icon="download" />
					</b-button>
					<div>{{ $t('list-of-wagons') }}</div>
				</li>
				<li>
					<b-button variant="white" class="btn-round-icon">
						<Icon icon="download" />
					</b-button>
					<div>{{ $t('waybill') }}</div>
				</li>
				<li>
					<b-button variant="primary" class="btn-round-icon">
						<Icon icon="upload" />
					</b-button>
					<div>{{ $t('list-of-packing') }}</div>
				</li>
			</ul>
		</b-card-body>
		<Footer :previous-step-path="operation.segment ? '/operation/select-segment' : '/operation/select-order'">
			<b-button variant="outline-danger" @click="$router.push('/operation/incident')">
				{{ $t('incident') }}
			</b-button>
			<b-button variant="primary" @click="submit">
				<span v-if="operation.order">
					{{ $t('charged') }}
				</span>
				<span v-else-if="operation.segment">
					{{ $t('departure') }}
				</span>
			</b-button>
		</Footer>
	</div>
</template>
<script>
import { Header, Footer } from './components/';
export default {
	data() {
		return {
			state : {
				date        : this.toDateInput(new Date()),
				time        : this.toTimeInput(new Date())
			}
		};
	},
	components : {
		Header,
		Footer
	},
	computed : {
		operation() {
			return this.$store.getters['operation/get'];
		},
		currentSegment() {
			const currentPosition = this.$store.getters['operation/get'].order.current_position.segment_id;
			const segments = this.$store.getters['operation/get'].order.trs[0].segments;
			return segments.find((seg) => seg._id == currentPosition);
		}
	},
	methods : {
		submit() {
			this.$store.commit('operation/setState', this.state);
			this.$router.push('/operation/confirmation');
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.form-group {
	margin-bottom: 0;
}
form {
	border-bottom: 1px solid $bootstrap-grey;
	padding-bottom: 1rem;
}
ul li {
	display: flex;
	align-items: center;
}
ul li button {
	margin-right: 0.5rem;
}
</style>
<i18n>
{
	"fr": {
		"charged": "Chargé",
		"list-of-wagons": "Liste des wagons",
		"waybill": "Lettre de voiture",
		"list-of-packing": "Liste de colisages"
	},
	"en": {
		"charged": "Charged",
		"list-of-wagons": "List of wagons",
		"waybill": "Waybill",
		"list-of-packing": "List of packing"
	}
}
</i18n>