export default {
	computed : {
		languages() {
			return [
				{
					value : 'fr',
					text  : this._i18n.t('french')
				},
				{
					value : 'en',
					text  : this._i18n.t('english')
				},
			];
		},
	},
};