<template>
	<b-row>
		<b-col lg="6" md="12">
			<b-form class="child-mb-3">
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('longitude')">
							<b-form-input type="number" placeholder="_" v-model="longitude" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('latitude')">
							<b-form-input type="number" placeholder="_" v-model="latitude" />
						</b-form-group>
					</b-col>
				</b-row>
				<MultipleSelect :options="[]" :label="$t('port')" v-model="maritimeInformation.station" />
				<MultipleSelect :options="[]" :label="$t('connecting-platform')"
					v-model="maritimeInformation.station" />
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('number-of-docks')">
							<b-form-input placeholder="_" v-model="maritimeInformation.platforms" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('platform-length')">
							<b-form-input placeholder="_" v-model="maritimeInformation.length" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('treatment-capacity')">
							<b-form-input placeholder="_" v-model="maritimeInformation.capacity" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</b-col>
		<b-col lg="6" md="12">
			<Schedules :schedules="maritimeInformation.openingHours"
				:title="$t('opening-hours-days')" @set-schedules="setSchedules" id="maritime" />
			<b-form-checkbox value="true" class="mt-3" v-model="sameSchedules">
				{{ $t('resume-the-general-timetable') }}
			</b-form-checkbox>
		</b-col>
	</b-row>
</template>
<script>
import Schedules from '@/components/common/Schedules.vue';
export default {
	props : {
		information : {
			required : true,
			type     : Object,
		}
	},
	components : {
		Schedules,
	},
	data() {
		return {
			default : {
				typology           : 'SEA',
				station            : null,
				connectingPlatform : null,
				geoLocation        : {},
				openingHours       : [],
				platforms          : null,
				capacity           : null,
				length             : null,
			},
			latitude      : null,
			longitude     : null,
			sameSchedules : null
		};
	},
	computed : {
		maritimeInformation() {
			if (this.information.modals && this.information.modals.length > 0) {
				const find = this.information.modals.find(mod=> mod.typology === 'SEA');
				if(find) {
					return find;
				}
			}
			return this.default;
		},
		getMainSchedules() {
			return this.information.openingHours;
		},
		optionsStation() {
			return {
				searchable  : true,
				label       : this._i18n.t('port'),
				track       : null,
				options     : [],
				validation : this.maritimeInformation.station
			};
		},
		optionsPlatform() {
			return {
				searchable  : true,
				label       : this._i18n.t('connecting-platform'),
				track       : null,
				options     : [],
				validation : this.maritimeInformation.station
			};
		},
	},
	watch : {
		getMainSchedules(val) {
			if(this.sameSchedules) {
				this.maritimeInformation.openingHours = val;
			}
		},
		sameSchedules(val) {
			if(val) {
				this.maritimeInformation.openingHours = this.getMainSchedules;
			} else {
				this.maritimeInformation.openingHours = [];
			}
		},
		longitude(val) {
			if(val) {
				if(this.maritimeInformation && this.maritimeInformation.geoLocation) {
					if(this.maritimeInformation.geoLocation.coordinates
					&& this.maritimeInformation.geoLocation.coordinates.length > 0) {
						this.maritimeInformation.geoLocation.coordinates[0] = val;
					} else {
						this.maritimeInformation.geoLocation = { coordinates : [val, null] };
					}
				} else {
					this.maritimeInformation.geoLocation = { coordinates : [val, null] };
				}
			}
		},
		latitude(val) {
			if(val) {
				if(this.maritimeInformation && this.maritimeInformation.geoLocation) {
					if(this.maritimeInformation.geoLocation.coordinates
					&& this.maritimeInformation.geoLocation.coordinates.length > 1) {
						this.maritimeInformation.geoLocation.coordinates[1] = val;
					} else {
						this.maritimeInformation.geoLocation = { coordinates : [null, val] };
					}
				} else {
					this.maritimeInformation.geoLocation = { coordinates : [null, val] };
				}
			}
		},
		'maritimeInformation' : {
			handler(val) {
				const index = this.information.modals.findIndex(mod=> mod.typology === 'SEA');
				if (index != -1) {
					this.information.modals[index] = val;
				} else {
					this.information.modals.push(val);
				}
			},
			deep : true
		},
	},
	methods : {
		setSchedules(schedules) {
			this.maritimeInformation.openingHours = schedules;
		},
		setMaritimeInformation() {
			if (this.information.modals && this.information.modals.length > 0) {
				const find =  this.information.modals.find(mod=> mod.typology === 'SEA');
				if (find) {
					if (find.geoLocation && find.geoLocation.coordinates) {
						this.longitude = find.geoLocation.coordinates[0];
						this.latitude = find.geoLocation.coordinates[1];
					}
					return find;
				}
			}
			return {
				typology           : 'SEA',
				station            : null,
				connectingPlatform : null,
				geoLocation        : {},
				openingHours       : [],
				platforms          : null,
				capacity           : null,
				length             : null
			};
		}
	}
};
</script>