<template>
	<div id="confirmation-segment">
		<Header />
		<b-card no-body>
			<b-card-header>Confirmation</b-card-header>
			<b-card-body class="grey">
				<b-row class="justify-content-center">
					<b-col lg="6" md="12">
						<b-list-group>
							<b-list-group-item v-if="operation.segment">
								<label>{{ $t('segment') }}</label>
								<div class="d-flex align-items-center content">
									<div v-if="operation.segment.type == 'rail'" class="icon trains primary" />
									<div v-if="operation.segment.type == 'road'" class="icon trains truck" />
									<div class="text-overflow">
										{{ operation.segment.origin.name }} > {{ operation.segment.destination.name }}
									</div>
								</div>
							</b-list-group-item>
							<b-list-group-item>
								<label>{{ $t('site') }}</label>
								{{ operation.location.name }}
							</b-list-group-item>
							<b-list-group-item>
								<label>{{ $t('wagons') }}</label>
								30
							</b-list-group-item>
							<!-- <b-list-group-item>
								<label>{{ $t('pallets') }}</label>
								800
							</b-list-group-item> -->
							<b-list-group-item>
								<label>{{ $t('date') }}</label>
								{{ operation.state.date | dateInputToFullDate }}
							</b-list-group-item>
							<b-list-group-item>
								<label>{{ $t('hour') }}</label>
								{{ operation.state.time }}
							</b-list-group-item>
						</b-list-group>
					</b-col>
					<b-col lg="6" md="12" v-if="operation.incidents && operation.incidents.length">
						<b-card no-body>
							<b-card-header>{{ $t('incidents') }}</b-card-header>
							<b-list-group>
								<b-list-group-item v-for="(incident, index) in operation.incidents" :key="index"
									class="d-flex justify-content-between align-items-center">
									<p class="text-overflow">
										<span class="color-primary">{{ index + 1 }}. </span>{{ incident.title }}
									</p>
									<div class="pointer" @click="editIncident(index)">
										<Icon icon="chrevron-forward" />
									</div>
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-col>
					<div class="mt-3 d-flex w-100">
						<b-col sm="6" class="justify-content-end d-flex">
							<b-button variant="white" @click="$router.push('/operation/state')">
								{{ $t('edit') }}
							</b-button>
						</b-col>
						<b-col sm="6">
							<b-button variant="primary" @click="$router.push('/')">
								{{ $t('valid') }}
							</b-button>
						</b-col>
					</div>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>
import Header from './components/Header';
export default {
	data() {
		return {
		};
	},
	components : {
		Header
	},
	computed : {
		operation() {
			return this.$store.getters['operation/get'];
		}
	},
	methods : {
		editIncident(index) {
			this.$router.push({
				path   : '/operation/incident',
				query : { index : index }
			});
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.list-group {
	font-weight: 600;
}
.list-group-item {
	display: flex;
}
.list-group-item .content {
	width: calc(100% - 100px);
}
.list-group-item label {
	color: var(--primary);
	width: 100px;
	margin: 0;
}
</style>