<template>
	<div class="handling" :class="{'active': value}" :id="id">
		<div class="container-info" @click="$emit('set-selected', 'handling')"
			:class="{'selected': isSelected}" v-if="value">
			{{ $t(value) }}
			<div @click.stop="clearHandling" class="d-flex">
				<Icon icon="cross" />
			</div>
		</div>
		<MultipleSelect v-else :options="opt" :placeholder="$t('service')" v-model="handlingClone"
			track="text" />
	</div>
</template>
<script>
export default {
	props : {
		value : {
			required : true,
			type     : null
		},
		isSelected : {
			required : true,
			type     : Boolean
		},
		id : {
			required : true,
			type     : String
		},
	},
	data() {
		return {
			handlingClone : this.value
		};
	},
	watch : {
		handlingClone(val) {
			if(val) {
				this.$emit('input', val.value);
			} else {
				this.$emit('input', null);
			}
		}
	},
	computed : {
		opt() {
			return [
				{
					text  : this._i18n.t('loading'),
					value : 'loading'
				},
				{
					text  : this._i18n.t('unloading'),
					value : 'unloading'
				},
			];
		}
	},
	methods : {
		clearHandling() {
			this.handlingClone = null;
			this.$emit('set-selected', null);
		}
	}
};
</script>
<style lang="scss" scoped>
.handling {
	max-width: 270px;
}
.container-info {
	width: auto !important;
}
</style>