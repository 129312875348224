import Dashboard from '@/views/dashboard/Dashboard.vue';
import GenericSegments from '@/views/GenericSegments.vue';
import DatedSegments from '@/views/DatedSegments.vue';
import Sillons from '@/views/Sillons.vue';
import { Contracts, VisualizationContracts, ListContracts, EditContracts, CreateContract } from  '@/views/contracts';
import Road from '../views/Road.vue';
import Trains from '../views/Trains.vue';
import { Wagons, ListWagons, CreateWagon, UpdateWagon, ViewWagon } from '../views/wagons';
import Finance from '../views/finance/ListPreInvoice.vue';
import TrackCO2 from '../views/TrackCO2.vue';
import Requester from '../views/Requester.vue';
import Company from '../views/Company.vue';
import { Partners, ListPartners, CreatePartner, SearchPartner, UpdatePartner } from '../views/partners';
import { Collaborators, ListCollaborators, CreateCollaborator, UpdateCollaborator,
	ViewCollaborator } from '../views/collaborators';
import { Registrations, ListRegistrations, SendRegistration } from '../views/registrations';
import { Locations, ListLocations, CreateLocation, UpdateLocation } from '@/views/locations';
import { Incidents, ListIncidents } from '@/views/incidents';

export const admin = [
	{
		path       : '/dashboard',
		name       : 'dashboard',
		component  : Dashboard,
		icon       : 'dashboard',
		title     : 'dashboard',
		index      : 1,
		meta       : {
			permission : ['super', 'charger', 'admin', 'operator']
		}
	},
	{
		path       : '/generic-segments',
		name       : 'genericSegments',
		component  : GenericSegments,
		meta       : {
			permission : ['super']
		}
	},
	{
		path       : '/dated-segments',
		name       : 'datedSegments',
		component  : DatedSegments,
		meta       : {
			permission : ['super']
		}
	},
	{
		path       : '/sillons',
		name       : 'sillons',
		component  : Sillons,
		meta       : {
			permission : ['super']
		}
	},
	{
		path      : '/contracts',
		name      : 'contracts',
		index  	  : 2,
		icon      : 'book',
		title     : 'contracts',
		component : Contracts,
		redirect  : '/contracts/list',
		children  : [
			{
				path      : 'list',
				name      : 'ListContracts',
				component : ListContracts,
				meta       : {
					permission : ['super', 'charger', 'admin']
				}
			},
			{
				path      : 'visualization/:id',
				name      : 'VisualizationContracts',
				component : VisualizationContracts,
				meta       : {
					permission : ['super', 'charger', 'admin', 'operator']
				}
			},
			{
				path      : 'edit/:step/:id',
				name      : 'EditContracts',
				component : EditContracts,
				meta       : {
					permission : ['super', 'charger', 'admin', 'operator']
				}
			},
			{
				path      : 'create/:step',
				name      : 'CreateContract',
				component : CreateContract,
				meta       : {
					permission : ['super', 'charger', 'admin', 'operator']
				}
			}
		],
		meta       : {
			permission : ['super', 'charger', 'admin', 'operator']
		}
	},
	{
		path       : '/road',
		name       : 'road',
		component  : Road,
		meta       : {
			permission : ['super']
		}
	},
	{
		path       : '/trains',
		name       : 'trains',
		component  : Trains,
		meta       : {
			permission : ['super']
		}
	},
	{
		path      : '/wagons',
		name      : 'wagons',
		icon      : 'wagon',
		title     : 'wagons',
		index     : 5,
		component : Wagons,
		redirect  : '/wagons/list',
		children  : [
			{
				path	     : 'list',
				name	     : 'WagonList',
				component    : ListWagons,
				meta       : {
					permission : ['super', 'charger', 'admin']
				}
			},
			{
				path		    : 'create-wagon',
				name		    : 'CreateWagon',
				component	    : CreateWagon,
				meta       : {
					permission : ['super', 'charger', 'admin']
				}
			},
			{
				path		    : 'update-wagon/:id',
				name		    : 'UpdateWagon',
				component	    : UpdateWagon,
				meta       : {
					permission : ['super', 'charger', 'admin']
				}
			},
			{
				path		    : 'view-wagon/:id',
				name		    : 'ViewWagon',
				component	    : ViewWagon,
				meta       : {
					permission : ['super', 'charger', 'admin']
				}
			},
		],
		meta       : {
			permission : ['super', 'charger', 'admin']
		}
	},
	{
		path       : '/finance',
		name       : 'finance',
		title     : 'finance',
		component  : Finance,
		icon       : 'finance',
		index      : 6,
		meta       : {
			permission : ['super', 'charger'],
		}
	},
	{
		path       : '/trackco2',
		name       : 'trackCO2',
		component  : TrackCO2,
		meta       : {
			permission : ['super']
		}
	},
	{
		path       : '/requester',
		name       : 'requester',
		component  : Requester,
		icon       : 'database',
		title     : 'requester',
		index      : 7,
		meta       : {
			permission : ['super']
		}
	},
	{
		path       : '/company',
		name       : 'company',
		component  : Company,
		meta       : {
			permission : ['super']
		}
	},
	{
		path      : '/partners',
		name      : 'partners',
		icon      : 'partners',
		title     : 'partners',
		index     :  8,
		component : Partners,
		redirect  : '/partners/list',
		children  : [
			{
				path      : 'list',
				name      : 'PartnerList',
				component : ListPartners,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
			{
				path      : 'create-partner',
				name      : 'CreatePartner',
				component : CreatePartner,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
			{
				path      : 'search-partner',
				name      : 'SearchPartner',
				component : SearchPartner,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
			{
				path		    : 'update-partner/:id',
				name		    : 'UpdatePartner',
				component	    : UpdatePartner,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
		],
		meta       : {
			permission : ['super', 'admin', 'charger']
		}
	},
	{
		path      : '/collaborators',
		name      : 'collaborators',
		index     : 9,
		title     : 'collaborators',
		icon      : 'team',
		component : Collaborators,
		redirect  : '/collaborators/list',
		children  : [
			{
				path      : 'list',
				name      : 'CollaboratorList',
				component : ListCollaborators,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
			{
				path      : 'create-collaborator',
				name      : 'CreateCollaborator',
				component : CreateCollaborator,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
			{
				path      : 'update-collaborator/:id',
				name      : 'UpdateCollaborator',
				component : UpdateCollaborator,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
			{
				path      : 'view-collaborator/:id',
				name      : 'ViewCollaborator',
				component : ViewCollaborator,
				meta       : {
					permission : ['super', 'admin', 'charger']
				}
			},
		],
		meta       : {
			permission : ['super', 'admin', 'charger']
		}
	},
	{
		path      : '/registrations',
		name      : 'registrations',
		icon      : 'registrations',
		title     : 'registrations',
		component : Registrations,
		redirect  : '/registrations/list',
		children  : [
			{
				path      : 'list',
				name      : 'RegistrationList',
				component : ListRegistrations,

				meta       : {
					permission : ['super']
				}
			},
			{
				path		    : 'send-registration/:id',
				name		    : 'SendRegistration',
				component	    : SendRegistration,

				meta       : {
					permission : ['super']
				}
			},
		],
		meta       : {
			permission : ['super']
		}
	},
	{
		path      : '/incidents',
		name      : 'incidents',
		icon      : 'alert',
		index     :  4,
		title     : 'incidents',
		component : Incidents,
		redirect  : '/incidents/list',
		meta       : {
			permission : ['super', 'operator', 'charger', 'admin'],
		},
		children  : [
			{
				path      : 'list',
				name      : 'IncidentsList',
				component : ListIncidents,
				meta       : {
					permission : ['super', 'operator', 'charger', 'admin'],
				}
			},
		],
	},
	{
		path      : '/locations',
		name      : 'locations',
		component : Locations,
		title     : 'locations',
		index     : 10,
		icon      : 'location',
		redirect  : 'locations/list',
		meta      : {
			permission : ['super', 'operator', 'charger', 'admin'],
		},
		children  : [
			{
				path	     : 'list',
				name	     : 'LocationList',
				component    : ListLocations,
				meta      : {
					permission : ['super', 'operator', 'charger', 'admin'],
				}
			},
			{
				path		    : 'create',
				name		    : 'CreateLocation',
				component	    : CreateLocation,
				meta      : {
					permission : ['super', 'operator', 'charger', 'admin'],
				}
			},
			{
				path		    : 'update/:id',
				name		    : 'UpdateLocation',
				component	    : UpdateLocation,
				meta      : {
					permission : ['super', 'operator', 'charger', 'admin'],
				}
			},
		],
	},
];
