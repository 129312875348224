<template>
	<div id="locations">
		<Table :items="locations" :fields="fields" :loading="loading" :pagination="pagination"
			:title="$t('locations')" @load="load">
			<template v-slot:buttons>
				<b-button variant="primary" @click="$router.push('create')" id="addlocation">
					{{ $t('add-location') }}
				</b-button>
			</template>
			<template v-slot:filters>
				<Search />
				<Status />
			</template>
			<template v-slot:typology="data">
				<span v-for="(modal, i) in filterModals(data.item.modals)" :key="i">
					<Icon :icon="findIconType(modal.typology)" />
				</span>
			</template>
			<template v-slot:action="data">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon" id="edit-btn"
								@click="$router.push('update/' + data.item._id)">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon" id="delete-btn"
								@click="showModalDelete(data.item)">
								<Icon icon="trash" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
			<template v-slot:terminal-type="data">
				<div v-if="data.item.interconnections && data.item.interconnections.length > 0"
					class="d-flex align-items-center justify-content-center">
					<div class="icon locations mr-1" />
					<span>{{ $t('terminal-agglomeration') }}</span>
				</div>
				<div v-else class="d-flex align-items-center justify-content-center">
					<Icon icon="location" class="mr-1" />
					{{ $t('terminal') }}
				</div>
			</template>
		</Table>
		<Modal id="modal-delete" @confirm="deleteLocation()">
			<template v-slot:content>
				<p class="text-initial" v-if="currentLocation">
					{{ $t('do-you-want-to-delete-the-location') + ' ' + currentLocation.name }} ?
				</p>
			</template>
		</Modal>
	</div>
</template>

<script>
import { Search, Status } from '@/components/filters/components';
import Table from '@/components/common/Table.vue';
export default {
	name       : 'Locations',
	components : {
		Table,
		Search,
		Status,
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('locations'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label        : this._i18n.t('name'),
					key          : 'name',
					sortable     : true,
				},
				{
					label        : this._i18n.t('terminal-type'),
					key          : 'terminal-type',
					sortable     : true,
				},
				{
					label    : this._i18n.t('typology'),
					key      : 'typology',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},
	methods : {
		filterModals(modals) {
			const modalsFiltered = [];
			for (let i = 0; i < modals.length; i++) {
				const modal = modals[i];
				if(this.findIconType(modal.typology)) {
					modalsFiltered.push(modal);
				}
			}
			return modalsFiltered;
		},
		load(query) {
			this.$store.dispatch('locations/loadLocations', query).then((res) => {
				if(res.pagination && res.pagination.length > 0) {
					this.locations = res.results;
					this.pagination.totalRows = res.pagination[0].count;
				}
				this.loading = false;
			}).catch((err) => {
				this.loading = false;
				this.$_makeToast('danger', err, this._i18n.t('error'));
			});
		},
		showModalDelete(location) {
			this.currentLocation = {
				_id   : location._id,
				name : location.name
			};
			this.$bvModal.show('modal-delete');
		},
		deleteLocation() {
			this.$store.dispatch('locations/delete', this.currentLocation._id).then(() => {
				this.$_makeToast('success', this._i18n.t('the-location') + ' ' + this.currentLocation.name + ' ' +
				this._i18n.t('has-been-deleted'), this._i18n.t('success'));
				this.$bvModal.hide('modal-delete');
				this.load();
			}).catch((err) => {
				this.$_makeToast('danger', err, this._i18n.t('error'));
			});
		},
		findIconType(text) {
			const opt = [
				{
					text : 'ROAD',
					icon : 'truck'
				},
				{
					text : 'RAIL',
					icon : 'train'
				},
				{
					text : 'AIR',
					icon : 'airplane'
				},
				{
					text : 'SEA',
					icon : 'boat'
				},
				{
					text : 'RIVER',
					icon : 'river'
				},
			];
			const find = opt.find((x) => x.text == text);
			if(find && find.icon) return find.icon;
			return false;
		},
	},
	data() {
		return {
			locations      : [],
			pagination : {
				perPage     : 20,
				totalRows  	: 0,
				currentPage : 1,
			},
			loading         : true,
			currentLocation : null
		};
	}
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>
<i18n>
{
	"fr": {
		"do-you-want-to-delete-the-location": "Voulez-vous supprimer le site",
		"has-been-deleted": "a été supprimé"
	},
	"en": {
		"do-you-want-to-delete-the-location": "Do you want to delete the location",
		"has-been-deleted": "has been deleted"
	}
}
</i18n>