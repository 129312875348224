<template>
	<div class="container-speedometer" :class="{'loading': loading}">
		<h2 :class="{'text-center color-primary my-2': true, 'color-yellow': threshold}">
			{{ circulation }}
		</h2>
		<div class="speedometer">
			<div class="arc" />
			<div class="arc-bg" :class="{'arc-cancel' : threshold}"
				:style="'transform: rotate(' + arcPrimaryDegree + 'deg);'" />
			<div class="arc-loading-wrap" v-if="loading" />
			<div class="arc-loading" v-if="loading" />
			<div v-if="threshold" class="arc-alert"
				:style="'transform: rotate(' + arcAlertDegree + 'deg);'" />
		</div>
		<h6 class="threshold" v-if="threshold">
			{{ $t('threshold') }} 1%
		</h6>
		<img src="@/assets/img/needle.jpg" class="needle" />
	</div>
</template>

<script>
export default {
	name  : 'Speedometer',
	props : {
		circulation : {
			type     : Number,
			required : true,
		},
		threshold : {
			type     : Number,
			required : false,
			default() {
				return 0;
			}
		},
		loading : {
			type     : Boolean,
			required : false,
			default() {
				return false;
			}
		},
		circulationPlanned : {
			type     : Number,
			required : true
		}
	},
	data() {
		return {
			arcPrimaryDegree    : -45,
			arcAlertDegree   : -45
		};
	},
	mounted() {
		this.setCirculation();
		if(this.threshold > 0) {
			this.setThreshold();
		}
	},
	watch : {
		circulation() {
			this.setCirculation();
		},
		threshold(val) {
			if(val > 0) {
				this.setThreshold();
			}
		}
	},
	methods : {
		setThreshold() {
			this.arcAlertDegree = Math.round(((100 - this.threshold) * 180 / 100) - 45 );
		},
		setCirculation() {
			const el = this.$el;
			const percentage = (this.circulation / this.circulationPlanned) * 100;
			this.arcPrimaryDegree = Math.round((percentage * 180 / 100) - 45 );

			const needle = el.getElementsByClassName('needle')[0];
			const needleDegree = Math.round((percentage * 180 / 100) - 90);
			needle.style.transform = 'rotate(' + needleDegree + 'deg)';
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.container-speedometer {
	position: relative;
	padding-bottom: 0.5rem;
}
.speedometer {
	width: 200px !important;
	height: 100px;
	position: relative;
	overflow: hidden;
}
.speedometer .arc,
.speedometer .arc-bg,
.speedometer .arc-alert,
.arc-loading-wrap {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	transition: 1s transform;
	border: 30px solid;
}
.arc-loading-wrap {
	position: absolute;
	top: 0;
	border-color: $light-grey;
}
.arc-loading {
	border-radius: 50%;
	width: 200px;
	position: absolute;
	top: 0;
	animation: skeleton-wave 2.5s linear infinite;
	height: 200px;
	background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
}

@keyframes skeleton-wave {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.speedometer .arc {
	border-color: $light-grey;
}
.speedometer .arc-bg {
	position: absolute;
	top: 0;
	left: 0;
	border-color: transparent transparent var(--primary) var(--primary);
}
.speedometer .arc-cancel {
	border-color: transparent transparent $yellow $yellow;
}
.speedometer .arc-alert {
	position: absolute;
	top: 0;
	left: 0;
	border-color: $red $red transparent transparent;
}
.container-speedometer img {
	position: absolute;
	bottom: 0.5rem;
	height: 60px;
	margin-left: -6px;
	left: 50%;
	transform-origin: bottom center;
	transition: 1s transform;
	z-index: 1;
	transform: rotate(0deg);
}
.threshold {
	position: absolute;
	left: 100%;
	white-space: nowrap;
	transform: translateX(-65%);
	color: $red;
	margin: 0.05rem 0 0 0;
}
</style>