<template>
	<div id="create-contract">
		<div class="d-flex justify-content-between mb-3">
			<h1>{{ $t('create-contract') }}</h1>
			<b-button variant="info" class="btn-icon" @click="$router.push('/contracts/list')">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<StepByStep :current-step="step" :steps="steps" @go-to-step="goToStep" :percentage-step="percentageStep" />
		<Identification v-if="step == 'identification'" :actors="contract.actors" @go-to-step="goToStep" />
		<TransportPlan v-if="step == 'transport-plan'" :contract="contract" @go-to-step="goToStep" :is-owner="true"
			@reset-contract="resetContract" />
		<Calendar v-if="step == 'calendar'" :calendar="contract.calendar" @go-to-step="goToStep" :is-owner="true" />
		<Pricing v-if="step == 'pricing-condition'" :contract="contract" @go-to-step="goToStep" />
		<Validation v-if="step == 'validation'" :contract="contract" @go-to-step="goToStep" @submit="submit"
			:is-owner="true" @set-validation-percentage="setValidationPercentage" id="validation" />
	</div>
</template>
<script>
import StepByStep from '@/components/common/StepByStep.vue';
import { TransportPlan, Identification, Pricing, Validation, Calendar } from './create/';
export default {
	components : {
		TransportPlan,
		Identification,
		Pricing,
		Validation,
		StepByStep,
		Calendar
	},
	data() {
		return {
			step           : 'identification',
			percentageStep : {
				step  : 'validation',
				value : 0
			},
			contract : {
				actors      : [],
				routes      : [],
				rates       : [],
				services    : [],
				calendar    : {
					sendProviderDays   : null,
					start            : null,
					end              : null,
					planning         : null,
					preInvocing      : null,
				},
				locations : []
			},

		};
	},

	async created() {
		const contract = this.getContractStore;
		if(contract) {
			this.contract = contract;
		}
		const step = this.$route.params.step;
		if(step) {
			this.setStep(step);
		}
		await this.$store.dispatch('locations/loadLocations', '');
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('contracts/contract', null);
		next();
	},
	computed : {
		getContractStore() {
			return this.$store.getters['contracts/contract'];
		},
		steps() {
			return [
				'identification',
				'transport-plan',
				'calendar',
				'pricing-condition',
				'validation'
			];
		}

	},
	methods : {
		goToStep(step) {
			const stepIndex = this.steps.findIndex((x) => x == step);
			const currentIndex = this.steps.findIndex((x) => x == this.step);
			if(stepIndex > currentIndex) {
				this.$store.commit('contracts/contract', this.contract);
			}
			this.$router.push(step);
			this.step = step;
		},
		setActorCountry(actors) {
			for (let i = 0; i < actors.length; i++) {
				const actor = actors[i];
				if(actor.address.country.alpha2) {
					actor.address.country = actor.address.country.alpha2;
				}
				for (let a = 0; a < actor.contacts.length; a++) {
					const contact = actor.contacts[a];
					if(contact.address.country.alpha2) {
						contact.address.country = contact.address.country.alpha2;
					}
				}
			}
			return actors;
		},
		setRatesTypology(rates) {
			for (let i = 0; i < rates.length; i++) {
				const rate = rates[i];
				if(rate.typology && rate.typology.text) {
					rate.typology = rate.typology.text;
				}
			}
			return rates;
		},
		setRouteModel(routes) {
			const r = [...routes];
			for (let i = 0; i < r.length; i++) {
				r[i].services = this.setServicestModel([...routes[i].services]);
			}
			return r;
		},
		setServicestModel(services) {
			for (let i = 0; i < services.length; i++) {
				if(services[i].type == 'segment') {
					const segment = services[i].segment;
					if(segment.origin ) {
						segment.origin = {
							id   : segment.origin._id ? segment.origin._id
								: segment.origin.locationIdtemp,
							name : segment.origin.name
						};
					}
					if(segment.destination ) {
						segment.destination = {
							id   : segment.destination._id ?
								segment.destination._id : segment.destination.locationIdtemp,
							name : segment.destination.name
						};
					}
					if(segment.operator ) {
						segment.operator = {
							id   : segment.operator.companyId || segment.operator.id,
							name : segment.operator.name
						};
					}
				} else {
					const service = services[i];
					if(service.operator ) {
						service.operator = {
							id   : service.operator.companyId || service.operator.id,
							name : service.operator.name
						};
					}
				}
			}
			return [...services];
		},
		setSLocationModel(locations) {
			// TO DO : change active modal process

			return locations.map(loc=> {
				delete loc.activeModal;
				if(loc.address && loc.address.country && loc.address.country.alpha2) {
					loc.address.country = loc.address.country.alpha2;
				}
				return loc;
			});
		},
		submit() {
			const sendContract = this.$_clone(this.contract);
			const actors = [...this.contract.actors];
			sendContract.actors = this.setActorCountry(actors);
			const routes = [...sendContract.routes];
			sendContract.routes = this.setRouteModel(routes);

			sendContract.rates = this.setRatesTypology([...this.contract.rates]);
			// console.log([...this.contract.services]);

			//this.setSegmentModel([...this.contract.services]);
			const services = [...sendContract.services];
			sendContract.services = this.setServicestModel([...services]);
			sendContract.locations = this.setSLocationModel([...this.contract.locations]);

			this.$store.dispatch('contracts/create', sendContract)
				.then(() => {
					this.$router.push('/contracts/list');
				});
		},
		setStep(step) {
			if(step) {
				const goStep = this.steps.find((x) => x == step);
				if(goStep) {
					this.step = goStep;
				} else {
					this.goToStep(0);
				}
			} else {
				this.goToStep(0);
			}
		},
		setValidationPercentage(percentage) {
			this.percentageStep.value = percentage;
		},
		resetContract(initial) {
			this.contract = { ...initial };
		}
	}
};
</script>
<i18n>
{
    "fr": {
        "route-construction": "Construction du trajet",
        "create-contract": "Création d'un contrat"
    },
    "en": {
        "route-construction": "Route construction",
        "create-contract": "Create contract"
    }
}
</i18n>