<template>
	<main id="add-global-order">
		<h1 class="mb-0">
			{{ $t('create-order') }}
		</h1>
		<div v-if="selectedContract._id && selectedContract.state == 'VALIDATED'">
			<b-row v-if="selectedContract._id">
				<b-col xl="6">
					<RoadList :order="order" :selected-contract="selectedContract" />
				</b-col>
				<b-col xl="6" class="p-0">
					<b-row>
						<b-col xl="12">
							<Dates class="mb-3" :order="order" :selected-contract="selectedContract" />
						</b-col>
						<b-col xl="12">
							<Wagons v-show="false" :order="order" />
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<Form v-if="order.transportRoutes && order.departureDate" :locations="selectedContract.locations"
				:order="order" :is-owner="isOwner" :actors="selectedContract.actors"
				:order-handle="$v.orderHandle" />
			<div class="d-flex align-items-center flex-column">
				<div class="d-flex mt-3">
					<b-button variant="info" @click="reset">
						{{ $t('cancel') }}
					</b-button>
					<b-button variant="primary" class="btn-icon ml-2"
						:disabled="!order.departureDate || !order.transportRoutes "
						@click="save()">
						<Icon icon="save" />
						{{ $t('save') }}
					</b-button>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import { RoadList, Dates, Wagons, Form } from './components/createOrder';
import { validateNumericRequired, validateText } from '@/utils/plugins/validations/vuelidate';
export default {
	name       : 'CreateOrder',
	components : {
		RoadList,
		Dates,
		Wagons,
		Form
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('add-global-order'),
		};
	},
	data() {
		return {
			order : {
				wagons          : [],
				transportRoutes   : null,
				departureDate   : null
			},
			orderHandle : {
				handlingUnitNumber : null,
				transitTime        : null,
				handlingUnitType   : null,
				handlingUnitPrice  : null
			},
			selectedContract : {},
			isOwner          : false,
		};
	},
	computed : {
		selectedContractCode() {
			return this.$store.getters['contracts/selected'];
		}
	},
	watch : {
		selectedContractCode() {
			this.load();
			this.order.transportRoutes = null;
			this.order.departureDate = null;
		}
	},
	created() {
		this.load();
	},
	methods : {
		async load() {
			if (this.selectedContractCode) {
				await this.$store.dispatch('contracts/loadList').then(res =>{
					this.selectedContract = res.results.find(c=>c.code === this.selectedContractCode);
					if(this.selectedContract && this.selectedContract.state !== 'VALIDATED') {
						this.$_makeToast('danger', this._i18n.t('choose-contract'), this._i18n.t('error'));
					}
					const companyOwner = this.selectedContract.actors.find(actor=>actor.owner);
					this.isOwner = companyOwner._id === this.user.company._id;
				}).catch((err) => {
					this.$_makeToast('danger', err, this._i18n.t('error'));
				});
			} else {
			// TO DO: open popup and ask for choosing a valid contrat
				this.$_makeToast('danger', this._i18n.t('choose-contract'), this._i18n.t('error'));
			}
		},
		reset() {
			this.order.wagons = [];
			this.order.transportRoutes = null;
			this.order.date = null;

			Array.from(document.getElementsByTagName('tr')).forEach((e) => {
				e.classList.remove('active');
			});
			Array.from(document.getElementsByClassName('container-item ')).forEach((e) => {
				e.classList.remove('active');
			});
		},
		setSegments(val) {
			const ind = val.transportRoutes[0].services.findIndex(s=> s.type == 'segment');
			val.transportRoutes[0].services[ind].segment.departureDate = val.departureDate;
		},
		save() {
			this.$v.$touch();
			if(!this.$v.orderHandle.$invalid) {
				let NoValidDate = false;
				const typologies = [];
				const transportRoutes = [];
				const companyOwner = this.selectedContract.actors.find(actor=>actor.owner);
				for (let r = 0; r < this.order.transportRoutes.length; r++) {
					const road = this.order.transportRoutes[r];

					// check that all segments have departure and arrival date/time
					road.scheduledSegments = [];
					for (let index = 0; index < road.segments.length; index++) {
						const segment = road.segments[index];
						if(!segment.departureDate ||  !segment.departureTime
						|| !segment.arrivalDate || !segment.arrivalTime) {
							NoValidDate = true;
						}
						typologies.push(segment.typology);
						road.scheduledSegments.push({
							transportationSegment : segment._id,
							typology              : segment.typology,
							operationState        : 'CREATED',
							planningState         : 'CREATED',
							tracking              : {
								departure : {
									estimated : (segment.departureDate + ' ' + segment.departureTime ),
								},
								arrival : {
									estimated : (segment.arrivalDate + ' ' + segment.arrivalTime ),
								}
							},
							operationalConstraint : segment.operationalConstraint,
							volumetry             : segment.volumetry,
							co2                   : segment.co2,
							origin                : segment.origin,
							destination                : segment.destination,
							contracts             : [ this.selectedContract._id],
							operator              : segment.operator,
							createdBy             : segment.createdBy,
						});
					}
					transportRoutes.push({
						_id      : road._id,
						name     : road.name,
						segments : road.scheduledSegments,
					});
				}

				const createdOrder = {
					operationState                 : 'CREATED',
					planningState   : 'CREATED',
					origin                :  this.order.transportRoutes[0].segments[0].origin,
					destination     :  this.order.transportRoutes[this.order.transportRoutes.length - 1]
						.segments[this.order.transportRoutes[this.order.transportRoutes.length - 1].segments.length - 1]
						.destination,
					typologies      : [...new Set(typologies)],
					departureDate   :  this.order.transportRoutes[0].scheduledSegments[0].tracking.departure.estimated,
					arrivalDate     : this.order.transportRoutes[this.order.transportRoutes.length - 1]
						.scheduledSegments[this.order.transportRoutes[this.order.transportRoutes.length - 1]
							.scheduledSegments.length - 1]
						.tracking.arrival.estimated,
					contractId         : this.selectedContract._id.toString(),
					handlingUnitType          : this.orderHandle.handlingUnitType,
					handlingUnitNumber        : parseInt(this.orderHandle.handlingUnitNumber),
					handlingUnitPrice         : parseInt(this.orderHandle.handlingUnitPrice),
					transitTime        : parseInt(this.orderHandle.transitTime),
					transportRoutes,
					currentPosition    :  {
						position          : 0,
						segmentId : null
					},
					owner : companyOwner,

				};
				if (NoValidDate) {
					this.$_makeToast('danger',  this._i18n.t('missing-dates'), this._i18n.t('error'));
				} else {
					this.$store.dispatch('orders/create', createdOrder)
						.then(()=>{
							this.$router.push('/orders/validation');
						}).catch((err) => {
							this.$_makeToast('danger', err, this._i18n.t('error'));
						});
				}
			}
		}
	},
	validations : {
		orderHandle : {
			transitTime        : validateNumericRequired,
			handlingUnitType   : validateText,
			handlingUnitPrice  : validateNumericRequired,
			handlingUnitNumber : validateNumericRequired,

		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#info-order {
	font-weight: 600;
}
#info-order li > span {
	min-width: 100px;
	display: inline-block;
	&:first-child {
		color: $grey;
	}
	&:last-child {
		color: var(--thirdary);
	}
}
#info-order li p {
	display: flex;
	color: var(--thirdary);
}
/deep/ .date-list {
	display: none;
}
</style>
<i18n>
{
	"fr": {

        "missing-dates": "Des dates ou des horaires sont manquants dans les segments.",
		"choose-contract" : "Veuillez choisir un contrat valide pour créer une commande."
	},
	"en": {
         "missing-dates": "Dates or hours are missing in the segment information.",
		"choose-contract" : "Please select a valid contract to create an order. "


	}
}
</i18n>