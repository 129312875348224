<template>
	<div id="Time">
		<div class="w-100 d-flex flex-column" v-if="range.length">
			<div class="d-flex justify-content-end mb-3">
				<b-button variant="info" class="btn-input btn-icon mr-1" @click="reset">
					<Icon icon="cross" />
					{{ $t('reset') }}
				</b-button>
			</div>
			<div class="technical-informations w-100 light">
				<ul>
					<li>
						<span>{{ $t(condition.penaltyType) }}
							<div class="text-lowercase ml-1">
								{{ $t('in') }} {{ $t(periodicity) }}
							</div>
						</span>
						<span v-for="(rng, index) in range" :key="index">
							{{ rng.init }} - {{ rng.fin }}
						</span>
					</li>
					<li>
						<span>{{ $t('penality') }}
							<div class="text-lowercase ml-1">
								{{ $t('in') }} {{ $t($v.type.$model) }}
							</div>
						</span>
						<span v-for="(ran, index) in range" :key="index">
							{{ ran.value }}
						</span>
					</li>
				</ul>
			</div>
		</div>
		<b-row>
			<b-col lg="4" md="6" sm="12">
				<b-form-group :label="$t('of')">
					<div class="d-flex">
						<b-form-input type="number" placeholder="_"
							v-model="$v.init.$model"
							:state="$_validateState($v.init)" :min="getMinimumOf" />
						<b-form-select :value="null" class="ml-1" :options="getTimesType"
							v-model="$v.periodicity.$model" :disabled="range.length > 0"
							:state="$_validateState($v.periodicity)">
							<template v-slot:first>
								<b-form-select-option :value="null" disabled>
									_
								</b-form-select-option>
							</template>
						</b-form-select>
					</div>
					<b-form-invalid-feedback>{{ $t('fields-required-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="4" md="6" sm="12">
				<b-form-group :label="$t('at')">
					<div class="d-flex">
						<b-form-input type="number" placeholder="_"
							v-model="$v.fin.$model"
							:state="$_validateState($v.fin)" />
						<b-form-select :disabled="range.length > 0"
							:value="null" class="ml-1" :options="getTimesType"
							v-model="$v.periodicity.$model"
							:state="$_validateState($v.periodicity)">
							<template v-slot:first>
								<b-form-select-option :value="null" disabled>
									_
								</b-form-select-option>
							</template>
						</b-form-select>
					</div>
					<b-form-invalid-feedback>{{ $t('fields-required-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="4" md="6" sm="12">
				<div class="d-flex">
					<b-form-group :label="$t('price')" class="w-49">
						<b-form-input type="number" placeholder="_"
							v-model="$v.value.$model"
							:state="$_validateState($v.value)" />
						<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
					</b-form-group>
					<b-form-group :label="$t('value')" class="w-50">
						<b-form-select :value="null" class="ml-1" :options="gettype"
							v-model="$v.type.$model" :disabled="range.length > 0"
							:state="$_validateState($v.type)">
							<template v-slot:first>
								<b-form-select-option :value="null" disabled>
									_
								</b-form-select-option>
							</template>
						</b-form-select>
						<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
					</b-form-group>
				</div>
			</b-col>
			<b-col lg="12" md="6" sm="12" class="d-flex justify-content-end align-items-end">
				<b-button variant="primary" class="btn-input btn-icon" @click="addRange">
					<Icon icon="add" />
					{{ $t('add') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { validateRequired } from '@/utils/plugins/validations/vuelidate.js';
export default {
	props : {
		condition : {
			type     : Object,
			required : true,
		}
	},
	data() {
		return {
			periodicity    : 'minutes',
			type        : null,

			init    : null,
			fin    : null,
			value : null,

			range : [],
		};
	},
	validations : {
		periodicity    : validateRequired,
		type        : validateRequired,

		init    : validateRequired,
		fin    : validateRequired,
		value : validateRequired


	},
	methods : {
		addRange() {
			this.$v.$touch();
			if(!this.$v.$invalid) {
				this.range.push({
					init        : this.init,
					fin         : this.fin,
					value       : this.value,
					periodicity : this.periodicity,
					type        : this.type
				});
				this.$emit('set-range-time', this.range);
				this.$nextTick(() => {
					this.$v.$reset();
				});
			}
		},
		reset() {
			this.range = [];
			this.init = 0;
			this.fin = 0;
			this.value = null;
			this.type = null;
			this.periodicity = 'minutes';
			this.$emit('set-range-time', null);
			this.$nextTick(() => {
				this.$v.$reset();
			});
		},
	},
	mounted() {
		const condition = this.condition;
		const range = condition.range;
		if(range && range.length) {
			this.range = range;
		} else {
			this.range = [];
			this.init = 0;
			this.fin = 0;
			this.value = null;
			this.type = null;
			this.periodicity = 'minutes';
		}
	},
	computed : {
		gettype() {
			return [
				{
					value : 'percentage',
					text  : this._i18n.t('percentage')
				},
				{
					value : 'fixed-price',
					text  : this._i18n.t('fixed-price')
				},
			];
		},
		getTimesType() {
			return [
				{
					value : 'minutes',
					text  : this._i18n.t('minutes')
				},
				{
					value : 'hours',
					text  : this._i18n.t('hours')
				},
				{
					value : 'days',
					text  : this._i18n.t('days')
				},

			];
		},
		getMinimumOf() {
			const range = this.range;
			if(range.length) {
				return range[range.length - 1].at + 1;
			}
			return 0;
		}
	},
};
</script>
<style lang="scss" scoped>
.technical-informations {
	max-width: 100%;
	overflow-x: auto;
}
.technical-informations li span:first-of-type {
	width: 185px;
	min-width: 185px;
}
.technical-informations li span:not(:first-of-type) {
	width: 75px;
	min-width: 75px;
	display: flex;
	justify-content: center;
}
</style>