<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('dates') }}
		</b-card-header>
		<b-card-body>
			<div class="d-flex justify-content-between">
				<ul class="legends">
					<li>
						<span class="plan" />
						<span>{{ $t('already-planned') }}</span>
					</li>
					<li class="ml-3">
						<span class="square" />
						<span>{{ $t('not-available') }}</span>
					</li>
				</ul>

				<b-button v-if="order.selectedOrder" variant="primary" class="btn-icon-only ml-2"
					v-b-modal.others-roads-modal>
					<Icon icon="information" />
				</b-button>
			</div>

			<b-calendar class="w-100 mt-3" :value="new Date()"
				:label-no-date-selected="$t('no-date-selected')" :locale="_i18n.locale"
				:date-info-fn="datePlanned" :date-disabled-fn="dateDisabled" v-model="order.departureDate" />
			<Modal id="others-roads-modal" title="Autres routes qui circulent ce jour" size="xl" :scrollable="true"
				:hide-footer="true">
				<template v-slot:content>
					<div class="list" v-if="order.selectedOrder">
						<div class="container-item" v-for=" (transportPlan, index)
								in order.selectedOrder.transportRoutes"
							:key="index">
							<div class="item">
								<Order :order="order.selectedOrder" />
							</div>
						</div>
					</div>
				</template>
			</Modal>
		</b-card-body>
	</b-card>
</template>
<script>
import Order from '../list/order/Order';
import Moment from 'moment';
export default {
	name       : 'Dates',
	components : {
		Order
	},
	props : {
		order : {
			required : true,
			type     : Object,
		},
		selectedContract : {
			required : true,
			type     : Object,
		}
	},
	data() {
		return {
			initialDate : null,
			orders      : []
		};
	},
	methods : {
		datePlanned(ymd, date) {
			for (let index = 0; index < this.orders.length; index++) {
				if (Moment(this.orders[index].departureDate).isSame(date, 'day')) {
					return 'planned';
				}
			}
			return '';
		},
		dateDisabled(ymd, date) {
			const today = new Date();
			return date < today.setDate(today.getDate() - 1);
		},
		async onContext(ctx) {
			// if the month changed call the orders of these month
			if (!this.initialDate || (this.initialDate && !Moment(ctx.activeYMD).isSame(this.initialDate, 'month'))) {
				const startOfMonth = Moment(ctx.activeYMD).clone().startOf('month').format('YYYY-MM-DD');
				const endOfMonth   = Moment(ctx.activeYMD).clone().endOf('month').format('YYYY-MM-DD');
				// search orders associated to this dates
				let query = '?state=CREATED&state=VALIDATED&state=MODIFIED&state=ACKNOWLEDGED'
				+ '&departureDateSince=' + startOfMonth + '&departureDateUntil=' + endOfMonth;

				const selectedContractCode = this.$store.getters['contracts/selected'];
				if (selectedContractCode) {
					const contracts = await this.$store.dispatch('contracts/loadList');
					const selectedContract = contracts.results.find(c=>c.code === selectedContractCode);
					// find contract ID
					if (selectedContract)
						query += '&contractId=' + selectedContract._id;
				}

				await this.$store.dispatch('orders/load', query).then((res) => {
					if (res.results && res.results.length > 0) {
						this.orders = res.results;
					}
				}).catch((err) => {
					this.$_makeToast('danger', err, this._i18n.t('error'));
				});
			} else {
				for (let index = 0; index < this.orders.length; index++) {
					if (Moment(this.orders[index].departureDate).isSame(ctx.activeYMD, 'day')) {
						this.order.selectedOrder = { ...this.orders[index] };
					}
				}
			}
			this.initialDate = ctx.activeYMD;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.legends {
	display: flex;
	justify-content: flex-end;
}
.legends li {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: darken($grey, 10%);
}
.legends .square {
	content: '';
	width: 14px;
	height: 14px;
	background-color: $grey;
	border-radius: $border-radius/2;
}
.legends .plan {
	content: '';
	width: 60px;
	height: 10px;
	background-color: var(--secondary);
	border-radius: 25px;
	margin-bottom: 3px;
	margin-top: 2px;
}
/deep/.b-calendar > div {
	width: 100% !important;
}
/deep/.b-calendar output {
	font-size: 1rem;
	padding: 0.5rem 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	height: fit-content;
}
/deep/.b-calendar .b-calendar-grid {
	border-right: 0;
	border-bottom: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
/deep/.b-calendar .b-calendar-grid header {
	border-right: 1px solid #ced4da;
	font-size: 1rem;
	padding: 1rem;
}
/deep/.b-calendar .b-calendar-grid-body .col[data-date] .btn {
	width: 100% !important;
	height: 100% !important;
	margin: 0 !important;
	border-radius: 0 !important;
	font-size: 1rem;
	text-align: left;
	padding: 0.25rem 2rem 2rem 0.25rem;
	border-right: 1px solid #ced4da !important;
	border-bottom: 1px solid #ced4da !important;
}
/deep/.b-calendar .form-control:focus {
	box-shadow: none;
	border-color: #ced4da;
}
/deep/.b-calendar .b-calendar-grid-weekdays {
	border-right: 1px solid #ced4da;
}
/deep/.b-calendar .b-calendar-grid-weekdays small {
	padding: 0.5rem;
	border-right: 1px solid #ced4da;
	border-top: 1px solid #ced4da;
	font-weight: bold;
	color: var(--secondary);
	font-size: 1rem;
}
/deep/ .b-calendar .b-calendar-nav .btn {
	padding: 1rem;
}
/deep/ .b-calendar .planned .btn:after {
	content: '';
	display: block;
	position: absolute;
	top: 0.25rem;
	right: 0.25rem;
	width: 50%;
	height: 10px;
	background-color: var(--secondary);
	border-radius: 25px;
}
/deep/ .b-calendar .planned .btn.active:after {
	background-color: $white;
}
/deep/ .b-calendar .bg-light span {
	color: $white !important;
	background-color: $grey !important;
}
/deep/ .modal-content footer {
	display: none;
}
</style>