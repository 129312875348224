import Vue from 'vue';
import Moment from 'moment';
Vue.filter('getHoursMinutes', function(date) {
	if (date) {
		Moment.locale('fr');
		return Moment(date).format('LT');
	}
	return '';
});
Vue.filter('getDateMonthYear', function(date) {
	if (date) {
		Moment.locale('fr');
		return Moment(date).format('L');
	}
	return '';
});
Vue.filter('getFullDate', function(date) {
	if (date) {
		return Moment(date).format('DD/MM/YYYY - HH:mm');
	}
	return '';
});
Vue.filter('dateInputToFullDate', function(date) {
	if(date) {
		return Moment(date).format('DD/MM/YYYY');
	}
	return '';
});