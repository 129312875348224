<template>
	<div>
		<b-button variant="primary" v-b-toggle="'transport-plan'" class="btn-collapse">
			{{ $t('transport-plan') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="transport-plan" accordion="accordion">
			<b-card>
				<div class="list-header border-radius-top">
					<ul>
						<li>{{ $t('road') }}</li>
						<li>{{ $t('origine') }}</li>
						<li />
						<li>{{ $t('destination') }}</li>
						<li v-if="$route.name != 'VisualizationContracts'">
							{{ $t('validation') }}
						</li>
						<li v-else />
					</ul>
				</div>

				<div class="list mt-0">
					<div class="container-item" v-for=" (route, index) in getUserRoutes" :key="index">
						<div class="item" v-b-toggle="'item-details-' + index">
							<Informations :transport-plan="route">
								<template v-slot:dateList>
									<DateList :date-list="route.days" />
								</template>
								<template v-slot>
									<div class="d-flex flex-column text-initial px-4"
										v-if="$route.name != 'VisualizationContracts'">
										<div class="d-flex align-items-center" v-if="isOwner">
											<legend class="mb-0 mr-2">
												{{ $t('charger') }}
											</legend>
											<b-form-checkbox disabled v-model="route.validatedCharger"
												class="success" switch size="lg" :id="'checkboxCharger'+index" />
										</div>
										<div class="d-flex align-items-center"
											v-if="$route.name == 'EditContracts'">
											<legend class="mb-0 mr-2">
												{{ $t('provider') }}
											</legend>
											<b-form-checkbox disabled v-model="route.validatedOperator"
												class="success" switch size="lg" :id="'checkboxProvider'+index" />
										</div>
									</div>
								</template>
							</Informations>
							<HorizontalVisualization :services="route.services.filter(s=> s.type=='segment')"
								class="mt-3" />
							<div class="toggle-details">
								<Icon icon="chevron-down" />
							</div>
						</div>
						<b-collapse :id="'item-details-' + index">
							<div class="p-5 d-flex justify-content-between">
								<VerticalVisualization :transport-plan="route" />
								<div class="technical-informations">
									<h3>
										{{ $t('technicals-informations') }}
									</h3>
									<ul>
										<li v-if="route.handlingUnitPrice">
											<span>
												{{ $t('price-per-handling-unit') }}
											</span>
											<span>
												{{ route.handlingUnitPrice }} €
											</span>
										</li>
										<li v-if="route.handlingUnitType">
											<span>{{ $t('type-of-handling-units') }}</span>
											<span>{{ $t(route.handlingUnitType) }}</span>
										</li>
										<li v-if="route.handlingUnitNumber">
											<span>{{ $t('number-of-handling-units') }}</span>
											<span>{{ route.handlingUnitNumber }}</span>
										</li>
									</ul>
								</div>
							</div>
						</b-collapse>
					</div>
				</div>
			</b-card>
		</b-collapse>
	</div>
</template>
<script>
/* import {  } from './segments/'; */
import Informations from '@/components/transportPlan/Informations.vue';
import DateList from '../create/transportPlan/form/DateList.vue';
import HorizontalVisualization from '@/components/transportPlan/HorizontalVisualization.vue';
import VerticalVisualization from '@/components/transportPlan/VerticalVisualization.vue';
export default {
	components : {
		Informations,
		DateList,
		HorizontalVisualization,
		VerticalVisualization
	},
	props : {
		getUserRoutes : {
			required : true,
			type     : Array
		},
		getUserLocations : {
			type     : Array,
			required : true
		},
		getUserSegments : {
			type     : Array,
			required : true
		},
		isOwner : {
			type     : Boolean,
			required : true,
		}
	},



};
</script>