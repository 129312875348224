<template>
	<div id="orders-list">
		<div class="d-flex justify-content-between">
			<h1>{{ $t('tracking-of-orders') }}</h1>
			<Legends />
		</div>
		<div class="child-mx-2 d-flex justify-content-end mt-2">
			<b-button variant="primary" @click="$router.push('/orders/planning')">
				{{ $t('edit-order') }}
			</b-button>
			<b-button variant="primary" @click="$router.push({name: 'CreateOrder'})">
				{{ $t('create-order') }}
			</b-button>
		</div>
		<b-tabs class="mt-3">
			<b-tab :title="$t('orders')" lazy>
				<div class="tab-header border-radius-top-right">
					<Filters>
						<Code />
						<Sites />
						<Clients />
						<ClientRef />
						<Ef />
						<Status />
					</Filters>
				</div>
				<div class="list-header tab-header-bottom border-radius-bottom">
					<ul>
						<li>{{ $t('order-code') }}</li>
						<li>{{ $t('origine') }}</li>
						<li> {{ $t('site') }}</li>
						<li>{{ $t('destination') }}</li>
						<li>{{ $t('actions') }}</li>
					</ul>
				</div>
				<div class="tab-content list mt-2" v-if="loading">
					<OrderSkeleton v-for="order in [1,2]" :key="order" />
				</div>
				<div class="tab-content list mt-2" v-else-if="orders.length">
					<Order :order="order" v-for="(order) in orders" :key="order._id" />
					<Pagination :pagination="pagination" />
				</div>
				<div class="tab-content list mt-2" v-else>
					<div class="container-item">
						<div class="item">
							<p class="text-center">
								{{ $t('no-data-to-display') }}
							</p>
						</div>
					</div>
				</div>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import Legends from './components/list/Legends.vue';
import Filters from '@/components/filters/Filters.vue';
import { Code,  Sites, ClientRef, Ef, Status } from '@/components/filters/components/order';
import Clients from '@/components/filters/components/Clients.vue';
import Pagination from '@/components/Pagination.vue';
import Order from './components/list/order/Order.vue';
import OrderSkeleton from './list/OrderSkeleton.vue';
export default {
	name       : 'Orders',
	components : {
		Legends,
		Filters,
		Code,
		Sites,
		Clients,
		ClientRef,
		Ef,
		Status,
		Pagination,
		Order,
		OrderSkeleton
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('tracking-of-orders'),
		};
	},
	data() {
		return {
			orders      : [],
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			},
			loading : true
		};
	},
	computed : {
		getOrdersSliced() {
			var pagination = this.pagination;
			return this.orders.slice((pagination.currentPage - 1) * pagination.perPage,
				pagination.currentPage * pagination.perPage);
		},
		selectedContractCode() {
			return this.$store.getters['contracts/selected'];
		}
	},
	watch : {
		selectedContractCode() {
			this.loadOrders();
		}
	},
	created() {
		this.loadOrders();
	},
	methods : {
		async loadOrders() {
			let query = '?state=ACKNOWLEDGED';
			this.loading = true;
			if (this.selectedContractCode) {
				const res = await this.$store.dispatch('contracts/loadList');
				const selectedContract = res.results.find(c=>c.code === this.selectedContractCode);
				if (selectedContract)
					query += '&contractId=' + selectedContract._id;

				// find contract ID
			}
			query += '&sortasc=departureDate';
			await this.$store.dispatch('orders/load', query).then((res) => {
				if (res.results && res.pagination && res.pagination[0]) {
					this.orders = res.results;
					this.pagination.totalRows = res.pagination[0].count;
				} else {
					this.$_makeToast('danger', this._i18n.t('no-orders-are-confirmed'), this._i18n.t('alert'));
					this.orders = [];
				}
				this.loading = false;
			}).catch((err) => {
				this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
				this.loading = false;
			});
		},
	}
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';
</style>
<i18n>
{
	"fr": {
		"edit-order": "Modifier une commande",
		"create-order": "Crée une commande",
		"no-orders-are-confirmed": "Aucune commandes n'est confirmé."
	},
	"en": {
		"edit-order": "Edit order",
		"create-order": "Create order",
		"no-orders-are-confirmed": "No orders are confirmed."
	}
}
</i18n>