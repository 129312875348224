<template>
	<b-row>
		<b-col lg="8" md="12">
			<b-form class="child-mb-3">
				<b-row>
					<b-col lg="6" md="6" sm="12">
						<b-form-group :label="$t('name')">
							<b-form-input placeholder="_" v-model="location.name.$model"
								:state="$_validateState(location.name)" name="name" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group :label="$t('site-type')">
							<b-form-select v-model="location.description.$model" name="sitetype"
								:state="$_validateState(location.description)" :options="typologyOptions">
								<b-form-select-option :value="null" disabled>
									_
								</b-form-select-option>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<MultipleSelect :options="locations" :multiple="true" v-model="location.interconnections.$model"
							:label="_i18n.t('interconnections')" track="name" :searchable="true"
							:validation="location.interconnections" />
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<MultipleSelect :options="companies" :label=" _i18n.t('handler')" track="companyName"
							:validation="location.handler" v-model="location.handler.$model" :searchable="true"
							:name="'optionsInterconnections'" />
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group :label="$t('street')">
							<b-form-input placeholder="_" v-model="location.address.street.$model"
								:state="$_validateState(location.address.street)" name="street" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group :label="$t('postal-code')">
							<b-form-input placeholder="_" v-model="location.address.postalCode.$model"
								:state="$_validateState(location.address.postalCode)" name="postalcode" />
							<b-form-invalid-feedback>{{ $t('postal-code-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group :label="$t('city')">
							<b-form-input placeholder="_" v-model="location.address.city.$model"
								:state="$_validateState(location.address.city)" name="city" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group :label="$t('country')"
							:class="getClassMultiSelectForValidation(location.address.country)">
							<multi-select v-model="location.address.country.$model" name="country"
								:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
								select-label="" placeholder="_" :searchable="true" label="name" track-by="name"
								:options="countries" :hide-selected="false">
								<template slot="noResult">
									{{ $t('no-result') }}
								</template>
							</multi-select>
							<b-form-invalid-feedback>
								{{ $t('field-required-err') }}
							</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</b-col>
		<b-col lg="4" md="12">
			<Schedules :schedules="location.openingHours.$model?location.openingHours.$model:[]"
				:title="$t('opening-hours-days')"
				:multiple="true" @set-schedules="setSchedules" id="location-form" />
		</b-col>
	</b-row>
</template>
<script>
import Schedules from '@/components/common/Schedules';
import countriesMixins from '@/mixins/countries.js';

export default {
	props : {
		location : {
			required : true,
			type     : Object
		},
		companies : {
			required : true,
			type     : Array
		},
		locations : {
			required : true,
			type     : Array,
		}
	},
	mixins     : [countriesMixins],
	components : {
		Schedules,
	},
	created() {
		const country = this.location.address.country.$model;
		if(country) {
			this.location.address.country.$model = this.findCountry(country);
			this.location.$reset();
		}
	},
	computed : {
		typologyOptions() {
			return [
				{
					value : 'terminal',
					text  : this._i18n.t('terminal')
				},
				{
					value : 'terminal-hub',
					text  : this._i18n.t('terminal-hub')
				},
				{
					value : 'platform',
					text  : this._i18n.t('platform')
				},
				{
					value : 'ITE',
					text  : this._i18n.t('ITE')
				},
				{
					value : 'station',
					text  : this._i18n.t('station')
				}
			];
		},
	},
	methods : {
		setSchedules(schedules) {
			this.location.openingHours.$model = schedules;
		},
	}
};
</script>