import { HTTP } from '../../http-common';
const state = () => ({
	transportationRoutes : []
});

const getters = {
	get : state => state.transportationRoutes
};

const mutations = {
	set(state, payload) {
		state.transportationRoutes = payload;
	},
};
const actions = {
	async load(context) {
		await HTTP.get('/transportationRoutes')
			.then(res => {
				context.commit('set', res.data);
			}).catch(err => {
				throw err.response.data.message;
			});
	},
	async loadRoute(context, id) {
		return await HTTP.get('/transportationRoutes/' + id)
			.then(res => {
				return res.data;
			}).catch(err => {
				throw err.response.data.message;
			});
	},
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};