<template>
	<div class="actor">
		<b-card no-body>
			<b-card-header v-if="actor.validateForm.$model">
				<span> {{ $t(actor.type.$model) }} </span>
				<div class="d-flex">
					<b-button variant="outline-light" class="btn-icon mr-1" @click="editActor">
						<Icon icon="edit" />
						{{ $t('edit') }}
					</b-button>
					<b-button v-if="index != 0" variant="outline-light" class="btn-icon" @click="removeActor">
						<Icon icon="trash" />
						{{ $t('clear') }}
					</b-button>
				</div>
			</b-card-header>
			<b-card-header v-else>
				<b-form-group>
					<b-form-select v-model="actor.type.$model" :options="companyTypes"
						:state="$_validateState(actor.type)" :name="'optionsActor'">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								{{ $t('type-of-actor') }}
							</b-form-select-option>
						</template>
					</b-form-select>
					<b-form-invalid-feedback>
						{{ $t('field-required-err') }}
					</b-form-invalid-feedback>
				</b-form-group>
				<b-button variant="outline-light" v-if="index != 0" class="btn-icon" @click="removeActor">
					<Icon icon="trash" />
					{{ $t('clear') }}
				</b-button>
			</b-card-header>
			<b-card-body>
				<Informations :actor="actor" :actors="actors" />
				<Contact v-for="(contact, indexContact) in actor.contacts.$each.$iter" :key="indexContact"
					:contact="contact" :actor="actor" :index="indexContact" @delete-contact="deleteContact" />
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<b-button variant="primary" class="btn-icon w-100" @click="addContact">
							<Icon icon="add-person" />
							{{ $t('add-a-contact') }}
						</b-button>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>
import companyMixins  from '@/mixins/company.js';
import { Informations, Contact } from './actor/';
export default {
	components : {
		Informations,
		Contact
	},
	mixins : [
		companyMixins
	],
	props : {
		actor : {
			required : true,
			type     : Object
		},
		index : {
			required : true,
			type     : String
		},
		actors : {
			required : true,
			type     : Array,
		}
	},
	methods : {
		removeActor() {
			this.actor.$reset();
			this.$emit('remove-actor', this.index);
		},
		editActor() {
			this.actor.validateForm.$model = false;
		},
		addContact() {
			this.actor.contacts.$model.push({
				lastname  : null,
				firstname : null,
				email     : null,
				phone     : null,
				address   : {
					street     : null,
					postalCode : null,
					country    : null,
					city       : null,
				},
				validateForm : false
			});
		},
		deleteContact(index) {
			this.actor.contacts.$model.splice(index, 1);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.actor {
	margin-bottom: 1rem;
	&:last-of-type {
		margin-bottom: 0;
	}
}
.card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
/deep/.text-information {
	display: flex;
	flex-direction: column;
}
/deep/.text-information span {
	font-weight: 600;
}
</style>
<i18n>
{
	"fr": {
		"type-of-actor": "Type d'acteur"
	},
	"en": {
		"type-of-actor": "Type of actor"
	}
}
</i18n>