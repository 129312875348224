<template>
	<main id="register">
		<router-link to="/login" id="login">
			<b-button variant="light">
				{{ $t('login') }}
			</b-button>
		</router-link>
		<div class="form-container">
			<h1 class="my-3" v-if="step != 'confirmation'">
				<span>{{ $t('register') }}</span>
			</h1>
			<StepByStep :current-step="step" :steps="steps" @go-to-step="goToStep" align="center" />
			<Society v-if="step === 'company'" @go-to-step="goToStep" :register="register" />
			<Address v-if="step === 'address'" @go-to-step="goToStep" :register="register" />
			<User v-if="step === 'user'" @go-to-step="goToStep" :register="register" />
			<Confirmation v-if="step === 'confirmation'" :register="register" />
		</div>
	</main>
</template>

<script>
import StepByStep from '@/components/common/StepByStep.vue';
import { Society, Address, User, Confirmation } from './register/';
export default {
	name       : 'FormRegister',
	components : {
		Society,
		Address,
		User,
		Confirmation,
		StepByStep
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('register'),
		};
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('registers/register', null);
		next();
	},
	data() {
		return {
			steps    : ['company', 'address', 'user'],
			step     : 'company',
			register : {
				company : {
					name  : null,
					type  : null,
					siret : {
						siren : null,
						nic   : null,
					},
					tva     : null,
					address    : {
						street     : null,
						city       : null,
						postalCode : null,
						country    : null
					}
				},
				user : {
					email        : null,
					confirmEmail : null,
					password     : null,
					repassword   : null,
					firstname    : null,
					lastname     : null,
					phone        : null,
					language     : null,
					emailconf    : true,
					address      : {
						street     : null,
						city       : null,
						postalCode : null,
						country    : null,
					}
				},
			}
		};
	},
	methods : {
		goToStep(step) {
			this.step = step;
			this.$router.push('/register/' + step);
		}
	},
	created() {
		const stepParams = this.$route.params.step;
		if(stepParams) {
			if(this.steps.includes(stepParams)) {
				this.step = this.$route.params.step;
			} else {
				this.$router.push('/register/company');
			}
		} else {
			this.$router.push('/register/company');
		}
		const register = this.$store.getters['registers/register'];
		if(register) {
			this.register = register;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
main {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
h1 {
	font-weight: 600;
}
#login {
	position: absolute;
	top: 20px;
	right: 20px;
}
.form-container {
	width: 100%;
	overflow-y: auto;
	max-height: 85%;
	max-width: 650px;
	color: $black;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	box-shadow: 0 0 5px $black;
	height: fit-content;
	background-color: $white;
	align-items: center;
	padding: 25px 50px;
}
/deep/ form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
/deep/ .custom-checkbox {
	width: 100%;
	display: flex;
}
.form-container /deep/ .form-group {
	width: 49%;
}
.breadcrumb {
	justify-content: center;
}
.breadcrumb-item {
	margin-top: 0;
}

@include media-breakpoint-down(xs) {
	.form-container {
		padding: 25px;
	}
	.form-container /deep/ .form-group {
		width: 100%;
	}
	.breadcrumb {
		width: 100%;
		justify-content: space-between;
	}
	.breadcrumb-item {
		padding: 0;
		margin: 0;
	}
	.breadcrumb-item a {
		flex-direction: column;
	}
}
</style>
