import { HTTP } from '../../http-common';

const actions = {
	create(context, incident) {
		return HTTP.post('/incidents', incident).then(res => {
			return res;
		}).catch(err => {
			throw err.response;
		});
	}
};

export default {
	namespaced : true,
	actions,
};
