<template>
	<div id="add-wagon">
		<h1>{{ $t('create-location') }}</h1>
		<b-spinner class="loader" variant="primary" v-if="loading" />
		<div v-else>
			<b-button variant="primary" v-b-toggle="'location'" class="btn-collapse" id="buttonform">
				{{ $t('information-location') }}
				<Icon icon="chevron-down" />
			</b-button>
			<b-collapse visible id="location" accordion="accordion">
				<b-card>
					<Form :location="$v.location" :companies="companies" :locations="locations" />
				</b-card>
			</b-collapse>

			<b-button variant="primary" v-b-toggle="'contact'" class="btn-collapse" id="buttoncontact">
				{{ $t('information-contact') }}
				<Icon icon="chevron-down" />
			</b-button>
			<b-collapse id="contact" accordion="accordion">
				<b-card>
					<ContactFields :location="$v.location" />
				</b-card>
			</b-collapse>

			<b-button variant="primary" v-b-toggle="'modals'" class="btn-collapse" id="buttonmodals">
				{{ $t('information-modals') }}
				<Icon icon="chevron-down" />
			</b-button>
			<b-collapse id="modals" accordion="accordion">
				<b-card>
					<Modals :location="$v.location" />
				</b-card>
			</b-collapse>

			<b-row>
				<b-col lg="12" class="d-flex justify-content-end">
					<b-button variant="info" @click="$router.go(-1)">
						{{ $t('cancel') }}
					</b-button>
					<b-button variant="primary" class="ml-1" @click="submit" id="createlocation">
						{{ $t('save') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import createMixins from './createMixins.js';
export default {
	name       : 'CreateLocation',
	mixins : [
		createMixins
	],
	metaInfo()  {
		return {
			title : this._i18n.t('create-location'),
		};
	},
	methods : {
		submit() {
			const validation = this.$v.location;
			validation.$touch();
			if(this.checkData()) {
				this.loading = true;
				this.setData();
				this.$store.dispatch('locations/createLocations', this.location).then(() => {
					this.$router.push('list').then(() => {
						this.$_makeToastRoot('success', this._i18n.t('the-location') + ' ' + this.location.name
						+ ' ' + this._i18n.t('has-been-created'), this._i18n.t('success'));
					});
				}).catch((err) => {
					this.loading = false;
					this.$_makeToast('danger', err, this._i18n.t('error'));
				});
			}
		},
	}
};
</script>