<template>
	<div>
		<AddRoute :route="$v.route" />
		<div class="d-flex">
			<ul id="list-transport-plan">
				<li v-for="(service, i) in route.services" :key="i">
					<Segment v-if="service.type == 'segment'" :segment="service.segment" :locations="locations"
						@add-services="e => { addServices(i) }"
						:previous-destination="previousDestination(getServiceId(service))"
						:can-delete-destination="canDeleteDestination(i)"
						@push-location="e => { $emit('push-location', e) }"
						@set-selected="e => {setSelected(getServiceId(service), e)}"
						@spliceServicesAfterIndex="e => { spliceServicesAfterIndex(i)}" :selected="selected"
						:id="getServiceId(service)" :loading-locations="loadingLocations" />
					<Handling v-else :value="service.type" @input="e => setHandling(service, e, i)"
						:is-selected="selected.id == getServiceId(service)" :id="getServiceId(service)"
						@set-selected="e => {setSelected(getServiceId(service), e)}" />
				</li>
			</ul>
			<Information v-if="selected.id && selected.type" ha
				:selected="selected" :actors="actors" :current-service="findServicesById(selected.id)"
				:current-location="findLocationByIdService(selected.id)" />
		</div>
		<b-button variant="info" @click="reset" class="mr-1">
			{{ $t('reset') }}
		</b-button>
		<b-button variant="primary" @click="submit" id="validTransportPlan">
			{{ $t('valid') }}
		</b-button>
	</div>
</template>
<script>
import uniqid from 'uniqid';
import { validateTextRequired, validateNumeric, validateRequired } from '@/utils/plugins/validations/vuelidate';
import { circulationsDays } from '@/utils/plugins/validations/contracts.js';
import { Segment, Handling, AddRoute, Information } from './form/';
export default {
	components : {
		Segment,
		Handling,
		AddRoute,
		Information
	},
	props : {
		currentRoute : {
			required : false,
			type     : Object,
			default() {
				return {};
			}
		},
		locations : {
			required : true,
			type     : Array,
		},
		actors : {
			required : true,
			type     : Array,
		},
		loadingLocations : {
			required : true,
			type     : Boolean
		}
	},
	validations : {
		route : {
			name               : validateTextRequired,
			transitTime        : validateNumeric,
			handlingUnitType   : validateRequired,
			days               : circulationsDays,
			handlingUnitPrice  : validateNumeric,
			handlingUnitNumber : {},
		}
	},
	data() {
		return {
			route            : {
				routeId            : uniqid(),
				name               : null,
				days               : [],
				handlingUnitType   : null,
				handlingUnitNumber : null,
				handlingUnitPrice  : null,
				transitTime        : null,
				origin             : {
					name : null,
					id   : null,
				},
				destination : {
					name : null,
					id   : null,
				},
				services : []
			},
			selected : {}
		};
	},
	created() {
		this.addHandling();
		this.addSegment();
	},
	methods : {
		addSegment() {
			const segment = {
				segment : {
					origin      : {
						name    : null,
						handler : {
							companyName : null,
						},
						address      : {},
						openingHours : [],
						modals       : [],
					},
					destination : {
						name    : null,
						handler : {
							companyName : null
						},
						address      : {},
						openingHours : [],
						modals       : [],
					},
					typology              : null,
					operator              : null,
					volumetry             : {},
					operationalConstraint : {},
					timetable             : [],
					technicalConstraint   : {},
					segmentIdtemp         : uniqid()
				},
				type                  : 'segment',
				validatedCharger      : false,
				validatedOperator     : true,
				serviceIdTemp     : uniqid(),
			};
			this.route.services.push(segment);
		},
		addHandling(index, force) {
			const handling = {
				type              : null,
				operator          : null,
				startTime         : null,
				operationTime     : null,
				timetable         : [],
				validatedCharger  : false,
				day               : 0,
				validatedOperator : true,
				serviceIdTemp     : uniqid()
			};
			if(index != undefined) {
				const previousHandling = this.route.services[index - 1];
				if(!previousHandling || (previousHandling && previousHandling.type == 'segment') || force)
					this.route.services.splice(index + 1, 0, handling);
			} else
				this.route.services.push(handling);
		},
		addServices(index) {
			if(!this.route.services[index + 1]) {
				this.addHandling();
				this.addSegment();
			}
		},
		getServiceId(service) {
			return service.serviceIdTemp || service.serviceId;
		},
		setHandling(service, value, index) {
			service.type = value;
			const nextHandling = this.route.services[index + 1];
			if(value && nextHandling.type == 'segment') {
				this.addHandling(index);
			}
		},
		canDeleteDestination(index) {
			const segments = this.route.services.filter((x) => x.type == 'segment');
			const lastSegmentInList = segments[segments.length - 2];
			if(lastSegmentInList) {
				const isLastSegment = this.route.services[index].id == lastSegmentInList.id;
				return isLastSegment && !segments[segments.length - 1].segment.origin.name;
			} else return false;
		},
		previousDestination(id) {
			const segments = this.route.services.filter((x) => x.type == 'segment');
			const index = segments.findIndex((x) => (x.serviceIdTemp || x.serviceId) == id);
			const previousSegment = segments[index - 1];
			if(previousSegment) return previousSegment.segment.destination;
			else return null;
		},
		async reset() {
			Object.assign(this.$data, this.$options.data.apply(this));
			this.$nextTick(() => {
				this.$v.$reset();
			});
			this.addHandling();
			this.addSegment();
		},
		submit() {
			this.$v.$touch();
			if(!this.$v.$invalid) {
				var services = this.$_clone(this.route.services);
				if(services.length < 3)
					return this.$_makeToast('danger', this._i18n.t('route-must-be-contains-segments'), 'error');
				const locations = [];
				for (let index = 0; index < services.length; index++) {
					const service = services[index];
					if(!service.type) {
						services.splice(index, 1);
					} else {
						if(service.segment && service.segment.destination.name) {
							const origin = service.segment.origin.name;
							if(!locations.find((x) => x.name == origin)) {
								const find = this.locations.find((x) => x.name == origin);
								locations.push(find);
							}
							const destination = service.segment.destination.name;
							if(!locations.find((x) => x.name == destination)) {
								const find = this.locations.find((x) => x.name == destination);
								locations.push(find);
							}
							if(service.segment.timetable && !service.segment.timetable.length)
								return this.$_makeToast('danger',
									this._i18n.t('please-add-circulation-hours-day-to-your-segments'), 'error');
							if(!service.segment.typology)
								return this.$_makeToast('danger', this._i18n.t('each-segment-must-have-a-type'),
									'error');
							if(!service.segment.operator)
								return this.$_makeToast('danger',
									this._i18n.t('please-add-operator-to-your-segments'), 'error');
						} else {
							if((service.type && service.type != 'segment') && !service.operator)
								return this.$_makeToast('danger',
									this._i18n.t('each-service-must-have-an-operator'), 'error');
						}
					}
				}
				for (let i = 0; i < locations.length; i++) {
					const location = locations[i];
					if(!location.handler.companyName)
						return this.$_makeToast('danger',
							this._i18n.t('each-location-must-have-a-handler'), 'error');
				}
				this.route.services = services;
				this.$emit('push-route', this.$_cleanObject(this.route));
				this.reset();
			} else {
				this.$_makeToast('danger', this._i18n.t('some-fields-are-incorrect'), 'error');
				window.scrollY = 0;
			}
		},
		spliceServicesAfterIndex(index) {
			this.route.services.splice(index + 1, 2);
		},
		setSelected(id, type) {
			if(type) {
				this.selected = {
					id   : id,
					type : type
				};
			} else
				this.selected = {};
		},
		findServicesById(id) {
			return this.route.services.find((x) => (x.serviceIdTemp || x.serviceId) == id);
		},
		findLocationByIdService(id) {
			const service = this.route.services.find((x) => (x.serviceIdTemp || x.serviceId) == id);
			if(service.segment) {
				const location = service.segment[this.selected.type];
				if(location) {
					return this.locations.find((x) => x.name == location.name);
				}
			}
		}
	},
	watch : {
		currentRoute(currentRoute) {
			if(currentRoute) {
				this.route = currentRoute;
				const services = this.route.services;
				if(services[0].type == 'segment') {
					this.addHandling(-1);
				}
				for (let i = 0; i < services.length; i++) {
					const nextService = services[i + 1];
					const currentService = services[i];
					if(currentService.type == 'segment') {
						if(!nextService || (nextService && nextService.type == 'segment')) {
							this.addHandling(i, true);
						}
					} else {
						if(currentService.type && (nextService && nextService.type == 'segment')) {
							this.addHandling(i);
						}
					}
				}
				this.addSegment();
			}
		}
	}
};
</script>
<i18n>
{
	"fr": {
		"each-segment-must-have-a-type": "Chaque segment doit avoir un type.",
		"some-fields-are-incorrect": "Certains champs sont incorrect.",
		"each-service-must-have-an-operator": "Chaque service doit avoir un opérateur.",
		"route-must-be-contains-segments": "La route doit contenir au moins 1 segment.",
		"please-add-type-to-your-segments": "Merci d'ajouter le type de vos segments.",
		"name-already-exist": "Ce nom existe déjà.",
		"each-location-must-have-a-handler": "Chaque station doit avoir un manutentionnaire",
		"please-add-operator-to-your-segments": "Merci d'ajouter les opérateurs aux types de vos segments."
	},
	"en": {
		"each-segment-must-have-a-type": "Each segment must have a type.",
		"some-fields-are-incorrect": "Some fields are incorrect.",
		"each-service-must-have-an-operator": "Each service must have an operator.",
		"route-must-be-contains-segments": "Route must be contains 1 segment.",
		"please-add-type-to-your-segments": "Please ad type to your segments.",
		"name-already-exist": "This name already exist",
		"each-location-must-have-a-handler": "Each location must have a handler",
		"please-add-operator-to-your-segments": "Please add operator to your segments.",
		"please-add-circulation-hours-day-to-your-segments": "Please add circulation hours day to your segments"
	}
}
</i18n>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
ul {
	margin: 1rem 0;
}
/deep/.container-info {
	border: 1px solid #ced4da;
	justify-content: space-between;
	border-radius: $border-radius;
	height: 35px;
	text-transform: initial;
	cursor: pointer;
	align-items: center;
	width: 245px;
	display: flex;
	position: relative;
	font-weight: 600;
	color: var(--primary);
	padding: 0.375rem 0.375rem 0.375rem 0.75rem;
}
/deep/.container-info.selected {
	background-color: var(--thirdary);
	color: $white;
}
/deep/ .origin .container-info,
/deep/ .origin .multiple-select {
	&:before {
		display: flex;
		content: '';
		border-radius: $border-radius;
		background-color: $grey;
		position: absolute;
		left: -20px;
		width: 10px;
		height: 100px;
		top: 18px;
	}
}
/deep/ .origin.has-origin-and-destination .container-info {
	&:before {
		background-color: var(--thirdary);
	}
}
#list-transport-plan /deep/ .multiple-select,
#list-transport-plan /deep/ .container-info {
	position: relative;
	margin-bottom: 1rem;
	margin-left: 24px;
}
/deep/ .container-info {
	margin-left: 23px;
}
#list-transport-plan /deep/div:not(.typology) > .multiple-select,
#list-transport-plan /deep/div:not(.typology) > .container-info {
	&:after {
		display: flex;
		content: '';
		background-color: $grey;
		border-radius: 50%;
		position: absolute;
		left: -24px;
		top: 50%;
		margin-top: -8px;
		width: 18px;
		height: 18px;
		border: 3px solid $white;
	}
}
/deep/.active .multiple-select,
/deep/.active .container-info {
	&:after {
		background-color: var(--thirdary) !important;
	}
}
#list-transport-plan /deep/ .multiselect {
	width: 245px;
}
</style>