<template>
	<div class="table">
		<div class="d-flex justify-content-between align-items-end">
			<h1>{{ title }}</h1>
			<div class="d-flex child-mx-1">
				<slot name="buttons" />
				<b-button variant="primary" class="btn-icon-only" @click="load();">
					<Icon icon="sync" />
				</b-button>
			</div>
		</div>
		<div class="tab-header border-radius-top mt-3">
			<Filters>
				<slot name="filters" />
			</Filters>
		</div>
		<b-table head-variant="light" :striped="true" hover responsive show-empty v-if="!loading"
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="items" :selectable="selectable"
			@row-selected="onRowSelected" ref="table">
			<template v-for="slot in Object.keys($scopedSlots)" v-slot:[toCellName(slot)]="props">
				<slot v-bind="props" :name="slot" />
			</template>
		</b-table>
		<SkeletonTable v-else :fields="fields" :items="items" :per-page="5" />
		<div class="d-flex justify-content-center align-items-center mt-3 child-mx-2">
			<slot name="bottom-buttons" />
			<b-button variant="info" @click="selectAllRows"
				v-if="items.length != selectedRows.length && selectable && items.length">
				{{ $t('select-all') }}
			</b-button>
			<b-button variant="outline-info" @click="unselectAllRows"
				v-if="items.length == selectedRows.length && selectable && items.length">
				{{ $t('unselect-all') }}
			</b-button>
			<Pagination :pagination="pagination" />
		</div>
	</div>
</template>
<script>

import Filters from '@/components/filters/Filters.vue';
import SkeletonTable from '@/components/common/SkeletonTable.vue';
import Pagination from '@/components/Pagination.vue';
export default {
	name       : 'Table',
	components : {
		SkeletonTable,
		Pagination,
		Filters
	},
	props : {
		fields : {
			required : true,
			type     : Array
		},
		items : {
			required : true,
			type     : Array,
		},
		loading : {
			required : true,
			type     : Boolean
		},
		title : {
			required : true,
			type     : String
		},
		pagination : {
			required : true,
			type     : Object,
		},
		selectable : {
			required : false,
			type     : Boolean,
			default  : false
		},
	},
	data() {
		return {
			selectedRows : []
		};
	},
	methods : {
		toCellName(slot) {
			return `cell(${slot})`;
		},
		load() {
			this.$emit('load', this.query);
		},
		onRowSelected(items) {
			this.selectedRows = items;
			this.$emit('on-row-selected', items);
		},
		selectAllRows() {
			this.$refs.table.selectAllRows();
		},
		unselectAllRows() {
			this.$refs.table.clearSelected();
		}
	},
	watch : {
		$route : {
			immediate : true,
			handler() {
				this.load();
			}
		},
		getPerPage() {
			this.pagination.currentPage = 1;
			this.load();
		},
		currentPage() {
			this.load();
		},
		selectedContract() {
			this.load();
		},
	},
	computed : {
		selectedContract() {
			return this.$store.getters['contracts/selected'];
		},
		query() {
			let query = '?offset=' + (this.pagination.currentPage - 1) * this.pagination.perPage + '&limit='
			+ this.pagination.perPage;
			for (let i = 0; i < Object.keys(this.$route.query).length; i++) {
				const key = Object.keys(this.$route.query)[i];
				const data = this.$route.query[key];
				if(data.includes(',')) {
					const values = data.split(',');
					for (let i = 0; i < values.length; i++) {
						const value = values[i];
						query += '&' + key + '=' + value;
					}
				} else {
					query += '&' + key + '=' + data;
				}
			}
			return query;
		},
		getPerPage() {
			return this.pagination.perPage;
		},
		currentPage() {
			return this.pagination.currentPage;
		}
	}
};
</script>