<template>
	<div id="partners">
		<Table :items="partners" :fields="fields" :loading="loading" @load="load()" :title="$t('partners')"
			:pagination="pagination">
			<template v-slot:buttons>
				<b-button v-if="userHaveAccess('CreatePartner')" variant="primary"
					@click="$router.push({name: 'SearchPartner'})">
					{{ $t('add-partner') }}
				</b-button>
			</template>
			<template v-slot:filters>
				<Search />
				<Status />
			</template>
			<template v-slot:action="data">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<Icon icon="eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="$router.push({name: 'UpdatePartner', params: {id: data.item._id}})">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<Icon icon="on" v-if="true" />
								<Icon icon="off" v-else />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
			<template v-slot:type="partner">
				{{ $t(partner.item.type) }}
			</template>
		</Table>
	</div>
</template>

<script>
import { Search, Status } from '@/components/filters/components';


export default {
	name       : 'Partners',
	components : {
		Search,
		Status,
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('partners'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label        : this._i18n.t('company'),
					key          : 'name',
					sortable     : true,
				},
				{
					label    : this._i18n.t('company-type'),
					key      : 'type',
					sortable : true,
				},
				{
					label    : this._i18n.t('siren-number'),
					key      : 'siret.siren',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'active',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		}
	},
	data() {
		return {
			partners      : [],
			loading    : false,
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			}
		};
	},
	methods : {
		async load() {
			const selectedContractCode = this.$store.getters['contracts/selected'];

			if (selectedContractCode) {
				const contracts = await this.$store.dispatch('contracts/loadList');
				const selectedContract = contracts.results.find(c=>c.code === selectedContractCode);
				// find contract ID
				this.partners = selectedContract ? selectedContract.actors : [];
			}
			if (this.partners.length == 0) {
				const contracts = await this.$store.dispatch('contracts/loadList');
				const actors =  contracts.results.map(c=>c.actors);

				this.partners = [].concat.apply([], actors);
			}
			// TEMPORARY
			this.partners = this.partners.map(p=> {
				p._id = p.companyId;
				return p;
			});
			this.pagination.totalRows = this.partners.length;
		}
	}
};
</script>

<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>