import { HTTP } from '../../http-common';
import SecureLS from 'secure-ls';
var ls = new SecureLS();
const state = () => ({
	contract : null,
	list     : [],
	selected : null
});

const getters = {
	contract : state => state.contract || ls.get('contract'),
	list     : state => state.list,
	selected : state => state.selected
};

const mutations = {
	contract(state, payload) {
		state.contract = payload;
		ls.set('contract', payload);
	},
	list(state, payload) {
		state.list = payload;
	},
	selected(state, payload) {
		state.selected = payload;
	}
};
const actions = {
	async create(context, contract) {
		await HTTP.post('/contracts', contract)
			.then().catch(err => {
				throw err.response;
			});
	},
	loadList(context, query) {
		let url = '/contracts';
		if(query) {
			url += query;
		}
		return HTTP.get(url).then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	loadRoutes(context, id) {
		const url = '/contracts/' + id + '/routes';
		return HTTP.get(url).then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async loadContract(context, id) {
		await HTTP.get('/contracts/' + id)
			.then(res => {
				context.commit('contract', res.data);
			}).catch(err => {
				throw err.response.data.message;
			});
	},

	async edit(context, contract) {
		const { _id, ...contractWithoutId } = contract;
		await HTTP.put('/contracts/' + _id, contractWithoutId)
			.then().catch(err => {
				throw err.response.data;
			});
	},
	async valid(context, id) {
		await HTTP.put('/contracts/' + id + '/validate')
			.then().catch(err => {
				throw err.response.data;
			});
	},
	async delete(context, id) {
		await HTTP.delete('contracts/' + id)
			.then().catch(err => {
				throw err.response.data;
			});
	}
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};