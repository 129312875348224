<template>
	<div id="hamburger" @click="toggleNav">
		<div />
		<div />
	</div>
</template>
<script>
export default {
	data : function() {
		return {
			cross : false,
		};
	},
	methods : {
		toggleNav() {
			document.getElementById('hamburger').classList.toggle('cross');
			this.$root.$el.classList.toggle('open-nav');
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#hamburger {
	cursor: pointer;
	z-index: 10;
	transition: $transition;
}
#hamburger > div {
	width: 25px;
	height: 2px;
	transition: $transition;
	background-color: $white;
	border-radius: $border-radius;
	&:last-of-type {
		margin-top: 5px;
	}
}
#hamburger.cross > div {
	&:first-of-type {
		position: relative;
		top: 3px;
		transform: rotate(45deg);
	}
	&:last-of-type {
		position: relative;
		bottom: 3px;
		transform: rotate(-45deg);
	}
}
</style>