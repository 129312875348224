<template>
	<div id="RentalFields">
		<b-card no-body>
			<b-card-header>
				{{ $t('coordinates') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('email')">
							<b-input placeholder="_" type="email" v-model="validate.email.$model"
								:state="$_validateState(validate.email)" />
							<b-form-invalid-feedback>{{ $t('email-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('phone')">
							<b-input placeholder="_" type="tel" v-model="validate.phone.$model"
								:state="$_validateState(validate.phone)" />
							<b-form-invalid-feedback>{{ $t('phone-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('lastname')">
							<b-input placeholder="_" type="tel" v-model="validate.lastname.$model"
								:state="$_validateState(validate.lastname)" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('firstname')">
							<b-input placeholder="_" type="tel" v-model="validate.firstname.$model"
								:state="$_validateState(validate.firstname)" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('language')">
							<b-form-select :value="null" :options="languages"
								v-model="validate.language.$model" :state="$_validateState(validate.language)">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>
										_
									</b-form-select-option>
								</template>
							</b-form-select>
							<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="12">
						<b-form-checkbox class="text-initial" v-model="sameAddress" id="sameaddress">
							{{ $t('identical-address-to-company') }}
						</b-form-checkbox>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-card no-body class="mt-3" v-if="!sameAddress">
			<b-card-header>
				{{ $t('address') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('address')">
							<b-input placeholder="_" v-model="validate.address.street.$model"
								:state="$_validateState(validate.address.street)" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('city')">
							<b-input placeholder="_" v-model="validate.address.city.$model"
								:state="$_validateState(validate.address.city)" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('postal-code')">
							<b-input placeholder="_" v-model="validate.address.postalCode.$model"
								:state="$_validateState(validate.address.postalCode)" />
							<b-form-invalid-feedback>{{ $t('postal-code-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<MultipleSelect :options="countries" :validation="validate.address.country" track="name"
							:label="this._i18n.t('country')" :searchable="true"
							v-model="validate.address.country.$model" />
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import languagesMixins from '@/mixins/languages.js';
import countriesMixins from '@/mixins/countries.js';

export default {
	name       : 'CompanyFields',
	mixins     : [languagesMixins, countriesMixins],
	props      : {
		validate : {
			type     : Object,
			required : true,
		},
		registration : {
			type     : Object,
			required : true,
		}
	},
	data() {
		return {
			sameAddress : true
		};
	},
	computed : {
		getAddress() {
			return this.validate.address.$model;
		},
	},
	created() {
		if(JSON.stringify(this.registration.company.address) != JSON.stringify(this.validate.address.$model)) {
			this.sameAddress = false;
		}
	},
	watch : {
		sameAddress(val) {
			if(val) {
				this.registration.user.address = this.$_clone(this.registration.company.address);
			}
		},
		getAddress : {
			handler : function(val) {
				if(JSON.stringify(val) == JSON.stringify(this.registration.company.address)) {
					this.sameAddress = true;
				}
			},
			deep : true
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>