<template>
	<ul class="light vertical visualization">
		<li v-for="(service, key) in services" :key="key"
			:class="service.type == 'segment'?service.segment.OperationStatus:''">
			<div v-if="service.type == 'loading' || service.type == 'unloading'">
				<ul class="loading-segment">
					<li />
					<li />
					<li />
					<p class="duration-loading" v-if="service.operator">
						<span>{{ $t('handler') }} : {{ service.operator.name }}</span>

						<!-- 						<span>
							<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
								&& getPreviousItemArray(key, segments).type == 'road'">
								{{ $t('handling-time-per-truck') }} :
								{{ getHandlingTimePerTruck(segment) }} h
							</span>
							<span v-else-if="segment.type == 'rail' && segment.handling.time !== 0">
								{{ $t('handling-time') }} : {{ segment.handling.time }} h
							</span>
							<span v-else-if="segment.type == 'rail' && segment.handling.time === 0">
								{{ $t(segment.handling.service_type) }}
							</span>
							<span v-if="segment.type == 'road'">
								{{ $t('handling-time-per-truck') }} : {{ segment.handling.time }} h
							</span>
						</span>
						<span v-if="segment.type == 'road' && segment.units">
							{{ $t('number-of-trucks') }} : {{ segment.units }}
						</span>
						<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
							&& getPreviousItemArray(key, segments).type == 'road'">
							{{ $t('number-of-trucks') }} : {{ segment.handling.units }}
						</span> -->
						<span v-if="service.operationTime">
							{{ $t('handling-time') }} : {{ service.operationTime.slice(0,2) }} h
							{{ service.operationTime.slice(3,5) }} mn
						</span>
					</p>
				</ul>
				<!-- 				<p class="segment-date first-departure-date"
					v-if="key == 0 && segment.handling && segment.handling.time
						&& segment.timetable.departure_time">
					<b v-if="segment.typology == 'rail'">{{ substractDate(segment) }}</b>
					<b v-if="segment.typology == 'road'">{{ substractDate(getNextItemArray(key, segments)) }}</b>
					<span v-if="getNextItemArray(key, segments).timetable.departure_time">
						{{ $t('day-letter') }}
						<span v-if="getDaysPlusFirstLoad(segment) > 0 && segment.type == 'rail'">
							-{{ getDaysPlusFirstLoad(segment) }}
						</span>
					</span>
				</p> -->
			</div>
			<div class="segment-container" v-if="service.type =='segment'">
				<p class="segment-date derparture-date">
					<!-- 				<span v-if="segment.type == 'rail'" class="d-flex flex-column align-items-end">
						<b>{{ segment.timetable.departure_time }} </b>
						<span v-if="segment.timetable.departure_time">
							{{ $t('day-letter') }}
							<span v-if="getDaysPlusDeparture(transportPlan, segment) > 0">
								+{{ getDaysPlusDeparture(transportPlan, segment) }}
							</span>
						</span>
					</span> -->
				</p>
				<div class="d-flex flex-column">
					<div class="segment-visualization">
						<div class="position" :id="'position-' + key" />
						<div :class="'segment ' + service.segment.typology">
							<div class="road-element" v-if="service.segment.typology == 'ROAD'" />
						</div>
						<Statut :segment="service.segment" />
						<DateList :timetable="service.segment.timetable.times" class="date-list" />
					</div>
				</div>
				<div class="position-destination">
					<p class="segment-date destination-date">
						<!-- <span v-if="segment.typology == 'rail'" class="d-flex align-items-end flex-column">
							<b>{{ segment.timetable.arrival_time }}</b>
							<span v-if="segment.timetable.arrival_time">
								{{ $t('day-letter') }}
								<span v-if="getDaysPlusArrival(transportPlan,segment) > 0">
									+{{ getDaysPlusArrival(transportPlan,segment) }}
								</span>
							</span>
						</span> -->
					</p>
					<div class="position" />
					<b class="destination-name">{{ service.segment.destination.name }}</b>
				</div>
				<div class="segment-details">
					<div class="d-flex flex-column">
						<b>{{ service.segment.origin.name }}</b>
						<p class="d-flex flex-column text-grey text-initial">
							<span>
								{{ $t('carrier') }} : {{ service.segment.operator.name }}
							</span>
							<span v-if="service.tripsNumber ">
								{{ $t('number-of-trips') }} : {{ service.tripsNumber }}
							</span>
							<!-- <span v-if="segment.typology == 'road'">
								{{ $t('travel-time-per-trucks') }} : {{ getTravelTimePerTrucks(segment.timetable) }} h
							</span> -->
						</p>
					</div>
				</div>
			</div>
			<!-- <div v-if="key == (services.length - 1)" class="unload">
				<ul class="loading-segment">
					<li />
					<li />
					<li />
						<p class="duration-loading" v-if="segment.handlerName">
						<span v-if="segment.handling_arrival">
							{{ $t('handler') }} : {{ segment.handling_arrival.name }}</span>
						<span>
							<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
								&& getPreviousItemArray(key, segments).type == 'road'">
								{{ $t('handling-time-per-truck') }} :
								{{ segment.handling.time }} h

							</span>

							<span v-else-if="segment.type == 'rail'">
								{{ $t('handling-time') }} : {{ segment.handling_arrival.time }} h
							</span>
							<span v-if="segment.type == 'road'">
								{{ $t('handling-time-per-truck') }} : {{ segment.handling.time }} h
							</span>
						</span>
						<span v-if="segment.type == 'road' && segment.units">
							{{ $t('number-of-trucks') }} : {{ segment.units }}
						</span>
						<span v-if="segment.type == 'rail' && getPreviousItemArray(key, segments)
							&& getPreviousItemArray(key, segments).type == 'road'">
							{{ $t('number-of-trucks') }} : {{ segment.handling.units }}
						</span>
					</p>
				</ul>
			</div> -->
		</li>
	</ul>
</template>
<script>
import Moment from 'moment';
import VerticalVisualization from '@/mixins/segments/VerticalVisualization.js';
import Statut from '@/components/segments/Statut';
import DateList from '@/components/segments/DateList';
export default {
	name : 'VerticalVisualization',

	props : {
		transportPlan : {
			type     : Object,
			required : true,
		},
	},
	components : {
		Statut,
		DateList
	},
	mixins : [
		VerticalVisualization,
	],
	data() {
		return {
			services : this.transportPlan.services
		};
	},
	methods : {
		substractDate(segment) {
			const hours_minutes = segment.timetable.departure_time.split(':');
			const start = Moment().startOf('month').hour(hours_minutes[0]).minute(hours_minutes[1]);
			return start.subtract(segment.handling.time, 'hours').format('hh:mm');
		},
		getDaysPlusFirstLoad(segment) {
			const hours_minutes = segment.timetable.departure_time.split(':');
			const start = Moment().hour(hours_minutes[0]).minute(hours_minutes[1]);
			const end =  Moment().hour(hours_minutes[0]).minute(hours_minutes[1]);
			end.subtract(segment.handling.time, 'hours');
			const diff = start.format('d') - end.format('d');
			if(diff > 0) return diff;
		},
		getTravelTimePerTrucks(timetable) {
			const departure_time_array = timetable.departure_time.split(':');
			const arrival_time_array = timetable.arrival_time.split(':');
			return arrival_time_array[0] - departure_time_array[0];
		}
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.date-list {
	position: absolute;
	top: 50%;
	left: 40px;
	transform: translateY(-50%);
}
</style>