export default {
	computed : {
		getAllValidation() {
			let allData =  this.$_clone(this.getUserLocations).concat(this.getUserSegments, this.getUserRoutes);
			let length = 1;
			let validateNumber = 0;
			if(this.isOwner) {
				length = length + 1;
				if(this.getOwnerCalendarValidate) {
					validateNumber = validateNumber + 1;
				}
				allData = allData.concat(this.contract.actors);
			} else {
				const actor = this.contract.actors.find((x) => x.companyId == this.user.company._id);
				if(actor) {
					allData.push(actor);
				}
			}
			if(this.getActorCalendarValidate) {
				validateNumber = validateNumber + 1;
			}
			for (let i = 0; i < allData.length; i++) {
				const data = allData[i];
				if(this.isOwner) {
					if(data.validatedCharger) validateNumber = validateNumber + 1;
				}
				if(data.validatedOperator) validateNumber = validateNumber + 1;
			}
			let percentage = 0;
			if(this.isOwner) length = length + (allData.length * 2);
			else {
				length = length + allData.length;
			}
			if(validateNumber > 0) {
				percentage = validateNumber / length;
			}
			const returnValue = Math.round(percentage * 100);
			this.$emit('set-validation-percentage', returnValue);
			return returnValue;
		},
		getUserLocations() {
			const locations = [];
			for (let i = 0; i < this.contract.locations.length; i++) {
				const location = this.contract.locations[i];
				if(this.isOwner) {
					locations.push(location);
				} else if(location.handler && location.handler.companyId == this.user.company._id) {
					locations.push(location);
				}
			}
			return locations;
		},
		getUserSegments() {
			const segments = [];
			if (this.contract.services) {
				const segmentsServices = this.contract.services.filter(s=>s.type == 'segment');
				for (let i = 0; i < segmentsServices.length; i++) {
					const segment = segmentsServices[i];
					const id = segment.segment.operator.id ? segment.segment.operator.id
						: segment.segment.operator.companyId;
					if(this.isOwner) {
						segments.push(segment);
					} else if(this.user.company._id == id) {
						segments.push(segment);
					}
				}
			}

			return segments;
		},
		getUserRate() {
			const rates = [];
			if (this.contract.rates) {
				for (let i = 0; i < this.contract.rates.length; i++) {
					const rate = this.contract.rates[i];

					if(this.isOwner) {
						rates.push(rate);
					} else if(this.user.company._id == rate.responsibility.companyId) {
						rates.push(rate);
					}
				}
			}

			return rates;
		},
		getUserManut() {
			const manuts = [];
			const manutServices = this.contract.services.filter(s=> s.type && s.type != 'segment');
			for (let i = 0; i < manutServices.length; i++) {
				const manut = manutServices[i];
				const id = manut.operator.id ? manut.operator.id
					: manut.operator.companyId;
				if(this.isOwner) {
					manuts.push(manut);
				} else if(this.user.company._id == id) {
					manuts.push(manut);
				}
			}

			return manuts;
		},
		getUserRoutes() {
			const routes = [];
			for (let i = 0; i < this.contract.routes.length; i++) {
				const route = this.contract.routes[i];
				if(this.isOwner) {
					routes.push(route);
				} else {
					const segmentsServices = this.contract.services.filter(s=>s.type == 'segment');

					for (let a = 0; a < segmentsServices.length; a++) {
						const segment = segmentsServices[a].segment;

						/*const origin = this.contract.locations.find((x) => x.locationIdtemp == segment.origin.id
						|| x.locationId == segment.origin.id);

						if(origin.handler && origin.handler.companyId == this.user.company._id) {
							routes.push(route);
							break;
						}
						const destination = this.contract.locations.find((x) =>
							x.locationIdtemp == segment.destination.id
						|| x.locationId == segment.destination.id);
						if(destination.handler && destination.handler.companyId == this.user.company._id) {
							routes.push(route);
							break;
						}*/
						const id = segment.operator.id ? segment.operator.id
							: segment.operator.companyId;

						if(id == this.user.company._id) {
							routes.push(route);
							break;
						}
					}
				}
			}
			return routes;
		},
		getOwnerCalendarValidate() {
			if(this.contract.actors[0]) {
				return this.contract.actors[0].calendarValidate;
			}
		},
		getActorCalendarValidate() {
			if(this.contract.actors && this.contract.actors.length) {
				if(this.isOwner) {
					for (let i = 1; i < this.contract.actors.length; i++) {
						const actor = this.contract.actors[i];
						if(!actor.calendarValidate) return false;
					}
					return true;
				}
				return this.contract.actors.find((x) => x.companyId == this.user.company._id).calendarValidate;
			} return false;
		}
	}
};