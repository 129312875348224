<template>
	<div id="validations">
		<Table :items="orders" :fields="fields" :loading="loading" @load="load" :title="$t('validation')"
			:pagination="pagination" :selectable="true" @on-row-selected="onRowSelected">
			<template v-slot:filters>
				<CodeOrder />
				<Status />
				<DepartureDateSince />
				<DepartureDateUntil />
			</template>
			<template v-slot:operators="data">
				<div class="d-flex flex-column">
					<div v-for="(operator, i) in getOperatorList(data.item)" :key="i">
						{{ operator }}
					</div>
				</div>
			</template>
			<template v-slot:planningState="data">
				{{ $t(data.item.planningState) }}
			</template>
			<template v-slot:departureDate="data">
				{{ getDate(data.item.departureDate) }}
			</template>
			<template v-slot:arrivalDate="data">
				{{ getDate(data.item.arrivalDate) }}
			</template>
			<template v-slot:roads="data">
				<div class="d-flex justify-content-center">
					<div v-for="(road, i) in data.item.transportRoutes" :key="i">
						{{ road.name }}
					</div>
				</div>
			</template>
			<template v-slot:co2="data">
				<div>
					{{ data.item.co2 }}T
				</div>
			</template>
			<template v-slot:actions="data">
				<div class="d-flex justify-content-center child-mx-1">
					<b-button variant="primary" class="btn-round-icon"
						@click="showActionModal(data.item._id, 'VALIDATED')"
						v-if="data.item.owner == user.company._id && data.item.planningState != 'VALIDATED'">
						<Icon icon="checkmark" />
					</b-button>
					<b-button variant="primary" class="btn-round-icon"
						@click="showActionModal(data.item._id, 'ACKNOWLEDGED')"
						v-if="userACKTheOrder(data.item)">
						<Icon icon="checkmark" />
					</b-button>
					<b-button variant="primary" class="btn-round-icon"
						@click="$router.push('/orders/update/' + data.item._id)">
						<Icon icon="edit" />
					</b-button>
					<b-button variant="primary" class="btn-round-icon"
						@click="showActionModal(data.item._id, 'CANCELED')"
						v-if="data.item.owner == user.company._id && data.item.planningState != 'CANCELED'">
						<Icon icon="cross" />
					</b-button>
				</div>
			</template>
			<template v-slot:bottom-buttons>
				<b-button variant="primary" class="btn-icon" v-if="selectedRows.length > 1"
					@click="openModalValidSelectedOrder()">
					{{ $t('valid-orders-selected') }}
					<Icon icon="checkmark-all" />
				</b-button>
			</template>
		</Table>
		<Modal id="modal-action" @confirm="updateState">
			<template v-slot:content>
				<p class="text-initial">
					{{ $t('do-you-want-to') + ' ' }}
					<b>{{ $t(currentAction) }}</b>
					{{ ' ' }} <span class="lowercase">{{ $t('the-order') }}</span> {{ ' ' + getCurrentOrderCode }} ?
				</p>
			</template>
		</Modal>
		<Modal id="modal-valid-selected-orders" @confirm="validSelectedOrders" :title="$t('validations')">
			<template v-slot:content>
				<p class="text-initial">
					{{ $t('do-you-want-to') + ' ' }}
					<span class="lowercase">{{ $t('valid-orders-selected') }} ?</span>
				</p>
			</template>
		</Modal>
	</div>
</template>
<script>
import  CodeOrder  from '@/components/filters/order/CodeOrder.vue';
import { Status, DepartureDateSince, DepartureDateUntil } from '@/components/filters/order/validations/';
import moment from 'moment';
export default {
	components : {
		CodeOrder,
		Status,
		DepartureDateSince,
		DepartureDateUntil
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('validation'),
		};
	},
	data() {
		return {
			loading    : false,
			pagination : {
				perPage     : 20,
				totalRows  	: null,
				currentPage : 1,
			},
			currentOrderId : null,
			currentAction  : null,
			orders         : [],
			selectedRows   : []
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('code'),
					key      : 'internalCode',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'planningState',
				},
				{
					label    : this._i18n.t('roads'),
					key      : 'roads'
				},
				{
					label    : this._i18n.t('departure-date'),
					key      : 'departureDate',
					sortable : true,
				},
				{
					label    : this._i18n.t('arrival-date'),
					key      : 'arrivalDate',
					sortable : true,
				},
				{
					label    : this._i18n.t('providers'),
					key      : 'operators',
					sortable : true,
				},
				{
					label    : 'CO2',
					sortable : true,
					key      : 'co2'
				},
				{
					label    : this._i18n.t('actions'),
					key      : 'actions'
				},
			];
		},
		getCurrentOrderCode() {
			const findIndex = this.orders.findIndex((x) => {
				return x._id == this.currentOrderId;
			});
			if(findIndex != -1) {
				return this.orders[findIndex].internalCode;
			}
			return '';
		},
		selectedContractCode() {
			return this.$store.getters['contracts/selected'];
		}
	},
	watch : {
		selectedContractCode() {
			this.load();
		}
	},
	methods : {
		async load(param) {
			let query = param;
			if(!query) {
				const table = this.$children.find((x) => x.$options._componentTag == 'Table');
				query = table.query;
			}
			this.loading = true;
			if (!query || (query && !query.includes('state'))) {
				query += '&state=CREATED&state=VALIDATED&state=CANCELED&state=MODIFIED';
			}
			if (this.selectedContractCode) {
				const contracts = await this.$store.dispatch('contracts/loadList');
				const selectedContract = contracts.results.find(c=>c.code === this.selectedContractCode);
				// find contract ID
				if (selectedContract)
					query += '&contractId=' + selectedContract._id;
			}
			query += '&sortasc=departureDate';
			this.$store.dispatch('orders/load', query).then((res) => {
				if (res.pagination && res.pagination.length > 0) {
					res.results.forEach((e) => {
						e.co2 = 0;
						e.transportRoutes[0].segments.forEach((seg) => {
							if(seg.co2) {
								e.co2 += seg.co2;
							}
						});
						e.co2 = Math.round(e.co2 * 100) / 100;
					});
					this.orders = res.results;
					this.pagination.totalRows = res.pagination[0].count;
				}
				this.loading = false;
			}).catch((err) => {
				this.$_makeToast('danger', err, this._i18n.t('error'));
				this.loading = false;
			});
		},
		getOperatorList(order) {
			const operators = [];
			for (let i = 0; i < order.transportRoutes.length; i++) {
				const route = order.transportRoutes[i];
				for (let a = 0; a < route.segments.length; a++) {
					const segment = route.segments[a];
					const find = operators.findIndex((x) => x == segment.operator.name);
					if(find == -1) {
						operators.push(segment.operator.name);
					}
				}
			}
			return operators;
		},
		getDate(date) {
			return moment(date).format('DD-MM-YYYY');
		},
		showActionModal(id, state) {
			this.currentOrderId = id;
			this.currentAction = state;
			this.$bvModal.show('modal-action');
		},
		updateState() {
			this.$store.dispatch('orders/updateState', {
				orderId : this.currentOrderId,
				state   : this.currentAction
			}).then(() => {
				this.load();
				this.$_makeToast('success', this._i18n.t('the-order') + ' ' + this.getCurrentOrderCode + ' ' +
				this._i18n.t('has-been-well') + ' ' + this._i18n.t(this.currentAction) + '.', this._i18n.t('success'));
			}).catch((err) => {
				this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
			});
			this.$bvModal.hide('modal-action');
		},
		userIsOperatorOfTheOrder(routes) {
			for (let i = 0; i < routes.length; i++) {
				const segments = routes[i].segments;
				for (let i = 0; i < segments.length; i++) {
					const segment = segments[i];
					if(segment.operator.id == this.user.company._id) {
						return true;
					}
				}
			}
			return false;
		},
		userACKTheOrder(order) {
			const routes = order.transportRoutes;

			for (let i = 0; i < routes.length; i++) {
				const segments = routes[i].segments;
				for (let i = 0; i < segments.length; i++) {
					const segment = segments[i];
					if((segment.operator.id == this.user.company._id && segment.planningState == 'VALIDATED')
					) {
						return true;
					}
				}
			}
			return false;
		},
		onRowSelected(items) {
			this.selectedRows = items;
		},
		openModalValidSelectedOrder() {
			this.$bvModal.show('modal-valid-selected-orders');
		},
		validSelectedOrders() {
			const ids = this.selectedRows.map(row=>row._id);
			const codes = this.selectedRows.map(row=>row.internalCode);
			let state = 'VALIDATED';
			if(this.selectedRows[0].owner == this.user.company._id
			&& this.selectedRows[0].planningState != 'VALIDATED') {
				state = 'VALIDATED';
			} else if(this.userIsOperatorOfTheOrder(this.selectedRows[0].transportRoutes)
				&& this.selectedRows[0].planningState == 'VALIDATED') {
				state = 'ACKNOWLEDGED';
			}

			this.$store.dispatch('orders/updateStates', {
				ids : { ids },
				state
			}).then(() => {
				this.load();
				this.$_makeToast('success', this._i18n.t('the-order') + ' ' + codes.join(',') + ' ' +
				this._i18n.t('has-been-well') + ' ' + this._i18n.t(this.currentAction) + '.', this._i18n.t('success'));
			}).catch((err) => {
				this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
			});
			this.$bvModal.hide('modal-action');
		},
		editOrder(id) {
			this.$router.push('orders/update/' + id);
		}
	},
};
</script>
<i18n>
{
	"fr": {
		"CREATED": "créée",
		"MODIFIED": "modifiée",
		"VALIDATED": "validée",
		"CANCELED": "annulée",
		"ACKNOWLEDGED": "confirmée",
		"the-order": "La commande",
		"has-been-well": "a bien été",
		"do-you-want-to": "Voulez-vous",
		"valid-orders-selected": "Valider les commandes sélectionnées"
	},
	"en": {
		"CREATED": "created",
		"MODIFIED": "modified",
		"VALIDATED": "validated",
		"CANCELED": "canceled",
		"ACKNOWLEDGED": "confirm",
		"the-order": "The order",
		"has-been-well": "has been well",
		"do-you-want-to": "Do you want to",
		"valid-orders-selected": "Valid orders selected"
	}
}
</i18n>