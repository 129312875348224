<template>
	<div id="RentalFields">
		<b-card no-body>
			<b-card-header>
				{{ $t('company') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('company-type')">
							<b-form-select :options="companyTypes" v-model="validate.type.$model"
								:state="$_validateState(validate.type)">
								<template v-slot:first>
									<b-form-select-option :value="null" disabled>
										_
									</b-form-select-option>
								</template>
							</b-form-select>
							<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('company-name')">
							<b-form-input v-model="validate.name.$model"
								:state="$_validateState(validate.name)" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('vat-number')">
							<b-input placeholder="_" type="text" v-model="validate.tva.$model"
								:state="$_validateState(validate.tva)" />
							<b-form-invalid-feedback>{{ $t('vat-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12" class="pt-0 pb-0">
						<b-row>
							<b-col md="8" sm="12">
								<b-form-group :label="$t('siren')">
									<b-input placeholder="_" type="number"
										v-model="validate.siret.siren.$model"
										:state="$_validateState(validate.siret.siren)" />
									<b-form-invalid-feedback>{{ $t('siren-err') }}</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
							<b-col md="4" sm="12">
								<b-form-group :label="$t('nic')">
									<b-input placeholder="_" type="number"
										v-model="validate.siret.nic.$model" name="nic"
										:state="$_validateState(validate.siret.nic)" />
									<b-form-invalid-feedback>{{ $t('nic-err') }}</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<b-card no-body class="mt-3">
			<b-card-header>
				{{ $t('address') }}
			</b-card-header>
			<b-card-body>
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('address')">
							<b-input placeholder="_" v-model="validate.address.street.$model"
								:state="$_validateState(validate.address.street)" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('city')">
							<b-input placeholder="_" v-model="validate.address.city.$model"
								:state="$_validateState(validate.address.city)" />
							<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-form-group :label="$t('postal-code')">
							<b-input placeholder="_" v-model="validate.address.postalCode.$model"
								:state="$_validateState(validate.address.postalCode)" />
							<b-form-invalid-feedback>{{ $t('postal-code-err') }}</b-form-invalid-feedback>
						</b-form-group>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<MultipleSelect :options="countries" :validation="validate.address.country" track="name"
							:label="this._i18n.t('country')" :searchable="true"
							v-model="validate.address.country.$model" />
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import companyMixins  from '@/mixins/company.js';
import countriesMixins from '@/mixins/countries.js';
export default {
	name       : 'CompanyFields',
	mixins     : [companyMixins, countriesMixins],
	props      : {
		validate : {
			type     : Object,
			required : true
		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>