import { HTTP } from '../../http-common';

const state = () => ({
	wagons : []
});

const getters = {
	list : state => {
		return state.wagons;
	}
};

const mutations = {
	SET(state, payload) {
		state.wagons = [...payload];
	}
};

const actions = {
	async createWagons(context, wagon) {
		await HTTP.post('/wagons', wagon).then().catch(err => {
			if (err.response) {
				throw err.response.data.message;
			} else if (err.request) {
				throw err.request;
			} else {
				throw 'error';
			}
		});
	},

	loadWagons(context, query) {
		let url = '/wagons';

		if (query) {
			url += query;
		}
		return HTTP.get(url).then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async updateWagons(context, { payload, _id }) {
		await HTTP.put('/wagons/' + _id, payload).then(() => {
		}).catch(err => {
			if (err.response) {
				throw err.response.data.message;
			} else if (err.request) {
				throw err.request;
			} else {
				throw 'error';
			}
		});
	}
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};