<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group label="Ef">
			<b-form-input placeholder="_" />
		</b-form-group>
	</b-col>
</template>
<script>
export default {
};
</script>