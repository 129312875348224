<template>
	<div class="scheduled-segment" :class="{'active': active}"
		@click="$emit('set-selected-segments')">
		<div class="departure">
			<span class="date">
				{{ formatDate(segment.tracking.departure.estimated) }}
			</span>
			<span>
				{{ segment.origin.name }}
			</span>
		</div>
		<Icon icon="long-arrow-right" />
		<div class="arrival">
			<span class="date">
				{{ formatDate(segment.tracking.arrival.estimated) }}
			</span>
			<span>
				{{ segment.destination.name }}
			</span>
		</div>
	</div>
</template>
<script>
import Moment from 'moment';
export default {
	props : {
		segment : {
			type     : Object,
			required : true,
		},
		active : {
			type     : Boolean,
			required : false,
			default  : false
		}
	},
	methods : {
		formatDate(date) {
			return Moment(date).format('DD/MM/YYYY');
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.scheduled-segment {
	border: 1px solid $light-grey;
	border-radius: $border-radius;
	padding: 5px 10px;
	display: flex;
	align-items: center;
	text-transform: initial;
	cursor: pointer;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	&:hover {
		background-color: $light-grey;
	}
}
.scheduled-segment.active {
	background-color: var(--primary);
	color: $white;
}
.scheduled-segment .arrival,
.scheduled-segment .departure {
	display: flex;
	flex-direction: column;
}
.scheduled-segment .date {
	display: flex;
	align-items: center;
	font-size: 1.2em;
}
.scheduled-segment .icon {
	font-size: 1.5em;
}
</style>