<template>
	<div class="skeleton-table">
		<b-table :fields="fields" :items="items" head-variant="light">
			<template v-for="field in fields" v-slot:[toCellName(field.key)]>
				<b-skeleton width="100%" :key="field.key" />
			</template>
		</b-table>
	</div>
</template>
<script>
export default {
	props : {
		fields : {
			type     : Array,
			required : true
		},
		perPage : {
			type     : Number,
			required : true
		}
	},
	data() {
		return {
			items : []
		};
	},
	methods : {
		toCellName(slot) {
			return `cell(${slot})`;
		}
	},
	created() {
		for (let index = 0; index < this.perPage; index++) {
			const obj = {};
			for (let i = 0; i < Object.keys(this.fields).length; i++) {
				const key = Object.keys(this.fields)[i];
				obj[key] = null;
			}
			this.items.push(obj);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.skeleton-table table {
	border-collapse: collapse;
	cursor: wait;
}
.skeleton-table tr td {
	border-top: 1px solid $light-grey;
	border-bottom: 1px solid $light-grey;
}
.skeleton-table tr td:last-of-type {
	border-right: 1px solid $light-grey;
}
.skeleton-table tr td:first-of-type {
	border-left: 1px solid $light-grey;
}
.skeleton-table tr:hover {
	background-color: none;
	color: none;
}
</style>