<template>
	<div class="calendar-picker" :class="{'disabled': disabled}">
		<div class="month-picker">
			<div @click="previousMonth()" class="chevron">
				<Icon icon="chevron-left" />
			</div>
			<h5 class="mb-0">
				{{ $t(firstDayOfMonth.getMonth() + 1 + '-month') }} {{ firstDayOfMonth.getFullYear() }}
			</h5>
			<div @click="nextMonth()" class="chevron">
				<Icon icon="chevron-right" />
			</div>
		</div>
		<div class="weekdays">
			<div class="weekday" v-for="(weekday, index) in weekdays" :key="index">
				{{ weekday }}
			</div>
		</div>
		<div class="days" v-if="!loading">
			<div :class="{'day': true, 'active' : day.date == value, 'disabled': notInAvailablesDates(day)}"
				v-for="day in days"
				:key="day.date.getTime()" @click="setSelectedDate(day)">
				{{ day.date.getDate() }}
			</div>
		</div>
		<b-spinner variant="primary" v-else class="loader" />
	</div>
</template>
<script>
export default {
	props : {
		value : {
			required : false,
			type     : null,
			default  : null
		},
		disabled : {
			required : false,
			type     : Boolean,
			default  : false
		},
		availablesDates : {
			required : false,
			type     : null,
			default  : null
		},
		loading : {
			required : false,
			type     : Boolean,
			default  : false,
		}
	},
	data() {
		return {
			firstDayOfMonth : null,
			firstDate       : new Date(),
		};
	},
	computed : {
		weekdays() {
			return [
				this._i18n.t('monday-abreviation'),
				this._i18n.t('tuesday-abreviation'),
				this._i18n.t('wednesday-abreviation'),
				this._i18n.t('thursday-abreviation'),
				this._i18n.t('friday-abreviation'),
				this._i18n.t('saturday-abreviation'),
				this._i18n.t('sunday-abreviation'),
			];
		},
		days() {
			const days = [];
			var date = new Date(this.firstDayOfMonth);

			var dayOfTheWeek = date.getDay() - 1;
			if(dayOfTheWeek) {
				if(dayOfTheWeek == -1) {
					dayOfTheWeek = 6;
				}
				const cloneDate = new Date(date.getDate());
				for (let i = 1; i <= dayOfTheWeek; i++) {
					days.push({
						date : new Date(cloneDate.setDate(cloneDate.getDate() - 1))
					});
				}
			}

			const month = new Date(this.firstDayOfMonth).getMonth();
			while(date.getMonth() == month) {
				days.push({
					date : new Date(date)
				});
				date.setDate(date.getDate() + 1);
			}
			return days;
		}
	},
	created() {
		this.setfirstDayOfMonth(this.firstDate);
	},
	watch : {
		firstDayOfMonth(val) {
			const lastDayOfMonth = new Date(val.getFullYear(), val.getMonth() + 1, 0);
			this.$emit('setFirstAndLastDateOfMonth', val, lastDayOfMonth);
		}
	},
	methods : {
		setfirstDayOfMonth(day) {
			if(this.availablesDates && this.availablesDates.length) {
				const sortedDates = this.availablesDates.sort((a, b) => {
					return a.getTime() - b.getTime();
				});
				this.firstDayOfMonth = new Date(sortedDates[0].getFullYear(), sortedDates[0].getMonth(), 1);
			}
			this.firstDayOfMonth = new Date(day.getFullYear(), day.getMonth(), 1);
		},
		previousMonth() {
			this.firstDayOfMonth = new Date(this.firstDayOfMonth.setMonth(this.firstDayOfMonth.getMonth() - 1));
		},
		nextMonth() {
			this.firstDayOfMonth = new Date(this.firstDayOfMonth.setMonth(this.firstDayOfMonth.getMonth() + 1));
		},
		setSelectedDate(day) {
			if(this.availablesDates && this.availablesDates.length) {
				if(this.availablesDates.find(x =>  this.checkIfSameDay(x, day.date))) {
					this.$emit('input', day.date);
				}
			} else {
				this.$emit('input', day.date);
			}
		},
		notInAvailablesDates(day) {
			if(!this.availablesDates) return false;
			else {
				if(this.availablesDates.find(x =>  this.checkIfSameDay(x, day.date))) {
					return false;
				}
				return true;
			}
		},
		checkIfSameDay(firstDate, secondDate) {
			return firstDate.getFullYear() === secondDate.getFullYear()
			&& firstDate.getDate() === secondDate.getDate()
			&& firstDate.getMonth() === secondDate.getMonth();
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.calendar-picker {
	border: 1px solid #ced4da;
	border-radius: $border-radius;
	font-weight: bold;
	padding: 5px 10px;
	min-height: 320px;
}
.calendar-picker.disabled {
	position: relative;
	pointer-events: none;
	border-color: rgba(206, 212, 218, 0.4);
	&:after {
		content: '';
		position: absolute;
		opacity: 0.4;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: $light-grey;
	}
}
.month-picker {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.days,
.weekdays {
	display: flex;
	flex-wrap: wrap;
}
.day,
.weekday {
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(14.28% - 4px);
	margin: 2px;
	height: 35px;
}
.day.disabled {
	color: $grey;
	pointer-events: none;
}
.weekday {
	color: $grey;
}
h5 {
	font-weight: bold;
}
.day {
	cursor: pointer;
	border-radius: $border-radius;
	&:hover {
		background-color: $light-grey;
	}
}
.day.active {
	background-color: var(--primary);
	color: $white;
}
.chevron {
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 1.4em;
	border-radius: 50%;
	&:hover {
		background-color: $light-grey;
	}
}
</style>
<i18n>
{
    "fr": {
        "1-month": "Janvier",
        "2-month": "Février",
        "3-month": "Mars",
        "4-month": "Avril",
        "5-month": "Mai",
        "6-month": "Juin",
        "7-month": "Juillet",
        "8-month": "Août",
        "9-month": "Septembre",
        "10-month": "Octobre",
        "11-month": "Novembre",
        "12-month": "Décembre",
        "monday-abreviation": "L",
        "tuesday-abreviation": "M",
        "wednesday-abreviation": "M",
        "thursday-abreviation": "J",
        "friday-abreviation": "V",
        "saturday-abreviation": "S",
        "sunday-abreviation": "D"
    }
}
</i18n>