<template>
	<div>
		<b-row>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('route-name')">
					<b-form-input placeholder="_" v-model="route.name.$model"
						:state="$_validateState(route.name)" name="name" />
					<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('circulation-days')">
					<DateList :date-list="route.days.$model" :input="true"
						:class="{'is-invalid': route.days.$error}" id="routedays" />
					<b-form-invalid-feedback>{{ $t('circulation-field-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('transit-time-j+')">
					<b-form-input typer="number" v-model="route.transitTime.$model" placeholder="_"
						:state="$_validateState(route.transitTime)" name="transitTime" />
					<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('type-of-handling-units')">
					<b-form-select v-model="route.handlingUnitType.$model" :options="handlingUnits"
						name="optionsUnitType" :state="$_validateState(route.handlingUnitType)">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								_
							</b-form-select-option>
						</template>
					</b-form-select>
					<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('number-of-handling-units')">
					<b-form-input placeholder="_" type="number" v-model="route.handlingUnitNumber.$model"
						:state="$_validateState(route.handlingUnitNumber)" name="handlingUnitNumber" />
					<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="4" sm="12">
				<b-form-group :label="$t('price-per-handling-unit')">
					<b-form-input placeholder="_" type="number" v-model="route.handlingUnitPrice.$model"
						:state="$_validateState(route.handlingUnitPrice)" name="price" />
					<b-form-invalid-feedback>{{ $t('field-numeric-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import DateList from './DateList';
import handlingUnitsMixins from '@/mixins/list/handlingUnits.js';
export default {
	components : {
		DateList
	},
	mixins : [
		handlingUnitsMixins
	]
	,
	props : {
		route : {
			type     : Object,
			required : true,
		}
	},
};
</script>
<i18n>
{
	"fr": {
		"circulation-field-err": "Ce champ est requis et doit contenir au moins 1 jour."
	},
	"en": {
		"circulation-field-err": "This field is required and must be contain one day."
	}
}
</i18n>