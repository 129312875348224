
import Form from './components/Form';
import ContactFields from './components/ContactFields';
import Modals from './components/Modals';
import { validateTextRequired, validateRequired,
	validatePostalCode, validatePhone, validateEmail, validateNumericRequired }
	from '@/utils/plugins/validations/vuelidate.js';
export default {
	components : {
		Form,
		ContactFields,
		Modals
	},
	data() {
		return {
			loading   : false,
			companies   : [],
			locations : [],
			location  : {
				name             : null,
				description      : null,
				interconnections : [],
				address          : {
					street     : null,
					postalCode    : null,
					city       : null,
					country    : null,
				},
				handler      : null,
				openingHours : [],
				contacts      : [],
				modals       : []

			},
		};
	},
	validations : {
		location : {
			name             : validateTextRequired,
			description      : validateTextRequired,
			interconnections : {},
			address          : {
				street     : validateTextRequired,
				postalCode    : validatePostalCode,
				country    : validateRequired,
				city       : validateTextRequired
			},
			handler      : validateRequired,
			openingHours : {},
			contacts     : {
				$each : {
					lastname  : validateTextRequired,
					mail      : validateEmail,
					job       : validateTextRequired,
					firstname : validateTextRequired,
					phone     : validatePhone
				}
			},
			modals    : {
				$each : {
					typology           : {},
					geoLocation        : {
						type        : {},
						coordinates : {
							longitude : validateNumericRequired,
							latitude  : validateNumericRequired
						}
					},
					railwayNetworkNode : {},
					tracks             : {},
					platforms          : {},
					length             : {},
					capacity           : {},
					openingHours       : {},
					address            : {
						street     : validateTextRequired,
						postalCode : validatePostalCode,
						country    : validateRequired,
						city       : validateTextRequired
					}
				}
			}
		}
	},
	created() {
		this.$store.dispatch('company/getCompanyList').then((res) => {
			this.companies = res.data.map((x) => {
				return {
					companyId   : x._id,
					companyName : x.name
				};
			});
		});
		this.$store.dispatch('locations/loadLocations').then((res) => {
			this.locations = res.results.map((x) => {
				return {
					_id  : x._id,
					name : x.name
				};
			});
		});
	},
	methods : {
		checkData() {
			if(this.$v.$invalid) {
				this.$_makeToast('danger', this._i18n.t('some-fields-contain-errors'), this._i18n.t('error'));
				return false;
			}
			if(!this.location.openingHours.length) {
				this.$_makeToast('danger', this._i18n.t('location-must-be-contains-opening-hours'),
					this._i18n.t('error'));
				return false;
			}
			for (let i = 0; i < this.location.modals.length; i++) {
				const modal = this.location.modals[i];
				if(!modal.openingHours.length) {
					this.$_makeToast('danger', this._i18n.t('each-modal-must-be-contains-opening-hours'),
						this._i18n.t('error'));
					return false;
				}
			}
			const find = this.locations.find((x) => {
				return x.name == this.location.name;
			});
			if(find != undefined && this.location._id != find._id) {
				this.$_makeToast('danger', this._i18n.t('this-name-is-already-taken-by-another-location'),
					this._i18n.t('error'));
				return false;
			}
			return true;
		},
		setData() {
			this.location.address.country = this.location.address.country.alpha2;
			for (let i = 0; i < this.location.modals.length; i++) {
				const modal = this.location.modals[i];
				modal.address.country = modal.address.country.alpha2;
			}
			for (let i = 0; i < this.location.modals.length; i++) {
				const modal = this.location.modals[i];
				const coordinates = this.$_clone(modal.geoLocation.coordinates);
				modal.geoLocation.coordinates = [coordinates.latitude, coordinates.longitude];
			}
		}
	}
};