<template>
	<main id="404">
		<h1>{{ $t('error') }} 403</h1>
		<p>{{ $t('dont-have-access') }}. <a href="/">{{ $t('return-to-home') }}</a></p>
	</main>
</template>

<script>
export default {
	name : 'Error404',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('error') + ' 404',
		};
	},
};
</script>
<i18n>
{
    "fr": {
        "dont-have-access": "Vous n'avez pas accès à cette page"
    },
    "en": {
        "dont-have-access": "You don't have access to this content"
    }
}
</i18n>