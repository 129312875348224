<template>
	<b-card no-body>
		<b-card-header class="d-flex justify-content-between align-items-center">
			{{ $t('calendar') }}
			<div class="d-flex text-initial" v-if="$route.name != 'VisualizationContracts'">
				<div class="d-flex align-items-center" v-if="isOwner">
					<legend class="mb-0 mr-2">
						{{ $t('charger') }}
					</legend>
					<b-form-checkbox v-model="getOwnerCalendarValidate" class="success"
						switch size="lg" />
				</div>
				<div class="d-flex mt-1 align-items-center" v-if="$route.name == 'EditContracts'">
					<legend class="mb-0 mx-2">
						{{ $t('provider') }}
					</legend>
					<b-form-checkbox :disabled="isOwner" v-model="getActorCalendarValidate" class="success"
						switch size="lg" />
				</div>
			</div>
		</b-card-header>
		<b-card-body>
			<p class="text-initial">
				{{ $t('start-date') }} : {{ calendar.start | getDateMonthYear }} <br />
				{{ $t('end-date') }} : {{ calendar.end | getDateMonthYear }} <br />
				{{ $t('planning') }} : {{ calendar.planning | getDateMonthYear }} <br />
				{{ $t('pre-invoice-conditions') }} : {{ $t(calendar.preInvocing) }} <br />
			</p>
		</b-card-body>
	</b-card>
</template>
<script>

export default {
	props : {
		calendar : {
			required : true,
			type     : Object
		},
		isOwner : {
			required : true,
			type     : Boolean
		},
		actors : {
			required : true,
			type     : Array
		}
	},
	computed : {
		getOwnerCalendarValidate : {
			get() {
				return this.actors[0].calendarValidate;
			},
			set(val) {
				this.actors[0].calendarValidate = val;
			}
		},
		getActorCalendarValidate : {
			get() {
				if(this.isOwner) {
					for (let i = 1; i < this.actors.length; i++) {
						const actor = this.actors[i];
						if(!actor.calendarValidate) return false;
					}
					return true;
				}
				return this.actors.find((x) => x.companyId == this.user.company._id).calendarValidate;
			},
			set(val) {
				if(this.isOwner) return false;
				return this.actors.find((x) => x.companyId == this.user.company._id).calendarValidate = val;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
p {
	font-weight: 600;
}
</style>