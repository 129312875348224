<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('period-type')">
			<b-form-select v-model="periodType" :options="options">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	computed : {
		options() {
			return [
				{
					value : 'quarter',
					text  : this._i18n.t('quarter')
				}
			];
		}
	},
	data() {
		return {
			periodType : null,
		};
	},
	methods : {
		reset() {
			this.periodType = 'quarter';
		}
	},
};
</script>