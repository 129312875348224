<template>
	<div class="schedules">
		<div class="d-flex">
			<legend>
				{{ title }}
			</legend>
			<b-button variant="primary" class="btn-icon-only ml-2" @click="showModal()">
				<Icon icon="calendar" />
			</b-button>
		</div>
		<div :class="'technical-informations ' + theme">
			<ul>
				<li v-for="(day, index) in getDayOfWeek" :key="index">
					<span>{{ getLabelOfDay(day.dayOfWeek) }}</span>

					<span v-if="findSchedulesByDay(day.dayOfWeek)">
						<span v-for="(daytime, inde) in findSchedulesByDay(day.dayOfWeek).times" :key="inde">
							<div class="d-flex">
								{{ daytime.departureTime.slice(0, -3) }} - {{ daytime.arrivalTime.slice(0, -3) }}
								<div v-if="daytime.journeyTime > 0" class="ml-1">
									(J+{{ daytime.journeyTime }})
								</div>
							</div>
						</span>
					</span>
					<span v-else-if="day.hours">
						<div v-for="(hour, h) in day.hours" :key="h" class="d-flex">
							{{ hour.start.slice(0, -3) }} - {{ hour.end.slice(0, -3) }}
						</div>
					</span>
					<span v-else />
				</li>
			</ul>
		</div>
		<SchedulesModal :schedules="schedules" :id="id" :title="title" :typology="typology"
			@set-schedules="setSchedules" />
	</div>
</template>
<script>
import DateMixins from '@/mixins/date.js';
import SchedulesModal from './SchedulesModal';
export default {
	name   : 'Schedules',
	mixins : [
		DateMixins
	],
	components : {
		SchedulesModal
	},
	props : {
		schedules : {
			required : true,
			type     : Array,

		},
		theme : {
			required : false,
			type     : String,
			default() {
				return '';
			}
		},
		title : {
			required : true,
			type     : String
		},
		id : {
			required : true,
			type     : String
		},
		typology : {
			required : true,
			type     : String
		}
	},
	computed : {
		getDayOfWeek() {
			return [
				{ dayOfWeek : 1 },
				{ dayOfWeek : 2 },
				{ dayOfWeek : 3 },
				{ dayOfWeek : 4 },
				{ dayOfWeek : 5 },
				{ dayOfWeek : 6 },
				{ dayOfWeek : 0 },
			];
		}

	},
	methods : {
		findSchedulesByDay(dayOfWeek) {
			const find = this.schedules.find((x) => x.dayOfWeek == dayOfWeek);
			return find;
		},
		showModal() {
			this.$root.$emit('bv::show::modal', 'modal-schedules-' + this.id);
		},
		setSchedules(schedules) {
			this.$emit('set-schedules', schedules);
		},
	}
};
</script>
