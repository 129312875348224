<template>
	<div id="pricing">
		<b-card no-body>
			<b-card-header class="primary">
				{{ $t('establish-pricing-conditions') }}
			</b-card-header>
			<b-card-body>
				<Condition @add-condition="addCondition" :actors="contract.actors" @edit-condition="editCondition"
					:selected-condition="selectedCondition" @delete-condition="deleteCondition"
					:segments="contract.services.filter(s=>s.type=='segment').map(s=>s.segment)" />
				<b-card no-body v-if="selectedCondition">
					<b-card-header>{{ $t('penalties-conditions') }}</b-card-header>
					<b-card-body>
						<Threshold v-if="selectedCondition.unitsPenalties == 'threshold'"
							:condition="selectedCondition" @set-range-threshold="setRangeThreshold" />
						<Time v-if="selectedCondition.unitsPenalties == 'time'" :condition="selectedCondition"
							@set-range-time="setRangeTime" />
					</b-card-body>
				</b-card>
				<Results v-if="contract.rates.length" :rates="contract.rates" @on-selected-row="onSelectedRow"
					ref="results" :selected-condition="selectedCondition"
					:segments="contract.services.filter(s=>s.type=='segment').map(s=>s.segment)" />
			</b-card-body>
		</b-card>
		<div class="mt-3 d-flex justify-content-end">
			<b-button variant="info" @click="previousStep">
				{{ $t('previous') }}
			</b-button>
			<b-button variant="primary" class="ml-1" :disabled="contract.rates.length == 0" @click="nextStep"
				id="next-pricing">
				{{ $t('next') }}
			</b-button>
		</div>
	</div>
</template>
<script>
import { Condition, Results,  Time, Threshold } from './pricing/';
export default {
	components : {
		Condition,
		Time,
		Threshold,
		Results
	},
	props : {
		contract : {
			type     : Object,
			required : true,
		}
	},
	data() {
		return {
			selectedCondition : null,
		};
	},
	methods : {
		previousStep() {
			this.$emit('go-to-step', 'calendar');
		},
		nextStep() {
			this.$emit('go-to-step', 'validation');
		},
		addCondition(condition) {
			condition.index = this.contract.rates.length;
			condition.typology = condition.typology.text;
			this.contract.rates.push(condition);
			this.selectedCondition = null;
			/*this.$nextTick(() => {
				this.$refs.results.selectRow(this.contract.rates.length - 1);
			});*/
		},
		onSelectedRow(item) {
			if(item) {
				if(item.typology && !item.typology.text) {
					item.typology = {
						text : item.typology,
						icon : this.findIconType(item.typology)
					};
				}

				this.selectedCondition = item;
			} else {
				this.selectedCondition = null;
			}
		},
		setRangeThreshold(range) {
			const currentCondition = this.contract.rates.find((x) => x.index == this.selectedCondition.index);
			currentCondition.range = range;
		},
		setRangeTime(range) {
			const currentCondition = this.contract.rates.find((x) => x.index == this.selectedCondition.index);

			currentCondition.range = range;
		},
		deleteCondition(id) {
			const index = this.contract.rates.findIndex((x) => x.id == id);
			this.contract.rates.splice(index, 1);
			this.selectedCondition = null;
		},
		editCondition(condition) {
			const index = this.contract.rates.findIndex((x) => x.id == condition.id);
			this.contract.rates.splice(index, 1, condition);
			this.selectedCondition = null;
		},
		findIconType(text) {
			const opt = [
				{
					text : 'ROAD',
					icon : 'truck'
				},
				{
					text : 'RAIL',
					icon : 'train'
				},
				{
					text : 'AIR',
					icon : 'airplane'
				},
				{
					text : 'SEA',
					icon : 'boat'
				},
				{
					text : 'RIVER',
					icon : 'river'
				},
			];
			return opt.find((x) => x.text == text).icon;
		},
	}
};
</script>
<i18n>
{
	"fr":{
		"establish-pricing-conditions": "Etablir les conditions tarifaires"
	},
	"en": {
		"etablish-pricing-conditions": "Establish pricing conditions"
	}
}
</i18n>