export default {
	computed : {
		transportsUnits() {
			return [
				{
					value : 'wagons',
					text  : this._i18n.t('wagons')
				},
				{
					value : 'trucks',
					text  : this._i18n.t('trucks')
				},
				{
					value : 'maritime-container',
					text  : this._i18n.t('maritime-container')
				},
				{
					value : 'multimodal-container',
					text  : this._i18n.t('multimodal-container')
				},
				{
					value : 'barge',
					text  :  this._i18n.t('barge')
				},
			];
		}
	}
};