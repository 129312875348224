<template>
	<b-card no-body class="informations w-100" :style="'margin-top: ' + top + 'px'">
		<b-card-header>{{ $t('technical-characteristics') }}</b-card-header>
		<b-card-body>
			<b-row>
				<b-col lg="6">
					<MultipleSelect v-model="handling.operator" :options="actors" track="name"
						:label="$t('operator')" />
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('starting-time')">
						<b-form-timepicker v-model="handling.startTime" placeholder="_" />
					</b-form-group>
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('duration')">
						<b-form-timepicker v-model="handling.operationTime" placeholder="_" />
					</b-form-group>
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('days')">
						<b-input-group size="md" prepend="J+">
							<b-form-input placeholder="_" v-model="handling.day" type="number" />
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	props : {
		top : {
			required : true,
			type     : Number
		},
		handling : {
			required : true,
			type     : Object,
		},
		actors : {
			required : true,
			type     : Array,
		}
	}
};
</script>