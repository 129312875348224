<template>
	<div id="app">
		<div v-if="$store.getters['user/get']" class="app-wrapper">
			<vue-ins-progress-bar />
			<Nav />
			<div id="overlay" />
			<div id="main-content">
				<Header />
				<router-view />
			</div>
		</div>
		<div v-else id="loginOut" class="app-wrapper">
			<router-view />
		</div>
	</div>
</template>
<script>
import Theme from '@/mixins/theme';
import Nav from '@/components/app/Nav.vue';
import Header from '@/components/app/Header.vue';
export default {
	name       : 'App',
	components : {
		Nav,
		Header
	},
	mixins : [
		Theme
	],
	data() {
		return {};
	},
	metaInfo()  {
		return {
			title         : 'App',
			titleTemplate : '%s | MyModalOne'
		};
	},
	mounted() {
		this.$insProgress.finish();
	},
	created() {
		this.$insProgress.start();

		this.$router.beforeEach((to, from, next) => {
			const user = this.user;
			if(user && user.template) {
				this.setTheme(user.template);
			} else {
				this.setTheme(['#046af3', '#061b6e', '#0542b0']);
			}
			this.$insProgress.start();
			next();
		});
		this.$router.afterEach(() => {
			this.$insProgress.finish();
		});
		if(localStorage.getItem('language')) this._i18n.locale = localStorage.getItem('language');
	},
};
</script>
<style lang="scss">
@import 'assets/scss/import.scss';
#app > .app-wrapper {
	height: fit-content;
	min-height: 100vh;
	display: flex;
}
#app #loginOut {
	color: $white;
	width: 100%;
	background: var(--secondary);
	background: linear-gradient(45deg, var(--secondary) 0%, var(--primary) 100%);
}
#loginOut a {
	color: $white;
	text-decoration: underline;
}
#main-content {
	width: 100%;
	max-height: 100vh;
	position: relative;
	overflow-y: auto;
}
#main-content main {
	min-height: calc(100% - 60px);
}
#overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: $black;
	z-index: 8;
	opacity: 0.5;
	display: none;
}
.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -1rem;
	margin-top: -1rem;
}
.ins-progress {
	background: var(--primary) !important;
}

@include media-breakpoint-down(md) {
	.open-nav #overlay {
		display: block;
	}
	.ins-progress {
		background: $white !important;
	}
}
</style>
