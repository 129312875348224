<template>
	<div id="results">
		<b-table head-variant="light" selectable :striped="true" responsive
			:empty-text="$t('no-data-to-display')" :fields="fields" :items="rates"
			@row-selected="onRowSelected" select-mode="single" ref="results">
			<template v-slot:cell()="{value}">
				<div v-if="!/\d/.test(value)">
					{{ $t(value) }}
				</div>
				<div v-else>
					{{ value }}
				</div>
			</template>
			<template #cell(typology)="{value}">
				<div v-if="!value.text" class="d-flex align-items-center justify-content-center">
					<Icon :icon="findIconType(value)" />
					{{ $t(value) }}
				</div>
				<div v-if="value.text" class="d-flex align-items-center justify-content-center">
					<Icon :icon="(value.icon)" />
					{{ $t(value.text) }}
				</div>
			</template>
			<template #cell(responsibility)="{value}">
				<div>
					{{ value.companyName }}
				</div>
			</template>
		</b-table>
	</div>
</template>
<script>
export default {
	props : {
		rates : {
			required : true,
			type     : Array,
		},
		selectedCondition : {
			required : false,
			type     : Object,
			default() {
				return null;
			}
		},
		segments : {
			required : true,
			type     : Array,
		}

	},
	computed : {
		segmentsTypes() {
			const types = [];
			for (let i = 0; i < this.segments.length; i++) {
				const seg = this.segments[i];
				const type = {
					icon : this.findIconType(seg.typology),
					text : seg.typology
				};
				types.push(type);
			}

			return types;
		},
		fields() {
			return [
				{
					label : this._i18n.t('typology'),
					key   : 'typology',
				},
				{
					label : this._i18n.t('responsibility'),
					key   : 'responsibility',
				},
				{
					label : this._i18n.t('type-of-trip'),
					key   : 'trajectType',
				},
				{
					label : this._i18n.t('type-of-penalties'),
					key   : 'unitsPenalties',
				},
				{
					label : this._i18n.t('units-of-penalties'),
					key   : 'unitsPenalties',
				},
				{
					label : this._i18n.t('percentage-road'),
					key   : 'percentage',
				}
			];
		}
	},
	watch : {
		selectedCondition(val) {
			if(!val) {
				this.$refs.results.clearSelected();
			}
		},
	},
	methods : {
		onRowSelected(items) {
			this.$emit('on-selected-row', items[0]);
		},
		selectRow(index) {
			this.$nextTick(() => {
				this.$refs.results.selectRow(index);
				this.$emit('on-selected-row', this.rates[index]);
			});
		},
		findIconType(text) {
			const opt = [
				{
					text : 'ROAD',
					icon : 'truck'
				},
				{
					text : 'RAIL',
					icon : 'train'
				},
				{
					text : 'AIR',
					icon : 'airplane'
				},
				{
					text : 'SEA',
					icon : 'boat'
				},
				{
					text : 'RIVER',
					icon : 'river'
				},
			];
			return opt.find((x) => x.text == text || x.text == text.text).icon;
		},
	}
};
</script>