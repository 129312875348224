<template>
	<ul class="text-grey legends child-mx-4">
		<li>
			<div class="rail" />
			<p>{{ $t('rail') }}</p>
		</li>
		<li>
			<div class="road" />
			<p>{{ $t('road') }}</p>
		</li>
		<li>
			<div class="text-green mb-0">
				<Icon icon="flag" />
			</div>
			<p>{{ $t('finished') }}</p>
		</li>
		<li>
			<div class="planned" />
			<p>{{ $t('planned') }}</p>
		</li>
		<li>
			<div class="unplanned" />
			<p>{{ $t('unplanned') }}</p>
		</li>
		<li>
			<div class="delay" />
			<p>{{ $t('delay') }}</p>
		</li>
		<li>
			<div class="text-red mb-0">
				<Icon icon="alert" />
			</div>
			<p>{{ $t('alert') }}</p>
		</li>
	</ul>
</template>

<script>
export default {
	name : 'Legends',
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.legends {
	display: flex;
	align-items: baseline;
	font-size: 0.8em;
}
.legends li {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.legends li > div {
	margin-bottom: 4px;
}
.legends .rail,
.legends .road {
	width: 60px;
	height: 10px;
	border-radius: 25px;
	background-color: var(--thirdary);
}
.legends .road {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	&:after {
		content: '';
		display: flex;
		position: absolute;
		width: 100%;
		height: 2px;
		top: 5px;
		margin-top: -1px;
		background: repeating-linear-gradient(to right, $white 0, $white 5px, transparent 5px, transparent 9px);
	}
}
.legends .planned,
.legends .unplanned,
.legends .delay {
	width: 1.2em;
	height: 1.2em;
	border-radius: 50%;
}
.legends .planned {
	background-color: var(--primary);
}
.legends .delay {
	background-color: $yellow;
}
.legends .unplanned {
	border: 2px solid $grey;
}
</style>
