import { required, minLength, maxLength, email, sameAs, decimal, integer,
	minValue, numeric } from 'vuelidate/lib/validators';

export const validateText = {
	minLength : minLength(2)
};
export const validateTextRequired = {
	required,
	minLength : minLength(2)
};
export const validateSiren = {
	required,
	numeric,
	minLength : minLength(9),
	maxLength : maxLength(9)
};
export const validateNic = {
	required,
	numeric,
	minLength : minLength(5),
	maxLength : maxLength(5)
};
const checkVAT = (vat) => {
	if(/^[A-Z]{2}[0-9]{6,11}$/.test(vat) === false) return false;
	return true;
};
export const validateVAT = {
	required,
	checkVAT
};
export const validatePostalCode = {
	required,
	numeric,
	minLength : minLength(5),
	maxLength : maxLength(5)
};
export const validatePostalCodeNotReq = {
	decimal,
	minLength : minLength(5),
	maxLength : maxLength(5)
};
export const validateRequired = {
	required
};
export const validateEmail = {
	required,
	email
};
export const validateConfirmEmail = {
	required,
	sameAs : sameAs('email')
};
const checkPasswordStrengh = (password) => {
	if(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(password) === false) return false;
	return true;
};
export const validatePassword = {
	required,
	minLength : minLength(8),
	checkPasswordStrengh
};

export const validateConfirmPassword = {
	required,
	sameAs : sameAs('password')
};
const isPhone = (value) => /^((\+)33|0)[1-9](\d{2}){4}$/.test(value);
export const validatePhone = {
	required,
	isPhone
};
export const validateNumeric = {
	numeric
};
export const validateNumericRequired = {
	required,
	numeric
};
export const valideType = {
	required,
	minValue : minValue(20),
	integer
};

export const validateDecimal = {
	decimal
};
export const validateDecimalRequired = {
	decimal,
	required
};
export const validateNumericNoReq = {
	numeric
};