<template>
	<div id="select-segment">
		<b-card no-body>
			<b-card-header>{{ $t('segment') }}</b-card-header>
			<b-card-body>
				<form @submit="submit">
					<b-row>
						<b-col lg="12">
							<MultipleSelect :options="segments" v-model="segment" track="label"
								:label="$t('select-a-segment')" />
						</b-col>
						<b-col lg="12" v-if="!loading">
							<b-form-group :label="$t('select-scheduled-segments')" v-if="scheduledSegments.length > 1">
								<ScheduledSegment v-for="(seg) in scheduledSegments" :key="seg._id"
									:segment="seg" :active="seg._id == selectedScheduledSegment._id"
									@click.native="setSelectedScheduledSegment(seg)" />
							</b-form-group>
							<b-form-group :label="$t('selected-scheduled-segment')"
								v-if="scheduledSegments.length == 1">
								<ScheduledSegment v-for="(seg) in scheduledSegments" :key="seg._id"
									:segment="seg" :active="true" />
							</b-form-group>
						</b-col>
						<b-col v-else-if="segment" class="my-3">
							<b-spinner class="loader" variant="primary" />
						</b-col>
					</b-row>
					<b-button variant="info" v-if="segment"
						@click="$bvModal.show('modal-calendar')">
						{{ $t('choose-an-other-date') }}
					</b-button>
					<ModalCalendar :date="date" @set-date="setDate" :segment="segment" />
				</form>
			</b-card-body>
		</b-card>
		<Footer previous-step-path="/operation/location">
			<b-button variant="primary" :disabled="!segment ||
					!selectedScheduledSegment._id"
				@click="submit">
				{{ $t('next') }}
			</b-button>
		</Footer>
	</div>
</template>
<script>
import Moment from 'moment';
import { ScheduledSegment, ModalCalendar } from './SelectSegment/';
import Footer from './components/Footer.vue';
export default {
	components : {
		Footer,
		ScheduledSegment,
		ModalCalendar
	},
	data() {
		return {
			segments                  : [],
			segment                   : null,
			date                      : new Date(),
			loading                  : false,
			scheduledSegments         : [],
			selectedScheduledSegment  : {}
		};
	},
	created() {
		const locationId = this.$store.getters['operation/get'].location._id;
		this.$store.dispatch('transportationSegments/getSegmentsByLocationId', locationId).then(res => {
			this.segments = res.map(x => {
				return {
					_id   : x._id,
					label : x.origin.name + ' > ' + x.destination.name
				};
			});
		}).catch(err => {
			this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
		});
	},
	computed : {
		segmentAndDate() {
			return `${this.segment}|${this.date}`;
		}
	},
	watch : {
		segmentAndDate() {
			this.loadingScheduledSegments = true;
			const data = {
				segment   : this.segment,
				startDate : Moment(this.date).format('YYYY-MM-DD'),
				endDate   : Moment(this.date).format('YYYY-MM-DD')
			};
			this.loading = true;
			this.$store.dispatch('scheduledSegments/get', data).then(res => {
				this.scheduledSegments = res;
				if(res.length == 1) {
					this.selectedScheduledSegment = this.scheduledSegments[0];
				} else if(!res.length) {
					this.$_makeToast('danger', this._i18n.t('this-segment-is-not-scheduled-for-the'
					) + ` ${Moment(this.date).format('DD/MM/YYYY')}.`, this._i18n.t('error'));
				}
				this.loading = false;
			}).catch(err => {
				this.loading = false;
				this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
			});
		}
	},
	methods : {
		setSelectedScheduledSegment(seg) {
			this.selectedScheduledSegment = seg;
		},
		setDate(date) {
			this.date = date;
		},
		submit() {
			this.$store.commit('operation/setSegment', this.selectedScheduledSegment);
			this.$router.push('/operation/state');
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>
<i18n>
{
    "fr":{
		"select-scheduled-segments": "Sélectionner un segment programmé",
		"select-a-segment": "Sélectionner un segment",
		"choose-an-other-date": "Choisir une autre date",
		"selected-scheduled-segment": "Segment programmé séléctionné",
		"this-segment-is-not-scheduled-for-the": "Ce segment n'est pas programmé pour le"
    },
    "en":{
		"select-scheduled-segments": "Select scheduled segment",
		"select-a-segment": "Select a segment",
		"choose-an-other-date": "Choose an other date",
		"selected-scheduled-segment": "Selected scheduled segment",
		"this-segment-is-not-scheduled-for-the": "This segment is not scheduled pour le"
    }
}
</i18n>