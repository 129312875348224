import { Orders, ListOrders, CreateOrder, PlanningOrders, ValidationsOrders, UpdateOrder } from '@/views/orders';
import { Operation, ConfirmationOperation, IncidentOperation, LocationOperation, SelectOrderOperation,
	SelectSegmentOperation, StateOperation, ListIncidentOperation } from '@/views/operation';
import Error404 from '@/views/Error404.vue';
import Error403 from '@/views/Error403.vue';
import Settings from '@/views/Settings.vue';
import store from '@/store';

export const common = [
	{
		path     : '/',
		redirect : () => {
			const user = store.getters['user/get'] || { scope : ['guest'] };
			if(user.scope.includes('guest')) {
				return '/login';
			} else if(user.scope.includes('super') || user.scope.includes('operator')
			|| user.scope.includes('charger') || user.scope.includes('admin')) {
				return '/dashboard';
			} else if(user.scope.includes('handler')) {
				return '/operation/location';
			} else if(user.scope.includes('driver')) {
				return '/operation/location';
			}
		}
	},
	{
		path       : '/orders',
		name       : 'orders',
		component  : Orders,
		icon       : 'orders',
		title     : 'orders',
		meta      : {
			permission : ['super', 'charger', 'admin', 'operator']
		},
		index      : 3,
		submenu    : true,
		redirect : 'orders/list',
		children   : [
			{
				path      : 'create-order',
				name      : 'CreateOrder',
				title     : 'creation',
				icon      : 'add-circle',
				component : CreateOrder,
				meta      : {
					subitem    : true,
					permission : ['super', 'charger', 'admin']
				},
			},
			{
				path      : 'update/:id',
				name      : 'UpdateOrder',
				title     : 'update',
				component : UpdateOrder,
				meta      : {
					permission : ['super', 'charger', 'admin', 'operator']
				},
			},
			{
				path      : 'validation',
				name      : 'validation',
				title     : 'validation',
				icon      : 'checkmark',
				component : ValidationsOrders,
				meta      : {
					subitem    : true,
					permission : ['super', 'charger', 'admin', 'operator']
				},
			},
			{
				path        : 'planning',
				icon        : 'calendar',
				title       : 'planning',
				subitem     : true,
				name        : 'planning',
				component   : PlanningOrders,
				meta      : {
					subitem    : true,
					permission : ['super', 'charger', 'admin']
				}
			},
			{
				path      : 'list',
				name      : 'list',
				title     : 'tracking',
				icon      : 'list',
				component : ListOrders,
				meta      : {
					subitem    : true,
					permission : ['super', 'charger', 'admin', 'operator']
				},
			},
		]
	},
	{
		path      : '/operation',
		name      : 'operation',
		component : Operation,
		redirect  : 'operation/location',
		meta       : {
			permission : ['super', 'admin', 'handler', 'charger', 'driver']
		},
		children  : [
			{
				path      : 'location',
				name      : 'LocationOperation',
				component : LocationOperation,
				meta       : {
					permission : ['super', 'admin', 'handler', 'charger', 'driver']
				}
			},
			{
				path      : 'select-order',
				name      : 'SelectOrderOperation',
				component : SelectOrderOperation,
				meta       : {
					permission : ['super', 'admin', 'handler', 'charger', 'driver']
				}
			},
			{
				path      : 'select-segment',
				name      : 'SelectSegmentOperation',
				component : SelectSegmentOperation,
				meta       : {
					permission : ['super', 'admin', 'handler', 'charger', 'driver']
				}
			},
			{
				path      : 'state',
				name      : 'StateOperation',
				component : StateOperation,
				meta       : {
					permission : ['super', 'admin', 'handler', 'charger', 'driver']
				}
			},
			{
				path      : 'incident',
				name      : 'IncidentOperation',
				component : IncidentOperation,
				meta       : {
					permission : ['super', 'admin', 'handler', 'charger', 'driver']
				}
			},
			{
				path      : 'Confirmation',
				name      : 'ConfirmationOperation',
				component : ConfirmationOperation,
				meta       : {
					permission : ['super', 'admin', 'handler', 'charger', 'driver']
				}
			},
			{
				path      : 'list-incidents',
				name      : 'ListIncidentOperation',
				component : ListIncidentOperation,
				meta      : {
					permission : ['super', 'admin', 'handler', 'charger', 'driver']
				}
			}
		],
	},
	{
		path      : '*',
		name      : 'error404',
		component : Error404,
	},
	{
		path      : '/403',
		name      : 'error403',
		component : Error403,
	},
	{
		path      : '/settings',
		name      : 'settings',
		component : Settings,
	},
];
