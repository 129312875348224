var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"requester"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h1',{staticClass:"font-header mb-4"},[_vm._v(" "+_vm._s(_vm.$t('order-requester'))+" ")]),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.extractXLSX('requesterTable')}}},[_c('Icon',{attrs:{"icon":"download"}}),_vm._v(" "+_vm._s(_vm.$t('extract'))+" ")],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"sm":"12"}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"card-header"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapseclient",arg:"collapseclient"}],staticClass:"btn rounded mr-3 ml-3 btn-outline-light",attrs:{"type":"button","id":"filter"}},[_c('span',{staticClass:"glyphicon glyphicon-cog"}),_vm._v(" "+_vm._s(_vm.$t('identity-input'))+" ")]),_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapserail",arg:"collapserail"}],staticClass:"btn rounded mr-3 btn-outline-light",attrs:{"type":"button","id":"filter"}},[_vm._v(" "+_vm._s(_vm.$t('routes-input'))+" ")]),_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle:collapseothers",arg:"collapseothers"}],staticClass:"btn rounded mr-3 btn-outline-light",attrs:{"type":"button","id":"filter"}},[_vm._v(" "+_vm._s(_vm.$t('others-input'))+" ")])]),_c('b-collapse',{attrs:{"id":"collapseclient"}},[_c('div',{staticClass:"card-body"},[_c('b-form-tags',{staticClass:"border-0",attrs:{"add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var disabled = ref.disabled;
var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-1"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"primary"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(" "+_vm._s(tag)+" ")])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({staticClass:"input-round",attrs:{"options":_vm.getOptionsMultiple(_vm.internalSchemaclient, _vm.clientItems),"disabled":disabled ||
										_vm.getOptionsMultiple(_vm.internalSchemaclient, _vm.clientItems).length === 0},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(_vm.$t('identity-input'))+" ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers))]}}]),model:{value:(_vm.clientItems),callback:function ($$v) {_vm.clientItems=$$v},expression:"clientItems"}})],1)]),_c('b-collapse',{attrs:{"id":"collapserail"}},[_c('div',{staticClass:"card-body"},[_c('b-form-tags',{staticClass:"border-0",attrs:{"add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var tags = ref.tags;
										var inputAttrs = ref.inputAttrs;
										var inputHandlers = ref.inputHandlers;
										var disabled = ref.disabled;
										var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-1"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"primary"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(" "+_vm._s(tag)+" ")])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({staticClass:"input-round",attrs:{"options":_vm.getOptionsMultiple(_vm.internalSchemaroute, _vm.railItems),"disabled":disabled ||
										_vm.getOptionsMultiple(_vm.internalSchemaroute, _vm.railItems).length === 0},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(_vm.$t('routes-input'))+" ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers))]}}]),model:{value:(_vm.railItems),callback:function ($$v) {_vm.railItems=$$v},expression:"railItems"}})],1)]),_c('b-collapse',{attrs:{"id":"collapseothers"}},[_c('div',{staticClass:"card-body"},[_c('b-form-tags',{staticClass:"border-0",attrs:{"add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var tags = ref.tags;
										var inputAttrs = ref.inputAttrs;
										var inputHandlers = ref.inputHandlers;
										var disabled = ref.disabled;
										var removeTag = ref.removeTag;
return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-1"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"primary"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(" "+_vm._s(tag)+" ")])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({staticClass:"input-round",attrs:{"options":_vm.getOptionsMultiple(_vm.internalSchemaOther, _vm.otherItems),"disabled":disabled ||
										_vm.getOptionsMultiple(_vm.internalSchemaOther, _vm.otherItems).length === 0},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" "+_vm._s(_vm.$t('others-input'))+" ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers))]}}]),model:{value:(_vm.otherItems),callback:function ($$v) {_vm.otherItems=$$v},expression:"otherItems"}})],1)]),_c('b-card-footer',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(['collapseclient', 'collapserail', 'collapseothers']),expression:"['collapseclient', 'collapserail', 'collapseothers']"}],staticClass:"p-0 d-flex justify-content-center"},[_c('div',{staticClass:"toggle-details"},[_c('Icon',{staticClass:"when-closed",attrs:{"icon":"chevron-down"}})],1)])],1)],1)],1),_c('div',{staticClass:"tab-header border-radius-top mt-3"},[_c('Filters',[_c('Clients'),_c('Roads',{attrs:{"roads":_vm.routes}}),_c('DepartureDateEarly'),_c('DepartureDateLate')],1)],1),_c('b-table',{attrs:{"head-variant":"light","striped":true,"hover":"","responsive":"","show-empty":"","empty-text":_vm.$t('no-data-to-display'),"fields":_vm.fields,"items":_vm.items,"current-page":_vm.pagination.currentPage,"per-page":_vm.pagination.perPage,"id":"requesterTable"},scopedSlots:_vm._u([{key:"cell(type)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.type))+" "),_c('span',{staticStyle:{"display":"none"}},[_vm._v(" teeeeeeest ")])]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.status))+" ")]}},{key:"cell(route)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.displayRoute(data.item.trs[0]))+" ")]}},{key:"cell(departure_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("getFullDate")((data.item.departure_date)))+" ")]}},{key:"cell(arrival_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("getFullDate")((data.item.arrival_date)))+" ")]}}])}),_c('Pagination',{attrs:{"pagination":_vm.pagination}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }