<template>
	<b-card no-body class="informations w-100" :style="'margin-top: ' + top + 'px'">
		<b-card-header>{{ $t('technical-characteristics') }}</b-card-header>
		<b-card-body>
			<Form :station="station" :actors="actors" :segment="segment" />
			<Modals :station="station" :active="typology" v-if="station.modals" />
		</b-card-body>
	</b-card>
</template>
<script>
import { Form, Modals } from './station/';
export default {
	components : {
		Form,
		Modals
	},
	props : {
		top : {
			required : true,
			type     : Number
		},
		station : {
			required : true,
			type     : Object
		},
		actors : {
			required : true,
			type     : Array,
		},
		segment : {
			required : false,
			type     : Object,
			default() {
				return {};
			}
		},
		typology : {
			required : true,
			type     : null
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>