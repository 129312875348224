<template>
	<div id="registrations">
		<Table :items="registrations" :fields="fields" :loading="loading" @load="load" :title="$t('registrations')"
			:pagination="pagination" :reload="reload">
			<template v-slot:filters>
				<Search />
				<Status />
			</template>
			<template v-slot:action="data">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="registerDetail(data.item)" id="viewregistration">
								<Icon icon="eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon" id="removeregistration"
								@click="openModalRefuse(data.item)">
								<Icon icon="trash" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
			<template v-slot:type="data">
				{{ $t(data.item.company.type) }}
			</template>
		</Table>
		<Modal id="modal-refuse" :title="$t('refuse-registration')" @confirm="refuse">
			<template v-slot:content>
				<p class="text-initial">
					{{ $t('refuse-text-modal') }} <b>{{ currentRegistration.user.lastname }}
						{{ currentRegistration.user.firstname }}</b> ?
				</p>
			</template>
		</Modal>
	</div>
</template>

<script>
import { Search, Status } from '@/components/filters/components';

export default {
	name       : 'Registrations',
	components : {
		Search,
		Status,
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('registrations'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('company-name'),
					key      : 'company.name',
					sortable : true,
				},
				{
					label    : this._i18n.t('company-type'),
					key      : 'type',
					sortable : true,
				},
				{
					label    : this._i18n.t('siren'),
					key      : 'company.siret.siren',
					sortable : true,
				},
				{
					label    : this._i18n.t('nic'),
					key      : 'company.siret.nic',
					sortable : true,
				},
				{
					label    : this._i18n.t('firstname'),
					key      : 'user.firstname',
					sortable : true,
				},
				{
					label    : this._i18n.t('lastname'),
					key      : 'user.lastname',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			loading    : true,
			pagination : {
				perPage     : 10,
				totalRows  	: 0,
				currentPage : 1,
			},
			currentRegistration : {
				user : {}
			},
			registrations : [],
			reload        : false
		};
	},

	methods : {
		load() {
			this.loading = true;
			this.$store.dispatch('registers/LOAD').then((res) => {
				if(res && res.pagination && res.pagination.length > 0) {
					this.registrations = res.results;
					this.pagination.totalRows = res.pagination[0].count;
				}
				this.loading = false;
				this.reload = false;
			}).catch(() => {
				this.loading = false;
				this.reload = false;
			});
		},
		registerDetail(item) {
			this.$router.push({
				name   : 'SendRegistration',
				params : {
					id : item._id
				}
			});
		},
		openModalRefuse(registration) {
			this.currentRegistration = registration;
			this.$bvModal.show('modal-refuse');
		},
		trash(id) {
			this.$bvModal.hide('modal-refuse');
			this.$store.dispatch('registers/DELETE', id).then(() => {
				this.reload = true;
			}).catch((err) => {
				this.$_makeToast('danger',
					this._i18n.t(err),
					this._i18n.t('error')
				);
			});
		},
		refuse() {
			this.$emit('refuse-registration', this.registration._id);
			this.load();
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>
<i18n>
{
    "fr": {
        "refuse-text-modal": "Souhaitez-vous vraiment supprimer la demande d'inscription de"
    },
    "en": {
        "refuse-text-modal": "Do you really want to delete the registration of"
    }
}
</i18n>