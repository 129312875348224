<template>
	<b-row>
		<b-col lg="6" md="12">
			<b-form class="child-mb-3">
				<MultipleSelect :options="this.actors.map((x) => x.name)"
					v-model="station.handler.companyName" @input="setHandler" :searchable="true"
					:label="$t('HANDLER')" />
				<div v-if="station.address">
					<b-form-group :label="$t('address')">
						<b-form-input placeholder="_" v-model="station.address.street" />
					</b-form-group>
					<b-form-group :label="$t('city')">
						<b-form-input placeholder="_" v-model="station.address.city" />
					</b-form-group>
					<b-form-group :label="$t('postal-code')">
						<b-form-input placeholder="_" v-model="station.address.postalCode" />
					</b-form-group>
				</div>
				<div v-if="segment._id">
					<div v-if="isDeparture">
						<b-form-group :label="$t('departure-date')">
							<DatePicker placeholder="_" v-model="segment.departureDate" />
						</b-form-group>
						<b-form-group :label="$t('start-time')" class="mt-3">
							<b-form-timepicker placeholder="_" v-model="segment.departureTime" />
						</b-form-group>
					</div>
					<div v-if="isArrival">
						<b-form-group :label="$t('arrival-date')">
							<DatePicker placeholder="_" v-model="segment.arrivalDate" />
						</b-form-group>
						<b-form-group :label="$t('arrival-time')" class="mt-3">
							<b-form-timepicker placeholder="_" v-model="segment.arrivalTime" />
						</b-form-group>
					</div>
				</div>
			</b-form>
		</b-col>
		<b-col lg="6" md="12">
			<Schedules :schedules="station.openingHours || []"
				:title="$t('opening-hours-days')" theme="light" @set-schedules="setTimetable" id="station-form" />
		</b-col>
	</b-row>
</template>
<script>
import Schedules from '@/components/common/Schedules.vue';
export default {
	props : {
		station : {
			required : true,
			type     : Object
		},
		actors : {
			required : true,
			type     : Array
		},
		segment : {
			required : true,
			type     : Object
		}
	},
	components : {
		Schedules
	},
	computed : {
		isDeparture() {
			return this.station.name == this.segment.origin.name;
		},
		isArrival() {
			return this.station.name == this.segment.destination.name;
		}
	},
	methods : {
		setTimetable(timetable) {
			if (timetable) {
				this.station.openingHours = timetable;
			} else {
				this.station.openingHours = [];
			}
		},
		setHandler(name) {
			const handler = this.actors.find((a) => a.name === name);
			this.station.handler = {
				companyName : handler.name,
				companyId   : handler.companyId
			};
			this.station.address = this.$_clone(handler.address);
		}
	}
};
</script>