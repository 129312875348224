<template>
	<div class="step-by-step">
		<div class="step" v-for="(step, index) in steps" :key="index" :class="getClass(index)"
			@click="goToStep(index)" :style="'width : ' + getWidth() + '%;'">
			<div class="identification">
				<p>
					{{ $t(step) }}
					<span v-if="percentageStep.step == step">
						({{ percentageStep.value }} %)
					</span>
				</p>
				<div class="number" id="number">
					{{ index + 1 }}
				</div>
				<ProgressCircle v-if="percentageStep.step == step" radius="22" :progress="percentageStep.value"
					stroke="3" color="#08c21b" />
				<div class="barBefore" />
			</div>
			<div class="bar" />
		</div>
	</div>
</template>
<script>
import ProgressCircle from './ProgressCircle';
export default {
	props : {
		steps : {
			required : true,
			type     : Array,
		},
		currentStep : {
			required : true,
			type     : String,
		},
		align : {
			required : false,
			type     : String,
			default() {
				return '';
			}
		},
		percentageStep : {
			required : false,
			type     : Object,
			default() {
				return {};
			}
		}
	},
	components : {
		ProgressCircle
	},
	data() {
		return {
			widthFirstElement : null
		};
	},
	methods : {
		getClass(index) {
			const currentIndex = this.steps.findIndex((x) => x == this.currentStep);
			const classList = [];
			if(this.align != 'center') classList.push('d-flex');
			if(currentIndex == index) classList.push('current');
			if(currentIndex > index) classList.push('done');
			if(currentIndex == (index - 1)) classList.push(' after-current');
			let classString = '';
			classList.forEach((e, i) => {
				if(i == 0) {
					classString += e;
				} else {
					classString += ' ' + e;
				}
			});
			return classString;
		},
		goToStep(index) {
			/*const currentIndex = this.steps.findIndex((x) => x == this.currentStep);
			 if(currentIndex > index) { */
			this.$emit('go-to-step', this.steps[index]);
			/* } */
		},
		getWidth() {
			return (100 / this.steps.length);
		}
	},
	mounted() {
		this.widthFirstElement = this.$el.getElementsByClassName('step')[0]
			.getElementsByClassName('identification')[0].offsetWidth;
	},

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.step-by-step {
	margin-bottom: 1rem;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	width: 100%;
}
.step {
	position: relative;
	width: 20%;
	margin-bottom: 0.5rem;
}
.step:last-of-type .bar,
.step:nth-child(5n) .bar {
	background-color: $white;
	width: 100%;
}
.step:first-of-type .barBefore,
.step:nth-child(6n) .barBefore {
	background-color: $white;
}
.identification {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	flex-direction: column;
}
p {
	color: $grey;
	margin-bottom: 0.25rem;
	text-align: center;
}
.number {
	background-color: $grey;
	color: $white;
	border-radius: 50%;
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 4;
}
.bar,
.barBefore {
	background-color: $grey;
	width: 100%;
	left: 0;
	height: 3px;
	position: absolute;
	bottom: 12px;
	z-index: 1;
}
.barBefore {
	z-index: 2;
	width: 50%;
}
.after-current .barBefore {
	background-color: var(--primary);
}
.current p {
	color: var(--primary);
}
.current .number {
	background-color: var(--primary);
}
.current .bar {
	background-color: var(--primary);
}
.done .identification {
	cursor: pointer;
}
.done p {
	color: $green;
}
.done .number {
	background-color: $green;
}
.done .bar {
	background-color: $green;
}
.done .barBefore {
	background-color: $green;
}
.current .barBefore {
	background-color: $green;
}
/deep/.progress-circle {
	position: absolute;
	bottom: -7px;
	z-index: 3;
	overflow: initial;
}
</style>
