<template>
	<main id="update-order">
		<h1 class="mb-4">
			{{ $t('update-order') }}  {{ order.internalCode }}
		</h1>
		<b-spinner class="loader" variant="primary" v-if="loading" />


		<Form v-if="order.transportRoutes && order.departureDate" :locations="selectedContract.locations"
			:order="order" :is-owner="isOwner" :actors="selectedContract.actors"
			:order-handle="$v.orderHandle" @tr-change="change" />


		<div class="d-flex align-items-center flex-column">
			<div class="d-flex mt-3">
				<b-button variant="info" @click="reset">
					{{ $t('cancel') }}
				</b-button>
				<b-button variant="primary" class="btn-icon ml-2"
					:disabled="!order.departureDate || !order.transportRoutes "
					@click="save()">
					<Icon icon="save" />
					{{ $t('save') }}
				</b-button>
			</div>
		</div>
	</main>
</template>
<script>
import { Form } from './components/createOrder';
import { validateNumericRequired, validateText } from '@/utils/plugins/validations/vuelidate';
import Moment from 'moment';

export default {
	name       : 'UpdateOrder',
	components : {
		Form
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('update-order'),
		};
	},
	data() {
		return {
			order : {
				wagons          : [],
				transportRoutes   : null,
			},
			selectedContract : {},
			isOwner          : false,
			orderHandle            : {},
			loading          : false,
			newOrder         : {}
		};
	},
	watch : {

		'orderHandle.transitTime' : {
			handler(val, oldVal) {
				if (oldVal) {
					this.newOrder.transitTime = val;
				}
			}
		},
		'orderHandle.handlingUnitType' : {
			handler(val, oldVal) {
				if (oldVal) {
					this.newOrder.handlingUnitType = val;
				}
			}
		},
		'orderHandle.handlingUnitPrice' : {
			handler(val, oldVal) {
				if (oldVal) {
					this.newOrder.handlingUnitPrice = val;
				}
			}
		},
		'orderHandle.handlingUnitNumber' : {
			handler(val, oldVal) {
				if (oldVal) {
					this.newOrder.handlingUnitNumber = val;
				}
			}
		},
	},
	created() {
		this.loading = true;
		// get selected contract
		const selectedContractCode = this.$store.getters['contracts/selected'];
		if (selectedContractCode) {
			this.$store.dispatch('contracts/loadList').then(res =>{
				this.selectedContract = res.results.find(c=>c.code === selectedContractCode);
				if(this.selectedContract && this.selectedContract.state !== 'VALIDATED') {
					this.$_makeToast('danger', this._i18n.t('choose-contract'), this._i18n.t('error'));
				}
				const companyOwner = this.selectedContract.actors.find(actor=>actor.owner);
				this.isOwner = companyOwner._id === this.user.company._id;
				const query = { _id   : this.$route.params.id,
					query : 'segmentDetails=true' };
				this.$store.dispatch('orders/getOrder', query).then((res) => {
					this.order = res;
					this.setSegments(res);
					this.orderHandle = {
						transitTime        : res.transitTime,
						handlingUnitType   : res.handlingUnitType,
						name               :  res.name,
						handlingUnitPrice  :  res.handlingUnitPrice,
						handlingUnitNumber :  res.handlingUnitNumber,
					};
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					this.$_makeToast('danger', err, this._i18n.t('error'));
				});
			}).catch((err) => {
				this.$_makeToast('danger', err, this._i18n.t('error'));
			});
		} else {
			// TO DO: open popup and ask for choosing a valid contrat
			this.$_makeToast('danger', this._i18n.t('choose-contract'), this._i18n.t('error'));
		}
		// get modified order
	},
	methods : {
		reset() {
			this.order.wagons = [];
			this.order.transportRoutes = null;
			this.order.departureDate = null;

			Array.from(document.getElementsByTagName('tr')).forEach((e) => {
				e.classList.remove('active');
			});
			Array.from(document.getElementsByClassName('container-item ')).forEach((e) => {
				e.classList.remove('active');
			});
		},
		setSegments(val) {
			for (let route = 0; route < val.transportRoutes.length; route++) {
				for (let seg = 0; seg < val.transportRoutes[route].segments.length; seg++) {
					val.transportRoutes[route].segments[seg].departureDate = Moment(val.transportRoutes[route]
						.segments[seg].tracking.departure.estimated).format('YYYY-MM-DD');
					val.transportRoutes[route].segments[seg].departureTime = Moment(val.transportRoutes[route]
						.segments[seg].tracking.departure.estimated).format('HH:mm');
					val.transportRoutes[route].segments[seg].arrivalDate = Moment(val.transportRoutes[route]
						.segments[seg].tracking.arrival.estimated).format('YYYY-MM-DD');
					val.transportRoutes[route].segments[seg].arrivalTime = Moment(val.transportRoutes[route]
						.segments[seg].tracking.arrival.estimated).format('HH:mm');
				}
			}
		},
		save() {
			if(!this.$v.orderHandle.$invalid) {
				this.newOrder._id = this.order._id;
				if (this.newOrder.transportRoutes) {
					for (let r = 0; r < this.newOrder.transportRoutes.length; r++) {
						const road = this.newOrder.transportRoutes[r];
						for (let index = 0; index < road.segments.length; index++) {
							const segment = { ...road.segments[index] };

							road.segments[index].tracking.departure.estimated =
					(segment.departureDate + ' ' + segment.departureTime );
							road.segments[index].tracking.arrival.estimated =
					(segment.arrivalDate + ' ' + segment.arrivalTime );
							delete road.segments[index].departureDate;
							delete road.segments[index].arrivalDate;
							delete road.segments[index].departureTime;
							delete road.segments[index].arrivalTime;
						}
					}
				}
				this.$store.dispatch('orders/update', this.newOrder)
					.then(()=>{
						this.$router.push('/orders/validation');
					}).catch((err) => {
						this.$_makeToast('danger', err, this._i18n.t('error'));
					});
			}
		},
		change(trs) {
			this.newOrder.transportRoutes = [...trs];
		}
	},
	validations : {
		orderHandle : {
			transitTime           : validateNumericRequired,
			handlingUnitType    : validateText,
			name               : validateText,
			handlingUnitPrice              : validateNumericRequired,
			handlingUnitNumber : validateNumericRequired,


		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#info-order {
	font-weight: 600;
}
#info-order li > span {
	min-width: 100px;
	display: inline-block;
	&:first-child {
		color: $grey;
	}
	&:last-child {
		color: var(--thirdary);
	}
}
#info-order li p {
	display: flex;
	color: var(--thirdary);
}
/deep/ .date-list {
	display: none;
}
</style>
<i18n>
{
	"fr": {

        "missing-dates": "Des dates ou des horaires sont manquants dans les segments.",
		"update-order" : "Modifier la commande "
	},
	"en": {
         "missing-dates": "Dates or hours are missing in the segment information.",
		"update-order" : "Modifier la commande "


	}
}
</i18n>