<template>
	<b-card no-body class="informations w-100" :style="'margin-top: ' + top + 'px'">
		<b-card-header>{{ $t('technical-characteristics') }}</b-card-header>
		<b-card-body>
			<Form :segment="segment" :actors="actors" :typology="typology" />
			<Modals :segment="segment" :typology="typology" :id="id" />
		</b-card-body>
	</b-card>
</template>
<script>
import { Form, Modals } from './typology/';
export default {
	components : {
		Form,
		Modals
	},
	props : {
		top : {
			required : true,
			type     : Number
		},
		segment : {
			required : true,
			type     : Object,
		},
		typology : {
			required : true,
			type     : null
		},
		actors : {
			required : true,
			type     : Array,
		},

		id : {
			required : true,
			type     : String
		}
	}
};
</script>