<template>
	<div class="schedules">
		<div class="d-flex">
			<legend>
				{{ title }}
			</legend>
			<b-button variant="primary" class="btn-icon-only ml-2" @click="showModal()" id="calendarbutton">
				<Icon icon="calendar" />
			</b-button>
		</div>
		<div :class="'my-1 w-100 technical-informations ' + theme">
			<ul>
				<li v-for="(day, index) in getSchedules" :key="index">
					<span>{{ $t(daysLabel[day.dayOfWeek]) }}</span>
					<span v-if="day.hours">
						<div v-for="(hour, indhour) in day.hours" :key="indhour" class="w-100 d-flex">
							<div v-if="hourIsClosed(hour)">
								{{ $t('closed') }}
							</div>
							<div v-else-if="hourIsOpen2424(hour)">
								{{ $t('open-24-24') }}
							</div>
							<div v-else>
								{{ hour.start.slice(0, -3) }} - {{ hour.end.slice(0, -3) }}
							</div>
							<div v-if="day.dayPlus" class="ml-1">
								{{ $t('day-plus') }}	{{ day.dayPlus }}
							</div>
						</div>
					</span>
					<span v-else />
				</li>
			</ul>
		</div>
		<SchedulesModal :schedules="schedules" @set-schedules="setSchedules" :title="title" :multiple="multiple"
			:days-label="daysLabel" :id="id" :day-plus="dayPlus" />
	</div>
</template>
<script>
import SchedulesModal from './SchedulesModal';
export default {
	name       : 'Schedules',
	components : {
		SchedulesModal
	},
	props : {
		schedules : {
			required : true,
			type     : Array,
		},
		theme : {
			required : false,
			type     : String,
			default() {
				return '';
			}
		},
		title : {
			required : false,
			type     : String,
			default() {
				return this._i18n.t('schedules');
			}
		},
		multiple : {
			required : false,
			type     : Boolean,
			default() {
				return false;
			}
		},
		id : {
			required : true,
			type     : String,
		},
		dayPlus : {
			required : false,
			type     : Boolean,
			default  : false
		}
	},
	data() {
		return {
			daysLabel : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
		};
	},
	computed : {
		getSchedules() {
			if(this.schedules && this.schedules.length) {
				return this.schedules;
			} else {
				return [
					{ dayOfWeek : 0 },
					{ dayOfWeek : 1 },
					{ dayOfWeek : 2 },
					{ dayOfWeek : 3 },
					{ dayOfWeek : 4 },
					{ dayOfWeek : 5 },
					{ dayOfWeek : 6 },
				];
			}
		}
	},
	methods : {
		setSchedules(schedules) {
			this.$emit('set-schedules', schedules);
		},
		showModal() {
			this.$root.$emit('bv::show::modal', 'modal-schedules-' + this.id);
		},
		hourIsClosed(hour) {
			if(hour.start == 'closed' && hour.end == 'closed') {
				return true;
			}
			return false;
		},
		hourIsOpen2424(hour) {
			if(hour.start == '00:00:00' && hour.end == '23:59:59') {
				return true;
			}
			return false;
		}
	}
};
</script>
