<template>
	<div id="visualization">
		<div class="d-flex justify-content-between mb-3">
			<h1>{{ $t('contract') }} : {{ contract.code }}</h1>
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)" id="return">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<div class="d-flex justify-content-end">
			<div id="validation" class="mb-3">
				<p>{{ $t('validation') }}</p>
				<div class="circle mt-1">
					{{ getAllValidation }} %
				</div>
				<ProgressCircle radius="29" :progress="getAllValidation"
					stroke="3" color="#08c21b" />
			</div>
		</div>
		<Identification :contract="contract"
			:get-user-segments="getUserSegments" :get-user-locations="getUserLocations"
			:is-owner="isOwner" :get-user-routes="getUserRoutes" :get-user-manut="getUserManut"
			id="identification" />
		<Locations :locations="contract.locations" :is-owner="isOwner" id="location" />
		<Segments :get-user-segments="getUserSegments" :is-owner="isOwner"
			v-if="getUserSegments && getUserSegments.length > 0" id="segments" />
		<TransportPlan v-if=" getUserRoutes && getUserRoutes.length > 0" :get-user-segments="getUserSegments"
			:get-user-locations="getUserLocations" id="transportPlan"
			:is-owner="isOwner" :get-user-routes="getUserRoutes" />
		<Pricing :rates="contract.rates" :segments="contract.services.filter(s=>s.type=='segment').map(s=>s.segment)"
			id="pricing" />
	</div>
</template>
<script>
import { Identification, Locations, Segments, TransportPlan, Pricing } from './visualization/';
import ValidationMixins from './mixins/validation';
import ProgressCircle from '@/components/common/ProgressCircle';
export default {
	components : {
		Identification,
		Locations,
		Segments,
		TransportPlan,
		Pricing,
		ProgressCircle
	},
	mixins : [
		ValidationMixins
	],
	data() {
		return {
			contract : {
				routes    : [],
				rates     : [],
				actors    : [],
				calendar  : {},
				services  : [],
				locations : []
			},
			id : null
		};
	},
	computed : {
		getContractStore() {
			return this.$store.getters['contracts/contract'];
		},
		isOwner() {
			if(this.contract.actors.length) {
				return this.user.company._id == this.contract.actors[0].companyId;
			} else {
				return false;
			}
		}
	},
	async created() {
		this.id = this.$route.params.id;
		await this.$store.dispatch('contracts/loadContract', this.id).then(() => {
			if(this.getContractStore) {
				this.contract = this.getContractStore;
				this.$store.commit('contracts/selected', this.contract.code);
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('contracts/contract', null);
		this.$store.commit('contracts/selected', this.contract.code);
		next();
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#validation {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	color: var(--primary);
}
#validation .circle {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	color: $white;
	background-color: var(--primary);
}
/deep/.progress-circle {
	position: absolute;
	bottom: -6px;
}
</style>