import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

let routes = [];
import { guest } from './guest';
import { admin } from './admin';
import { common } from './common';
routes = routes.concat(common, guest, admin);

const router = new VueRouter({
	mode : 'history',
	routes,
	scrollBehavior() {
		const main = document.getElementById('main-content');
		if(main) main.scrollTop = 0;
	}
});
import { roles } from './roles';
router.beforeEach((to, from, next) => {
	let user = store.getters['user/get'] || { scope : ['guest'] };
	const permission = to.meta.permission || roles;

	if(permission[0] == 'guest') {
		store.commit('user/set', null);
		user = { scope : ['guest'] };
	}
	for (let i = 0; i < user.scope.length; i++) {
		const scope = user.scope[i];
		if(permission.includes(scope)) {
			return next();
		}
	}
	return next('/403');
});

export default router;
