<template>
	<b-row id="modals">
		<b-col lg="4" class="d-flex align-items-end">
			<b-form-group :label="$t('add-modal')" class="">
				<b-form-select v-model="modalType" :options="typesOfRoad" name="modaltype">
					<template v-slot:first>
						<b-form-select-option :value="null" disabled>
							_
						</b-form-select-option>
					</template>
				</b-form-select>
			</b-form-group>
			<div id="add-modal-type">
				<b-button variant="primary" class="btn-icon btn-input ml-1" @click="newTab()" :disabled="!modalType"
					id="addmodaltype">
					<Icon icon="add" />
					{{ $t('add') }}
				</b-button>
				<b-tooltip target="add-modal-type" v-if="!modalType" triggers="hover">
					{{ $t('select-a-modal-type') }}
				</b-tooltip>
			</div>
		</b-col>
		<b-col lg="12">
			<b-tabs class="light" v-model="tabIndex" v-if="tabs.length">
				<b-tab v-for="(tab, i) in tabs" :key="i" :title="getTypesTitles(tab)">
					<Road :location="location" v-if="tab == 'ROAD'" :index="i" />
					<Rail :location="location" v-else-if="tab == 'RAIL'" :index="i" />
					<Others :location="location" :index="i" v-else />
					<div class="d-flex justify-content-end">
						<b-button variant="outline-danger" @click="closeTab(i)">
							{{ $t('delete-modal') }}
						</b-button>
					</div>
				</b-tab>
			</b-tabs>
		</b-col>
	</b-row>
</template>

<script>
import typesOfRoad from '@/mixins/list/typesOfRoad.js';
import { Rail, Road, Others } from './modals/';
import countriesMixins from '@/mixins/countries.js';
export default {
	mixins : [
		typesOfRoad, countriesMixins
	],
	components : {
		Rail,
		Road,
		Others,
	},
	props : {
		location : {
			required : true,
			type     : Object,
		}
	},
	data() {
		return {
			modalType   : null,
			tabIndex    : 0,
			tabs        : [],
		};
	},
	created() {
		const modals = this.location.modals.$model;
		if(modals.length) {
			for (let i = 0; i < modals.length; i++) {
				const modal = modals[i];
				this.tabs.push(modal.typology);

				if(modal.address) {
					const country = modal.address.country;
					modal.address.country = this.findCountry(country);
					//this.location.modals.$reset();
				}
			}
		}
	},
	methods : {
		closeTab(x) {
			this.tabs.splice(x, 1);
			this.location.modals.$model.splice(x, 1);
		},
		newTab() {
			const findIndex = this.tabs.findIndex((x) => {
				return x == this.modalType;
			});
			if(findIndex != -1) {
				return this.$_makeToast('danger',
					this._i18n.t('this-modal-is-already-present'),
					this._i18n.t('error'));
			}
			this.updateLocation(this.modalType);
			this.tabs.push(this.modalType);
			this.modalType = null;
		},
		getTypesTitles(tab) {
			return this.typesOfRoad.find((x) => x.value == tab).text;
		},
		updateLocation(val) {
			if (val) {
				const modal = {
					typology    : val,
					geoLocation        : {
						type        : 'Point',
						coordinates : {
							longitude : null,
							latitude  : null,
						},
					},
					platforms       : null,
					lengthCapcity   : null,
					capacity      : null,
					openingHours  : [],
					address       : {
						street        : null,
						postalCode    : null,
						city          : null,
						country       : null,
					},
				};
				if (val == 'rail') {
					modal.railwayNetworkNode = null;
				}
				this.location.modals.$model.push(modal);
			}
		},

	}
};
</script>
<i18n>
{
	"fr": {
		"this-modal-is-already-present": "Cette modale est déjà présent dans la liste.",
		"delete-modal": "Supprimer cette modale",
		"select-a-modal-type": "Sélectionner un type de modale"
	},
	"en": {
		"this-modal-is-already-present": "This modal is already present in the list.",
		"delete-modal": "Delete this modal",
		"select-a-modal-type": "Select a modal type"
	}
}
</i18n>