<template>
	<div class="handling" :id="id">
		<div class="container-info" @click="$emit('set-selected', 'handling')"
			:class="{'selected': isSelected}" v-if="handling">
			{{ $t(handling) }}
		</div>
	</div>
</template>
<script>
export default {
	props : {
		handling : {
			type     : String,
			required : true
		},
		isSelected : {
			required : true,
			type     : Boolean
		},
		id : {
			required : true,
			type     : String
		}
	}
};
</script>