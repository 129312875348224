import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
	extends : Bar,
	mixins  : [reactiveProp],
	props   : {
		chartdata : {
			type    : Object,
			default : null
		},
		options : {
			type    : Object,
			default : null
		}
	},
	computed : {
		datasets() {
			return this.chartData.datasets;
		},
		suggestedMax() {
			return this.options.scales.yAxes[0].ticks.suggestedMax;
		},
		labels() {
			return this.chartData.labels;
		}
	},
	watch : {
		datasets() {
			this.$data._chart.update();
		},
		suggestedMax() {
			this.renderChart(this.chartData, this.options);
		},
		labels() {
			this.$data._chart.update();
		}

	},
	mounted() {
		this.$el.classList.add('canvas-container');
		this.renderChart(this.chartData, this.options);
	}
};