<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('status')">
			<b-form-select v-model="status" :options="options">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	props : {
		items : {
			type     : Array,
			required : false,
			default() {
				return [];
			}
		}
	},
	computed : {
		options() {
			return [
				{ value : true,
					text  : this._i18n.t('active') },
				{ value : false,
					text  : this._i18n.t('inactive') },

			];
		},

	},
	data() {
		return {
			status : null,
		};
	},
	watch : {
		status(val) {
			this.$emit('status', val);
		}
	}

};
</script>