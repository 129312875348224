export default {
	computed : {
		companyTypes() {
			return [
				{
					value : 'SHIPPER',
					text  : this._i18n.t('SHIPPER')
				},
				{
					value : 'FREIGHT_FORWARDER',
					text  : this._i18n.t('FREIGHT_FORWARDER')
				},
				{
					value : 'RAIL_CARRIER',
					text  : this._i18n.t('RAIL_CARRIER')
				},
				{
					value : 'ROAD_CARRIER',
					text  : this._i18n.t('ROAD_CARRIER')
				},
				{
					value : 'WATERWAY_CARRIER',
					text  : this._i18n.t('WATERWAY_CARRIER')
				},
				{
					value : 'MARITIME_CARRIER',
					text  : this._i18n.t('MARITIME_CARRIER')
				},
				{
					value : 'AIR_CARRIER',
					text  : this._i18n.t('AIR_CARRIER')
				},
				{
					value : 'CARRIER',
					text  : this._i18n.t('CARRIER')
				},
				{
					value : 'HANDLER',
					text  : this._i18n.t('HANDLER')
				},
				{
					value : 'RAIL_HANDLER',
					text  : this._i18n.t('RAIL_HANDLER')
				},
				{
					value : 'RENTER',
					text  : this._i18n.t('RENTER_WAGON')
				},
				{
					value : 'MAINTENANCE',
					text  : this._i18n.t('MAINTENANCE_COMPANY')
				},
			];
		}
	},
};