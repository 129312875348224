<template>
	<div class="btn-icon statut text-primary">
		<Icon v-if="segment.typology == 'RAIL'" icon="train" />
		<Icon v-if="segment.typology == 'ROAD'" icon="truck" />
		<Icon v-if="segment.typology == 'AIR'" icon="airplane" />
		<Icon v-if="segment.typology == 'RIVER'" icon="river" />
		<Icon v-if="segment.typology == 'SEA'"
			icon="boat" />
		<Icon v-if="segment.type == 'RAIL' && segment.state == 'empty'" icon="train-outline" />
		<Icon v-if="segment.type == 'RAIL' && segment.state == 'full'" icon="train" />
		<Icon v-if="segment.type == 'RAIL' && segment.state == 'maintenance'" icon="entretien" />
		<Icon v-if="segment.type== 'ROAD'" icon="truck" />
	</div>
</template>
<script>
export default {
	name  : 'Statut',
	props : {
		segment : {
			type     : Object,
			required : true
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>