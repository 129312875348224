<template>
	<main>
		<b-spinner variant="white" />
	</main>
</template>
<script>
export default {

};
</script>
<style lang="scss" scoped>
main {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

</style>