<template>
	<div class="date-list" :class="{'input': input}">
		<ul class="child-mx-1">
			<li v-for="(day, index) in days" :key="index"
				@click="toggleActive(day)" :class="{'active' : checkDateList(day)}">
				{{ $t(day.text +'-initial') }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name  : 'DateList',
	props : {
		dateList : {
			type     : Array,
			required : true,
		},
		input : {
			type     : Boolean,
			required : false,
			default() {
				return false;
			}
		}
	},
	data() {
		return {
			days   : [
				{ text  : 'monday',
					value : 1 },
				{ text  : 'tuesday',
					value : 2 },
				{ text  : 'wednesday',
					value : 3 },
				{ text  : 'thursday',
					value : 4 },
				{ text  : 'friday',
					value : 5 },
				{ text  : 'saturday',
					value : 6 },
				{ text  : 'sunday',
					value : 0 }
			]
		};
	},
	methods : {
		toggleActive(day) {
			if(this.input) {
				const index = this.dateList.findIndex((x) => x == day.value);
				if(index == -1) {
					this.dateList.push(day.value);
				} else {
					this.dateList.splice(index, 1);
				}
			}
		},
		checkDateList(day) {
			const index = this.dateList.findIndex((x) => x == day.value);
			if(index != -1) return true;
			return false;
		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.date-list ul {
	display: flex;
}
.date-list li {
	font-weight: 700;
	width: 30px;
	height: 30px;
	border-radius: $border-radius;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $light-grey;
}
.date-list li.active {
	background-color: $green;
}
.date-list li.desactive {
	background-color: $red;
}
.date-list li.primary {
	background-color: var(--primary);
}
.date-list li.grey {
	background-color: $grey;
}
.maintenance-msg {
	font-size: smaller;
	color: $grey;
}
.date-list.input {
	cursor: pointer;
}
</style>
<i18n>
{
	"fr": {
		"monday-initial": "L",
		"tuesday-initial": "M",
		"wednesday-initial": "M",
		"thursday-initial": "J",
		"friday-initial": "V",
		"saturday-initial": "S",
		"sunday-initial": "D"
	},
	"en": {
		"monday-initial": "M",
		"tuesday-initial": "T",
		"wednesday-initial": "W",
		"thursday-initial": "T",
		"friday-initial": "F",
		"saturday-initial": "S",
		"sunday-initial": "S"
	}
}
</i18n>