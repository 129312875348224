<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('period-def')">
			<b-form-select v-model="periodDef" :options="options">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	computed : {
		options() {
			return [
			];
		}
	},
	data() {
		return {
			periodDef : null,
		};
	}
};
</script>