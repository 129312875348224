<template>
	<b-card no-body id="wagons">
		<b-card-header>
			{{ $t('wagons') }}
		</b-card-header>
		<b-card-body>
			<b-form>
				<b-form-group :label="$t('choose-a-file')">
					<b-form-file placeholder="_" :browse-text="$t('browse')" />
				</b-form-group>
				<b-form-group :label="$t('enter-a-registration-number')">
					<b-form-input placeholder="_" />
				</b-form-group>
				<b-form-group :label="$t('group')">
					<b-form-select :value="null" :options="group">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								_
							</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>
			</b-form>
			<table>
				<thead>
					<tr>
						<th>{{ $t('registration') }}</th>
						<th>{{ $t('length') }}</th>
						<th>{{ $t('evp') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="wagon in wagons" :key="wagon.registration" @click="selectWagon">
						<td class="registration">
							{{ wagon.registration }}
						</td>
						<td>{{ wagon.length }}</td>
						<td>{{ wagon.evp }}</td>
					</tr>
				</tbody>
			</table>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	name  : 'Wagons',
	props : {
		order : {
			type     : Object,
			required : true,
		}
	},
	data() {
		return {
			group : [
				'Parc 1',
				'Parc 2'
			],
			wagons : [
				{
					rank         : '1',
					registration : '33-87-4584-048-0',
					length       : '13,84',
					evp          : '2'
				},
				{
					rank         : '1',
					registration : '33-87-4584-048-1',
					length       : '10,89',
					evp          : '2'
				},
				{
					rank         : '1',
					registration : '33-87-4584-048-2',
					length       : '9,88',
					evp          : '2'
				},
				{
					rank         : '1',
					registration : '33-87-4584-048-3',
					length       : '13,24',
					evp          : '2'
				},
				{
					rank         : '1',
					registration : '33-87-4584-048-4',
					length       : '14,45',
					evp          : '2'
				},
			]
		};
	},
	methods : {
		selectWagon($event) {
			const tr = $event.target.closest('tr');
			const registration = tr.getElementsByClassName('registration')[0].innerHTML;
			const wagons = this.order.wagons;
			tr.classList.toggle('active');
			if(wagons.includes(registration)) this.order.wagons = wagons.filter(e => e !== registration);
			else wagons.push(registration);
		}
	},

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
	border-left: 4px solid transparent;
}
table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 2px;
}
tbody tr {
	cursor: pointer;
	&:not(.active):hover {
		background: var(--primary);
		color: $white;
		transition: $transition;
	}
}
tbody tr.active td {
	border-top: 3px solid $green;
	border-bottom: 3px solid $green;
	border-right: 1px solid $green;
	border-left: 1px solid $green;
	&:first-of-type {
		border-left: 3px solid $green;
	}
	&:last-of-type {
		border-right: 3px solid $green;
	}
}
tr td,
tr th {
	padding: 0.375rem 0.75rem;
}
tr td {
	border: 1px solid $light-grey;
	border-right: 0;
	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	&:last-child {
		border-right: 1px solid $light-grey;
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}
}
</style>