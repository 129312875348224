<template>
	<div id="update-wagon">
		<h1 class="mb-4">
			{{ $t('view-wagon') }}
		</h1>
		<div class="d-flex justify-content-between align-items-baseline">
			<Breadcrumbs />
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-identification class="primary d-flex justify-content-between">
				{{ $t('information-id') }}
				<Icon icon="chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-identification" visible accordion="accordion" class="mt-2">
				<b-card-body>
					<Identification :identification="$v.wagon" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-renting class="primary d-flex justify-content-between">
				{{ $t('information-rental') }}
				<Icon icon="chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-renting" accordion="accordion" class="mt-2">
				<b-card-body>
					<renting :renting="$v.renting" />
				</b-card-body>
			</b-collapse>
		</div>
		<div>
			<b-card-header v-b-toggle.collapse-maintenance class="primary d-flex justify-content-between">
				{{ $t('information-maintenance') }}
				<Icon icon="chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-maintenance" accordion="accordion" class="mt-2">
				<b-card-body>
					<Maintenance :maintenance="$v.maintenance" />
				</b-card-body>
			</b-collapse>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '../wagons/components/Breadcrumbs.vue';
import Identification from '../wagons/components/Identification.vue';
import Renting from '../wagons/components/Renting.vue';
import Maintenance from '../wagons/components/Maintenance.vue';
import { valideType, validateTextRequired, validateDecimal, validateDecimalRequired }
	from '@/utils/plugins/validations/vuelidate';
import { vWagonCode } from '@/utils/plugins/validations/wagons';
import { HTTP } from '../../http-common';

//import Comments from '../wagons/components/Comments.vue';


export default {
	name       : 'UpdateWagon',
	components : {
		Breadcrumbs,
		Identification,
		Renting,
		Maintenance,
		//Comments
	},
	validations : {
		wagon : {
			code           : vWagonCode,
			type           : valideType,
			family         : validateTextRequired,
			payload        : validateDecimal,
			tare           : validateDecimal,
			TEU            : validateDecimalRequired,
			axesNumber     : validateDecimal,
			length         : validateDecimal,
			brakingWeight  : validateDecimal,
			kmUsage        : validateDecimal,
		},
		renting            : {
			company        : validateTextRequired,
			cost           : validateDecimal,
			startContract  : validateTextRequired,
			endContract    : validateTextRequired
		},
		maintenance : {
			company         : validateTextRequired,
			type            : validateTextRequired,
			nextDate        : validateTextRequired,
			nextKilometers  : validateDecimal,
			locationName    : validateTextRequired,
			periodicityKM   : validateDecimal,
			periodicityDate : validateTextRequired
		}
	},

	async created() {
		// to do : récupérer données de renting, maintenance, comments
		const id = this.$route.params.id;
		await HTTP.get('/wagons/' + id).then(res => {
			if (res.data) {
				this.wagon =  res.data;
			}
		}).catch((err)=>{
			this.$_makeToast('danger',
				this._i18n.t(err),
				this._i18n.t('error')
			);
		});
	},
	data() {
		return {
			wagon       : {},
			renting     : {},
			maintenance : {}

		};
	},

};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>