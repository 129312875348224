<template>
	<Modal id="modal-calendar" @confirm="emitDate">
		<template slot="content">
			<b-form-group :label="$t('select-a-date')">
				<CalendarPicker v-model="dateCalendar" @setFirstAndLastDateOfMonth="setFirstAndLastDateOfMonth"
					:availables-dates="availablesDates" :loading="loading" />
			</b-form-group>
		</template>
	</Modal>
</template>
<script>
import Moment from 'moment';
export default {
	props : {
		date : {
			type     : Date,
			required : true
		},
		segment : {
			type     : Object,
			required : false,
			default() {
				return null;
			}
		}
	},
	data() {
		return {
			dateCalendar     : null,
			availablesDates  : [new Date()],
			loading          : false,
			firstDateOfMonth : null,
			lastDateOfMonth  : null,
		};
	},
	watch : {
		firstDateOfMonth : {
			handler() {
				this.loading = true;
				const data = {
					segment   : this.segment,
					startDate : Moment(this.firstDateOfMonth).format('YYYY-MM-DD'),
					endDate   : Moment(this.lastDateOfMonth).format('YYYY-MM-DD')
				};
				this.$store.dispatch('scheduledSegments/get', data).then(res => {
					this.loading = false;
					const departureDates = res.map(x => {
						return Moment(x.tracking.departure.estimated);
					});
					const arrivalDates = res.map(x => {
						return Moment(x.tracking.arrival.estimated);
					});
					this.availablesDates = arrivalDates.concat(departureDates).map(x => {
						return x.toDate();
					});
				}).catch(err => {
					this.loading = false;
					this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
				});
			}
		}
	},
	methods : {
		emitDate() {
			this.$emit('set-date', this.dateCalendar);
		},
		setFirstAndLastDateOfMonth(firstDate, lastDate) {
			this.firstDateOfMonth = firstDate;
			this.lastDateOfMonth = lastDate;
		},
	}
};
</script>
<i18n>
{
    "fr": {
        "select-a-date": "Sélectionner une date"
    },
    "en" : {
        "select-a-date": "Select a date"
    }
}
</i18n>