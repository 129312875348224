<template>
	<div id="footer-operation">
		<div>
			<b-button class="btn-icon-only" variant="info" @click="$router.push(previousStepPath)"
				v-if="previousStepPath">
				<Icon icon="arrow-back" />
			</b-button>
		</div>
		<slot />
	</div>
</template>
<script>
export default {
	props : {
		previousStepPath : {
			required : false,
			type     : String,
			default() {
				return null;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#footer-operation {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	width: 100%;
	border-top: 1px solid $bootstrap-grey;
	position: fixed;
	bottom: 0;
	left: 0;
}
</style>