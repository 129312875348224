<template>
	<main id="404">
		<h1>{{ $t('error') }} 404</h1>
		<p>{{ $t('this-page-doesnt-exist!') }} <a href="/">{{ $t('return-to-home') }}</a></p>
	</main>
</template>

<script>
export default {
	name : 'Error404',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('error') + ' 404',
		};
	},
};
</script>
