<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('roads')">
			<b-form-select v-model="road" :options="roads">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						{{ $t('all') }}
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>
	</b-col>
</template>
<script>
export default {
	props : {
		roads : {
			type     : Array,
			required : false,
			default  : function() {
				return [];
			}
		}
	},
	computed : {
		options() {
			return [
			];
		}
	},
	data() {
		return {
			road : null,
		};
	}
};
</script>