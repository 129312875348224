<template>
	<div id="transport-plan">
		<b-card-header class="primary">
			{{ $t('transport-plan') }}
		</b-card-header>
		<b-card-body>
			<Form :current-route="currentRoute" @push-route="pushRoute" :locations="locations"
				:actors="contract.actors" :loading-locations="loadingLocations"
				@push-location="e => { locations.push(e) }" />
			<List :routes="contract.routes" @edit-route="editRoute" @delete-route="deleteRoute" class="mt-3" />
		</b-card-body>
		<div class="mt-3 d-flex justify-content-end">
			<b-button variant="info" @click="$emit('go-to-step', 'identification')">
				{{ $t('previous') }}
			</b-button>

			<b-button variant="primary" class="ml-1" :disabled="contract.routes && contract.routes.length == 0"
				id="next-transport" @click="submit">
				{{ $t('next') }}
			</b-button>
		</div>
	</div>
</template>
<script>
import { Form, List }  from './transportPlan/';
export default {
	components : {
		List,
		Form
	},
	data() {
		return {
			currentRoute     : null,
			initialContract  : null,
			locations        : [],
			loadingLocations : false,
		};
	},
	props : {
		contract : {
			type     : Object,
			required : true
		},
		isOwner : {
			type     : Boolean,
			required : true,
		}
	},
	async created() {
		this.loadingLocations = true;
		await this.$store.dispatch('locations/loadLocations', '').then(res => {
			res.results.forEach((e) => {
				const find = this.contract.locations.find((x) => {
					return x.locationId == e._id;
				});
				if(!find) {
					this.locations.push(e);
				}
			});
			this.locations = this.locations.concat(this.contract.locations);
			this.loadingLocations = false;
		});
	},
	methods : {
		resetContract(val) {
			if(val && this.initialContract) {
				this.$emit('reset-contract', this.initialContract);
			}
		},
		pushRoute(route) {
			const t = this;
			t.$bvToast.hide();

			const segments = route.services;
			segments.splice(segments.length - 1, 1);
			const index = t.contract.routes.findIndex((x) => x.routeId == route.routeId);
			if(index != -1) {
				t.contract.routes.splice(index, 1, t.$_clone(route));
				this.currentRoute = null;
			} else {
				t.contract.routes.push(t.$_clone(route));
			}
		},
		editRoute(route) {
			this.currentRoute = this.$_clone(route);
			this.initialContract = JSON.parse(JSON.stringify(this.contract));
		},
		deleteRoute(index) {
			this.contract.routes.splice(index, 1);
		},
		setServices() {
			const services = [];
			for (let i = 0; i < this.contract.routes.length; i++) {
				const route = this.contract.routes[i];
				for (let index = 0; index < route.services.length; index++) {
					const service = route.services[index];
					services.push(service);
				}
			}
			this.contract.services = [...services];
		},
		setLocations() {
			this.contract.routes.forEach((route) => {
				const segments = route.services.filter((x) => x.type == 'segment').map((x) => x.segment);
				route.origin = segments[0].origin;
				route.destination = segments[segments.length - 1].destination;
				segments.forEach((segment) => {
					const locationOrigin = this.locations.find((x) => {
						return x.name == segment.origin.name;
					});
					const locationDestination = this.locations.find((x) => {
						return x.name == segment.destination.name;
					});
					if(locationOrigin) {
						if(!this.contract.locations.find((x) => x.name == locationOrigin.name)) {
							this.contract.locations.push(
								{ ...locationOrigin,
									...{
										validatedCharger  : false,
										validatedOperator : true }
								}
							);
						}
					}
					if(locationDestination) {
						if(!this.contract.locations.find((x) => x.name == locationDestination.name)) {
							this.contract.locations.push(
								{ ...locationDestination,
									...{
										validatedCharger  : false,
										validatedOperator : true }
								}
							);
						}
					}
				});
			});
		},
		submit() {
			this.setLocations();
			this.setServices();
			this.$emit('go-to-step', 'calendar');
		},
	}
};
</script>
<i18n>
{
	"fr": {
		"do-you-want-to-save-your-current-edition": "Voulez vous enregistrer votre édition courante ?"
	},
	"en": {
		"do-you-want-to-save-your-current-edition": "Do you want to save your current edition?"
	}
}
</i18n>