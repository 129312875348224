import Vue from 'vue';
Vue.mixin({
	methods : {
		getNextItemArray(key, array) {
			return array[key + 1];
		},
		getPreviousItemArray(key, array) {
			return array[key - 1];
		},
		toDateInput(date) {
			var local = new Date(date);
			local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
			return local.toJSON().slice(0, 10);
		},
		toTimeInput(date) {
			return ('0' + date.getHours()).substr(-2) + ':' + ('0' + date.getMinutes()).substr(-2);
		},
		$_validateState(name) {
			const { $dirty, $error } = name;
			return $dirty ? !$error : null;
		},
		getClassMultiSelectForValidation(model) {
			if(model.$error) {
				return 'form-group--error';
			} else if(model.$dirty && !model.$error) {
				return 'form-group--valid';
			} else return '';
		},
		$_clone(item) {
			return JSON.parse(JSON.stringify(item));
		},
		$_makeToast(variant, msg, title) {
			this.$bvToast.toast(msg, {
				title         : title,
				variant       : variant,
				solid         : true
			});
		},
		$_makeToastRoot(variant, msg, title) {
			this.$root.$bvToast.toast(msg, {
				title   : title,
				variant : variant,
				solid   : true
			});
		},
		$_cleanObject(obj) {
			Object.keys(obj).forEach(k =>
				(obj[k] && typeof obj[k] === 'object') && this.$_cleanObject(obj[k]) ||
				(obj[k] == null && obj[k] !== undefined) && delete obj[k]
			);
			return obj;
		}
	}
});