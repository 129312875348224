<template>
	<Modal :id="'modal-schedules-' + id" centered :title="title" size="lg" @confirm="save">
		<template v-slot:content>
			<b-row>
				<b-col md="6">
					<legend>{{ $t('select-days') }}</legend>
					<div class="technical-informations light w-100">
						<ul>
							<li v-for="(day, i) in days" :key="i">
								<span>
									<b-form-checkbox v-model="day.active" :id="'checkboxday' + i">
										{{ $t(daysLabel[day.dayOfWeek]) }}
									</b-form-checkbox>
								</span>
								<span v-if="day.hours">
									<div v-for="(hour, indhour) in day.hours" :key="indhour" class="w-100 d-flex">
										<div v-if="hourIsClosed(hour)">
											{{ $t('closed') }}
										</div>
										<div v-else-if="hourIsOpen2424(hour)">
											{{ $t('open-24-24') }}
										</div>
										<div v-else>
											{{ hour.start.slice(0, -3) }} - {{ hour.end.slice(0, -3) }}
										</div>
										<div v-if="day.dayPlus" class="ml-1">
											{{ $t('day-plus') }}{{ day.dayPlus }}
										</div>
									</div>
								</span>
								<span v-else />
							</li>
						</ul>
					</div>
				</b-col>
				<b-col md="6" class="border-left-grey p-3">
					<legend>{{ $t('assign-openingHours') }}</legend>
					<b-form class="child-mt-2">
						<b-form-group :disabled="!daysSelected.length" class="child-width-content">
							<b-form-radio value="open" v-model="schedule.status">
								{{ $t('open') }}
							</b-form-radio>
							<b-form-radio value="closed" v-model="schedule.status">
								{{ $t('closed') }}
							</b-form-radio>
							<b-form-radio value="open-24-24" v-model="schedule.status">
								{{ $t('open-24-24') }}
							</b-form-radio>
						</b-form-group>
						<div id="schedules-input-container" class="pr-1">
							<div v-if="schedule.status == 'open'">
								<div v-for="(hour, hourIndex) in schedule.hours" :key="hourIndex"
									class="align-items-center d-flex">
									<b-form-group :label="$t('of')" label-cols="3" content-cols="8">
										<b-form-timepicker v-model="hour.start" :placeholder="$t('choose-a-time')"
											:disabled="!daysSelected.length" />
									</b-form-group>
									<b-form-group :label="$t('at')" label-cols="3" content-cols="8">
										<b-form-timepicker v-model="hour.end" :placeholder="$t('choose-a-time')"
											:disabled="!daysSelected.length" />
									</b-form-group>
									<div class="d-flex justify-content-end" style="width: 80px;" v-if="hourIndex != 0">
										<b-button class="btn-round-icon" variant="outline-danger"
											@click="removeTime(hourIndex)">
											<Icon icon="cross" />
										</b-button>
									</div>
								</div>
								<b-form-group :label="$t('day-plus')" label-cols="3" content-cols="8" v-if="dayPlus">
									<b-form-input v-model="schedule.dayPlus" type="number"
										:disabled="!daysSelected.length" placeholder="_" />
								</b-form-group>
							</div>
							<div class="d-flex justify-content-end w-100"
								v-if="daysSelected.length > 0 && schedule.status === 'open' && multiple">
								<b-button variant="primary" class="btn-round-icon" @click="addTime">
									<Icon icon="add" />
								</b-button>
							</div>
						</div>
						<div class="d-flex justify-content-end">
							<b-button variant="primary" :disabled="!daysSelected.length" @click="submit"
								id="validschedules">
								{{ $t('valid') }}
							</b-button>
						</div>
					</b-form>
				</b-col>
			</b-row>
		</template>
	</Modal>
</template>
<script>
export default {
	props : {
		schedules : {
			type     : Array,
			required : true,
		},
		title : {
			required : false,
			type     : String,
			default() {
				return this._i18n.t('schedules');
			}
		},
		multiple : {
			required : false,
			type     : Boolean,
			default() {
				return false;
			}
		},
		daysLabel : {
			required : true,
			type     : Array
		},
		id : {
			required : true,
			type     : String
		},
		dayPlus : {
			required : false,
			type     : Boolean,
			default  : false
		}

	},
	data() {
		return {
			schedule : {
				status : 'open',
				hours  : [{
					start     : '08:00:00',
					end     : '18:00:00',
				}]
			},
			daysSelected : [],
			days         : [
				{
					dayOfWeek  : '0',
					active       : false,
				},
				{
					dayOfWeek  : '1',
					active       : false,
				},
				{
					dayOfWeek  : '2',
					active       : false,
				},
				{
					dayOfWeek  : '3',
					active       : false,
				},
				{
					dayOfWeek  : '4',
					active       : false,
				},
				{
					dayOfWeek  : '5',
					active       : false,
				},
				{
					dayOfWeek  : '6',
					active       : false,
				},
			]
		};
	},
	methods : {
		submit() {
			const t = this;
			for (let i = 0; i < t.daysSelected.length; i++) {
				const day = this.days.find((x) => x.dayOfWeek == t.daysSelected[i].dayOfWeek);
				if(t.dayPlus) {
					day.dayPlus = t.schedule.dayPlus;
				}
				if(t.schedule.status == 'open-24-24') {
					day.hours = [
						{
							start : '00:00:00',
							end   : '23:59:59'
						}
					];
				} else if(t.schedule.status == 'closed') {
					day.hours = [
						{
							start : 'closed',
							end   : 'closed'
						}
					];
				} else if(t.schedule.status == 'open') {
					day.hours = [ ...t.schedule.hours ];
				}
			}
			t.reset();
			t.$forceUpdate();
		},
		reset() {
			const t = this;
			t.schedule =  {
				status : 'open',
				hours  : [{
					start     : '08:00:00',
					end     : '18:00:00',
				}]
			};
			for (let a = 0; a < t.days.length; a++) {
				const day = t.days[a];
				if(day.active) day.active = false;
			}
		},
		save() {
			this.$emit('set-schedules',
				this.days.map((x) => {
					delete x.active; return x;
				})
			);
			this.$bvModal.hide('modal-schedules-' + this.id);
		},
		addTime() {
			this.schedule.hours.push({
				start     : '08:00:00',
				end     : '18:00:00',
			});
		},
		removeTime(index) {
			this.schedule.hours.splice(index, 1);
		},
		hourIsClosed(hour) {
			if(hour.start == 'closed' && hour.end == 'closed') {
				return true;
			}
			return false;
		},
		hourIsOpen2424(hour) {
			if(hour.start == '00:00:00' && hour.end == '23:59:59') {
				return true;
			}
			return false;
		}
	},
	mounted() {
		this.$root.$on('bv::modal::show', () => {
			if(this.schedules && this.schedules.length) {
				this.days = this.$_clone(this.schedules);
			} else {
				this.days = [
					{
						dayOfWeek  : '0',
						active       : false,
					},
					{
						dayOfWeek  : '1',
						active       : false,
					},
					{
						dayOfWeek  : '2',
						active       : false,
					},
					{
						dayOfWeek  : '3',
						active       : false,
					},
					{
						dayOfWeek  : '4',
						active       : false,
					},
					{
						dayOfWeek  : '5',
						active       : false,
					},
					{
						dayOfWeek  : '6',
						active       : false,
					},
				];
			}
			this.reset();
		});
	},
	watch : {
		days : {
			handler(val) {
				for (let i = 0; i < val.length; i++) {
					const day = val[i];
					const index = this.daysSelected.findIndex((x) => x.dayOfWeek == day.dayOfWeek);
					if(day.active && index == -1) {
						this.daysSelected.push(this.$_clone(day));
					}
					if(index != -1 && !day.active) {
						this.daysSelected.splice(index, 1);
					}
				}
			},
			deep : true
		},
		daysSelected(days) {
			if(days.length) {
				for (let i = 0; i < days.length; i++) {
					const day = days[i];
					if(day.hours) {
						const firstHour = day.hours[0];
						if(this.hourIsOpen2424(firstHour)) {
							this.schedule.status = 'open-24-24';
						} else if(this.hourIsClosed(firstHour)) {
							this.schedule.status = 'closed';
						} else {
							if(this.dayPlus) {
								this.schedule.dayPlus = day.dayPlus;
							}
							this.schedule.hours = day.hours;
						}
					}
				}
			} else {
				this.reset();
			}
		},
	},

};
</script>
<style lang="scss" scoped>
#schedules-input-container /deep/.form-row {
	width: 150px;
}
</style>
<i18n>
{
    "fr": {
        "select-days": "Sélectioner jour(s)",
        "assign-openingHours": "Assigner les horaires",
        "open-24-24": "Ouvert 24h/24"
    },
    "en": {
        "select-days": "Select day(s)",
        "assign-openingHours": "Assign timetable",
        "open-24-24": "Open 24h/24"
    }
}
</i18n>