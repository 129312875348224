<template>
	<main id="wagons">
		<router-view />
	</main>
</template>

<script>
export default {
	name : 'Wagons',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('wagons'),
		};
	},
	mounted() {
		this.$root.$on('bv::collapse::state', this.activeBreadcrumb);
		document.getElementById('main-content').style.scrollBehavior = 'smooth';
	},
	beforeDestroy() {
		this.$root.$off('bv::collapse::state', this.activeBreadcrumb);
	},
	methods : {
		activeBreadcrumb(collapseId, isJustShown) {
			var breadcrumb = document.getElementById('breadcrumb-' + collapseId);
			if(!breadcrumb) return;
			if(isJustShown) {
				if(collapseId != 'identification') {
					document.getElementById('main-content').scrollTop = 0;
				}
				breadcrumb.classList.add('active');
			} else {
				breadcrumb.classList.remove('active');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ .btn.collapsed {
	margin-bottom: 2px;
}
/deep/ .btn.not-collapsed {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	&:not(:first-of-type) {
		margin-top: 1rem;
	}
}
/deep/ .card-body > div {
	&:not(:first-of-type) {
		margin-top: 1.25rem;
	}
}
</style>