import { HTTP } from '../../http-common';
const actions = {
	async READBYID(context, id) {
		return await HTTP.get('/users/' + id );
	},
	async READBYFORCEID(context, id) {
		return await HTTP.get('/users/' + id + '?force=true' );
	},
	LOAD() {
		return HTTP.get('/users').then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async CREATE(context, payload) {
		await HTTP.post('/users', payload).then(() => {
			context.commit('set', null);
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async UPDATE(context, user) {
		const { _id, ...registerWithoutId } = user;
		await HTTP.put('/users/' + _id, registerWithoutId)
			.then().catch(err => {
				throw err.response.data;
			});
	},
	async ACTIVATE(context, id) {
		await HTTP.put('/users/' + id + '/activate')
			.then().catch(err => {
				throw err.response.data;
			});
	},
	async DEACTIVATE(context, id) {
		await HTTP.put('/users/' + id + '/deactivate')
			.then().catch(err => {
				throw err.response.data;
			});
	},
};
export default {
	namespaced : true,
	actions,
};