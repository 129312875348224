const frCountries = require('@/utils/plugins/countries/fr.json');
const enCountries = require('@/utils/plugins/countries/en.json');
export default {
	methods : {
		findCountry(code) {
			return this.countries.find((x) => x.alpha2 == code);
		}
	},
	computed : {
		countries() {
			if(this._i18n.locale == 'fr') {
				return frCountries;
			} else {
				return enCountries;
			}
		},
	},
};