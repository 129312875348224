import Vue from 'vue';

Vue.mixin({
	methods : {
		logout() {
			event.preventDefault();
			this.$store.dispatch('user/LOGOUT');
			this.$router.push('/login');
		},
		switchLanguage() {
			if(this._i18n.locale == 'fr') {
				localStorage.setItem('language', 'en');
				this._i18n.locale = 'en';
			} else {
				this._i18n.locale = 'fr';
				localStorage.setItem('language', 'fr');
			}
		},
	}
});