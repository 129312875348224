<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('circulations-modified') }}
		</b-card-header>
		<b-card-body>
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null">
						{{ $t('january') }}
					</b-form-select-option>
				</template>
			</b-form-select>
			<div class="percentage-circle yellow">
				<h2>
					20%
				</h2>
			</div>
			<ProgressBar percentage="20" color="#fac741" :loading="loading" />
			<h6 class="d-flex w-100 justify-content-center align-items-center">
				<span>{{ $t('on') }}</span>
				<span v-if="!loading" class="mx-1">40</span>
				<b-skeleton v-else width="8%" class="mx-1 my-0" />
				<span class="no-wrap">{{ $t('planned-circulations') }}</span>
			</h6>
		</b-card-body>
	</b-card>
</template>
<script>
import ProgressBar from '@/components/common/ProgressBar.vue';
export default {
	components : {
		ProgressBar
	},
	data() {
		return {
			loading : true
		};
	},
	mounted() {
		setTimeout(() => {
			this.loading = false;
		}, 2000);
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>