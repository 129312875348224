<template>
	<main id="update-collaborator">
		<h1 class="mb-4">
			{{ $t('update-collaborator') }}
		</h1>
		<b-row>
			<b-col xl="12">
				<b-card no-body id="updatecollaborator">
					<b-card-header class="primary" />
					<b-card-body>
						<CollaboratorsFields :collaborator="$v.collaborator" type="update" />
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row>
			<b-col xl="6" class="d-flex justify-content-end">
				<b-button class="btn-icon mt-3" variant="info"
					@click="$router.push({name: 'CollaboratorList'})">
					<Icon icon="arrow-back" />
					{{ $t('return') }}
				</b-button>
			</b-col>
			<b-col xl="6">
				<b-button class="btn-icon mt-3" variant="primary"
					@click="update()" id="updateCollaborator">
					<Icon icon="save" />
					{{ $t('save') }}
				</b-button>
			</b-col>
		</b-row>
	</main>
</template>

<script>
import CollaboratorsFields from '../collaborators/components/CollaboratorsFields.vue';
import { validateEmail, validateText, validatePhone,
	validatePostalCode } from '@/utils/plugins/validations/vuelidate';
import countriesMixins from '@/mixins/countries.js';

export default {
	name       : 'UpdateCollaborator',
	components : {
		CollaboratorsFields
	},
	mixins : [countriesMixins],
	async created() {
		await this.$store.dispatch('collaborator/READBYFORCEID', this.$route.params.id).then((res) => {
			this.collaborator = res.data;
			this.collaborator.phone = this.collaborator.phone.join(';');
			this.collaborator.scope = this.collaborator.scope.join(';');
			if (this.collaborator.address.country) {
				const country = this.collaborator.address.country;
				this.collaborator.address.country = this.countries.find(cnt => cnt.alpha2 === country);
			}
		});
	},

	data() {
		return {
			collaborator : {}
		};
	},
	methods :  {
		update() {
			this.$v.$touch();
			if(!this.$v.$invalid) {
				const collaborator = { ...this.collaborator };
				collaborator.address.country = collaborator.address.country.alpha2;
				const phone = collaborator.phone;
				collaborator.phone = [];
				collaborator.phone[0] = phone;
				const scope = collaborator.scope;
				collaborator.scope = [];
				collaborator.scope[0] = scope;
				this.$store.dispatch('collaborator/UPDATE', collaborator)
					.then(() => {
						this.$router.push('/collaborators/list');
					}).catch((err) => {
						this.$_makeToast('danger',
							this._i18n.t(err),
							this._i18n.t('error')
						);
					});
			}
		},
	},
	validations() {
		const validations = {
			collaborator : {
				firstname    : validateText,
				lastname     : validateText,
				email        : validateEmail,
				phone        : validatePhone,
				language     : validateText,
				scope        : validateText,
				emailconf     : validateText,
				address      : {
					street     : validateText,
					country    : validateText,
					city       : validateText,
					postalCode : validatePostalCode,
					state      : validateText,
					lieu       : validateText,

				}
			}
		};
		return validations;
	},

};
</script>