<template>
	<div class="list" v-if="routes.length">
		<div class="list-header border-radius-top">
			<ul>
				<li>{{ $t('road') }}</li>
				<li>{{ $t('origine') }}</li>
				<li />
				<li>{{ $t('destination') }}</li>
				<li>{{ $t('actions') }}</li>
			</ul>
		</div>
		<div class="container-item" v-for=" (route, index) in routes" :key="index">
			<div class="item">
				<Informations :transport-plan="route">
					<template v-slot:dateList>
						<DateList :date-list="route.days" />
					</template>

					<b-button variant="primary" class="btn-round-icon mr-1" @click="editRoute(route)">
						<Icon icon="edit" />
					</b-button>
					<b-button variant="primary" class="btn-round-icon" @click="deleteRoute(index)">
						<Icon icon="trash" />
					</b-button>
				</Informations>
				<HorizontalVisualization :services="route.services.filter(s=>s.type=='segment')" class="mt-3" />
			</div>
		</div>
	</div>
</template>
<script>
import DateList from './form/DateList';
import { Informations, HorizontalVisualization } from '@/components/transportPlan/';
export default {
	props : {
		routes : {
			required : true,
			type     : Array,
		}
	},
	components : {
		Informations,
		HorizontalVisualization,
		DateList
	},
	methods : {
		editRoute(route) {
			this.$emit('edit-route', route);
		},
		deleteRoute(route) {
			this.$emit('delete-route', route);
		}
	}
};
</script>