<template>
	<div>
		<b-button variant="primary" v-b-toggle="'identification'" class="btn-collapse">
			{{ $t('identification') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="identification" visible accordion="accordion">
			<b-card>
				<Actor v-for="(actor, index) in contract.actors" :key="index" :actor="actor" :is-owner="isOwner"
					:index="index" />
				<TransportPlan :routes="contract.routes" :is-owner="isOwner" :locations="contract.locations"
					:services="contract.segments" :get-user-locations="getUserLocations"
					:get-user-segments="getUserSegments" :get-user-routes="getUserRoutes" />
				<b-row>
					<b-col lg="6" md="12">
						<Locations :get-user-locations="getUserLocations" :is-owner="isOwner" />
					</b-col>
					<b-col lg="6" md="12">
						<Segments :get-user-segments="getUserSegments" :is-owner="isOwner" />
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="6" md="12" v-if="getUserManut.length">
						<Manutention :get-user-manut="getUserManut" :is-owner="isOwner" />
					</b-col>
					<b-col lg="6" md="12">
						<Calendar :calendar="contract.calendar" :is-owner="isOwner" :actors="contract.actors" />
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
	</div>
</template>
<script>
import { Actor, TransportPlan, Locations, Segments, Calendar, Manutention } from './identification/';
export default {
	components : {
		Actor,
		TransportPlan,
		Locations,
		Segments,
		Calendar,
		Manutention
	},
	props : {
		contract : {
			type     : Object,
			required : true,
		},
		isOwner : {
			type     : Boolean,
			required : true
		},
		getUserLocations : {
			type     : Array,
			required : true
		},
		getUserSegments : {
			type     : Array,
			required : true
		},
		getUserRoutes : {
			type     : Array,
			required : true
		},
		getUserManut : {
			type     : Array,
			required : true
		}
	},
};
</script>