import { HTTP } from '../../http-common';

const actions = {
	getSegmentsByLocationId(context, locationId) {
		return HTTP.get('/transportationSegments?locationId=' + locationId).then(res => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	}
};

export default {
	namespaced : true,
	actions,
};