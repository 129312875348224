<template>
	<div id="maintenance">
		<b-row>
			<b-col lg="3">
				<b-form-group :label="$t('company')">
					<b-form-input placeholder="_" type="text" v-model="maintenance.company.$model"
						:state="$_validateState(maintenance.company)" name="maintenancecompany" />
					<b-form-invalid-feedback>{{ $t('company-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('maintenance-type')">
					<b-form-select v-model="maintenance.type.$model" :options="typeOptions"
						:state="$_validateState(maintenance.type)" name="maintenancetype">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								-
							</b-form-select-option>
						</template>
					</b-form-select>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('next-date')">
					<DatePicker v-model="maintenance.nextDate.$model"
						:state="$_validateState(maintenance.nextDate)" name="nextDate" id="nextDate" />
					<b-form-invalid-feedback>{{ $t('next-date-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('nextKilometers')">
					<b-form-input placeholder="_" type="number" v-model="maintenance.nextKilometers.$model"
						:state="$_validateState(maintenance.nextKilometers)" name="nextKilometers" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('location-maintenance-name')">
					<b-form-input placeholder="_" type="text" v-model="maintenance.locationName.$model"
						:state="$_validateState(maintenance.locationName)" name="locationName" />
					<b-form-invalid-feedback>{{ $t('location-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('periodicityKM')">
					<b-form-input placeholder="_" type="number" v-model="maintenance.periodicityKM.$model"
						:state="$_validateState(maintenance.periodicityKM)" name="periodicityKM" />
					<b-form-invalid-feedback>{{ $t('number-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3">
				<b-form-group :label="$t('periodicityDate')">
					<b-form-input placeholder="_" type="text" v-model="maintenance.periodicityDate.$model"
						:state="$_validateState(maintenance.periodicityDate)" name="periodicityDate" />
					<b-form-invalid-feedback>{{ $t('date-periodicity-err') }} </b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="12" class="d-flex justify-content-end">
				<b-button class="btn-icon-only" variant="primary" @click="addMaintenace()"
					id="addMaintenance">
					<Icon icon="add" />
				</b-button>
			</b-col>
		</b-row>
		<b-table head-variant="light" :striped="true" hover responsive v-if="items.length"
			:fields="fields" :items="items" class="mt-4" id="maintenanceTable"
			:current-page="pagination.currentPage" :per-page="pagination.perPage">
			<template #cell(action)>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button v-if="true" variant="primary" class="btn-round-icon">
								<Icon icon="on" />
							</b-button>
							<b-button v-else variant="primary" class="btn-round-icon">
								<Icon icon="off" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
		<Pagination :pagination="pagination" />
	</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';

export default {
	name       : 'Maintenance',
	components : {
		Pagination,
	},
	props : {
		maintenance : {
			type     : Object,
			required : true,
		},
		maintenanceList : {
			type     : Array,
			required : false,
			default  :  function() {
				return [];
			}
		}
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('next-date'),
					key      : 'nextDate',
					sortable : true,
				},
				{
					label    : this._i18n.t('nextKilometers'),
					key      : 'nextKilometers',
					sortable : true,
				},
				{
					label    : this._i18n.t('location-maintenance-name'),
					key      : 'locationName',
					sortable : true,
				},
				{
					label    : this._i18n.t('periodicityKM'),
					key      : 'periodicityKM',
					sortable : true,
				},
				{
					label    : this._i18n.t('periodicityDate'),
					key      : 'periodicityDate',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
					class : 'action-field'
				},
			];
		},
		typeOptions() {
			return [
				{ text  : 'maintenace1',
					value : 'maint1' },
				{ text  : 'maintenace2',
					value : 'maint2' },

			];
		},
		items() {
			if (this.maintenanceList) return this.maintenanceList;
			return [];
		}
	},

	data() {
		return {
			pagination : {
				perPage     : 10,
				currentPage : 1,
			}
		};
	},
	methods : {
		addMaintenace() {
			this.maintenance.$touch();
			if(!this.maintenance.$invalid && !this.maintenance.$erro) {
				this.items.push(this.maintenance.$model);
				this.maintenance.$model = {
					company         : null,
					type            : null,
					nextDate        : null,
					nextKilometers  : null,
					locationName    : null,
					periodicityKM   : null,
					periodicityDate : null
				};
				this.$emit('maintenance', this.items);
				this.maintenance.$reset();
			}
		}
	}
};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>