<template>
	<div id="contracts-list">
		<Table :items="contracts" :fields="fields" :loading="loading" @load="load" :title="$t('contracts')"
			:pagination="pagination">
			<template v-slot:buttons>
				<b-button variant="primary" @click="$router.push('create/identification')" id="addcontract">
					{{ $t('add-contract') }}
				</b-button>
			</template>
			<template v-slot:filters>
				<Search />
				<Status />
			</template>
			<template v-slot:type>
				Multimodal
			</template>
			<template v-slot:state="data">
				{{ $t(data.item.state) }}
			</template>
			<template v-slot:action="data">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon" @click="visualization(data.item._id)"
								id="buttonview">
								<Icon icon="eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon" @click="edit(data.item._id)"
								id="buttonedit">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon" @click="openTrashModal(data.item._id)"
								v-if="data.item.state != 'VALIDATED'" id="buttondelete">
								<Icon icon="trash" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</Table>
		<Modal @confirm="trash(idCurrentContract)" id="modal-delete-contract" :title="$t('deletion-of-contract')">
			<template v-slot:content>
				<p class="text-initial">
					{{ $t('do-you-want-to-delete-the-contract') }}
					<b>{{ getCodeCurrentContract }}</b> ?
				</p>
			</template>
			<template v-slot:footer>
				<div class="child-mx-2">
					<b-button variant="info" @click="$bvModal.hide('modal-delete-contract')">
						{{ $t('cancel') }}
					</b-button>
					<b-button variant="primary" @click="trash()">
						<b-spinner small variant="light" v-if="loadingDelete" />
						<span v-else>{{ $t('delete') }}</span>
					</b-button>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import { Search, Status } from '@/components/filters/components';
export default {
	name       : 'ContractList',
	components : {
		Search,
		Status,
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('contracts'),
		};
	},
	computed : {
		getCodeCurrentContract() {
			const res = this.contracts.find((x) => x._id == this.idCurrentContract);
			if(res) return res.code;
			else return '';
		},
		fields() {
			return [
				{
					label    : this._i18n.t('contract-number'),
					key      : 'code',
					sortable : true,
				},
				{
					label    : this._i18n.t('client'),
					key      : 'actors[0].name',
					sortable : true,
				},
				{
					label    : this._i18n.t('type'),
					key      : 'type',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'state',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			pagination : {
				perPage     : 20,
				totalRows  	: null,
				currentPage : 1,
			},
			loading           : false,
			loadingDelete     : false,
			contracts         : [],
			idCurrentContract : null
		};
	},
	methods : {
		load(params) {
			let query = params;
			if(!query) {
				const children = this.$children.find((x) => x.$options._componentTag == 'Table');
				query = children.query;
			}
			this.loading = true;
			this.$store.dispatch('contracts/loadList', query).then((res) => {
				this.contracts = res.results;
				if(res.pagination.length) {
					this.pagination.totalRows = res.pagination[0].count;
				}
				this.loading = false;
			}).catch((err) => {
				this.$_makeToast('danger', err, this._i18n.t('error'));
				this.loading = false;
			});
		},
		edit(id) {
			this.$router.push('edit/identification/' + id);
		},
		openTrashModal(id) {
			this.idCurrentContract = id;
			this.$bvModal.show('modal-delete-contract');
		},
		trash() {
			this.loadingDelete = true;
			this.$store.dispatch('contracts/delete', this.idCurrentContract)
				.then(() => {
					this.$bvModal.hide('modal-delete-contract');
					this.load();
					this.loadingDelete = false;
				});
		},
		visualization(id) {
			this.$router.push('visualization/' + id);
		}
	}
};
</script>
<i18n>
{
	"fr": {
		"deletion-of-contract": "Suppression d'un contrat",
		"do-you-want-to-delete-the-contract": "Voulez-vous supprimer le contrat"
	},
	"en": {
		"deletion-of-contract": "Deletion of contract",
		"do-you-want-to-delete-the-contract": "Do you want to delete the contract"
	}
}
</i18n>
