<template>
	<b-col xl="3" lg="6" md="6">
		<MultipleSelect :options="options" :multiple="true" track="text" :label="this._i18n.t('status')"
			v-model="status" />
	</b-col>
</template>
<script>
export default {
	computed : {
		options() {
			return [
				{
					text  : this._i18n.t('created'),
					value : 'CREATED'
				},

				{
					text  : this._i18n.t('modified'),
					value : 'MODIFIED'
				},
				{
					text  : this._i18n.t('validated'),
					value : 'VALIDATED'
				},
				{
					text  : this._i18n.t('canceled'),
					value : 'CANCELED'
				}
			];
		}
	},
	data() {
		return {
			status : [],
		};
	},
	created() {
		const query = this.$route.query['state'];
		if(query) {
			for (let i = 0; i < query.split(',').length; i++) {
				const el = query.split(',')[i];
				this.status.push(this.options.options.find((x) => x.value == el));
			}
		}
	},
	watch : {
		status(status) {
			let value = '';
			for (let i = 0; i < status.length; i++) {
				if(i > 0) value += ',';
				value += status[i].value;
			}
			this.$parent.setFilter(value, 'state');
		}
	},
	methods : {
		reset() {
			this.status = [];
		}
	}
};
</script>