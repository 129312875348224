<template>
	<div class="origin" :class="{'active': value.name, 'has-origin-and-destination': value.name && destination.name}">
		<div class="container-info" :class="{'selected': isSelected}" v-if="value.name"
			@click="$emit('set-selected', 'origin')">
			{{ value.name }}
			<div @click.stop="cleanOrigin" class="d-flex" v-if="!destination.name">
				<Icon icon="cross" />
			</div>
		</div>
		<MultipleSelect v-else :options="getLocations" :placeholder="$t('departure-terminal')" track="name"
			:button="{text: $t('create-a-terminal')}" @click-btn="addLocation"
			:searchable="true" v-model="originClone" :loading="loadingLocations" />
	</div>
</template>
<script>
import uniqid from 'uniqid';
export default {
	name  : 'Origin',
	props : {
		locations : {
			required : true,
			type     : Array,
		},
		value : {
			required : true,
			type     : null
		},
		destination : {
			required : true,
			type     : null
		},
		previousDestination : {
			required : true,
			type     : null
		},
		isSelected : {
			required : true,
			type     : Boolean
		},
		loadingLocations : {
			required : true,
			type     : Boolean
		}

	},
	data() {
		return {
			originClone : null
		};
	},
	computed : {
		getLocations() {
			if(this.previousDestination) {
				return [this.previousDestination];
			} else {
				return this.locations;
			}
		},
	},
	methods : {
		addLocation(location) {
			if(!this.previousDestination) {
				const id = uniqid();
				this.$emit('push-location', location, id);
				this.originClone = {
					name           : location,
					locationIdtemp : id
				};
			}
		},
		cleanOrigin() {
			this.originClone = null;
			this.$emit('set-selected', null);
		}
	},
	watch : {
		originClone(val) {
			if(val) {
				this.$emit('input', val);
			} else {
				this.$emit('input', {});
			}
		},
		value(value) {
			if(!value.name)
				this.originClone = null;
		},
	},
	created() {
		if(this.value.name)
			this.originClone = this.value;
	}
};
</script>