<template>
	<main id="sillons" />
</template>

<script>
export default {
	name : 'Sillons',
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('sillons'),
		};
	},
};
</script>
