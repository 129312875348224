
import Address from './Address.vue';
import Schedules from '@/components/common/Schedules.vue';
export default {
	components : {
		Address,
		Schedules
	},
	props : {
		location : {
			required : true,
			type     : Object,
		},
		index : {
			required : true,
			type     : Number,
		}
	},
	data() {
		return {
			sameOpeningHours : false,
		};
	},
	computed : {
		modal() {
			return this.location.modals.$each.$iter[this.index];
		},
		getMainOpeningHours() {
			return this.location.openingHours.$model;
		},
		mainOpeningHoursIsContainsHours() {
			if (location.openingHours && location.openingHours.$model) {
				for (let i = 0; i < this.location.openingHours.$model.length; i++) {
					const el = this.location.openingHours.$model[i];
					if(el.hours && el.hours.length) return true;
				}
			}
			return false;
		},
	},
	watch : {
		getMainOpeningHours(val) {
			if(this.sameOpeningHours) {
				this.modal.openingHours.$model = val;
			}
		},
		sameOpeningHours(val) {
			if(val) {
				this.modal.openingHours.$model = this.getMainOpeningHours;
			} else {
				this.modal.openingHours.$model = [];
			}
		},
	},
	methods : {
		setSchedules(schedules) {
			this.modal.openingHours.$model = schedules;
		}
	}
};