<template>
	<ul :class="{'light' : true, 'horizontal visualization' : true}">
		<li v-for="(service, key) in services" :key="key">
			<div class="segment-container" :class="service.segment.status">
				<div class="current-position center" style="left: 50%;">
					<Statut :segment="service.segment" />
				</div>

				<div class="position" :id="'position-' + key">
					<span v-if="key != 0 && services.length < 5">

						{{ service.segment.origin.name }}
					</span>
				</div>
				<b-tooltip v-if="key != 0 && services.length >= 5"
					:target="'position-' + key" triggers="hover" placement="top">
					{{ service.segment.origin.name }}
				</b-tooltip>
				<div :class="'segment ' + service.segment.type">
					<div class="road-element" v-if="service.segment.typology == 'ROAD'" />
					<div class="road-element" v-else-if="service.segment.type == 'road'" />
				</div>
				<div v-if="key == (services.length - 1)" class="position" id="last-destination" />
			</div>
		</li>
	</ul>
</template>
<script>

import Statut from '@/components/segments/Statut';

export default {
	name       : 'HorizontalVisualization',
	components : { Statut },
	props      : {
		services : {
			type     : Array,
			required : true,
		}
	}

};
</script>
<style lang="scss" scoped>
.visualization.horizontal #last-destination {
	left: 100%;
}
</style>