<template>
	<div id="add-wagon">
		<h1>{{ $t('create-wagon') }}</h1>
		<div class="d-flex justify-content-between align-items-baseline">
			<Breadcrumbs />
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-button variant="primary" v-b-toggle="'identification'" class="btn-collapse">
			{{ $t('information-id') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="identification" visible accordion="accordion">
			<b-card>
				<Identification :identification="$v.wagon" />
			</b-card>
		</b-collapse>
		<b-button variant="primary" v-b-toggle="'renting'" class="btn-collapse" id="rentingbutton">
			{{ $t('information-rental') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="renting" accordion="accordion">
			<b-card>
				<Renting :renting="$v.renting" @renting="wagon.renting=$event" />
			</b-card>
		</b-collapse>

		<b-button variant="primary" v-b-toggle="'maintenance'" class="btn-collapse" id="maintenancebutton">
			{{ $t('information-maintenance') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="maintenance" accordion="accordion">
			<b-card>
				<Maintenance :maintenance="$v.maintenance" @maintenance="wagon.maintenance = $event" />
			</b-card>
		</b-collapse>
		<!--div>
			<b-card-header v-b-toggle.collapse-comments class="primary d-flex justify-content-between">
				{{ $t('comments') }}
				<Icon icon="chevron-down" />
			</b-card-header>
			<b-collapse id="collapse-comments" accordion="accordion">
				<b-card-body>
					<Comments />
				</b-card-body>
			</b-collapse>
		</-div-->
		<div class="d-flex justify-content-center mt-3">
			<b-button class="btn-icon" variant="primary" id="createwagonsavebutton"
				@click="submit">
				<Icon icon="save" />
				{{ $t('save') }}
			</b-button>
		</div>
	</div>
</template>

<script>
import Breadcrumbs from '../wagons/components/Breadcrumbs.vue';
import Identification from '../wagons/components/Identification.vue';
import Renting from '../wagons/components/Renting.vue';
import Maintenance from '../wagons/components/Maintenance.vue';
import { valideType, validateTextRequired, validateDecimal, validateDecimalRequired }
	from '@/utils/plugins/validations/vuelidate';
import { vWagonCode } from '@/utils/plugins/validations/wagons';
//import Comments from '../wagons/components/Comments.vue';

export default {
	name       : 'CreateWagon',
	components : {
		Breadcrumbs,
		Identification,
		Renting,
		Maintenance,
		//Comments
	},
	validations : {
		wagon : {
			code           : vWagonCode,
			type           : valideType,
			family         : validateTextRequired,
			payload        : validateDecimal,
			tare           : validateDecimal,
			TEU            : validateDecimalRequired,
			axesNumber     : validateDecimal,
			length         : validateDecimal,
			brakingWeight  : validateDecimal,
			kmUsage        : validateDecimal,
		},
		renting            : {
			company        : validateTextRequired,
			cost           : validateDecimal,
			startContract  : validateTextRequired,
			endContract    : validateTextRequired
		},
		maintenance : {
			company         : validateTextRequired,
			type            : validateTextRequired,
			nextDate        : validateTextRequired,
			nextKilometers  : validateDecimal,
			locationName    : validateTextRequired,
			periodicityKM   : validateDecimal,
			periodicityDate : validateTextRequired
		}
	},
	computed : {
		list() {
			return this.$store.getters['wagons/list'];
		}
	},
	data() {
		return {
			wagon : {
				code           : null,
				type           : null,
				family         : null,
				payload        : null,
				tare           : null,
				group          : '',
				TEU            : null,
				axesNumber     : null,
				length         : null,
				brakingWeight : null,
				kmUsage       : null,
				renting            : [],
				maintenance   : []
			},
			renting            : {
				company         : null,
				cost            : null,
				startContract  : null,
				endContract    : null
			},
			maintenance : {
				company         : null,
				type            : null,
				nextDate        : null,
				nextKilometers  : null,
				locationName    : null,
				periodicityKM   : null,
				periodicityDate : null
			}
		};
	},
	methods : {
		submit() {
			const validation = this.$v.wagon;
			validation.$touch();
			if(!validation.$invalid) {
				this.$store.dispatch('wagons/createWagons', this.wagon)
					.then(() => {
						this.$router.push('list');
					})
					.catch((err) => {
						this.$_makeToast('danger',
							this._i18n.t(err),
							this._i18n.t('error')
						);
					});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>