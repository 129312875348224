<template>
	<div>
		<ul class="informations">
			<li>
				<p>
					<b>{{ order.internalCode || segment._id || transportPlan.road }}</b>
					<b v-if="segment && segment.typology
						&& segment.internalRef">{{ segment.internalRef }}</b>
					<span v-if="order.freight">{{ order.freight.type }} : {{ order.freight.quantity }}</span>
					<span>

						{{ (order.client && order.client.name) || (segment.operator && segment.operator.name)
							||transportPlan.name }}
					</span>
				</p>
			</li>
			<li>
				<p class="text-center">
					<b v-if="order.origin">{{ order.origin.name }}</b>
					<b v-if="segment.origin">{{ segment.origin.name }}</b>
					<b v-if="segment.origin">{{ transportPlan.origin.name }}</b>
					<span v-if="order.departureDate">{{ order.departureDate | getFullDate }}</span>
					<span v-else-if="segment.timetable && segment._id">{{ segment.timetable.departure_time }}</span>
				</p>
			</li>
			<li>
				<DateList v-if="segment && segment.type &&
					segment.type.days" :date-list="segment.type.days" />
				<!-- TEMPORARY ELEMENT FOR DEMO, TO CORRECT WITH WRIGHT ORDER MODEL -->
				<p class="text-center" v-if="order && order.delivery">
					<b>{{ order.delivery.name }}</b>
					<span v-if="order.delivery_date">{{ order.delivery_date | getFullDate }}</span>
				</p>
				<p class="text-center" v-if="transportPlan && transportPlan.delivery">
					<b>{{ transportPlan.delivery.name }}</b>
					<span v-if="transportPlan.delivery_date">{{ transportPlan.delivery_date | getFullDate }}</span>
				</p>
			</li>

			<li>
				<p class="text-center">
					<b>{{ order.destination.name || segment.destination.name || transportPlan.destination.name }}</b>
					<span v-if="order.arrivalDate">{{ order.arrivalDate | getFullDate }}</span>
					<span v-else-if="segment.timetable && segment.id">
						{{ segment.timetable.arrival_time }}
						<span v-if="getDaysPlusTransportPlan(segment) > 0">
							{{ $t('day-letter') }}+{{ getDaysPlusTransportPlan(segment) }}
						</span>
					</span>
				</p>
			</li>
			<li>
				<slot />
				<Actions v-if="order.internalCode" />
				<Statut v-if="segment && segment.id" :segment="segment" />
			</li>
		</ul>
	</div>
</template>
<script>
import Informations from '@/mixins/segments/Informations';
import Actions from '@/components/Actions.vue';
import DateList from '../../views/contracts/create/transportPlan/form/DateList.vue';
import Statut from './Statut.vue';
export default {
	name       : 'Informations',
	components : {
		Actions,
		DateList,
		Statut
	},
	mixins : [
		Informations,
	],
	props : {
		order : {
			type     : Object,
			required : false,
			default() {
				return {
					client_id   : {},
					origin      : {},
					destination : {}
				};
			}
		},
		segment : {
			type     : Object,
			required : false,
			default() {
				return {
					origin      : {},
					destination : {}
				};
			}
		},
		transportPlan : {
			type     : Object,
			required : false,
			default() {
				return {
					origin      : {},
					destination : {}
				};
			}
		},
	},

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.informations {
	color: var(--secondary);
}
</style>