import axios from 'axios';

const hostName = process.env.VUE_APP_API_HOST;
export const HTTP = axios.create({
	baseURL         : `${hostName}/api`,
	withCredentials : true,
	'Content-Type'  : 'application/json'
	// credentials     : 'same-origin', //'include'
	// 'Content-Type'  : 'application/json',
	// baseURL : process.env.BASE_URL,
});
