import { HTTP } from '../../http-common';
const state = () => ({
	orders : []
});

const getters = {
	get : state => state.orders
};

const mutations = {
	set(state, payload) {
		state.orders = payload;
	},
};
const actions = {
	load(context, query) {
		let url = '/orders';
		if(query) {
			url += query;
		}
		return HTTP.get(url)
			.then(res => {
				return res.data;
			}).catch(err => {
				throw err.response.data.message;
			});
	},
	updateState(context, payload) {
		return HTTP.put('/orders/' + payload.orderId + '/state/' + payload.state).then().catch(err => {
			throw err.response.data.message;
		});
	},
	async create(context, order) {
		await HTTP.post('/orders', order)
			.then().catch(err => {
				throw err.response;
			});
	},
	async update(context, payload) {
		const { _id, ...order } = payload;
		await HTTP.put('/orders/' + _id, order)
			.then().catch(err => {
				throw err.response;
			});
	},
	async getOrder(context, queries) {
		const { _id, ...query } = queries;
		return await HTTP.get('/orders/' + _id + '?' + query.query).then((res) => {
			return res.data;
		}).catch(err => {
			throw err.response.data.message;
		});
	},
	async updateStates(context, payload) {
		return HTTP.put('/orders/state/' + payload.state, payload.ids)
			.then().catch(err => {
				throw err.response;
			});
	},
};
export default {
	namespaced : true,
	state,
	getters,
	actions,
	mutations
};