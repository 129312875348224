<template>
	<div>
		<ul class="informations" v-if="segments.length > 0">
			<li>
				<p>
					<b>{{ transportPlan.name }}</b>
					<span v-if="transportPlan.days && transportPlan.timetable
						&& transportPlan.days.length > transportPlan.timetable.circulation_number">
						{{ $t('date-to-choose') }}
					</span>
				</p>
			</li>
			<li>
				<p class="text-center">
					<b>{{ segments[0].origin.name }}</b>
					<span v-if="FirstSegmentIsRoad()">{{ getFirstSegmentRailDate() }}</span>
					<span v-else>{{ segments[0].timetable ?
						segments[0].timetable.departure_time : '' }}</span>
				</p>
			</li>
			<li>
				<slot name="dateList" />
			</li>
			<li>
				<p class="text-center">
					<b>{{ segments[segments.length - 1].destination.name }}</b>
					<span v-if="transportPlan.timetable">
						{{ segments[0].timetable?
							segments[0].timetable.arrival_time : '' }}
						<span v-if="getDaysPlusTransportPlan(transportPlan) > 0">
							{{ $t('day-letter') }}+{{ getDaysPlusTransportPlan(transportPlan) }}
						</span>
					</span>
				</p>
			</li>
			<li>
				<slot />
			</li>
		</ul>
	</div>
</template>
<script>
import Informations from '@/mixins/segments/Informations';
export default {
	name       : 'Informations',
	mixins : [
		Informations
	],
	props : {
		transportPlan : {
			type     : Object,
			required : false,
			default() {
				return {
					origin      : {},
					destination : {},
					services    : []

				};
			}
		},
	},
	data() {
		return {
			segments : []
		};
	},
	created() {
		this.segments = this.transportPlan.services.filter(s=>s.type == 'segment' )
			.map(ser=>ser.segment);
	},
	methods : {
		FirstSegmentIsRoad() {
			if(this.segments[0].type == 'road') return true;
			else return false;
		},
		getFirstSegmentRailDate() {
			return this.segments[1].timetable.departure_time;
		}
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.informations {
	color: var(--secondary);
}
</style>