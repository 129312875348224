import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user.js';
import registers from './modules/registers.js';
import contracts from './modules/contracts';
import wagons from './modules/wagons.js';
import locations from './modules/locations.js';
import company from './modules/company.js';
import collaborator from './modules/collaborator.js';
import orders from './modules/orders.js';
import transportationRoutes from './modules/transportationRoutes.js';
import transportationSegments from './modules/transportationSegments.js';
import scheduledSegments from './modules/scheduledSegments.js';
import incidents from './modules/incidents.js';
import createPersistedState from 'vuex-persistedstate';


Vue.use(Vuex);

const modules = {
	user,
	registers,
	contracts,
	wagons,
	locations,
	company,
	collaborator,
	orders,
	transportationRoutes,
	transportationSegments,
	scheduledSegments,
	incidents
};

export default new Vuex.Store({
	plugins : [createPersistedState({
		key : 'user.user'
	})],
	state     : {},
	mutations : {},
	actions   : {},
	modules   : modules,
});
