<template>
	<div id="threshold">
		<div class="w-100 d-flex flex-column" v-if="range.length">
			<div class="d-flex justify-content-end mb-3">
				<b-button variant="info" class="btn-input btn-icon mr-1" @click="reset">
					<Icon="cross" />
					{{ $t('reset') }}
				</b-button>
			</div>
			<div class="technical-informations w-100 light">
				<ul>
					<li v-for="(threshold, index) in range" :key="index">
						<span>{{ $t('threshold') }}</span>
						<span>{{ threshold.init }}%</span>
						<span>{{ $t(threshold.periodicity) }}</span>
						<span>{{ threshold.value }} €</span>
						<span>{{ $t(threshold.type) }}</span>
					</li>
				</ul>
			</div>
		</div>
		<b-row>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('percentage-threshold')">
					<b-form-input type="number" placeholder="_" v-model="$v.form.init.$model"
						:state="$_validateState($v.form.init)" />
					<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('times')">
					<b-form-select :options="getTimeOptions" v-model="$v.form.periodicity.$model"
						:state="$_validateState($v.form.periodicity)">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								_
							</b-form-select-option>
						</template>
					</b-form-select>
					<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('price')" class="w-100">
					<b-form-input placeholder="_" type="number" v-model="$v.form.value.$model"
						:state="$_validateState($v.form.value)" />
					<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="3" md="6" sm="12">
				<b-form-group :label="$t('value')">
					<b-form-select :options="getPriceType" v-model="$v.form.type.$model"
						:state="$_validateState($v.form.type)">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>
								_
							</b-form-select-option>
						</template>
					</b-form-select>
					<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
				</b-form-group>
			</b-col>
			<b-col lg="12" class="d-flex align-items-end justify-content-end">
				<b-button variant="primary" class="btn-input btn-icon" @click="addThreshold">
					<Icon icon="add" />
					{{ $t('add') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { validateRequired } from '@/utils/plugins/validations/vuelidate.js';
export default {
	data() {
		return {
			form : {
				init        : null,
				periodicity : null,
				type        : null,
				value       : null,
			},
			range : []
		};
	},
	props : {
		condition : {
			type     : Object,
			required : true,
		}
	},
	validations : {
		form : {
			init        : validateRequired,
			periodicity : validateRequired,
			type        : validateRequired,
			value       : validateRequired,
		}
	},
	methods : {
		addThreshold() {
			this.$v.$touch();
			if(!this.$v.$invalid) {
				const form = { ...this.form };
				this.range.push({
					init        : form.init,
					periodicity      : form.periodicity,
					type        : form.type,
					value       : form.value,
				});
				this.$emit('set-range-threshold', this.range);
				this.form.init = null;
				this.form.periodicity = null;
				this.form.type = null;
				this.form.value = null;
				this.$nextTick(() => {
					this.$v.$reset();
				});
			}
		},
		reset() {
			this.$emit('set-range-threshold', null);
			this.form.init = null;
			this.range = [];
			this.form.periodicity = null;
			this.form.type = null;
			this.form.value = null;
			this.$nextTick(() => {
				this.$v.$reset();
			});
		},
	},
	computed : {
		getPriceType() {
			return [
				{
					value : 'percentage',
					text  : this._i18n.t('percentage')
				},
				{
					value : 'fixed-price',
					text  : this._i18n.t('fixed-price')
				},
			];
		},
		getTimeOptions() {
			return [
				{
					value : 'month',
					text  : this._i18n.t('month')
				},
				{
					value : 'quarter',
					text  : this._i18n.t('quarter')
				},
				{
					value : 'year',
					text  : this._i18n.t('year')
				},
			];
		}
	},
	mounted() {
		const range = this.condition.range;
		if(range  && range.length) {
			this.range = range;
		}
	}
};
</script>
<i18n>
{
	"fr": {
		"percentage-threshold": "% de seuil"
	},
	"en": {
		"percentage-threshold": "% of threshold"
	}
}
</i18n>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.technical-informations {
	max-width: 100%;
	overflow-x: auto;
}
.technical-informations li span {
	width: 20% !important;
	display: flex;
	justify-content: center;
}
</style>