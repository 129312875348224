<template>
	<div id="edit-contract">
		<div class="d-flex justify-content-between mb-3">
			<h1>{{ $t('edit-contract') }}</h1>
			<b-button variant="info" class="btn-icon" @click="$router.push('/contracts/list')">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<StepByStep :current-step="step" :steps="steps" @go-to-step="goToStep"
			:percentage-step="percentageStep" />
		<Identification v-if="step == 'identification'" :actors="contract.actors" @go-to-step="goToStep" />

		<TransportPlan v-if="step == 'transport-plan'"	@go-to-step="goToStep" :contract="contract"
			:is-owner="isOwner" @reset-contract="resetContract" />
		<Calendar v-if="step == 'calendar'" :calendar="contract.calendar" @go-to-step="goToStep" :is-owner="isOwner" />
		<Pricing v-if="step == 'pricing-condition'" :contract="contract" @go-to-step="goToStep" />
		<Validation v-if="step == 'validation'" :contract="contract" @go-to-step="goToStep" :is-owner="isOwner"
			@submit="submit" @set-validation-percentage="setValidationPercentage"
			@valid="valid" id="validation" />
	</div>
</template>
<script>
import StepByStep from '@/components/common/StepByStep.vue';
import { TransportPlan, Identification, Pricing, Validation, Calendar } from './create/';
import ValidationMixins from './mixins/validation';
export default {
	components : {
		TransportPlan,
		Identification,
		Pricing,
		Validation,
		StepByStep,
		Calendar
	},
	mixins : [
		ValidationMixins
	],
	data() {
		return {
			step      : 'identification',
			contract : {
				actors      : [],
				routes      : [],
				rates       : [],
				locations   : [],
				segments  : [],
				calendar    : {
					sendProviderDays   : null,
					start            : null,
					end              : null,
					planning         : null,
					preInvocing      : null,
				},
				defaultLocation : [],
			},
			percentageStep : {
				step  : 'validation',
				value : 0
			},
			steps : [
				'identification',
				'transport-plan',
				'calendar',
				'pricing-condition',
				'validation'
			],
			id : null,
		};
	},
	async created() {
		await this.loadContract();
		if(!this.isOwner) {
			this.steps.splice(3, 1);
		}
		this.setStep();
		await this.$store.dispatch('locations/loadLocations', '');
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('contracts/contract', null);
		this.$store.commit('contracts/selected', this.contract.code);
		next();
	},
	computed : {
		getContractStore() {
			return this.$store.getters['contracts/contract'];
		},
		isOwner() {
			if(this.contract.actors.length) {
				return this.user.company._id == this.contract.actors[0].companyId;
			} else {
				return false;
			}
		}
	},
	methods : {
		async loadContract() {
			this.id = this.$route.params.id;
			if(this.id) {
				await this.$store.dispatch('contracts/loadContract', this.id).then(() => {
					if(this.getContractStore) {
						this.contract = this.$store.getters['contracts/contract'];
						this.$store.commit('contracts/selected', this.contract.code);
					}
				});
			}
		},
		setStep() {
			const stepParams = this.$route.params.step;
			if(stepParams) {
				const step = this.steps.find((x) => x == stepParams);
				if(step) {
					this.step = step;
				} else {
					this.goToStep(0);
				}
			} else {
				this.goToStep(0);
			}
		},
		goToStep(step) {
			const stepIndex = this.steps.findIndex((x) => x == step);
			const currentIndex = this.steps.findIndex((x) => x == this.step);
			if(stepIndex > currentIndex) {
				this.$store.commit('contracts/contract', this.contract);
			}
			this.$router.push('/contracts/edit/' + step + '/' + this.id);
			this.step = step;
		},
		setActorCountry(actors) {
			for (let i = 0; i < actors.length; i++) {
				const actor = actors[i];
				if(actor.address.country.alpha2) {
					actor.address.country = actor.address.country.alpha2;
				}
				for (let a = 0; a < actor.contacts.length; a++) {
					const contact = actor.contacts[a];
					if(contact.address.country.alpha2) {
						contact.address.country = contact.address.country.alpha2;
					}
				}
			}
			return actors;
		},
		setRatesTypology(rates) {
			for (let i = 0; i < rates.length; i++) {
				const rate = rates[i];
				if(rate.typology.text) {
					rate.typology = rate.typology.text;
				}
			}
			return rates;
		},
		setRouteModel(routes) {
			const r = [...routes];
			for (let i = 0; i < r.length; i++) {
				r[i].services = this.setServicestModel([...routes[i].services]);
			}
			return r;
		},
		setServicestModel(services) {
			for (let i = 0; i < services.length; i++) {
				if(services[i].type == 'segment') {
					const segment = services[i].segment;
					if(segment.origin ) {
						segment.origin = {
							id   : segment.origin._id ? segment.origin._id
								: segment.origin.locationIdtemp,
							name : segment.origin.name
						};
					}
					if(segment.destination ) {
						segment.destination = {
							id   : segment.destination._id ?
								segment.destination._id : segment.destination.locationIdtemp,
							name : segment.destination.name
						};
					}
					if(segment.operator ) {
						segment.operator = {
							id   : segment.operator.companyId || segment.operator.id,
							name : segment.operator.name
						};
					}
				} else {
					const service = services[i];
					if(service.operator ) {
						service.operator = {
							id   : service.operator.companyId || service.operator.id,
							name : service.operator.name
						};
					}
				}
			}
			return [...services];
		},
		setSLocationModel(locations) {
			// TO DO : change active modal process
			return locations.map(loc=> {
				delete loc.activeModal;
				if(loc._id)
					loc.locationId = loc._id;
				delete loc._id;
				if(loc.address && loc.address.country && loc.address.country.alpha2) {
					loc.address.country = loc.address.country.alpha2;
				}
				return loc;
			});
		},
		formatContract() {
			const sendContract = this.$_clone(this.contract);
			const actors = [...this.contract.actors];
			sendContract.actors = this.setActorCountry(actors);
			const routes = [...sendContract.routes];
			sendContract.routes = this.setRouteModel(routes);

			sendContract.rates = this.setRatesTypology([...this.contract.rates]);
			// console.log([...this.contract.services]);

			//this.setSegmentModel([...this.contract.services]);
			const services = [...sendContract.services];
			sendContract.services = this.setServicestModel([...services]);
			sendContract.locations = this.setSLocationModel([...this.contract.locations]);
			return sendContract;
		},
		submit() {
			const sendContract = this.formatContract();
			this.$store.dispatch('contracts/edit', sendContract)
				.then(() => {
					this.$router.push('/contracts/list');
				});
		},
		setValidationPercentage(percentage) {
			this.percentageStep.value = percentage;
		},
		valid() {
			const sendContract = this.formatContract();
			this.$store.dispatch('contracts/edit', sendContract)
				.then(() => {
					this.$store.dispatch('contracts/valid', this.contract._id)
						.then(() => {
							this.$router.push('/contracts/list');
						});
				});
		},
		resetContract(initial) {
			this.contract = { ...initial };
		}
	},
	watch : {
		getAllValidation(val) {
			this.setValidationPercentage(val);
		},


	}
};
</script>
<i18n>
{
    "fr": {
        "route-construction": "Construction du trajet",
        "create-contract": "Création d'un contrat"
    },
    "en": {
        "route-construction": "Route construction",
        "create-contract": "Create contract"
    }
}
</i18n>