<template>
	<div id="partnersfields">
		<b-row>
			<b-col lg="6">
				<b-form-group :label="$t('company-type')">
					<b-form-input type="text" placeholder="_" :value="$t(partner.type)" />
				</b-form-group>

				<b-form-group :label="$t('siren')">
					<b-form-input type="number" placeholder="_" v-model="partner.siret.siren" />
				</b-form-group>

				<b-form-group :label="$t('intra-community-vat-number')">
					<b-form-input type="number" placeholder="_" />
				</b-form-group>

				<b-form-group :label="$t('street')">
					<b-form-input type="text" placeholder="_" v-model="partner.address.street" />
				</b-form-group>

				<b-form-group :label="$t('city')">
					<b-form-input type="text" placeholder="_" v-model="partner.address.city" />
				</b-form-group>

				<b-form-group :label="$t('department-or-region')">
					<b-form-input type="text" placeholder="_" />
				</b-form-group>
			</b-col>
			<b-col lg="6">
				<b-form-group :label="$t('company-name')">
					<b-form-input type="text" placeholder="_" v-model="partner.name" />
				</b-form-group>

				<b-form-group :label="$t('nic')">
					<b-form-input type="number" placeholder="_" v-model="partner.siret.nic" />
				</b-form-group>

				<b-form-group :label="$t('code-company')">
					<b-form-input type="text" placeholder="_" v-model="partner.code" />
				</b-form-group>

				<b-form-group :label="$t('complement-address')">
					<b-form-input type="text" placeholder="_" v-model="partner.address.lieu" />
				</b-form-group>

				<b-form-group :label="$t('postal-code')">
					<b-form-input type="number" placeholder="_" v-model="partner.address.postalCode" />
				</b-form-group>

				<b-form-group :label="$t('country')">
					<b-form-input type="text" placeholder="_" v-model="partner.address.country" />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name  : 'PartnersFields',
	props : {
		partner : {
			type     : Object,
			required : false,
			default() {
				return {
					partner : {}
				};
			}
		},
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>