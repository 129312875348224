<template>
	<div>
		<b-card no-body>
			<b-card-header>Selectionner un site</b-card-header>
			<b-card-body>
				<form @submit="submit">
					<multi-select class="content" v-model="location"
						:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
						select-label="" placeholder="_" :searchable="true" :hide-selected="true"
						:options="locations" label="name" track-by="name">
						<template slot="noResult">
							{{ $t('no-result') }}
						</template>
					</multi-select>
				</form>
			</b-card-body>
		</b-card>
		<Footer>
			<b-button variant="primary" :disabled="!location" @click="submit">
				{{ $t('next') }}
			</b-button>
		</Footer>
	</div>
</template>
<script>
import Footer from './components/Footer.vue';
export default {
	components : {
		Footer
	},
	data() {
		return {
			locations : [],
			location  : '',
		};
	},
	created() {
		this.$store.dispatch('locations/getLocationsByCompanyId', this.user.company._id).then(res => {
			this.locations = res.results;
		}).catch(err => {
			this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
		});
		this.$store.commit('operation/reset');
	},
	methods : {
		submit($event) {
			$event.preventDefault();
			this.$store.commit('operation/setLocation', this.location);
			const scope = this.user.scope;
			if(scope.includes('handler')) {
				this.$router.push('/operation/select-order');
			} else if(scope.includes('driver')) {
				this.$router.push('/operation/select-segment');
			}
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.list-group-item {
	display: flex;
	cursor: pointer;
	padding: 5px 0;
	&:not(.active):hover {
		background-color: darken($white, 5%);
	}
}
.list-group-item.active {
	background-color: var(--primary);
	color: $white;
}
.list-group-item.active .icon {
	filter: invert(98%) sepia(1%) saturate(261%) hue-rotate(138deg) brightness(120%) contrast(100%);
}
</style>