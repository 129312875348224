<template>
	<li :class="getDayClass(day)" @click="setSelectedDate($event, day)">
		<div :class="getDateContainerClass(day)">
			<div class="date">
				{{ day.format('D') }}
			</div>
		</div>
		<b-button v-if="newDateIsCurrentDate(day)" variant="primary" id="valid-new-date">
			{{ $t('valid') }}
		</b-button>
		<div class="segments" v-if="day.orders && day.orders.length">
			<div v-for="(order, i) in day.orders" :key="i" class="vertical-rectangle"
				:style="setStyle(order, index)" :id="'order-' + index"
				@mouseover="addBackgroundToRectangle(order, $event)"
				@mouseleave="removeBackgroundToRectangle(order, $event)"
				@click="setCurrentRoad(order)" />
		</div>
		<div v-if="getCurrentMonth(day, index)" class="current-month d-flex flex-column">
			<span>{{ getCurrentMonth(day, index) }}</span>
			<span>{{ day.format('YYYY') }}</span>
		</div>
		<b-button v-if="selectedDateIsCurrentDate(day)" class="btn-round-icon" variant="primary"
			id="remove-selected-date" @click="removeSelectedDate()">
			<Icon icon="cross" />
		</b-button>
		<p id="valid-new-date-text" v-if="newDateIsCurrentDate(day)" class="color-primary text-initial text-center">
			{{ $t('new-date') }} ?
		</p>
	</li>
</template>
<script>
export default {
	props : {
		items : {
			required : true,
			type     : Object,
		},
		day : {
			required : true,
			type     : Object,
		},
		index : {
			required : true,
			type     : Number,
		},
	},
	methods : {
		addBackgroundToRectangle(order, $event) {
			const t = this;
			const currentRoad = t.items.currentRoad;
			const road = t.items.roads.find((road) => road._id == order.transportRoutes[0]._id);
			if(currentRoad && road) {
				$event.target.style.background = road.color;
			}
		},
		removeBackgroundToRectangle(order, $event) {
			const currentRoad = this.items.currentRoad;
			const road = this.items.roads.find((road) => road._id == order.transportRoutes[0]._id);
			if(currentRoad && !road) {
				$event.target.style.background = '#e6e6e6';
			}
		},
		setCurrentRoad(order) {
			const t = this;
			const road = this.items.roads.find((road) => road._id == order.transportRoutes[0]._id);
			if(t.currentRoad && road._id == t.currentRoad._id) {
				t.items.currentRoad = null;
			} else {
				t.items.currentRoad = road;
			}
			t.$emit('set-items', t.items);
		},
		setStyle(order) {
			const t = this;
			const currentRoad = t.items.currentRoad;
			if(currentRoad) {
				const road = currentRoad._id == order.transportRoutes[0]._id;
				if(road) {
					return 'background:' + currentRoad.color;
				}
			}else {
				return 'background: #E6E6E6';
			}
		},
		getDayClass(day) {
			let dayClass = 'day';
			if(day.class != undefined) {
				dayClass = dayClass + ' ' + day.class;
			}
			if(this.selectedDateIsCurrentDate(day)) {
				dayClass = dayClass + ' active';
			}
			if(this.newDateIsCurrentDate(day)) {
				dayClass = dayClass + ' new-date';
			}
			return dayClass;
		},
		getDateContainerClass(day) {
			let dateContainerClass = 'date-container';
			if(this.items.selectedDate) {
				const diff = Math.round(day.diff(this.items.selectedDate, 'days', true));
				if(diff >= 1) {
					if(diff < 7) {
						dateContainerClass = dateContainerClass + ' red';
					} else if(diff >= 7 && diff < 14) {
						dateContainerClass = dateContainerClass + ' grey';
					}
				}
			}
			return dateContainerClass;
		},
		getCurrentMonth(day, index) {
			if(day.class != undefined) {
				if(index % 7 == 0) {
					return this._i18n.t('month-abbreviated-' + day.format('M'));
				}
			}
		},
		newDateIsCurrentDate(day) {
			const newDate = this.items.newDate;
			if(newDate) {
				if(newDate.diff(day) == 0) {
					return true;
				}
			}
			return false;
		},
		selectedDateIsCurrentDate(day) {
			const selectedDate = this.items.selectedDate;
			if(selectedDate) {
				if(selectedDate.format('DD/MM/YYYY') == day.format('DD/MM/YYYY')) {
					return true;
				}
			}
			return false;
		},
		removeSelectedDate() {
			const t = this;
			t.items.selectedDate = null;
			t.items.newDate = null;
			t.$emit('set-items', t.items);
			t.$emit('set-items', t.items);
		},
		setSelectedDate($event, day) {
			if(!$event.target.closest('#remove-selected-date')) {
				const t = this;
				const selectedDate = t.items.selectedDate;
				if(!$event.target.closest('.vertical-rectangle')) {
					if(selectedDate && day.diff(selectedDate, 'days', true) > 0) {
						t.items.newDate = day,
						t.$emit('set-items', t.items);
					} else {
						if(day.orders.length) {
							t.items.selectedDate = day,
							t.$emit('set-items', t.items);
						}
					}
				}
			}
		}

	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.day {
	width: 14.28%;
	height: 90px;
	border-bottom: 1px solid $light-grey;
	border-left: 1px solid $light-grey;
	display: inline-table;
	position: relative;
	font-weight: 700;
	cursor: pointer;
	&:last-of-type {
		border-right: 1px solid $light-grey;
	}
}
.day .date {
	padding: 0.1rem 0.5rem 0.1rem 0.5rem;
}
.day.first-day-of-month {
	border-top: 3px solid var(--primary);
	border-left: 3px solid var(--primary);
}
.day.weekdays {
	border-top: 3px solid var(--primary);
}
.day.new-date {
	border: 3px solid var(--primary);
}
.day.active {
	border: 3px solid $green;
}
.date-container.red {
	background-color: $red;
	border-top: 2px solid $red;
}
.date-container.grey {
	background-color: $grey;
	border-top: 2px solid $grey;
}
.day.weekdays .date-container,
.day.first-day-of-month .date-container,
.day.new-date .date-container {
	border: 0;
}
.day.new-date #valid-new-date {
	height: 25px;
}
.date-container.red .date,
.date-container.grey .date {
	color: $white;
}
.current-month {
	color: var(--primary);
	position: absolute;
	top: -6px;
	left: -43px;
	font-weight: 700;
	&:after {
		content: '';
		display: flex;
		position: absolute;
		top: 3px;
		right: -13px;
		width: 10px;
		height: 3px;
		background-color: var(--primary);
	}
}
.current-month span {
	line-height: 13px;
}
.segments {
	display: flex;
	flex-wrap: wrap;
	padding: 0.25rem 0.5rem;
}
.segments > .vertical-rectangle {
	margin-right: 2px;
	margin-bottom: 2px;
}
#remove-selected-date {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 0;
	z-index: 1;
	left: 0;
	transform: translate(-60%, -60%);
}
#remove-selected-date .icon {
	font-size: 8px;
}
#valid-new-date-text {
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	font-size: 0.9rem;
}
#valid-new-date {
	border-radius: 0;
	padding: 0.3rem 0.25rem;
	font-size: 0.75rem;
	position: absolute;
	top: 0;
	right: 0;
}
</style>