<template>
	<form @submit="submit" autocomplete="off">
		<b-input type="text" v-model="$v.register.user.emailconf.$model"
			name="emailconf" v-show="false" />
		<b-form-group :label="$t('email')">
			<b-input placeholder="_" type="email" v-model="$v.register.user.email.$model"
				:state="$_validateState($v.register.user.email)" name="email" />
			<b-form-invalid-feedback>{{ $t('email-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('email-confirmation')">
			<b-input placeholder="_" type="email" v-model="$v.register.user.confirmEmail.$model"
				:state="$_validateState($v.register.user.confirmEmail)" name="emailconfirm" />
			<b-form-invalid-feedback>{{ $t('confirm-email-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('password')">
			<b-input placeholder="_" type="password" v-model="$v.register.user.password.$model"
				:state="$_validateState($v.register.user.password)" name="password" autocomplete="new-password" />
			<b-form-invalid-feedback>{{ $t('password-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('password-confirmation')">
			<b-input placeholder="_" type="password" v-model="$v.register.user.repassword.$model"
				:state="$_validateState($v.register.user.repassword)" name="passwordconfirm"
				autocomplete="new-password" />
			<b-form-invalid-feedback>{{ $t('confirm-password-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('lastname')">
			<b-input placeholder="_" type="tel" v-model="$v.register.user.lastname.$model"
				:state="$_validateState($v.register.user.lastname)" name="lastname" autocomplete="family-name" />
			<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('firstname')">
			<b-input placeholder="_" type="tel" v-model="$v.register.user.firstname.$model"
				:state="$_validateState($v.register.user.firstname)" name="firstname" autocomplete="given-name" />
			<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('phone-number')">
			<b-input placeholder="_" type="tel" v-model="$v.register.user.phone.$model"
				:state="$_validateState($v.register.user.phone)" name="phonenumber" />
			<b-form-invalid-feedback>{{ $t('phone-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('language')">
			<b-form-select :value="null" :options="languages"
				v-model="$v.register.user.language.$model" :state="$_validateState($v.register.user.language)"
				name="language">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
			<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-checkbox class="text-initial" v-model="sameAddress">
			{{ $t('identical-address-to-company') }}
		</b-form-checkbox>
		<div v-if="!sameAddress" id="user-address">
			<b-form-group :label="$t('address')">
				<b-input placeholder="_" v-model="$v.register.user.address.street.$model"
					:state="$_validateState($v.register.user.address.street)" />
				<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
			</b-form-group>
			<b-form-group :label="$t('city')">
				<b-input placeholder="_" v-model="$v.register.user.address.city.$model"
					:state="$_validateState($v.register.user.address.city)" />
				<b-form-invalid-feedback>{{ $t('field-text-err') }}</b-form-invalid-feedback>
			</b-form-group>
			<b-form-group :label="$t('postal-code')">
				<b-input placeholder="_" v-model="$v.register.user.address.postalCode.$model"
					:state="$_validateState($v.register.user.address.postalCode)" />
				<b-form-invalid-feedback>{{ $t('postal-code-err') }}</b-form-invalid-feedback>
			</b-form-group>
			<b-form-group :label="$t('country')"
				:class="getClassMultiSelectForValidation($v.register.user.address.country)">
				<multi-select v-model="$v.register.user.address.country.$model"
					:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
					select-label="" placeholder="_" :searchable="true" label="name" track-by="name"
					:options="countries" :hide-selected="false">
					<template slot="noResult">
						{{ $t('no-result') }}
					</template>
				</multi-select>
				<b-form-invalid-feedback>
					{{ $t('field-required-err') }}
				</b-form-invalid-feedback>
			</b-form-group>
		</div>
		<div class="d-flex justify-content-between w-100 mt-3">
			<b-button variant="info" class="w-49" @click="previousStep">
				{{ $t('previous') }}
			</b-button>
			<b-button variant="primary" class="w-49" type="submit" @click="submit" id="registerbutton">
				{{ $t('register') }}
			</b-button>
		</div>
	</form>
</template>

<script>
import { validateEmail, validatePassword, validateConfirmPassword,
	validateConfirmEmail, validateTextRequired, validateRequired, validatePhone,
	validatePostalCode } from '@/utils/plugins/validations/vuelidate';
import countriesMixins from '@/mixins/countries.js';
import languagesMixins from '@/mixins/languages.js';
export default {
	name       : 'User',
	mixins     : [countriesMixins, languagesMixins],
	props  : {
		register : {
			type     : Object,
			required : true
		},
	},
	computed : {
	},
	data() {
		return {
			sameAddress : true
		};
	},
	validations() {
		const validations = {
			register : {
				user : {
					firstname    : validateTextRequired,
					lastname     : validateTextRequired,
					email        : validateEmail,
					confirmEmail : validateConfirmEmail,
					password     : validatePassword,
					repassword   : validateConfirmPassword,
					phone        : validatePhone,
					language     : validateRequired,
					emailconf    : validateRequired,
				}
			}
		};
		if(!this.sameAddress) {
			validations.register.user.address = {
				street     : validateTextRequired,
				country    : validateRequired,
				city       : validateTextRequired,
				postalCode : validatePostalCode
			};
		}
		return validations;
	},
	methods  : {
		previousStep() {
			this.$emit('go-to-step', 'address');
		},
		submit($event) {
			$event.preventDefault();
			this.$v.register.$touch();
			if (!this.$v.register.$invalid) {
				this.$store.commit('registers/register', this.register);
				const register = this.setRegisterInformation();

				this.$store.dispatch('registers/CREATE', register)
					.then(() => {
						this.$router.push('/send-mail-confirmation');
					})
					.catch((err) => {
						this.$_makeToast('danger',
							this._i18n.t(err),
							this._i18n.t('error')
						);
					});
			}
		},
		setRegisterInformation() {
			const register = this.$_clone(this.register);
			register.company.address.country = register.company.address.country.alpha2;
			const phones = [];
			phones[0] = register.user.phone.slice();
			register.user.phone = phones;
			if(register.user.address && register.user.address.country) {
				register.user.address.country = register.user.address.country.alpha2;
			}
			if(this.sameAddress) {
				register.user.address = register.company.address;
			}
			delete register.user.sameAddress;
			delete register.user.confirmEmail;
			return register;
		}
	}
};
</script>
<style lang="scss" scoped>
#user-address {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 1rem;
	width: 100%;
}
</style>
