<template>
	<b-col xl="3" lg="6" md="6">
		<b-form-group :label="$t('search')">
			<b-form-input placeholder="_" v-model="search" />
		</b-form-group>
	</b-col>
</template>
<script>
export default {

	data() {
		return {
			search : null,
		};
	},
	created() {
		if(this.$route.query['search']) {
			this.search = this.$route.query['search'];
		}
	},
	watch : {
		search(val) {
			if(val) {
				this.$emit('set-search', val, 'search');
			}
		}
	},
	methods : {
		reset() {
			this.search = null;
		}
	}
};
</script>