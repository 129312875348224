<template>
	<div id="collaborators-list">
		<Table :items="collaborators" :fields="fields" :loading="loading" @load="load()" :title="$t('collaborators')"
			:pagination="pagination" :reload="reload">
			<template v-slot:buttons>
				<b-button variant="primary" v-if="userHaveAccess('CreateCollaborator')"
					@click="$router.push({name: 'CreateCollaborator'})" id="createCollaborator">
					{{ $t('add-collaborator') }}
				</b-button>
			</template>
			<template v-slot:filters>
				<Search />
				<Status />
			</template>
			<template #cell(action)="collaborator">
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="$router.push({name: 'ViewCollaborator',
									params: {id: collaborator.item._id}})"
								:id="'viewCollaborator'+collaborator.index">
								<Icon icon="eye" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon"
								@click="$router.push({name: 'UpdateCollaborator',
									params: {id: collaborator.item._id}})"
								:id="'updateCollaborator'+collaborator.index">
								<Icon icon="edit" />
							</b-button>
						</li>
						<li>
							<b-button variant="primary" class="btn-round-icon"
								v-if="collaborator.item.active" @click="deactivate(collaborator.item._id)"
								:id="'activateCollaborator'+collaborator.index">
								<Icon icon="on" />
							</b-button>
							<b-button variant="primary" class="btn-round-icon"
								v-else @click="activate(collaborator.item._id)"
								:id="'deactivateCollaborator'+collaborator.index">
								<Icon icon="off" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</Table>
	</div>
</template>

<script>
import { Search, Status } from '@/components/filters/components';

export default {
	name       : 'Collaborators',
	components : {
		Search,
		Status
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('collaborators'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label        : this._i18n.t('firstname'),
					key          : 'firstname',
					sortable     : true,
				},
				{
					label    : this._i18n.t('lastname'),
					key      : 'lastname',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			loading       : true,
			collaborators : [],
			filters       : {
				name    : null,
				status : null
			},
			pagination : {
				perPage     : 10,
				totalRows  	: null,
				currentPage : 1,
			},
			reload : false
		};
	},

	methods : {
		load() {
			this.loading = true;
			this.$store.dispatch('collaborator/LOAD').then((res) => {
				if(res) {
					this.collaborators = res;
					this.pagination.totalRows = res.length;
				}
				this.loading = false;
				this.reload = false;
			}).catch(() => {
				this.loading = false;
				this.reload = false;
			});
		},
		activate(id) {
			this.$store.dispatch('collaborator/ACTIVATE', id)
				.then(() => {
					this.reload = true;
				}).catch((err) => {
					this.$_makeToast('danger',
						this._i18n.t(err),
						this._i18n.t('error')
					);
				});
		},
		deactivate(id) {
			this.$store.dispatch('collaborator/DEACTIVATE', id)
				.then(() => {
					this.reload = true;
				}).catch((err) => {
					this.$_makeToast('danger',
						this._i18n.t(err),
						this._i18n.t('error')
					);
				});
		}
	}
};
</script>
