<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('circulations-realized') }}
		</b-card-header>
		<b-card-body>
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null">
						{{ $t('january') }}
					</b-form-select-option>
				</template>
			</b-form-select>
			<Speedometer :circulation="circulation" :circulation-planned="40" :loading="loading" />
			<h6 class="d-flex w-100 justify-content-center align-items-center">
				<span>{{ $t('on') }}</span>
				<span v-if="!loading" class="mx-1">40</span>
				<b-skeleton v-else width="8%" class="mx-1 my-0" />
				<span class="no-wrap">{{ $t('planned-circulations') }}</span>
			</h6>
		</b-card-body>
	</b-card>
</template>
<script>
import Speedometer from './components/Speedometer';
export default {
	components : {
		Speedometer,
	},
	data() {
		return {
			circulation : 0,
			loading     : true,
		};
	},
	created() {
		setTimeout(() => {
			this.circulation = 20;
			this.loading = false;
		}, 3000);
	},
	methods : {
		animateCirculation(end, duration) {
			const timeOut = duration / end;
			let sum = 0;
			var interval = setInterval(() => {
				this.circulation++;
				sum = sum + timeOut;
				if(sum >= duration) clearInterval(interval);
			}, timeOut);
		}
	}

};
</script>