<template>
	<b-card no-body>
		<b-card-header class="d-flex justify-content-between align-items-center">
			<div>
				{{ $t('locations') }}
			</div>
			<div class="d-flex text-initial" v-if="$route.name != 'VisualizationContracts'">
				<div class="d-flex align-items-center" v-if="isOwner">
					<legend class="mb-0 mr-2">
						{{ $t('charger') }}
					</legend>
					<b-form-checkbox v-model="locationsValidation.validatedCharger" class="success"
						switch size="lg" :id="'checkboxCharger'+index" />
				</div>
				<div class="d-flex ml-2 align-items-center" v-if="$route.name == 'EditContracts'">
					<legend class="mb-0 mr-2">
						{{ $t('provider') }}
					</legend>
					<b-form-checkbox :disabled="isOwner" v-model="locationsValidation.validatedOperator" class="success"
						switch size="lg" :id="'checkboxProvider'+index" />
				</div>
			</div>
		</b-card-header>
		<b-card-body>
			<ul>
				<li v-for="(location, index) in getUserLocations" :key="index">
					<div>
						<span v-for="(modal,m) in location.modals" :key="m">
							<Icon :icon=" findIconType(modal.typology)" />

						</span>
					</div>
					<div>{{ location.name }}</div>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	props : {
		getUserLocations : {
			required : true,
			type     : Array
		},
		isOwner : {
			required : true,
			type     : Boolean
		}
	},
	data() {
		return {
			locationsValidation : {
				validatedOperator : false,
				validatedCharger   : false
			}
		};
	},
	created() {
		if(this.getUserLocations.length) {
			if(this.getUserLocations.every((x) => x.validatedCharger == true)) {
				this.locationsValidation.validatedCharger = true;
			}
			if(this.getUserLocations.every((x) => x.validatedOperator == true)) {
				this.locationsValidation.validatedOperator = true;
			}
		}
	},
	watch : {
		locationsValidation : {
			handler(val) {
				if(this.isOwner) {
					if(val.validatedCharger) {
						for (let i = 0; i < this.getUserLocations.length; i++) {
							const location = this.getUserLocations[i];
							location.validatedCharger = true;
						}
					} else {
						for (let i = 0; i < this.getUserLocations.length; i++) {
							const location = this.getUserLocations[i];
							location.validatedCharger = false;
						}
					}
				} else {
					if(val.validatedOperator) {
						for (let i = 0; i < this.getUserLocations.length; i++) {
							const location = this.getUserLocations[i];
							location.validatedOperator = true;
						}
					} else {
						for (let i = 0; i < this.getUserLocations.length; i++) {
							const location = this.getUserLocations[i];
							location.validatedOperator = false;
						}
					}
				}
			},
			deep : true
		},
		getUserLocations : {
			handler(val) {
				if(this.isOwner) {
					for (let i = 0; i < val.length; i++) {
						const location = val[i];
						if(!location.validatedCharger) {
							this.locationsValidation.validatedCharger = false;
							return;
						}
					}
					this.locationsValidation.validatedCharger = true;
				} else {
					for (let i = 0; i < val.length; i++) {
						const location = val[i];
						if(!location.validatedOperator) {
							this.locationsValidation.validatedOperator = false;
							return;
						}
					}
					this.locationsValidation.validatedOperator = true;
				}
			},
			deep : true
		}
	},
	methods : {
		findIconType(text) {
			const opt = [
				{
					text : 'ROAD',
					icon : 'truck'
				},
				{
					text : 'RAIL',
					icon : 'train'
				},
				{
					text : 'AIR',
					icon : 'airplane'
				},
				{
					text : 'SEA',
					icon : 'boat'
				},
				{
					text : 'RIVER',
					icon : 'river'
				},
			];
			return opt.find((x) => x.text == text).icon;
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
ul {
	display: flex;
	flex-direction: column;
}
ul li {
	display: flex;
	border-bottom: 2px solid $light-grey;
	padding: 0.5rem 0;
	font-weight: 600;
}
</style>