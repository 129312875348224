<template>
	<div id="planning">
		<h1>{{ $t('planning-of-orders') }}</h1>
		<b-card no-body class="mt-3" v-if="!loading">
			<b-card-header class="primary">
				{{ $t('road') }}
			</b-card-header>
			<b-card-body>
				<b-row class="h-100">
					<b-col lg="8" class="max-h-100">
						<Calendar :items="items" @set-items="setItems" />
					</b-col>
					<b-col lg="4" class="child-mt-3">
						<FilterPerRoad @set-items="setItems" :items="items" v-if="items.orders.length" />
						<SelectDate v-if="items.currentRoad" @set-items="setItems" :items="items" />
						<EditOrder v-if="items.currentRoad || items.selectedDate"
							@set-items="setItems" :items="items" />
						<Cancel v-if="items.selectedDate" />
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
		<div class="loading" v-else>
			<b-spinner variant="primary" label="Spinning" />
		</div>
	</div>
</template>
<script>
import { Calendar, FilterPerRoad, SelectDate, EditOrder, Cancel } from './planning/';

export default {
	components : {
		Calendar,
		FilterPerRoad,
		SelectDate,
		EditOrder,
		Cancel
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('planning'),
		};
	},
	data() {
		return {
			loading : true,
			items   : {
				orders               : [],
				currentRoad          : null,
				selectedDate         : null,
				newDate              : null,
				roads                : [],
			},
			currentRoad  : null,
			selectedDate : null,
			roads        : [],
			newDate      : null,
		};
	},
	async created() {
		await this.loadOrders();
		await this.loadTransportationRoutes();
		this.loading = false;
		const colors = [
			'#046AF3',
			'#1CA564',
			'#00B2C1',
			'#ED6D02',
			'#0542B0',
			'#24844E',
			'#00909C',
			'#D36301',
			'#061B6E',
			'#2C6438',
			'#006E77',
			'#B95900',
		];
		var orderColor = 0;
		for (let i = 0; i < this.items.orders.length; i++) {
			const order = this.items.orders[i];
			for (let a = 0; a < order.transportRoutes.length; a++) {
				const transportRoute = order.transportRoutes[a];
				const findRoute = this.transportationRoutes.find((x) => x._id == transportRoute._id);
				if(findRoute) {
					const findRouteInRoads = this.items.roads.find((x) => x._id == findRoute._id);
					if(!findRouteInRoads) {
						orderColor += 1;
						if(orderColor == colors.length) orderColor = 0;
						findRoute.color = colors[orderColor];
						this.items.roads.push(findRoute);
					}
				}
			}
		}
	},
	computed : {
		transportationRoutes() {
			return this.$store.getters['transportationRoutes/get'];
		}
	},
	methods : {
		async loadOrders() {
			let query = '?state=ACKNOWLEDGED';
			const selectedContractCode = this.$store.getters['contracts/selected'];
			if (selectedContractCode) {
				const res = await this.$store.dispatch('contracts/loadList');
				const selectedContract = res.results.find(c=>c.code === selectedContractCode);
				if (selectedContract)
					query += '&contractId=' + selectedContract._id;

				// find contract ID
			}
			await this.$store.dispatch('orders/load', query).then((res) => {
				if (res.pagination && res.pagination.length > 0) {
					this.items.orders = res.results;
				} else {
					this.$_makeToast('danger', this._i18n.t('no-orders-are-confirmed'), this._i18n.t('alert'));
				}
			});
		},
		async loadTransportationRoutes() {
			await this.$store.dispatch('transportationRoutes/load');
		},
		setItems(items) {
			this.items = items;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/.vertical-rectangle {
	min-width: 8px;
	border-radius: $border-radius/2;
	height: 18px;
	background-color: $light-grey;
}
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
</style>
<i18n>
{
	"fr":{
		"planning-of-orders": "Plannification des commandes",
		"no-orders-are-confirmed": "Aucune commandes n'est confirmé."
	},
	"en": {
		"planning-of-orders": "Planning of orders",
		"no-orders-are-confirmed": "No orders are confirmed"
	}
}
</i18n>