<template>
	<b-row>
		<b-col lg="6">
			<b-form-group :label="$t('type-of-vehicle')">
				<multi-select v-model="vehicleType" name="vehicleType"
					:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
					select-label="" placeholder="_" :searchable="true" label="text" track-by="value"
					:options="trucksType" :hide-selected="false">
					<template slot="noResult">
						{{ $t('no-result') }}
					</template>
				</multi-select>
			</b-form-group>
		</b-col>
		<b-col lg="6" sm="12">
			<b-form-group :label="$t('number-of-km')">
				<b-form-input placeholder="_" type="number" v-model="segment.technicalConstraint.numberKm" />
			</b-form-group>
		</b-col>
	</b-row>
</template>
<script>
import  trucksMixin  from '@/mixins/list/truckTypes';
export default {
	props : {
		segment : {
			required : true,
			type     : Object
		},
		id : {
			required : true,
			type     : String
		}
	},
	data() {
		return {
			vehicleType : null,
		};
	},
	watch : {
		vehicleType(vehicleType) {
			if(vehicleType)
				this.segment.technicalConstraint.vehicleType = vehicleType.value;
		},
		id : {
			handler() {
				if(this.segment.technicalConstraint) {
					const find = this.trucksType.find(t=>t.value == this.segment.technicalConstraint.vehicleType);
					this.vehicleType = find;
				} else {
					this.vehicleType = null;
				}
			},
			immediate : true
		}
	},
	mixins : [trucksMixin]
};
</script>