/* eslint-disable */
/* eslint-disable-line quotes */
/* eslint-disable-line semi */
/* eslint-disable-line indent */
/* eslint-disable-line key-spacing */
export default {

	computed : {
		stations() {
      return [
        {
            "codeUIC" : 1479,
            "codeCH" : "BV",
            "nameUIC" : "Roissy-Aéroport-CDG 2-RER",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 1479,
            "codeCH" : "TI",
            "nameUIC" : "Roissy-Aéroport-CDG 2-RER",
            "nameCH" : "Tiroirs",
            "code" : ""
        },
        {
            "codeUIC" : 1545,
            "codeCH" : "WG",
            "nameUIC" : "Fréthun-Tunnel",
            "nameCH" : "Portail-Nord",
            "code" : ""
        },
        {
            "codeUIC" : 1545,
            "codeCH" : "W2",
            "nameUIC" : "Fréthun-Tunnel",
            "nameCH" : "Crossovers-Fr",
            "code" : ""
        },
        {
            "codeUIC" : 1545,
            "codeCH" : "XT",
            "nameUIC" : "Fréthun-Tunnel",
            "nameCH" : "Portail-Sud",
            "code" : ""
        },
        {
            "codeUIC" : 2154,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 232",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 2162,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 206",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 2170,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 157,5",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 2188,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 205",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 2196,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 201 (Écuisses)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 2295,
            "codeCH" : 0,
            "nameUIC" : "ITE Sablières de Puy-la-Laude",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4580,
            "codeCH" : 0,
            "nameUIC" : "Carré 3,6",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4598,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 111",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4606,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 116",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4614,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 115",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4622,
            "codeCH" : 0,
            "nameUIC" : "Carré 23,1",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4630,
            "codeCH" : 0,
            "nameUIC" : "Carré 118",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4648,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore n635",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4655,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 632",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4663,
            "codeCH" : 0,
            "nameUIC" : "Évitement d'Orbagna",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4689,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 441",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4697,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 442",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4705,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 341",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 4713,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 342",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 9696,
            "codeCH" : 0,
            "nameUIC" : "La Douzillère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 21030,
            "codeCH" : "BV",
            "nameUIC" : "Bény Aiguille IPCS",
            "nameCH" : "Aiguille",
            "code" : ""
        },
        {
            "codeUIC" : 25098,
            "codeCH" : 0,
            "nameUIC" : "Monteux-les-Escampades",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 25114,
            "codeCH" : "EP",
            "nameUIC" : "ITE Rossi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 25122,
            "codeCH" : "EP",
            "nameUIC" : "ITE Société Étienne Sirops DD",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 27102,
            "codeCH" : 0,
            "nameUIC" : "Signal 195",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 27110,
            "codeCH" : 0,
            "nameUIC" : "Signal 196",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 27128,
            "codeCH" : "PR",
            "nameUIC" : "Signal PR",
            "nameCH" : "Signal 215",
            "code" : ""
        },
        {
            "codeUIC" : 27128,
            "codeCH" : "SG",
            "nameUIC" : "Signal PR",
            "nameCH" : "Carré 216",
            "code" : ""
        },
        {
            "codeUIC" : 50013,
            "codeCH" : 0,
            "nameUIC" : "ITE ZI St-Martin-d'Héres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 103903,
            "codeCH" : "JO",
            "nameUIC" : "St-Hilaire-de-Briouze",
            "nameCH" : "Jonction",
            "code" : ""
        },
        {
            "codeUIC" : 104562,
            "codeCH" : "WE",
            "nameUIC" : "Cambiador Portbou",
            "nameCH" : "Échangeur Talgo",
            "code" : ""
        },
        {
            "codeUIC" : 104570,
            "codeCH" : "WE",
            "nameUIC" : "Cambiador Irun",
            "nameCH" : "Échangeur Talgo",
            "code" : ""
        },
        {
            "codeUIC" : 107896,
            "codeCH" : "W3",
            "nameUIC" : "Antoing",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 109306,
            "codeCH" : 0,
            "nameUIC" : "Lille CHR",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 110965,
            "codeCH" : "JO",
            "nameUIC" : "St-Martin-de-Tallevende",
            "nameCH" : "Jonction",
            "code" : ""
        },
        {
            "codeUIC" : 111278,
            "codeCH" : 0,
            "nameUIC" : "Porte-de-Clichy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111732,
            "codeCH" : 0,
            "nameUIC" : "Ville-en-Vermois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111757,
            "codeCH" : 0,
            "nameUIC" : "ITE Tournan Base LGV",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111815,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 346",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111823,
            "codeCH" : "AS",
            "nameUIC" : "Poste 74 Jablines",
            "nameCH" : "Aiguilles Sud Raccordement Est",
            "code" : ""
        },
        {
            "codeUIC" : 111823,
            "codeCH" : "PR",
            "nameUIC" : "Poste 74 Jablines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111831,
            "codeCH" : "ET",
            "nameUIC" : "Poste 72 Neufmoutiers-en-Brie",
            "nameCH" : "Entrée R1C",
            "code" : ""
        },
        {
            "codeUIC" : 111831,
            "codeCH" : "PR",
            "nameUIC" : "Poste 72 Neufmoutiers-en-Brie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111831,
            "codeCH" : "RT",
            "nameUIC" : "Poste 72 Neufmoutiers-en-Brie",
            "nameCH" : "Aiguilles Raccordement Tournan",
            "code" : ""
        },
        {
            "codeUIC" : 111831,
            "codeCH" : "TV",
            "nameUIC" : "Poste 72 Neufmoutiers-en-Brie",
            "nameCH" : "Transition TVM",
            "code" : ""
        },
        {
            "codeUIC" : 111849,
            "codeCH" : "AN",
            "nameUIC" : "Marne-la-Vallée-Chessy",
            "nameCH" : "Aiguilles Nord",
            "code" : ""
        },
        {
            "codeUIC" : 111849,
            "codeCH" : "AS",
            "nameUIC" : "Marne-la-Vallée-Chessy",
            "nameCH" : "Aiguilles Sud",
            "code" : ""
        },
        {
            "codeUIC" : 111849,
            "codeCH" : "BV",
            "nameUIC" : "Marne-la-Vallée-Chessy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 111849,
            "codeCH" : "PR",
            "nameUIC" : "Marne-la-Vallée-Chessy",
            "nameCH" : "Poste 73",
            "code" : ""
        },
        {
            "codeUIC" : 111864,
            "codeCH" : 0,
            "nameUIC" : "Balsièges-Bourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111872,
            "codeCH" : "BM",
            "nameUIC" : "Poste 71 Presles-en-Brie",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 111872,
            "codeCH" : "PR",
            "nameUIC" : "Poste 71 Presles-en-Brie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 111971,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 125,3",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 112086,
            "codeCH" : "EP",
            "nameUIC" : "Grandpuits-Bagneux",
            "nameCH" : "ITE Grande Paroisse",
            "code" : "GRP"
        },
        {
            "codeUIC" : 112110,
            "codeCH" : 0,
            "nameUIC" : "ITE Lafarge emballage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 112128,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 538,4 (CNR)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 112136,
            "codeCH" : 0,
            "nameUIC" : "ITE ATO",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 112185,
            "codeCH" : 0,
            "nameUIC" : "ITE Zone Industrielle N°2",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 112201,
            "codeCH" : "AI",
            "nameUIC" : "Plateforme AFA",
            "nameCH" : "Aiguille",
            "code" : ""
        },
        {
            "codeUIC" : 112201,
            "codeCH" : 0,
            "nameUIC" : "Plateforme AFA",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 112482,
            "codeCH" : "BL",
            "nameUIC" : "Poste 64 Solers",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 112482,
            "codeCH" : "PR",
            "nameUIC" : "Poste 64 Solers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 112490,
            "codeCH" : "AN",
            "nameUIC" : "Poste 76 Messy",
            "nameCH" : "Accès Nord Raccordement LGV",
            "code" : ""
        },
        {
            "codeUIC" : 112490,
            "codeCH" : "PR",
            "nameUIC" : "Poste 76 Messy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113001,
            "codeCH" : 0,
            "nameUIC" : "Paris-Est",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : "DP",
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : "FM",
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Faisceau",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : "PK",
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Pk 1,840",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : "P2",
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : "T3",
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Tiroir 3",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : 12,
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "PL Km 1,2",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : 13,
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Accès Voies 1EO/T3/VL",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : 26,
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Rac de l'Évangile",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : 48,
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Voie4",
            "code" : ""
        },
        {
            "codeUIC" : 113100,
            "codeCH" : 65,
            "nameUIC" : "Paris-La Villette",
            "nameCH" : "Carré Violet 165",
            "code" : ""
        },
        {
            "codeUIC" : 113126,
            "codeCH" : 0,
            "nameUIC" : "Paris-La Vilette-Est-Pierres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113134,
            "codeCH" : 0,
            "nameUIC" : "Paris-La Villette-Hébert",
            "nameCH" : "",
            "code" : "PVH"
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "EN",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Entretien",
            "code" : ""
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "FC",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Faisceau",
            "code" : ""
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "GG",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Garages",
            "code" : ""
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "PA",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Accès Ourcq",
            "code" : ""
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "PB",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Poste B",
            "code" : ""
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "PC",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Poste C",
            "code" : ""
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "PO",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Poste O",
            "code" : ""
        },
        {
            "codeUIC" : 113159,
            "codeCH" : "PR",
            "nameUIC" : "Paris-Garage-de-l'Ourcq",
            "nameCH" : "Poste R",
            "code" : ""
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "A3",
            "nameUIC" : "Pantin",
            "nameCH" : "Accès Voie 1EO/Voie 1B1/Voie M",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "BV",
            "nameUIC" : "Pantin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "B1",
            "nameUIC" : "Pantin",
            "nameCH" : "Poste B1",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "B7",
            "nameUIC" : "Pantin",
            "nameCH" : "Poste B7",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "FV",
            "nameUIC" : "Pantin",
            "nameCH" : "Faisceau V",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "LO",
            "nameUIC" : "Pantin",
            "nameCH" : "Local",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "ML",
            "nameUIC" : "Pantin",
            "nameCH" : "Machine-à-laver",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "PE",
            "nameUIC" : "Pantin",
            "nameCH" : "Point E",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "PG",
            "nameUIC" : "Pantin",
            "nameCH" : "Poste G",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "PJ",
            "nameUIC" : "Pantin",
            "nameCH" : "Point J",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "SM",
            "nameUIC" : "Pantin",
            "nameCH" : "Saut de Mouton",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "TR",
            "nameUIC" : "Pantin",
            "nameCH" : "Faisceau D",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "ZN",
            "nameUIC" : "Pantin",
            "nameCH" : "Zone",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "1B",
            "nameUIC" : "Pantin",
            "nameCH" : "Aiguille Voie 1Bis/Voie 1",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "1R",
            "nameUIC" : "Pantin",
            "nameCH" : "Aiguille Voie 1RF/Voie N",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : "2T",
            "nameUIC" : "Pantin",
            "nameCH" : "Aiguille Voie 2Bis/Voie 2RF",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : 33,
            "nameUIC" : "Pantin",
            "nameCH" : "Aiguilles Km 3,3",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113209,
            "codeCH" : 37,
            "nameUIC" : "Pantin",
            "nameCH" : "Aiguille Voie 2Bis/Voie N",
            "code" : "PAN"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "BA",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Faisceau Banlieue",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "BR",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Bifurcation GC/Ligne 4",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "BV",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "B9",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Pk 59,2",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "C2",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Carré Violet 210",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "DP",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Dépôt",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "FN",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Faisceau Nord",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "FR",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Faisceau Réception",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "HA",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Halle",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "LO",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Local",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "MT",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Centre Multitechnique",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "PF",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Poste F",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "PK",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Poste K",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "TI",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Tiroir",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "TK",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Tiroir PK",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : "TR",
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Triage",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : 72,
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Aiguille Km 7,1",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113217,
            "codeCH" : 83,
            "nameUIC" : "Noisy-le-Sec",
            "nameCH" : "Bifurcation Impaire Ouest",
            "code" : "NSY"
        },
        {
            "codeUIC" : 113407,
            "codeCH" : "BV",
            "nameUIC" : "Bondy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 113407,
            "codeCH" : "V4",
            "nameUIC" : "Bondy",
            "nameCH" : "Voie 4",
            "code" : ""
        },
        {
            "codeUIC" : 113472,
            "codeCH" : 0,
            "nameUIC" : "Le Raincy-Villemomble-Montferm",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113514,
            "codeCH" : "AG",
            "nameUIC" : "Gagny",
            "nameCH" : "Aiguille Voie 2Bis/Voie 2Ter",
            "code" : ""
        },
        {
            "codeUIC" : 113514,
            "codeCH" : "BF",
            "nameUIC" : "Gagny",
            "nameCH" : "Bifurcation GC/Ligne 1",
            "code" : ""
        },
        {
            "codeUIC" : 113514,
            "codeCH" : "BV",
            "nameUIC" : "Gagny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 113522,
            "codeCH" : 0,
            "nameUIC" : "Chenay-Gagny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113696,
            "codeCH" : 0,
            "nameUIC" : "Rosny-Bois-Perrier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113704,
            "codeCH" : 0,
            "nameUIC" : "Rosny-sous-Bois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113712,
            "codeCH" : 0,
            "nameUIC" : "Val-de-Fontenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113746,
            "codeCH" : "AG",
            "nameUIC" : "Nogent-Le Perreux",
            "nameCH" : "Aiguille Km 14,9 Voie 1Bis/Voie 1",
            "code" : "NOP"
        },
        {
            "codeUIC" : 113746,
            "codeCH" : "BF",
            "nameUIC" : "Nogent-Le Perreux",
            "nameCH" : "Bifurcation",
            "code" : "NOP"
        },
        {
            "codeUIC" : 113746,
            "codeCH" : "BV",
            "nameUIC" : "Nogent-Le Perreux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NOP"
        },
        {
            "codeUIC" : 113746,
            "codeCH" : "VC",
            "nameUIC" : "Nogent-Le Perreux",
            "nameCH" : "Voie C",
            "code" : "NOP"
        },
        {
            "codeUIC" : 113779,
            "codeCH" : 0,
            "nameUIC" : "Les Boullereaux-Champigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113795,
            "codeCH" : "AG",
            "nameUIC" : "Villiers-sur-Marne-Le Plessis-",
            "nameCH" : "Raccordement",
            "code" : ""
        },
        {
            "codeUIC" : 113795,
            "codeCH" : "BV",
            "nameUIC" : "Villiers-sur-Marne-Le Plessis-",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 113795,
            "codeCH" : "IT",
            "nameUIC" : "Villiers-sur-Marne-Le Plessis-",
            "nameCH" : "ITE Champigny",
            "code" : ""
        },
        {
            "codeUIC" : 113795,
            "codeCH" : "JN",
            "nameUIC" : "Villiers-sur-Marne-Le Plessis-",
            "nameCH" : "Jonction V1R/V2R",
            "code" : ""
        },
        {
            "codeUIC" : 113803,
            "codeCH" : 0,
            "nameUIC" : "Les Yvris-Noisy-le-Grand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113829,
            "codeCH" : 0,
            "nameUIC" : "ITE Base Travaux d'Eurre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113845,
            "codeCH" : 0,
            "nameUIC" : "Les Coquetiers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113852,
            "codeCH" : 0,
            "nameUIC" : "Pavillons-sous-Bois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113860,
            "codeCH" : "BV",
            "nameUIC" : "Gargan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 113878,
            "codeCH" : 0,
            "nameUIC" : "Allée-de-la-Tour-Rendez-Vous",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113886,
            "codeCH" : 0,
            "nameUIC" : "L'Abbaye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 113894,
            "codeCH" : 0,
            "nameUIC" : "Freinville-Sevran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 114223,
            "codeCH" : 0,
            "nameUIC" : "ITE Budillon-Rabatel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 114348,
            "codeCH" : 0,
            "nameUIC" : "Le Plant-Champigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 114413,
            "codeCH" : "LR",
            "nameUIC" : "Sucy-Bonneuil",
            "nameCH" : "Limite RATP/SNCF",
            "code" : "SUB"
        },
        {
            "codeUIC" : 114413,
            "codeCH" : "PB",
            "nameUIC" : "Sucy-Bonneuil",
            "nameCH" : "Port de Bonneuil",
            "code" : "SUB"
        },
        {
            "codeUIC" : 114413,
            "codeCH" : "TI",
            "nameUIC" : "Sucy-Bonneuil",
            "nameCH" : "Tiroir",
            "code" : "SUB"
        },
        {
            "codeUIC" : 114413,
            "codeCH" : "TR",
            "nameUIC" : "Sucy-Bonneuil",
            "nameCH" : "Voies 6 à 14",
            "code" : "SUB"
        },
        {
            "codeUIC" : 114645,
            "codeCH" : "RN",
            "nameUIC" : "Raccordement Interconnexion",
            "nameCH" : "Raccordement Nord",
            "code" : ""
        },
        {
            "codeUIC" : 114645,
            "codeCH" : "RS",
            "nameUIC" : "Raccordement Interconnexion",
            "nameCH" : "Raccordement Sud",
            "code" : ""
        },
        {
            "codeUIC" : 115857,
            "codeCH" : 0,
            "nameUIC" : "ITE Essence du Sud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 115865,
            "codeCH" : 0,
            "nameUIC" : "ITE SOPAD",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 115873,
            "codeCH" : 0,
            "nameUIC" : "Champbenoist-Poigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 115881,
            "codeCH" : "EC",
            "nameUIC" : "Grandpuits",
            "nameCH" : "ITE CORIF",
            "code" : "GDP"
        },
        {
            "codeUIC" : 115881,
            "codeCH" : "P1",
            "nameUIC" : "Grandpuits",
            "nameCH" : "Poste 1",
            "code" : "GDP"
        },
        {
            "codeUIC" : 115881,
            "codeCH" : "P2",
            "nameUIC" : "Grandpuits",
            "nameCH" : "Poste 2",
            "code" : "GDP"
        },
        {
            "codeUIC" : 115881,
            "codeCH" : "P3",
            "nameUIC" : "Grandpuits",
            "nameCH" : "Poste 3",
            "code" : "GDP"
        },
        {
            "codeUIC" : 115881,
            "codeCH" : "P4",
            "nameUIC" : "Grandpuits",
            "nameCH" : "Poste 4",
            "code" : "GDP"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "BV",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "CV",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "Changement Voies",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "EB",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "ITE KHD",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "EP",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "ITE Bernard Oury",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "PA",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "Poste A",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "PB",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "Poste B",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "PD",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "Point D",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116012,
            "codeCH" : "ZI",
            "nameUIC" : "Gretz-Armainvilliers",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "GZ"
        },
        {
            "codeUIC" : 116020,
            "codeCH" : 0,
            "nameUIC" : "Ozoir-la-Ferrière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116038,
            "codeCH" : 0,
            "nameUIC" : "Roissy-en-Brie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116046,
            "codeCH" : "BV",
            "nameUIC" : "Émerainville-Pontault-Combault",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EPC"
        },
        {
            "codeUIC" : 116046,
            "codeCH" : "EP",
            "nameUIC" : "Émerainville-Pontault-Combault",
            "nameCH" : "ITE GIE",
            "code" : "EPC"
        },
        {
            "codeUIC" : 116053,
            "codeCH" : 0,
            "nameUIC" : "Villepatour-Presles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116061,
            "codeCH" : 0,
            "nameUIC" : "Ozouer-le-Voulgis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116079,
            "codeCH" : 0,
            "nameUIC" : "Verneuil-l'Étang",
            "nameCH" : "",
            "code" : "VNL"
        },
        {
            "codeUIC" : 116087,
            "codeCH" : 0,
            "nameUIC" : "Mormant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116095,
            "codeCH" : "BV",
            "nameUIC" : "Nangis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NAN"
        },
        {
            "codeUIC" : 116095,
            "codeCH" : "ZI",
            "nameUIC" : "Nangis",
            "nameCH" : "ITE ZI",
            "code" : "NAN"
        },
        {
            "codeUIC" : 116103,
            "codeCH" : "A1",
            "nameUIC" : "Meaux",
            "nameCH" : "Aiguille Km 40,0 - Évitement",
            "code" : "MEA"
        },
        {
            "codeUIC" : 116103,
            "codeCH" : "A2",
            "nameUIC" : "Meaux",
            "nameCH" : "Aiguille Km 41,7 - Évitement",
            "code" : "MEA"
        },
        {
            "codeUIC" : 116103,
            "codeCH" : "BV",
            "nameUIC" : "Meaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MEA"
        },
        {
            "codeUIC" : 116103,
            "codeCH" : "EC",
            "nameUIC" : "Meaux",
            "nameCH" : "ITE Cornillard",
            "code" : "MEA"
        },
        {
            "codeUIC" : 116103,
            "codeCH" : "FM",
            "nameUIC" : "Meaux",
            "nameCH" : "Marchandises",
            "code" : "MEA"
        },
        {
            "codeUIC" : 116103,
            "codeCH" : "V3",
            "nameUIC" : "Meaux",
            "nameCH" : "Voie 3",
            "code" : "MEA"
        },
        {
            "codeUIC" : 116103,
            "codeCH" : "ZI",
            "nameUIC" : "Meaux",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "MEA"
        },
        {
            "codeUIC" : 116111,
            "codeCH" : "AG",
            "nameUIC" : "Chelles-Gournay",
            "nameCH" : "Aiguille côté Gagny",
            "code" : ""
        },
        {
            "codeUIC" : 116111,
            "codeCH" : "BV",
            "nameUIC" : "Chelles-Gournay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 116111,
            "codeCH" : 0,
            "nameUIC" : "Chelles-Gournay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116129,
            "codeCH" : "EP",
            "nameUIC" : "Maison-Rouge-en-Brie",
            "nameCH" : "ITE Damrec PT D",
            "code" : "MEB"
        },
        {
            "codeUIC" : 116129,
            "codeCH" : "E1",
            "nameUIC" : "Maison-Rouge-en-Brie",
            "nameCH" : "ITE CA La-Brie",
            "code" : "MEB"
        },
        {
            "codeUIC" : 116129,
            "codeCH" : "PB",
            "nameUIC" : "Maison-Rouge-en-Brie",
            "nameCH" : "Point L",
            "code" : "MEB"
        },
        {
            "codeUIC" : 116137,
            "codeCH" : 0,
            "nameUIC" : "Longueville (Seine-et-Marne)",
            "nameCH" : "",
            "code" : "LON"
        },
        {
            "codeUIC" : 116145,
            "codeCH" : "BV",
            "nameUIC" : "Flamboin-Gouaix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FLG"
        },
        {
            "codeUIC" : 116145,
            "codeCH" : "PL",
            "nameUIC" : "Flamboin-Gouaix",
            "nameCH" : "Sémaphore 98,8",
            "code" : "FLG"
        },
        {
            "codeUIC" : 116145,
            "codeCH" : "S1",
            "nameUIC" : "Flamboin-Gouaix",
            "nameCH" : "Sémaphore 193",
            "code" : "FLG"
        },
        {
            "codeUIC" : 116160,
            "codeCH" : "BV",
            "nameUIC" : "Provins",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PRO"
        },
        {
            "codeUIC" : 116160,
            "codeCH" : "EP",
            "nameUIC" : "Provins",
            "nameCH" : "ITE Ceratera",
            "code" : "PRO"
        },
        {
            "codeUIC" : 116160,
            "codeCH" : "ES",
            "nameUIC" : "Provins",
            "nameCH" : "ITE Coopérative Union Agricole de Rosay-en-Brie",
            "code" : "PRO"
        },
        {
            "codeUIC" : 116178,
            "codeCH" : 0,
            "nameUIC" : "Ste-Colombe-Septveilles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116186,
            "codeCH" : "EP",
            "nameUIC" : "Léchelle (Seine-et-Marne)",
            "nameCH" : "ITE CA La-Brie",
            "code" : "LHE"
        },
        {
            "codeUIC" : 116194,
            "codeCH" : "BV",
            "nameUIC" : "Villiers-St-Georges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VSS"
        },
        {
            "codeUIC" : 116194,
            "codeCH" : "EP",
            "nameUIC" : "Villiers-St-Georges",
            "nameCH" : "ITE CA Brie Est",
            "code" : "VSS"
        },
        {
            "codeUIC" : 116210,
            "codeCH" : "BV",
            "nameUIC" : "Tournan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TOU"
        },
        {
            "codeUIC" : 116210,
            "codeCH" : "CV",
            "nameUIC" : "Tournan",
            "nameCH" : "Changement Voies",
            "code" : "TOU"
        },
        {
            "codeUIC" : 116210,
            "codeCH" : "EB",
            "nameUIC" : "Tournan",
            "nameCH" : "ITE Bosch",
            "code" : "TOU"
        },
        {
            "codeUIC" : 116210,
            "codeCH" : "EV",
            "nameUIC" : "Tournan",
            "nameCH" : "ITE Val Bréon",
            "code" : "TOU"
        },
        {
            "codeUIC" : 116210,
            "codeCH" : "E2",
            "nameUIC" : "Tournan",
            "nameCH" : "ITE Grands Vins de France",
            "code" : "TOU"
        },
        {
            "codeUIC" : 116228,
            "codeCH" : 0,
            "nameUIC" : "Marles-en-Brie",
            "nameCH" : "",
            "code" : "MLB"
        },
        {
            "codeUIC" : 116244,
            "codeCH" : 0,
            "nameUIC" : "Mortcerf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116269,
            "codeCH" : 0,
            "nameUIC" : "Guérard-La Celle-sur-Morin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116277,
            "codeCH" : 0,
            "nameUIC" : "Faremoutiers-Pommeuse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116285,
            "codeCH" : 0,
            "nameUIC" : "Mouroux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "AG",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Aiguille Côté Chelles",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "AI",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Aiguille Voie 2R/Voie 2Bis",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "BV",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "DP",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Dépôt",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "EF",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "ITE EDF",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "FV",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Faisceau V",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "F1",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Faisceau Impair",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "F2",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Faisceau Pair",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "P4",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Poste 4",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : "SI",
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Carré 129",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : 17,
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Carré Violet 17",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : 21,
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Aiguille Km 21,0",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116293,
            "codeCH" : 40,
            "nameUIC" : "Vaires-Torcy",
            "nameCH" : "Carré Violet 40",
            "code" : "VTT"
        },
        {
            "codeUIC" : 116301,
            "codeCH" : "TI",
            "nameUIC" : "Coulommiers",
            "nameCH" : "Tiroir",
            "code" : "CO"
        },
        {
            "codeUIC" : 116301,
            "codeCH" : 0,
            "nameUIC" : "Coulommiers",
            "nameCH" : "",
            "code" : "CO"
        },
        {
            "codeUIC" : 116319,
            "codeCH" : "T3",
            "nameUIC" : "Lagny-Thorigny",
            "nameCH" : "Tiroir 3",
            "code" : ""
        },
        {
            "codeUIC" : 116319,
            "codeCH" : 0,
            "nameUIC" : "Lagny-Thorigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116327,
            "codeCH" : "BV",
            "nameUIC" : "Esbly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 116327,
            "codeCH" : 0,
            "nameUIC" : "Esbly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116343,
            "codeCH" : "BV",
            "nameUIC" : "Chailly-Boissy-Le Châtel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 116368,
            "codeCH" : 0,
            "nameUIC" : "St-Siméon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116384,
            "codeCH" : 0,
            "nameUIC" : "Jouy-sur-Morin-Le Marais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116392,
            "codeCH" : 0,
            "nameUIC" : "La Ferté-Gaucher",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116400,
            "codeCH" : "BV",
            "nameUIC" : "Montry-Condé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 116483,
            "codeCH" : 0,
            "nameUIC" : "Esternay",
            "nameCH" : "",
            "code" : "EST"
        },
        {
            "codeUIC" : 116491,
            "codeCH" : "BF",
            "nameUIC" : "Trilport",
            "nameCH" : "Bifurcation de Germigny",
            "code" : "TLP"
        },
        {
            "codeUIC" : 116491,
            "codeCH" : "BV",
            "nameUIC" : "Trilport",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TLP"
        },
        {
            "codeUIC" : 116491,
            "codeCH" : "EP",
            "nameUIC" : "Trilport",
            "nameCH" : "ITE CCI de Meaux",
            "code" : "TLP"
        },
        {
            "codeUIC" : 116491,
            "codeCH" : "EV",
            "nameUIC" : "Trilport",
            "nameCH" : "Aiguille Évitement de Germigny",
            "code" : "TLP"
        },
        {
            "codeUIC" : 116509,
            "codeCH" : "BV",
            "nameUIC" : "Changis-St-Jean",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CJN"
        },
        {
            "codeUIC" : 116509,
            "codeCH" : "EP",
            "nameUIC" : "Changis-St-Jean",
            "nameCH" : "ITE Appel",
            "code" : "CJN"
        },
        {
            "codeUIC" : 116517,
            "codeCH" : "A1",
            "nameUIC" : "La Ferté-sous-Jouarre",
            "nameCH" : "Aiguille Km 63,6 - Évitement",
            "code" : "LFJ"
        },
        {
            "codeUIC" : 116517,
            "codeCH" : "A2",
            "nameUIC" : "La Ferté-sous-Jouarre",
            "nameCH" : "Aiguille Km 64,6 - Évitement",
            "code" : "LFJ"
        },
        {
            "codeUIC" : 116517,
            "codeCH" : "BV",
            "nameUIC" : "La Ferté-sous-Jouarre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LFJ"
        },
        {
            "codeUIC" : 116517,
            "codeCH" : "EP",
            "nameUIC" : "La Ferté-sous-Jouarre",
            "nameCH" : "ITE CA La-Brie",
            "code" : "LFJ"
        },
        {
            "codeUIC" : 116541,
            "codeCH" : "BV",
            "nameUIC" : "Sézanne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SZ"
        },
        {
            "codeUIC" : 116541,
            "codeCH" : "EP",
            "nameUIC" : "Sézanne",
            "nameCH" : "ITE Johnson",
            "code" : "SZ"
        },
        {
            "codeUIC" : 116541,
            "codeCH" : "PN",
            "nameUIC" : "Sézanne",
            "nameCH" : "PN 119",
            "code" : "SZ"
        },
        {
            "codeUIC" : 116558,
            "codeCH" : 0,
            "nameUIC" : "Nanteuil-Saâcy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116566,
            "codeCH" : "A1",
            "nameUIC" : "Nogent-l'Artaud-Charly",
            "nameCH" : "Aiguille Km 82,3 - Évitement",
            "code" : "NAA"
        },
        {
            "codeUIC" : 116566,
            "codeCH" : "A2",
            "nameUIC" : "Nogent-l'Artaud-Charly",
            "nameCH" : "Aiguille Km 83,6 - Évitement",
            "code" : "NAA"
        },
        {
            "codeUIC" : 116566,
            "codeCH" : "BV",
            "nameUIC" : "Nogent-l'Artaud-Charly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NAA"
        },
        {
            "codeUIC" : 116574,
            "codeCH" : 0,
            "nameUIC" : "Chézy-sur-Marne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116582,
            "codeCH" : "BV",
            "nameUIC" : "Château-Thierry",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CTH"
        },
        {
            "codeUIC" : 116582,
            "codeCH" : "EM",
            "nameUIC" : "Château-Thierry",
            "nameCH" : "ITE Melitta",
            "code" : "CTH"
        },
        {
            "codeUIC" : 116590,
            "codeCH" : "BV",
            "nameUIC" : "Mézy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MZY"
        },
        {
            "codeUIC" : 116590,
            "codeCH" : "EP",
            "nameUIC" : "Mézy",
            "nameCH" : "ITE Société Luchaire S A",
            "code" : "MZY"
        },
        {
            "codeUIC" : 116590,
            "codeCH" : "PU",
            "nameUIC" : "Mézy",
            "nameCH" : "Poste U",
            "code" : "MZY"
        },
        {
            "codeUIC" : 116590,
            "codeCH" : 0,
            "nameUIC" : "Mézy",
            "nameCH" : "",
            "code" : "MZY"
        },
        {
            "codeUIC" : 116616,
            "codeCH" : "BV",
            "nameUIC" : "Isles-Armentières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ISA"
        },
        {
            "codeUIC" : 116616,
            "codeCH" : "EP",
            "nameUIC" : "Isles-Armentières",
            "nameCH" : "ITE Capoulade",
            "code" : "ISA"
        },
        {
            "codeUIC" : 116632,
            "codeCH" : "BO",
            "nameUIC" : "Lizy-sur-Ourcq",
            "nameCH" : "Base Ocquerre",
            "code" : "LZO"
        },
        {
            "codeUIC" : 116632,
            "codeCH" : "BV",
            "nameUIC" : "Lizy-sur-Ourcq",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LZO"
        },
        {
            "codeUIC" : 116632,
            "codeCH" : "C1",
            "nameUIC" : "Lizy-sur-Ourcq",
            "nameCH" : "Carré 11",
            "code" : "LZO"
        },
        {
            "codeUIC" : 116632,
            "codeCH" : "RL",
            "nameUIC" : "Lizy-sur-Ourcq",
            "nameCH" : "Raccordement Côté Lizy",
            "code" : "LZO"
        },
        {
            "codeUIC" : 116632,
            "codeCH" : 0,
            "nameUIC" : "Lizy-sur-Ourcq",
            "nameCH" : "",
            "code" : "LZO"
        },
        {
            "codeUIC" : 116640,
            "codeCH" : 0,
            "nameUIC" : "Crouy-sur-Ourcq",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116665,
            "codeCH" : 0,
            "nameUIC" : "Mareuil-sur-Ourcq",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116673,
            "codeCH" : "S6",
            "nameUIC" : "La Ferté-Milon",
            "nameCH" : "Sémaphore 60",
            "code" : ""
        },
        {
            "codeUIC" : 116673,
            "codeCH" : 0,
            "nameUIC" : "La Ferté-Milon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116681,
            "codeCH" : "BV",
            "nameUIC" : "Neuilly-St-Front",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NSF"
        },
        {
            "codeUIC" : 116699,
            "codeCH" : "BV",
            "nameUIC" : "Oulchy-Breny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "OCH"
        },
        {
            "codeUIC" : 116699,
            "codeCH" : "EP",
            "nameUIC" : "Oulchy-Breny",
            "nameCH" : "ITE Sifraco",
            "code" : "OCH"
        },
        {
            "codeUIC" : 116723,
            "codeCH" : 0,
            "nameUIC" : "Anglure",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116731,
            "codeCH" : "BV",
            "nameUIC" : "Couilly-St-Germain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 116749,
            "codeCH" : 0,
            "nameUIC" : "Villiers-Montbarbin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116756,
            "codeCH" : 0,
            "nameUIC" : "Les Ormes-sur-Voulzie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116764,
            "codeCH" : 0,
            "nameUIC" : "Vimpelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116772,
            "codeCH" : 0,
            "nameUIC" : "Crécy-en-Brie-La Chapelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 116848,
            "codeCH" : "BV",
            "nameUIC" : "Condé-en-Brie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 116848,
            "codeCH" : "EP",
            "nameUIC" : "Condé-en-Brie",
            "nameCH" : "ITE Etablissements J.Soufflet",
            "code" : ""
        },
        {
            "codeUIC" : 116855,
            "codeCH" : "BV",
            "nameUIC" : "Montmirail",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 116855,
            "codeCH" : "EP",
            "nameUIC" : "Montmirail",
            "nameCH" : "ITE Ville de Montmirail",
            "code" : ""
        },
        {
            "codeUIC" : 116897,
            "codeCH" : 0,
            "nameUIC" : "Les Champs-Forts",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "AE",
            "nameUIC" : "Troyes",
            "nameCH" : "Aiguille Est-Preize",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "AM",
            "nameUIC" : "Troyes",
            "nameCH" : "Aiguille Est-Quai Militaire",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "AO",
            "nameUIC" : "Troyes",
            "nameCH" : "Aiguille Ouest-Preize",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "BB",
            "nameUIC" : "Troyes",
            "nameCH" : "FaIsceau Barberay",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "BF",
            "nameUIC" : "Troyes",
            "nameCH" : "Bifurcation de St-Julien",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "BV",
            "nameUIC" : "Troyes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "EC",
            "nameUIC" : "Troyes",
            "nameCH" : "Aiguille Est-Voie C",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "EF",
            "nameUIC" : "Troyes",
            "nameCH" : "ITE EDF",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "OC",
            "nameUIC" : "Troyes",
            "nameCH" : "Aiguille Ouest-Voie C",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "PL",
            "nameUIC" : "Troyes",
            "nameCH" : "Sémaphore 171,5",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "PU",
            "nameUIC" : "Troyes",
            "nameCH" : "ITE SCAC",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "PW",
            "nameUIC" : "Troyes",
            "nameCH" : "ITE FENWICK",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "QM",
            "nameUIC" : "Troyes",
            "nameCH" : "Quai Militaire de Croncels",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "RA",
            "nameUIC" : "Troyes",
            "nameCH" : "Faisceau Preize",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "RO",
            "nameUIC" : "Troyes",
            "nameCH" : "Faicseau St-Luc",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "S1",
            "nameUIC" : "Troyes",
            "nameCH" : "Sémaphore 91",
            "code" : "TR"
        },
        {
            "codeUIC" : 118000,
            "codeCH" : "VF",
            "nameUIC" : "Troyes",
            "nameCH" : "Bifurcation L4/L26 5",
            "code" : "TR"
        },
        {
            "codeUIC" : 118042,
            "codeCH" : "RA",
            "nameUIC" : "Troyes-Preize",
            "nameCH" : "Faisceau Preize",
            "code" : ""
        },
        {
            "codeUIC" : 118059,
            "codeCH" : "ED",
            "nameUIC" : "Barberey-St-Sulpice",
            "nameCH" : "ITE Siderem",
            "code" : ""
        },
        {
            "codeUIC" : 118059,
            "codeCH" : "EK",
            "nameUIC" : "Barberey-St-Sulpice",
            "nameCH" : "ITE Kléber",
            "code" : ""
        },
        {
            "codeUIC" : 118059,
            "codeCH" : "EL",
            "nameUIC" : "Barberey-St-Sulpice",
            "nameCH" : "ITE Lucart",
            "code" : ""
        },
        {
            "codeUIC" : 118059,
            "codeCH" : "EM",
            "nameUIC" : "Barberey-St-Sulpice",
            "nameCH" : "ITE Michelin RF",
            "code" : ""
        },
        {
            "codeUIC" : 118059,
            "codeCH" : "ES",
            "nameUIC" : "Barberey-St-Sulpice",
            "nameCH" : "ITE SMETA COCELEC",
            "code" : ""
        },
        {
            "codeUIC" : 118059,
            "codeCH" : 0,
            "nameUIC" : "Barberey-St-Sulpice",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118067,
            "codeCH" : 0,
            "nameUIC" : "St-Lyé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118075,
            "codeCH" : 0,
            "nameUIC" : "Payns",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118083,
            "codeCH" : 0,
            "nameUIC" : "Savières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118091,
            "codeCH" : "BV",
            "nameUIC" : "St-Mesmin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SMM"
        },
        {
            "codeUIC" : 118091,
            "codeCH" : "S1",
            "nameUIC" : "St-Mesmin",
            "nameCH" : "Carré 381",
            "code" : "SMM"
        },
        {
            "codeUIC" : 118091,
            "codeCH" : "S2",
            "nameUIC" : "St-Mesmin",
            "nameCH" : "Carré 392",
            "code" : "SMM"
        },
        {
            "codeUIC" : 118117,
            "codeCH" : 0,
            "nameUIC" : "Vallant-St-Georges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118133,
            "codeCH" : "BV",
            "nameUIC" : "Châtres (Aube)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CTR"
        },
        {
            "codeUIC" : 118133,
            "codeCH" : "FM",
            "nameUIC" : "Châtres (Aube)",
            "nameCH" : "Faisceau",
            "code" : "CTR"
        },
        {
            "codeUIC" : 118133,
            "codeCH" : "PL",
            "nameUIC" : "Châtres (Aube)",
            "nameCH" : "Carré 362",
            "code" : "CTR"
        },
        {
            "codeUIC" : 118133,
            "codeCH" : "S1",
            "nameUIC" : "Châtres (Aube)",
            "nameCH" : "Sémaphore 361",
            "code" : "CTR"
        },
        {
            "codeUIC" : 118141,
            "codeCH" : 0,
            "nameUIC" : "Maizières-la-Grande-Paroisse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118158,
            "codeCH" : "BV",
            "nameUIC" : "Romilly-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RMY"
        },
        {
            "codeUIC" : 118158,
            "codeCH" : "E2",
            "nameUIC" : "Romilly-sur-Seine",
            "nameCH" : "ITE FMC",
            "code" : "RMY"
        },
        {
            "codeUIC" : 118158,
            "codeCH" : "PL",
            "nameUIC" : "Romilly-sur-Seine",
            "nameCH" : "Carré 1",
            "code" : "RMY"
        },
        {
            "codeUIC" : 118158,
            "codeCH" : "S2",
            "nameUIC" : "Romilly-sur-Seine",
            "nameCH" : "Sémaphore S26",
            "code" : "RMY"
        },
        {
            "codeUIC" : 118158,
            "codeCH" : "ZI",
            "nameUIC" : "Romilly-sur-Seine",
            "nameCH" : "ITE SCARM",
            "code" : "RMY"
        },
        {
            "codeUIC" : 118166,
            "codeCH" : 0,
            "nameUIC" : "Crancey",
            "nameCH" : "",
            "code" : "CRC"
        },
        {
            "codeUIC" : 118174,
            "codeCH" : 0,
            "nameUIC" : "Pont-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118182,
            "codeCH" : 0,
            "nameUIC" : "Marnay-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118190,
            "codeCH" : "BV",
            "nameUIC" : "Nogent-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NOG"
        },
        {
            "codeUIC" : 118190,
            "codeCH" : "EF",
            "nameUIC" : "Nogent-sur-Seine",
            "nameCH" : "ITE EDF",
            "code" : "NOG"
        },
        {
            "codeUIC" : 118190,
            "codeCH" : "EP",
            "nameUIC" : "Nogent-sur-Seine",
            "nameCH" : "Embranchements",
            "code" : "NOG"
        },
        {
            "codeUIC" : 118190,
            "codeCH" : "S1",
            "nameUIC" : "Nogent-sur-Seine",
            "nameCH" : "Branchement 201",
            "code" : "NOG"
        },
        {
            "codeUIC" : 118190,
            "codeCH" : "S2",
            "nameUIC" : "Nogent-sur-Seine",
            "nameCH" : "Carré 212",
            "code" : "NOG"
        },
        {
            "codeUIC" : 118190,
            "codeCH" : "ZP",
            "nameUIC" : "Nogent-sur-Seine",
            "nameCH" : "ITE Zone Portuaire",
            "code" : "NOG"
        },
        {
            "codeUIC" : 118208,
            "codeCH" : 0,
            "nameUIC" : "Rouilly-St-Loup",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118216,
            "codeCH" : "BV",
            "nameUIC" : "Montaulin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 118216,
            "codeCH" : "S1",
            "nameUIC" : "Montaulin",
            "nameCH" : "Sémaphore 141",
            "code" : ""
        },
        {
            "codeUIC" : 118224,
            "codeCH" : "BV",
            "nameUIC" : "Lusigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 118224,
            "codeCH" : "S2",
            "nameUIC" : "Lusigny",
            "nameCH" : "Sémaphore 142",
            "code" : ""
        },
        {
            "codeUIC" : 118232,
            "codeCH" : 0,
            "nameUIC" : "Montiéramey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118240,
            "codeCH" : 0,
            "nameUIC" : "La Villeneuve-au-Chène",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118257,
            "codeCH" : "BV",
            "nameUIC" : "Vendeuvre (Aube)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 118257,
            "codeCH" : "EP",
            "nameUIC" : "Vendeuvre (Aube)",
            "nameCH" : "ITE Allia-Doult",
            "code" : ""
        },
        {
            "codeUIC" : 118257,
            "codeCH" : "PL",
            "nameUIC" : "Vendeuvre (Aube)",
            "nameCH" : "Carré 182",
            "code" : ""
        },
        {
            "codeUIC" : 118257,
            "codeCH" : "S1",
            "nameUIC" : "Vendeuvre (Aube)",
            "nameCH" : "Carré 171",
            "code" : ""
        },
        {
            "codeUIC" : 118265,
            "codeCH" : 0,
            "nameUIC" : "Vauchonvilliers-Maisons-des-Ch",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118273,
            "codeCH" : 0,
            "nameUIC" : "Jessains",
            "nameCH" : "",
            "code" : "JAI"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "AE",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "Aiguille Est-Évitement",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "AO",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "Aiguille Ouest-Évitement",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "BV",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "C2",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "Carré 204",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "EP",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "ITE Finition du siège",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "PL",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "Carré 201",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "S1",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "Sémaphore 215",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118299,
            "codeCH" : "S2",
            "nameUIC" : "Bar-sur-Aube",
            "nameCH" : "Carré 212",
            "code" : "BRB"
        },
        {
            "codeUIC" : 118307,
            "codeCH" : "BV",
            "nameUIC" : "Bayel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 118307,
            "codeCH" : "EP",
            "nameUIC" : "Bayel",
            "nameCH" : "ITE Cristallerie",
            "code" : ""
        },
        {
            "codeUIC" : 118315,
            "codeCH" : "BV",
            "nameUIC" : "Clairvaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CVX"
        },
        {
            "codeUIC" : 118315,
            "codeCH" : "IT",
            "nameUIC" : "Clairvaux",
            "nameCH" : "ITE HOLCIM",
            "code" : "CVX"
        },
        {
            "codeUIC" : 118315,
            "codeCH" : "S1",
            "nameUIC" : "Clairvaux",
            "nameCH" : "Carré 221",
            "code" : "CVX"
        },
        {
            "codeUIC" : 118315,
            "codeCH" : "S2",
            "nameUIC" : "Clairvaux",
            "nameCH" : "Sémaphore 235,4",
            "code" : "CVX"
        },
        {
            "codeUIC" : 118323,
            "codeCH" : "BV",
            "nameUIC" : "St-Julien-les-Villas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 118349,
            "codeCH" : 0,
            "nameUIC" : "St-Léger-Moussey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118356,
            "codeCH" : 0,
            "nameUIC" : "Bouilly-Roncenay",
            "nameCH" : "",
            "code" : "BIY"
        },
        {
            "codeUIC" : 118364,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-de-Bonneval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118380,
            "codeCH" : 0,
            "nameUIC" : "Chamoy-St-Phal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118398,
            "codeCH" : 0,
            "nameUIC" : "Montigny-les-Monts",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118430,
            "codeCH" : 0,
            "nameUIC" : "Buchères-Verrières",
            "nameCH" : "",
            "code" : "BHV"
        },
        {
            "codeUIC" : 118455,
            "codeCH" : 0,
            "nameUIC" : "Clérey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118497,
            "codeCH" : "BV",
            "nameUIC" : "Bar-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BUI"
        },
        {
            "codeUIC" : 118497,
            "codeCH" : "EP",
            "nameUIC" : "Bar-sur-Seine",
            "nameCH" : "ITE ABM",
            "code" : "BUI"
        },
        {
            "codeUIC" : 118497,
            "codeCH" : 0,
            "nameUIC" : "Bar-sur-Seine",
            "nameCH" : "",
            "code" : "BUI"
        },
        {
            "codeUIC" : 118505,
            "codeCH" : 0,
            "nameUIC" : "Polisot",
            "nameCH" : "",
            "code" : "POL"
        },
        {
            "codeUIC" : 118521,
            "codeCH" : 0,
            "nameUIC" : "Plaines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118539,
            "codeCH" : 0,
            "nameUIC" : "Mussy-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118547,
            "codeCH" : 0,
            "nameUIC" : "Pothières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118554,
            "codeCH" : "BV",
            "nameUIC" : "Charmont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CMO"
        },
        {
            "codeUIC" : 118554,
            "codeCH" : "EA",
            "nameUIC" : "Charmont",
            "nameCH" : "ITE Coopérative d'Arcis",
            "code" : "CMO"
        },
        {
            "codeUIC" : 118554,
            "codeCH" : "ES",
            "nameUIC" : "Charmont",
            "nameCH" : "ITE Soufflet Agriculture",
            "code" : "CMO"
        },
        {
            "codeUIC" : 118588,
            "codeCH" : "BV",
            "nameUIC" : "Arcis-sur-Aube",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ASA"
        },
        {
            "codeUIC" : 118588,
            "codeCH" : "PN",
            "nameUIC" : "Arcis-sur-Aube",
            "nameCH" : "PN 58",
            "code" : "ASA"
        },
        {
            "codeUIC" : 118646,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 526,5",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118653,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 525,2",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118703,
            "codeCH" : 0,
            "nameUIC" : "Thennelières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118737,
            "codeCH" : 0,
            "nameUIC" : "Brevonnes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118752,
            "codeCH" : "PK",
            "nameUIC" : "Brienne-le-Château",
            "nameCH" : "Changement PK",
            "code" : "BRC"
        },
        {
            "codeUIC" : 118752,
            "codeCH" : 0,
            "nameUIC" : "Brienne-le-Château",
            "nameCH" : "",
            "code" : "BRC"
        },
        {
            "codeUIC" : 118778,
            "codeCH" : 0,
            "nameUIC" : "Vallentigny-Maizières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118810,
            "codeCH" : 0,
            "nameUIC" : "Estissac",
            "nameCH" : "",
            "code" : "ESI"
        },
        {
            "codeUIC" : 118828,
            "codeCH" : 0,
            "nameUIC" : "Aix-en-Othe-Villemaur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118844,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-l'Archevêque",
            "nameCH" : "",
            "code" : "VIL"
        },
        {
            "codeUIC" : 118869,
            "codeCH" : 0,
            "nameUIC" : "Theil-Cerisiers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 118992,
            "codeCH" : 0,
            "nameUIC" : "ITE Moreau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 119008,
            "codeCH" : 0,
            "nameUIC" : "ITE Froger",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 124321,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 14,0",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 124347,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 463,2",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 131300,
            "codeCH" : 0,
            "nameUIC" : "ITE SMDA",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 131961,
            "codeCH" : "BF",
            "nameUIC" : "Brion-Montréal-la-Cluse",
            "nameCH" : "Bifurcation Km 116,7/35,3",
            "code" : ""
        },
        {
            "codeUIC" : 131961,
            "codeCH" : "BV",
            "nameUIC" : "Brion-Montréal-la-Cluse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 140210,
            "codeCH" : 0,
            "nameUIC" : "ITE EDF Km 558,5",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140228,
            "codeCH" : 0,
            "nameUIC" : "ITE Ciments Français",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140236,
            "codeCH" : 0,
            "nameUIC" : "ITE Ciments Lafarge (Cruas)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140244,
            "codeCH" : 0,
            "nameUIC" : "ITE EDF (Meysse)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140251,
            "codeCH" : 0,
            "nameUIC" : "ITE Postasses et Azote",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140269,
            "codeCH" : 0,
            "nameUIC" : "ITE Air-Liquide",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140277,
            "codeCH" : 0,
            "nameUIC" : "ITE Rhône-Poulenc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140285,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 18,8",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140293,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 17,5",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140301,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 426,7",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140384,
            "codeCH" : 0,
            "nameUIC" : "Signal S301",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140392,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 15,5",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 140459,
            "codeCH" : 0,
            "nameUIC" : "Signal S302",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "AE",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Accès Est",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "BV",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "C5",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Carré Violet 4005",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "DT",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Dépôt",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "EB",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "ITE Brasseries",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "JN",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Jonction Km 350,8",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "KM",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Km 253,2",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "MT",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Montet",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "PL",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Carré 4003",
            "code" : "NY"
        },
        {
            "codeUIC" : 141002,
            "codeCH" : "T1",
            "nameUIC" : "Nancy-Ville",
            "nameCH" : "Tiroir Ouest",
            "code" : "NY"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "AI",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "Aiguille 5435",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "BV",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "EM",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "ITE SCI Mouchoir",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "ES",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "ITE Seita",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "L1",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "Bifurcation L1/L14",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "PA",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "Poste A",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "PE",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "ITE Nancy-Sud",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "PK",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "Point d'arrêt Km 1,6",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "TR",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "Faisceau",
            "code" : "JA"
        },
        {
            "codeUIC" : 141010,
            "codeCH" : "2T",
            "nameUIC" : "Jarville-la-Malgrange",
            "nameCH" : "Voie 2T",
            "code" : "JA"
        },
        {
            "codeUIC" : 141036,
            "codeCH" : "BV",
            "nameUIC" : "Foug",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FUG"
        },
        {
            "codeUIC" : 141036,
            "codeCH" : "EF",
            "nameUIC" : "Foug",
            "nameCH" : "ITE Fonderies Pont-à-Mousson S A",
            "code" : "FUG"
        },
        {
            "codeUIC" : 141044,
            "codeCH" : "BV",
            "nameUIC" : "Toul",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TL"
        },
        {
            "codeUIC" : 141044,
            "codeCH" : "ES",
            "nameUIC" : "Toul",
            "nameCH" : "ITE NHA",
            "code" : "TL"
        },
        {
            "codeUIC" : 141044,
            "codeCH" : "PA",
            "nameUIC" : "Toul",
            "nameCH" : "Poste A Fontenoy-sur-Moselle",
            "code" : "TL"
        },
        {
            "codeUIC" : 141044,
            "codeCH" : "PB",
            "nameUIC" : "Toul",
            "nameCH" : "Poste B Fontenoy-sur-Moselle",
            "code" : "TL"
        },
        {
            "codeUIC" : 141044,
            "codeCH" : "P1",
            "nameUIC" : "Toul",
            "nameCH" : "Poste 1",
            "code" : "TL"
        },
        {
            "codeUIC" : 141044,
            "codeCH" : "RC",
            "nameUIC" : "Toul",
            "nameCH" : "Bifurcation L1/L15",
            "code" : "TL"
        },
        {
            "codeUIC" : 141044,
            "codeCH" : "RS",
            "nameUIC" : "Toul",
            "nameCH" : "Raccordement Sud",
            "code" : "TL"
        },
        {
            "codeUIC" : 141051,
            "codeCH" : 0,
            "nameUIC" : "Fontenoy-sur-Moselle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141069,
            "codeCH" : "BV",
            "nameUIC" : "Liverdun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 141077,
            "codeCH" : "AN",
            "nameUIC" : "Frouard",
            "nameCH" : "Accès Nord",
            "code" : "FR"
        },
        {
            "codeUIC" : 141077,
            "codeCH" : "BF",
            "nameUIC" : "Frouard",
            "nameCH" : "Bifurcation",
            "code" : "FR"
        },
        {
            "codeUIC" : 141077,
            "codeCH" : "BV",
            "nameUIC" : "Frouard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FR"
        },
        {
            "codeUIC" : 141077,
            "codeCH" : "RM",
            "nameUIC" : "Frouard",
            "nameCH" : "Raccordement côté Metz",
            "code" : "FR"
        },
        {
            "codeUIC" : 141077,
            "codeCH" : "RT",
            "nameUIC" : "Frouard",
            "nameCH" : "Raccordement côté Toul",
            "code" : "FR"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "AE",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Accès Est",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "AG",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Aiguille Km 345,7",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "BV",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "EC",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Côté Champigneulles",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "EN",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Côté Nancy",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "JN",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Jonction Km 345,9",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "PC",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Poste C",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "P1",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Poste 1",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141085,
            "codeCH" : "TR",
            "nameUIC" : "Champigneulles",
            "nameCH" : "Faisceau",
            "code" : "CHG"
        },
        {
            "codeUIC" : 141093,
            "codeCH" : "BV",
            "nameUIC" : "Laneuveville-devant-Nancy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 141101,
            "codeCH" : "BV",
            "nameUIC" : "Varangéville-St-Nicolas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VRA"
        },
        {
            "codeUIC" : 141101,
            "codeCH" : "E7",
            "nameUIC" : "Varangéville-St-Nicolas",
            "nameCH" : "ITE N°2 Rhône-Poulenc",
            "code" : "VRA"
        },
        {
            "codeUIC" : 141101,
            "codeCH" : "FI",
            "nameUIC" : "Varangéville-St-Nicolas",
            "nameCH" : "Faisceau Impair",
            "code" : "VRA"
        },
        {
            "codeUIC" : 141101,
            "codeCH" : "FP",
            "nameUIC" : "Varangéville-St-Nicolas",
            "nameCH" : "Faisceau Pair",
            "code" : "VRA"
        },
        {
            "codeUIC" : 141101,
            "codeCH" : "SD",
            "nameUIC" : "Varangéville-St-Nicolas",
            "nameCH" : "ITE N°1 Rhône-Poulenc",
            "code" : "VRA"
        },
        {
            "codeUIC" : 141119,
            "codeCH" : "BV",
            "nameUIC" : "Dombasle-sur-Meurthe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 141127,
            "codeCH" : "AG",
            "nameUIC" : "Rosières-aux-Salines",
            "nameCH" : "Aiguille 6001",
            "code" : ""
        },
        {
            "codeUIC" : 141127,
            "codeCH" : "BV",
            "nameUIC" : "Rosières-aux-Salines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 141127,
            "codeCH" : "EP",
            "nameUIC" : "Rosières-aux-Salines",
            "nameCH" : "ITE Entreprise Pétrolière",
            "code" : ""
        },
        {
            "codeUIC" : 141127,
            "codeCH" : "SI",
            "nameUIC" : "Rosières-aux-Salines",
            "nameCH" : "Sémaphore S32,0I",
            "code" : ""
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "AB",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Aiguille des Rosières",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "AM",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Aiguille Voies Bis/M",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "AS",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Aiguille Voies Bis/Voies L1",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "BV",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "C8",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Carré 80",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "C9",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Carré 91",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "PF",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Poste F",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "TR",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Triage - Relais",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "1B",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Aiguille V1V2 FSC ar",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : "1T",
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Aiguille V1/V1T",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : 16,
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Bifurcation L1/L16",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141135,
            "codeCH" : 61,
            "nameUIC" : "Blainville-Damelevières",
            "nameCH" : "Carré 61",
            "code" : "BLV"
        },
        {
            "codeUIC" : 141143,
            "codeCH" : 0,
            "nameUIC" : "Mont-sur-Meurthe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141150,
            "codeCH" : "BV",
            "nameUIC" : "Lunéville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LNE"
        },
        {
            "codeUIC" : 141168,
            "codeCH" : 0,
            "nameUIC" : "Marainviller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141184,
            "codeCH" : 0,
            "nameUIC" : "Emberménil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141200,
            "codeCH" : 0,
            "nameUIC" : "Einvaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141218,
            "codeCH" : 0,
            "nameUIC" : "Bayon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141218,
            "codeCH" : 21,
            "nameUIC" : "Bayon",
            "nameCH" : "Sémaphore BAPR1",
            "code" : ""
        },
        {
            "codeUIC" : 141218,
            "codeCH" : 22,
            "nameUIC" : "Bayon",
            "nameCH" : "Sémaphore BAPR2",
            "code" : ""
        },
        {
            "codeUIC" : 141259,
            "codeCH" : 0,
            "nameUIC" : "Soulosse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141283,
            "codeCH" : 0,
            "nameUIC" : "Girauvoisin-St-Julien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141291,
            "codeCH" : "BV",
            "nameUIC" : "Neufchâteau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NCH"
        },
        {
            "codeUIC" : 141291,
            "codeCH" : "PA",
            "nameUIC" : "Neufchâteau",
            "nameCH" : "Pancarte Arrêt",
            "code" : "NCH"
        },
        {
            "codeUIC" : 141291,
            "codeCH" : "P1",
            "nameUIC" : "Neufchâteau",
            "nameCH" : "Poste 1",
            "code" : "NCH"
        },
        {
            "codeUIC" : 141291,
            "codeCH" : "TR",
            "nameUIC" : "Neufchâteau",
            "nameCH" : "Tiroir 24",
            "code" : "NCH"
        },
        {
            "codeUIC" : 141291,
            "codeCH" : 17,
            "nameUIC" : "Neufchâteau",
            "nameCH" : "Carré Violet 217",
            "code" : "NCH"
        },
        {
            "codeUIC" : 141291,
            "codeCH" : 54,
            "nameUIC" : "Neufchâteau",
            "nameCH" : "Carré Violet 254",
            "code" : "NCH"
        },
        {
            "codeUIC" : 141341,
            "codeCH" : "BV",
            "nameUIC" : "Coussey",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COY"
        },
        {
            "codeUIC" : 141341,
            "codeCH" : "EP",
            "nameUIC" : "Coussey",
            "nameCH" : "ITE",
            "code" : "COY"
        },
        {
            "codeUIC" : 141341,
            "codeCH" : 41,
            "nameUIC" : "Coussey",
            "nameCH" : "PN 41",
            "code" : "COY"
        },
        {
            "codeUIC" : 141374,
            "codeCH" : 0,
            "nameUIC" : "St-Clément-Laronxe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141382,
            "codeCH" : 0,
            "nameUIC" : "Chenevières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141390,
            "codeCH" : 0,
            "nameUIC" : "Ménil-Flin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141408,
            "codeCH" : 0,
            "nameUIC" : "Azerailles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141416,
            "codeCH" : 0,
            "nameUIC" : "Baccarat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141424,
            "codeCH" : 0,
            "nameUIC" : "Bertrichamps",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141432,
            "codeCH" : 0,
            "nameUIC" : "Thiaville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141473,
            "codeCH" : "BV",
            "nameUIC" : "Houdemont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HOU"
        },
        {
            "codeUIC" : 141473,
            "codeCH" : "ER",
            "nameUIC" : "Houdemont",
            "nameCH" : "ITE Est-Républicain",
            "code" : "HOU"
        },
        {
            "codeUIC" : 141481,
            "codeCH" : 0,
            "nameUIC" : "Ludres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141499,
            "codeCH" : 0,
            "nameUIC" : "Messein",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141507,
            "codeCH" : 0,
            "nameUIC" : "Neuves-Maisons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141523,
            "codeCH" : "BF",
            "nameUIC" : "Pont-St-Vincent",
            "nameCH" : "Bifurcation L14/Raccordement N1",
            "code" : "PSV"
        },
        {
            "codeUIC" : 141523,
            "codeCH" : "BV",
            "nameUIC" : "Pont-St-Vincent",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PSV"
        },
        {
            "codeUIC" : 141523,
            "codeCH" : "RC",
            "nameUIC" : "Pont-St-Vincent",
            "nameCH" : "Bifurcation Raccordement N2/L27",
            "code" : "PSV"
        },
        {
            "codeUIC" : 141523,
            "codeCH" : "R2",
            "nameUIC" : "Pont-St-Vincent",
            "nameCH" : "Bifurcation L14/Raccordement N2",
            "code" : "PSV"
        },
        {
            "codeUIC" : 141523,
            "codeCH" : "ZI",
            "nameUIC" : "Pont-St-Vincent",
            "nameCH" : "ITE ZI Ludres",
            "code" : "PSV"
        },
        {
            "codeUIC" : 141531,
            "codeCH" : 0,
            "nameUIC" : "Bainville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141549,
            "codeCH" : "BV",
            "nameUIC" : "Xeuilley",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "XEU"
        },
        {
            "codeUIC" : 141549,
            "codeCH" : "EP",
            "nameUIC" : "Xeuilley",
            "nameCH" : "ITE Ciments Vicat",
            "code" : "XEU"
        },
        {
            "codeUIC" : 141556,
            "codeCH" : 0,
            "nameUIC" : "Pierreville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141564,
            "codeCH" : 0,
            "nameUIC" : "Pulligny-Autrey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141572,
            "codeCH" : "BV",
            "nameUIC" : "Ceintrey",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 141572,
            "codeCH" : "EP",
            "nameUIC" : "Ceintrey",
            "nameCH" : "ITE Coopérative",
            "code" : ""
        },
        {
            "codeUIC" : 141598,
            "codeCH" : 0,
            "nameUIC" : "Tantonville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141606,
            "codeCH" : 0,
            "nameUIC" : "Vézelise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141622,
            "codeCH" : 0,
            "nameUIC" : "Praye-sous-Vaudémont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141648,
            "codeCH" : 0,
            "nameUIC" : "Diarville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141655,
            "codeCH" : 0,
            "nameUIC" : "Bouzanville-Boulaincourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141705,
            "codeCH" : 0,
            "nameUIC" : "Domgermain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141754,
            "codeCH" : 0,
            "nameUIC" : "Barisey-la-Côte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141762,
            "codeCH" : "EP",
            "nameUIC" : "Colombey-les-Belles",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 141788,
            "codeCH" : 0,
            "nameUIC" : "Pompey",
            "nameCH" : "",
            "code" : "PPE"
        },
        {
            "codeUIC" : 141796,
            "codeCH" : 0,
            "nameUIC" : "Marbache",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 141804,
            "codeCH" : 0,
            "nameUIC" : "Belleville",
            "nameCH" : "",
            "code" : "BEE"
        },
        {
            "codeUIC" : 141812,
            "codeCH" : "BV",
            "nameUIC" : "Dieulouard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DID"
        },
        {
            "codeUIC" : 141812,
            "codeCH" : "CS",
            "nameUIC" : "Dieulouard",
            "nameCH" : "Aiguille IPCS",
            "code" : "DID"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "AF",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "ITE EDF",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "BV",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "EF",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "ITE EDF (EP)",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "EP",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "Montrichard",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "ES",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "ITE LAMBERT",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "E1",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "Poste E1",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "FO",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "ITE Pamsa",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "GZ",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "ITE Sigalnor",
            "code" : "PTM"
        },
        {
            "codeUIC" : 141820,
            "codeCH" : "PJ",
            "nameUIC" : "Pont-à-Mousson",
            "nameCH" : "Point J",
            "code" : "PTM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "AE",
            "nameUIC" : "Chaumont",
            "nameCH" : "Aiguille Est",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "AO",
            "nameUIC" : "Chaumont",
            "nameCH" : "Aiguille Ouest",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "BA",
            "nameUIC" : "Chaumont",
            "nameCH" : "ITE EMC2",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "BF",
            "nameUIC" : "Chaumont",
            "nameCH" : "Bifurcation L4/L10",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "BV",
            "nameUIC" : "Chaumont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "E2",
            "nameUIC" : "Chaumont",
            "nameCH" : "ITE Cadac",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "GA",
            "nameUIC" : "Chaumont",
            "nameCH" : "Garage",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "MI",
            "nameUIC" : "Chaumont",
            "nameCH" : "ITE Aérodrome de Semoutiers",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "PG",
            "nameUIC" : "Chaumont",
            "nameCH" : "Point G",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "PL",
            "nameUIC" : "Chaumont",
            "nameCH" : "Sémaphore 302,2",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142000,
            "codeCH" : "S6",
            "nameUIC" : "Chaumont",
            "nameCH" : "Sémaphore 6",
            "code" : "CHM"
        },
        {
            "codeUIC" : 142018,
            "codeCH" : 0,
            "nameUIC" : "Maranville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142026,
            "codeCH" : "BV",
            "nameUIC" : "Bricon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BCN"
        },
        {
            "codeUIC" : 142026,
            "codeCH" : "EP",
            "nameUIC" : "Bricon",
            "nameCH" : "ITE Essences des Armées",
            "code" : "BCN"
        },
        {
            "codeUIC" : 142026,
            "codeCH" : "PL",
            "nameUIC" : "Bricon",
            "nameCH" : "Carré 251",
            "code" : "BCN"
        },
        {
            "codeUIC" : 142026,
            "codeCH" : "S2",
            "nameUIC" : "Bricon",
            "nameCH" : "Sémaphore 252",
            "code" : "BCN"
        },
        {
            "codeUIC" : 142042,
            "codeCH" : 0,
            "nameUIC" : "Luzy-sur-Marne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142059,
            "codeCH" : 0,
            "nameUIC" : "Foulain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142067,
            "codeCH" : 0,
            "nameUIC" : "Vesaignes-sur-Marne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142075,
            "codeCH" : 0,
            "nameUIC" : "Rolampont",
            "nameCH" : "",
            "code" : "RAT"
        },
        {
            "codeUIC" : 142091,
            "codeCH" : "AE",
            "nameUIC" : "Langres",
            "nameCH" : "Aiguille Est",
            "code" : "LAG"
        },
        {
            "codeUIC" : 142091,
            "codeCH" : "AO",
            "nameUIC" : "Langres",
            "nameCH" : "Aiguille Ouest",
            "code" : "LAG"
        },
        {
            "codeUIC" : 142091,
            "codeCH" : "BV",
            "nameUIC" : "Langres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LAG"
        },
        {
            "codeUIC" : 142091,
            "codeCH" : "GG",
            "nameUIC" : "Langres",
            "nameCH" : "Faisceau Jorquenay",
            "code" : "LAG"
        },
        {
            "codeUIC" : 142091,
            "codeCH" : "P1",
            "nameUIC" : "Langres",
            "nameCH" : "Accès Ouest Faisceau",
            "code" : "LAG"
        },
        {
            "codeUIC" : 142091,
            "codeCH" : "P2",
            "nameUIC" : "Langres",
            "nameCH" : "Accès Est Faisceau",
            "code" : "LAG"
        },
        {
            "codeUIC" : 142109,
            "codeCH" : "BV",
            "nameUIC" : "Lorraine-TGV",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "BF",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Bifurcation Raccordement Est",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "BG",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Bifurcation L15/L15-8",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "BV",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "EZ",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "ITE Les Moulières",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "IS",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Carré 88",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "PM",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Carré 77",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "P2",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Poste 2",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : "RL",
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Faisceau Relais",
            "code" : "CY"
        },
        {
            "codeUIC" : 142125,
            "codeCH" : 15,
            "nameUIC" : "Culmont-Chalindrey",
            "nameCH" : "Bifurcation Chaudenay",
            "code" : "CY"
        },
        {
            "codeUIC" : 142166,
            "codeCH" : 0,
            "nameUIC" : "Laferté-sur-Amance",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142174,
            "codeCH" : "BV",
            "nameUIC" : "Vitrey-Vernois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142224,
            "codeCH" : "BV",
            "nameUIC" : "Bologne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BOG"
        },
        {
            "codeUIC" : 142224,
            "codeCH" : "C0",
            "nameUIC" : "Bologne",
            "nameCH" : "Carré 260",
            "code" : "BOG"
        },
        {
            "codeUIC" : 142224,
            "codeCH" : "C2",
            "nameUIC" : "Bologne",
            "nameCH" : "Carré 22",
            "code" : "BOG"
        },
        {
            "codeUIC" : 142224,
            "codeCH" : "S5",
            "nameUIC" : "Bologne",
            "nameCH" : "Sémaphore 285",
            "code" : "BOG"
        },
        {
            "codeUIC" : 142232,
            "codeCH" : 0,
            "nameUIC" : "Vignory",
            "nameCH" : "",
            "code" : "VNR"
        },
        {
            "codeUIC" : 142240,
            "codeCH" : "BV",
            "nameUIC" : "Froncles-Buxières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142265,
            "codeCH" : "BV",
            "nameUIC" : "Gudmont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GDM"
        },
        {
            "codeUIC" : 142265,
            "codeCH" : "S5",
            "nameUIC" : "Gudmont",
            "nameCH" : "Sémaphore 265",
            "code" : "GDM"
        },
        {
            "codeUIC" : 142265,
            "codeCH" : "S6",
            "nameUIC" : "Gudmont",
            "nameCH" : "Sémaphore 246",
            "code" : "GDM"
        },
        {
            "codeUIC" : 142273,
            "codeCH" : 0,
            "nameUIC" : "Donjeux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142299,
            "codeCH" : 0,
            "nameUIC" : "Rimaucourt",
            "nameCH" : "",
            "code" : "REU"
        },
        {
            "codeUIC" : 142307,
            "codeCH" : "BV",
            "nameUIC" : "Manois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MIH"
        },
        {
            "codeUIC" : 142307,
            "codeCH" : "EP",
            "nameUIC" : "Manois",
            "nameCH" : "ITE TREFILAC",
            "code" : "MIH"
        },
        {
            "codeUIC" : 142307,
            "codeCH" : 17,
            "nameUIC" : "Manois",
            "nameCH" : "PN 17",
            "code" : "MIH"
        },
        {
            "codeUIC" : 142315,
            "codeCH" : "BV",
            "nameUIC" : "St-Blin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142315,
            "codeCH" : 21,
            "nameUIC" : "St-Blin",
            "nameCH" : "PN 21",
            "code" : ""
        },
        {
            "codeUIC" : 142323,
            "codeCH" : 0,
            "nameUIC" : "Prez-sous-Lafauche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142331,
            "codeCH" : 0,
            "nameUIC" : "Liffol-le-Grand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142349,
            "codeCH" : 0,
            "nameUIC" : "Neuilly-l'Évêque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142364,
            "codeCH" : "BV",
            "nameUIC" : "Andilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142380,
            "codeCH" : 0,
            "nameUIC" : "Meuse-Montigny-le-Roi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142406,
            "codeCH" : 0,
            "nameUIC" : "Merrey (Haute-Marne)",
            "nameCH" : "",
            "code" : "MRY"
        },
        {
            "codeUIC" : 142448,
            "codeCH" : 0,
            "nameUIC" : "Bourmont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142497,
            "codeCH" : "EP",
            "nameUIC" : "Damblain",
            "nameCH" : "ITE Conseil Régional des Vosges",
            "code" : ""
        },
        {
            "codeUIC" : 142497,
            "codeCH" : 0,
            "nameUIC" : "Damblain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142505,
            "codeCH" : 0,
            "nameUIC" : "Châteauvillain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142513,
            "codeCH" : 0,
            "nameUIC" : "Latrecey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142521,
            "codeCH" : "BV",
            "nameUIC" : "Veuxhaulles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VXH"
        },
        {
            "codeUIC" : 142521,
            "codeCH" : 23,
            "nameUIC" : "Veuxhaulles",
            "nameCH" : "PN 23",
            "code" : "VXH"
        },
        {
            "codeUIC" : 142547,
            "codeCH" : 0,
            "nameUIC" : "Brion-sur-Ource",
            "nameCH" : "",
            "code" : "BSO"
        },
        {
            "codeUIC" : 142554,
            "codeCH" : 0,
            "nameUIC" : "Châtillon-sur-Seine",
            "nameCH" : "",
            "code" : "CTS"
        },
        {
            "codeUIC" : 142562,
            "codeCH" : 0,
            "nameUIC" : "Ste-Colombe-sur-Seine",
            "nameCH" : "",
            "code" : "SCS"
        },
        {
            "codeUIC" : 142570,
            "codeCH" : 0,
            "nameUIC" : "Poinçon",
            "nameCH" : "",
            "code" : "POO"
        },
        {
            "codeUIC" : 142588,
            "codeCH" : 0,
            "nameUIC" : "Laignes",
            "nameCH" : "",
            "code" : "LIK"
        },
        {
            "codeUIC" : 142620,
            "codeCH" : "BV",
            "nameUIC" : "Vaux-sous-Aubigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VXY"
        },
        {
            "codeUIC" : 142620,
            "codeCH" : "EP",
            "nameUIC" : "Vaux-sous-Aubigny",
            "nameCH" : "ITE Coopérative de la Haute Vingeanne",
            "code" : "VXY"
        },
        {
            "codeUIC" : 142646,
            "codeCH" : 0,
            "nameUIC" : "Selongey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142653,
            "codeCH" : 0,
            "nameUIC" : "Violot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142661,
            "codeCH" : 0,
            "nameUIC" : "Maâtz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142687,
            "codeCH" : 0,
            "nameUIC" : "Champlitte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142703,
            "codeCH" : 0,
            "nameUIC" : "Oyrières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142729,
            "codeCH" : 0,
            "nameUIC" : "Autrey-lès-Gray",
            "nameCH" : "",
            "code" : "ATA"
        },
        {
            "codeUIC" : 142737,
            "codeCH" : 0,
            "nameUIC" : "Champagne-sur-Vingeanne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142745,
            "codeCH" : 0,
            "nameUIC" : "Oisilly-Renève",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142752,
            "codeCH" : 0,
            "nameUIC" : "Mirebeau-sur-Bèze",
            "nameCH" : "",
            "code" : "MBB"
        },
        {
            "codeUIC" : 142760,
            "codeCH" : 0,
            "nameUIC" : "Bèze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142778,
            "codeCH" : 0,
            "nameUIC" : "Lux",
            "nameCH" : "",
            "code" : "LXD"
        },
        {
            "codeUIC" : 142786,
            "codeCH" : 0,
            "nameUIC" : "Tilchâtel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142810,
            "codeCH" : 0,
            "nameUIC" : "Pavillon-lès-Grancey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142828,
            "codeCH" : 0,
            "nameUIC" : "Poinson-Beneuvre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 142836,
            "codeCH" : "BV",
            "nameUIC" : "Villars-Santenoge",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142836,
            "codeCH" : "PA",
            "nameUIC" : "Villars-Santenoge",
            "nameCH" : "PN 114",
            "code" : ""
        },
        {
            "codeUIC" : 142836,
            "codeCH" : "PN",
            "nameUIC" : "Villars-Santenoge",
            "nameCH" : "PN 113",
            "code" : ""
        },
        {
            "codeUIC" : 142844,
            "codeCH" : "BV",
            "nameUIC" : "Recey-sur-Ource",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142844,
            "codeCH" : 3,
            "nameUIC" : "Recey-sur-Ource",
            "nameCH" : "PN 103",
            "code" : ""
        },
        {
            "codeUIC" : 142844,
            "codeCH" : 4,
            "nameUIC" : "Recey-sur-Ource",
            "nameCH" : "PN 104",
            "code" : ""
        },
        {
            "codeUIC" : 142851,
            "codeCH" : "BV",
            "nameUIC" : "Leuglay-Voulaines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142851,
            "codeCH" : "PN",
            "nameUIC" : "Leuglay-Voulaines",
            "nameCH" : "PN 94",
            "code" : ""
        },
        {
            "codeUIC" : 142869,
            "codeCH" : "BV",
            "nameUIC" : "Vanvey-Villiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 142869,
            "codeCH" : "PN",
            "nameUIC" : "Vanvey-Villiers",
            "nameCH" : "PN 86",
            "code" : ""
        },
        {
            "codeUIC" : 143024,
            "codeCH" : 0,
            "nameUIC" : "Vraincourt-Viéville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 143115,
            "codeCH" : 0,
            "nameUIC" : "Bannes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 143123,
            "codeCH" : 0,
            "nameUIC" : "Charmes (Haute-Marne)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 143669,
            "codeCH" : 0,
            "nameUIC" : "ITE Tréfileries de Conflandey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144006,
            "codeCH" : "AC",
            "nameUIC" : "Épinal",
            "nameCH" : "Aiguille Accès Chavelot",
            "code" : "EPI"
        },
        {
            "codeUIC" : 144006,
            "codeCH" : "BF",
            "nameUIC" : "Épinal",
            "nameCH" : "Poste 3",
            "code" : "EPI"
        },
        {
            "codeUIC" : 144006,
            "codeCH" : "BV",
            "nameUIC" : "Épinal",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EPI"
        },
        {
            "codeUIC" : 144006,
            "codeCH" : "GG",
            "nameUIC" : "Épinal",
            "nameCH" : "ITE Zone industrielle Golbey II",
            "code" : "EPI"
        },
        {
            "codeUIC" : 144006,
            "codeCH" : "PG",
            "nameUIC" : "Épinal",
            "nameCH" : "ITE Papèterie de Golbey",
            "code" : "EPI"
        },
        {
            "codeUIC" : 144006,
            "codeCH" : "PL",
            "nameUIC" : "Épinal",
            "nameCH" : "PL 67 Km 56,8",
            "code" : "EPI"
        },
        {
            "codeUIC" : 144006,
            "codeCH" : "SL",
            "nameUIC" : "Épinal",
            "nameCH" : "Bifurcation St-Laurent",
            "code" : "EPI"
        },
        {
            "codeUIC" : 144014,
            "codeCH" : "BV",
            "nameUIC" : "St-Dié",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "STD"
        },
        {
            "codeUIC" : 144014,
            "codeCH" : "EP",
            "nameUIC" : "St-Dié",
            "nameCH" : "ITE Salzemann",
            "code" : "STD"
        },
        {
            "codeUIC" : 144014,
            "codeCH" : "PB",
            "nameUIC" : "St-Dié",
            "nameCH" : "Point B",
            "code" : "STD"
        },
        {
            "codeUIC" : 144030,
            "codeCH" : 0,
            "nameUIC" : "Docelles-Cheniménil",
            "nameCH" : "",
            "code" : "DOC"
        },
        {
            "codeUIC" : 144048,
            "codeCH" : 0,
            "nameUIC" : "Lépanges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144055,
            "codeCH" : "BV",
            "nameUIC" : "Charmes (Vosges)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHS"
        },
        {
            "codeUIC" : 144055,
            "codeCH" : "EP",
            "nameUIC" : "Charmes (Vosges)",
            "nameCH" : "ITE SOTRADEST",
            "code" : "CHS"
        },
        {
            "codeUIC" : 144063,
            "codeCH" : "BV",
            "nameUIC" : "Vincey",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VCY"
        },
        {
            "codeUIC" : 144063,
            "codeCH" : "EF",
            "nameUIC" : "Vincey",
            "nameCH" : "ITE Société Vincey-Bourget",
            "code" : "VCY"
        },
        {
            "codeUIC" : 144071,
            "codeCH" : "BV",
            "nameUIC" : "Châtel-Nomexy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CEO"
        },
        {
            "codeUIC" : 144089,
            "codeCH" : 0,
            "nameUIC" : "Igney",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144097,
            "codeCH" : "BV",
            "nameUIC" : "Thaon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 144105,
            "codeCH" : 0,
            "nameUIC" : "Raves-Ban-de-Laveline",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144121,
            "codeCH" : 0,
            "nameUIC" : "Xertigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144147,
            "codeCH" : 0,
            "nameUIC" : "Bains-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144154,
            "codeCH" : "BV",
            "nameUIC" : "Bruyères (Vosges)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 144162,
            "codeCH" : "BV",
            "nameUIC" : "Laveline-devant-Bruyères",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 144162,
            "codeCH" : "PC",
            "nameUIC" : "Laveline-devant-Bruyères",
            "nameCH" : "Point C",
            "code" : ""
        },
        {
            "codeUIC" : 144188,
            "codeCH" : 0,
            "nameUIC" : "Biffontaine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144196,
            "codeCH" : 0,
            "nameUIC" : "Corcieux-Vanémont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144204,
            "codeCH" : 0,
            "nameUIC" : "Poussay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144212,
            "codeCH" : 0,
            "nameUIC" : "Mirecourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144220,
            "codeCH" : 0,
            "nameUIC" : "Hymont-Mattaincourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144279,
            "codeCH" : "EP",
            "nameUIC" : "Vittel",
            "nameCH" : "ITE SGGSEMF",
            "code" : "VIT"
        },
        {
            "codeUIC" : 144279,
            "codeCH" : "PG",
            "nameUIC" : "Vittel",
            "nameCH" : "Point G",
            "code" : "VIT"
        },
        {
            "codeUIC" : 144279,
            "codeCH" : 0,
            "nameUIC" : "Vittel",
            "nameCH" : "",
            "code" : "VIT"
        },
        {
            "codeUIC" : 144287,
            "codeCH" : "BV",
            "nameUIC" : "Contrexéville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CTX"
        },
        {
            "codeUIC" : 144287,
            "codeCH" : "PE",
            "nameUIC" : "Contrexéville",
            "nameCH" : "Poste E",
            "code" : "CTX"
        },
        {
            "codeUIC" : 144295,
            "codeCH" : 0,
            "nameUIC" : "Martigny-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144303,
            "codeCH" : 0,
            "nameUIC" : "Lamarche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144311,
            "codeCH" : 0,
            "nameUIC" : "Rozières-sur-Mouzon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144329,
            "codeCH" : 0,
            "nameUIC" : "Lesseux-Frapelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144345,
            "codeCH" : 0,
            "nameUIC" : "Provenchères-sur-Fave",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144352,
            "codeCH" : "BV",
            "nameUIC" : "Gironcourt-Houécourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GIT"
        },
        {
            "codeUIC" : 144352,
            "codeCH" : 0,
            "nameUIC" : "Gironcourt-Houécourt",
            "nameCH" : "",
            "code" : "GIT"
        },
        {
            "codeUIC" : 144360,
            "codeCH" : "BV",
            "nameUIC" : "Châtenois-Dolaincourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDU"
        },
        {
            "codeUIC" : 144360,
            "codeCH" : "EP",
            "nameUIC" : "Châtenois-Dolaincourt",
            "nameCH" : "ITE ZIA Neuilly",
            "code" : "CDU"
        },
        {
            "codeUIC" : 144360,
            "codeCH" : 0,
            "nameUIC" : "Châtenois-Dolaincourt",
            "nameCH" : "",
            "code" : "CDU"
        },
        {
            "codeUIC" : 144386,
            "codeCH" : 0,
            "nameUIC" : "Colroy-Lubine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144410,
            "codeCH" : 0,
            "nameUIC" : "Arches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144428,
            "codeCH" : 0,
            "nameUIC" : "Pouxeux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144436,
            "codeCH" : 0,
            "nameUIC" : "Éloyes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144451,
            "codeCH" : 0,
            "nameUIC" : "Remiremont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144477,
            "codeCH" : "EP",
            "nameUIC" : "St-Léonard (Vosges)",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 144477,
            "codeCH" : 0,
            "nameUIC" : "St-Léonard (Vosges)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144477,
            "codeCH" : 5,
            "nameUIC" : "St-Léonard (Vosges)",
            "nameCH" : "PN 5",
            "code" : ""
        },
        {
            "codeUIC" : 144493,
            "codeCH" : 0,
            "nameUIC" : "Saulcy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144626,
            "codeCH" : "IT",
            "nameUIC" : "Raon-l'Étape",
            "nameCH" : "ITE Km 419,6",
            "code" : "RAO"
        },
        {
            "codeUIC" : 144626,
            "codeCH" : 0,
            "nameUIC" : "Raon-l'Étape",
            "nameCH" : "",
            "code" : "RAO"
        },
        {
            "codeUIC" : 144642,
            "codeCH" : 0,
            "nameUIC" : "Étival-Clairefontaine",
            "nameCH" : "",
            "code" : "ETC"
        },
        {
            "codeUIC" : 144683,
            "codeCH" : 0,
            "nameUIC" : "St-Michel-sur-Meurthe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144816,
            "codeCH" : 0,
            "nameUIC" : "Viaduc de l'Eyrieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 144857,
            "codeCH" : "BV",
            "nameUIC" : "Gérardmer",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 144873,
            "codeCH" : "BV",
            "nameUIC" : "Anould",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ANU"
        },
        {
            "codeUIC" : 144873,
            "codeCH" : "EP",
            "nameUIC" : "Anould",
            "nameCH" : "ITE Aussedat Rey",
            "code" : "ANU"
        },
        {
            "codeUIC" : 145227,
            "codeCH" : 0,
            "nameUIC" : "La Pacaudière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 145268,
            "codeCH" : 0,
            "nameUIC" : "Carré 132",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 146191,
            "codeCH" : 0,
            "nameUIC" : "Rozerotte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 146209,
            "codeCH" : 0,
            "nameUIC" : "Haréville-sous-Montfort",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 146217,
            "codeCH" : 0,
            "nameUIC" : "St-Nabord",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 146993,
            "codeCH" : 0,
            "nameUIC" : "ITE SCEM",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 147322,
            "codeCH" : "BV",
            "nameUIC" : "Meuse-TGV",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 149971,
            "codeCH" : "C8",
            "nameUIC" : "Km 8,3",
            "nameCH" : "Carré 8",
            "code" : ""
        },
        {
            "codeUIC" : 149971,
            "codeCH" : "EP",
            "nameUIC" : "Km 8,3",
            "nameCH" : "ITE DDE Nièvre",
            "code" : ""
        },
        {
            "codeUIC" : 151589,
            "codeCH" : 0,
            "nameUIC" : "Le Châtelard-Frontière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 157784,
            "codeCH" : 0,
            "nameUIC" : "ITE SCI de Pouilly et Nohain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 159947,
            "codeCH" : 0,
            "nameUIC" : "Ker-Lann-Bruz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 164780,
            "codeCH" : 0,
            "nameUIC" : "Stade-de-France-St-Denis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 164798,
            "codeCH" : 0,
            "nameUIC" : "La Plaine-Stade-de-France",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "AG",
            "nameUIC" : "Reims",
            "nameCH" : "Dédoublement",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "BF",
            "nameUIC" : "Reims",
            "nameCH" : "Bifurcation L2/L22-5",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "BN",
            "nameUIC" : "Reims",
            "nameCH" : "Bifurcation L2/L10 Nord",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "BS",
            "nameUIC" : "Reims",
            "nameCH" : "Bifurcation L2/L10 Sud",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "BV",
            "nameUIC" : "Reims",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "CT",
            "nameUIC" : "Reims",
            "nameCH" : "Centre",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "C2",
            "nameUIC" : "Reims",
            "nameCH" : "Carré 208",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "PK",
            "nameUIC" : "Reims",
            "nameCH" : "PK 219,4 (SDR)",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "P2",
            "nameUIC" : "Reims",
            "nameCH" : "Bifurcation L10 Sud/Rac 3",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "P3",
            "nameUIC" : "Reims",
            "nameCH" : "Bifurcation L10 Nord/Rac 1",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "P4",
            "nameUIC" : "Reims",
            "nameCH" : "Bifurcation L2/Rac 3",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "P5",
            "nameUIC" : "Reims",
            "nameCH" : "Poste 5",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "R1",
            "nameUIC" : "Reims",
            "nameCH" : "Bifurcation L10 Sud/Rac 1",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "SB",
            "nameUIC" : "Reims",
            "nameCH" : "St-Brice-Courcelles",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "S8",
            "nameUIC" : "Reims",
            "nameCH" : "Sémaphore 82",
            "code" : "RE"
        },
        {
            "codeUIC" : 171009,
            "codeCH" : "TR",
            "nameUIC" : "Reims",
            "nameCH" : "Bétheny Triage",
            "code" : "RE"
        },
        {
            "codeUIC" : 171058,
            "codeCH" : "E1",
            "nameUIC" : "Reims-St-Léonard",
            "nameCH" : "Poste E1",
            "code" : "RST"
        },
        {
            "codeUIC" : 171058,
            "codeCH" : "E2",
            "nameUIC" : "Reims-St-Léonard",
            "nameCH" : "Poste E2",
            "code" : "RST"
        },
        {
            "codeUIC" : 171256,
            "codeCH" : 0,
            "nameUIC" : "Montbré",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171264,
            "codeCH" : "AR",
            "nameUIC" : "Trois-Puits",
            "nameCH" : "Accès Raccordement LGV",
            "code" : ""
        },
        {
            "codeUIC" : 171264,
            "codeCH" : "A1",
            "nameUIC" : "Trois-Puits",
            "nameCH" : "Sortie Paire",
            "code" : ""
        },
        {
            "codeUIC" : 171264,
            "codeCH" : "A2",
            "nameUIC" : "Trois-Puits",
            "nameCH" : "Sortie Impaire",
            "code" : ""
        },
        {
            "codeUIC" : 171264,
            "codeCH" : "BV",
            "nameUIC" : "Trois-Puits",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171272,
            "codeCH" : 0,
            "nameUIC" : "Reims-Maison-Blanche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171298,
            "codeCH" : "BV",
            "nameUIC" : "Muizon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MIZ"
        },
        {
            "codeUIC" : 171298,
            "codeCH" : "MA",
            "nameUIC" : "Muizon",
            "nameCH" : "Marchandises",
            "code" : "MIZ"
        },
        {
            "codeUIC" : 171314,
            "codeCH" : "BV",
            "nameUIC" : "Jonchery-sur-Vesle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171314,
            "codeCH" : 0,
            "nameUIC" : "Jonchery-sur-Vesle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171314,
            "codeCH" : 11,
            "nameUIC" : "Jonchery-sur-Vesle",
            "nameCH" : "Sémaphore 11",
            "code" : ""
        },
        {
            "codeUIC" : 171314,
            "codeCH" : 12,
            "nameUIC" : "Jonchery-sur-Vesle",
            "nameCH" : "Sémaphore 12",
            "code" : ""
        },
        {
            "codeUIC" : 171322,
            "codeCH" : "BV",
            "nameUIC" : "Breuil-Romain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171330,
            "codeCH" : 0,
            "nameUIC" : "Fismes",
            "nameCH" : "",
            "code" : "FI"
        },
        {
            "codeUIC" : 171348,
            "codeCH" : "PP",
            "nameUIC" : "Bazoches",
            "nameCH" : "PP",
            "code" : ""
        },
        {
            "codeUIC" : 171348,
            "codeCH" : 0,
            "nameUIC" : "Bazoches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171355,
            "codeCH" : 0,
            "nameUIC" : "Mont-Notre-Dame",
            "nameCH" : "",
            "code" : "MDA"
        },
        {
            "codeUIC" : 171371,
            "codeCH" : 0,
            "nameUIC" : "Fère-en-Tardenois",
            "nameCH" : "",
            "code" : "FET"
        },
        {
            "codeUIC" : 171447,
            "codeCH" : "S1",
            "nameUIC" : "Witry-lès-Reims",
            "nameCH" : "Sémaphore 301",
            "code" : ""
        },
        {
            "codeUIC" : 171447,
            "codeCH" : "S2",
            "nameUIC" : "Witry-lès-Reims",
            "nameCH" : "Sémaphore 304",
            "code" : ""
        },
        {
            "codeUIC" : 171454,
            "codeCH" : 0,
            "nameUIC" : "Lavannes-Caurel",
            "nameCH" : "ITE Coopérative Déshydratation-Reims-Luzerne",
            "code" : "LAV"
        },
        {
            "codeUIC" : 171462,
            "codeCH" : "BV",
            "nameUIC" : "Bazancourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BZT"
        },
        {
            "codeUIC" : 171462,
            "codeCH" : "PA",
            "nameUIC" : "Bazancourt",
            "nameCH" : "ITE Cristanol",
            "code" : "BZT"
        },
        {
            "codeUIC" : 171462,
            "codeCH" : "S6",
            "nameUIC" : "Bazancourt",
            "nameCH" : "Sémaphore 306",
            "code" : "BZT"
        },
        {
            "codeUIC" : 171496,
            "codeCH" : "BV",
            "nameUIC" : "Braine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BIE"
        },
        {
            "codeUIC" : 171496,
            "codeCH" : 15,
            "nameUIC" : "Braine",
            "nameCH" : "PN 15",
            "code" : "BIE"
        },
        {
            "codeUIC" : 171512,
            "codeCH" : "A1",
            "nameUIC" : "Dormans",
            "nameCH" : "A1-Évitement de Soilly",
            "code" : "DOR"
        },
        {
            "codeUIC" : 171512,
            "codeCH" : "A2",
            "nameUIC" : "Dormans",
            "nameCH" : "A2-Évitement de Soilly",
            "code" : "DOR"
        },
        {
            "codeUIC" : 171512,
            "codeCH" : "BV",
            "nameUIC" : "Dormans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DOR"
        },
        {
            "codeUIC" : 171538,
            "codeCH" : 0,
            "nameUIC" : "Port-à-Binson-Châtillon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171553,
            "codeCH" : "A1",
            "nameUIC" : "Épernay",
            "nameCH" : "Aiguille 139,3",
            "code" : ""
        },
        {
            "codeUIC" : 171553,
            "codeCH" : "A2",
            "nameUIC" : "Épernay",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 171553,
            "codeCH" : "BF",
            "nameUIC" : "Épernay",
            "nameCH" : "Bifurcation L1/22 5",
            "code" : ""
        },
        {
            "codeUIC" : 171553,
            "codeCH" : "BV",
            "nameUIC" : "Épernay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171553,
            "codeCH" : "RO",
            "nameUIC" : "Épernay",
            "nameCH" : "Raccordement Côté Oiry",
            "code" : ""
        },
        {
            "codeUIC" : 171553,
            "codeCH" : "RR",
            "nameUIC" : "Épernay",
            "nameCH" : "Bifurcation L22 5/Rac",
            "code" : ""
        },
        {
            "codeUIC" : 171553,
            "codeCH" : "TR",
            "nameUIC" : "Épernay",
            "nameCH" : "Cumières Triage",
            "code" : ""
        },
        {
            "codeUIC" : 171561,
            "codeCH" : "AG",
            "nameUIC" : "Ay",
            "nameCH" : "Dédoublement",
            "code" : ""
        },
        {
            "codeUIC" : 171561,
            "codeCH" : "BV",
            "nameUIC" : "Ay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171579,
            "codeCH" : "A1",
            "nameUIC" : "Avenay",
            "nameCH" : "Sortie Paire",
            "code" : ""
        },
        {
            "codeUIC" : 171579,
            "codeCH" : "A2",
            "nameUIC" : "Avenay",
            "nameCH" : "Sortie Impaire",
            "code" : ""
        },
        {
            "codeUIC" : 171579,
            "codeCH" : "BV",
            "nameUIC" : "Avenay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171587,
            "codeCH" : "A1",
            "nameUIC" : "Germaine",
            "nameCH" : "Sortie Paire",
            "code" : ""
        },
        {
            "codeUIC" : 171587,
            "codeCH" : "A2",
            "nameUIC" : "Germaine",
            "nameCH" : "Sortie Impaire",
            "code" : ""
        },
        {
            "codeUIC" : 171587,
            "codeCH" : "BV",
            "nameUIC" : "Germaine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171595,
            "codeCH" : "A1",
            "nameUIC" : "Rilly-la-Montagne",
            "nameCH" : "Sortie Paire",
            "code" : ""
        },
        {
            "codeUIC" : 171595,
            "codeCH" : "A2",
            "nameUIC" : "Rilly-la-Montagne",
            "nameCH" : "Sortie Impaire",
            "code" : ""
        },
        {
            "codeUIC" : 171595,
            "codeCH" : "BV",
            "nameUIC" : "Rilly-la-Montagne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171611,
            "codeCH" : 0,
            "nameUIC" : "Val-de-Vesle (Marne)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171629,
            "codeCH" : 0,
            "nameUIC" : "Prunay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171637,
            "codeCH" : "EP",
            "nameUIC" : "Sillery",
            "nameCH" : "ITE Providence Agricole",
            "code" : "SLY"
        },
        {
            "codeUIC" : 171637,
            "codeCH" : 0,
            "nameUIC" : "Sillery",
            "nameCH" : "",
            "code" : "SLY"
        },
        {
            "codeUIC" : 171645,
            "codeCH" : 0,
            "nameUIC" : "Sept-Saulx",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171702,
            "codeCH" : "BV",
            "nameUIC" : "Aguilcourt-Variscourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171702,
            "codeCH" : "S7",
            "nameUIC" : "Aguilcourt-Variscourt",
            "nameCH" : "Sémaphore 75",
            "code" : ""
        },
        {
            "codeUIC" : 171702,
            "codeCH" : 72,
            "nameUIC" : "Aguilcourt-Variscourt",
            "nameCH" : "Carré 72",
            "code" : ""
        },
        {
            "codeUIC" : 171710,
            "codeCH" : 0,
            "nameUIC" : "Courcy-Brimont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171728,
            "codeCH" : 0,
            "nameUIC" : "Loivre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171736,
            "codeCH" : "BV",
            "nameUIC" : "Guignicourt (Aisne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GUI"
        },
        {
            "codeUIC" : 171736,
            "codeCH" : "EP",
            "nameUIC" : "Guignicourt (Aisne)",
            "nameCH" : "ITE ST-Louis Sucre",
            "code" : "GUI"
        },
        {
            "codeUIC" : 171736,
            "codeCH" : "S6",
            "nameUIC" : "Guignicourt (Aisne)",
            "nameCH" : "Sémaphore 62",
            "code" : "GUI"
        },
        {
            "codeUIC" : 171736,
            "codeCH" : 61,
            "nameUIC" : "Guignicourt (Aisne)",
            "nameCH" : "Carré 61",
            "code" : "GUI"
        },
        {
            "codeUIC" : 171744,
            "codeCH" : "BV",
            "nameUIC" : "Amifontaine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AMF"
        },
        {
            "codeUIC" : 171744,
            "codeCH" : "S5",
            "nameUIC" : "Amifontaine",
            "nameCH" : "Sémaphore 51",
            "code" : "AMF"
        },
        {
            "codeUIC" : 171751,
            "codeCH" : "BV",
            "nameUIC" : "St-Erme",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "STM"
        },
        {
            "codeUIC" : 171751,
            "codeCH" : "S3",
            "nameUIC" : "St-Erme",
            "nameCH" : "Carré 32",
            "code" : "STM"
        },
        {
            "codeUIC" : 171751,
            "codeCH" : "S4",
            "nameUIC" : "St-Erme",
            "nameCH" : "Carré 41",
            "code" : "STM"
        },
        {
            "codeUIC" : 171751,
            "codeCH" : 31,
            "nameUIC" : "St-Erme",
            "nameCH" : "carré 31",
            "code" : "STM"
        },
        {
            "codeUIC" : 171751,
            "codeCH" : 42,
            "nameUIC" : "St-Erme",
            "nameCH" : "Carré 42",
            "code" : "STM"
        },
        {
            "codeUIC" : 171769,
            "codeCH" : "BV",
            "nameUIC" : "Coucy-lès-Eppes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171769,
            "codeCH" : "S2",
            "nameUIC" : "Coucy-lès-Eppes",
            "nameCH" : "Sémaphore 21",
            "code" : ""
        },
        {
            "codeUIC" : 171801,
            "codeCH" : 0,
            "nameUIC" : "Warmeriville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171819,
            "codeCH" : "BV",
            "nameUIC" : "St-Masmes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SMS"
        },
        {
            "codeUIC" : 171827,
            "codeCH" : "EP",
            "nameUIC" : "Pontfaverger",
            "nameCH" : "ITE SCA Desival",
            "code" : "PTF"
        },
        {
            "codeUIC" : 171835,
            "codeCH" : "BV",
            "nameUIC" : "Bétheniville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BTV"
        },
        {
            "codeUIC" : 171868,
            "codeCH" : "BV",
            "nameUIC" : "Dontrien",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DOT"
        },
        {
            "codeUIC" : 171884,
            "codeCH" : 0,
            "nameUIC" : "Sommepy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 171926,
            "codeCH" : "AG",
            "nameUIC" : "Champagne-Ardenne-TGV",
            "nameCH" : "Aiguille Voies VR1/VR2",
            "code" : ""
        },
        {
            "codeUIC" : 171926,
            "codeCH" : "AN",
            "nameUIC" : "Champagne-Ardenne-TGV",
            "nameCH" : "Accès Voie N",
            "code" : ""
        },
        {
            "codeUIC" : 171926,
            "codeCH" : "BV",
            "nameUIC" : "Champagne-Ardenne-TGV",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 171926,
            "codeCH" : "RP",
            "nameUIC" : "Champagne-Ardenne-TGV",
            "nameCH" : "Raccordement côté Paris",
            "code" : ""
        },
        {
            "codeUIC" : 172007,
            "codeCH" : "BV",
            "nameUIC" : "Charleville-Mézières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CA"
        },
        {
            "codeUIC" : 172007,
            "codeCH" : "EB",
            "nameUIC" : "Charleville-Mézières",
            "nameCH" : "ITE BLAIRON",
            "code" : "CA"
        },
        {
            "codeUIC" : 172007,
            "codeCH" : "RH",
            "nameUIC" : "Charleville-Mézières",
            "nameCH" : "Raccordement Côté Hirson",
            "code" : "CA"
        },
        {
            "codeUIC" : 172007,
            "codeCH" : "RP",
            "nameUIC" : "Charleville-Mézières",
            "nameCH" : "Raccordement Côté Paris",
            "code" : "CA"
        },
        {
            "codeUIC" : 172023,
            "codeCH" : 0,
            "nameUIC" : "Nouzonville",
            "nameCH" : "",
            "code" : "NOU"
        },
        {
            "codeUIC" : 172031,
            "codeCH" : 0,
            "nameUIC" : "Joigny-sur-Meuse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172049,
            "codeCH" : 0,
            "nameUIC" : "Bogny-sur-Meuse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172056,
            "codeCH" : 0,
            "nameUIC" : "Monthermé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172064,
            "codeCH" : "BV",
            "nameUIC" : "Deville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172064,
            "codeCH" : "EP",
            "nameUIC" : "Deville",
            "nameCH" : "ITE COCHAUX",
            "code" : ""
        },
        {
            "codeUIC" : 172072,
            "codeCH" : 0,
            "nameUIC" : "Laifour",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172080,
            "codeCH" : 0,
            "nameUIC" : "Revin",
            "nameCH" : "",
            "code" : "RV"
        },
        {
            "codeUIC" : 172098,
            "codeCH" : 0,
            "nameUIC" : "Fumay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172106,
            "codeCH" : "BV",
            "nameUIC" : "Haybes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172114,
            "codeCH" : 0,
            "nameUIC" : "Vireux-Molhain",
            "nameCH" : "",
            "code" : "VX"
        },
        {
            "codeUIC" : 172122,
            "codeCH" : 0,
            "nameUIC" : "Aubrives",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172130,
            "codeCH" : "AG",
            "nameUIC" : "Givet",
            "nameCH" : "Poste 1",
            "code" : "GVT"
        },
        {
            "codeUIC" : 172130,
            "codeCH" : "BV",
            "nameUIC" : "Givet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GVT"
        },
        {
            "codeUIC" : 172130,
            "codeCH" : "PB",
            "nameUIC" : "Givet",
            "nameCH" : "ITE Granulats Nord-Est",
            "code" : "GVT"
        },
        {
            "codeUIC" : 172155,
            "codeCH" : "AT",
            "nameUIC" : "Mohon",
            "nameCH" : "Ateliers",
            "code" : "MN"
        },
        {
            "codeUIC" : 172155,
            "codeCH" : "BV",
            "nameUIC" : "Mohon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MN"
        },
        {
            "codeUIC" : 172155,
            "codeCH" : "C2",
            "nameUIC" : "Mohon",
            "nameCH" : "Carré 302",
            "code" : "MN"
        },
        {
            "codeUIC" : 172155,
            "codeCH" : "PA",
            "nameUIC" : "Mohon",
            "nameCH" : "Bifurcation L2/Rac",
            "code" : "MN"
        },
        {
            "codeUIC" : 172155,
            "codeCH" : "PC",
            "nameUIC" : "Mohon",
            "nameCH" : "Bifurcation L2",
            "code" : "MN"
        },
        {
            "codeUIC" : 172171,
            "codeCH" : "BV",
            "nameUIC" : "Poix-Terron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172171,
            "codeCH" : "S1",
            "nameUIC" : "Poix-Terron",
            "nameCH" : "Sémaphore 91",
            "code" : ""
        },
        {
            "codeUIC" : 172171,
            "codeCH" : "S2",
            "nameUIC" : "Poix-Terron",
            "nameCH" : "Sémaphore 82",
            "code" : ""
        },
        {
            "codeUIC" : 172189,
            "codeCH" : 0,
            "nameUIC" : "Lumes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "AG",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "Accès Km 146,3",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "CD",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "Km 146,0",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "EC",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "ITE Citroën",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "FA",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "Faisceau A",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "FD",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "Faisceau D",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "PD",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "Postes 1 et D",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "P1",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "Accès Km 144,5",
            "code" : ""
        },
        {
            "codeUIC" : 172197,
            "codeCH" : "P2",
            "nameUIC" : "Lumes-Triage",
            "nameCH" : "Accès Km 148,0",
            "code" : ""
        },
        {
            "codeUIC" : 172205,
            "codeCH" : 0,
            "nameUIC" : "Launois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172205,
            "codeCH" : 71,
            "nameUIC" : "Launois",
            "nameCH" : "Sémaphore 71",
            "code" : ""
        },
        {
            "codeUIC" : 172213,
            "codeCH" : 0,
            "nameUIC" : "Nouvion-sur-Meuse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172221,
            "codeCH" : "BV",
            "nameUIC" : "Vrigne-Meuse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172239,
            "codeCH" : 0,
            "nameUIC" : "Donchery",
            "nameCH" : "",
            "code" : "DEY"
        },
        {
            "codeUIC" : 172254,
            "codeCH" : "AG",
            "nameUIC" : "Sedan",
            "nameCH" : "Accès Garage",
            "code" : ""
        },
        {
            "codeUIC" : 172254,
            "codeCH" : "AV",
            "nameUIC" : "Sedan",
            "nameCH" : "Entrée/Sortie Voie A",
            "code" : ""
        },
        {
            "codeUIC" : 172254,
            "codeCH" : "BV",
            "nameUIC" : "Sedan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172254,
            "codeCH" : "GA",
            "nameUIC" : "Sedan",
            "nameCH" : "Garages",
            "code" : ""
        },
        {
            "codeUIC" : 172262,
            "codeCH" : "BV",
            "nameUIC" : "Amagne-Lucquy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172262,
            "codeCH" : "S2",
            "nameUIC" : "Amagne-Lucquy",
            "nameCH" : "Sémaphore 72",
            "code" : ""
        },
        {
            "codeUIC" : 172270,
            "codeCH" : "BV",
            "nameUIC" : "Rethel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RTL"
        },
        {
            "codeUIC" : 172296,
            "codeCH" : 0,
            "nameUIC" : "Le Châtelet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172304,
            "codeCH" : "AI",
            "nameUIC" : "Pont-Maugis",
            "nameCH" : "Jonction VUTR",
            "code" : ""
        },
        {
            "codeUIC" : 172304,
            "codeCH" : 0,
            "nameUIC" : "Pont-Maugis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172312,
            "codeCH" : "EP",
            "nameUIC" : "Bazeilles",
            "nameCH" : "ITE Unilin",
            "code" : "BZI"
        },
        {
            "codeUIC" : 172312,
            "codeCH" : 0,
            "nameUIC" : "Bazeilles",
            "nameCH" : "",
            "code" : "BZI"
        },
        {
            "codeUIC" : 172338,
            "codeCH" : "BV",
            "nameUIC" : "Pouru-Brévilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172338,
            "codeCH" : "2B",
            "nameUIC" : "Pouru-Brévilly",
            "nameCH" : "Sortie Évitement V2B",
            "code" : ""
        },
        {
            "codeUIC" : 172353,
            "codeCH" : 0,
            "nameUIC" : "Carignan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172387,
            "codeCH" : 0,
            "nameUIC" : "Blagny",
            "nameCH" : "ITE Petit-Jean-Blagny",
            "code" : "BGY"
        },
        {
            "codeUIC" : 172403,
            "codeCH" : "BV",
            "nameUIC" : "Novion-Porcien",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172411,
            "codeCH" : "BV",
            "nameUIC" : "Margut-Fromy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MAF"
        },
        {
            "codeUIC" : 172452,
            "codeCH" : "EP",
            "nameUIC" : "Alland'Huy",
            "nameCH" : "ITE PROV AGR ALLAND",
            "code" : "ALU"
        },
        {
            "codeUIC" : 172460,
            "codeCH" : "BV",
            "nameUIC" : "Attigny (Ardennes)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ATG"
        },
        {
            "codeUIC" : 172494,
            "codeCH" : 0,
            "nameUIC" : "Rilly-Semuy-St-Irénée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172502,
            "codeCH" : 0,
            "nameUIC" : "Tournes",
            "nameCH" : "",
            "code" : "TRN"
        },
        {
            "codeUIC" : 172528,
            "codeCH" : 0,
            "nameUIC" : "Rouvroy-l'Échelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172544,
            "codeCH" : "BV",
            "nameUIC" : "Liart",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172544,
            "codeCH" : "ST",
            "nameUIC" : "Liart",
            "nameCH" : "Sectionnement Aouste",
            "code" : ""
        },
        {
            "codeUIC" : 172569,
            "codeCH" : 0,
            "nameUIC" : "Aubenton",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172593,
            "codeCH" : "BV",
            "nameUIC" : "Vouziers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VZS"
        },
        {
            "codeUIC" : 172627,
            "codeCH" : "EP",
            "nameUIC" : "Monthois",
            "nameCH" : "ITE Champagne Céréales",
            "code" : "MEP"
        },
        {
            "codeUIC" : 172627,
            "codeCH" : 0,
            "nameUIC" : "Monthois",
            "nameCH" : "",
            "code" : "MEP"
        },
        {
            "codeUIC" : 172635,
            "codeCH" : "BV",
            "nameUIC" : "Challerange",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172635,
            "codeCH" : 4,
            "nameUIC" : "Challerange",
            "nameCH" : "PN 4",
            "code" : ""
        },
        {
            "codeUIC" : 172759,
            "codeCH" : "BV",
            "nameUIC" : "Mouzon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MZN"
        },
        {
            "codeUIC" : 172759,
            "codeCH" : "EP",
            "nameUIC" : "Mouzon",
            "nameCH" : "ITE SOLLAC",
            "code" : "MZN"
        },
        {
            "codeUIC" : 172759,
            "codeCH" : 72,
            "nameUIC" : "Mouzon",
            "nameCH" : "PN 172",
            "code" : "MZN"
        },
        {
            "codeUIC" : 172767,
            "codeCH" : 0,
            "nameUIC" : "Létanne-Beaumont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172775,
            "codeCH" : 0,
            "nameUIC" : "Stenay",
            "nameCH" : "",
            "code" : "STY"
        },
        {
            "codeUIC" : 172809,
            "codeCH" : "BV",
            "nameUIC" : "Grandpré",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 172809,
            "codeCH" : 11,
            "nameUIC" : "Grandpré",
            "nameCH" : "PN 11",
            "code" : ""
        },
        {
            "codeUIC" : 172858,
            "codeCH" : 0,
            "nameUIC" : "Anchamps",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172874,
            "codeCH" : 0,
            "nameUIC" : "Fépin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 172882,
            "codeCH" : 0,
            "nameUIC" : "Montigny-sur-Meuse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 173203,
            "codeCH" : 0,
            "nameUIC" : "Amagne-Village",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "AR",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Accès Relais",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "AT",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Accès Triage",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "BF",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Bifurcation 1-26",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "BV",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "DP",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Dépôt",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "EC",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "ITE Coopérative Agricole UCP",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "ED",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "ITE Coopérative Champagne Céréales",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "E2",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "ITE ZI (Entrée)",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "E3",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "ITE ZI (Sortie)",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "PK",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "ITE Coopérative Agricole Champagne Céréales",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "P1",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Poste 1",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "P2",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Aiguille Km 172,6",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "QM",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Quai Militaire",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174003,
            "codeCH" : "TR",
            "nameUIC" : "Châlons-en-Champagne",
            "nameCH" : "Triage",
            "code" : "CLC"
        },
        {
            "codeUIC" : 174102,
            "codeCH" : "A1",
            "nameUIC" : "Oiry",
            "nameCH" : "Aiguille 145,9-Évitement",
            "code" : "OIR"
        },
        {
            "codeUIC" : 174102,
            "codeCH" : "A2",
            "nameUIC" : "Oiry",
            "nameCH" : "Aiguille 147,2-Évitement",
            "code" : "OIR"
        },
        {
            "codeUIC" : 174102,
            "codeCH" : "BF",
            "nameUIC" : "Oiry",
            "nameCH" : "Bifurcation L1/L22",
            "code" : "OIR"
        },
        {
            "codeUIC" : 174102,
            "codeCH" : "BV",
            "nameUIC" : "Oiry",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "OIR"
        },
        {
            "codeUIC" : 174102,
            "codeCH" : "EP",
            "nameUIC" : "Oiry",
            "nameCH" : "ITE Coopérative Agricole et Viticole d'Épernay",
            "code" : "OIR"
        },
        {
            "codeUIC" : 174110,
            "codeCH" : 0,
            "nameUIC" : "Athis-Tours-sur-Marne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174219,
            "codeCH" : "A1",
            "nameUIC" : "Vitry-la-Ville",
            "nameCH" : "Aiguille 187,8-Évitement",
            "code" : "VYV"
        },
        {
            "codeUIC" : 174219,
            "codeCH" : "A2",
            "nameUIC" : "Vitry-la-Ville",
            "nameCH" : "Aiguille 189,0-Évitement",
            "code" : "VYV"
        },
        {
            "codeUIC" : 174219,
            "codeCH" : "A3",
            "nameUIC" : "Vitry-la-Ville",
            "nameCH" : "Songy Évitement Pair",
            "code" : "VYV"
        },
        {
            "codeUIC" : 174219,
            "codeCH" : "A4",
            "nameUIC" : "Vitry-la-Ville",
            "nameCH" : "Entrée Évitement Songy",
            "code" : "VYV"
        },
        {
            "codeUIC" : 174219,
            "codeCH" : "A5",
            "nameUIC" : "Vitry-la-Ville",
            "nameCH" : "Songy Évitement Impair",
            "code" : "VYV"
        },
        {
            "codeUIC" : 174219,
            "codeCH" : "BV",
            "nameUIC" : "Vitry-la-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VYV"
        },
        {
            "codeUIC" : 174250,
            "codeCH" : 0,
            "nameUIC" : "Loisy-sur-Marne",
            "nameCH" : "",
            "code" : "LOS"
        },
        {
            "codeUIC" : 174276,
            "codeCH" : "A1",
            "nameUIC" : "Vitry-le-François",
            "nameCH" : "Aiguille Ouest-Voie Bis",
            "code" : "VYF"
        },
        {
            "codeUIC" : 174276,
            "codeCH" : "A2",
            "nameUIC" : "Vitry-le-François",
            "nameCH" : "Aiguille Est-Voie Bis",
            "code" : "VYF"
        },
        {
            "codeUIC" : 174276,
            "codeCH" : "BV",
            "nameUIC" : "Vitry-le-François",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VYF"
        },
        {
            "codeUIC" : 174326,
            "codeCH" : "BF",
            "nameUIC" : "Blesme-Haussignemont",
            "nameCH" : "Aiguille L1/L10",
            "code" : ""
        },
        {
            "codeUIC" : 174326,
            "codeCH" : "BV",
            "nameUIC" : "Blesme-Haussignemont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 174334,
            "codeCH" : 0,
            "nameUIC" : "Pargny-sur-Saulx",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174359,
            "codeCH" : 0,
            "nameUIC" : "Sermaize-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174409,
            "codeCH" : 0,
            "nameUIC" : "Mourmelon-le-Petit",
            "nameCH" : "",
            "code" : "MLP"
        },
        {
            "codeUIC" : 174433,
            "codeCH" : 0,
            "nameUIC" : "Bouy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174441,
            "codeCH" : "BV",
            "nameUIC" : "St-Hilaire-au-Temple",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SHT"
        },
        {
            "codeUIC" : 174441,
            "codeCH" : "C1",
            "nameUIC" : "St-Hilaire-au-Temple",
            "nameCH" : "Raccordement Châlons Voie R1C",
            "code" : "SHT"
        },
        {
            "codeUIC" : 174441,
            "codeCH" : "C2",
            "nameUIC" : "St-Hilaire-au-Temple",
            "nameCH" : "Raccordement Châlons Voie R2C",
            "code" : "SHT"
        },
        {
            "codeUIC" : 174441,
            "codeCH" : "EP",
            "nameUIC" : "St-Hilaire-au-Temple",
            "nameCH" : "ITE Service des Essences des Armées",
            "code" : "SHT"
        },
        {
            "codeUIC" : 174441,
            "codeCH" : "E4",
            "nameUIC" : "St-Hilaire-au-Temple",
            "nameCH" : "Point E4",
            "code" : "SHT"
        },
        {
            "codeUIC" : 174441,
            "codeCH" : "RP",
            "nameUIC" : "St-Hilaire-au-Temple",
            "nameCH" : "Raccordement côté Paris",
            "code" : "SHT"
        },
        {
            "codeUIC" : 174466,
            "codeCH" : 0,
            "nameUIC" : "La Veuve",
            "nameCH" : "Sémaphore 201",
            "code" : "LVE"
        },
        {
            "codeUIC" : 174474,
            "codeCH" : "BV",
            "nameUIC" : "Nuisement",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NEM"
        },
        {
            "codeUIC" : 174474,
            "codeCH" : "EP",
            "nameUIC" : "Nuisement",
            "nameCH" : "ITE Carburants",
            "code" : "NEM"
        },
        {
            "codeUIC" : 174516,
            "codeCH" : "EP",
            "nameUIC" : "Bussy-Lettrée-Vatry",
            "nameCH" : "ITE Europort Vatry",
            "code" : "BYY"
        },
        {
            "codeUIC" : 174516,
            "codeCH" : 0,
            "nameUIC" : "Bussy-Lettrée-Vatry",
            "nameCH" : "",
            "code" : "BYY"
        },
        {
            "codeUIC" : 174540,
            "codeCH" : 0,
            "nameUIC" : "Sommesous",
            "nameCH" : "",
            "code" : "SMU"
        },
        {
            "codeUIC" : 174573,
            "codeCH" : "BV",
            "nameUIC" : "Mailly-le-Camp",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MLC"
        },
        {
            "codeUIC" : 174573,
            "codeCH" : "PN",
            "nameUIC" : "Mailly-le-Camp",
            "nameCH" : "PN 37",
            "code" : "MLC"
        },
        {
            "codeUIC" : 174607,
            "codeCH" : 0,
            "nameUIC" : "Avize",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174623,
            "codeCH" : 0,
            "nameUIC" : "Vertus",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174649,
            "codeCH" : "BV",
            "nameUIC" : "Coligny (Marne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CGR"
        },
        {
            "codeUIC" : 174649,
            "codeCH" : "EM",
            "nameUIC" : "Coligny (Marne)",
            "nameCH" : "ITE CAM",
            "code" : "CGR"
        },
        {
            "codeUIC" : 174649,
            "codeCH" : "EP",
            "nameUIC" : "Coligny (Marne)",
            "nameCH" : "ITE Coopérative Agricole de la Champagne",
            "code" : "CGR"
        },
        {
            "codeUIC" : 174656,
            "codeCH" : 0,
            "nameUIC" : "Morains-le-Petit",
            "nameCH" : "ITE Distillerie de Morains",
            "code" : ""
        },
        {
            "codeUIC" : 174664,
            "codeCH" : "BV",
            "nameUIC" : "Fère-Champenoise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FAS"
        },
        {
            "codeUIC" : 174664,
            "codeCH" : "PN",
            "nameUIC" : "Fère-Champenoise",
            "nameCH" : "PN 50",
            "code" : "FAS"
        },
        {
            "codeUIC" : 174698,
            "codeCH" : "EP",
            "nameUIC" : "Connantre",
            "nameCH" : "ITE Blanchet",
            "code" : "CNR"
        },
        {
            "codeUIC" : 174698,
            "codeCH" : "ES",
            "nameUIC" : "Connantre",
            "nameCH" : "ITE Beghin-Say",
            "code" : "CNR"
        },
        {
            "codeUIC" : 174714,
            "codeCH" : 0,
            "nameUIC" : "Linthes-Linthelles-Pleurs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174771,
            "codeCH" : 0,
            "nameUIC" : "Gigny-Brandonvillers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 174805,
            "codeCH" : 0,
            "nameUIC" : "Cuperly",
            "nameCH" : "ITE Les Producteurs Marnais",
            "code" : ""
        },
        {
            "codeUIC" : 174821,
            "codeCH" : 0,
            "nameUIC" : "Suippes",
            "nameCH" : "",
            "code" : "SUI"
        },
        {
            "codeUIC" : 174862,
            "codeCH" : 0,
            "nameUIC" : "Valmy",
            "nameCH" : "",
            "code" : "VLY"
        },
        {
            "codeUIC" : 174870,
            "codeCH" : 0,
            "nameUIC" : "Ste-Menehould",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175000,
            "codeCH" : "AT",
            "nameUIC" : "St-Dizier",
            "nameCH" : "Ancien Triage",
            "code" : "SDE"
        },
        {
            "codeUIC" : 175000,
            "codeCH" : "BV",
            "nameUIC" : "St-Dizier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SDE"
        },
        {
            "codeUIC" : 175000,
            "codeCH" : "E1",
            "nameUIC" : "St-Dizier",
            "nameCH" : "ITE Tréfilunion",
            "code" : "SDE"
        },
        {
            "codeUIC" : 175000,
            "codeCH" : "P1",
            "nameUIC" : "St-Dizier",
            "nameCH" : "Poste 1",
            "code" : "SDE"
        },
        {
            "codeUIC" : 175018,
            "codeCH" : "A1",
            "nameUIC" : "Revigny",
            "nameCH" : "Aiguille Km 235,8",
            "code" : "REV"
        },
        {
            "codeUIC" : 175018,
            "codeCH" : "A2",
            "nameUIC" : "Revigny",
            "nameCH" : "Aiguille Km 239,3",
            "code" : "REV"
        },
        {
            "codeUIC" : 175018,
            "codeCH" : "BV",
            "nameUIC" : "Revigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "REV"
        },
        {
            "codeUIC" : 175018,
            "codeCH" : "LO",
            "nameUIC" : "Revigny",
            "nameCH" : "Local",
            "code" : "REV"
        },
        {
            "codeUIC" : 175026,
            "codeCH" : 0,
            "nameUIC" : "Mussey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175042,
            "codeCH" : "AE",
            "nameUIC" : "Bar-le-Duc",
            "nameCH" : "Entrée V1B/Sortie V2B",
            "code" : ""
        },
        {
            "codeUIC" : 175042,
            "codeCH" : "AO",
            "nameUIC" : "Bar-le-Duc",
            "nameCH" : "Entrée V2B/Sortie V1B",
            "code" : ""
        },
        {
            "codeUIC" : 175042,
            "codeCH" : "BV",
            "nameUIC" : "Bar-le-Duc",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 175067,
            "codeCH" : "AG",
            "nameUIC" : "Nançois-Tronville",
            "nameCH" : "Aiguille Km 263,4",
            "code" : "NAE"
        },
        {
            "codeUIC" : 175067,
            "codeCH" : "BV",
            "nameUIC" : "Nançois-Tronville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NAE"
        },
        {
            "codeUIC" : 175067,
            "codeCH" : "EP",
            "nameUIC" : "Nançois-Tronville",
            "nameCH" : "ITE ZI de Velaines",
            "code" : "NAE"
        },
        {
            "codeUIC" : 175067,
            "codeCH" : "IT",
            "nameUIC" : "Nançois-Tronville",
            "nameCH" : "ITE Km 264",
            "code" : "NAE"
        },
        {
            "codeUIC" : 175067,
            "codeCH" : "V4",
            "nameUIC" : "Nançois-Tronville",
            "nameCH" : "Voie 104 Réception",
            "code" : "NAE"
        },
        {
            "codeUIC" : 175075,
            "codeCH" : 0,
            "nameUIC" : "Fronville-St-Urbain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175091,
            "codeCH" : "BV",
            "nameUIC" : "Joinville (Haute-Marne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "J"
        },
        {
            "codeUIC" : 175091,
            "codeCH" : "EP",
            "nameUIC" : "Joinville (Haute-Marne)",
            "nameCH" : "ITE Bussy-Ferry",
            "code" : "J"
        },
        {
            "codeUIC" : 175091,
            "codeCH" : "S0",
            "nameUIC" : "Joinville (Haute-Marne)",
            "nameCH" : "Sémaphore 230",
            "code" : "J"
        },
        {
            "codeUIC" : 175091,
            "codeCH" : "S5",
            "nameUIC" : "Joinville (Haute-Marne)",
            "nameCH" : "Sémaphore 245",
            "code" : "J"
        },
        {
            "codeUIC" : 175117,
            "codeCH" : "BV",
            "nameUIC" : "Chevillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CIN"
        },
        {
            "codeUIC" : 175117,
            "codeCH" : "S6",
            "nameUIC" : "Chevillon",
            "nameCH" : "Sémaphore 226",
            "code" : "CIN"
        },
        {
            "codeUIC" : 175133,
            "codeCH" : 0,
            "nameUIC" : "Bayard",
            "nameCH" : "",
            "code" : "BYD"
        },
        {
            "codeUIC" : 175141,
            "codeCH" : "BV",
            "nameUIC" : "Eurville-Bienville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EUB"
        },
        {
            "codeUIC" : 175141,
            "codeCH" : "EP",
            "nameUIC" : "Eurville-Bienville",
            "nameCH" : "ITE Isoroy",
            "code" : "EUB"
        },
        {
            "codeUIC" : 175166,
            "codeCH" : "BV",
            "nameUIC" : "Ancerville-Guë",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ACE"
        },
        {
            "codeUIC" : 175166,
            "codeCH" : "E2",
            "nameUIC" : "Ancerville-Guë",
            "nameCH" : "ITE Tréfilunion",
            "code" : "ACE"
        },
        {
            "codeUIC" : 175190,
            "codeCH" : 0,
            "nameUIC" : "Marnaval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175208,
            "codeCH" : 0,
            "nameUIC" : "Villiers-en-Lieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175216,
            "codeCH" : 0,
            "nameUIC" : "St-Eulien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175240,
            "codeCH" : "AB",
            "nameUIC" : "Lérouville",
            "nameCH" : "Aiguille Km 290,4",
            "code" : "LER"
        },
        {
            "codeUIC" : 175240,
            "codeCH" : "AG",
            "nameUIC" : "Lérouville",
            "nameCH" : "Aiguille Km 287,6",
            "code" : "LER"
        },
        {
            "codeUIC" : 175240,
            "codeCH" : "BV",
            "nameUIC" : "Lérouville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LER"
        },
        {
            "codeUIC" : 175240,
            "codeCH" : "FM",
            "nameUIC" : "Lérouville",
            "nameCH" : "Faisceau",
            "code" : "LER"
        },
        {
            "codeUIC" : 175240,
            "codeCH" : "P1",
            "nameUIC" : "Lérouville",
            "nameCH" : "Poste 1",
            "code" : "LER"
        },
        {
            "codeUIC" : 175240,
            "codeCH" : "RM",
            "nameUIC" : "Lérouville",
            "nameCH" : "Raccordement Côté Metz",
            "code" : "LER"
        },
        {
            "codeUIC" : 175240,
            "codeCH" : "RN",
            "nameUIC" : "Lérouville",
            "nameCH" : "Raccordement Côté Nancy",
            "code" : "LER"
        },
        {
            "codeUIC" : 175257,
            "codeCH" : 0,
            "nameUIC" : "Commercy",
            "nameCH" : "",
            "code" : "CCY"
        },
        {
            "codeUIC" : 175281,
            "codeCH" : 0,
            "nameUIC" : "Sorcy",
            "nameCH" : "",
            "code" : "SOR"
        },
        {
            "codeUIC" : 175299,
            "codeCH" : "P1",
            "nameUIC" : "Ernécourt-Loxéville",
            "nameCH" : "Aiguille Km 276,9",
            "code" : ""
        },
        {
            "codeUIC" : 175323,
            "codeCH" : "BV",
            "nameUIC" : "Ligny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LIY"
        },
        {
            "codeUIC" : 175323,
            "codeCH" : "EP",
            "nameUIC" : "Ligny",
            "nameCH" : "ITE Coopalib",
            "code" : "LIY"
        },
        {
            "codeUIC" : 175323,
            "codeCH" : "EV",
            "nameUIC" : "Ligny",
            "nameCH" : "ITE EVOBUS",
            "code" : "LIY"
        },
        {
            "codeUIC" : 175331,
            "codeCH" : 0,
            "nameUIC" : "Givrauval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175364,
            "codeCH" : "BV",
            "nameUIC" : "Menaucourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 175364,
            "codeCH" : "EP",
            "nameUIC" : "Menaucourt",
            "nameCH" : "ITE Houpiez",
            "code" : ""
        },
        {
            "codeUIC" : 175414,
            "codeCH" : "BV",
            "nameUIC" : "Gondrecourt-le-Château",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GON"
        },
        {
            "codeUIC" : 175414,
            "codeCH" : "EP",
            "nameUIC" : "Gondrecourt-le-Château",
            "nameCH" : "ITE Champ céréales",
            "code" : "GON"
        },
        {
            "codeUIC" : 175414,
            "codeCH" : "GV",
            "nameUIC" : "Gondrecourt-le-Château",
            "nameCH" : "Garage Voitures",
            "code" : "GON"
        },
        {
            "codeUIC" : 175414,
            "codeCH" : "PA",
            "nameUIC" : "Gondrecourt-le-Château",
            "nameCH" : "Pancarte Arrêt",
            "code" : "GON"
        },
        {
            "codeUIC" : 175497,
            "codeCH" : 0,
            "nameUIC" : "Laneuville-St-Joire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175604,
            "codeCH" : 3,
            "nameUIC" : "Sampigny",
            "nameCH" : "PN 3",
            "code" : ""
        },
        {
            "codeUIC" : 175612,
            "codeCH" : "EP",
            "nameUIC" : "Les Koeurs",
            "nameCH" : "ITE Zone Industrielle de Han-sur-Meuse",
            "code" : ""
        },
        {
            "codeUIC" : 175612,
            "codeCH" : 5,
            "nameUIC" : "Les Koeurs",
            "nameCH" : "PN 5",
            "code" : ""
        },
        {
            "codeUIC" : 175612,
            "codeCH" : 7,
            "nameUIC" : "Les Koeurs",
            "nameCH" : "PN 7",
            "code" : ""
        },
        {
            "codeUIC" : 175620,
            "codeCH" : "BV",
            "nameUIC" : "St-Mihiel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 175620,
            "codeCH" : 17,
            "nameUIC" : "St-Mihiel",
            "nameCH" : "PN 17",
            "code" : ""
        },
        {
            "codeUIC" : 175653,
            "codeCH" : 0,
            "nameUIC" : "Ancemont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175661,
            "codeCH" : "BV",
            "nameUIC" : "Dugny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DU"
        },
        {
            "codeUIC" : 175661,
            "codeCH" : "EP",
            "nameUIC" : "Dugny",
            "nameCH" : "ITE Carrières",
            "code" : "DU"
        },
        {
            "codeUIC" : 175711,
            "codeCH" : 0,
            "nameUIC" : "Les Islettes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175737,
            "codeCH" : 0,
            "nameUIC" : "Clermont-en-Argonne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175760,
            "codeCH" : 0,
            "nameUIC" : "Baleycourt",
            "nameCH" : "",
            "code" : "BYC"
        },
        {
            "codeUIC" : 175778,
            "codeCH" : "BF",
            "nameUIC" : "Verdun",
            "nameCH" : "Bifurcation L5/L19",
            "code" : "VDN"
        },
        {
            "codeUIC" : 175778,
            "codeCH" : "BV",
            "nameUIC" : "Verdun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VDN"
        },
        {
            "codeUIC" : 175778,
            "codeCH" : "SR",
            "nameUIC" : "Verdun",
            "nameCH" : "Sommet de Rampe",
            "code" : "VDN"
        },
        {
            "codeUIC" : 175786,
            "codeCH" : 0,
            "nameUIC" : "Eix-Abaucourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175794,
            "codeCH" : 0,
            "nameUIC" : "Étain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 175802,
            "codeCH" : 0,
            "nameUIC" : "ITE St-Jean-lès-Buzy",
            "nameCH" : "",
            "code" : "BUZ"
        },
        {
            "codeUIC" : 175810,
            "codeCH" : "BV",
            "nameUIC" : "Rambucourt-Bouconville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 175828,
            "codeCH" : "BV",
            "nameUIC" : "Pagny-sur-Meuse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PYM"
        },
        {
            "codeUIC" : 175828,
            "codeCH" : "E1",
            "nameUIC" : "Pagny-sur-Meuse",
            "nameCH" : "ITE Novacarb E1",
            "code" : "PYM"
        },
        {
            "codeUIC" : 175828,
            "codeCH" : "E2",
            "nameUIC" : "Pagny-sur-Meuse",
            "nameCH" : "ITE Novacarb E2",
            "code" : "PYM"
        },
        {
            "codeUIC" : 175828,
            "codeCH" : "E3",
            "nameUIC" : "Pagny-sur-Meuse",
            "nameCH" : "ITE Solvay E3",
            "code" : "PYM"
        },
        {
            "codeUIC" : 175828,
            "codeCH" : "E4",
            "nameUIC" : "Pagny-sur-Meuse",
            "nameCH" : "ITE Solvay E4",
            "code" : "PYM"
        },
        {
            "codeUIC" : 175828,
            "codeCH" : "FP",
            "nameUIC" : "Pagny-sur-Meuse",
            "nameCH" : "Faisceau Pair",
            "code" : "PYM"
        },
        {
            "codeUIC" : 181016,
            "codeCH" : 0,
            "nameUIC" : "St-Louis-la-Chaussée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181024,
            "codeCH" : 0,
            "nameUIC" : "Hasenrain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181032,
            "codeCH" : "J1",
            "nameUIC" : "Brunstatt",
            "nameCH" : "Jonction Aiguilles 721/720",
            "code" : ""
        },
        {
            "codeUIC" : 181032,
            "codeCH" : 0,
            "nameUIC" : "Brunstatt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181040,
            "codeCH" : 0,
            "nameUIC" : "Flaxlanden",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181057,
            "codeCH" : 0,
            "nameUIC" : "Tagolsheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181065,
            "codeCH" : 0,
            "nameUIC" : "Walheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181073,
            "codeCH" : 0,
            "nameUIC" : "Ballersdorf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181081,
            "codeCH" : 0,
            "nameUIC" : "Valdieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181131,
            "codeCH" : 0,
            "nameUIC" : "Graffenwald",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181172,
            "codeCH" : 0,
            "nameUIC" : "Grunhutte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181180,
            "codeCH" : "XD",
            "nameUIC" : "Chalampé",
            "nameCH" : "Frontière France-Allemagne",
            "code" : ""
        },
        {
            "codeUIC" : 181180,
            "codeCH" : 0,
            "nameUIC" : "Chalampé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181222,
            "codeCH" : 0,
            "nameUIC" : "Colmar-St-Joseph",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181289,
            "codeCH" : "BV",
            "nameUIC" : "Vieux-Thann ZI",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 181289,
            "codeCH" : "IT",
            "nameUIC" : "Vieux-Thann ZI",
            "nameCH" : "ITE Société Braun",
            "code" : ""
        },
        {
            "codeUIC" : 181289,
            "codeCH" : 0,
            "nameUIC" : "Vieux-Thann ZI",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 181552,
            "codeCH" : 0,
            "nameUIC" : "St-Gilles (Haut-Rhin)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "A1",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Aiguille Km 1,4",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "A2",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Aiguille 7b",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "A3",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Aiguille Km 4,4",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "BW",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Bifurcation De Wanne",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "DP",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Dépôt",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "FF",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Faisceau Formation",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "FR",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Faisceau Réception",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "GP",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "G P F",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "PE",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Point E",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "PF",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Poste F",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "P1",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Poste 1",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "RL",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Relais",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : "ZP",
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "ITE Zone Portuaire",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182006,
            "codeCH" : 43,
            "nameUIC" : "Mulhouse-Nord",
            "nameCH" : "Carré 43",
            "code" : "MUN"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "AN",
            "nameUIC" : "Colmar",
            "nameCH" : "Accès Sortie Nord",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "AS",
            "nameUIC" : "Colmar",
            "nameCH" : "Aiguille Sud",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "BF",
            "nameUIC" : "Colmar",
            "nameCH" : "Bifurcation",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "BV",
            "nameUIC" : "Colmar",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "ES",
            "nameUIC" : "Colmar",
            "nameCH" : "Entrée/Sortie Sud",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "EV",
            "nameUIC" : "Colmar",
            "nameCH" : "Évitement",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "FM",
            "nameUIC" : "Colmar",
            "nameCH" : "Faisceau",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "PA",
            "nameUIC" : "Colmar",
            "nameCH" : "Pancarte Arrêt",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "SE",
            "nameUIC" : "Colmar",
            "nameCH" : "Entrée/Sortie V3àV5",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182014,
            "codeCH" : "ZI",
            "nameUIC" : "Colmar",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "CMR"
        },
        {
            "codeUIC" : 182055,
            "codeCH" : "J1",
            "nameUIC" : "Mulhouse-Dornach",
            "nameCH" : "Jonction Aiguille 291",
            "code" : "MD"
        },
        {
            "codeUIC" : 182055,
            "codeCH" : "J2",
            "nameUIC" : "Mulhouse-Dornach",
            "nameCH" : "Jonction Aiguille 723",
            "code" : "MD"
        },
        {
            "codeUIC" : 182055,
            "codeCH" : 0,
            "nameUIC" : "Mulhouse-Dornach",
            "nameCH" : "",
            "code" : "MD"
        },
        {
            "codeUIC" : 182063,
            "codeCH" : "AM",
            "nameUIC" : "Mulhouse-Ville",
            "nameCH" : "Aiguille Voie M",
            "code" : "MUH"
        },
        {
            "codeUIC" : 182063,
            "codeCH" : "AS",
            "nameUIC" : "Mulhouse-Ville",
            "nameCH" : "Aiguille Km 110",
            "code" : "MUH"
        },
        {
            "codeUIC" : 182063,
            "codeCH" : "AT",
            "nameUIC" : "Mulhouse-Ville",
            "nameCH" : "Atelier",
            "code" : "MUH"
        },
        {
            "codeUIC" : 182063,
            "codeCH" : "BV",
            "nameUIC" : "Mulhouse-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MUH"
        },
        {
            "codeUIC" : 182063,
            "codeCH" : "FM",
            "nameUIC" : "Mulhouse-Ville",
            "nameCH" : "Faisceau",
            "code" : "MUH"
        },
        {
            "codeUIC" : 182063,
            "codeCH" : "PD",
            "nameUIC" : "Mulhouse-Ville",
            "nameCH" : "Point D",
            "code" : "MUH"
        },
        {
            "codeUIC" : 182071,
            "codeCH" : "BF",
            "nameUIC" : "Rixheim",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 182071,
            "codeCH" : "BV",
            "nameUIC" : "Rixheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182089,
            "codeCH" : "BV",
            "nameUIC" : "Habsheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182097,
            "codeCH" : 0,
            "nameUIC" : "Schlierbach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182105,
            "codeCH" : "BV",
            "nameUIC" : "Sierentz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182113,
            "codeCH" : 0,
            "nameUIC" : "Bartenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182121,
            "codeCH" : "BV",
            "nameUIC" : "Ste-Croix-en-Plaine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182121,
            "codeCH" : "EP",
            "nameUIC" : "Ste-Croix-en-Plaine",
            "nameCH" : "ITE Sicma",
            "code" : ""
        },
        {
            "codeUIC" : 182121,
            "codeCH" : "SR",
            "nameUIC" : "Ste-Croix-en-Plaine",
            "nameCH" : "Pancarte S",
            "code" : ""
        },
        {
            "codeUIC" : 182121,
            "codeCH" : 14,
            "nameUIC" : "Ste-Croix-en-Plaine",
            "nameCH" : "PN 14Bis",
            "code" : ""
        },
        {
            "codeUIC" : 182121,
            "codeCH" : 16,
            "nameUIC" : "Ste-Croix-en-Plaine",
            "nameCH" : "PN 16",
            "code" : ""
        },
        {
            "codeUIC" : 182139,
            "codeCH" : "BV",
            "nameUIC" : "St-Louis (Haut-Rhin)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SLS"
        },
        {
            "codeUIC" : 182139,
            "codeCH" : "FM",
            "nameUIC" : "St-Louis (Haut-Rhin)",
            "nameCH" : "Faisceau",
            "code" : "SLS"
        },
        {
            "codeUIC" : 182139,
            "codeCH" : "P1",
            "nameUIC" : "St-Louis (Haut-Rhin)",
            "nameCH" : "Aiguille 2",
            "code" : "SLS"
        },
        {
            "codeUIC" : 182139,
            "codeCH" : "P2",
            "nameUIC" : "St-Louis (Haut-Rhin)",
            "nameCH" : "Aiguille 12",
            "code" : "SLS"
        },
        {
            "codeUIC" : 182139,
            "codeCH" : "P3",
            "nameUIC" : "St-Louis (Haut-Rhin)",
            "nameCH" : "Aiguille 23",
            "code" : "SLS"
        },
        {
            "codeUIC" : 182139,
            "codeCH" : "XS",
            "nameUIC" : "St-Louis (Haut-Rhin)",
            "nameCH" : "Frontière France-Suisse",
            "code" : "SLS"
        },
        {
            "codeUIC" : 182154,
            "codeCH" : 0,
            "nameUIC" : "Huningue",
            "nameCH" : "",
            "code" : "HIN"
        },
        {
            "codeUIC" : 182162,
            "codeCH" : 0,
            "nameUIC" : "Niederhergheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182170,
            "codeCH" : 0,
            "nameUIC" : "Oberhergheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182188,
            "codeCH" : 0,
            "nameUIC" : "Oberentzen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182196,
            "codeCH" : 0,
            "nameUIC" : "Zillisheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182204,
            "codeCH" : 0,
            "nameUIC" : "Illfurth",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182212,
            "codeCH" : "BA",
            "nameUIC" : "Meyenheim",
            "nameCH" : "ITE Base aérienne",
            "code" : ""
        },
        {
            "codeUIC" : 182212,
            "codeCH" : "BV",
            "nameUIC" : "Meyenheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182220,
            "codeCH" : "BV",
            "nameUIC" : "Réguisheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182238,
            "codeCH" : "AE",
            "nameUIC" : "Altkirch",
            "nameCH" : "Accès Est",
            "code" : "ALK"
        },
        {
            "codeUIC" : 182238,
            "codeCH" : "AO",
            "nameUIC" : "Altkirch",
            "nameCH" : "Accès Ouest",
            "code" : "ALK"
        },
        {
            "codeUIC" : 182238,
            "codeCH" : "BV",
            "nameUIC" : "Altkirch",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ALK"
        },
        {
            "codeUIC" : 182253,
            "codeCH" : 0,
            "nameUIC" : "Dannemarie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182261,
            "codeCH" : 0,
            "nameUIC" : "St-Hippolyte (Haut-Rhin)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182279,
            "codeCH" : "BV",
            "nameUIC" : "Ribeauvillé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182279,
            "codeCH" : "C1",
            "nameUIC" : "Ribeauvillé",
            "nameCH" : "Carré 405",
            "code" : ""
        },
        {
            "codeUIC" : 182279,
            "codeCH" : "C2",
            "nameUIC" : "Ribeauvillé",
            "nameCH" : "Carré 408",
            "code" : ""
        },
        {
            "codeUIC" : 182279,
            "codeCH" : "1B",
            "nameUIC" : "Ribeauvillé",
            "nameCH" : "Évitement Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 182279,
            "codeCH" : "2B",
            "nameUIC" : "Ribeauvillé",
            "nameCH" : "Évitement Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 182295,
            "codeCH" : 0,
            "nameUIC" : "Ostheim-Beblenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182303,
            "codeCH" : "BV",
            "nameUIC" : "Bennwihr",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182329,
            "codeCH" : 0,
            "nameUIC" : "Herrlisheim-près-Colmar",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182337,
            "codeCH" : "BV",
            "nameUIC" : "Rouffach",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182337,
            "codeCH" : "C1",
            "nameUIC" : "Rouffach",
            "nameCH" : "Carré 573",
            "code" : ""
        },
        {
            "codeUIC" : 182337,
            "codeCH" : "C2",
            "nameUIC" : "Rouffach",
            "nameCH" : "Carré 576",
            "code" : ""
        },
        {
            "codeUIC" : 182337,
            "codeCH" : "V3",
            "nameUIC" : "Rouffach",
            "nameCH" : "Évitement Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 182337,
            "codeCH" : "V4",
            "nameUIC" : "Rouffach",
            "nameCH" : "Évitement Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 182345,
            "codeCH" : 0,
            "nameUIC" : "Logelbach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182352,
            "codeCH" : 0,
            "nameUIC" : "Turckheim",
            "nameCH" : "",
            "code" : "TKM"
        },
        {
            "codeUIC" : 182360,
            "codeCH" : 0,
            "nameUIC" : "Walbach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182378,
            "codeCH" : 0,
            "nameUIC" : "Wihr-au-Val-Soultzbach-les-Bai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182386,
            "codeCH" : 0,
            "nameUIC" : "Gunsbach-Griesbach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182394,
            "codeCH" : 0,
            "nameUIC" : "Munster",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182402,
            "codeCH" : 0,
            "nameUIC" : "Ranspach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182410,
            "codeCH" : "BV",
            "nameUIC" : "Cernay (Haut-Rhin)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CER"
        },
        {
            "codeUIC" : 182410,
            "codeCH" : "EP",
            "nameUIC" : "Cernay (Haut-Rhin)",
            "nameCH" : "ITE Ville de Cernay",
            "code" : "CER"
        },
        {
            "codeUIC" : 182543,
            "codeCH" : 0,
            "nameUIC" : "Luttenbach-près-Munster",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182550,
            "codeCH" : 0,
            "nameUIC" : "Vieux-Thann",
            "nameCH" : "",
            "code" : "VTH"
        },
        {
            "codeUIC" : 182568,
            "codeCH" : "BV",
            "nameUIC" : "Thann",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "THA"
        },
        {
            "codeUIC" : 182576,
            "codeCH" : 0,
            "nameUIC" : "Bitschwiller (Haut-Rhin)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182584,
            "codeCH" : 0,
            "nameUIC" : "Willer-sur-Thur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182592,
            "codeCH" : 0,
            "nameUIC" : "Moosch",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182600,
            "codeCH" : 0,
            "nameUIC" : "St-Amarin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182618,
            "codeCH" : 0,
            "nameUIC" : "Wesserling",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182626,
            "codeCH" : 0,
            "nameUIC" : "Fellering",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182634,
            "codeCH" : 0,
            "nameUIC" : "Oderen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182642,
            "codeCH" : 0,
            "nameUIC" : "Kruth",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182659,
            "codeCH" : 0,
            "nameUIC" : "Breitenbach-Haut-Rhin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182667,
            "codeCH" : "BF",
            "nameUIC" : "Lutterbach (Haut-Rhin)",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 182667,
            "codeCH" : "BV",
            "nameUIC" : "Lutterbach (Haut-Rhin)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182667,
            "codeCH" : "TI",
            "nameUIC" : "Lutterbach (Haut-Rhin)",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 182675,
            "codeCH" : "FP",
            "nameUIC" : "Richwiller",
            "nameCH" : "Faisceau Pair",
            "code" : "RWL"
        },
        {
            "codeUIC" : 182675,
            "codeCH" : 0,
            "nameUIC" : "Richwiller",
            "nameCH" : "",
            "code" : "RWL"
        },
        {
            "codeUIC" : 182683,
            "codeCH" : "BV",
            "nameUIC" : "Wittelsheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182691,
            "codeCH" : 0,
            "nameUIC" : "Staffelfelden",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182709,
            "codeCH" : "BV",
            "nameUIC" : "Bollwiller",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 182709,
            "codeCH" : "EV",
            "nameUIC" : "Bollwiller",
            "nameCH" : "Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 182717,
            "codeCH" : 0,
            "nameUIC" : "Raedersheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182725,
            "codeCH" : 0,
            "nameUIC" : "Merxheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182733,
            "codeCH" : 0,
            "nameUIC" : "Muhlbach-sur-Munster",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182816,
            "codeCH" : 0,
            "nameUIC" : "Metzeral",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182824,
            "codeCH" : "A1",
            "nameUIC" : "Île-Napoléon-garage",
            "nameCH" : "Aiguille Km 6,1",
            "code" : "ILG"
        },
        {
            "codeUIC" : 182824,
            "codeCH" : "A2",
            "nameUIC" : "Île-Napoléon-garage",
            "nameCH" : "Aiguille Km 7,4",
            "code" : "ILG"
        },
        {
            "codeUIC" : 182824,
            "codeCH" : "BV",
            "nameUIC" : "Île-Napoléon-garage",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ILG"
        },
        {
            "codeUIC" : 182824,
            "codeCH" : "EP",
            "nameUIC" : "Île-Napoléon-garage",
            "nameCH" : "ITE Peugeot",
            "code" : "ILG"
        },
        {
            "codeUIC" : 182824,
            "codeCH" : "ES",
            "nameUIC" : "Île-Napoléon-garage",
            "nameCH" : "ITE Sogina",
            "code" : "ILG"
        },
        {
            "codeUIC" : 182824,
            "codeCH" : "PU",
            "nameUIC" : "Île-Napoléon-garage",
            "nameCH" : "Poste",
            "code" : "ILG"
        },
        {
            "codeUIC" : 182840,
            "codeCH" : "BF",
            "nameUIC" : "Bantzenheim",
            "nameCH" : "Bifurcation L32-7/32-9",
            "code" : "BTZ"
        },
        {
            "codeUIC" : 182840,
            "codeCH" : "BV",
            "nameUIC" : "Bantzenheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BTZ"
        },
        {
            "codeUIC" : 182840,
            "codeCH" : 0,
            "nameUIC" : "Bantzenheim",
            "nameCH" : "",
            "code" : "BTZ"
        },
        {
            "codeUIC" : 182865,
            "codeCH" : "EF",
            "nameUIC" : "Blodelsheim",
            "nameCH" : "ITE EDF",
            "code" : "BDL"
        },
        {
            "codeUIC" : 182873,
            "codeCH" : 0,
            "nameUIC" : "Sundhoffen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182881,
            "codeCH" : "TI",
            "nameUIC" : "Thann-St-Jacques",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 182881,
            "codeCH" : 0,
            "nameUIC" : "Thann-St-Jacques",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182899,
            "codeCH" : 0,
            "nameUIC" : "Neuf-Brisach",
            "nameCH" : "",
            "code" : "NEB"
        },
        {
            "codeUIC" : 182915,
            "codeCH" : "WD",
            "nameUIC" : "Neuenburg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 182964,
            "codeCH" : 0,
            "nameUIC" : "Mulhouse-Musées",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "AG",
            "nameUIC" : "Belfort",
            "nameCH" : "Aiguille Accès BV/TR",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "AN",
            "nameUIC" : "Belfort",
            "nameCH" : "Accès Nord",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "AS",
            "nameUIC" : "Belfort",
            "nameCH" : "Accès Sud",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "BV",
            "nameUIC" : "Belfort",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "DJ",
            "nameUIC" : "Belfort",
            "nameCH" : "Bifurcation de Danjoutin",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "DP",
            "nameUIC" : "Belfort",
            "nameCH" : "Dépôt",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "RE",
            "nameUIC" : "Belfort",
            "nameCH" : "Raccordement Est",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "RS",
            "nameUIC" : "Belfort",
            "nameCH" : "Raccordement Sud",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : "TR",
            "nameUIC" : "Belfort",
            "nameCH" : "Triage",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184002,
            "codeCH" : 51,
            "nameUIC" : "Belfort",
            "nameCH" : "ITE Alsthom",
            "code" : "BFT"
        },
        {
            "codeUIC" : 184010,
            "codeCH" : 0,
            "nameUIC" : "Bif L34-8/L38",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184101,
            "codeCH" : 0,
            "nameUIC" : "ITE Société Weber Industrie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184200,
            "codeCH" : 0,
            "nameUIC" : "Bas-Évette",
            "nameCH" : "",
            "code" : "BAE"
        },
        {
            "codeUIC" : 184218,
            "codeCH" : 0,
            "nameUIC" : "Valdoie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184242,
            "codeCH" : 0,
            "nameUIC" : "Trois-Chênes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184259,
            "codeCH" : 0,
            "nameUIC" : "Chèvremont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184267,
            "codeCH" : "PK",
            "nameUIC" : "Petit-Croix",
            "nameCH" : "Km 0 LGV",
            "code" : ""
        },
        {
            "codeUIC" : 184267,
            "codeCH" : "RP",
            "nameUIC" : "Petit-Croix",
            "nameCH" : "Raccordement",
            "code" : ""
        },
        {
            "codeUIC" : 184267,
            "codeCH" : 0,
            "nameUIC" : "Petit-Croix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184275,
            "codeCH" : "BV",
            "nameUIC" : "Montreux-Vieux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 184275,
            "codeCH" : "FI",
            "nameUIC" : "Montreux-Vieux",
            "nameCH" : "Faisceau Impair",
            "code" : ""
        },
        {
            "codeUIC" : 184317,
            "codeCH" : "BV",
            "nameUIC" : "Giromagny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GY"
        },
        {
            "codeUIC" : 184358,
            "codeCH" : "BV",
            "nameUIC" : "Audincourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 184358,
            "codeCH" : "EP",
            "nameUIC" : "Audincourt",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 184358,
            "codeCH" : "E1",
            "nameUIC" : "Audincourt",
            "nameCH" : "ITE SED",
            "code" : ""
        },
        {
            "codeUIC" : 184358,
            "codeCH" : "E2",
            "nameUIC" : "Audincourt",
            "nameCH" : "ITE Capem",
            "code" : ""
        },
        {
            "codeUIC" : 184366,
            "codeCH" : "BV",
            "nameUIC" : "Beaucourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 184366,
            "codeCH" : "EP",
            "nameUIC" : "Beaucourt",
            "nameCH" : "Accès Voie 4",
            "code" : ""
        },
        {
            "codeUIC" : 184374,
            "codeCH" : 0,
            "nameUIC" : "Fesches-le-Châtel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184408,
            "codeCH" : 0,
            "nameUIC" : "Meroux",
            "nameCH" : "",
            "code" : "MUX"
        },
        {
            "codeUIC" : 184424,
            "codeCH" : "BV",
            "nameUIC" : "Morvillars",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MOV"
        },
        {
            "codeUIC" : 184424,
            "codeCH" : "EP",
            "nameUIC" : "Morvillars",
            "nameCH" : "ITE Vernerey",
            "code" : "MOV"
        },
        {
            "codeUIC" : 184424,
            "codeCH" : 0,
            "nameUIC" : "Morvillars",
            "nameCH" : "",
            "code" : "MOV"
        },
        {
            "codeUIC" : 184432,
            "codeCH" : 0,
            "nameUIC" : "Grandvillars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184440,
            "codeCH" : "BV",
            "nameUIC" : "Delle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 184440,
            "codeCH" : "EP",
            "nameUIC" : "Delle",
            "nameCH" : "ITE Le Fil Isolé Moderne",
            "code" : ""
        },
        {
            "codeUIC" : 184440,
            "codeCH" : "XS",
            "nameUIC" : "Delle",
            "nameCH" : "Frontière France-Suisse",
            "code" : ""
        },
        {
            "codeUIC" : 184507,
            "codeCH" : "BV",
            "nameUIC" : "Héricourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HCT"
        },
        {
            "codeUIC" : 184507,
            "codeCH" : "EP",
            "nameUIC" : "Héricourt",
            "nameCH" : "ITE Kern",
            "code" : "HCT"
        },
        {
            "codeUIC" : 184523,
            "codeCH" : "BF",
            "nameUIC" : "Montbéliard",
            "nameCH" : "Bifurcation L28/28-4",
            "code" : "MBD"
        },
        {
            "codeUIC" : 184523,
            "codeCH" : "BV",
            "nameUIC" : "Montbéliard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MBD"
        },
        {
            "codeUIC" : 184523,
            "codeCH" : "EV",
            "nameUIC" : "Montbéliard",
            "nameCH" : "Évitement",
            "code" : "MBD"
        },
        {
            "codeUIC" : 184523,
            "codeCH" : "FP",
            "nameUIC" : "Montbéliard",
            "nameCH" : "FP",
            "code" : "MBD"
        },
        {
            "codeUIC" : 184523,
            "codeCH" : "JC",
            "nameUIC" : "Montbéliard",
            "nameCH" : "JC",
            "code" : "MBD"
        },
        {
            "codeUIC" : 184531,
            "codeCH" : "BF",
            "nameUIC" : "Voujeaucourt",
            "nameCH" : "Bifurcation L28/28-6",
            "code" : ""
        },
        {
            "codeUIC" : 184531,
            "codeCH" : "BV",
            "nameUIC" : "Voujeaucourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 184556,
            "codeCH" : 0,
            "nameUIC" : "Colombier-Fontaine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184564,
            "codeCH" : 0,
            "nameUIC" : "St-Maurice (Doubs)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184572,
            "codeCH" : 0,
            "nameUIC" : "L'Isle-sur-le-Doubs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184580,
            "codeCH" : 0,
            "nameUIC" : "Rang",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184622,
            "codeCH" : "BV",
            "nameUIC" : "Clerval",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 184622,
            "codeCH" : "EV",
            "nameUIC" : "Clerval",
            "nameCH" : "Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 184804,
            "codeCH" : 0,
            "nameUIC" : "Mathay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 184820,
            "codeCH" : "BV",
            "nameUIC" : "Pont-de-Roide",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PDR"
        },
        {
            "codeUIC" : 184820,
            "codeCH" : "EP",
            "nameUIC" : "Pont-de-Roide",
            "nameCH" : "ITE Ugine SA",
            "code" : "PDR"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : "AG",
            "nameUIC" : "Vesoul",
            "nameCH" : "Aiguille Km 380",
            "code" : "VES"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : "BV",
            "nameUIC" : "Vesoul",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VES"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : "FM",
            "nameUIC" : "Vesoul",
            "nameCH" : "Faisceau",
            "code" : "VES"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : "PC",
            "nameUIC" : "Vesoul",
            "nameCH" : "Poste C",
            "code" : "VES"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : "PL",
            "nameUIC" : "Vesoul",
            "nameCH" : "Carré 15",
            "code" : "VES"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : 18,
            "nameUIC" : "Vesoul",
            "nameCH" : "Carré 18",
            "code" : "VES"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : 45,
            "nameUIC" : "Vesoul",
            "nameCH" : "Carré 145",
            "code" : "VES"
        },
        {
            "codeUIC" : 185009,
            "codeCH" : 52,
            "nameUIC" : "Vesoul",
            "nameCH" : "Carré 152",
            "code" : "VES"
        },
        {
            "codeUIC" : 185108,
            "codeCH" : "BV",
            "nameUIC" : "Port-d'Atelier-Amance",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PA"
        },
        {
            "codeUIC" : 185108,
            "codeCH" : "PL",
            "nameUIC" : "Port-d'Atelier-Amance",
            "nameCH" : "Carré 3",
            "code" : "PA"
        },
        {
            "codeUIC" : 185108,
            "codeCH" : "S1",
            "nameUIC" : "Port-d'Atelier-Amance",
            "nameCH" : "Sémaphore 19",
            "code" : "PA"
        },
        {
            "codeUIC" : 185108,
            "codeCH" : "S2",
            "nameUIC" : "Port-d'Atelier-Amance",
            "nameCH" : "Sémaphore 2",
            "code" : "PA"
        },
        {
            "codeUIC" : 185108,
            "codeCH" : 16,
            "nameUIC" : "Port-d'Atelier-Amance",
            "nameCH" : "Carré 16",
            "code" : "PA"
        },
        {
            "codeUIC" : 185165,
            "codeCH" : 0,
            "nameUIC" : "Vaivre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185207,
            "codeCH" : 0,
            "nameUIC" : "Colombier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185215,
            "codeCH" : 0,
            "nameUIC" : "Creveney-Saulx",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185231,
            "codeCH" : 0,
            "nameUIC" : "Genevreuille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185249,
            "codeCH" : "BF",
            "nameUIC" : "Lure",
            "nameCH" : "Bifurcation L4/L16",
            "code" : "LUR"
        },
        {
            "codeUIC" : 185249,
            "codeCH" : "BV",
            "nameUIC" : "Lure",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LUR"
        },
        {
            "codeUIC" : 185249,
            "codeCH" : "PA",
            "nameUIC" : "Lure",
            "nameCH" : "Pancarte Arrêt",
            "code" : "LUR"
        },
        {
            "codeUIC" : 185280,
            "codeCH" : 0,
            "nameUIC" : "Montureux-lès-Baulay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185306,
            "codeCH" : 0,
            "nameUIC" : "Conflans-Varigney",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185314,
            "codeCH" : 0,
            "nameUIC" : "Ronchamp",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185330,
            "codeCH" : 0,
            "nameUIC" : "St-Loup",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185348,
            "codeCH" : 0,
            "nameUIC" : "Champagney",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185355,
            "codeCH" : "BV",
            "nameUIC" : "Jussey",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JUS"
        },
        {
            "codeUIC" : 185355,
            "codeCH" : 0,
            "nameUIC" : "Jussey",
            "nameCH" : "",
            "code" : "JUS"
        },
        {
            "codeUIC" : 185405,
            "codeCH" : 0,
            "nameUIC" : "Mont-le-Vernois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185447,
            "codeCH" : 0,
            "nameUIC" : "Noidans-le-Ferroux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185488,
            "codeCH" : 0,
            "nameUIC" : "Vellexon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185512,
            "codeCH" : 0,
            "nameUIC" : "Seveux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185538,
            "codeCH" : 0,
            "nameUIC" : "Autet",
            "nameCH" : "",
            "code" : "AUT"
        },
        {
            "codeUIC" : 185579,
            "codeCH" : "BV",
            "nameUIC" : "Gray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GR"
        },
        {
            "codeUIC" : 185579,
            "codeCH" : "JD",
            "nameUIC" : "Gray",
            "nameCH" : "Jonction",
            "code" : "GR"
        },
        {
            "codeUIC" : 185579,
            "codeCH" : "SM",
            "nameUIC" : "Gray",
            "nameCH" : "ITE Interval",
            "code" : "GR"
        },
        {
            "codeUIC" : 185579,
            "codeCH" : "ZI",
            "nameUIC" : "Gray",
            "nameCH" : "ITE ZI",
            "code" : "GR"
        },
        {
            "codeUIC" : 185579,
            "codeCH" : 0,
            "nameUIC" : "Gray",
            "nameCH" : "",
            "code" : "GR"
        },
        {
            "codeUIC" : 185579,
            "codeCH" : 24,
            "nameUIC" : "Gray",
            "nameCH" : "PN 24",
            "code" : "GR"
        },
        {
            "codeUIC" : 185579,
            "codeCH" : 25,
            "nameUIC" : "Gray",
            "nameCH" : "PN 25",
            "code" : "GR"
        },
        {
            "codeUIC" : 185595,
            "codeCH" : "BV",
            "nameUIC" : "Luxeuil-les-Bains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 185629,
            "codeCH" : "BF",
            "nameUIC" : "Aillevillers",
            "nameCH" : "Bifurcation L16/L16 5",
            "code" : "AI"
        },
        {
            "codeUIC" : 185629,
            "codeCH" : "BV",
            "nameUIC" : "Aillevillers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AI"
        },
        {
            "codeUIC" : 185629,
            "codeCH" : "EG",
            "nameUIC" : "Aillevillers",
            "nameCH" : "ITE Société Genet",
            "code" : "AI"
        },
        {
            "codeUIC" : 185629,
            "codeCH" : "EP",
            "nameUIC" : "Aillevillers",
            "nameCH" : "ITE Etablissement Parisot",
            "code" : "AI"
        },
        {
            "codeUIC" : 185629,
            "codeCH" : "PA",
            "nameUIC" : "Aillevillers",
            "nameCH" : "Pancarte Arrêt",
            "code" : "AI"
        },
        {
            "codeUIC" : 185629,
            "codeCH" : 3,
            "nameUIC" : "Aillevillers",
            "nameCH" : "PN 3",
            "code" : "AI"
        },
        {
            "codeUIC" : 185629,
            "codeCH" : 7,
            "nameUIC" : "Aillevillers",
            "nameCH" : "PN 7",
            "code" : "AI"
        },
        {
            "codeUIC" : 185629,
            "codeCH" : 8,
            "nameUIC" : "Aillevillers",
            "nameCH" : "PN 8",
            "code" : "AI"
        },
        {
            "codeUIC" : 185645,
            "codeCH" : 0,
            "nameUIC" : "Mantoche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185660,
            "codeCH" : 0,
            "nameUIC" : "Faymont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 185678,
            "codeCH" : "BV",
            "nameUIC" : "Le Val-d'Ajol",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 185678,
            "codeCH" : 23,
            "nameUIC" : "Le Val-d'Ajol",
            "nameCH" : "PN 23",
            "code" : ""
        },
        {
            "codeUIC" : 185678,
            "codeCH" : 24,
            "nameUIC" : "Le Val-d'Ajol",
            "nameCH" : "PN 24",
            "code" : ""
        },
        {
            "codeUIC" : 185678,
            "codeCH" : 26,
            "nameUIC" : "Le Val-d'Ajol",
            "nameCH" : "PN 26",
            "code" : ""
        },
        {
            "codeUIC" : 185678,
            "codeCH" : 27,
            "nameUIC" : "Le Val-d'Ajol",
            "nameCH" : "PN 27",
            "code" : ""
        },
        {
            "codeUIC" : 185678,
            "codeCH" : 28,
            "nameUIC" : "Le Val-d'Ajol",
            "nameCH" : "PN 28",
            "code" : ""
        },
        {
            "codeUIC" : 185686,
            "codeCH" : "BV",
            "nameUIC" : "Fougerolles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FGS"
        },
        {
            "codeUIC" : 185686,
            "codeCH" : "EP",
            "nameUIC" : "Fougerolles",
            "nameCH" : "ITE Etablissement Ougier",
            "code" : "FGS"
        },
        {
            "codeUIC" : 185686,
            "codeCH" : 12,
            "nameUIC" : "Fougerolles",
            "nameCH" : "PN 12",
            "code" : "FGS"
        },
        {
            "codeUIC" : 185686,
            "codeCH" : 14,
            "nameUIC" : "Fougerolles",
            "nameCH" : "PN 14",
            "code" : "FGS"
        },
        {
            "codeUIC" : 185686,
            "codeCH" : 15,
            "nameUIC" : "Fougerolles",
            "nameCH" : "PN 15",
            "code" : "FGS"
        },
        {
            "codeUIC" : 185686,
            "codeCH" : 17,
            "nameUIC" : "Fougerolles",
            "nameCH" : "PN 17",
            "code" : "FGS"
        },
        {
            "codeUIC" : 185686,
            "codeCH" : 20,
            "nameUIC" : "Fougerolles",
            "nameCH" : "PN 20",
            "code" : "FGS"
        },
        {
            "codeUIC" : 185751,
            "codeCH" : "BV",
            "nameUIC" : "Loulans-Les Forges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 185835,
            "codeCH" : "BT",
            "nameUIC" : "Villersexel",
            "nameCH" : "Base travaux",
            "code" : ""
        },
        {
            "codeUIC" : 187914,
            "codeCH" : "WS",
            "nameUIC" : "Bâle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 187930,
            "codeCH" : "WS",
            "nameUIC" : "Bâle-St-Jean",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "AP",
            "nameUIC" : "Thionville",
            "nameCH" : "ITE Camifemo",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "BF",
            "nameUIC" : "Thionville",
            "nameCH" : "Bifurcation Raccordement L7",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "BV",
            "nameUIC" : "Thionville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "DP",
            "nameUIC" : "Thionville",
            "nameCH" : "Dépôt",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "EF",
            "nameUIC" : "Thionville",
            "nameCH" : "ITE EDF/Cattenom",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "FL",
            "nameUIC" : "Thionville",
            "nameCH" : "Faisceau Luxembourg",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "FM",
            "nameUIC" : "Thionville",
            "nameCH" : "Faisceau Est",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "L7",
            "nameUIC" : "Thionville",
            "nameCH" : "Bifurcation L3/L7",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "P2",
            "nameUIC" : "Thionville",
            "nameCH" : "Ac-Nord FSC Lux",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "P3",
            "nameUIC" : "Thionville",
            "nameCH" : "Poste B",
            "code" : "THL"
        },
        {
            "codeUIC" : 191007,
            "codeCH" : "YU",
            "nameUIC" : "Thionville",
            "nameCH" : "ITE Yutz",
            "code" : "THL"
        },
        {
            "codeUIC" : 191015,
            "codeCH" : "AN",
            "nameUIC" : "Ébange",
            "nameCH" : "Accès-Nord",
            "code" : ""
        },
        {
            "codeUIC" : 191015,
            "codeCH" : "AS",
            "nameUIC" : "Ébange",
            "nameCH" : "Accès Sud",
            "code" : ""
        },
        {
            "codeUIC" : 191015,
            "codeCH" : "FM",
            "nameUIC" : "Ébange",
            "nameCH" : "Faisceau",
            "code" : ""
        },
        {
            "codeUIC" : 191015,
            "codeCH" : "JN",
            "nameUIC" : "Ébange",
            "nameCH" : "Jonction Nord",
            "code" : ""
        },
        {
            "codeUIC" : 191015,
            "codeCH" : 90,
            "nameUIC" : "Ébange",
            "nameCH" : "Aiguille VL90",
            "code" : ""
        },
        {
            "codeUIC" : 191098,
            "codeCH" : 0,
            "nameUIC" : "Walibi-Lorraine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191106,
            "codeCH" : "BV",
            "nameUIC" : "Maizières-lès-Metz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "AP",
            "nameUIC" : "Hagondange",
            "nameCH" : "Aiguille ITE",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "BM",
            "nameUIC" : "Hagondange",
            "nameCH" : "Bifurcation Mondelange",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "BV",
            "nameUIC" : "Hagondange",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "EP",
            "nameUIC" : "Hagondange",
            "nameCH" : "ITE Sacilor",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "ER",
            "nameUIC" : "Hagondange",
            "nameCH" : "ITE Richemont",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "FM",
            "nameUIC" : "Hagondange",
            "nameCH" : "Faisceau",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "P3",
            "nameUIC" : "Hagondange",
            "nameCH" : "Poste 3",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191114,
            "codeCH" : "RM",
            "nameUIC" : "Hagondange",
            "nameCH" : "Raccordement Mondelange",
            "code" : "HGD"
        },
        {
            "codeUIC" : 191130,
            "codeCH" : "BV",
            "nameUIC" : "Uckange",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "UCK"
        },
        {
            "codeUIC" : 191130,
            "codeCH" : "FM",
            "nameUIC" : "Uckange",
            "nameCH" : "Faisceau",
            "code" : "UCK"
        },
        {
            "codeUIC" : 191130,
            "codeCH" : "KM",
            "nameUIC" : "Uckange",
            "nameCH" : "Anomalie Km 181,3",
            "code" : "UCK"
        },
        {
            "codeUIC" : 191130,
            "codeCH" : "P1",
            "nameUIC" : "Uckange",
            "nameCH" : "Poste 1",
            "code" : "UCK"
        },
        {
            "codeUIC" : 191130,
            "codeCH" : "P2",
            "nameUIC" : "Uckange",
            "nameCH" : "Poste 2",
            "code" : "UCK"
        },
        {
            "codeUIC" : 191163,
            "codeCH" : 0,
            "nameUIC" : "Hettange-Grande",
            "nameCH" : "",
            "code" : "HET"
        },
        {
            "codeUIC" : 191213,
            "codeCH" : 0,
            "nameUIC" : "Knutange-Nilvange",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191239,
            "codeCH" : 0,
            "nameUIC" : "Hayange",
            "nameCH" : "",
            "code" : "HAY"
        },
        {
            "codeUIC" : 191247,
            "codeCH" : "CA",
            "nameUIC" : "Florange",
            "nameCH" : "ITE Camifemo",
            "code" : "FLG"
        },
        {
            "codeUIC" : 191304,
            "codeCH" : 0,
            "nameUIC" : "Yutz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191312,
            "codeCH" : 0,
            "nameUIC" : "Kuntzig",
            "nameCH" : "",
            "code" : "KZG"
        },
        {
            "codeUIC" : 191320,
            "codeCH" : 0,
            "nameUIC" : "Distroff",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191338,
            "codeCH" : 0,
            "nameUIC" : "Metzervisse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191346,
            "codeCH" : 0,
            "nameUIC" : "Kédange",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191353,
            "codeCH" : 0,
            "nameUIC" : "Hombourg-Budange",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191379,
            "codeCH" : 0,
            "nameUIC" : "Ébersviller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191387,
            "codeCH" : 0,
            "nameUIC" : "Anzeling",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191395,
            "codeCH" : 0,
            "nameUIC" : "Freistroff",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191460,
            "codeCH" : 0,
            "nameUIC" : "Sarreguemines-Est",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191601,
            "codeCH" : 0,
            "nameUIC" : "Basse-Ham",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191619,
            "codeCH" : 0,
            "nameUIC" : "Koenigsmacker",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191627,
            "codeCH" : 0,
            "nameUIC" : "Malling",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191635,
            "codeCH" : 0,
            "nameUIC" : "Sierck-les-Bains",
            "nameCH" : "",
            "code" : "SIK"
        },
        {
            "codeUIC" : 191643,
            "codeCH" : "XD",
            "nameUIC" : "Apach (Moselle)",
            "nameCH" : "Frontière France-Allemagne",
            "code" : "AP"
        },
        {
            "codeUIC" : 191643,
            "codeCH" : 0,
            "nameUIC" : "Apach (Moselle)",
            "nameCH" : "",
            "code" : "AP"
        },
        {
            "codeUIC" : 191684,
            "codeCH" : "BV",
            "nameUIC" : "Auboué",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ABE"
        },
        {
            "codeUIC" : 191692,
            "codeCH" : 0,
            "nameUIC" : "Homécourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191700,
            "codeCH" : 0,
            "nameUIC" : "Joeuf",
            "nameCH" : "",
            "code" : "JOF"
        },
        {
            "codeUIC" : 191718,
            "codeCH" : 0,
            "nameUIC" : "Moyeuvre-Grande",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191734,
            "codeCH" : 0,
            "nameUIC" : "Rombas-Clouange",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 191742,
            "codeCH" : 0,
            "nameUIC" : "Gandrange-Amnéville",
            "nameCH" : "",
            "code" : "GDG"
        },
        {
            "codeUIC" : 191973,
            "codeCH" : "XL",
            "nameUIC" : "Zoufftgen (IE)",
            "nameCH" : "Frontière France-Luxembourg",
            "code" : "ZOU"
        },
        {
            "codeUIC" : 191973,
            "codeCH" : 0,
            "nameUIC" : "Zoufftgen (IE)",
            "nameCH" : "",
            "code" : "ZOU"
        },
        {
            "codeUIC" : 191999,
            "codeCH" : "WL",
            "nameUIC" : "Bettembourg",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 191999,
            "codeCH" : "W3",
            "nameUIC" : "Bettembourg",
            "nameCH" : "Accès Nord Faisceau Marchandises",
            "code" : ""
        },
        {
            "codeUIC" : 191999,
            "codeCH" : "W4",
            "nameUIC" : "Bettembourg",
            "nameCH" : "Accès sud Faisceau Marchandise",
            "code" : ""
        },
        {
            "codeUIC" : 191999,
            "codeCH" : "W5",
            "nameUIC" : "Bettembourg",
            "nameCH" : "Faisceau Marchandises",
            "code" : ""
        },
        {
            "codeUIC" : 192005,
            "codeCH" : "FM",
            "nameUIC" : "Metz-Marchandises",
            "nameCH" : "Faisceau",
            "code" : "MZM"
        },
        {
            "codeUIC" : 192005,
            "codeCH" : "PU",
            "nameUIC" : "Metz-Marchandises",
            "nameCH" : "Poste 1",
            "code" : "MZM"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "AM",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Aiguille VM",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "BV",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "C4",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Carré 104",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "DP",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Dépôt",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "F1",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Faisceau F1",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "QE",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Quai Est",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "TN",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Poste 66 Centre Technique Nord 1",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192039,
            "codeCH" : "TS",
            "nameUIC" : "Metz-Ville",
            "nameCH" : "Poste 66 Centre Technique Sud 2",
            "code" : "MZ"
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "AE",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Jonction V2/V2R",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "AO",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Bifurcation L3/L11",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "AS",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Aiguille Km 150,6",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "FA",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "FA",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "FM",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Faisceau",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "PK",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Repère Kilométrique",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "P1",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "P2",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "YA",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Montigny Accès Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "YF",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Faisceau Montigny",
            "code" : ""
        },
        {
            "codeUIC" : 192047,
            "codeCH" : "YT",
            "nameUIC" : "Metz-Sablon",
            "nameCH" : "Montigny AT",
            "code" : ""
        },
        {
            "codeUIC" : 192054,
            "codeCH" : 0,
            "nameUIC" : "Metz-devant-les-Ponts",
            "nameCH" : "",
            "code" : "MDP"
        },
        {
            "codeUIC" : 192062,
            "codeCH" : 0,
            "nameUIC" : "Metz-Chambière",
            "nameCH" : "",
            "code" : "MZC"
        },
        {
            "codeUIC" : 192070,
            "codeCH" : "BV",
            "nameUIC" : "Metz-Nord",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192070,
            "codeCH" : "NP",
            "nameUIC" : "Metz-Nord",
            "nameCH" : "Nouveau Port",
            "code" : ""
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "AM",
            "nameUIC" : "Woippy",
            "nameCH" : "Aiguille V1M",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "AN",
            "nameUIC" : "Woippy",
            "nameCH" : "Accès-Nord",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "AP",
            "nameUIC" : "Woippy",
            "nameCH" : "Aiguille ITE",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "AS",
            "nameUIC" : "Woippy",
            "nameCH" : "Accès Sud",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "BV",
            "nameUIC" : "Woippy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "DB",
            "nameUIC" : "Woippy",
            "nameCH" : "Faisceau Début",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "DT",
            "nameUIC" : "Woippy",
            "nameCH" : "Dépôt",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "EF",
            "nameUIC" : "Woippy",
            "nameCH" : "ITE EDF",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "EZ",
            "nameUIC" : "Woippy",
            "nameCH" : "ITE Zone Activité Ennery",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "FA",
            "nameUIC" : "Woippy",
            "nameCH" : "Faisceau Arrivée",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "FD",
            "nameUIC" : "Woippy",
            "nameCH" : "Faisceau Départ",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "MF",
            "nameUIC" : "Woippy",
            "nameCH" : "ITE Maizière",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "PN",
            "nameUIC" : "Woippy",
            "nameCH" : "PN 1",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "PP",
            "nameUIC" : "Woippy",
            "nameCH" : "Poste P",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "RH",
            "nameUIC" : "Woippy",
            "nameCH" : "ITE Aslim",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : "RL",
            "nameUIC" : "Woippy",
            "nameCH" : "Faisceau Relais",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : 63,
            "nameUIC" : "Woippy",
            "nameCH" : "Aiguille Km 163,0",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : 64,
            "nameUIC" : "Woippy",
            "nameCH" : "Aiguille Km 164,0",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : 65,
            "nameUIC" : "Woippy",
            "nameCH" : "Aiguille Km 165,3",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : 88,
            "nameUIC" : "Woippy",
            "nameCH" : "Carré Violet 188",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192088,
            "codeCH" : 94,
            "nameUIC" : "Woippy",
            "nameCH" : "Carré Violet 194",
            "code" : "WPY"
        },
        {
            "codeUIC" : 192203,
            "codeCH" : "AG",
            "nameUIC" : "Peltre",
            "nameCH" : "Aiguille Est Voies Bis",
            "code" : ""
        },
        {
            "codeUIC" : 192203,
            "codeCH" : "BV",
            "nameUIC" : "Peltre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192203,
            "codeCH" : "EV",
            "nameUIC" : "Peltre",
            "nameCH" : "Evitement V1B V2B",
            "code" : ""
        },
        {
            "codeUIC" : 192211,
            "codeCH" : 0,
            "nameUIC" : "Courcelles-sur-Nied",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192229,
            "codeCH" : 0,
            "nameUIC" : "Sanry-sur-Nied",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192237,
            "codeCH" : "BV",
            "nameUIC" : "Rémilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RMI"
        },
        {
            "codeUIC" : 192237,
            "codeCH" : "EV",
            "nameUIC" : "Rémilly",
            "nameCH" : "Évitement Impair",
            "code" : "RMI"
        },
        {
            "codeUIC" : 192237,
            "codeCH" : "P3",
            "nameUIC" : "Rémilly",
            "nameCH" : "Bifurcation L3/L11",
            "code" : "RMI"
        },
        {
            "codeUIC" : 192237,
            "codeCH" : "2B",
            "nameUIC" : "Rémilly",
            "nameCH" : "Evitement Pair",
            "code" : "RMI"
        },
        {
            "codeUIC" : 192245,
            "codeCH" : "BV",
            "nameUIC" : "Baudrecourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192245,
            "codeCH" : "LU",
            "nameUIC" : "Baudrecourt",
            "nameCH" : "Lucy",
            "code" : ""
        },
        {
            "codeUIC" : 192245,
            "codeCH" : "RL",
            "nameUIC" : "Baudrecourt",
            "nameCH" : "Raccordement côté Lucy",
            "code" : ""
        },
        {
            "codeUIC" : 192245,
            "codeCH" : 0,
            "nameUIC" : "Baudrecourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192245,
            "codeCH" : "1B",
            "nameUIC" : "Baudrecourt",
            "nameCH" : "Accès 1B",
            "code" : ""
        },
        {
            "codeUIC" : 192245,
            "codeCH" : "2B",
            "nameUIC" : "Baudrecourt",
            "nameCH" : "Accès 2B",
            "code" : ""
        },
        {
            "codeUIC" : 192286,
            "codeCH" : 0,
            "nameUIC" : "Morhange",
            "nameCH" : "",
            "code" : "MHG"
        },
        {
            "codeUIC" : 192302,
            "codeCH" : "BV",
            "nameUIC" : "Bénestroff",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192302,
            "codeCH" : "PA",
            "nameUIC" : "Bénestroff",
            "nameCH" : "Poste A",
            "code" : ""
        },
        {
            "codeUIC" : 192302,
            "codeCH" : 0,
            "nameUIC" : "Bénestroff",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192385,
            "codeCH" : 0,
            "nameUIC" : "Dieuze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192401,
            "codeCH" : 0,
            "nameUIC" : "Ars-sur-Moselle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192419,
            "codeCH" : 0,
            "nameUIC" : "Ancy-sur-Moselle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192427,
            "codeCH" : "AB",
            "nameUIC" : "Novéant",
            "nameCH" : "Bifurcation Km 373,3",
            "code" : ""
        },
        {
            "codeUIC" : 192427,
            "codeCH" : "BF",
            "nameUIC" : "Novéant",
            "nameCH" : "Bifurcation Km 338,9",
            "code" : ""
        },
        {
            "codeUIC" : 192427,
            "codeCH" : "BV",
            "nameUIC" : "Novéant",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192435,
            "codeCH" : "AO",
            "nameUIC" : "Onville",
            "nameCH" : "Accès Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 192435,
            "codeCH" : "BV",
            "nameUIC" : "Onville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192435,
            "codeCH" : "RC",
            "nameUIC" : "Onville",
            "nameCH" : "Raccordement Côté Conflans-Jarny",
            "code" : ""
        },
        {
            "codeUIC" : 192435,
            "codeCH" : "RL",
            "nameUIC" : "Onville",
            "nameCH" : "Raccordement Côté Lérouville",
            "code" : ""
        },
        {
            "codeUIC" : 192443,
            "codeCH" : 0,
            "nameUIC" : "Thiaucourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192450,
            "codeCH" : 0,
            "nameUIC" : "Essey-et-Maizerais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192468,
            "codeCH" : "BV",
            "nameUIC" : "Pagny-sur-Moselle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PMD"
        },
        {
            "codeUIC" : 192468,
            "codeCH" : "FM",
            "nameUIC" : "Pagny-sur-Moselle",
            "nameCH" : "Faisceau",
            "code" : "PMD"
        },
        {
            "codeUIC" : 192468,
            "codeCH" : "P3",
            "nameUIC" : "Pagny-sur-Moselle",
            "nameCH" : "Poste 3",
            "code" : "PMD"
        },
        {
            "codeUIC" : 192468,
            "codeCH" : "RM",
            "nameUIC" : "Pagny-sur-Moselle",
            "nameCH" : "Raccordement côté Metz",
            "code" : "PMD"
        },
        {
            "codeUIC" : 192476,
            "codeCH" : "BV",
            "nameUIC" : "Vandières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192476,
            "codeCH" : "RN",
            "nameUIC" : "Vandières",
            "nameCH" : "Raccordement côté Nancy",
            "code" : ""
        },
        {
            "codeUIC" : 192476,
            "codeCH" : 0,
            "nameUIC" : "Vandières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192484,
            "codeCH" : "BV",
            "nameUIC" : "Augny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192484,
            "codeCH" : 3,
            "nameUIC" : "Augny",
            "nameCH" : "PN 3",
            "code" : ""
        },
        {
            "codeUIC" : 192484,
            "codeCH" : 4,
            "nameUIC" : "Augny",
            "nameCH" : "PN 4",
            "code" : ""
        },
        {
            "codeUIC" : 192484,
            "codeCH" : 5,
            "nameUIC" : "Augny",
            "nameCH" : "PN 5",
            "code" : ""
        },
        {
            "codeUIC" : 192484,
            "codeCH" : 6,
            "nameUIC" : "Augny",
            "nameCH" : "PN 6",
            "code" : ""
        },
        {
            "codeUIC" : 192484,
            "codeCH" : 7,
            "nameUIC" : "Augny",
            "nameCH" : "PN 7",
            "code" : ""
        },
        {
            "codeUIC" : 192575,
            "codeCH" : "AI",
            "nameUIC" : "Longeville-lès-Metz",
            "nameCH" : "AI",
            "code" : ""
        },
        {
            "codeUIC" : 192575,
            "codeCH" : "EP",
            "nameUIC" : "Longeville-lès-Metz",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 192617,
            "codeCH" : "BV",
            "nameUIC" : "Batilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BTL"
        },
        {
            "codeUIC" : 192617,
            "codeCH" : "EP",
            "nameUIC" : "Batilly",
            "nameCH" : "ITE SOVAB",
            "code" : "BTL"
        },
        {
            "codeUIC" : 192617,
            "codeCH" : 22,
            "nameUIC" : "Batilly",
            "nameCH" : "PN 22",
            "code" : "BTL"
        },
        {
            "codeUIC" : 192658,
            "codeCH" : 0,
            "nameUIC" : "Baroncourt",
            "nameCH" : "",
            "code" : "BRT"
        },
        {
            "codeUIC" : 192666,
            "codeCH" : "BV",
            "nameUIC" : "Conflans-Jarny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CJ"
        },
        {
            "codeUIC" : 192666,
            "codeCH" : "P1",
            "nameUIC" : "Conflans-Jarny",
            "nameCH" : "Bifurcation L5 VU/L12",
            "code" : "CJ"
        },
        {
            "codeUIC" : 192666,
            "codeCH" : "P4",
            "nameUIC" : "Conflans-Jarny",
            "nameCH" : "Bifurcation L12/L5 Double voie",
            "code" : "CJ"
        },
        {
            "codeUIC" : 192666,
            "codeCH" : "ZE",
            "nameUIC" : "Conflans-Jarny",
            "nameCH" : "Relais Zone Est",
            "code" : "CJ"
        },
        {
            "codeUIC" : 192690,
            "codeCH" : 0,
            "nameUIC" : "Chambley",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192708,
            "codeCH" : 0,
            "nameUIC" : "Jeandelize",
            "nameCH" : "",
            "code" : "JDZ"
        },
        {
            "codeUIC" : 192724,
            "codeCH" : 0,
            "nameUIC" : "Hatrize",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 192732,
            "codeCH" : "BV",
            "nameUIC" : "Valleroy-Moineville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 192872,
            "codeCH" : 0,
            "nameUIC" : "Gondrecourt-Aix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193003,
            "codeCH" : "BV",
            "nameUIC" : "Forbach",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FB"
        },
        {
            "codeUIC" : 193003,
            "codeCH" : "FE",
            "nameUIC" : "Forbach",
            "nameCH" : "Faiscseau Export",
            "code" : "FB"
        },
        {
            "codeUIC" : 193003,
            "codeCH" : "FM",
            "nameUIC" : "Forbach",
            "nameCH" : "Faisceau",
            "code" : "FB"
        },
        {
            "codeUIC" : 193144,
            "codeCH" : 0,
            "nameUIC" : "Hargarten-Falck",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193151,
            "codeCH" : 0,
            "nameUIC" : "Téterchen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193169,
            "codeCH" : 0,
            "nameUIC" : "Brettnach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193177,
            "codeCH" : 0,
            "nameUIC" : "Bouzonville",
            "nameCH" : "",
            "code" : "BZV"
        },
        {
            "codeUIC" : 193201,
            "codeCH" : "BV",
            "nameUIC" : "Stiring-Wendel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 193201,
            "codeCH" : "XD",
            "nameUIC" : "Stiring-Wendel",
            "nameCH" : "Frontière France-Allemagne",
            "code" : ""
        },
        {
            "codeUIC" : 193250,
            "codeCH" : "BV",
            "nameUIC" : "Béning",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BNG"
        },
        {
            "codeUIC" : 193250,
            "codeCH" : "MR",
            "nameUIC" : "Béning",
            "nameCH" : "Chantier MR",
            "code" : "BNG"
        },
        {
            "codeUIC" : 193250,
            "codeCH" : "P1",
            "nameUIC" : "Béning",
            "nameCH" : "Accès Ouest Faisceau",
            "code" : "BNG"
        },
        {
            "codeUIC" : 193250,
            "codeCH" : "P2",
            "nameUIC" : "Béning",
            "nameCH" : "Accès Est Faisceau",
            "code" : "BNG"
        },
        {
            "codeUIC" : 193276,
            "codeCH" : 0,
            "nameUIC" : "Hombourg-Haut",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193318,
            "codeCH" : 0,
            "nameUIC" : "St-Avold",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193342,
            "codeCH" : 0,
            "nameUIC" : "Teting",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193359,
            "codeCH" : "A1",
            "nameUIC" : "Faulquemont",
            "nameCH" : "Aiguille Ouest Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 193359,
            "codeCH" : "A2",
            "nameUIC" : "Faulquemont",
            "nameCH" : "Aiguille Est Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 193359,
            "codeCH" : "BV",
            "nameUIC" : "Faulquemont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 193383,
            "codeCH" : "BV",
            "nameUIC" : "Herny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 193383,
            "codeCH" : "RH",
            "nameUIC" : "Herny",
            "nameCH" : "Raccordement côté Herny",
            "code" : ""
        },
        {
            "codeUIC" : 193383,
            "codeCH" : "RP",
            "nameUIC" : "Herny",
            "nameCH" : "Raccordement côté Paris",
            "code" : ""
        },
        {
            "codeUIC" : 193383,
            "codeCH" : 0,
            "nameUIC" : "Herny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193409,
            "codeCH" : 0,
            "nameUIC" : "Creutzwald",
            "nameCH" : "",
            "code" : "CCX"
        },
        {
            "codeUIC" : 193433,
            "codeCH" : 0,
            "nameUIC" : "Carling",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193516,
            "codeCH" : 0,
            "nameUIC" : "Farébersviller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193524,
            "codeCH" : "BV",
            "nameUIC" : "Farschviller",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 193524,
            "codeCH" : "S2",
            "nameUIC" : "Farschviller",
            "nameCH" : "Signal 222",
            "code" : ""
        },
        {
            "codeUIC" : 193540,
            "codeCH" : 0,
            "nameUIC" : "Hundling",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193615,
            "codeCH" : "BV",
            "nameUIC" : "Sarreguemines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SGS"
        },
        {
            "codeUIC" : 193615,
            "codeCH" : "P6",
            "nameUIC" : "Sarreguemines",
            "nameCH" : "Accès Est Faisceau",
            "code" : "SGS"
        },
        {
            "codeUIC" : 193615,
            "codeCH" : "XD",
            "nameUIC" : "Sarreguemines",
            "nameCH" : "Frontière France-Allemagne",
            "code" : "SGS"
        },
        {
            "codeUIC" : 193623,
            "codeCH" : 0,
            "nameUIC" : "Rémelfing",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193631,
            "codeCH" : 0,
            "nameUIC" : "Sarreinsming",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193649,
            "codeCH" : "S1",
            "nameUIC" : "Zetting",
            "nameCH" : "Sémaphore 51",
            "code" : ""
        },
        {
            "codeUIC" : 193649,
            "codeCH" : "S2",
            "nameUIC" : "Zetting",
            "nameCH" : "Sémaphore 152",
            "code" : ""
        },
        {
            "codeUIC" : 193649,
            "codeCH" : 0,
            "nameUIC" : "Zetting",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193656,
            "codeCH" : 0,
            "nameUIC" : "Wittring",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193664,
            "codeCH" : "BV",
            "nameUIC" : "Kalhausen",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 193664,
            "codeCH" : "P2",
            "nameUIC" : "Kalhausen",
            "nameCH" : "Raccordement Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 193664,
            "codeCH" : "P3",
            "nameUIC" : "Kalhausen",
            "nameCH" : "Raccordement Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 193672,
            "codeCH" : 0,
            "nameUIC" : "Herbitzheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193714,
            "codeCH" : "BV",
            "nameUIC" : "Sarralbe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SRB"
        },
        {
            "codeUIC" : 193714,
            "codeCH" : "EP",
            "nameUIC" : "Sarralbe",
            "nameCH" : "ITE",
            "code" : "SRB"
        },
        {
            "codeUIC" : 193714,
            "codeCH" : "PA",
            "nameUIC" : "Sarralbe",
            "nameCH" : "PA",
            "code" : "SRB"
        },
        {
            "codeUIC" : 193714,
            "codeCH" : 0,
            "nameUIC" : "Sarralbe",
            "nameCH" : "",
            "code" : "SRB"
        },
        {
            "codeUIC" : 193722,
            "codeCH" : "BV",
            "nameUIC" : "Rech",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 193722,
            "codeCH" : "EP",
            "nameUIC" : "Rech",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 193771,
            "codeCH" : 0,
            "nameUIC" : "Woelfling-lès-Sarreguemines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193789,
            "codeCH" : 0,
            "nameUIC" : "Rohrbach-lès-Bitche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193797,
            "codeCH" : 0,
            "nameUIC" : "Petit-Réderching",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193805,
            "codeCH" : 0,
            "nameUIC" : "Enchenberg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193813,
            "codeCH" : 0,
            "nameUIC" : "Lemberg (Moselle)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193821,
            "codeCH" : 0,
            "nameUIC" : "Bitche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 193839,
            "codeCH" : 0,
            "nameUIC" : "Bitche-Camp",
            "nameCH" : "",
            "code" : "BHP"
        },
        {
            "codeUIC" : 193888,
            "codeCH" : "BV",
            "nameUIC" : "Guerstling",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 193888,
            "codeCH" : "XD",
            "nameUIC" : "Guerstling",
            "nameCH" : "Frontière France-Allemagne",
            "code" : ""
        },
        {
            "codeUIC" : 194001,
            "codeCH" : "AU",
            "nameUIC" : "Longwy",
            "nameCH" : "Aiguille U",
            "code" : "LWY"
        },
        {
            "codeUIC" : 194001,
            "codeCH" : "BV",
            "nameUIC" : "Longwy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LWY"
        },
        {
            "codeUIC" : 194001,
            "codeCH" : "FM",
            "nameUIC" : "Longwy",
            "nameCH" : "Faisceau",
            "code" : "LWY"
        },
        {
            "codeUIC" : 194001,
            "codeCH" : "P1",
            "nameUIC" : "Longwy",
            "nameCH" : "Poste 1",
            "code" : "LWY"
        },
        {
            "codeUIC" : 194001,
            "codeCH" : "P3",
            "nameUIC" : "Longwy",
            "nameCH" : "Poste 3",
            "code" : "LWY"
        },
        {
            "codeUIC" : 194035,
            "codeCH" : 0,
            "nameUIC" : "Pouilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194209,
            "codeCH" : "BV",
            "nameUIC" : "Montmédy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 194209,
            "codeCH" : "EV",
            "nameUIC" : "Montmédy",
            "nameCH" : "Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 194209,
            "codeCH" : "PK",
            "nameUIC" : "Montmédy",
            "nameCH" : "PK 207,5",
            "code" : ""
        },
        {
            "codeUIC" : 194258,
            "codeCH" : 0,
            "nameUIC" : "Charency-Vezin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194274,
            "codeCH" : "BF",
            "nameUIC" : "Longuyon",
            "nameCH" : "Bifurcation",
            "code" : "LGN"
        },
        {
            "codeUIC" : 194274,
            "codeCH" : "BV",
            "nameUIC" : "Longuyon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LGN"
        },
        {
            "codeUIC" : 194290,
            "codeCH" : 0,
            "nameUIC" : "Spincourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194316,
            "codeCH" : 0,
            "nameUIC" : "Pierrepont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194332,
            "codeCH" : 0,
            "nameUIC" : "Mercy-le-Bas-Mainbottel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194357,
            "codeCH" : "BV",
            "nameUIC" : "Audun-le-Roman",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ALR"
        },
        {
            "codeUIC" : 194357,
            "codeCH" : "PN",
            "nameUIC" : "Audun-le-Roman",
            "nameCH" : "PN 9",
            "code" : "ALR"
        },
        {
            "codeUIC" : 194357,
            "codeCH" : "P1",
            "nameUIC" : "Audun-le-Roman",
            "nameCH" : "Accès Ouest Faisceau",
            "code" : "ALR"
        },
        {
            "codeUIC" : 194365,
            "codeCH" : 0,
            "nameUIC" : "Fontoy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194407,
            "codeCH" : "BF",
            "nameUIC" : "Mont-St-Martin",
            "nameCH" : "Bifurcation",
            "code" : "MSM"
        },
        {
            "codeUIC" : 194407,
            "codeCH" : "P2",
            "nameUIC" : "Mont-St-Martin",
            "nameCH" : "Accès Faisceau",
            "code" : "MSM"
        },
        {
            "codeUIC" : 194407,
            "codeCH" : "XB",
            "nameUIC" : "Mont-St-Martin",
            "nameCH" : "Frontière France-Belgique",
            "code" : "MSM"
        },
        {
            "codeUIC" : 194407,
            "codeCH" : "XL",
            "nameUIC" : "Mont-St-Martin",
            "nameCH" : "Frontière France-Luxembourg",
            "code" : "MSM"
        },
        {
            "codeUIC" : 194456,
            "codeCH" : 0,
            "nameUIC" : "Cons-la-Grandville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194480,
            "codeCH" : 0,
            "nameUIC" : "Montigny-sur-Chiers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194506,
            "codeCH" : "EP",
            "nameUIC" : "Saulnes",
            "nameCH" : "ITE Zone Industrielle Saulnes",
            "code" : "SNS"
        },
        {
            "codeUIC" : 194506,
            "codeCH" : "P2",
            "nameUIC" : "Saulnes",
            "nameCH" : "P2",
            "code" : "SNS"
        },
        {
            "codeUIC" : 194555,
            "codeCH" : "BV",
            "nameUIC" : "Audun-le-Tiche",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 194555,
            "codeCH" : "XL",
            "nameUIC" : "Audun-le-Tiche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 194654,
            "codeCH" : 0,
            "nameUIC" : "Tiercelet-Villers-la-Montagne",
            "nameCH" : "",
            "code" : "TIE"
        },
        {
            "codeUIC" : 196683,
            "codeCH" : 0,
            "nameUIC" : "Sémaphores 6,7I/6,7P",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199372,
            "codeCH" : 0,
            "nameUIC" : "Carré 150,4",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199380,
            "codeCH" : 0,
            "nameUIC" : "Carré 219",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199406,
            "codeCH" : 0,
            "nameUIC" : "Carré 218",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199414,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 35",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199422,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 36",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199430,
            "codeCH" : 0,
            "nameUIC" : "Carré 41",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199455,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 51",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199463,
            "codeCH" : 0,
            "nameUIC" : "Carré 42",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199471,
            "codeCH" : 0,
            "nameUIC" : "Carré 52",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199489,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 55",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199497,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 56",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199638,
            "codeCH" : 0,
            "nameUIC" : "Carré 11",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 199646,
            "codeCH" : 0,
            "nameUIC" : "Carré 12",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 207092,
            "codeCH" : 0,
            "nameUIC" : "Poste 53 Crest",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 207100,
            "codeCH" : 0,
            "nameUIC" : "Poste 54 Bonlieu-Roubion",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 207118,
            "codeCH" : "PR",
            "nameUIC" : "Poste 55 Allan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 207118,
            "codeCH" : "V4",
            "nameUIC" : "Poste 55 Allan",
            "nameCH" : "Voie 4",
            "code" : ""
        },
        {
            "codeUIC" : 207118,
            "codeCH" : "ZE",
            "nameUIC" : "Poste 55 Allan",
            "nameCH" : "Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 207126,
            "codeCH" : "PR",
            "nameUIC" : "Poste 56 Lapalud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 207126,
            "codeCH" : "RA",
            "nameUIC" : "Poste 56 Lapalud",
            "nameCH" : "Rac d'Avignon",
            "code" : ""
        },
        {
            "codeUIC" : 207126,
            "codeCH" : "RV",
            "nameUIC" : "Poste 56 Lapalud",
            "nameCH" : "Rac de Valence",
            "code" : ""
        },
        {
            "codeUIC" : 207431,
            "codeCH" : 0,
            "nameUIC" : "Poste 52 Upie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 207779,
            "codeCH" : 0,
            "nameUIC" : "ITE La Gauloise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 208553,
            "codeCH" : 0,
            "nameUIC" : "Casino-Lacroix-Laval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 208728,
            "codeCH" : 0,
            "nameUIC" : "IPCS Montbarbier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 208736,
            "codeCH" : 0,
            "nameUIC" : "IPCS Pompignan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 208744,
            "codeCH" : 0,
            "nameUIC" : "La Réole Poste 45",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212027,
            "codeCH" : "AM",
            "nameUIC" : "Strasbourg-Ville",
            "nameCH" : "Aiguille QM",
            "code" : "SG"
        },
        {
            "codeUIC" : 212027,
            "codeCH" : "BV",
            "nameUIC" : "Strasbourg-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SG"
        },
        {
            "codeUIC" : 212027,
            "codeCH" : "DT",
            "nameUIC" : "Strasbourg-Ville",
            "nameCH" : "Dépôt",
            "code" : "SG"
        },
        {
            "codeUIC" : 212027,
            "codeCH" : "J1",
            "nameUIC" : "Strasbourg-Ville",
            "nameCH" : "Jonction 1",
            "code" : "SG"
        },
        {
            "codeUIC" : 212027,
            "codeCH" : "J2",
            "nameUIC" : "Strasbourg-Ville",
            "nameCH" : "Jonction 2",
            "code" : "SG"
        },
        {
            "codeUIC" : 212027,
            "codeCH" : "NO",
            "nameUIC" : "Strasbourg-Ville",
            "nameCH" : "Entrée Nord",
            "code" : "SG"
        },
        {
            "codeUIC" : 212027,
            "codeCH" : "QM",
            "nameUIC" : "Strasbourg-Ville",
            "nameCH" : "Quai Marchandises",
            "code" : "SG"
        },
        {
            "codeUIC" : 212050,
            "codeCH" : "AH",
            "nameUIC" : "Strasbourg-Cronenbourg",
            "nameCH" : "Aiguille Voie 2B/VH",
            "code" : "STC"
        },
        {
            "codeUIC" : 212050,
            "codeCH" : "AK",
            "nameUIC" : "Strasbourg-Cronenbourg",
            "nameCH" : "Aiguille 7,9",
            "code" : "STC"
        },
        {
            "codeUIC" : 212050,
            "codeCH" : "AS",
            "nameUIC" : "Strasbourg-Cronenbourg",
            "nameCH" : "Aiguille VS/V1B",
            "code" : "STC"
        },
        {
            "codeUIC" : 212050,
            "codeCH" : "P1",
            "nameUIC" : "Strasbourg-Cronenbourg",
            "nameCH" : "Poste 1",
            "code" : "STC"
        },
        {
            "codeUIC" : 212050,
            "codeCH" : "RA",
            "nameUIC" : "Strasbourg-Cronenbourg",
            "nameCH" : "Faisceau RA",
            "code" : "STC"
        },
        {
            "codeUIC" : 212068,
            "codeCH" : "BV",
            "nameUIC" : "Strasbourg-Neudorf",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SNF"
        },
        {
            "codeUIC" : 212068,
            "codeCH" : "FR",
            "nameUIC" : "Strasbourg-Neudorf",
            "nameCH" : "Faisceau Réception",
            "code" : "SNF"
        },
        {
            "codeUIC" : 212068,
            "codeCH" : "JE",
            "nameUIC" : "Strasbourg-Neudorf",
            "nameCH" : "Jonction Est",
            "code" : "SNF"
        },
        {
            "codeUIC" : 212068,
            "codeCH" : "JO",
            "nameUIC" : "Strasbourg-Neudorf",
            "nameCH" : "Jonction Ouest",
            "code" : "SNF"
        },
        {
            "codeUIC" : 212068,
            "codeCH" : "LA",
            "nameUIC" : "Strasbourg-Neudorf",
            "nameCH" : "Entrée lavage",
            "code" : "SNF"
        },
        {
            "codeUIC" : 212068,
            "codeCH" : "PA",
            "nameUIC" : "Strasbourg-Neudorf",
            "nameCH" : "Port Autonome",
            "code" : "SNF"
        },
        {
            "codeUIC" : 212068,
            "codeCH" : "RG",
            "nameUIC" : "Strasbourg-Neudorf",
            "nameCH" : "Aiguille 4,2",
            "code" : "SNF"
        },
        {
            "codeUIC" : 212076,
            "codeCH" : "JN",
            "nameUIC" : "Strasbourg-Port-du-Rhin",
            "nameCH" : "Jonction",
            "code" : "SRH"
        },
        {
            "codeUIC" : 212076,
            "codeCH" : "XD",
            "nameUIC" : "Strasbourg-Port-du-Rhin",
            "nameCH" : "Frontière France-Allemagne",
            "code" : "SRH"
        },
        {
            "codeUIC" : 212076,
            "codeCH" : 0,
            "nameUIC" : "Strasbourg-Port-du-Rhin",
            "nameCH" : "Poste 1",
            "code" : "SRH"
        },
        {
            "codeUIC" : 212084,
            "codeCH" : "P1",
            "nameUIC" : "Strasbourg-Koenigshoffen",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 212084,
            "codeCH" : "RN",
            "nameUIC" : "Strasbourg-Koenigshoffen",
            "nameCH" : "Raccordement Nord",
            "code" : ""
        },
        {
            "codeUIC" : 212084,
            "codeCH" : "RS",
            "nameUIC" : "Strasbourg-Koenigshoffen",
            "nameCH" : "Raccordement Sud",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "AC",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Accès 1B3 CS",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "AG",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Aiguille 499,197",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "AH",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Sortie V2BIS",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "A1",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Aiguille 495,1",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "A3",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Aiguille 496,9",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "A5",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Aiguille 497,9",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "DP",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "FD",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Faisceau D",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "FF",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Faisceau F",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "FN",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "FS",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Faisceau Départ",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "JN",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Jonction Voie 1Bis/Voie D",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "PF",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Poste F",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "PP",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Point P",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "PU",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "Q5",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Quai 5",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "RI",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Relais Impairs",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "RP",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Relais Pairs",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : 10,
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Quai 10 Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : 21,
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Carré 21",
            "code" : ""
        },
        {
            "codeUIC" : 212092,
            "codeCH" : "4B",
            "nameUIC" : "Hausbergen-Triage",
            "nameCH" : "Quai 4B",
            "code" : ""
        },
        {
            "codeUIC" : 212100,
            "codeCH" : 0,
            "nameUIC" : "Mundolsheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212118,
            "codeCH" : "JN",
            "nameUIC" : "Vendenheim",
            "nameCH" : "Jonction Voie 1BIS/Voie D",
            "code" : ""
        },
        {
            "codeUIC" : 212118,
            "codeCH" : "RN",
            "nameUIC" : "Vendenheim",
            "nameCH" : "Raccordement Nord",
            "code" : ""
        },
        {
            "codeUIC" : 212118,
            "codeCH" : 0,
            "nameUIC" : "Vendenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212126,
            "codeCH" : 0,
            "nameUIC" : "Stephansfeld",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212134,
            "codeCH" : "BV",
            "nameUIC" : "Brumath",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BAH"
        },
        {
            "codeUIC" : 212142,
            "codeCH" : "AE",
            "nameUIC" : "Mommenheim",
            "nameCH" : "Accès Est",
            "code" : ""
        },
        {
            "codeUIC" : 212142,
            "codeCH" : 0,
            "nameUIC" : "Mommenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212159,
            "codeCH" : 0,
            "nameUIC" : "Schwindratzheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212167,
            "codeCH" : "BV",
            "nameUIC" : "Hochfelden",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HCH"
        },
        {
            "codeUIC" : 212167,
            "codeCH" : "EP",
            "nameUIC" : "Hochfelden",
            "nameCH" : "ITE Météor",
            "code" : "HCH"
        },
        {
            "codeUIC" : 212167,
            "codeCH" : "EV",
            "nameUIC" : "Hochfelden",
            "nameCH" : "Évitement",
            "code" : "HCH"
        },
        {
            "codeUIC" : 212175,
            "codeCH" : "ES",
            "nameUIC" : "Wilwisheim",
            "nameCH" : "Entrée/Sortie IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 212175,
            "codeCH" : 0,
            "nameUIC" : "Wilwisheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212183,
            "codeCH" : "BV",
            "nameUIC" : "Dettwiller",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 212191,
            "codeCH" : 0,
            "nameUIC" : "Strasbourg-Roethig",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212209,
            "codeCH" : 0,
            "nameUIC" : "Steinbourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212217,
            "codeCH" : 0,
            "nameUIC" : "Zornhoff-Monswiller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212225,
            "codeCH" : "AE",
            "nameUIC" : "Saverne",
            "nameCH" : "Aiguille Est",
            "code" : "SNE"
        },
        {
            "codeUIC" : 212225,
            "codeCH" : 0,
            "nameUIC" : "Saverne",
            "nameCH" : "",
            "code" : "SNE"
        },
        {
            "codeUIC" : 212233,
            "codeCH" : 0,
            "nameUIC" : "Stambach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212241,
            "codeCH" : "BF",
            "nameUIC" : "Graffenstaden",
            "nameCH" : "Bifurcation",
            "code" : "GRF"
        },
        {
            "codeUIC" : 212241,
            "codeCH" : "BV",
            "nameUIC" : "Graffenstaden",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GRF"
        },
        {
            "codeUIC" : 212241,
            "codeCH" : "RC",
            "nameUIC" : "Graffenstaden",
            "nameCH" : "Raccordement",
            "code" : "GRF"
        },
        {
            "codeUIC" : 212258,
            "codeCH" : 0,
            "nameUIC" : "Geispolsheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212266,
            "codeCH" : "BV",
            "nameUIC" : "Fegersheim-Lipsheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 212266,
            "codeCH" : "E4",
            "nameUIC" : "Fegersheim-Lipsheim",
            "nameCH" : "Sortie Évitement 2BIS",
            "code" : ""
        },
        {
            "codeUIC" : 212274,
            "codeCH" : 0,
            "nameUIC" : "Limersheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212308,
            "codeCH" : "AT",
            "nameUIC" : "Bischheim",
            "nameCH" : "Ateliers",
            "code" : "BHM"
        },
        {
            "codeUIC" : 212308,
            "codeCH" : "BV",
            "nameUIC" : "Bischheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BHM"
        },
        {
            "codeUIC" : 212308,
            "codeCH" : "P2",
            "nameUIC" : "Bischheim",
            "nameCH" : "Poste 2",
            "code" : "BHM"
        },
        {
            "codeUIC" : 212316,
            "codeCH" : "BV",
            "nameUIC" : "La Wantzenau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 212316,
            "codeCH" : "PN",
            "nameUIC" : "La Wantzenau",
            "nameCH" : "PN 10",
            "code" : ""
        },
        {
            "codeUIC" : 212324,
            "codeCH" : 0,
            "nameUIC" : "Kilstett",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212332,
            "codeCH" : 0,
            "nameUIC" : "Gambsheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212340,
            "codeCH" : "BV",
            "nameUIC" : "Herrlisheim (Bas-Rhin)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HRL"
        },
        {
            "codeUIC" : 212340,
            "codeCH" : "EP",
            "nameUIC" : "Herrlisheim (Bas-Rhin)",
            "nameCH" : "ITE Elf-Antargaz",
            "code" : "HRL"
        },
        {
            "codeUIC" : 212357,
            "codeCH" : 0,
            "nameUIC" : "Drusenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212365,
            "codeCH" : 0,
            "nameUIC" : "Sessenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212373,
            "codeCH" : 0,
            "nameUIC" : "Rountzenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212381,
            "codeCH" : "PE",
            "nameUIC" : "Reichstett",
            "nameCH" : "Poste E",
            "code" : "REH"
        },
        {
            "codeUIC" : 212381,
            "codeCH" : "SF",
            "nameUIC" : "Reichstett",
            "nameCH" : "ITE Stef",
            "code" : "REH"
        },
        {
            "codeUIC" : 212381,
            "codeCH" : "ZI",
            "nameUIC" : "Reichstett",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "REH"
        },
        {
            "codeUIC" : 212407,
            "codeCH" : 0,
            "nameUIC" : "Roeschwoog",
            "nameCH" : "",
            "code" : "RWG"
        },
        {
            "codeUIC" : 212415,
            "codeCH" : 0,
            "nameUIC" : "Roppenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212423,
            "codeCH" : "BV",
            "nameUIC" : "Beinheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 212423,
            "codeCH" : "ER",
            "nameUIC" : "Beinheim",
            "nameCH" : "ITE Roquette",
            "code" : ""
        },
        {
            "codeUIC" : 212423,
            "codeCH" : 0,
            "nameUIC" : "Beinheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212431,
            "codeCH" : 0,
            "nameUIC" : "Seltz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212449,
            "codeCH" : 0,
            "nameUIC" : "Munchhausen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212456,
            "codeCH" : 0,
            "nameUIC" : "Mothern",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 212464,
            "codeCH" : "AG",
            "nameUIC" : "Lauterbourg",
            "nameCH" : "Aiguilles 22",
            "code" : "LTG"
        },
        {
            "codeUIC" : 212464,
            "codeCH" : "XD",
            "nameUIC" : "Lauterbourg",
            "nameCH" : "Frontière France-Allemagne",
            "code" : "LTG"
        },
        {
            "codeUIC" : 212464,
            "codeCH" : 0,
            "nameUIC" : "Lauterbourg",
            "nameCH" : "",
            "code" : "LTG"
        },
        {
            "codeUIC" : 212704,
            "codeCH" : "BV",
            "nameUIC" : "Soufflenheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 212704,
            "codeCH" : 11,
            "nameUIC" : "Soufflenheim",
            "nameCH" : "PN 11",
            "code" : ""
        },
        {
            "codeUIC" : 212704,
            "codeCH" : 12,
            "nameUIC" : "Soufflenheim",
            "nameCH" : "PN 12",
            "code" : ""
        },
        {
            "codeUIC" : 212712,
            "codeCH" : "BV",
            "nameUIC" : "Schirrhein",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 212712,
            "codeCH" : 6,
            "nameUIC" : "Schirrhein",
            "nameCH" : "PN 6",
            "code" : ""
        },
        {
            "codeUIC" : 212720,
            "codeCH" : "BV",
            "nameUIC" : "Oberhoffen-Camp-de-Haguenau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "OBH"
        },
        {
            "codeUIC" : 212910,
            "codeCH" : "WD",
            "nameUIC" : "Kehl",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 213058,
            "codeCH" : "BV",
            "nameUIC" : "Haguenau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HAG"
        },
        {
            "codeUIC" : 213058,
            "codeCH" : 1,
            "nameUIC" : "Haguenau",
            "nameCH" : "PN 1",
            "code" : "HAG"
        },
        {
            "codeUIC" : 213108,
            "codeCH" : "BV",
            "nameUIC" : "Schweighouse-sur-Moder",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SWG"
        },
        {
            "codeUIC" : 213108,
            "codeCH" : "EP",
            "nameUIC" : "Schweighouse-sur-Moder",
            "nameCH" : "ITE INA Roulement",
            "code" : "SWG"
        },
        {
            "codeUIC" : 213116,
            "codeCH" : "EP",
            "nameUIC" : "ITE Km 10,0",
            "nameCH" : "ITE BAYER Polymères",
            "code" : ""
        },
        {
            "codeUIC" : 213116,
            "codeCH" : "JE",
            "nameUIC" : "ITE Km 10,0",
            "nameCH" : "Jonction ITE",
            "code" : ""
        },
        {
            "codeUIC" : 213165,
            "codeCH" : "AE",
            "nameUIC" : "Obermodern",
            "nameCH" : "Accès Est Voie Unique",
            "code" : ""
        },
        {
            "codeUIC" : 213165,
            "codeCH" : "AO",
            "nameUIC" : "Obermodern",
            "nameCH" : "Accès Ouest Voie Unique",
            "code" : ""
        },
        {
            "codeUIC" : 213165,
            "codeCH" : "BV",
            "nameUIC" : "Obermodern",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 213207,
            "codeCH" : 0,
            "nameUIC" : "Mertzwiller",
            "nameCH" : "",
            "code" : "MZW"
        },
        {
            "codeUIC" : 213215,
            "codeCH" : 0,
            "nameUIC" : "Mietesheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213223,
            "codeCH" : 0,
            "nameUIC" : "Gundershoffen",
            "nameCH" : "",
            "code" : "GUF"
        },
        {
            "codeUIC" : 213231,
            "codeCH" : 0,
            "nameUIC" : "Reichshoffen-Usines",
            "nameCH" : "",
            "code" : "RUO"
        },
        {
            "codeUIC" : 213249,
            "codeCH" : 0,
            "nameUIC" : "Reichshoffen-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213256,
            "codeCH" : "BV",
            "nameUIC" : "Niederbronn-les-Bains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 213405,
            "codeCH" : 0,
            "nameUIC" : "Walbourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213413,
            "codeCH" : 0,
            "nameUIC" : "Hoelschloch",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213421,
            "codeCH" : 0,
            "nameUIC" : "Soultz-sous-Forêts",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213447,
            "codeCH" : 0,
            "nameUIC" : "Hoffen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213454,
            "codeCH" : 0,
            "nameUIC" : "Hunspach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213462,
            "codeCH" : 0,
            "nameUIC" : "Riedseltz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213470,
            "codeCH" : "BF",
            "nameUIC" : "Wissembourg",
            "nameCH" : "Accès Winden",
            "code" : "WBG"
        },
        {
            "codeUIC" : 213470,
            "codeCH" : "BV",
            "nameUIC" : "Wissembourg",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "WBG"
        },
        {
            "codeUIC" : 213470,
            "codeCH" : "XD",
            "nameUIC" : "Wissembourg",
            "nameCH" : "Frontière France-Allemagne",
            "code" : "WBG"
        },
        {
            "codeUIC" : 213470,
            "codeCH" : 0,
            "nameUIC" : "Wissembourg",
            "nameCH" : "",
            "code" : "WBG"
        },
        {
            "codeUIC" : 213603,
            "codeCH" : 0,
            "nameUIC" : "Marienthal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213611,
            "codeCH" : "BV",
            "nameUIC" : "Bischwiller (Bas-Rhin)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BHW"
        },
        {
            "codeUIC" : 213611,
            "codeCH" : "EP",
            "nameUIC" : "Bischwiller (Bas-Rhin)",
            "nameCH" : "ITE Cérafilia Schneider",
            "code" : "BHW"
        },
        {
            "codeUIC" : 213660,
            "codeCH" : 0,
            "nameUIC" : "Kurtzenhouse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213678,
            "codeCH" : 0,
            "nameUIC" : "Weyersheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213686,
            "codeCH" : 0,
            "nameUIC" : "Hoerdt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213744,
            "codeCH" : 0,
            "nameUIC" : "Wingen-sur-Moder",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213769,
            "codeCH" : 0,
            "nameUIC" : "Wimmenau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213785,
            "codeCH" : 0,
            "nameUIC" : "Ingwiller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213819,
            "codeCH" : 0,
            "nameUIC" : "Menchhoffen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213827,
            "codeCH" : 0,
            "nameUIC" : "Ettendorf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 213835,
            "codeCH" : 0,
            "nameUIC" : "Alteckendorf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214056,
            "codeCH" : 0,
            "nameUIC" : "Sélestat",
            "nameCH" : "",
            "code" : "STT"
        },
        {
            "codeUIC" : 214106,
            "codeCH" : 0,
            "nameUIC" : "Ebersheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214114,
            "codeCH" : "BV",
            "nameUIC" : "Kogenheim",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 214114,
            "codeCH" : "C3",
            "nameUIC" : "Kogenheim",
            "nameCH" : "Carré 311",
            "code" : ""
        },
        {
            "codeUIC" : 214114,
            "codeCH" : "EV",
            "nameUIC" : "Kogenheim",
            "nameCH" : "Aiguille Voie Bis",
            "code" : ""
        },
        {
            "codeUIC" : 214122,
            "codeCH" : "BV",
            "nameUIC" : "Benfeld",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 214122,
            "codeCH" : "E3",
            "nameUIC" : "Benfeld",
            "nameCH" : "Sortie Évitement 1TER",
            "code" : ""
        },
        {
            "codeUIC" : 214130,
            "codeCH" : 0,
            "nameUIC" : "Matzenheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214148,
            "codeCH" : "BV",
            "nameUIC" : "Erstein",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 214148,
            "codeCH" : "C2",
            "nameUIC" : "Erstein",
            "nameCH" : "Carré 211",
            "code" : ""
        },
        {
            "codeUIC" : 214148,
            "codeCH" : "EV",
            "nameUIC" : "Erstein",
            "nameCH" : "Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 214148,
            "codeCH" : "E1",
            "nameUIC" : "Erstein",
            "nameCH" : "Entrée Évitement 1TER",
            "code" : ""
        },
        {
            "codeUIC" : 214148,
            "codeCH" : "E2",
            "nameUIC" : "Erstein",
            "nameCH" : "Entrée Évitement 2BIS",
            "code" : ""
        },
        {
            "codeUIC" : 214205,
            "codeCH" : 0,
            "nameUIC" : "Scherwiller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214213,
            "codeCH" : 0,
            "nameUIC" : "Dambach-la-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214221,
            "codeCH" : 0,
            "nameUIC" : "Epfig",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214239,
            "codeCH" : 0,
            "nameUIC" : "Eichhoffen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214247,
            "codeCH" : 0,
            "nameUIC" : "Barr",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214254,
            "codeCH" : 0,
            "nameUIC" : "Gertwiller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214270,
            "codeCH" : 0,
            "nameUIC" : "Goxwiller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214288,
            "codeCH" : "A1",
            "nameUIC" : "Obernai",
            "nameCH" : "Aiguille Z",
            "code" : ""
        },
        {
            "codeUIC" : 214288,
            "codeCH" : "BV",
            "nameUIC" : "Obernai",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 214296,
            "codeCH" : 0,
            "nameUIC" : "Bischoffsheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214320,
            "codeCH" : 0,
            "nameUIC" : "Rosheim",
            "nameCH" : "",
            "code" : "ROH"
        },
        {
            "codeUIC" : 214338,
            "codeCH" : 0,
            "nameUIC" : "Dorlisheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214502,
            "codeCH" : 0,
            "nameUIC" : "Lingolsheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214510,
            "codeCH" : 0,
            "nameUIC" : "Holtzheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214528,
            "codeCH" : 0,
            "nameUIC" : "Entzheim-Aéroport",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214536,
            "codeCH" : 0,
            "nameUIC" : "Duppigheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214544,
            "codeCH" : 0,
            "nameUIC" : "Duttlenheim",
            "nameCH" : "",
            "code" : "DTM"
        },
        {
            "codeUIC" : 214551,
            "codeCH" : 0,
            "nameUIC" : "Dachstein",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214577,
            "codeCH" : "V4",
            "nameUIC" : "Molsheim",
            "nameCH" : "Voie 4",
            "code" : "MOL"
        },
        {
            "codeUIC" : 214577,
            "codeCH" : 0,
            "nameUIC" : "Molsheim",
            "nameCH" : "",
            "code" : "MOL"
        },
        {
            "codeUIC" : 214585,
            "codeCH" : 0,
            "nameUIC" : "Mutzig",
            "nameCH" : "",
            "code" : "MTZ"
        },
        {
            "codeUIC" : 214593,
            "codeCH" : 0,
            "nameUIC" : "Gresswiller",
            "nameCH" : "",
            "code" : "GEI"
        },
        {
            "codeUIC" : 214627,
            "codeCH" : 0,
            "nameUIC" : "Heiligenberg-Mollkirch",
            "nameCH" : "",
            "code" : "HEI"
        },
        {
            "codeUIC" : 214635,
            "codeCH" : 0,
            "nameUIC" : "Urmatt",
            "nameCH" : "",
            "code" : "URM"
        },
        {
            "codeUIC" : 214643,
            "codeCH" : 0,
            "nameUIC" : "Mullerhof",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214650,
            "codeCH" : 0,
            "nameUIC" : "Lutzelhouse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214676,
            "codeCH" : 0,
            "nameUIC" : "Wisches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214684,
            "codeCH" : 0,
            "nameUIC" : "Russ-Hersbach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214692,
            "codeCH" : "EP",
            "nameUIC" : "Schirmeck-La Broque",
            "nameCH" : "ITE Grandes Carrières Douvier",
            "code" : "SLC"
        },
        {
            "codeUIC" : 214692,
            "codeCH" : 0,
            "nameUIC" : "Schirmeck-La Broque",
            "nameCH" : "",
            "code" : "SLC"
        },
        {
            "codeUIC" : 214726,
            "codeCH" : 0,
            "nameUIC" : "Rothau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214734,
            "codeCH" : 0,
            "nameUIC" : "Fouday",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214742,
            "codeCH" : 0,
            "nameUIC" : "St-Blaise-la-Roche-Poutay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214759,
            "codeCH" : 0,
            "nameUIC" : "Saulxures (Bas-Rhin)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214775,
            "codeCH" : 0,
            "nameUIC" : "Bourg-Bruche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214783,
            "codeCH" : 0,
            "nameUIC" : "Saales",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214809,
            "codeCH" : 0,
            "nameUIC" : "Châtenois (Bas-Rhin)",
            "nameCH" : "",
            "code" : "CEB"
        },
        {
            "codeUIC" : 214817,
            "codeCH" : 0,
            "nameUIC" : "Val-de-Villé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214825,
            "codeCH" : "BV",
            "nameUIC" : "La Vancelle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LVN"
        },
        {
            "codeUIC" : 214825,
            "codeCH" : "EP",
            "nameUIC" : "La Vancelle",
            "nameCH" : "ITE Hartmann",
            "code" : "LVN"
        },
        {
            "codeUIC" : 214833,
            "codeCH" : "EP",
            "nameUIC" : "Lièpvre",
            "nameCH" : "ITE Zone Industrielle de Lièpvre",
            "code" : ""
        },
        {
            "codeUIC" : 214833,
            "codeCH" : 0,
            "nameUIC" : "Lièpvre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 214841,
            "codeCH" : 0,
            "nameUIC" : "Ste-Croix-aux-Mines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215012,
            "codeCH" : 0,
            "nameUIC" : "Sarrebourg",
            "nameCH" : "",
            "code" : "SBG"
        },
        {
            "codeUIC" : 215053,
            "codeCH" : "AE",
            "nameUIC" : "Phalsbourg",
            "nameCH" : "ITE Administration Militaire Nø1",
            "code" : ""
        },
        {
            "codeUIC" : 215053,
            "codeCH" : "AS",
            "nameUIC" : "Phalsbourg",
            "nameCH" : "ITE Administration Militaire Nø2",
            "code" : ""
        },
        {
            "codeUIC" : 215053,
            "codeCH" : "BA",
            "nameUIC" : "Phalsbourg",
            "nameCH" : "ITE Camp La Horie",
            "code" : ""
        },
        {
            "codeUIC" : 215079,
            "codeCH" : "AE",
            "nameUIC" : "Réding",
            "nameCH" : "Accès Est",
            "code" : "RDG"
        },
        {
            "codeUIC" : 215079,
            "codeCH" : "AI",
            "nameUIC" : "Réding",
            "nameCH" : "TJD 30/31",
            "code" : "RDG"
        },
        {
            "codeUIC" : 215079,
            "codeCH" : "BV",
            "nameUIC" : "Réding",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RDG"
        },
        {
            "codeUIC" : 215079,
            "codeCH" : "FP",
            "nameUIC" : "Réding",
            "nameCH" : "Faisceau Pair",
            "code" : "RDG"
        },
        {
            "codeUIC" : 215079,
            "codeCH" : "P2",
            "nameUIC" : "Réding",
            "nameCH" : "Poste 2",
            "code" : "RDG"
        },
        {
            "codeUIC" : 215087,
            "codeCH" : "BF",
            "nameUIC" : "Brouviller-Lixheim",
            "nameCH" : "Bifurcation Lixheim",
            "code" : ""
        },
        {
            "codeUIC" : 215087,
            "codeCH" : 0,
            "nameUIC" : "Brouviller-Lixheim",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215103,
            "codeCH" : "BV",
            "nameUIC" : "Igney-Avricourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 215129,
            "codeCH" : 0,
            "nameUIC" : "Réchicourt-le-Château",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215145,
            "codeCH" : 0,
            "nameUIC" : "Héming",
            "nameCH" : "",
            "code" : "HMG"
        },
        {
            "codeUIC" : 215152,
            "codeCH" : "BV",
            "nameUIC" : "Arzviller",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 215152,
            "codeCH" : "CS",
            "nameUIC" : "Arzviller",
            "nameCH" : "IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 215160,
            "codeCH" : "BV",
            "nameUIC" : "Lutzelbourg",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 215160,
            "codeCH" : "CS",
            "nameUIC" : "Lutzelbourg",
            "nameCH" : "IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 215160,
            "codeCH" : "EV",
            "nameUIC" : "Lutzelbourg",
            "nameCH" : "Carré Violet 452",
            "code" : ""
        },
        {
            "codeUIC" : 215178,
            "codeCH" : "CS",
            "nameUIC" : "Hommarting",
            "nameCH" : "IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 215319,
            "codeCH" : 0,
            "nameUIC" : "Loudrefing",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215335,
            "codeCH" : 0,
            "nameUIC" : "Berthelming",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215343,
            "codeCH" : 0,
            "nameUIC" : "Oberstinzel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215350,
            "codeCH" : "BF",
            "nameUIC" : "Sarraltroff",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 215350,
            "codeCH" : "BV",
            "nameUIC" : "Sarraltroff",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 215418,
            "codeCH" : 0,
            "nameUIC" : "Niederstinzel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215426,
            "codeCH" : 0,
            "nameUIC" : "Wolfskirchen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215442,
            "codeCH" : 0,
            "nameUIC" : "Sarrewerden",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215459,
            "codeCH" : 0,
            "nameUIC" : "Sarre-Union",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215483,
            "codeCH" : 0,
            "nameUIC" : "Schopperten",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215491,
            "codeCH" : 0,
            "nameUIC" : "Keskastel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215632,
            "codeCH" : 0,
            "nameUIC" : "Oermingen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215640,
            "codeCH" : 0,
            "nameUIC" : "Voellerdingen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215657,
            "codeCH" : 0,
            "nameUIC" : "Domfessel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215665,
            "codeCH" : 0,
            "nameUIC" : "Diemeringen",
            "nameCH" : "",
            "code" : "DRI"
        },
        {
            "codeUIC" : 215681,
            "codeCH" : 0,
            "nameUIC" : "Adamswiller",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215699,
            "codeCH" : 0,
            "nameUIC" : "Tieffenbach-Struth",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215715,
            "codeCH" : 0,
            "nameUIC" : "Frohmuhl",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215723,
            "codeCH" : 0,
            "nameUIC" : "Puberg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215764,
            "codeCH" : 0,
            "nameUIC" : "Schalbach",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 215772,
            "codeCH" : "BV",
            "nameUIC" : "Drulingen",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DRL"
        },
        {
            "codeUIC" : 215772,
            "codeCH" : "E1",
            "nameUIC" : "Drulingen",
            "nameCH" : "ITE Sotralentz N°1",
            "code" : "DRL"
        },
        {
            "codeUIC" : 215772,
            "codeCH" : "E2",
            "nameUIC" : "Drulingen",
            "nameCH" : "ITE Bieber N°2",
            "code" : "DRL"
        },
        {
            "codeUIC" : 215772,
            "codeCH" : "E3",
            "nameUIC" : "Drulingen",
            "nameCH" : "ITE Sotralentz N°3",
            "code" : "DRL"
        },
        {
            "codeUIC" : 215772,
            "codeCH" : "PA",
            "nameUIC" : "Drulingen",
            "nameCH" : "Pancarte Arrêt",
            "code" : "DRL"
        },
        {
            "codeUIC" : 221143,
            "codeCH" : 0,
            "nameUIC" : "EMT La Plaine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 222497,
            "codeCH" : "BT",
            "nameUIC" : "Longueil-Ste-Marie (TGV)",
            "nameCH" : "Base Travaux",
            "code" : ""
        },
        {
            "codeUIC" : 222497,
            "codeCH" : "PR",
            "nameUIC" : "Longueil-Ste-Marie (TGV)",
            "nameCH" : "Poste 15",
            "code" : ""
        },
        {
            "codeUIC" : 222505,
            "codeCH" : 0,
            "nameUIC" : "Noisy Raccordement",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 222612,
            "codeCH" : 0,
            "nameUIC" : "L'Oraille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 222612,
            "codeCH" : 72,
            "nameUIC" : "L'Oraille",
            "nameCH" : "Sémaphore 72",
            "code" : ""
        },
        {
            "codeUIC" : 222612,
            "codeCH" : 75,
            "nameUIC" : "L'Oraille",
            "nameCH" : "Carré 75",
            "code" : ""
        },
        {
            "codeUIC" : 222612,
            "codeCH" : 79,
            "nameUIC" : "L'Oraille",
            "nameCH" : "Sémaphore 79,6",
            "code" : ""
        },
        {
            "codeUIC" : 223263,
            "codeCH" : "AS",
            "nameUIC" : "Lille-Europe",
            "nameCH" : "Accès Sud Voies A D",
            "code" : ""
        },
        {
            "codeUIC" : 223263,
            "codeCH" : "BE",
            "nameUIC" : "Lille-Europe",
            "nameCH" : "Bifurcation Est",
            "code" : ""
        },
        {
            "codeUIC" : 223263,
            "codeCH" : "BO",
            "nameUIC" : "Lille-Europe",
            "nameCH" : "Bifurcation Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 223263,
            "codeCH" : "BV",
            "nameUIC" : "Lille-Europe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 223263,
            "codeCH" : "PR",
            "nameUIC" : "Lille-Europe",
            "nameCH" : "Poste 41",
            "code" : ""
        },
        {
            "codeUIC" : 223263,
            "codeCH" : "VR",
            "nameUIC" : "Lille-Europe",
            "nameCH" : "Entrée/Sortie Voie VR",
            "code" : ""
        },
        {
            "codeUIC" : 235317,
            "codeCH" : "BM",
            "nameUIC" : "Ravezies",
            "nameCH" : "Bifurcation de Beyreman",
            "code" : ""
        },
        {
            "codeUIC" : 235317,
            "codeCH" : "BN",
            "nameUIC" : "Ravezies",
            "nameCH" : "Bifurcation de Bonnaous",
            "code" : ""
        },
        {
            "codeUIC" : 235317,
            "codeCH" : "BV",
            "nameUIC" : "Ravezies",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 235317,
            "codeCH" : "EB",
            "nameUIC" : "Ravezies",
            "nameCH" : "ITE SBA",
            "code" : ""
        },
        {
            "codeUIC" : 235317,
            "codeCH" : "EP",
            "nameUIC" : "Ravezies",
            "nameCH" : "ITE DERUPPE",
            "code" : ""
        },
        {
            "codeUIC" : 235317,
            "codeCH" : "LV",
            "nameUIC" : "Ravezies",
            "nameCH" : "Poste de la Bifurcation de La Vache",
            "code" : ""
        },
        {
            "codeUIC" : 240465,
            "codeCH" : "RN",
            "nameUIC" : "Chauconin",
            "nameCH" : "Raccordement Nord",
            "code" : ""
        },
        {
            "codeUIC" : 240465,
            "codeCH" : "RS",
            "nameUIC" : "Chauconin",
            "nameCH" : "Raccordement Sud",
            "code" : ""
        },
        {
            "codeUIC" : 240465,
            "codeCH" : 12,
            "nameUIC" : "Chauconin",
            "nameCH" : "Poste 12",
            "code" : ""
        },
        {
            "codeUIC" : 241109,
            "codeCH" : "WB",
            "nameUIC" : "Bruxelles-Midi",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 241315,
            "codeCH" : "BE",
            "nameUIC" : "Gagny Raccordement",
            "nameCH" : "Bifurcation Est",
            "code" : ""
        },
        {
            "codeUIC" : 241315,
            "codeCH" : "BN",
            "nameUIC" : "Gagny Raccordement",
            "nameCH" : "Bifurcation Nord",
            "code" : ""
        },
        {
            "codeUIC" : 241315,
            "codeCH" : "BS",
            "nameUIC" : "Gagny Raccordement",
            "nameCH" : "Bifurcation Sud",
            "code" : ""
        },
        {
            "codeUIC" : 241760,
            "codeCH" : "AN",
            "nameUIC" : "Triangle d'Ostricourt",
            "nameCH" : "Accés Nord PM",
            "code" : ""
        },
        {
            "codeUIC" : 241760,
            "codeCH" : "BD",
            "nameUIC" : "Triangle d'Ostricourt",
            "nameCH" : "Bifurcation de Dourges",
            "code" : ""
        },
        {
            "codeUIC" : 241760,
            "codeCH" : "BE",
            "nameUIC" : "Triangle d'Ostricourt",
            "nameCH" : "Bifurcation de Évin",
            "code" : ""
        },
        {
            "codeUIC" : 241760,
            "codeCH" : "BH",
            "nameUIC" : "Triangle d'Ostricourt",
            "nameCH" : "Bifurcation de Harponlieu",
            "code" : ""
        },
        {
            "codeUIC" : 241760,
            "codeCH" : "BO",
            "nameUIC" : "Triangle d'Ostricourt",
            "nameCH" : "Bifurcation les Onze",
            "code" : ""
        },
        {
            "codeUIC" : 241760,
            "codeCH" : "MR",
            "nameUIC" : "Triangle d'Ostricourt",
            "nameCH" : "Bifurcation de Maison-Rouge",
            "code" : ""
        },
        {
            "codeUIC" : 241877,
            "codeCH" : 0,
            "nameUIC" : "Poste 19 Beuvraignes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 241885,
            "codeCH" : 0,
            "nameUIC" : "Poste 22 Combles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242339,
            "codeCH" : 0,
            "nameUIC" : "Saltatojo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242420,
            "codeCH" : 0,
            "nameUIC" : "Maison d'arrêt de Borgo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242453,
            "codeCH" : 0,
            "nameUIC" : "GR 20 Dolce-Vita",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242461,
            "codeCH" : 0,
            "nameUIC" : "Polyclinique",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242487,
            "codeCH" : "RO",
            "nameUIC" : "Ocquerre",
            "nameCH" : "Raccordement Ocquerre",
            "code" : ""
        },
        {
            "codeUIC" : 242487,
            "codeCH" : "SE",
            "nameUIC" : "Ocquerre",
            "nameCH" : "Poste 13",
            "code" : ""
        },
        {
            "codeUIC" : 242503,
            "codeCH" : 0,
            "nameUIC" : "Poste 14 Coulombs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242529,
            "codeCH" : 0,
            "nameUIC" : "Poste 15 Beuvardes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242545,
            "codeCH" : 0,
            "nameUIC" : "Poste 16 Villers-Agron-Aiguisy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242552,
            "codeCH" : 0,
            "nameUIC" : "Poste 18 Livry-Louvercy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242602,
            "codeCH" : "RP",
            "nameUIC" : "Raccordement Châlons",
            "nameCH" : "Raccordement côté Paris",
            "code" : ""
        },
        {
            "codeUIC" : 242669,
            "codeCH" : "ZE",
            "nameUIC" : "Tilloy-et-Bellay",
            "nameCH" : "Zone d'évitement",
            "code" : ""
        },
        {
            "codeUIC" : 242669,
            "codeCH" : 20,
            "nameUIC" : "Tilloy-et-Bellay",
            "nameCH" : "Poste 20",
            "code" : ""
        },
        {
            "codeUIC" : 242685,
            "codeCH" : 0,
            "nameUIC" : "Poste 21 Villers-en-Argonne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242701,
            "codeCH" : 0,
            "nameUIC" : "Poste 23 Lamorville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242727,
            "codeCH" : 0,
            "nameUIC" : "Poste 24 Prény",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 242743,
            "codeCH" : "RC",
            "nameUIC" : "Raccordement Pagny-Vandières",
            "nameCH" : "Accès RM/RN",
            "code" : ""
        },
        {
            "codeUIC" : 242743,
            "codeCH" : "RM",
            "nameUIC" : "Raccordement Pagny-Vandières",
            "nameCH" : "Raccordement côté Metz",
            "code" : ""
        },
        {
            "codeUIC" : 242743,
            "codeCH" : "RN",
            "nameUIC" : "Raccordement Pagny-Vandières",
            "nameCH" : "Raccordement côté Nancy",
            "code" : ""
        },
        {
            "codeUIC" : 242743,
            "codeCH" : "RP",
            "nameUIC" : "Raccordement Pagny-Vandières",
            "nameCH" : "Raccordement côté Paris",
            "code" : ""
        },
        {
            "codeUIC" : 243972,
            "codeCH" : "RB",
            "nameUIC" : "Raccordement Baudrecourt",
            "nameCH" : "Accès Baudrecourt",
            "code" : ""
        },
        {
            "codeUIC" : 243972,
            "codeCH" : "RH",
            "nameUIC" : "Raccordement Baudrecourt",
            "nameCH" : "Raccordement côté Herny",
            "code" : ""
        },
        {
            "codeUIC" : 243972,
            "codeCH" : "RP",
            "nameUIC" : "Raccordement Baudrecourt",
            "nameCH" : "Raccordement côté Paris",
            "code" : ""
        },
        {
            "codeUIC" : 243972,
            "codeCH" : "1B",
            "nameUIC" : "Raccordement Baudrecourt",
            "nameCH" : "Accès 1B",
            "code" : ""
        },
        {
            "codeUIC" : 243972,
            "codeCH" : "2B",
            "nameUIC" : "Raccordement Baudrecourt",
            "nameCH" : "Accès 2B",
            "code" : ""
        },
        {
            "codeUIC" : 269514,
            "codeCH" : "PR",
            "nameUIC" : "Poste 44 Hondeghem",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 269514,
            "codeCH" : "RC",
            "nameUIC" : "Poste 44 Hondeghem",
            "nameCH" : "Raccordement de Cassel",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "BP",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Jonction BD Périphérique",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "BV",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "DC",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Dépôt de La Chapelle",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "D1",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Jonction DR/RT",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "JN",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Jonction Km 0,9",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "J3",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Jonction Km 3",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "PC",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Repère 3,2",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "P3",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "P4",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Poste Satellite Périphérique",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "P6",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Poste 6",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "P7",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Portique du Landy",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "P8",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Poste 8",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "SI",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Carrés 2402/2401",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "TG",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Tiroir G",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "TL",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Technicentre Landy",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : "VC",
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Carré Violet 426",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : 37,
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Bifurcation de Marcadet",
            "code" : ""
        },
        {
            "codeUIC" : 271007,
            "codeCH" : 44,
            "nameUIC" : "Paris-Nord",
            "nameCH" : "Poste Satelitte Périphérique",
            "code" : ""
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "BV",
            "nameUIC" : "St-Denis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "GI",
            "nameUIC" : "St-Denis",
            "nameCH" : "Garages Impairs",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "GP",
            "nameUIC" : "St-Denis",
            "nameCH" : "Garages Pairs",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "JO",
            "nameUIC" : "St-Denis",
            "nameCH" : "Joncherolles",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "P1",
            "nameUIC" : "St-Denis",
            "nameCH" : "Jonction Sud",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "P2",
            "nameUIC" : "St-Denis",
            "nameCH" : "Portique de Joncherolles",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "P3",
            "nameUIC" : "St-Denis",
            "nameCH" : "Jonction LC/DI",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : "P5",
            "nameUIC" : "St-Denis",
            "nameCH" : "Aiguille Nord Km 8,7",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271015,
            "codeCH" : 68,
            "nameUIC" : "St-Denis",
            "nameCH" : "Pk 6,8",
            "code" : "SDS"
        },
        {
            "codeUIC" : 271023,
            "codeCH" : "LR",
            "nameUIC" : "Paris-Nord-Souterraine",
            "nameCH" : "Limite RATP/SNCF",
            "code" : ""
        },
        {
            "codeUIC" : 271023,
            "codeCH" : 0,
            "nameUIC" : "Paris-Nord-Souterraine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271031,
            "codeCH" : "J1",
            "nameUIC" : "Paris-Nord-Surface",
            "nameCH" : "Jonction Aiguilles 801/802",
            "code" : ""
        },
        {
            "codeUIC" : 271031,
            "codeCH" : 0,
            "nameUIC" : "Paris-Nord-Surface",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271056,
            "codeCH" : 0,
            "nameUIC" : "Poste 13 Moussy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271064,
            "codeCH" : 0,
            "nameUIC" : "Poste 11 Val-Noël",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271072,
            "codeCH" : "BE",
            "nameUIC" : "Poste 12 Vémars",
            "nameCH" : "Bifurcation Est",
            "code" : ""
        },
        {
            "codeUIC" : 271072,
            "codeCH" : "BN",
            "nameUIC" : "Poste 12 Vémars",
            "nameCH" : "Bifurcation Nord",
            "code" : ""
        },
        {
            "codeUIC" : 271072,
            "codeCH" : "BS",
            "nameUIC" : "Poste 12 Vémars",
            "nameCH" : "Bifurcation Sud",
            "code" : ""
        },
        {
            "codeUIC" : 271072,
            "codeCH" : "ER",
            "nameUIC" : "Poste 12 Vémars",
            "nameCH" : "Est Rebrousse",
            "code" : ""
        },
        {
            "codeUIC" : 271072,
            "codeCH" : "NR",
            "nameUIC" : "Poste 12 Vémars",
            "nameCH" : "Nord Rebrousse",
            "code" : ""
        },
        {
            "codeUIC" : 271072,
            "codeCH" : "PR",
            "nameUIC" : "Poste 12 Vémars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271072,
            "codeCH" : "SR",
            "nameUIC" : "Poste 12 Vémars",
            "nameCH" : "Sud Rebrousse",
            "code" : ""
        },
        {
            "codeUIC" : 271106,
            "codeCH" : "CE",
            "nameUIC" : "Paris-Landy",
            "nameCH" : "Centre",
            "code" : ""
        },
        {
            "codeUIC" : 271106,
            "codeCH" : "ML",
            "nameUIC" : "Paris-Landy",
            "nameCH" : "Machine-à-Laver",
            "code" : ""
        },
        {
            "codeUIC" : 271106,
            "codeCH" : "PL",
            "nameUIC" : "Paris-Landy",
            "nameCH" : "Pleyel",
            "code" : ""
        },
        {
            "codeUIC" : 271106,
            "codeCH" : "SD",
            "nameUIC" : "Paris-Landy",
            "nameCH" : "Sud",
            "code" : ""
        },
        {
            "codeUIC" : 271106,
            "codeCH" : "VK",
            "nameUIC" : "Paris-Landy",
            "nameCH" : "Voie K",
            "code" : ""
        },
        {
            "codeUIC" : 271122,
            "codeCH" : "BV",
            "nameUIC" : "Épinay-Villetaneuse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 271122,
            "codeCH" : "GA",
            "nameUIC" : "Épinay-Villetaneuse",
            "nameCH" : "Garages Centraux",
            "code" : ""
        },
        {
            "codeUIC" : 271130,
            "codeCH" : 0,
            "nameUIC" : "Épinay-Grande-Ceinture",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271148,
            "codeCH" : 0,
            "nameUIC" : "Épinay-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271155,
            "codeCH" : 0,
            "nameUIC" : "Stains",
            "nameCH" : "",
            "code" : "STI"
        },
        {
            "codeUIC" : 271163,
            "codeCH" : "BS",
            "nameUIC" : "Pierrefitte-Stains",
            "nameCH" : "Bifurcation Sud",
            "code" : ""
        },
        {
            "codeUIC" : 271163,
            "codeCH" : "BV",
            "nameUIC" : "Pierrefitte-Stains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 271171,
            "codeCH" : 0,
            "nameUIC" : "La Barre-Ormesson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271205,
            "codeCH" : "BV",
            "nameUIC" : "Gennevilliers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GEN"
        },
        {
            "codeUIC" : 271205,
            "codeCH" : "ES",
            "nameUIC" : "Gennevilliers",
            "nameCH" : "",
            "code" : "GEN"
        },
        {
            "codeUIC" : 271205,
            "codeCH" : "GA",
            "nameUIC" : "Gennevilliers",
            "nameCH" : "Garages",
            "code" : "GEN"
        },
        {
            "codeUIC" : 271205,
            "codeCH" : "PO",
            "nameUIC" : "Gennevilliers",
            "nameCH" : "Port",
            "code" : "GEN"
        },
        {
            "codeUIC" : 271221,
            "codeCH" : "C2",
            "nameUIC" : "St-Ouen-les-Docks",
            "nameCH" : "Carré Violet 202",
            "code" : "SOD"
        },
        {
            "codeUIC" : 271221,
            "codeCH" : "GC",
            "nameUIC" : "St-Ouen-les-Docks",
            "nameCH" : "Garages Centraux",
            "code" : "SOD"
        },
        {
            "codeUIC" : 271221,
            "codeCH" : "IG",
            "nameUIC" : "St-Ouen-les-Docks",
            "nameCH" : "Lab IGLE",
            "code" : "SOD"
        },
        {
            "codeUIC" : 271221,
            "codeCH" : "P1",
            "nameUIC" : "St-Ouen-les-Docks",
            "nameCH" : "Poste 1",
            "code" : "SOD"
        },
        {
            "codeUIC" : 271247,
            "codeCH" : "BF",
            "nameUIC" : "St-Ouen",
            "nameCH" : "Bifurcation de St-Ouen",
            "code" : ""
        },
        {
            "codeUIC" : 271247,
            "codeCH" : "BV",
            "nameUIC" : "St-Ouen",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 271288,
            "codeCH" : 0,
            "nameUIC" : "Poste 14 Fresnoy-Le Luat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271296,
            "codeCH" : 0,
            "nameUIC" : "Poste 16 Arsy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271304,
            "codeCH" : "BV",
            "nameUIC" : "Aubervilliers-La Courneuve",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "AB",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Atelier B",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "AT",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Faisceau Attente",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "BD",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Bifurcation de Dugny",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "BM",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Bifurcation du Bas-Martineau",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "CI",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "ITE Citroën",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "DT",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "FO",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Faisceau Formation",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "GA",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "ITE Garonor",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "GP",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "GPF de Blanc-Mesnil",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "LO",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "P1",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "P2",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "P3",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "RB",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Rebroussement",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "RC",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "TI",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Tiroir A",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : "VE",
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "EVB",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : 19,
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Carré Violet 8119",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : 22,
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Carré Violet 8122",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : 40,
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Carré Violet 8240",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : 71,
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Carré Violet 171",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : 88,
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Aiguille Km 8,8",
            "code" : ""
        },
        {
            "codeUIC" : 271312,
            "codeCH" : 91,
            "nameUIC" : "Le Bourget-Triage",
            "nameCH" : "Aiguille Km 9,1",
            "code" : ""
        },
        {
            "codeUIC" : 271320,
            "codeCH" : "BV",
            "nameUIC" : "La Courneuve-Dugny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LCD"
        },
        {
            "codeUIC" : 271320,
            "codeCH" : "HF",
            "nameUIC" : "La Courneuve-Dugny",
            "nameCH" : "ITE Ravitaillement des essences",
            "code" : "LCD"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : "DT",
            "nameUIC" : "Bobigny",
            "nameCH" : "Dépôt",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : "GA",
            "nameUIC" : "Bobigny",
            "nameCH" : "Garages",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : "IT",
            "nameUIC" : "Bobigny",
            "nameCH" : "ITE SGP",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : "P1",
            "nameUIC" : "Bobigny",
            "nameCH" : "Poste 1",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : "P2",
            "nameUIC" : "Bobigny",
            "nameCH" : "Poste 2",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : "ST",
            "nameUIC" : "Bobigny",
            "nameCH" : "Bifurcation de Stains",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : "TL",
            "nameUIC" : "Bobigny",
            "nameCH" : "Entrée/Sortie TLN",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : 14,
            "nameUIC" : "Bobigny",
            "nameCH" : "Carré Violet 214",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : 25,
            "nameUIC" : "Bobigny",
            "nameCH" : "Ex 25",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : 55,
            "nameUIC" : "Bobigny",
            "nameCH" : "Aiguille Km 55,9",
            "code" : "PTB"
        },
        {
            "codeUIC" : 271338,
            "codeCH" : 84,
            "nameUIC" : "Bobigny",
            "nameCH" : 84,
            "code" : "PTB"
        },
        {
            "codeUIC" : 271346,
            "codeCH" : "GA",
            "nameUIC" : "Villemonble",
            "nameCH" : "Garages",
            "code" : ""
        },
        {
            "codeUIC" : 271346,
            "codeCH" : "PA",
            "nameUIC" : "Villemonble",
            "nameCH" : "Poste A",
            "code" : ""
        },
        {
            "codeUIC" : 271346,
            "codeCH" : "P1",
            "nameUIC" : "Villemonble",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 271353,
            "codeCH" : 0,
            "nameUIC" : "Neuilly-sur-Marne",
            "nameCH" : "",
            "code" : "NYM"
        },
        {
            "codeUIC" : 271361,
            "codeCH" : "BB",
            "nameUIC" : "Bry-sur-Marne",
            "nameCH" : "Bifurcation de Bry-sur-Marne",
            "code" : "BMA"
        },
        {
            "codeUIC" : 271361,
            "codeCH" : "BV",
            "nameUIC" : "Bry-sur-Marne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BMA"
        },
        {
            "codeUIC" : 271379,
            "codeCH" : 0,
            "nameUIC" : "Chennevières-sur-Marne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "DT",
            "nameUIC" : "Valenton",
            "nameCH" : "Dépôt",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "GA",
            "nameUIC" : "Valenton",
            "nameCH" : "Garages",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "GC",
            "nameUIC" : "Valenton",
            "nameCH" : "Bifurcation GC/GC Bis",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "JN",
            "nameUIC" : "Valenton",
            "nameCH" : "Jonction Km 77,9",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "NA",
            "nameUIC" : "Valenton",
            "nameCH" : "Accès Nord Voie A",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "PT",
            "nameUIC" : "Valenton",
            "nameCH" : "CMT",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "PV",
            "nameUIC" : "Valenton",
            "nameCH" : "Poste V",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "P1",
            "nameUIC" : "Valenton",
            "nameCH" : "Poste 1",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "P2",
            "nameUIC" : "Valenton",
            "nameCH" : "Poste 2 Sud",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "RN",
            "nameUIC" : "Valenton",
            "nameCH" : "Raccordement Nord",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : "SA",
            "nameUIC" : "Valenton",
            "nameCH" : "Accès Sud Voie A",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : 44,
            "nameUIC" : "Valenton",
            "nameCH" : "Aiguille Km 78,4",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : 81,
            "nameUIC" : "Valenton",
            "nameCH" : "Aiguille Km 81,9",
            "code" : "VON"
        },
        {
            "codeUIC" : 271387,
            "codeCH" : 82,
            "nameUIC" : "Valenton",
            "nameCH" : "Aig Km 82,7",
            "code" : "VON"
        },
        {
            "codeUIC" : 271395,
            "codeCH" : 0,
            "nameUIC" : "Le Bourget",
            "nameCH" : "",
            "code" : "LBG"
        },
        {
            "codeUIC" : 271403,
            "codeCH" : 0,
            "nameUIC" : "Drancy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271411,
            "codeCH" : "AN",
            "nameUIC" : "Aulnay-sous-Bois",
            "nameCH" : "Aiguille Nord",
            "code" : ""
        },
        {
            "codeUIC" : 271411,
            "codeCH" : "AS",
            "nameUIC" : "Aulnay-sous-Bois",
            "nameCH" : "Aiguille Sud",
            "code" : ""
        },
        {
            "codeUIC" : 271411,
            "codeCH" : "BV",
            "nameUIC" : "Aulnay-sous-Bois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 271411,
            "codeCH" : "GV",
            "nameUIC" : "Aulnay-sous-Bois",
            "nameCH" : "Voie GV",
            "code" : ""
        },
        {
            "codeUIC" : 271429,
            "codeCH" : 0,
            "nameUIC" : "Sevran-Livry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271437,
            "codeCH" : 0,
            "nameUIC" : "Vert-Galant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271445,
            "codeCH" : 0,
            "nameUIC" : "Sevran-Beaudottes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271452,
            "codeCH" : 0,
            "nameUIC" : "Villepinte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271460,
            "codeCH" : 0,
            "nameUIC" : "Roissy-Aéroport-CDG 1",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271478,
            "codeCH" : 0,
            "nameUIC" : "Le Blanc-Mesnil",
            "nameCH" : "",
            "code" : "LBM"
        },
        {
            "codeUIC" : 271486,
            "codeCH" : 0,
            "nameUIC" : "Parc-des-Expositions",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271494,
            "codeCH" : "AN",
            "nameUIC" : "Roissy-Aéroport-CDG 2",
            "nameCH" : "Aiguille Nord",
            "code" : ""
        },
        {
            "codeUIC" : 271494,
            "codeCH" : "AS",
            "nameUIC" : "Roissy-Aéroport-CDG 2",
            "nameCH" : "Aiguille Sud",
            "code" : ""
        },
        {
            "codeUIC" : 271494,
            "codeCH" : "BV",
            "nameUIC" : "Roissy-Aéroport-CDG 2",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 271494,
            "codeCH" : "PR",
            "nameUIC" : "Roissy-Aéroport-CDG 2",
            "nameCH" : "Poste 77",
            "code" : ""
        },
        {
            "codeUIC" : 271505,
            "codeCH" : "GA",
            "nameUIC" : "Gennevilliers / Garages",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271510,
            "codeCH" : 0,
            "nameUIC" : "Villeparisis",
            "nameCH" : "",
            "code" : "VII"
        },
        {
            "codeUIC" : 271510,
            "codeCH" : 9,
            "nameUIC" : "Villeparisis",
            "nameCH" : "Carré Violet 7709",
            "code" : "VII"
        },
        {
            "codeUIC" : 271528,
            "codeCH" : "A1",
            "nameUIC" : "Mitry-Claye",
            "nameCH" : "Accès Côté Paris",
            "code" : "MY"
        },
        {
            "codeUIC" : 271528,
            "codeCH" : "SA",
            "nameUIC" : "Mitry-Claye",
            "nameCH" : "Accès SAS et Garage",
            "code" : "MY"
        },
        {
            "codeUIC" : 271528,
            "codeCH" : "TM",
            "nameUIC" : "Mitry-Claye",
            "nameCH" : "Tiroir MaL",
            "code" : "MY"
        },
        {
            "codeUIC" : 271528,
            "codeCH" : 0,
            "nameUIC" : "Mitry-Claye",
            "nameCH" : "",
            "code" : "MY"
        },
        {
            "codeUIC" : 271536,
            "codeCH" : 0,
            "nameUIC" : "Dammartin-Juilly-St-Mard",
            "nameCH" : "",
            "code" : "DAM"
        },
        {
            "codeUIC" : 271551,
            "codeCH" : 0,
            "nameUIC" : "Le Plessis-Belleville",
            "nameCH" : "",
            "code" : "LEB"
        },
        {
            "codeUIC" : 271569,
            "codeCH" : 0,
            "nameUIC" : "La Baraque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271577,
            "codeCH" : 0,
            "nameUIC" : "Nanteuil-le-Haudouin",
            "nameCH" : "",
            "code" : "NAH"
        },
        {
            "codeUIC" : 271585,
            "codeCH" : "C3",
            "nameUIC" : "Ormoy-Villers",
            "nameCH" : "Carré Violet 103",
            "code" : "ORM"
        },
        {
            "codeUIC" : 271585,
            "codeCH" : "C8",
            "nameUIC" : "Ormoy-Villers",
            "nameCH" : "Carré 108",
            "code" : "ORM"
        },
        {
            "codeUIC" : 271585,
            "codeCH" : "S1",
            "nameUIC" : "Ormoy-Villers",
            "nameCH" : "Sémaphore 121",
            "code" : "ORM"
        },
        {
            "codeUIC" : 271585,
            "codeCH" : 0,
            "nameUIC" : "Ormoy-Villers",
            "nameCH" : "",
            "code" : "ORM"
        },
        {
            "codeUIC" : 271593,
            "codeCH" : 0,
            "nameUIC" : "Crépy-en-Valois",
            "nameCH" : "",
            "code" : "CYV"
        },
        {
            "codeUIC" : 271601,
            "codeCH" : 0,
            "nameUIC" : "Vaumoise",
            "nameCH" : "",
            "code" : "VAU"
        },
        {
            "codeUIC" : 271619,
            "codeCH" : 0,
            "nameUIC" : "Duvy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271627,
            "codeCH" : 0,
            "nameUIC" : "Béthisy-St-Pierre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271734,
            "codeCH" : 0,
            "nameUIC" : "Villers-Cotterêts",
            "nameCH" : "",
            "code" : "VCT"
        },
        {
            "codeUIC" : 271775,
            "codeCH" : 0,
            "nameUIC" : "Longpont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 271783,
            "codeCH" : "BV",
            "nameUIC" : "Vierzy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VYA"
        },
        {
            "codeUIC" : 271783,
            "codeCH" : 94,
            "nameUIC" : "Vierzy",
            "nameCH" : "Aiguille Km 94,1",
            "code" : "VYA"
        },
        {
            "codeUIC" : 271783,
            "codeCH" : 96,
            "nameUIC" : "Vierzy",
            "nameCH" : "Aiguille Km 96,0",
            "code" : "VYA"
        },
        {
            "codeUIC" : 271817,
            "codeCH" : "BV",
            "nameUIC" : "Soissons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SOI"
        },
        {
            "codeUIC" : 271817,
            "codeCH" : "CV",
            "nameUIC" : "Soissons",
            "nameCH" : "Carré Violet 103",
            "code" : "SOI"
        },
        {
            "codeUIC" : 271817,
            "codeCH" : "EC",
            "nameUIC" : "Soissons",
            "nameCH" : "ITE Cartonneries",
            "code" : "SOI"
        },
        {
            "codeUIC" : 271817,
            "codeCH" : "EE",
            "nameUIC" : "Soissons",
            "nameCH" : "ITE Equipement",
            "code" : "SOI"
        },
        {
            "codeUIC" : 271817,
            "codeCH" : "EL",
            "nameUIC" : "Soissons",
            "nameCH" : "ITE Km 0,7",
            "code" : "SOI"
        },
        {
            "codeUIC" : 271817,
            "codeCH" : "EV",
            "nameUIC" : "Soissons",
            "nameCH" : "ITE Ville",
            "code" : "SOI"
        },
        {
            "codeUIC" : 271858,
            "codeCH" : "BV",
            "nameUIC" : "Venizel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VL"
        },
        {
            "codeUIC" : 271858,
            "codeCH" : "EP",
            "nameUIC" : "Venizel",
            "nameCH" : "ITE Rochette",
            "code" : "VL"
        },
        {
            "codeUIC" : 271866,
            "codeCH" : "BV",
            "nameUIC" : "Ciry-Sermoise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CYS"
        },
        {
            "codeUIC" : 271866,
            "codeCH" : "EP",
            "nameUIC" : "Ciry-Sermoise",
            "nameCH" : "ITE R.Morin",
            "code" : "CYS"
        },
        {
            "codeUIC" : 271874,
            "codeCH" : 0,
            "nameUIC" : "Crouy",
            "nameCH" : "",
            "code" : "CRU"
        },
        {
            "codeUIC" : 271882,
            "codeCH" : 0,
            "nameUIC" : "Margival",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272013,
            "codeCH" : 0,
            "nameUIC" : "Jaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272021,
            "codeCH" : 0,
            "nameUIC" : "Villaines (Val-d'Oise)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272039,
            "codeCH" : 0,
            "nameUIC" : "Seugy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272047,
            "codeCH" : 0,
            "nameUIC" : "Compans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272054,
            "codeCH" : 0,
            "nameUIC" : "Thieux-Nantouillet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272062,
            "codeCH" : 0,
            "nameUIC" : "Choisy-au-Bac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272070,
            "codeCH" : 0,
            "nameUIC" : "Les Prés-Roseaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272096,
            "codeCH" : 0,
            "nameUIC" : "Esches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272146,
            "codeCH" : 0,
            "nameUIC" : "Les Grésillons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272179,
            "codeCH" : "S4",
            "nameUIC" : "Orrouy-Glaignes",
            "nameCH" : "Sémaphore 124",
            "code" : ""
        },
        {
            "codeUIC" : 272179,
            "codeCH" : 0,
            "nameUIC" : "Orrouy-Glaignes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272195,
            "codeCH" : 0,
            "nameUIC" : "Longueil-Annel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272203,
            "codeCH" : 0,
            "nameUIC" : "Pont-l'Évêque-sur-Oise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 272286,
            "codeCH" : 0,
            "nameUIC" : "Corcy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 275205,
            "codeCH" : "SI",
            "nameUIC" : "Gennevilliers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "AT",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Atelier Voies des Wagons",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "DT",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "JN",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Jonctions Nord",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "JS",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Jonction Sud",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "PD",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Point D",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "P1",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "P2",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "P3",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "RC",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "RL",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Faisceau Relais",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "TR",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "US",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "ITE SOLLAC",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : "VD",
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : 17,
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Carré Violet 315 et 317",
            "code" : ""
        },
        {
            "codeUIC" : 275990,
            "codeCH" : 36,
            "nameUIC" : "Le Petit-Thérain",
            "nameCH" : "Carré Violet 360",
            "code" : ""
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "BB",
            "nameUIC" : "Creil",
            "nameCH" : "Bifurcation de Bonvilliers",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "BL",
            "nameUIC" : "Creil",
            "nameCH" : "Bifurcation de Laversine",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "BN",
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "BS",
            "nameUIC" : "Creil",
            "nameCH" : "BIF SUD",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "BV",
            "nameUIC" : "Creil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "CV",
            "nameUIC" : "Creil",
            "nameCH" : "Cv 5025",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "JN",
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "JS",
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "J1",
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "P1",
            "nameUIC" : "Creil",
            "nameCH" : "Poste 1",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "P3",
            "nameUIC" : "Creil",
            "nameCH" : "Poste 3",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "P5",
            "nameUIC" : "Creil",
            "nameCH" : "Poste 5 (Bif)",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "RA",
            "nameUIC" : "Creil",
            "nameCH" : "Plaine",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : "ZI",
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : 2,
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : 4,
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : 5,
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : 32,
            "nameUIC" : "Creil",
            "nameCH" : "Aiguille 32",
            "code" : "CL"
        },
        {
            "codeUIC" : 276006,
            "codeCH" : 32,
            "nameUIC" : "Creil",
            "nameCH" : "",
            "code" : "CL"
        },
        {
            "codeUIC" : 276022,
            "codeCH" : 0,
            "nameUIC" : "Enghien-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276030,
            "codeCH" : 0,
            "nameUIC" : "Champ-de-Courses-d'Enghien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276055,
            "codeCH" : "AI",
            "nameUIC" : "Ermont-Eaubonne",
            "nameCH" : "Aiguilles",
            "code" : ""
        },
        {
            "codeUIC" : 276055,
            "codeCH" : "BV",
            "nameUIC" : "Ermont-Eaubonne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276055,
            "codeCH" : "GI",
            "nameUIC" : "Ermont-Eaubonne",
            "nameCH" : "Garages Impairs",
            "code" : ""
        },
        {
            "codeUIC" : 276055,
            "codeCH" : "GP",
            "nameUIC" : "Ermont-Eaubonne",
            "nameCH" : "Garage Pair",
            "code" : ""
        },
        {
            "codeUIC" : 276055,
            "codeCH" : "P1",
            "nameUIC" : "Ermont-Eaubonne",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 276063,
            "codeCH" : 0,
            "nameUIC" : "Cernay (Val-d'Oise)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276071,
            "codeCH" : 0,
            "nameUIC" : "Franconville-Plessis-Bouchard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276089,
            "codeCH" : "BV",
            "nameUIC" : "Montigny-Beauchamp",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276089,
            "codeCH" : "ML",
            "nameUIC" : "Montigny-Beauchamp",
            "nameCH" : "ITE DEHE COGIFER",
            "code" : ""
        },
        {
            "codeUIC" : 276089,
            "codeCH" : "TI",
            "nameUIC" : "Montigny-Beauchamp",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 276097,
            "codeCH" : 0,
            "nameUIC" : "Pierrelaye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276105,
            "codeCH" : 0,
            "nameUIC" : "St-Ouen-l'Aumône",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276113,
            "codeCH" : 0,
            "nameUIC" : "Chantilly-Gouvieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276121,
            "codeCH" : 0,
            "nameUIC" : "St-Maximin-Marchandises",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276139,
            "codeCH" : "BL",
            "nameUIC" : "Pontoise",
            "nameCH" : "Bifurcation de Liesse",
            "code" : ""
        },
        {
            "codeUIC" : 276139,
            "codeCH" : "BV",
            "nameUIC" : "Pontoise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276147,
            "codeCH" : "BV",
            "nameUIC" : "Épluches",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EPL"
        },
        {
            "codeUIC" : 276147,
            "codeCH" : "GI",
            "nameUIC" : "Épluches",
            "nameCH" : "Garage Impair",
            "code" : "EPL"
        },
        {
            "codeUIC" : 276147,
            "codeCH" : 0,
            "nameUIC" : "Épluches",
            "nameCH" : "",
            "code" : "EPL"
        },
        {
            "codeUIC" : 276154,
            "codeCH" : 0,
            "nameUIC" : "Pont-Petit",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276162,
            "codeCH" : 0,
            "nameUIC" : "Chaponval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276170,
            "codeCH" : 0,
            "nameUIC" : "St-Gratien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276188,
            "codeCH" : 0,
            "nameUIC" : "Sannois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276196,
            "codeCH" : 0,
            "nameUIC" : "Garges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276204,
            "codeCH" : 0,
            "nameUIC" : "Laigneville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276212,
            "codeCH" : 0,
            "nameUIC" : "Liancourt-Rantigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276220,
            "codeCH" : "BL",
            "nameUIC" : "Villiers-le-Bel-Gonesse",
            "nameCH" : "Bifurcation de Gonesse",
            "code" : ""
        },
        {
            "codeUIC" : 276220,
            "codeCH" : "BV",
            "nameUIC" : "Villiers-le-Bel-Gonesse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276220,
            "codeCH" : "1M",
            "nameUIC" : "Villiers-le-Bel-Gonesse",
            "nameCH" : "Voie 1M Côté Creil",
            "code" : ""
        },
        {
            "codeUIC" : 276220,
            "codeCH" : "2M",
            "nameUIC" : "Villiers-le-Bel-Gonesse",
            "nameCH" : "V2M côté Vemars",
            "code" : ""
        },
        {
            "codeUIC" : 276238,
            "codeCH" : 0,
            "nameUIC" : "Les Noues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276246,
            "codeCH" : "BV",
            "nameUIC" : "Goussainville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GOU"
        },
        {
            "codeUIC" : 276246,
            "codeCH" : "GR",
            "nameUIC" : "Goussainville",
            "nameCH" : "Garage",
            "code" : "GOU"
        },
        {
            "codeUIC" : 276246,
            "codeCH" : "TG",
            "nameUIC" : "Goussainville",
            "nameCH" : "Tiroirs",
            "code" : "GOU"
        },
        {
            "codeUIC" : 276246,
            "codeCH" : 0,
            "nameUIC" : "Goussainville",
            "nameCH" : "",
            "code" : "GOU"
        },
        {
            "codeUIC" : 276246,
            "codeCH" : 12,
            "nameUIC" : "Goussainville",
            "nameCH" : "Carré Violet 12",
            "code" : "GOU"
        },
        {
            "codeUIC" : 276253,
            "codeCH" : 0,
            "nameUIC" : "Louvres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276261,
            "codeCH" : 0,
            "nameUIC" : "Survilliers-Fosses",
            "nameCH" : "",
            "code" : "SUR"
        },
        {
            "codeUIC" : 276279,
            "codeCH" : "BV",
            "nameUIC" : "Orry-la-Ville-Coye",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276279,
            "codeCH" : "TG",
            "nameUIC" : "Orry-la-Ville-Coye",
            "nameCH" : "Tiroir G",
            "code" : ""
        },
        {
            "codeUIC" : 276279,
            "codeCH" : 33,
            "nameUIC" : "Orry-la-Ville-Coye",
            "nameCH" : "Aiguille Km 33,7",
            "code" : ""
        },
        {
            "codeUIC" : 276279,
            "codeCH" : 35,
            "nameUIC" : "Orry-la-Ville-Coye",
            "nameCH" : "Aiguille Km 35,5",
            "code" : ""
        },
        {
            "codeUIC" : 276279,
            "codeCH" : 36,
            "nameUIC" : "Orry-la-Ville-Coye",
            "nameCH" : "Aiguille Km 35,8",
            "code" : ""
        },
        {
            "codeUIC" : 276287,
            "codeCH" : 0,
            "nameUIC" : "La Borne-Blanche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276295,
            "codeCH" : "AG",
            "nameUIC" : "Verberie",
            "nameCH" : "Bifurcation d'Ageux",
            "code" : "VBE"
        },
        {
            "codeUIC" : 276295,
            "codeCH" : "BV",
            "nameUIC" : "Verberie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VBE"
        },
        {
            "codeUIC" : 276295,
            "codeCH" : "C1",
            "nameUIC" : "Verberie",
            "nameCH" : "Carré 101",
            "code" : "VBE"
        },
        {
            "codeUIC" : 276295,
            "codeCH" : "EN",
            "nameUIC" : "Verberie",
            "nameCH" : "ITE Engrais de Longueil",
            "code" : "VBE"
        },
        {
            "codeUIC" : 276295,
            "codeCH" : "GA",
            "nameUIC" : "Verberie",
            "nameCH" : "Garages",
            "code" : "VBE"
        },
        {
            "codeUIC" : 276295,
            "codeCH" : "S2",
            "nameUIC" : "Verberie",
            "nameCH" : "Carré 122",
            "code" : "VBE"
        },
        {
            "codeUIC" : 276303,
            "codeCH" : "BN",
            "nameUIC" : "Longueil-Ste-Marie",
            "nameCH" : "Bifurcation Nord",
            "code" : "LSM"
        },
        {
            "codeUIC" : 276303,
            "codeCH" : "BR",
            "nameUIC" : "Longueil-Ste-Marie",
            "nameCH" : "Bifurcation de Rivecourt",
            "code" : "LSM"
        },
        {
            "codeUIC" : 276303,
            "codeCH" : "BV",
            "nameUIC" : "Longueil-Ste-Marie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LSM"
        },
        {
            "codeUIC" : 276303,
            "codeCH" : "V4",
            "nameUIC" : "Longueil-Ste-Marie",
            "nameCH" : "Voie 4",
            "code" : "LSM"
        },
        {
            "codeUIC" : 276311,
            "codeCH" : 0,
            "nameUIC" : "Chevrières",
            "nameCH" : "",
            "code" : "CVE"
        },
        {
            "codeUIC" : 276329,
            "codeCH" : "BV",
            "nameUIC" : "Pont-Ste-Maxence",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276329,
            "codeCH" : "EP",
            "nameUIC" : "Pont-Ste-Maxence",
            "nameCH" : "ITE Semmap",
            "code" : ""
        },
        {
            "codeUIC" : 276345,
            "codeCH" : 0,
            "nameUIC" : "Deuil-Montmagny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276352,
            "codeCH" : 0,
            "nameUIC" : "Rieux-Angicourt",
            "nameCH" : "",
            "code" : "RIA"
        },
        {
            "codeUIC" : 276360,
            "codeCH" : 0,
            "nameUIC" : "Groslay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276378,
            "codeCH" : 0,
            "nameUIC" : "Villers-St-Paul",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276386,
            "codeCH" : 0,
            "nameUIC" : "Sarcelles-St-Brice",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276394,
            "codeCH" : 0,
            "nameUIC" : "Écouen-Ézanville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276402,
            "codeCH" : 0,
            "nameUIC" : "St-Leu-d'Esserent",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276410,
            "codeCH" : "BP",
            "nameUIC" : "Précy-sur-Oise",
            "nameCH" : "ITE BMP",
            "code" : ""
        },
        {
            "codeUIC" : 276410,
            "codeCH" : "BV",
            "nameUIC" : "Précy-sur-Oise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276428,
            "codeCH" : 0,
            "nameUIC" : "Les Galluaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276436,
            "codeCH" : 0,
            "nameUIC" : "Domont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276444,
            "codeCH" : 0,
            "nameUIC" : "Boran-sur-Oise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276451,
            "codeCH" : 0,
            "nameUIC" : "Bruyères-sur-Oise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276469,
            "codeCH" : "BE",
            "nameUIC" : "Persan-Beaumont",
            "nameCH" : "Garage de Bernes",
            "code" : "PEB"
        },
        {
            "codeUIC" : 276469,
            "codeCH" : "BF",
            "nameUIC" : "Persan-Beaumont",
            "nameCH" : "Bifurcation de Persan",
            "code" : "PEB"
        },
        {
            "codeUIC" : 276469,
            "codeCH" : "BV",
            "nameUIC" : "Persan-Beaumont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PEB"
        },
        {
            "codeUIC" : 276469,
            "codeCH" : "DA",
            "nameUIC" : "Persan-Beaumont",
            "nameCH" : "ITE Davum",
            "code" : "PEB"
        },
        {
            "codeUIC" : 276469,
            "codeCH" : "P2",
            "nameUIC" : "Persan-Beaumont",
            "nameCH" : "Bifurcation de Beaumont",
            "code" : "PEB"
        },
        {
            "codeUIC" : 276477,
            "codeCH" : "BV",
            "nameUIC" : "Moulin-Neuf (Oise)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MOF"
        },
        {
            "codeUIC" : 276477,
            "codeCH" : "GA",
            "nameUIC" : "Moulin-Neuf (Oise)",
            "nameCH" : "Garages",
            "code" : "MOF"
        },
        {
            "codeUIC" : 276485,
            "codeCH" : 0,
            "nameUIC" : "Bouffemont-Moisselles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276493,
            "codeCH" : "AN",
            "nameUIC" : "Montsoult-Maffliers",
            "nameCH" : "Aiguille Nord",
            "code" : ""
        },
        {
            "codeUIC" : 276493,
            "codeCH" : "AS",
            "nameUIC" : "Montsoult-Maffliers",
            "nameCH" : "Aiguille Sud",
            "code" : ""
        },
        {
            "codeUIC" : 276493,
            "codeCH" : "BV",
            "nameUIC" : "Montsoult-Maffliers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 276501,
            "codeCH" : 0,
            "nameUIC" : "Presles-Courcelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276519,
            "codeCH" : 0,
            "nameUIC" : "Champagne-sur-Oise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276527,
            "codeCH" : 0,
            "nameUIC" : "L'Isle-Adam-Parmain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276535,
            "codeCH" : 0,
            "nameUIC" : "Valmondois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276543,
            "codeCH" : 0,
            "nameUIC" : "Auvers-sur-Oise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276550,
            "codeCH" : 0,
            "nameUIC" : "Belloy-St-Martin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276568,
            "codeCH" : 0,
            "nameUIC" : "Viarmes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276576,
            "codeCH" : 0,
            "nameUIC" : "Luzarches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276584,
            "codeCH" : 0,
            "nameUIC" : "Ermont-Halte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276592,
            "codeCH" : 0,
            "nameUIC" : "Gros-Noyer-St-Prix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276600,
            "codeCH" : 0,
            "nameUIC" : "St-Leu-la-Forêt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276618,
            "codeCH" : "AI",
            "nameUIC" : "Montataire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276618,
            "codeCH" : 0,
            "nameUIC" : "Montataire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276626,
            "codeCH" : 0,
            "nameUIC" : "Vaucelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276634,
            "codeCH" : 0,
            "nameUIC" : "Taverny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276642,
            "codeCH" : 0,
            "nameUIC" : "Bessancourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276659,
            "codeCH" : 0,
            "nameUIC" : "Frépillon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276667,
            "codeCH" : 0,
            "nameUIC" : "Méry (Val-d'Oise)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276675,
            "codeCH" : 0,
            "nameUIC" : "Mériel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276691,
            "codeCH" : "BV",
            "nameUIC" : "Compiègne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CPE"
        },
        {
            "codeUIC" : 276691,
            "codeCH" : "EN",
            "nameUIC" : "Compiègne",
            "nameCH" : "ITE Maille",
            "code" : "CPE"
        },
        {
            "codeUIC" : 276691,
            "codeCH" : "GA",
            "nameUIC" : "Compiègne",
            "nameCH" : "Garage Pair",
            "code" : "CPE"
        },
        {
            "codeUIC" : 276691,
            "codeCH" : "GB",
            "nameUIC" : "Compiègne",
            "nameCH" : "Faisceau Garage Bellicart",
            "code" : "CPE"
        },
        {
            "codeUIC" : 276691,
            "codeCH" : "GI",
            "nameUIC" : "Compiègne",
            "nameCH" : "Garage Impair",
            "code" : "CPE"
        },
        {
            "codeUIC" : 276691,
            "codeCH" : "NO",
            "nameUIC" : "Compiègne",
            "nameCH" : "ITE Nourylande",
            "code" : "CPE"
        },
        {
            "codeUIC" : 276717,
            "codeCH" : 0,
            "nameUIC" : "Bornel-Belle-Église",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276725,
            "codeCH" : 0,
            "nameUIC" : "Chambly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276733,
            "codeCH" : 0,
            "nameUIC" : "Le Meux-la-Croix-St-Ouen",
            "nameCH" : "",
            "code" : "LET"
        },
        {
            "codeUIC" : 276741,
            "codeCH" : 0,
            "nameUIC" : "Thourotte",
            "nameCH" : "",
            "code" : "TOE"
        },
        {
            "codeUIC" : 276758,
            "codeCH" : 0,
            "nameUIC" : "Nointel-Mours",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276766,
            "codeCH" : 0,
            "nameUIC" : "Ribécourt",
            "nameCH" : "",
            "code" : "RIB"
        },
        {
            "codeUIC" : 276774,
            "codeCH" : 0,
            "nameUIC" : "Ourscamps",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 276782,
            "codeCH" : "V3",
            "nameUIC" : "Noyon",
            "nameCH" : "Voie 3",
            "code" : "NOY"
        },
        {
            "codeUIC" : 276782,
            "codeCH" : 0,
            "nameUIC" : "Noyon",
            "nameCH" : "",
            "code" : "NOY"
        },
        {
            "codeUIC" : 276824,
            "codeCH" : 0,
            "nameUIC" : "Ressons-sur-Matz",
            "nameCH" : "Poste 17",
            "code" : ""
        },
        {
            "codeUIC" : 276865,
            "codeCH" : 0,
            "nameUIC" : "Rethondes",
            "nameCH" : "",
            "code" : "RHD"
        },
        {
            "codeUIC" : 276873,
            "codeCH" : 0,
            "nameUIC" : "Lamotte-Breuil",
            "nameCH" : "",
            "code" : "LBW"
        },
        {
            "codeUIC" : 276881,
            "codeCH" : 0,
            "nameUIC" : "Attichy",
            "nameCH" : "",
            "code" : "AHY"
        },
        {
            "codeUIC" : 276899,
            "codeCH" : "BV",
            "nameUIC" : "Vic-sur-Aisne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VSA"
        },
        {
            "codeUIC" : 276899,
            "codeCH" : "EP",
            "nameUIC" : "Vic-sur-Aisne",
            "nameCH" : "ITE",
            "code" : "VSA"
        },
        {
            "codeUIC" : 276899,
            "codeCH" : "JA",
            "nameUIC" : "Vic-sur-Aisne",
            "nameCH" : "Pancarte Arrêt",
            "code" : "VSA"
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "BC",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Bifurcation de La Chapelle",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "CT",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "CTP Poste de l'Évangile",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "C7",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Carré 807",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "DP",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "EC",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Voies d'Échange",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "ES",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Évangile Sud",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "G2",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Groupe 2",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "IN",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Inter",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "JN",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Jonction Km 3,4",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "PC",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Poste C",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "PE",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Poste E",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "PL",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "La Plaine",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "PV",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Poste de l'Évangile",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "P5",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Poste 5",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "P6",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Poste 6",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : "RA",
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Rampes",
            "code" : ""
        },
        {
            "codeUIC" : 278002,
            "codeCH" : 23,
            "nameUIC" : "Paris-La Chapelle",
            "nameCH" : "Carré 423",
            "code" : ""
        },
        {
            "codeUIC" : 278143,
            "codeCH" : 0,
            "nameUIC" : "La Plaine-Voyageurs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 278242,
            "codeCH" : "BT",
            "nameUIC" : "La Glacière-Gentilly",
            "nameCH" : "Bifurcation de Tolbiac",
            "code" : ""
        },
        {
            "codeUIC" : 278242,
            "codeCH" : "LR",
            "nameUIC" : "La Glacière-Gentilly",
            "nameCH" : "La Rapée",
            "code" : ""
        },
        {
            "codeUIC" : 278242,
            "codeCH" : "PL",
            "nameUIC" : "La Glacière-Gentilly",
            "nameCH" : "Bifurcation Pont-de-Lyon",
            "code" : ""
        },
        {
            "codeUIC" : 278242,
            "codeCH" : "PN",
            "nameUIC" : "La Glacière-Gentilly",
            "nameCH" : "Poste du Pont National",
            "code" : ""
        },
        {
            "codeUIC" : 278259,
            "codeCH" : "BF",
            "nameUIC" : "Arlac",
            "nameCH" : "Bifurcation Pessac",
            "code" : ""
        },
        {
            "codeUIC" : 278259,
            "codeCH" : 0,
            "nameUIC" : "Arlac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 278259,
            "codeCH" : 63,
            "nameUIC" : "Arlac",
            "nameCH" : "Carré Violet 6306",
            "code" : ""
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "BV",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "DU",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Arrêt des Dunes",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "ES",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Entrée/Sortie Dunkerque",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "FL",
            "nameUIC" : "Dunkerque",
            "nameCH" : "ITE Fort-Louis",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "JB",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Jonction Bray-Dunes",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "K3",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Km 301,3",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "PD",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Relais Port PD",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "PF",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Poste F",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "P3",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Poste 3",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "P4",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Poste 4",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : 35,
            "nameUIC" : "Dunkerque",
            "nameCH" : "Panneau Km 306,635",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : "7P",
            "nameUIC" : "Dunkerque",
            "nameCH" : "Bifurcation des 7 Planètes",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281006,
            "codeCH" : 90,
            "nameUIC" : "Dunkerque",
            "nameCH" : "Panneau Km 306,690",
            "code" : "DUK"
        },
        {
            "codeUIC" : 281022,
            "codeCH" : 0,
            "nameUIC" : "Petite-Synthe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281055,
            "codeCH" : 0,
            "nameUIC" : "Croix-l'Allumette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "AC",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Km 290 Accès Faisceau Tunnel",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "AN",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Accès Voie V1R",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "AO",
            "nameUIC" : "Calais-Frethun",
            "nameCH" : "Accès Offerkerque",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "AS",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Carré 103",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "AT",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Km 288 Accès Faisceau Tunnel",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "A1",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Accès LGV/Faisceau Tunnel",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "BC",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Bifurcation de Coulogne",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "BF",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Bifurcation de Calais",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "BV",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "C1",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Carré 321",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "DT",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "E1",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "ITE Calaire Chimie",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "LO",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Faisceau Local",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "P1",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "RN",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Bifurcation de la Rivière Neuve",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "SI",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Sg102/104",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : "TU",
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Faisceau Tunnel",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : 3,
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Signal 103",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : 67,
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Repère 6167",
            "code" : ""
        },
        {
            "codeUIC" : 281071,
            "codeCH" : 84,
            "nameUIC" : "Calais-Fréthun",
            "nameCH" : "Repère 5184",
            "code" : ""
        },
        {
            "codeUIC" : 281105,
            "codeCH" : 0,
            "nameUIC" : "Bergues",
            "nameCH" : "",
            "code" : "BEG"
        },
        {
            "codeUIC" : 281113,
            "codeCH" : 0,
            "nameUIC" : "Esquelbecq",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281121,
            "codeCH" : 0,
            "nameUIC" : "Arnèke",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281139,
            "codeCH" : "BL",
            "nameUIC" : "Cassel",
            "nameCH" : "Bifurcation d'Oxelaere",
            "code" : ""
        },
        {
            "codeUIC" : 281139,
            "codeCH" : "BV",
            "nameUIC" : "Cassel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 281139,
            "codeCH" : "EP",
            "nameUIC" : "Cassel",
            "nameCH" : "ITE OXELAERE",
            "code" : ""
        },
        {
            "codeUIC" : 281147,
            "codeCH" : 0,
            "nameUIC" : "Poste 23 Beugnâtre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281154,
            "codeCH" : 0,
            "nameUIC" : "Poste 26 Izel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281162,
            "codeCH" : 0,
            "nameUIC" : "Poste 27 Oignies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281170,
            "codeCH" : 0,
            "nameUIC" : "Poste 28 Avelin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281188,
            "codeCH" : "PR",
            "nameUIC" : "Wannehain",
            "nameCH" : "Poste 30",
            "code" : ""
        },
        {
            "codeUIC" : 281188,
            "codeCH" : "XB",
            "nameUIC" : "Wannehain",
            "nameCH" : "Frontière France-Belgique",
            "code" : ""
        },
        {
            "codeUIC" : 281196,
            "codeCH" : 0,
            "nameUIC" : "Poste 46 Rodelinghem",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281220,
            "codeCH" : 0,
            "nameUIC" : "Pont-d'Oye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281238,
            "codeCH" : 0,
            "nameUIC" : "Poste 43 Erquinghem",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281246,
            "codeCH" : "AE",
            "nameUIC" : "Gravelines",
            "nameCH" : "Accès Est",
            "code" : ""
        },
        {
            "codeUIC" : 281246,
            "codeCH" : "AO",
            "nameUIC" : "Gravelines",
            "nameCH" : "Accès Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 281246,
            "codeCH" : "PL",
            "nameUIC" : "Gravelines",
            "nameCH" : "Pont Levant",
            "code" : ""
        },
        {
            "codeUIC" : 281246,
            "codeCH" : 0,
            "nameUIC" : "Gravelines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281279,
            "codeCH" : 0,
            "nameUIC" : "Bourbourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281311,
            "codeCH" : 0,
            "nameUIC" : "Grande-Synthe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281329,
            "codeCH" : "BF",
            "nameUIC" : "Loon-Plage",
            "nameCH" : "Ferme coupvent",
            "code" : ""
        },
        {
            "codeUIC" : 281329,
            "codeCH" : 0,
            "nameUIC" : "Loon-Plage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281337,
            "codeCH" : 0,
            "nameUIC" : "Courghain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281402,
            "codeCH" : 0,
            "nameUIC" : "Coudekerque-Branche",
            "nameCH" : "",
            "code" : "CDK"
        },
        {
            "codeUIC" : 281410,
            "codeCH" : 0,
            "nameUIC" : "Pont-d'Ardres",
            "nameCH" : "",
            "code" : "PRD"
        },
        {
            "codeUIC" : 281428,
            "codeCH" : 0,
            "nameUIC" : "Audruicq",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281428,
            "codeCH" : 54,
            "nameUIC" : "Audruicq",
            "nameCH" : "Poste 54",
            "code" : ""
        },
        {
            "codeUIC" : 281436,
            "codeCH" : 0,
            "nameUIC" : "Watten-Éperlecques",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281444,
            "codeCH" : "BM",
            "nameUIC" : "St-Omer (Pas-de-Calais)",
            "nameCH" : "Bifurcation de Malhove",
            "code" : "SOM"
        },
        {
            "codeUIC" : 281444,
            "codeCH" : "BV",
            "nameUIC" : "St-Omer (Pas-de-Calais)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SOM"
        },
        {
            "codeUIC" : 281451,
            "codeCH" : 0,
            "nameUIC" : "Renescure",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281469,
            "codeCH" : 0,
            "nameUIC" : "Ebblinghem",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281477,
            "codeCH" : "BV",
            "nameUIC" : "Arques (Pas-de-Calais)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ARQ"
        },
        {
            "codeUIC" : 281527,
            "codeCH" : 0,
            "nameUIC" : "Lumbres",
            "nameCH" : "",
            "code" : "LUM"
        },
        {
            "codeUIC" : 281626,
            "codeCH" : 0,
            "nameUIC" : "Leffrinckoucke",
            "nameCH" : "",
            "code" : "LNK"
        },
        {
            "codeUIC" : 281659,
            "codeCH" : "XB",
            "nameUIC" : "Bray-Dunes",
            "nameCH" : "Frontière France-Belgique",
            "code" : ""
        },
        {
            "codeUIC" : 281659,
            "codeCH" : 0,
            "nameUIC" : "Bray-Dunes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281873,
            "codeCH" : "BV",
            "nameUIC" : "Magenta",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 281873,
            "codeCH" : "PK",
            "nameUIC" : "Magenta",
            "nameCH" : "Pk 0",
            "code" : ""
        },
        {
            "codeUIC" : 281899,
            "codeCH" : 0,
            "nameUIC" : "Haussmann-St-Lazare",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "BV",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Gare Maritime",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "CR",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "ITE CFR",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "EF",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Centrale EDF",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "FC",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Faisceau du Colombier",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "FL",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Faisceau de Loon",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "PR",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Bifurcation du Poste R",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "P9",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Poste 9",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "RS",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Raccordement sud",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "SN",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "ITE Stocknord",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : "VG",
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Bifurcation du Vert Gazon",
            "code" : ""
        },
        {
            "codeUIC" : 281931,
            "codeCH" : 10,
            "nameUIC" : "Dunkerque-Ferry (IE)",
            "nameCH" : "Poste 10",
            "code" : ""
        },
        {
            "codeUIC" : 281998,
            "codeCH" : "WB",
            "nameUIC" : "De Panne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 282020,
            "codeCH" : 0,
            "nameUIC" : "Château-de-Saillant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 282624,
            "codeCH" : 0,
            "nameUIC" : "Lyon-Jean-Macé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "AD",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Aiguille Voie D",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "AG",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Aiguille 254B",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "AT",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Entrée/Sortie Voie A TGV",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "BF",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Bifurcation de Fives Sud",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "BV",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "BW",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Bifurcation de Wasquehal",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "DT",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Dépôt de Fives",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "FI",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Garage de Fives",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "GP",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Entrée/Sortie GP",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "ID",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Impasse D",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "JO",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction V2B/DD-401a",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J1",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction Voies RB/AM/DM/SA",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J2",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction Voies 1B/2B(A 414)",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J3",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction V2B/DD-406a",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J4",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction V1D/2D-410b",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J5",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction Voies 1D/DD (1401)",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J6",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction V1D/2D-409",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J7",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction AD/Q2",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J8",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction Voies 1D/2D (A417)",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "J9",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction Voies V2/V1 (A601)",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "LO",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Jonction du Lion d'Or",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "ML",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Machine-à-Laver",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "PZ",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Bifurcation de Fives Nord",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "RT",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Remisage Ter",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "SA",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Garage Ste-Agnès",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "SM",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Entrée/Sortie SM",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : "VR",
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Bifurcation de Verte-Rue",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : 30,
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Carré 508",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : 56,
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Carré 264",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286005,
            "codeCH" : 57,
            "nameUIC" : "Lille-Flandres",
            "nameCH" : "Carré 253",
            "code" : "LIF"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "EN",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Accès Gril GE GD",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "GD",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Grill Diesel",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "GE",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Grill Électrique",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "P1",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Poste 1",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "P2",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Poste 2",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "RC",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Réception",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "RL",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Relais",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : "TR",
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Triage",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : 18,
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Carré Violet 182 184 et 188",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286013,
            "codeCH" : 51,
            "nameUIC" : "Lille-Délivrance",
            "nameCH" : "Carré Violet 149 et 151",
            "code" : "LIV"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "BA",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Bifurcation des Ateliers Est",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "BJ",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Bifurcation de la Justice",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "BM",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Bifurcation du Mont-de-Terre",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "CM",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Champ-de-Mars",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "C2",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Carré 910",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "EM",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Atelier 2000",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "EP",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "ITE Atelier Hellemmes-Point H",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "ES",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Entrée/Sortie Faisceaux",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "GA",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "ITE Garolille",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "PA",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Atelier Hellemes-Point A",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "P1",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Poste CM",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "P2",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Jonction Voies M/P",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : "SM",
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Bifurcation des Sourds-Muets",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : 24,
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Jonction 24",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : 27,
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Carré Violet 503",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : 40,
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Signal 40",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286039,
            "codeCH" : 53,
            "nameUIC" : "Lille-St-Sauveur",
            "nameCH" : "Carre Violet 40",
            "code" : "LSA"
        },
        {
            "codeUIC" : 286062,
            "codeCH" : 0,
            "nameUIC" : "Hellemmes",
            "nameCH" : "",
            "code" : "HEL"
        },
        {
            "codeUIC" : 286096,
            "codeCH" : "BH",
            "nameUIC" : "Haubourdin",
            "nameCH" : "Bifurcation d'Haubourdin",
            "code" : "HAU"
        },
        {
            "codeUIC" : 286096,
            "codeCH" : "BR",
            "nameUIC" : "Haubourdin",
            "nameCH" : "Bifurcation de Riez",
            "code" : "HAU"
        },
        {
            "codeUIC" : 286096,
            "codeCH" : "BT",
            "nameUIC" : "Haubourdin",
            "nameCH" : "Bifurcation de la Tortue",
            "code" : "HAU"
        },
        {
            "codeUIC" : 286096,
            "codeCH" : "BV",
            "nameUIC" : "Haubourdin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HAU"
        },
        {
            "codeUIC" : 286096,
            "codeCH" : "LE",
            "nameUIC" : "Haubourdin",
            "nameCH" : "ITE Lever",
            "code" : "HAU"
        },
        {
            "codeUIC" : 286096,
            "codeCH" : "VI",
            "nameUIC" : "Haubourdin",
            "nameCH" : "ITE Villeroy",
            "code" : "HAU"
        },
        {
            "codeUIC" : 286112,
            "codeCH" : 0,
            "nameUIC" : "Loos-les-Lille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286146,
            "codeCH" : "SI",
            "nameUIC" : "Armentières",
            "nameCH" : "Carré 208",
            "code" : "ARM"
        },
        {
            "codeUIC" : 286146,
            "codeCH" : 0,
            "nameUIC" : "Armentières",
            "nameCH" : "",
            "code" : "ARM"
        },
        {
            "codeUIC" : 286161,
            "codeCH" : 0,
            "nameUIC" : "Steenwerck",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286179,
            "codeCH" : 0,
            "nameUIC" : "Bailleul (Nord)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286187,
            "codeCH" : "BM",
            "nameUIC" : "La Madeleine (Nord)",
            "nameCH" : "Bifurcation de la Madeleine",
            "code" : "LMA"
        },
        {
            "codeUIC" : 286187,
            "codeCH" : "BV",
            "nameUIC" : "La Madeleine (Nord)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LMA"
        },
        {
            "codeUIC" : 286187,
            "codeCH" : "PI",
            "nameUIC" : "La Madeleine (Nord)",
            "nameCH" : "ITE Zone Industrielle de la Pilaterie",
            "code" : "LMA"
        },
        {
            "codeUIC" : 286187,
            "codeCH" : "RO",
            "nameUIC" : "La Madeleine (Nord)",
            "nameCH" : "Garage Rougebarre",
            "code" : "LMA"
        },
        {
            "codeUIC" : 286195,
            "codeCH" : 0,
            "nameUIC" : "St-André (Nord)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286203,
            "codeCH" : 0,
            "nameUIC" : "Strazeele",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286229,
            "codeCH" : 0,
            "nameUIC" : "Wambrechies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286245,
            "codeCH" : 0,
            "nameUIC" : "Quesnoy-sur-Deûle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286260,
            "codeCH" : 0,
            "nameUIC" : "Comines-France",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286278,
            "codeCH" : 0,
            "nameUIC" : "Pérenchies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286286,
            "codeCH" : "BL",
            "nameUIC" : "Lambersart",
            "nameCH" : "Bifurcation de Lambersart",
            "code" : ""
        },
        {
            "codeUIC" : 286286,
            "codeCH" : "BV",
            "nameUIC" : "Lambersart",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 286286,
            "codeCH" : "PR",
            "nameUIC" : "Lambersart",
            "nameCH" : "Poste 42",
            "code" : ""
        },
        {
            "codeUIC" : 286286,
            "codeCH" : "RN",
            "nameUIC" : "Lambersart",
            "nameCH" : "Raccordement de Calais",
            "code" : ""
        },
        {
            "codeUIC" : 286286,
            "codeCH" : "RS",
            "nameUIC" : "Lambersart",
            "nameCH" : "Raccordement de Lille",
            "code" : ""
        },
        {
            "codeUIC" : 286286,
            "codeCH" : "SA",
            "nameUIC" : "Lambersart",
            "nameCH" : "Bifurcation de St-André",
            "code" : ""
        },
        {
            "codeUIC" : 286286,
            "codeCH" : "4M",
            "nameUIC" : "Lambersart",
            "nameCH" : "Bifurcation des 4 Maisons",
            "code" : ""
        },
        {
            "codeUIC" : 286294,
            "codeCH" : 0,
            "nameUIC" : "Lomme",
            "nameCH" : "",
            "code" : "LMM"
        },
        {
            "codeUIC" : 286302,
            "codeCH" : "BV",
            "nameUIC" : "Hazebrouck",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HZK"
        },
        {
            "codeUIC" : 286302,
            "codeCH" : "HL",
            "nameUIC" : "Hazebrouck",
            "nameCH" : "Bifurcation de Haute Loge",
            "code" : "HZK"
        },
        {
            "codeUIC" : 286302,
            "codeCH" : "P1",
            "nameUIC" : "Hazebrouck",
            "nameCH" : "Poste 1",
            "code" : "HZK"
        },
        {
            "codeUIC" : 286310,
            "codeCH" : 0,
            "nameUIC" : "Sequedin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286328,
            "codeCH" : 0,
            "nameUIC" : "Haubourdin-Halte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286336,
            "codeCH" : 0,
            "nameUIC" : "Bac-St-Maur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286344,
            "codeCH" : 0,
            "nameUIC" : "Sailly-sur-la-Lys",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286351,
            "codeCH" : 0,
            "nameUIC" : "Laventie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286377,
            "codeCH" : 0,
            "nameUIC" : "Lestrem",
            "nameCH" : "",
            "code" : "LEM"
        },
        {
            "codeUIC" : 286401,
            "codeCH" : 0,
            "nameUIC" : "Santes",
            "nameCH" : "",
            "code" : "STS"
        },
        {
            "codeUIC" : 286419,
            "codeCH" : 0,
            "nameUIC" : "Wavrin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286427,
            "codeCH" : 0,
            "nameUIC" : "La Fontaine-Nord",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286435,
            "codeCH" : "BV",
            "nameUIC" : "Don-Sainghin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DON"
        },
        {
            "codeUIC" : 286500,
            "codeCH" : 0,
            "nameUIC" : "Phalempin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286518,
            "codeCH" : "BV",
            "nameUIC" : "Seclin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SCN"
        },
        {
            "codeUIC" : 286526,
            "codeCH" : 0,
            "nameUIC" : "Wattignies-Templemars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286534,
            "codeCH" : "BV",
            "nameUIC" : "Ronchin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ROC"
        },
        {
            "codeUIC" : 286534,
            "codeCH" : "CH",
            "nameUIC" : "Ronchin",
            "nameCH" : "ITE Choteau",
            "code" : "ROC"
        },
        {
            "codeUIC" : 286542,
            "codeCH" : "BV",
            "nameUIC" : "Tourcoing",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TG"
        },
        {
            "codeUIC" : 286542,
            "codeCH" : "MA",
            "nameUIC" : "Tourcoing",
            "nameCH" : "Marchandises",
            "code" : "TG"
        },
        {
            "codeUIC" : 286542,
            "codeCH" : "P1",
            "nameUIC" : "Tourcoing",
            "nameCH" : "Poste 1",
            "code" : "TG"
        },
        {
            "codeUIC" : 286542,
            "codeCH" : "SV",
            "nameUIC" : "Tourcoing",
            "nameCH" : "Bifurcation du Sapin Vert",
            "code" : "TG"
        },
        {
            "codeUIC" : 286542,
            "codeCH" : "XB",
            "nameUIC" : "Tourcoing",
            "nameCH" : "Frontière France-Belgique",
            "code" : "TG"
        },
        {
            "codeUIC" : 286567,
            "codeCH" : 0,
            "nameUIC" : "Rosult",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286575,
            "codeCH" : 0,
            "nameUIC" : "Landas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286583,
            "codeCH" : 0,
            "nameUIC" : "Orchies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286609,
            "codeCH" : 0,
            "nameUIC" : "Nomain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286625,
            "codeCH" : 0,
            "nameUIC" : "Templeuve",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286641,
            "codeCH" : 0,
            "nameUIC" : "Fretin (Nord)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286716,
            "codeCH" : 0,
            "nameUIC" : "Croix-Wasquehal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286724,
            "codeCH" : "GA",
            "nameUIC" : "Lille-Sud",
            "nameCH" : "Garages",
            "code" : "LID"
        },
        {
            "codeUIC" : 286724,
            "codeCH" : "PX",
            "nameUIC" : "Lille-Sud",
            "nameCH" : "Point X",
            "code" : "LID"
        },
        {
            "codeUIC" : 286724,
            "codeCH" : "P1",
            "nameUIC" : "Lille-Sud",
            "nameCH" : "Poste 1",
            "code" : "LID"
        },
        {
            "codeUIC" : 286732,
            "codeCH" : "AN",
            "nameUIC" : "Roubaix",
            "nameCH" : "Annexe",
            "code" : ""
        },
        {
            "codeUIC" : 286732,
            "codeCH" : "BV",
            "nameUIC" : "Roubaix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 286740,
            "codeCH" : 0,
            "nameUIC" : "Tressin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286757,
            "codeCH" : 0,
            "nameUIC" : "Roubaix-Wattrelos",
            "nameCH" : "",
            "code" : "RXW"
        },
        {
            "codeUIC" : 286781,
            "codeCH" : 0,
            "nameUIC" : "Cysoing",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286807,
            "codeCH" : 0,
            "nameUIC" : "Genech",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286815,
            "codeCH" : 0,
            "nameUIC" : "Nomain-Ouvignies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286831,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Bois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286849,
            "codeCH" : "BL",
            "nameUIC" : "Lesquin",
            "nameCH" : "Bifurcation de Lesquin",
            "code" : ""
        },
        {
            "codeUIC" : 286849,
            "codeCH" : "BV",
            "nameUIC" : "Lesquin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 286856,
            "codeCH" : 0,
            "nameUIC" : "Annappes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286864,
            "codeCH" : 0,
            "nameUIC" : "Ascq",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 286872,
            "codeCH" : "XB",
            "nameUIC" : "Baisieux",
            "nameCH" : "Frontière France-Belgique",
            "code" : "BAI"
        },
        {
            "codeUIC" : 286872,
            "codeCH" : 0,
            "nameUIC" : "Baisieux",
            "nameCH" : "",
            "code" : "BAI"
        },
        {
            "codeUIC" : 286989,
            "codeCH" : "WB",
            "nameUIC" : "Mouscron",
            "nameCH" : "",
            "code" : "MOU"
        },
        {
            "codeUIC" : 287011,
            "codeCH" : 0,
            "nameUIC" : "Offekerque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "AT",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Faisceau Attente",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "FO",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Faisceau Formation",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "G2",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Grille Électrique 2",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "JP",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Jonction Voies PX1/PX2",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "PH",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Bifurcation du Puyt-Houck Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "PJ",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Poste J",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "PQ",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Point Q",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "P1",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Faisceau des Pondereux 1",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "P2",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Faisceau des Pondereux 2",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "P5",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Poste 5 (Bif)",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "P6",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Poste 6",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "P7",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Poste 7",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "P8",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Poste 8",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "RC",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "SA",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "SAS A",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : "UR",
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Poste 1 Usinor",
            "code" : ""
        },
        {
            "codeUIC" : 287037,
            "codeCH" : 23,
            "nameUIC" : "Dunkerque-Grande-Synthe",
            "nameCH" : "Sémaphore 2-4",
            "code" : ""
        },
        {
            "codeUIC" : 287102,
            "codeCH" : 0,
            "nameUIC" : "Lezennes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287128,
            "codeCH" : 0,
            "nameUIC" : "Mont-de-Terre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287136,
            "codeCH" : 0,
            "nameUIC" : "Marais-de-Lomme",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287151,
            "codeCH" : 0,
            "nameUIC" : "Nieppe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287169,
            "codeCH" : 0,
            "nameUIC" : "Marquette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287185,
            "codeCH" : 0,
            "nameUIC" : "Deûlémont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287193,
            "codeCH" : 0,
            "nameUIC" : "Ste-Marguerite",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287219,
            "codeCH" : 0,
            "nameUIC" : "Anstaing",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287227,
            "codeCH" : 0,
            "nameUIC" : "Gruson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287235,
            "codeCH" : 0,
            "nameUIC" : "Bouvines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287243,
            "codeCH" : 0,
            "nameUIC" : "Cobrieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287250,
            "codeCH" : 0,
            "nameUIC" : "Lille-Porte-de-Douai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287276,
            "codeCH" : 0,
            "nameUIC" : "Ennevelin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287292,
            "codeCH" : 0,
            "nameUIC" : "Nortkerque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287300,
            "codeCH" : 0,
            "nameUIC" : "Ruminghem",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 287359,
            "codeCH" : 0,
            "nameUIC" : "Lambersart-Halte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295006,
            "codeCH" : "BV",
            "nameUIC" : "Maubeuge",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MBG"
        },
        {
            "codeUIC" : 295006,
            "codeCH" : "GA",
            "nameUIC" : "Maubeuge",
            "nameCH" : "Garages Pairs",
            "code" : "MBG"
        },
        {
            "codeUIC" : 295006,
            "codeCH" : "P2",
            "nameUIC" : "Maubeuge",
            "nameCH" : "Poste 2",
            "code" : "MBG"
        },
        {
            "codeUIC" : 295006,
            "codeCH" : "US",
            "nameUIC" : "Maubeuge",
            "nameCH" : "Garage Usines",
            "code" : "MBG"
        },
        {
            "codeUIC" : 295014,
            "codeCH" : "BV",
            "nameUIC" : "Jeumont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JT"
        },
        {
            "codeUIC" : 295014,
            "codeCH" : "XB",
            "nameUIC" : "Jeumont",
            "nameCH" : "Frontière France-Belgique",
            "code" : "JT"
        },
        {
            "codeUIC" : 295022,
            "codeCH" : 0,
            "nameUIC" : "Recquignies",
            "nameCH" : "",
            "code" : "RCQ"
        },
        {
            "codeUIC" : 295030,
            "codeCH" : "XB",
            "nameUIC" : "Feignies",
            "nameCH" : "Frontière France-Belgique",
            "code" : "FEI"
        },
        {
            "codeUIC" : 295048,
            "codeCH" : 0,
            "nameUIC" : "Fourmies",
            "nameCH" : "",
            "code" : "FMI"
        },
        {
            "codeUIC" : 295055,
            "codeCH" : 0,
            "nameUIC" : "Anor",
            "nameCH" : "",
            "code" : "ANO"
        },
        {
            "codeUIC" : 295063,
            "codeCH" : "AM",
            "nameUIC" : "Hirson",
            "nameCH" : "Bifurcation de Buire",
            "code" : ""
        },
        {
            "codeUIC" : 295063,
            "codeCH" : "BV",
            "nameUIC" : "Hirson",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 295063,
            "codeCH" : "EM",
            "nameUIC" : "Hirson",
            "nameCH" : "Entrée Marchandises",
            "code" : ""
        },
        {
            "codeUIC" : 295220,
            "codeCH" : 0,
            "nameUIC" : "Le Cateau",
            "nameCH" : "",
            "code" : "LEC"
        },
        {
            "codeUIC" : 295238,
            "codeCH" : 0,
            "nameUIC" : "Douzies",
            "nameCH" : "",
            "code" : "DOZ"
        },
        {
            "codeUIC" : 295337,
            "codeCH" : 0,
            "nameUIC" : "Ferrière-la-Grande",
            "nameCH" : "",
            "code" : "FAD"
        },
        {
            "codeUIC" : 295535,
            "codeCH" : 0,
            "nameUIC" : "Louvroil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295543,
            "codeCH" : 0,
            "nameUIC" : "Sous-le-Bois",
            "nameCH" : "",
            "code" : "SB"
        },
        {
            "codeUIC" : 295568,
            "codeCH" : "BD",
            "nameUIC" : "Hautmont",
            "nameCH" : "Bifurcation de Douzies",
            "code" : "HAT"
        },
        {
            "codeUIC" : 295568,
            "codeCH" : "BH",
            "nameUIC" : "Hautmont",
            "nameCH" : "Bifurcation d'Hautmont",
            "code" : "HAT"
        },
        {
            "codeUIC" : 295568,
            "codeCH" : "BU",
            "nameUIC" : "Hautmont",
            "nameCH" : "Bifurcation des Usines",
            "code" : "HAT"
        },
        {
            "codeUIC" : 295568,
            "codeCH" : "BV",
            "nameUIC" : "Hautmont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HAT"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "BB",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Bifurcation de Berlaimont",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "BL",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Bifurcation de Leval",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "BO",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Bifurcation de Leval-Ouest",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "BS",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Bifurcation de Sassegnies",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "BV",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "DT",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Dépôt",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "EF",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "ITE EDF",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "P1",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Poste 1",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "P3",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Poste 3",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : "TR",
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Triage Plaine",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : 17,
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Km 217,3",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295600,
            "codeCH" : 56,
            "nameUIC" : "Aulnoye-Aymeries",
            "nameCH" : "Carré Violet 356",
            "code" : "AYE"
        },
        {
            "codeUIC" : 295642,
            "codeCH" : 0,
            "nameUIC" : "Landrecies",
            "nameCH" : "",
            "code" : "LNR"
        },
        {
            "codeUIC" : 295667,
            "codeCH" : 0,
            "nameUIC" : "Ors",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295709,
            "codeCH" : 0,
            "nameUIC" : "Sains-du-Nord",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295725,
            "codeCH" : "BV",
            "nameUIC" : "Avesnelles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 295725,
            "codeCH" : "EP",
            "nameUIC" : "Avesnelles",
            "nameCH" : "ITE Bocahut",
            "code" : ""
        },
        {
            "codeUIC" : 295725,
            "codeCH" : 0,
            "nameUIC" : "Avesnelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295733,
            "codeCH" : 0,
            "nameUIC" : "Avesnes",
            "nameCH" : "",
            "code" : "AVN"
        },
        {
            "codeUIC" : 295774,
            "codeCH" : 0,
            "nameUIC" : "Dompierre (Nord)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295790,
            "codeCH" : 0,
            "nameUIC" : "Leval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295808,
            "codeCH" : "BV",
            "nameUIC" : "Berlaimont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BER"
        },
        {
            "codeUIC" : 295808,
            "codeCH" : "VA",
            "nameUIC" : "Berlaimont",
            "nameCH" : "ITE Vallourec",
            "code" : "BER"
        },
        {
            "codeUIC" : 295857,
            "codeCH" : 0,
            "nameUIC" : "Le Quesnoy (Nord)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 295980,
            "codeCH" : "WB",
            "nameUIC" : "Erquelinnes",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 295998,
            "codeCH" : "WB",
            "nameUIC" : "Quevy-le-Grand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296004,
            "codeCH" : "BV",
            "nameUIC" : "St-Quentin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "STQ"
        },
        {
            "codeUIC" : 296004,
            "codeCH" : "P1",
            "nameUIC" : "St-Quentin",
            "nameCH" : "Poste 1",
            "code" : "STQ"
        },
        {
            "codeUIC" : 296004,
            "codeCH" : "RA",
            "nameUIC" : "St-Quentin",
            "nameCH" : "RA",
            "code" : "STQ"
        },
        {
            "codeUIC" : 296004,
            "codeCH" : "ZI",
            "nameUIC" : "St-Quentin",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "STQ"
        },
        {
            "codeUIC" : 296012,
            "codeCH" : "BV",
            "nameUIC" : "Laon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LAO"
        },
        {
            "codeUIC" : 296012,
            "codeCH" : "C2",
            "nameUIC" : "Laon",
            "nameCH" : "Carré 208",
            "code" : "LAO"
        },
        {
            "codeUIC" : 296012,
            "codeCH" : "EP",
            "nameUIC" : "Laon",
            "nameCH" : "ITE Pirelli",
            "code" : "LAO"
        },
        {
            "codeUIC" : 296012,
            "codeCH" : "MA",
            "nameUIC" : "Laon",
            "nameCH" : "Faisceau Marchandises",
            "code" : "LAO"
        },
        {
            "codeUIC" : 296012,
            "codeCH" : "P1",
            "nameUIC" : "Laon",
            "nameCH" : "Bifurcation St-Marcel",
            "code" : "LAO"
        },
        {
            "codeUIC" : 296012,
            "codeCH" : "P2",
            "nameUIC" : "Laon",
            "nameCH" : "Poste 2",
            "code" : "LAO"
        },
        {
            "codeUIC" : 296012,
            "codeCH" : "S1",
            "nameUIC" : "Laon",
            "nameCH" : "Carré 11",
            "code" : "LAO"
        },
        {
            "codeUIC" : 296095,
            "codeCH" : 0,
            "nameUIC" : "Marle-sur-Serre",
            "nameCH" : "",
            "code" : "MR"
        },
        {
            "codeUIC" : 296129,
            "codeCH" : 0,
            "nameUIC" : "Vervins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296145,
            "codeCH" : 0,
            "nameUIC" : "Origny-en-Thiérache",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296152,
            "codeCH" : "BV",
            "nameUIC" : "Crépy-Couvron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CYU"
        },
        {
            "codeUIC" : 296152,
            "codeCH" : "C1",
            "nameUIC" : "Crépy-Couvron",
            "nameCH" : "Carré 31",
            "code" : "CYU"
        },
        {
            "codeUIC" : 296152,
            "codeCH" : "C2",
            "nameUIC" : "Crépy-Couvron",
            "nameCH" : "Carré 42",
            "code" : "CYU"
        },
        {
            "codeUIC" : 296178,
            "codeCH" : 0,
            "nameUIC" : "Clacy-Mons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296186,
            "codeCH" : 0,
            "nameUIC" : "Dercy-Mortiers",
            "nameCH" : "",
            "code" : "DRT"
        },
        {
            "codeUIC" : 296236,
            "codeCH" : "BF",
            "nameUIC" : "Honnechy",
            "nameCH" : "Bifurcation Honnechy",
            "code" : ""
        },
        {
            "codeUIC" : 296244,
            "codeCH" : "BV",
            "nameUIC" : "Busigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 296244,
            "codeCH" : "GI",
            "nameUIC" : "Busigny",
            "nameCH" : "Garage ACT impair",
            "code" : ""
        },
        {
            "codeUIC" : 296244,
            "codeCH" : "GP",
            "nameUIC" : "Busigny",
            "nameCH" : "Garage ACT pair",
            "code" : ""
        },
        {
            "codeUIC" : 296244,
            "codeCH" : "MA",
            "nameUIC" : "Busigny",
            "nameCH" : "Marchandises",
            "code" : ""
        },
        {
            "codeUIC" : 296244,
            "codeCH" : "P1",
            "nameUIC" : "Busigny",
            "nameCH" : "Bifurcation Sud",
            "code" : ""
        },
        {
            "codeUIC" : 296244,
            "codeCH" : "P2",
            "nameUIC" : "Busigny",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 296269,
            "codeCH" : 0,
            "nameUIC" : "Bohain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296293,
            "codeCH" : "BV",
            "nameUIC" : "Fresnoy-le-Grand",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FEG"
        },
        {
            "codeUIC" : 296293,
            "codeCH" : 17,
            "nameUIC" : "Fresnoy-le-Grand",
            "nameCH" : "Km 171,3",
            "code" : "FEG"
        },
        {
            "codeUIC" : 296327,
            "codeCH" : "EP",
            "nameUIC" : "Athies-sous-Laon",
            "nameCH" : "ITE Ponts et chaussée",
            "code" : "ATO"
        },
        {
            "codeUIC" : 296335,
            "codeCH" : 0,
            "nameUIC" : "Essigny-le-Petit",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296350,
            "codeCH" : "BP",
            "nameUIC" : "Liesse-Gizy",
            "nameCH" : "Bâtiment Principal",
            "code" : ""
        },
        {
            "codeUIC" : 296376,
            "codeCH" : "EP",
            "nameUIC" : "Clermont-les-Fermes",
            "nameCH" : "ITE Norepi",
            "code" : "CFE"
        },
        {
            "codeUIC" : 296384,
            "codeCH" : "BP",
            "nameUIC" : "Montcornet",
            "nameCH" : "Bâtiment Principal",
            "code" : "MCR"
        },
        {
            "codeUIC" : 296400,
            "codeCH" : 0,
            "nameUIC" : "Essigny-le-Grand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296418,
            "codeCH" : 0,
            "nameUIC" : "Montescourt",
            "nameCH" : "",
            "code" : "MCU"
        },
        {
            "codeUIC" : 296426,
            "codeCH" : "EA",
            "nameUIC" : "Fargniers-Garage",
            "nameCH" : "ITE ALB",
            "code" : ""
        },
        {
            "codeUIC" : 296426,
            "codeCH" : "EC",
            "nameUIC" : "Fargniers-Garage",
            "nameCH" : "ITE CELDRAN",
            "code" : ""
        },
        {
            "codeUIC" : 296434,
            "codeCH" : 0,
            "nameUIC" : "Mennessis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "AM",
            "nameUIC" : "Tergnier",
            "nameCH" : "Bifurcation de Flavy",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "BV",
            "nameUIC" : "Tergnier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "C5",
            "nameUIC" : "Tergnier",
            "nameCH" : "Carré 52",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "C8",
            "nameUIC" : "Tergnier",
            "nameCH" : "Carré Violet 83",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "C9",
            "nameUIC" : "Tergnier",
            "nameCH" : "Carré 661",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "DT",
            "nameUIC" : "Tergnier",
            "nameCH" : "Dépôt",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "EM",
            "nameUIC" : "Tergnier",
            "nameCH" : "Entrée Marchandises",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "P2",
            "nameUIC" : "Tergnier",
            "nameCH" : "Accès Nord",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "RC",
            "nameUIC" : "Tergnier",
            "nameCH" : "Faisceau Réception",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "RL",
            "nameUIC" : "Tergnier",
            "nameCH" : "Faisceau Relais",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "SQ",
            "nameUIC" : "Tergnier",
            "nameCH" : "Bifurcation de Jussy",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296442,
            "codeCH" : "TR",
            "nameUIC" : "Tergnier",
            "nameCH" : "Triage",
            "code" : "TGR"
        },
        {
            "codeUIC" : 296459,
            "codeCH" : 0,
            "nameUIC" : "Appilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296566,
            "codeCH" : 0,
            "nameUIC" : "Anizy-Pinon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296608,
            "codeCH" : 0,
            "nameUIC" : "Versigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296616,
            "codeCH" : "GG",
            "nameUIC" : "Chauny",
            "nameCH" : "Garages",
            "code" : "CNY"
        },
        {
            "codeUIC" : 296616,
            "codeCH" : 0,
            "nameUIC" : "Chauny",
            "nameCH" : "",
            "code" : "CNY"
        },
        {
            "codeUIC" : 296632,
            "codeCH" : 0,
            "nameUIC" : "La Fère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 296871,
            "codeCH" : "SO",
            "nameUIC" : "Flavy-le-Martel",
            "nameCH" : "Sémaphore 106",
            "code" : "FAM"
        },
        {
            "codeUIC" : 296871,
            "codeCH" : "S7",
            "nameUIC" : "Flavy-le-Martel",
            "nameCH" : "Sémaphore 105",
            "code" : "FAM"
        },
        {
            "codeUIC" : 296871,
            "codeCH" : 0,
            "nameUIC" : "Flavy-le-Martel",
            "nameCH" : "",
            "code" : "FAM"
        },
        {
            "codeUIC" : 297036,
            "codeCH" : 0,
            "nameUIC" : "Croix-Fonsommes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297051,
            "codeCH" : 0,
            "nameUIC" : "Viry-Noureuil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297069,
            "codeCH" : 0,
            "nameUIC" : "Jussy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297085,
            "codeCH" : 0,
            "nameUIC" : "La Bouteille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297101,
            "codeCH" : 0,
            "nameUIC" : "La Neuville-sous-Laon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297119,
            "codeCH" : 0,
            "nameUIC" : "Verneuil-sur-Serre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297135,
            "codeCH" : 0,
            "nameUIC" : "Buire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297143,
            "codeCH" : 0,
            "nameUIC" : "Dercy-Froidmont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297150,
            "codeCH" : 0,
            "nameUIC" : "Voyenne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297192,
            "codeCH" : "EP",
            "nameUIC" : "Chivres-en-Laonnois",
            "nameCH" : "ITE GIE",
            "code" : "CRO"
        },
        {
            "codeUIC" : 297283,
            "codeCH" : "BV",
            "nameUIC" : "Vauxaillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 297283,
            "codeCH" : 70,
            "nameUIC" : "Vauxaillon",
            "nameCH" : "Km 115,970",
            "code" : ""
        },
        {
            "codeUIC" : 297283,
            "codeCH" : 91,
            "nameUIC" : "Vauxaillon",
            "nameCH" : "Km 117,291",
            "code" : ""
        },
        {
            "codeUIC" : 297291,
            "codeCH" : 0,
            "nameUIC" : "Barenton-Bugny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297432,
            "codeCH" : 0,
            "nameUIC" : "Féron-Glageon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297515,
            "codeCH" : 0,
            "nameUIC" : "Les Bons-Pères",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297523,
            "codeCH" : 0,
            "nameUIC" : "Hachette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297531,
            "codeCH" : 0,
            "nameUIC" : "St-Hilaire (Nord)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 297549,
            "codeCH" : 0,
            "nameUIC" : "Monceau-St-Waast",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 298919,
            "codeCH" : 0,
            "nameUIC" : "ITE CARB",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 300822,
            "codeCH" : "AN",
            "nameUIC" : "Belfort-Montbéliard-TGV",
            "nameCH" : "Accès Nord",
            "code" : ""
        },
        {
            "codeUIC" : 300822,
            "codeCH" : "AS",
            "nameUIC" : "Belfort-Montbéliard-TGV",
            "nameCH" : "Accès Sud",
            "code" : ""
        },
        {
            "codeUIC" : 300822,
            "codeCH" : "BV",
            "nameUIC" : "Belfort-Montbéliard-TGV",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 300863,
            "codeCH" : "AG",
            "nameUIC" : "Besançon-Franche-Comté-TGV",
            "nameCH" : "Aiguille 8940",
            "code" : ""
        },
        {
            "codeUIC" : 300863,
            "codeCH" : "BF",
            "nameUIC" : "Besançon-Franche-Comté-TGV",
            "nameCH" : "Raccordement RE2",
            "code" : ""
        },
        {
            "codeUIC" : 300863,
            "codeCH" : "BM",
            "nameUIC" : "Besançon-Franche-Comté-TGV",
            "nameCH" : "Base maintenance",
            "code" : ""
        },
        {
            "codeUIC" : 300863,
            "codeCH" : "BV",
            "nameUIC" : "Besançon-Franche-Comté-TGV",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 300863,
            "codeCH" : "RA",
            "nameUIC" : "Besançon-Franche-Comté-TGV",
            "nameCH" : "Raccordement RO2/RE2",
            "code" : ""
        },
        {
            "codeUIC" : 303875,
            "codeCH" : 0,
            "nameUIC" : "Ingersheim-Cité-Scolaire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 307124,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 518,1",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 308882,
            "codeCH" : "C9",
            "nameUIC" : "Km 6,9",
            "nameCH" : "Carré 9",
            "code" : ""
        },
        {
            "codeUIC" : 308882,
            "codeCH" : "EP",
            "nameUIC" : "Km 6,9",
            "nameCH" : "ITE Zone Industrielle de Nevers",
            "code" : ""
        },
        {
            "codeUIC" : 308890,
            "codeCH" : 0,
            "nameUIC" : "ITE Mouesse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313015,
            "codeCH" : "BV",
            "nameUIC" : "Saleux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SLX"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "A1",
            "nameUIC" : "Longueau",
            "nameCH" : "VBA/V5",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "BC",
            "nameUIC" : "Longueau",
            "nameCH" : "Bifurcation de Camon",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "BV",
            "nameUIC" : "Longueau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "C6",
            "nameUIC" : "Longueau",
            "nameCH" : "Carré Violet 2706",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "DT",
            "nameUIC" : "Longueau",
            "nameCH" : "Dépôt",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "D1",
            "nameUIC" : "Longueau",
            "nameCH" : "Direction Lille",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "GJ",
            "nameUIC" : "Longueau",
            "nameCH" : "Faisceau Groupe J et Arras",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "J1",
            "nameUIC" : "Longueau",
            "nameCH" : "Jonction VL/FGB",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "J2",
            "nameUIC" : "Longueau",
            "nameCH" : "Jonction CM/VL",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "J3",
            "nameUIC" : "Longueau",
            "nameCH" : "AA/VA",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "L3",
            "nameUIC" : "Longueau",
            "nameCH" : "Entrée/Sortie Dépôt",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "L4",
            "nameUIC" : "Longueau",
            "nameCH" : "Entrée Sud Relais PARIS",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "L5",
            "nameUIC" : "Longueau",
            "nameCH" : "Entrée/Sortie Tergnier/Douai",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "L6",
            "nameUIC" : "Longueau",
            "nameCH" : "Entrée/Sortie Tergnier",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "L7",
            "nameUIC" : "Longueau",
            "nameCH" : "Entrée/Sortie Douai",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "MA",
            "nameUIC" : "Longueau",
            "nameCH" : "Faisceau Groupe Boulogne",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "PA",
            "nameUIC" : "Longueau",
            "nameCH" : "Poste A",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "P1",
            "nameUIC" : "Longueau",
            "nameCH" : "Poste 1",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "P2",
            "nameUIC" : "Longueau",
            "nameCH" : "Poste 2",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "P3",
            "nameUIC" : "Longueau",
            "nameCH" : "Poste 3",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "P4",
            "nameUIC" : "Longueau",
            "nameCH" : "Poste 4",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "P5",
            "nameUIC" : "Longueau",
            "nameCH" : "Poste 5",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "P6",
            "nameUIC" : "Longueau",
            "nameCH" : "Poste 6",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "RP",
            "nameUIC" : "Longueau",
            "nameCH" : "Faisceau Relais Paris",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "RR",
            "nameUIC" : "Longueau",
            "nameCH" : "Faisceau Relais Rouen",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : "TI",
            "nameUIC" : "Longueau",
            "nameCH" : "Tiroir",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : 13,
            "nameUIC" : "Longueau",
            "nameCH" : "Carré 613",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313049,
            "codeCH" : 15,
            "nameUIC" : "Longueau",
            "nameCH" : "Carré 2715",
            "code" : "LUA"
        },
        {
            "codeUIC" : 313056,
            "codeCH" : "BV",
            "nameUIC" : "Corbie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CBI"
        },
        {
            "codeUIC" : 313056,
            "codeCH" : "GL",
            "nameUIC" : "Corbie",
            "nameCH" : "ITE Gloria",
            "code" : "CBI"
        },
        {
            "codeUIC" : 313064,
            "codeCH" : 0,
            "nameUIC" : "Méricourt-Ribemont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313072,
            "codeCH" : 0,
            "nameUIC" : "Albert",
            "nameCH" : "",
            "code" : "ALB"
        },
        {
            "codeUIC" : 313080,
            "codeCH" : "BV",
            "nameUIC" : "St-Roch (Somme)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SRO"
        },
        {
            "codeUIC" : 313080,
            "codeCH" : "P1",
            "nameUIC" : "St-Roch (Somme)",
            "nameCH" : "Bifurcation et Poste 1",
            "code" : "SRO"
        },
        {
            "codeUIC" : 313098,
            "codeCH" : 0,
            "nameUIC" : "Ailly-sur-Somme",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313106,
            "codeCH" : 0,
            "nameUIC" : "Picquigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313114,
            "codeCH" : 0,
            "nameUIC" : "Hangest",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313122,
            "codeCH" : 0,
            "nameUIC" : "Longpré-les-Corps-Saints",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313130,
            "codeCH" : 0,
            "nameUIC" : "Pont-Remy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313197,
            "codeCH" : "BV",
            "nameUIC" : "Montières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MIE"
        },
        {
            "codeUIC" : 313197,
            "codeCH" : "N1",
            "nameUIC" : "Montières",
            "nameCH" : "ITE ZI Amiens N°1",
            "code" : "MIE"
        },
        {
            "codeUIC" : 313197,
            "codeCH" : "N2",
            "nameUIC" : "Montières",
            "nameCH" : "ITE ZI Amiens N°2",
            "code" : "MIE"
        },
        {
            "codeUIC" : 313197,
            "codeCH" : 0,
            "nameUIC" : "Montières",
            "nameCH" : "",
            "code" : "MIE"
        },
        {
            "codeUIC" : 313213,
            "codeCH" : 0,
            "nameUIC" : "Boves",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313221,
            "codeCH" : "BV",
            "nameUIC" : "Ailly-sur-Noye",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ASN"
        },
        {
            "codeUIC" : 313221,
            "codeCH" : "GP",
            "nameUIC" : "Ailly-sur-Noye",
            "nameCH" : "Évitement Impair",
            "code" : "ASN"
        },
        {
            "codeUIC" : 313239,
            "codeCH" : 0,
            "nameUIC" : "La Faloise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313247,
            "codeCH" : "BV",
            "nameUIC" : "Breteuil-Embranchement",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BEB"
        },
        {
            "codeUIC" : 313247,
            "codeCH" : "V4",
            "nameUIC" : "Breteuil-Embranchement",
            "nameCH" : "Voie 4",
            "code" : "BEB"
        },
        {
            "codeUIC" : 313254,
            "codeCH" : "BP",
            "nameUIC" : "Breteuil-Ville",
            "nameCH" : "Bâtiment Principal",
            "code" : "BRV"
        },
        {
            "codeUIC" : 313262,
            "codeCH" : 0,
            "nameUIC" : "Gannes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313270,
            "codeCH" : "BV",
            "nameUIC" : "St-Just-en-Chaussée",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SJC"
        },
        {
            "codeUIC" : 313270,
            "codeCH" : "V4",
            "nameUIC" : "St-Just-en-Chaussée",
            "nameCH" : "Voie 4",
            "code" : "SJC"
        },
        {
            "codeUIC" : 313288,
            "codeCH" : 0,
            "nameUIC" : "St-Remy-en-l'Eau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313296,
            "codeCH" : 0,
            "nameUIC" : "Avrechy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313304,
            "codeCH" : "BV",
            "nameUIC" : "Clermont-de-l'Oise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 313312,
            "codeCH" : "EP",
            "nameUIC" : "Catenoy",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 313320,
            "codeCH" : "BV",
            "nameUIC" : "Moreuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 313338,
            "codeCH" : 0,
            "nameUIC" : "Hargicourt-Pierrepont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313338,
            "codeCH" : 14,
            "nameUIC" : "Hargicourt-Pierrepont",
            "nameCH" : "Sémaphore 145 et 146",
            "code" : ""
        },
        {
            "codeUIC" : 313346,
            "codeCH" : 0,
            "nameUIC" : "Montdidier",
            "nameCH" : "",
            "code" : "MTD"
        },
        {
            "codeUIC" : 313353,
            "codeCH" : 0,
            "nameUIC" : "Tricot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313353,
            "codeCH" : 12,
            "nameUIC" : "Tricot",
            "nameCH" : "Sémaphore 127 et 128",
            "code" : ""
        },
        {
            "codeUIC" : 313379,
            "codeCH" : 0,
            "nameUIC" : "Namps-Quevauvillers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313387,
            "codeCH" : "BV",
            "nameUIC" : "Estrées-St-Denis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ESD"
        },
        {
            "codeUIC" : 313387,
            "codeCH" : "EP",
            "nameUIC" : "Estrées-St-Denis",
            "nameCH" : "ITE Bellagri",
            "code" : "ESD"
        },
        {
            "codeUIC" : 313395,
            "codeCH" : 0,
            "nameUIC" : "Poix-de-Picardie",
            "nameCH" : "",
            "code" : "POP"
        },
        {
            "codeUIC" : 313403,
            "codeCH" : 0,
            "nameUIC" : "Remy (Oise)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313437,
            "codeCH" : "S1",
            "nameUIC" : "Villers-Bretonneux",
            "nameCH" : "Sémaphore 13",
            "code" : ""
        },
        {
            "codeUIC" : 313437,
            "codeCH" : "S2",
            "nameUIC" : "Villers-Bretonneux",
            "nameCH" : "Sémaphore 14",
            "code" : ""
        },
        {
            "codeUIC" : 313437,
            "codeCH" : 0,
            "nameUIC" : "Villers-Bretonneux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313445,
            "codeCH" : "SE",
            "nameUIC" : "Marcelcave",
            "nameCH" : "Sémaphore 15/16",
            "code" : ""
        },
        {
            "codeUIC" : 313445,
            "codeCH" : 0,
            "nameUIC" : "Marcelcave",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313452,
            "codeCH" : 0,
            "nameUIC" : "Guillaucourt",
            "nameCH" : "",
            "code" : "GUT"
        },
        {
            "codeUIC" : 313460,
            "codeCH" : "SE",
            "nameUIC" : "Rosières",
            "nameCH" : "Sémaphore 17/18",
            "code" : "ROS"
        },
        {
            "codeUIC" : 313460,
            "codeCH" : 0,
            "nameUIC" : "Rosières",
            "nameCH" : "",
            "code" : "ROS"
        },
        {
            "codeUIC" : 313478,
            "codeCH" : "BV",
            "nameUIC" : "Chaulnes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHL"
        },
        {
            "codeUIC" : 313478,
            "codeCH" : "EP",
            "nameUIC" : "Chaulnes",
            "nameCH" : "ITE SCAEX",
            "code" : "CHL"
        },
        {
            "codeUIC" : 313478,
            "codeCH" : "GV",
            "nameUIC" : "Chaulnes",
            "nameCH" : "Base LGV",
            "code" : "CHL"
        },
        {
            "codeUIC" : 313478,
            "codeCH" : "S2",
            "nameUIC" : "Chaulnes",
            "nameCH" : "Sémaphore 62",
            "code" : "CHL"
        },
        {
            "codeUIC" : 313478,
            "codeCH" : 5,
            "nameUIC" : "Chaulnes",
            "nameCH" : "Carré 105",
            "code" : "CHL"
        },
        {
            "codeUIC" : 313478,
            "codeCH" : 26,
            "nameUIC" : "Chaulnes",
            "nameCH" : "Carré 126",
            "code" : "CHL"
        },
        {
            "codeUIC" : 313478,
            "codeCH" : 31,
            "nameUIC" : "Chaulnes",
            "nameCH" : "Carré 131",
            "code" : "CHL"
        },
        {
            "codeUIC" : 313486,
            "codeCH" : "C1",
            "nameUIC" : "Nesle (Somme)",
            "nameCH" : "Carré 1",
            "code" : "NES"
        },
        {
            "codeUIC" : 313486,
            "codeCH" : "S2",
            "nameUIC" : "Nesle (Somme)",
            "nameCH" : "Sémaphore 82",
            "code" : "NES"
        },
        {
            "codeUIC" : 313486,
            "codeCH" : "S3",
            "nameUIC" : "Nesle (Somme)",
            "nameCH" : "Sémaphore 85",
            "code" : "NES"
        },
        {
            "codeUIC" : 313486,
            "codeCH" : "S6",
            "nameUIC" : "Nesle (Somme)",
            "nameCH" : "Sémaphore 86",
            "code" : "NES"
        },
        {
            "codeUIC" : 313486,
            "codeCH" : "S8",
            "nameUIC" : "Nesle (Somme)",
            "nameCH" : "Sémaphore 2",
            "code" : "NES"
        },
        {
            "codeUIC" : 313486,
            "codeCH" : 0,
            "nameUIC" : "Nesle (Somme)",
            "nameCH" : "",
            "code" : "NES"
        },
        {
            "codeUIC" : 313494,
            "codeCH" : "C4",
            "nameUIC" : "Ham (Somme)",
            "nameCH" : "Carré 102",
            "code" : "HAM"
        },
        {
            "codeUIC" : 313494,
            "codeCH" : "C5",
            "nameUIC" : "Ham (Somme)",
            "nameCH" : "Carré 101",
            "code" : "HAM"
        },
        {
            "codeUIC" : 313494,
            "codeCH" : 0,
            "nameUIC" : "Ham (Somme)",
            "nameCH" : "",
            "code" : "HAM"
        },
        {
            "codeUIC" : 313502,
            "codeCH" : 0,
            "nameUIC" : "Roye (Somme)",
            "nameCH" : "",
            "code" : "ROY"
        },
        {
            "codeUIC" : 313510,
            "codeCH" : "BV",
            "nameUIC" : "Beauvais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BVS"
        },
        {
            "codeUIC" : 313510,
            "codeCH" : "MA",
            "nameUIC" : "Beauvais",
            "nameCH" : "Garage Marchandises",
            "code" : "BVS"
        },
        {
            "codeUIC" : 313510,
            "codeCH" : "VI",
            "nameUIC" : "Beauvais",
            "nameCH" : "Bifurcation de Villers-sur-Thère",
            "code" : "BVS"
        },
        {
            "codeUIC" : 313510,
            "codeCH" : "VO",
            "nameUIC" : "Beauvais",
            "nameCH" : "Garage Voyageurs",
            "code" : "BVS"
        },
        {
            "codeUIC" : 313544,
            "codeCH" : 0,
            "nameUIC" : "Cramoisy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313551,
            "codeCH" : 0,
            "nameUIC" : "Cires-lès-Mello",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313569,
            "codeCH" : 0,
            "nameUIC" : "Balagny-St-Épin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313577,
            "codeCH" : 0,
            "nameUIC" : "Mouy-Bury",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313585,
            "codeCH" : 0,
            "nameUIC" : "Hermes-Berthecourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313593,
            "codeCH" : 0,
            "nameUIC" : "Villers-St-Sépulcre",
            "nameCH" : "",
            "code" : "VS-"
        },
        {
            "codeUIC" : 313601,
            "codeCH" : 0,
            "nameUIC" : "Rochy-Condé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313619,
            "codeCH" : "BV",
            "nameUIC" : "Bresles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BLW"
        },
        {
            "codeUIC" : 313619,
            "codeCH" : "EP",
            "nameUIC" : "Bresles",
            "nameCH" : "ITE Sucreries",
            "code" : "BLW"
        },
        {
            "codeUIC" : 313619,
            "codeCH" : "HT",
            "nameUIC" : "Bresles",
            "nameCH" : "Heurtoir",
            "code" : "BLW"
        },
        {
            "codeUIC" : 313668,
            "codeCH" : "BV",
            "nameUIC" : "Méru",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 313668,
            "codeCH" : "MA",
            "nameUIC" : "Méru",
            "nameCH" : "ITE Mauser",
            "code" : ""
        },
        {
            "codeUIC" : 313668,
            "codeCH" : "NA",
            "nameUIC" : "Méru",
            "nameCH" : "ITE Nathan",
            "code" : ""
        },
        {
            "codeUIC" : 313668,
            "codeCH" : 59,
            "nameUIC" : "Méru",
            "nameCH" : "Aiguille Km 59,4",
            "code" : ""
        },
        {
            "codeUIC" : 313668,
            "codeCH" : 64,
            "nameUIC" : "Méru",
            "nameCH" : "Aiguille Km 64,6",
            "code" : ""
        },
        {
            "codeUIC" : 313676,
            "codeCH" : 0,
            "nameUIC" : "Laboissière-le-Déluge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313684,
            "codeCH" : 0,
            "nameUIC" : "St-Sulpice-Auteuil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313692,
            "codeCH" : 0,
            "nameUIC" : "Herchies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313692,
            "codeCH" : 42,
            "nameUIC" : "Herchies",
            "nameCH" : "Carré 5254",
            "code" : ""
        },
        {
            "codeUIC" : 313700,
            "codeCH" : 0,
            "nameUIC" : "Milly-sur-Thérain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313718,
            "codeCH" : 0,
            "nameUIC" : "St-Omer-en-Chaussée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313726,
            "codeCH" : 0,
            "nameUIC" : "Marseille-en-Beauvaisis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313734,
            "codeCH" : 0,
            "nameUIC" : "Grandvilliers",
            "nameCH" : "",
            "code" : "GAR"
        },
        {
            "codeUIC" : 313742,
            "codeCH" : 0,
            "nameUIC" : "Feuquières-Broquiers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313759,
            "codeCH" : 0,
            "nameUIC" : "Abancourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313767,
            "codeCH" : 0,
            "nameUIC" : "Aumale",
            "nameCH" : "",
            "code" : "AUM"
        },
        {
            "codeUIC" : 313809,
            "codeCH" : 0,
            "nameUIC" : "Blangy-sur-Bresle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313817,
            "codeCH" : 0,
            "nameUIC" : "Longroy-Gamaches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313825,
            "codeCH" : 0,
            "nameUIC" : "Incheville",
            "nameCH" : "",
            "code" : "ICH"
        },
        {
            "codeUIC" : 313833,
            "codeCH" : 0,
            "nameUIC" : "Formerie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313841,
            "codeCH" : 0,
            "nameUIC" : "Fouilloy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 313866,
            "codeCH" : 0,
            "nameUIC" : "Péronne-la-Chapelette",
            "nameCH" : "",
            "code" : "PEC"
        },
        {
            "codeUIC" : 313874,
            "codeCH" : "BV",
            "nameUIC" : "Amiens",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AS"
        },
        {
            "codeUIC" : 313874,
            "codeCH" : "J1",
            "nameUIC" : "Amiens",
            "nameCH" : "Jonction DC/DA",
            "code" : "AS"
        },
        {
            "codeUIC" : 313874,
            "codeCH" : "J2",
            "nameUIC" : "Amiens",
            "nameCH" : "Jonction Nord",
            "code" : "AS"
        },
        {
            "codeUIC" : 313874,
            "codeCH" : "P1",
            "nameUIC" : "Amiens",
            "nameCH" : "Poste 1",
            "code" : "AS"
        },
        {
            "codeUIC" : 313874,
            "codeCH" : "RA",
            "nameUIC" : "Amiens",
            "nameCH" : "Faisceau Marchandises",
            "code" : "AS"
        },
        {
            "codeUIC" : 313882,
            "codeCH" : "AN",
            "nameUIC" : "TGV Haute-Picardie",
            "nameCH" : "Aiguille Nord",
            "code" : ""
        },
        {
            "codeUIC" : 313882,
            "codeCH" : "AS",
            "nameUIC" : "TGV Haute-Picardie",
            "nameCH" : "Aiguille Sud",
            "code" : ""
        },
        {
            "codeUIC" : 313882,
            "codeCH" : "BV",
            "nameUIC" : "TGV Haute-Picardie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 313882,
            "codeCH" : "PR",
            "nameUIC" : "TGV Haute-Picardie",
            "nameCH" : "Poste 21",
            "code" : ""
        },
        {
            "codeUIC" : 314963,
            "codeCH" : "BF",
            "nameUIC" : "Poste 71 Saze",
            "nameCH" : "Bifurcation Languedoc",
            "code" : ""
        },
        {
            "codeUIC" : 314963,
            "codeCH" : "PR",
            "nameUIC" : "Poste 71 Saze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 314971,
            "codeCH" : "BF",
            "nameUIC" : "Poste 72 Redessan",
            "nameCH" : "Bifurcation de Nîmes",
            "code" : ""
        },
        {
            "codeUIC" : 314971,
            "codeCH" : "BM",
            "nameUIC" : "Poste 72 Redessan",
            "nameCH" : "Base Maintenance",
            "code" : ""
        },
        {
            "codeUIC" : 314971,
            "codeCH" : "PR",
            "nameUIC" : "Poste 72 Redessan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 315010,
            "codeCH" : "EP",
            "nameUIC" : "Bacouël",
            "nameCH" : "ITE des Entrepots Pétroliers de la Somme",
            "code" : "BCU"
        },
        {
            "codeUIC" : 315010,
            "codeCH" : 36,
            "nameUIC" : "Bacouël",
            "nameCH" : "Carré 36",
            "code" : "BCU"
        },
        {
            "codeUIC" : 315010,
            "codeCH" : 37,
            "nameUIC" : "Bacouël",
            "nameCH" : "Carré 37",
            "code" : "BCU"
        },
        {
            "codeUIC" : 315044,
            "codeCH" : "BV",
            "nameUIC" : "Daours",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DAO"
        },
        {
            "codeUIC" : 315044,
            "codeCH" : "RO",
            "nameUIC" : "Daours",
            "nameCH" : "ITE Roquette",
            "code" : "DAO"
        },
        {
            "codeUIC" : 315127,
            "codeCH" : 0,
            "nameUIC" : "Bertangles-Poulainville",
            "nameCH" : "Bâtiment Principal",
            "code" : ""
        },
        {
            "codeUIC" : 315150,
            "codeCH" : "BV",
            "nameUIC" : "Breuil-le-Sec",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 315150,
            "codeCH" : "EM",
            "nameUIC" : "Breuil-le-Sec",
            "nameCH" : "ITE Merckaert",
            "code" : ""
        },
        {
            "codeUIC" : 315150,
            "codeCH" : "ES",
            "nameUIC" : "Breuil-le-Sec",
            "nameCH" : "ITE Sapec",
            "code" : ""
        },
        {
            "codeUIC" : 315168,
            "codeCH" : "BV",
            "nameUIC" : "Avrigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AVG"
        },
        {
            "codeUIC" : 315168,
            "codeCH" : "EP",
            "nameUIC" : "Avrigny",
            "nameCH" : "ITE Coopérative Agricole",
            "code" : "AVG"
        },
        {
            "codeUIC" : 315168,
            "codeCH" : "ES",
            "nameUIC" : "Avrigny",
            "nameCH" : "ITE S.T.A.D.",
            "code" : "AVG"
        },
        {
            "codeUIC" : 315168,
            "codeCH" : "HT",
            "nameUIC" : "Avrigny",
            "nameCH" : "Heurtoir",
            "code" : "AVG"
        },
        {
            "codeUIC" : 315234,
            "codeCH" : 0,
            "nameUIC" : "Marchélepot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 315242,
            "codeCH" : 0,
            "nameUIC" : "Pont-les-Brie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 315267,
            "codeCH" : "GV",
            "nameUIC" : "Hattencourt",
            "nameCH" : "Raccordement LGV",
            "code" : ""
        },
        {
            "codeUIC" : 315267,
            "codeCH" : "PR",
            "nameUIC" : "Hattencourt",
            "nameCH" : "Poste 20",
            "code" : ""
        },
        {
            "codeUIC" : 315341,
            "codeCH" : "EC",
            "nameUIC" : "Bailleul-sur-Thérain",
            "nameCH" : "ITE Colas",
            "code" : "BGG"
        },
        {
            "codeUIC" : 315341,
            "codeCH" : "EF",
            "nameUIC" : "Bailleul-sur-Thérain",
            "nameCH" : "ITE Profilafroid",
            "code" : "BGG"
        },
        {
            "codeUIC" : 315341,
            "codeCH" : "EG",
            "nameUIC" : "Bailleul-sur-Thérain",
            "nameCH" : "ITE GEB",
            "code" : "BGG"
        },
        {
            "codeUIC" : 315341,
            "codeCH" : "EP",
            "nameUIC" : "Bailleul-sur-Thérain",
            "nameCH" : "ITE Procidia",
            "code" : "BGG"
        },
        {
            "codeUIC" : 315390,
            "codeCH" : 0,
            "nameUIC" : "Warluis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316034,
            "codeCH" : "S1",
            "nameUIC" : "Blangy-Glisy",
            "nameCH" : "Sémaphore 11",
            "code" : ""
        },
        {
            "codeUIC" : 316034,
            "codeCH" : "S2",
            "nameUIC" : "Blangy-Glisy",
            "nameCH" : "Sémaphore 7",
            "code" : ""
        },
        {
            "codeUIC" : 316034,
            "codeCH" : 0,
            "nameUIC" : "Blangy-Glisy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316042,
            "codeCH" : 0,
            "nameUIC" : "Lamotte-Brebière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316059,
            "codeCH" : 0,
            "nameUIC" : "Heilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316067,
            "codeCH" : 0,
            "nameUIC" : "Buire-sur-l'Ancre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316075,
            "codeCH" : 0,
            "nameUIC" : "Dreuil-lès-Amiens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316109,
            "codeCH" : 0,
            "nameUIC" : "Dommartin-Remiencourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316125,
            "codeCH" : 0,
            "nameUIC" : "Thézy-Glimont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316190,
            "codeCH" : 0,
            "nameUIC" : "Famechon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316208,
            "codeCH" : 0,
            "nameUIC" : "Wacquemoulin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316281,
            "codeCH" : 0,
            "nameUIC" : "Wiencourt-l'Équipée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316315,
            "codeCH" : 0,
            "nameUIC" : "Curchy-Dreslincourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316398,
            "codeCH" : 0,
            "nameUIC" : "Heilles-Mouchy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316406,
            "codeCH" : 0,
            "nameUIC" : "Montreuil-sur-Thérain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316422,
            "codeCH" : 0,
            "nameUIC" : "Villers-sur-Thère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316455,
            "codeCH" : 0,
            "nameUIC" : "Achy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316463,
            "codeCH" : 0,
            "nameUIC" : "Fontaine-Lavaganne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316471,
            "codeCH" : 0,
            "nameUIC" : "Grez-Gaudechart",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316497,
            "codeCH" : 0,
            "nameUIC" : "Moliens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316588,
            "codeCH" : 0,
            "nameUIC" : "Romescamps",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316596,
            "codeCH" : 0,
            "nameUIC" : "Ste-Segrée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316604,
            "codeCH" : 0,
            "nameUIC" : "Aubin-St-Vaast",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316638,
            "codeCH" : 0,
            "nameUIC" : "Beutin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316679,
            "codeCH" : 0,
            "nameUIC" : "Pihen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316687,
            "codeCH" : 0,
            "nameUIC" : "Le Haut-Banc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316695,
            "codeCH" : 0,
            "nameUIC" : "St-Pierre-lès-Calais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316745,
            "codeCH" : 0,
            "nameUIC" : "Acheux-Franleu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316752,
            "codeCH" : 0,
            "nameUIC" : "Feuquerolles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 316760,
            "codeCH" : 0,
            "nameUIC" : "Eu-la-Mouillette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317016,
            "codeCH" : 0,
            "nameUIC" : "Boulogne-Tintelleries",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317040,
            "codeCH" : 0,
            "nameUIC" : "Conchil-le-Temple",
            "nameCH" : "",
            "code" : "CNC"
        },
        {
            "codeUIC" : 317040,
            "codeCH" : 1,
            "nameUIC" : "Conchil-le-Temple",
            "nameCH" : "Sémaphore BAPR 9101",
            "code" : "CNC"
        },
        {
            "codeUIC" : 317040,
            "codeCH" : 2,
            "nameUIC" : "Conchil-le-Temple",
            "nameCH" : "Sémaphore BAPR 9102",
            "code" : "CNC"
        },
        {
            "codeUIC" : 317057,
            "codeCH" : 0,
            "nameUIC" : "Rang-du-Fliers-Verton",
            "nameCH" : "",
            "code" : "RDF"
        },
        {
            "codeUIC" : 317065,
            "codeCH" : "AI",
            "nameUIC" : "Étaples-Le Touquet",
            "nameCH" : "Aiguille vers Voie Unique",
            "code" : ""
        },
        {
            "codeUIC" : 317065,
            "codeCH" : "AN",
            "nameUIC" : "Étaples-Le Touquet",
            "nameCH" : "Accès Nord",
            "code" : ""
        },
        {
            "codeUIC" : 317065,
            "codeCH" : "BV",
            "nameUIC" : "Étaples-Le Touquet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 317081,
            "codeCH" : 0,
            "nameUIC" : "Dannes-Camiers",
            "nameCH" : "",
            "code" : "DC"
        },
        {
            "codeUIC" : 317099,
            "codeCH" : 0,
            "nameUIC" : "Neufchâtel-Hardelot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317107,
            "codeCH" : "VU",
            "nameUIC" : "Hesdigneul",
            "nameCH" : "Accès Voie Unique",
            "code" : ""
        },
        {
            "codeUIC" : 317107,
            "codeCH" : 0,
            "nameUIC" : "Hesdigneul",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317115,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Briques",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317123,
            "codeCH" : 0,
            "nameUIC" : "Wimille-Wimereux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317149,
            "codeCH" : 0,
            "nameUIC" : "Desvres",
            "nameCH" : "",
            "code" : "DEV"
        },
        {
            "codeUIC" : 317164,
            "codeCH" : 0,
            "nameUIC" : "Montreuil-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317172,
            "codeCH" : 0,
            "nameUIC" : "Attin-Garage",
            "nameCH" : "",
            "code" : "ATT"
        },
        {
            "codeUIC" : 317180,
            "codeCH" : 0,
            "nameUIC" : "Brimeux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317198,
            "codeCH" : 0,
            "nameUIC" : "Beaurainville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317206,
            "codeCH" : 0,
            "nameUIC" : "Maresquel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317214,
            "codeCH" : "FM",
            "nameUIC" : "Boulogne Marée",
            "nameCH" : "Faisceau Marée",
            "code" : ""
        },
        {
            "codeUIC" : 317222,
            "codeCH" : 0,
            "nameUIC" : "Hesdin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317230,
            "codeCH" : 0,
            "nameUIC" : "Auchy-lès-Hesdin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317248,
            "codeCH" : 0,
            "nameUIC" : "Blangy-sur-Ternoise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317255,
            "codeCH" : 0,
            "nameUIC" : "Anvin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317263,
            "codeCH" : "BV",
            "nameUIC" : "Calais-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CS"
        },
        {
            "codeUIC" : 317263,
            "codeCH" : "EP",
            "nameUIC" : "Calais-Ville",
            "nameCH" : "ITE Magnesia",
            "code" : "CS"
        },
        {
            "codeUIC" : 317263,
            "codeCH" : "TA",
            "nameUIC" : "Calais-Ville",
            "nameCH" : "Quai Auto-train",
            "code" : "CS"
        },
        {
            "codeUIC" : 317297,
            "codeCH" : "GA",
            "nameUIC" : "Calais-Triage",
            "nameCH" : "Garages",
            "code" : ""
        },
        {
            "codeUIC" : 317297,
            "codeCH" : "P1",
            "nameUIC" : "Calais-Triage",
            "nameCH" : "Bifurcation des Fontinettes Nord",
            "code" : ""
        },
        {
            "codeUIC" : 317305,
            "codeCH" : 0,
            "nameUIC" : "Les Fontinettes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317321,
            "codeCH" : "BV",
            "nameUIC" : "Caffiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CAF"
        },
        {
            "codeUIC" : 317339,
            "codeCH" : 0,
            "nameUIC" : "Marquise-Rinxent",
            "nameCH" : "",
            "code" : "MRX"
        },
        {
            "codeUIC" : 317362,
            "codeCH" : 0,
            "nameUIC" : "Abbeville",
            "nameCH" : "",
            "code" : "ABB"
        },
        {
            "codeUIC" : 317362,
            "codeCH" : 3,
            "nameUIC" : "Abbeville",
            "nameCH" : "Sémaphore BAPR 303",
            "code" : "ABB"
        },
        {
            "codeUIC" : 317362,
            "codeCH" : 24,
            "nameUIC" : "Abbeville",
            "nameCH" : "Sémaphore BAPR 224",
            "code" : "ABB"
        },
        {
            "codeUIC" : 317396,
            "codeCH" : 0,
            "nameUIC" : "Noyelles",
            "nameCH" : "",
            "code" : "NOE"
        },
        {
            "codeUIC" : 317396,
            "codeCH" : 21,
            "nameUIC" : "Noyelles",
            "nameCH" : "Sémaphore BAPR 321",
            "code" : "NOE"
        },
        {
            "codeUIC" : 317396,
            "codeCH" : 31,
            "nameUIC" : "Noyelles",
            "nameCH" : "Sémaphore BAPR 331",
            "code" : "NOE"
        },
        {
            "codeUIC" : 317396,
            "codeCH" : 32,
            "nameUIC" : "Noyelles",
            "nameCH" : "Sémaphore BAPR 332",
            "code" : "NOE"
        },
        {
            "codeUIC" : 317404,
            "codeCH" : 0,
            "nameUIC" : "Rue",
            "nameCH" : "",
            "code" : "RUE"
        },
        {
            "codeUIC" : 317487,
            "codeCH" : 0,
            "nameUIC" : "Quesnoy-le-Montant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317495,
            "codeCH" : 0,
            "nameUIC" : "Chepy-Valines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317503,
            "codeCH" : 0,
            "nameUIC" : "Feuquières-Fressenneville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317511,
            "codeCH" : 0,
            "nameUIC" : "Woincourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317529,
            "codeCH" : "BV",
            "nameUIC" : "Le Tréport-Mers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LTM"
        },
        {
            "codeUIC" : 317529,
            "codeCH" : "P1",
            "nameUIC" : "Le Tréport-Mers",
            "nameCH" : "Bifurcation d'Eu",
            "code" : "LTM"
        },
        {
            "codeUIC" : 317537,
            "codeCH" : 0,
            "nameUIC" : "Eu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317552,
            "codeCH" : 0,
            "nameUIC" : "Boulogne-Aéroglisseurs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317586,
            "codeCH" : "BV",
            "nameUIC" : "Boulogne-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BE"
        },
        {
            "codeUIC" : 317586,
            "codeCH" : "GA",
            "nameUIC" : "Boulogne-Ville",
            "nameCH" : "Garages Outreau",
            "code" : "BE"
        },
        {
            "codeUIC" : 317586,
            "codeCH" : "GV",
            "nameUIC" : "Boulogne-Ville",
            "nameCH" : "Garage Voyageurs",
            "code" : "BE"
        },
        {
            "codeUIC" : 317586,
            "codeCH" : "P1",
            "nameUIC" : "Boulogne-Ville",
            "nameCH" : "Bifurcation de St-Léonard",
            "code" : "BE"
        },
        {
            "codeUIC" : 317586,
            "codeCH" : "P3",
            "nameUIC" : "Boulogne-Ville",
            "nameCH" : "Bifurcation d'Outreau",
            "code" : "BE"
        },
        {
            "codeUIC" : 317586,
            "codeCH" : "P5",
            "nameUIC" : "Boulogne-Ville",
            "nameCH" : "Poste 5",
            "code" : "BE"
        },
        {
            "codeUIC" : 317594,
            "codeCH" : "FL",
            "nameUIC" : "Boulogne-Bassins",
            "nameCH" : "Faisceau Loubet",
            "code" : ""
        },
        {
            "codeUIC" : 317610,
            "codeCH" : "EP",
            "nameUIC" : "Beau-Marais",
            "nameCH" : "ITE Turquerie",
            "code" : ""
        },
        {
            "codeUIC" : 317610,
            "codeCH" : 0,
            "nameUIC" : "Beau-Marais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 317677,
            "codeCH" : 0,
            "nameUIC" : "Montplaisir",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 318964,
            "codeCH" : "BF",
            "nameUIC" : "Avignon-TGV",
            "nameCH" : "Bifurcation Grand-Sud",
            "code" : ""
        },
        {
            "codeUIC" : 318964,
            "codeCH" : "BV",
            "nameUIC" : "Avignon-TGV",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 318972,
            "codeCH" : 0,
            "nameUIC" : "Poste 63 Cavaillon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 318980,
            "codeCH" : "PR",
            "nameUIC" : "Poste 60 Piolenc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 318980,
            "codeCH" : "V4",
            "nameUIC" : "Poste 60 Piolenc",
            "nameCH" : "Voie 4",
            "code" : ""
        },
        {
            "codeUIC" : 318980,
            "codeCH" : "ZE",
            "nameUIC" : "Poste 60 Piolenc",
            "nameCH" : "Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 318998,
            "codeCH" : "PR",
            "nameUIC" : "Poste 64 Senas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 318998,
            "codeCH" : "RC",
            "nameUIC" : "Poste 64 Senas",
            "nameCH" : "Rac Cheval Blanc",
            "code" : ""
        },
        {
            "codeUIC" : 318998,
            "codeCH" : "V3",
            "nameUIC" : "Poste 64 Senas",
            "nameCH" : "Voie 3",
            "code" : ""
        },
        {
            "codeUIC" : 318998,
            "codeCH" : "ZE",
            "nameUIC" : "Poste 64 Senas",
            "nameCH" : "Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 319004,
            "codeCH" : 0,
            "nameUIC" : "Poste 65 Lambesc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 319012,
            "codeCH" : 0,
            "nameUIC" : "Aix-en-Provence-TGV",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 319103,
            "codeCH" : "AI",
            "nameUIC" : "Poste 61 Roquemaure",
            "nameCH" : "Aiguille",
            "code" : ""
        },
        {
            "codeUIC" : 319103,
            "codeCH" : "BF",
            "nameUIC" : "Poste 61 Roquemaure",
            "nameCH" : "Bifurcation des Angles",
            "code" : ""
        },
        {
            "codeUIC" : 319103,
            "codeCH" : "PR",
            "nameUIC" : "Poste 61 Roquemaure",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 321745,
            "codeCH" : "BV",
            "nameUIC" : "Penhoët",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 322347,
            "codeCH" : 0,
            "nameUIC" : "Traou-Nez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 322792,
            "codeCH" : "ST",
            "nameUIC" : "Le Parc",
            "nameCH" : "Sous-Station",
            "code" : ""
        },
        {
            "codeUIC" : 323675,
            "codeCH" : 0,
            "nameUIC" : "Montluçon-Rimard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 324095,
            "codeCH" : "BV",
            "nameUIC" : "Futuroscope",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 324335,
            "codeCH" : 0,
            "nameUIC" : "Hirson-Écoles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 324723,
            "codeCH" : 0,
            "nameUIC" : "ITE Ciments d'Origny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 328021,
            "codeCH" : "IT",
            "nameUIC" : "Les Cauquillous",
            "nameCH" : "ITE Km 259,769",
            "code" : ""
        },
        {
            "codeUIC" : 328021,
            "codeCH" : 0,
            "nameUIC" : "Les Cauquillous",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 328112,
            "codeCH" : 0,
            "nameUIC" : "Purretone",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 328195,
            "codeCH" : "BV",
            "nameUIC" : "Thésée",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 328328,
            "codeCH" : "BV",
            "nameUIC" : "Bibliothèque-François-Mitterra",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 328328,
            "codeCH" : "EB",
            "nameUIC" : "Bibliothèque-François-Mitterra",
            "nameCH" : "Entrée Voie B",
            "code" : ""
        },
        {
            "codeUIC" : 328328,
            "codeCH" : "ES",
            "nameUIC" : "Bibliothèque-François-Mitterra",
            "nameCH" : "Entrée/Sortie Voie 2BIS",
            "code" : ""
        },
        {
            "codeUIC" : 328328,
            "codeCH" : "SB",
            "nameUIC" : "Bibliothèque-François-Mitterra",
            "nameCH" : "Sortie Voie V1BIS",
            "code" : ""
        },
        {
            "codeUIC" : 328328,
            "codeCH" : "SE",
            "nameUIC" : "Bibliothèque-François-Mitterra",
            "nameCH" : "Sortie Voie E",
            "code" : ""
        },
        {
            "codeUIC" : 328328,
            "codeCH" : "TA",
            "nameUIC" : "Bibliothèque-François-Mitterra",
            "nameCH" : "Accès Voie A",
            "code" : ""
        },
        {
            "codeUIC" : 334482,
            "codeCH" : 0,
            "nameUIC" : "Neuville-Université",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 334490,
            "codeCH" : 0,
            "nameUIC" : "Cesson-Sévigné-Halte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 334508,
            "codeCH" : 0,
            "nameUIC" : "La Poterie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 337378,
            "codeCH" : "BV",
            "nameUIC" : "Dives-sur-Mer-Port-Guillaume",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 337980,
            "codeCH" : 0,
            "nameUIC" : "St-Ouen-l'Aumône-Liesse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 338517,
            "codeCH" : 0,
            "nameUIC" : "Hoenheim-Tram",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 338525,
            "codeCH" : 0,
            "nameUIC" : "Krimmeri-Meinau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 338590,
            "codeCH" : 0,
            "nameUIC" : "Monte-Carlo-Country-Club",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342006,
            "codeCH" : "BV",
            "nameUIC" : "Béthune",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BET"
        },
        {
            "codeUIC" : 342006,
            "codeCH" : "ES",
            "nameUIC" : "Béthune",
            "nameCH" : "Entrée/Sortie Béthune",
            "code" : "BET"
        },
        {
            "codeUIC" : 342006,
            "codeCH" : "MA",
            "nameUIC" : "Béthune",
            "nameCH" : "Marchandises",
            "code" : "BET"
        },
        {
            "codeUIC" : 342006,
            "codeCH" : "P2",
            "nameUIC" : "Béthune",
            "nameCH" : "Poste 2",
            "code" : "BET"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "BN",
            "nameUIC" : "Arras",
            "nameCH" : "Bifurcation Nord",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "BS",
            "nameUIC" : "Arras",
            "nameCH" : "Bifurcation Sud",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "BV",
            "nameUIC" : "Arras",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "CT",
            "nameUIC" : "Arras",
            "nameCH" : "CTA",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "EN",
            "nameUIC" : "Arras",
            "nameCH" : "Triage Accès Nord",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "G1",
            "nameUIC" : "Arras",
            "nameCH" : "Voie G1",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "PL",
            "nameUIC" : "Arras",
            "nameCH" : "Plaine",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "P1",
            "nameUIC" : "Arras",
            "nameCH" : "Poste 1",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "P2",
            "nameUIC" : "Arras",
            "nameCH" : "Poste 2",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "RA",
            "nameUIC" : "Arras",
            "nameCH" : "Faisceau RA",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "RE",
            "nameUIC" : "Arras",
            "nameCH" : "Bifurcation Blangy-Est",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "RL",
            "nameUIC" : "Arras",
            "nameCH" : "Relais RE/VK",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342014,
            "codeCH" : "RN",
            "nameUIC" : "Arras",
            "nameCH" : "Bifurcation Blangy-Nord",
            "code" : "ARR"
        },
        {
            "codeUIC" : 342030,
            "codeCH" : 0,
            "nameUIC" : "Miraumont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342048,
            "codeCH" : 0,
            "nameUIC" : "Achiet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342063,
            "codeCH" : 0,
            "nameUIC" : "Courcelles-le-Comte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342071,
            "codeCH" : 0,
            "nameUIC" : "Boisleux",
            "nameCH" : "",
            "code" : "BLX"
        },
        {
            "codeUIC" : 342089,
            "codeCH" : 0,
            "nameUIC" : "Roeux (Pas-de-Calais)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342097,
            "codeCH" : 0,
            "nameUIC" : "Biache-St-Vaast",
            "nameCH" : "",
            "code" : "BIA"
        },
        {
            "codeUIC" : 342105,
            "codeCH" : "BV",
            "nameUIC" : "Bully-Grenay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BG"
        },
        {
            "codeUIC" : 342113,
            "codeCH" : 0,
            "nameUIC" : "Vitry-en-Artois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342121,
            "codeCH" : 0,
            "nameUIC" : "Mazingarbe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342139,
            "codeCH" : 0,
            "nameUIC" : "Noeux-les-Mines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342154,
            "codeCH" : 0,
            "nameUIC" : "Achicourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342162,
            "codeCH" : 0,
            "nameUIC" : "Dainville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342170,
            "codeCH" : 0,
            "nameUIC" : "Duisans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342188,
            "codeCH" : "BV",
            "nameUIC" : "Maroeuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MUI"
        },
        {
            "codeUIC" : 342188,
            "codeCH" : "GY",
            "nameUIC" : "Maroeuil",
            "nameCH" : "ITE Les Engrais du GY",
            "code" : "MUI"
        },
        {
            "codeUIC" : 342196,
            "codeCH" : 0,
            "nameUIC" : "Mont-St-Éloi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342204,
            "codeCH" : "BF",
            "nameUIC" : "Fouquereuil",
            "nameCH" : "Bifurcation de Fouquereuil",
            "code" : "FQE"
        },
        {
            "codeUIC" : 342204,
            "codeCH" : "BV",
            "nameUIC" : "Fouquereuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FQE"
        },
        {
            "codeUIC" : 342204,
            "codeCH" : "EP",
            "nameUIC" : "Fouquereuil",
            "nameCH" : "ITE Atochem",
            "code" : "FQE"
        },
        {
            "codeUIC" : 342212,
            "codeCH" : 0,
            "nameUIC" : "Frévin-Capelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342220,
            "codeCH" : 0,
            "nameUIC" : "Chocques",
            "nameCH" : "",
            "code" : "CHO"
        },
        {
            "codeUIC" : 342238,
            "codeCH" : 0,
            "nameUIC" : "Lillers",
            "nameCH" : "",
            "code" : "LLL"
        },
        {
            "codeUIC" : 342246,
            "codeCH" : 0,
            "nameUIC" : "Aubigny-en-Artois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342253,
            "codeCH" : 0,
            "nameUIC" : "Savy-Berlette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342261,
            "codeCH" : 0,
            "nameUIC" : "Ham-en-Artois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342279,
            "codeCH" : 0,
            "nameUIC" : "Berguette-Isbergues",
            "nameCH" : "",
            "code" : "ISB"
        },
        {
            "codeUIC" : 342287,
            "codeCH" : 0,
            "nameUIC" : "Thiennes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342295,
            "codeCH" : 0,
            "nameUIC" : "Steenbecque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342303,
            "codeCH" : 0,
            "nameUIC" : "Tincques",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342311,
            "codeCH" : 0,
            "nameUIC" : "Ligny-St-Flochel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342337,
            "codeCH" : "BV",
            "nameUIC" : "St-Pol-sur-Ternoise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SPT"
        },
        {
            "codeUIC" : 342378,
            "codeCH" : 0,
            "nameUIC" : "Brias",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342402,
            "codeCH" : 0,
            "nameUIC" : "Beuvry (Pas-de-Calais)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342410,
            "codeCH" : 0,
            "nameUIC" : "Cuinchy",
            "nameCH" : "",
            "code" : "CUH"
        },
        {
            "codeUIC" : 342436,
            "codeCH" : 0,
            "nameUIC" : "La Bassée-Violaines",
            "nameCH" : "",
            "code" : "LBV"
        },
        {
            "codeUIC" : 342451,
            "codeCH" : 0,
            "nameUIC" : "Salomé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342469,
            "codeCH" : 0,
            "nameUIC" : "Marquillies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342501,
            "codeCH" : 0,
            "nameUIC" : "La Beuvrière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342519,
            "codeCH" : 0,
            "nameUIC" : "Lapugnoy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342527,
            "codeCH" : 0,
            "nameUIC" : "Farbus",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342535,
            "codeCH" : 0,
            "nameUIC" : "Vis-à-Marles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342543,
            "codeCH" : 0,
            "nameUIC" : "Calonne-Ricouart",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342550,
            "codeCH" : 0,
            "nameUIC" : "Pernes-Camblain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342568,
            "codeCH" : 0,
            "nameUIC" : "Bours",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342576,
            "codeCH" : 0,
            "nameUIC" : "Vimy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342584,
            "codeCH" : 0,
            "nameUIC" : "Avion",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342592,
            "codeCH" : 0,
            "nameUIC" : "Bailleul-Sir-Berthoult",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 342725,
            "codeCH" : 0,
            "nameUIC" : "Hersin-Coupigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 343004,
            "codeCH" : "AG",
            "nameUIC" : "Valenciennes",
            "nameCH" : "Avant Gare",
            "code" : "VS"
        },
        {
            "codeUIC" : 343004,
            "codeCH" : "BB",
            "nameUIC" : "Valenciennes",
            "nameCH" : "Poste 1",
            "code" : "VS"
        },
        {
            "codeUIC" : 343004,
            "codeCH" : "BV",
            "nameUIC" : "Valenciennes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VS"
        },
        {
            "codeUIC" : 343004,
            "codeCH" : "P2",
            "nameUIC" : "Valenciennes",
            "nameCH" : "Passerelle",
            "code" : "VS"
        },
        {
            "codeUIC" : 343004,
            "codeCH" : "SS",
            "nameUIC" : "Valenciennes",
            "nameCH" : "Garages de St-Saulve",
            "code" : "VS"
        },
        {
            "codeUIC" : 343004,
            "codeCH" : "S2",
            "nameUIC" : "Valenciennes",
            "nameCH" : "Poste 2",
            "code" : "VS"
        },
        {
            "codeUIC" : 343103,
            "codeCH" : 0,
            "nameUIC" : "St-Amand-les-Eaux",
            "nameCH" : "",
            "code" : "STA"
        },
        {
            "codeUIC" : 343160,
            "codeCH" : 0,
            "nameUIC" : "Beuvrages",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 343210,
            "codeCH" : 0,
            "nameUIC" : "Le Poirier",
            "nameCH" : "",
            "code" : "LEP"
        },
        {
            "codeUIC" : 343301,
            "codeCH" : 0,
            "nameUIC" : "Wallers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 343319,
            "codeCH" : 0,
            "nameUIC" : "Raismes (Nord)",
            "nameCH" : "",
            "code" : "RAS"
        },
        {
            "codeUIC" : 343400,
            "codeCH" : "BV",
            "nameUIC" : "Trith-St-Léger",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TSL"
        },
        {
            "codeUIC" : 343400,
            "codeCH" : "C3",
            "nameUIC" : "Trith-St-Léger",
            "nameCH" : "Carré Violet 3",
            "code" : "TSL"
        },
        {
            "codeUIC" : 343400,
            "codeCH" : "C4",
            "nameUIC" : "Trith-St-Léger",
            "nameCH" : "Carré Violet 4",
            "code" : "TSL"
        },
        {
            "codeUIC" : 343400,
            "codeCH" : 0,
            "nameUIC" : "Trith-St-Léger",
            "nameCH" : "",
            "code" : "TSL"
        },
        {
            "codeUIC" : 343418,
            "codeCH" : "S1",
            "nameUIC" : "Prouvy-Thiant",
            "nameCH" : "Carré 1",
            "code" : "PRT"
        },
        {
            "codeUIC" : 343418,
            "codeCH" : "S4",
            "nameUIC" : "Prouvy-Thiant",
            "nameCH" : "Carré 234,9",
            "code" : "PRT"
        },
        {
            "codeUIC" : 343418,
            "codeCH" : 0,
            "nameUIC" : "Prouvy-Thiant",
            "nameCH" : "",
            "code" : "PRT"
        },
        {
            "codeUIC" : 343459,
            "codeCH" : 0,
            "nameUIC" : "Denain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 343475,
            "codeCH" : "BF",
            "nameUIC" : "Lourches",
            "nameCH" : "Gros-Caillou",
            "code" : "LOU"
        },
        {
            "codeUIC" : 343475,
            "codeCH" : "BV",
            "nameUIC" : "Lourches",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LOU"
        },
        {
            "codeUIC" : 343475,
            "codeCH" : "EF",
            "nameUIC" : "Lourches",
            "nameCH" : "ITE EDF",
            "code" : "LOU"
        },
        {
            "codeUIC" : 343475,
            "codeCH" : "P1",
            "nameUIC" : "Lourches",
            "nameCH" : "Poste 1",
            "code" : "LOU"
        },
        {
            "codeUIC" : 343475,
            "codeCH" : "S1",
            "nameUIC" : "Lourches",
            "nameCH" : "Signal 1",
            "code" : "LOU"
        },
        {
            "codeUIC" : 343475,
            "codeCH" : 28,
            "nameUIC" : "Lourches",
            "nameCH" : "Signal 80128",
            "code" : "LOU"
        },
        {
            "codeUIC" : 345009,
            "codeCH" : "AM",
            "nameUIC" : "Douai",
            "nameCH" : "Accès Marchandises",
            "code" : "DOI"
        },
        {
            "codeUIC" : 345009,
            "codeCH" : "BV",
            "nameUIC" : "Douai",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DOI"
        },
        {
            "codeUIC" : 345009,
            "codeCH" : "SE",
            "nameUIC" : "Douai",
            "nameCH" : "Bifurcation de St-Éloi",
            "code" : "DOI"
        },
        {
            "codeUIC" : 345009,
            "codeCH" : "SI",
            "nameUIC" : "Douai",
            "nameCH" : "Bifurcation de Sin",
            "code" : "DOI"
        },
        {
            "codeUIC" : 345009,
            "codeCH" : "TR",
            "nameUIC" : "Douai",
            "nameCH" : "Triage",
            "code" : "DOI"
        },
        {
            "codeUIC" : 345009,
            "codeCH" : 18,
            "nameUIC" : "Douai",
            "nameCH" : "Aiguille Km 218,5",
            "code" : "DOI"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "BA",
            "nameUIC" : "Lens",
            "nameCH" : "Bifurcation d'Avion",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "BE",
            "nameUIC" : "Lens",
            "nameCH" : "Bifurcation de l'Épinette",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "BS",
            "nameUIC" : "Lens",
            "nameCH" : "Bifurcation de Sallaumines",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "BV",
            "nameUIC" : "Lens",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "C2",
            "nameUIC" : "Lens",
            "nameCH" : "Carré Violet 204",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "DT",
            "nameUIC" : "Lens",
            "nameCH" : "Dépôt",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "P3",
            "nameUIC" : "Lens",
            "nameCH" : "Poste 3",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "P4",
            "nameUIC" : "Lens",
            "nameCH" : "Poste 4",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345025,
            "codeCH" : "TR",
            "nameUIC" : "Lens",
            "nameCH" : "Triage",
            "code" : "LNS"
        },
        {
            "codeUIC" : 345033,
            "codeCH" : 0,
            "nameUIC" : "Loos-en-Gohelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345041,
            "codeCH" : 0,
            "nameUIC" : "Liévin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345108,
            "codeCH" : 0,
            "nameUIC" : "Brebières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345116,
            "codeCH" : 0,
            "nameUIC" : "Corbehem",
            "nameCH" : "",
            "code" : "COR"
        },
        {
            "codeUIC" : 345124,
            "codeCH" : "BV",
            "nameUIC" : "Pont-de-Sallaumines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 345132,
            "codeCH" : 0,
            "nameUIC" : "Coron-de-Méricourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345140,
            "codeCH" : "P1",
            "nameUIC" : "Billy-Montigny",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 345140,
            "codeCH" : "P2",
            "nameUIC" : "Billy-Montigny",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 345140,
            "codeCH" : 0,
            "nameUIC" : "Billy-Montigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345157,
            "codeCH" : 0,
            "nameUIC" : "Hénin-Beaumont",
            "nameCH" : "",
            "code" : "HBE"
        },
        {
            "codeUIC" : 345165,
            "codeCH" : 0,
            "nameUIC" : "Dourges",
            "nameCH" : "",
            "code" : "DOU"
        },
        {
            "codeUIC" : 345207,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-la-Deule",
            "nameCH" : "",
            "code" : "PDD"
        },
        {
            "codeUIC" : 345215,
            "codeCH" : 0,
            "nameUIC" : "Sallaumines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345223,
            "codeCH" : 0,
            "nameUIC" : "Leforest",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345231,
            "codeCH" : 0,
            "nameUIC" : "Loison",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345249,
            "codeCH" : 0,
            "nameUIC" : "Ostricourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345256,
            "codeCH" : "BV",
            "nameUIC" : "Libercourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LIB"
        },
        {
            "codeUIC" : 345256,
            "codeCH" : "EP",
            "nameUIC" : "Libercourt",
            "nameCH" : "ITE Logidis",
            "code" : "LIB"
        },
        {
            "codeUIC" : 345256,
            "codeCH" : 0,
            "nameUIC" : "Libercourt",
            "nameCH" : "",
            "code" : "LIB"
        },
        {
            "codeUIC" : 345264,
            "codeCH" : "BV",
            "nameUIC" : "Pont-à-Vendin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PTV"
        },
        {
            "codeUIC" : 345264,
            "codeCH" : "GA",
            "nameUIC" : "Pont-à-Vendin",
            "nameCH" : "Chamiers",
            "code" : "PTV"
        },
        {
            "codeUIC" : 345264,
            "codeCH" : "P1",
            "nameUIC" : "Pont-à-Vendin",
            "nameCH" : "Poste 1",
            "code" : "PTV"
        },
        {
            "codeUIC" : 345272,
            "codeCH" : 0,
            "nameUIC" : "Meurchin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345280,
            "codeCH" : 0,
            "nameUIC" : "Bauvin-Provin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345306,
            "codeCH" : 0,
            "nameUIC" : "Montigny-en-Ostrevent",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "AI",
            "nameUIC" : "Somain",
            "nameCH" : "Faisceau Attente Impair",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "AP",
            "nameUIC" : "Somain",
            "nameCH" : "Faisceau Attente Pair",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "AT",
            "nameUIC" : "Somain",
            "nameCH" : "Accès Triage",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "A1",
            "nameUIC" : "Somain",
            "nameCH" : "Aiguille 80061b",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "BV",
            "nameUIC" : "Somain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "DT",
            "nameUIC" : "Somain",
            "nameCH" : "Dépôt",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "EF",
            "nameUIC" : "Somain",
            "nameCH" : "ITE EDF",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "PB",
            "nameUIC" : "Somain",
            "nameCH" : "Poste B",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "P1",
            "nameUIC" : "Somain",
            "nameCH" : "Poste 1",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "P2",
            "nameUIC" : "Somain",
            "nameCH" : "Poste 2",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "P3",
            "nameUIC" : "Somain",
            "nameCH" : "Poste 3",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "RC",
            "nameUIC" : "Somain",
            "nameCH" : "Faisceau Réception",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "TR",
            "nameUIC" : "Somain",
            "nameCH" : "Triage",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : "1P",
            "nameUIC" : "Somain",
            "nameCH" : "Poste 1-Triage",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345314,
            "codeCH" : 23,
            "nameUIC" : "Somain",
            "nameCH" : "Bifurcation de Lourches",
            "code" : "SOM"
        },
        {
            "codeUIC" : 345363,
            "codeCH" : 0,
            "nameUIC" : "Épehy",
            "nameCH" : "",
            "code" : "EPY"
        },
        {
            "codeUIC" : 345389,
            "codeCH" : "BV",
            "nameUIC" : "Bouchain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BAK"
        },
        {
            "codeUIC" : 345389,
            "codeCH" : "CH",
            "nameUIC" : "Bouchain",
            "nameCH" : "ITE Peugeot",
            "code" : "BAK"
        },
        {
            "codeUIC" : 345397,
            "codeCH" : 0,
            "nameUIC" : "Iwuy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345405,
            "codeCH" : 0,
            "nameUIC" : "Sin-le-Noble",
            "nameCH" : "",
            "code" : "SIN"
        },
        {
            "codeUIC" : 345413,
            "codeCH" : 0,
            "nameUIC" : "Cantin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345421,
            "codeCH" : 0,
            "nameUIC" : "Arleux",
            "nameCH" : "",
            "code" : "ARX"
        },
        {
            "codeUIC" : 345439,
            "codeCH" : 0,
            "nameUIC" : "Brunémont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345447,
            "codeCH" : 0,
            "nameUIC" : "Aubigny-au-Bac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345454,
            "codeCH" : 0,
            "nameUIC" : "Oisy-le-Verger",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345462,
            "codeCH" : 0,
            "nameUIC" : "Fressies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345470,
            "codeCH" : 0,
            "nameUIC" : "Sancourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345496,
            "codeCH" : 0,
            "nameUIC" : "Escaudoeuvres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345504,
            "codeCH" : 0,
            "nameUIC" : "Aniche",
            "nameCH" : "",
            "code" : "ANI"
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "BN",
            "nameUIC" : "Cambrai",
            "nameCH" : "Bifurcation de Cambrai Nord",
            "code" : ""
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "BS",
            "nameUIC" : "Cambrai Ville",
            "nameCH" : "Bifurcation Sud",
            "code" : ""
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "BV",
            "nameUIC" : "Cambrai",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "GA",
            "nameUIC" : "Cambrai",
            "nameCH" : "Garages",
            "code" : ""
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "JE",
            "nameUIC" : "Cambrai",
            "nameCH" : "Jonction Épehy",
            "code" : ""
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "P1",
            "nameUIC" : "Cambrai",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "P2",
            "nameUIC" : "Cambrai",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 345520,
            "codeCH" : "P3",
            "nameUIC" : "Cambrai",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 345538,
            "codeCH" : "BV",
            "nameUIC" : "Cambrai-Annexe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CBA"
        },
        {
            "codeUIC" : 345546,
            "codeCH" : "BF",
            "nameUIC" : "Maurois",
            "nameCH" : "Bifurcation Maurois",
            "code" : ""
        },
        {
            "codeUIC" : 345546,
            "codeCH" : 0,
            "nameUIC" : "Maurois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345553,
            "codeCH" : 0,
            "nameUIC" : "Bertry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345561,
            "codeCH" : 0,
            "nameUIC" : "Caudry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345579,
            "codeCH" : 0,
            "nameUIC" : "Fontaine-au-Pire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345587,
            "codeCH" : 0,
            "nameUIC" : "Cattenières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 345595,
            "codeCH" : 0,
            "nameUIC" : "Wambaix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 352047,
            "codeCH" : 0,
            "nameUIC" : "Paul-Émile-Victor",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 352054,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 408,7",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 352088,
            "codeCH" : 0,
            "nameUIC" : "Labège-Innopole Poste",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 353573,
            "codeCH" : 0,
            "nameUIC" : "Le Toec",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 353581,
            "codeCH" : 0,
            "nameUIC" : "Les Ramassiers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 353599,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-du-Touch",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 354027,
            "codeCH" : "BF",
            "nameUIC" : "Poste 66 Crisenoy",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 354027,
            "codeCH" : "PR",
            "nameUIC" : "Poste 66 Crisenoy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 354514,
            "codeCH" : "BS",
            "nameUIC" : "Poste 24 Croisilles",
            "nameCH" : "Bifurcation Sud",
            "code" : ""
        },
        {
            "codeUIC" : 354514,
            "codeCH" : "PR",
            "nameUIC" : "Poste 24 Croisilles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 354522,
            "codeCH" : "BN",
            "nameUIC" : "Poste 25 Roeux",
            "nameCH" : "Bifurcation Nord",
            "code" : ""
        },
        {
            "codeUIC" : 354522,
            "codeCH" : "PR",
            "nameUIC" : "Poste 25 Roeux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 354548,
            "codeCH" : 0,
            "nameUIC" : "Poste 45 Quilleval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 354555,
            "codeCH" : "BE",
            "nameUIC" : "Poste 29 Sainghin",
            "nameCH" : "Bifurcation de Péronne",
            "code" : ""
        },
        {
            "codeUIC" : 354555,
            "codeCH" : "BN",
            "nameUIC" : "Poste 29 Sainghin",
            "nameCH" : "Bifurcation de Fretin",
            "code" : ""
        },
        {
            "codeUIC" : 354555,
            "codeCH" : "BS",
            "nameUIC" : "Poste 29 Sainghin",
            "nameCH" : "Bifurcation de Sainghin",
            "code" : ""
        },
        {
            "codeUIC" : 354589,
            "codeCH" : 0,
            "nameUIC" : "Pas-Enchantés",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 354597,
            "codeCH" : 0,
            "nameUIC" : "Frêne-Rond",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 357178,
            "codeCH" : 0,
            "nameUIC" : "ITE Pagny Technoport",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 359067,
            "codeCH" : "PM",
            "nameUIC" : "Dourges-Delta",
            "nameCH" : "Plateforme Multimodale",
            "code" : ""
        },
        {
            "codeUIC" : 366922,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-Bel-air",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381004,
            "codeCH" : "FC",
            "nameUIC" : "Paris-Batignolles",
            "nameCH" : "Faisceau Ceinture",
            "code" : "PB"
        },
        {
            "codeUIC" : 381004,
            "codeCH" : "P3",
            "nameUIC" : "Paris-Batignolles",
            "nameCH" : "Poste 3",
            "code" : "PB"
        },
        {
            "codeUIC" : 381004,
            "codeCH" : "P5",
            "nameUIC" : "Paris-Batignolles",
            "nameCH" : "Poste 5",
            "code" : "PB"
        },
        {
            "codeUIC" : 381004,
            "codeCH" : "QN",
            "nameUIC" : "Paris-Batignolles",
            "nameCH" : "Quai Navette Pont-Cardinet",
            "code" : "PB"
        },
        {
            "codeUIC" : 381004,
            "codeCH" : "RA",
            "nameUIC" : "Paris-Batignolles",
            "nameCH" : "Messageries",
            "code" : "PB"
        },
        {
            "codeUIC" : 381004,
            "codeCH" : "RE",
            "nameUIC" : "Paris-Batignolles",
            "nameCH" : "Remblai",
            "code" : "PB"
        },
        {
            "codeUIC" : 381004,
            "codeCH" : "TC",
            "nameUIC" : "Paris-Batignolles",
            "nameCH" : "Accès TCT",
            "code" : "PB"
        },
        {
            "codeUIC" : 381012,
            "codeCH" : 0,
            "nameUIC" : "Péreire-Levallois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381020,
            "codeCH" : 0,
            "nameUIC" : "Neuilly-Porte-Maillot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381038,
            "codeCH" : 0,
            "nameUIC" : "Avenue-Foch",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381046,
            "codeCH" : "TI",
            "nameUIC" : "Avenue-Henri-Martin",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 381046,
            "codeCH" : 0,
            "nameUIC" : "Avenue-Henri-Martin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381079,
            "codeCH" : "BV",
            "nameUIC" : "Bois-Colombes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381079,
            "codeCH" : "GA",
            "nameUIC" : "Bois-Colombes",
            "nameCH" : "Garages",
            "code" : ""
        },
        {
            "codeUIC" : 381079,
            "codeCH" : "TI",
            "nameUIC" : "Bois-Colombes",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 381087,
            "codeCH" : 0,
            "nameUIC" : "Colombes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381095,
            "codeCH" : 0,
            "nameUIC" : "Le Stade",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : "BV",
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : "DL",
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "Dépôt de Levallois",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : "ES",
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "Entrée/Sortie Côté PSL",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : "GZ",
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "Garage de Rames",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : "RA",
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "Relais Auomatique",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : "SE",
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "Entrée/Sortie Côté Province",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : "TI",
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "Voie de Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 381111,
            "codeCH" : 0,
            "nameUIC" : "Pont-Cardinet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381129,
            "codeCH" : 0,
            "nameUIC" : "Clichy-Levallois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381137,
            "codeCH" : "AG",
            "nameUIC" : "Asnières-sur-Seine",
            "nameCH" : "Liaison GR2/GR3",
            "code" : ""
        },
        {
            "codeUIC" : 381137,
            "codeCH" : "BV",
            "nameUIC" : "Asnières-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381137,
            "codeCH" : "P1",
            "nameUIC" : "Asnières-sur-Seine",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 381137,
            "codeCH" : "P3",
            "nameUIC" : "Asnières-sur-Seine",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 381145,
            "codeCH" : 0,
            "nameUIC" : "Osny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381152,
            "codeCH" : 0,
            "nameUIC" : "Boissy-l'Aillerie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381160,
            "codeCH" : 0,
            "nameUIC" : "Montgeroult-Courcelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381178,
            "codeCH" : 0,
            "nameUIC" : "Us",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381186,
            "codeCH" : 0,
            "nameUIC" : "Santeuil-Le Perchay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381194,
            "codeCH" : "BV",
            "nameUIC" : "Chars",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHR"
        },
        {
            "codeUIC" : 381202,
            "codeCH" : 0,
            "nameUIC" : "La Villetertre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381210,
            "codeCH" : 0,
            "nameUIC" : "Liancourt-St-Pierre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381228,
            "codeCH" : "BV",
            "nameUIC" : "Chaumont-en-Vexin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381236,
            "codeCH" : 0,
            "nameUIC" : "Trie-Château",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381244,
            "codeCH" : 0,
            "nameUIC" : "Gisors",
            "nameCH" : "",
            "code" : "GIS"
        },
        {
            "codeUIC" : 381251,
            "codeCH" : 0,
            "nameUIC" : "Éragny-Bazincourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381269,
            "codeCH" : 0,
            "nameUIC" : "Sérifontaine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381277,
            "codeCH" : 0,
            "nameUIC" : "Amécourt-Talmontier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381285,
            "codeCH" : 0,
            "nameUIC" : "Neufmarché",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381293,
            "codeCH" : 0,
            "nameUIC" : "Gournay-Ferrières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381418,
            "codeCH" : "BV",
            "nameUIC" : "Éragny-Neuville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ERN"
        },
        {
            "codeUIC" : 381418,
            "codeCH" : "CF",
            "nameUIC" : "Éragny-Neuville",
            "nameCH" : "Bifurcation de Conflans-Ste-Honorine",
            "code" : "ERN"
        },
        {
            "codeUIC" : 381418,
            "codeCH" : "ER",
            "nameUIC" : "Éragny-Neuville",
            "nameCH" : "ITE AVN",
            "code" : "ERN"
        },
        {
            "codeUIC" : 381418,
            "codeCH" : "NO",
            "nameUIC" : "Éragny-Neuville",
            "nameCH" : "Bifurcation du Nord",
            "code" : "ERN"
        },
        {
            "codeUIC" : 381426,
            "codeCH" : 0,
            "nameUIC" : "St-Ouen-l'Aumône-Église",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381459,
            "codeCH" : "BV",
            "nameUIC" : "Conflans-Fin-d'Oise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CFO"
        },
        {
            "codeUIC" : 381459,
            "codeCH" : "EB",
            "nameUIC" : "Conflans-Fin-d'Oise",
            "nameCH" : "ITE Bonna Scirc",
            "code" : "CFO"
        },
        {
            "codeUIC" : 381459,
            "codeCH" : 0,
            "nameUIC" : "Conflans-Fin-d'Oise",
            "nameCH" : "",
            "code" : "CFO"
        },
        {
            "codeUIC" : 381475,
            "codeCH" : 0,
            "nameUIC" : "Chanteloup-les-Vignes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381483,
            "codeCH" : 0,
            "nameUIC" : "Maurecourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381491,
            "codeCH" : 0,
            "nameUIC" : "Andrésy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "BI",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Bifurcation d'Argenteuil",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "BR",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Bifurcation Côté Rouen",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "BV",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "B3",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Sortie Voie 3",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "DG",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Tiroir DG",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "EC",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "ITE Aiguille Est",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "FC",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "ITE Ciments Français",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "JB",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Jonction IPCS Km 80,8",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "JI",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Jonction IPCS Km 69,9",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "JS",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Jonction IPCS Km 59,4",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "LI",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Liaison Voies Centrale/V1 et V2",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "MS",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Marchandises",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "OC",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "ITE Aiguille Ouest",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "PA",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Entrée/Sortie Vois Centrale Côté PARIS",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "PI",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Raccordement  des Piquettes",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "PR",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Entrée/Sortie Voie Centrale Côté Province",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "P2",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Poste Satellite",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381509,
            "codeCH" : "V4",
            "nameUIC" : "Mantes-la-Jolie",
            "nameCH" : "Sortie Voie 4",
            "code" : "MTE"
        },
        {
            "codeUIC" : 381558,
            "codeCH" : 0,
            "nameUIC" : "Juziers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381566,
            "codeCH" : 0,
            "nameUIC" : "Gargenville",
            "nameCH" : "",
            "code" : "GGV"
        },
        {
            "codeUIC" : 381574,
            "codeCH" : 0,
            "nameUIC" : "Issou-Porcheville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381582,
            "codeCH" : "AF",
            "nameUIC" : "Limay",
            "nameCH" : "ITE EDF",
            "code" : ""
        },
        {
            "codeUIC" : 381582,
            "codeCH" : "BV",
            "nameUIC" : "Limay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381582,
            "codeCH" : "EF",
            "nameUIC" : "Limay",
            "nameCH" : "ITE EDF (interieur ITE)",
            "code" : ""
        },
        {
            "codeUIC" : 381582,
            "codeCH" : "PP",
            "nameUIC" : "Limay",
            "nameCH" : "Port-de-Paris",
            "code" : ""
        },
        {
            "codeUIC" : 381590,
            "codeCH" : 0,
            "nameUIC" : "Mantes-Station",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381616,
            "codeCH" : 0,
            "nameUIC" : "Ménerville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381624,
            "codeCH" : "BV",
            "nameUIC" : "Bréval",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381632,
            "codeCH" : 0,
            "nameUIC" : "Gilles-Guainville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381657,
            "codeCH" : "BN",
            "nameUIC" : "Achères-Ville",
            "nameCH" : "Bifurcation de Neuville",
            "code" : ""
        },
        {
            "codeUIC" : 381657,
            "codeCH" : "BV",
            "nameUIC" : "Achères-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381657,
            "codeCH" : "EI",
            "nameUIC" : "Achères-Ville",
            "nameCH" : "Entrée/Sortie Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 381715,
            "codeCH" : 0,
            "nameUIC" : "Mareil-sur-Mauldre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381723,
            "codeCH" : "BV",
            "nameUIC" : "Maule",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381731,
            "codeCH" : 0,
            "nameUIC" : "Nézel-Aulnay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381798,
            "codeCH" : 0,
            "nameUIC" : "Le Val-d'Argenteuil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381806,
            "codeCH" : 0,
            "nameUIC" : "Triel-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381814,
            "codeCH" : 0,
            "nameUIC" : "Vaux-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381822,
            "codeCH" : 0,
            "nameUIC" : "Thun-le-Paradis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381830,
            "codeCH" : "BV",
            "nameUIC" : "Meulan-Hardricourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381848,
            "codeCH" : "BV",
            "nameUIC" : "Argenteuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381848,
            "codeCH" : 11,
            "nameUIC" : "Argenteuil",
            "nameCH" : "Carré 643",
            "code" : ""
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "AL",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "ITE Lambert",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "EA",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "ITE AIVAP",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "GB",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "Dépôt du Val-Notre-Dame",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "GC",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "Chantier Argenteuil-Faisceau",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "LO",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "Local",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "P2",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "Bifurcation de Bourceron (P2)",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "P3",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "Poste 3",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "P5",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "Poste 5",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381855,
            "codeCH" : "RB",
            "nameUIC" : "Argenteuil-Triage",
            "nameCH" : "Voie de Rebroussement",
            "code" : "ARG"
        },
        {
            "codeUIC" : 381863,
            "codeCH" : 0,
            "nameUIC" : "Cormeilles-en-Parisis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381871,
            "codeCH" : 0,
            "nameUIC" : "La Frette-Montigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381889,
            "codeCH" : 0,
            "nameUIC" : "Herblay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 381897,
            "codeCH" : "BV",
            "nameUIC" : "Conflans-Ste-Honorine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 381897,
            "codeCH" : "CH",
            "nameUIC" : "Conflans-Ste-Honorine",
            "nameCH" : "Chennevières",
            "code" : ""
        },
        {
            "codeUIC" : 381897,
            "codeCH" : "FO",
            "nameUIC" : "Conflans-Ste-Honorine",
            "nameCH" : "Bifurcation de Fin-d'Oise",
            "code" : ""
        },
        {
            "codeUIC" : 381897,
            "codeCH" : "GI",
            "nameUIC" : "Conflans-Ste-Honorine",
            "nameCH" : "Bifurcation du Gibet",
            "code" : ""
        },
        {
            "codeUIC" : 381905,
            "codeCH" : 0,
            "nameUIC" : "Cergy-Préfecture",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382002,
            "codeCH" : "BC",
            "nameUIC" : "Bécon-les-Bruyères",
            "nameCH" : "Bifurcation de Courbevoie",
            "code" : "BEC"
        },
        {
            "codeUIC" : 382002,
            "codeCH" : "BV",
            "nameUIC" : "Bécon-les-Bruyères",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BEC"
        },
        {
            "codeUIC" : 382002,
            "codeCH" : "ES",
            "nameUIC" : "Bécon-les-Bruyères",
            "nameCH" : "Entrée/Sortie Marchandises",
            "code" : "BEC"
        },
        {
            "codeUIC" : 382002,
            "codeCH" : "MS",
            "nameUIC" : "Bécon-les-Bruyères",
            "nameCH" : "Marchandises",
            "code" : "BEC"
        },
        {
            "codeUIC" : 382002,
            "codeCH" : "VA",
            "nameUIC" : "Bécon-les-Bruyères",
            "nameCH" : "Bifurcation des Valllées",
            "code" : "BEC"
        },
        {
            "codeUIC" : 382002,
            "codeCH" : "VM",
            "nameUIC" : "Bécon-les-Bruyères",
            "nameCH" : "Voie de Manoeuvre",
            "code" : "BEC"
        },
        {
            "codeUIC" : 382200,
            "codeCH" : 0,
            "nameUIC" : "Courbevoie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382218,
            "codeCH" : "BD",
            "nameUIC" : "La Défense",
            "nameCH" : "Bifurcation de La Défense",
            "code" : ""
        },
        {
            "codeUIC" : 382218,
            "codeCH" : "BP",
            "nameUIC" : "La Défense",
            "nameCH" : "Bifurcation de Puteaux",
            "code" : ""
        },
        {
            "codeUIC" : 382218,
            "codeCH" : "BV",
            "nameUIC" : "La Défense",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 382218,
            "codeCH" : "G2",
            "nameUIC" : "La Défense",
            "nameCH" : "Liaison Voies 1G2/2G2",
            "code" : ""
        },
        {
            "codeUIC" : 382218,
            "codeCH" : "TR",
            "nameUIC" : "La Défense",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 382259,
            "codeCH" : "BV",
            "nameUIC" : "Garches-Marnes-la-Coquette",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 382267,
            "codeCH" : 0,
            "nameUIC" : "Vaucresson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382333,
            "codeCH" : 0,
            "nameUIC" : "Chaville-Rive-Droite",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382341,
            "codeCH" : "IP",
            "nameUIC" : "Sèvres-Ville-d'Avray",
            "nameCH" : "Entrée/Sortie IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 382341,
            "codeCH" : 0,
            "nameUIC" : "Sèvres-Ville-d'Avray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382358,
            "codeCH" : "BP",
            "nameUIC" : "St-Cloud",
            "nameCH" : "Bifurcation du Parc",
            "code" : ""
        },
        {
            "codeUIC" : 382358,
            "codeCH" : "BV",
            "nameUIC" : "St-Cloud",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 382358,
            "codeCH" : "TI",
            "nameUIC" : "St-Cloud",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 382366,
            "codeCH" : 0,
            "nameUIC" : "Le Val-d'Or",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382374,
            "codeCH" : 0,
            "nameUIC" : "Suresnes-Mont-Valérien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382382,
            "codeCH" : "BV",
            "nameUIC" : "Puteaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 382432,
            "codeCH" : 0,
            "nameUIC" : "La Celle-St-Cloud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382440,
            "codeCH" : 0,
            "nameUIC" : "Bougival",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382457,
            "codeCH" : 0,
            "nameUIC" : "Louveciennes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382465,
            "codeCH" : "BV",
            "nameUIC" : "Marly-le-Roi",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 382473,
            "codeCH" : 0,
            "nameUIC" : "L'Étang-la-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382481,
            "codeCH" : "BG",
            "nameUIC" : "St-Nom-la-Bretêche-Forêt-de-Ma",
            "nameCH" : "Bifurcation St-Nom GCO",
            "code" : ""
        },
        {
            "codeUIC" : 382481,
            "codeCH" : "BV",
            "nameUIC" : "St-Nom-la-Bretêche-Forêt-de-Ma",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 382481,
            "codeCH" : "B2",
            "nameUIC" : "St-Nom-la-Bretêche-Forêt-de-Ma",
            "nameCH" : "Bifurcation St-Nom GRII",
            "code" : ""
        },
        {
            "codeUIC" : 382481,
            "codeCH" : 0,
            "nameUIC" : "St-Nom-la-Bretêche-Forêt-de-Ma",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382499,
            "codeCH" : 0,
            "nameUIC" : "Cergy-St-Christophe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382655,
            "codeCH" : "TI",
            "nameUIC" : "Cergy-le-Haut",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382655,
            "codeCH" : 0,
            "nameUIC" : "Cergy-le-Haut",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382804,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-Grande-Ceinture",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382812,
            "codeCH" : 0,
            "nameUIC" : "Mareil-Marly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382861,
            "codeCH" : 0,
            "nameUIC" : "Versailles-Rive-Droite",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382879,
            "codeCH" : 0,
            "nameUIC" : "Montreuil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 382887,
            "codeCH" : "BI",
            "nameUIC" : "Viroflay-Rive-Droite",
            "nameCH" : "Bifurcation de Viroflay",
            "code" : ""
        },
        {
            "codeUIC" : 382887,
            "codeCH" : "BV",
            "nameUIC" : "Viroflay-Rive-Droite",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 382887,
            "codeCH" : "IP",
            "nameUIC" : "Viroflay-Rive-Droite",
            "nameCH" : "Entrée/Sortie IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 382887,
            "codeCH" : "SE",
            "nameUIC" : "Viroflay-Rive-Droite",
            "nameCH" : "Sortie IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 383109,
            "codeCH" : "EP",
            "nameUIC" : "ITE Carnaud",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 383109,
            "codeCH" : "PO",
            "nameUIC" : "ITE Carnaud",
            "nameCH" : "Entrée/Sortie ITE",
            "code" : ""
        },
        {
            "codeUIC" : 383125,
            "codeCH" : 0,
            "nameUIC" : "Bif de Beautot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 383281,
            "codeCH" : 0,
            "nameUIC" : "Massy-Verrières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 383539,
            "codeCH" : "C5",
            "nameUIC" : "Cormelles",
            "nameCH" : "Point de Manoeuvre C5",
            "code" : ""
        },
        {
            "codeUIC" : 383539,
            "codeCH" : 0,
            "nameUIC" : "Cormelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 383562,
            "codeCH" : 0,
            "nameUIC" : "ITE Ayrault",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 383687,
            "codeCH" : 0,
            "nameUIC" : "ITE Gouraud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 383729,
            "codeCH" : 0,
            "nameUIC" : "Petit-Couronne -Voies de quais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 383919,
            "codeCH" : 0,
            "nameUIC" : "Km 299,400",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 383927,
            "codeCH" : "J1",
            "nameUIC" : "Bif de Darnétal",
            "nameCH" : "Jonction 1",
            "code" : ""
        },
        {
            "codeUIC" : 383927,
            "codeCH" : "R1",
            "nameUIC" : "Bif de Darnétal",
            "nameCH" : "Raccordement 1",
            "code" : ""
        },
        {
            "codeUIC" : 383927,
            "codeCH" : "R2",
            "nameUIC" : "Bif de Darnétal",
            "nameCH" : "Raccordement 2",
            "code" : ""
        },
        {
            "codeUIC" : 383927,
            "codeCH" : "R3",
            "nameUIC" : "Bif de Darnétal",
            "nameCH" : "Raccordement 3",
            "code" : ""
        },
        {
            "codeUIC" : 383927,
            "codeCH" : 0,
            "nameUIC" : "Bif de Darnétal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 384008,
            "codeCH" : "PL",
            "nameUIC" : "Paris-St-Lazare",
            "nameCH" : "Pont Legendre",
            "code" : ""
        },
        {
            "codeUIC" : 384008,
            "codeCH" : 0,
            "nameUIC" : "Paris-St-Lazare",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 384040,
            "codeCH" : 0,
            "nameUIC" : "ITE Heuliez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : "AT",
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Atelier Matériel La Folie",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : "BB",
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Bifurcation de Bezons",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : "BV",
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : "GZ",
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Garage de Rames",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : "SP",
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : "V3",
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Garage Voie 3",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : 23,
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Carré Violet 123",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : 39,
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Carré Violet 39",
            "code" : ""
        },
        {
            "codeUIC" : 386003,
            "codeCH" : 98,
            "nameUIC" : "La Garenne-Colombes",
            "nameCH" : "Carré Violet 198",
            "code" : ""
        },
        {
            "codeUIC" : 386011,
            "codeCH" : "BF",
            "nameUIC" : "La Folie-Nanterre",
            "nameCH" : "Bifurcation de la Folie",
            "code" : "LFN"
        },
        {
            "codeUIC" : 386011,
            "codeCH" : "LO",
            "nameUIC" : "La Folie-Nanterre",
            "nameCH" : "Local",
            "code" : "LFN"
        },
        {
            "codeUIC" : 386011,
            "codeCH" : "P3",
            "nameUIC" : "La Folie-Nanterre",
            "nameCH" : "Poste 3",
            "code" : "LFN"
        },
        {
            "codeUIC" : 386037,
            "codeCH" : 0,
            "nameUIC" : "ITE CCI des Deux-Sèvres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386052,
            "codeCH" : 0,
            "nameUIC" : "Achères-Grand-Cormier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386300,
            "codeCH" : "BV",
            "nameUIC" : "Les Vallées",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 386318,
            "codeCH" : "BV",
            "nameUIC" : "Nanterre-Université",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 386318,
            "codeCH" : "CV",
            "nameUIC" : "Nanterre-Université",
            "nameCH" : "Carré Violet 83",
            "code" : ""
        },
        {
            "codeUIC" : 386318,
            "codeCH" : "ES",
            "nameUIC" : "Nanterre-Université",
            "nameCH" : "Entrée/Sortie RATP",
            "code" : ""
        },
        {
            "codeUIC" : 386318,
            "codeCH" : "PT",
            "nameUIC" : "Nanterre-Université",
            "nameCH" : "Point RATP",
            "code" : ""
        },
        {
            "codeUIC" : 386318,
            "codeCH" : "SF",
            "nameUIC" : "Nanterre-Université",
            "nameCH" : "Entrée/Sortie SNCF",
            "code" : ""
        },
        {
            "codeUIC" : 386318,
            "codeCH" : 81,
            "nameUIC" : "Nanterre-Université",
            "nameCH" : "Carré 81",
            "code" : ""
        },
        {
            "codeUIC" : 386409,
            "codeCH" : 0,
            "nameUIC" : "Houilles-Carrières-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386417,
            "codeCH" : "AG",
            "nameUIC" : "Sartrouville",
            "nameCH" : "Entrée/Sortie Groupes 3 et 5",
            "code" : ""
        },
        {
            "codeUIC" : 386417,
            "codeCH" : "BN",
            "nameUIC" : "Sartrouville",
            "nameCH" : "Bifurcation de Nanterre",
            "code" : ""
        },
        {
            "codeUIC" : 386417,
            "codeCH" : "BV",
            "nameUIC" : "Sartrouville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 386417,
            "codeCH" : "GR",
            "nameUIC" : "Sartrouville",
            "nameCH" : "Garage",
            "code" : ""
        },
        {
            "codeUIC" : 386417,
            "codeCH" : "LR",
            "nameUIC" : "Sartrouville",
            "nameCH" : "Limite RATP/SNCF",
            "code" : ""
        },
        {
            "codeUIC" : 386417,
            "codeCH" : "SP",
            "nameUIC" : "Sartrouville",
            "nameCH" : "Entrée/sortie Côté Maisons-Laffite",
            "code" : ""
        },
        {
            "codeUIC" : 386425,
            "codeCH" : "BV",
            "nameUIC" : "Maisons-Laffitte",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 386425,
            "codeCH" : "1B",
            "nameUIC" : "Maisons-Laffitte",
            "nameCH" : "Entrée/Sortie Côté Achères",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "AJ",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Traversée Voies 1A/2J",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "AM",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Bifurcation des Ambassadeurs",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "BD",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Bifurcation de Dieppe",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "CO",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Grand-Cormier",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "DP",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Dépôt Électrique/Diesel",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "EI",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré 29",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "ES",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Bifurcation d'Achères-Ville",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "FE",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Faisceau Escale",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "FR",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "GV",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Garage Matériel Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "JT",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Entrée Voie 2J/Triage",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "MI",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Garage MI",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "PA",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Poste A",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "PC",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Poste C",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "P2",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "P3",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "QN",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Quai Navette",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "SD",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Entrée Voie SAS",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "SS",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "SAS",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "S2",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Liaison Voies SAS/2D",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "TR",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : "1B",
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Jonction Voies V1/V1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : 15,
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré Violet 15",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : 17,
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré Violet 17",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : 19,
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré Violet 19",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : 33,
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré Violet 33",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : 44,
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré 44",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : 45,
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré 45",
            "code" : ""
        },
        {
            "codeUIC" : 386466,
            "codeCH" : 48,
            "nameUIC" : "Achères-Triage",
            "nameCH" : "Carré Violet 48",
            "code" : ""
        },
        {
            "codeUIC" : 386540,
            "codeCH" : 0,
            "nameUIC" : "Munster-Badischhof",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386565,
            "codeCH" : 0,
            "nameUIC" : "Poissy-Grande-Ceinture",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386573,
            "codeCH" : "BV",
            "nameUIC" : "Poissy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PSY"
        },
        {
            "codeUIC" : 386573,
            "codeCH" : "ES",
            "nameUIC" : "Poissy",
            "nameCH" : "ITE Talbot Accès Est",
            "code" : "PSY"
        },
        {
            "codeUIC" : 386573,
            "codeCH" : "FS",
            "nameUIC" : "Poissy",
            "nameCH" : "Faisceau TALBOT",
            "code" : "PSY"
        },
        {
            "codeUIC" : 386573,
            "codeCH" : "OS",
            "nameUIC" : "Poissy",
            "nameCH" : "ITE Talbot Accès Ouest",
            "code" : "PSY"
        },
        {
            "codeUIC" : 386573,
            "codeCH" : "QS",
            "nameUIC" : "Poissy",
            "nameCH" : "Quai Talbot",
            "code" : "PSY"
        },
        {
            "codeUIC" : 386631,
            "codeCH" : "HA",
            "nameUIC" : "Medan",
            "nameCH" : "Halte",
            "code" : ""
        },
        {
            "codeUIC" : 386649,
            "codeCH" : 0,
            "nameUIC" : "Villennes-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386656,
            "codeCH" : "AF",
            "nameUIC" : "Vernouillet-Verneuil",
            "nameCH" : "Aiguille ITE FIBRO-CIMENTS",
            "code" : "VET"
        },
        {
            "codeUIC" : 386656,
            "codeCH" : "BV",
            "nameUIC" : "Vernouillet-Verneuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VET"
        },
        {
            "codeUIC" : 386656,
            "codeCH" : "EF",
            "nameUIC" : "Vernouillet-Verneuil",
            "nameCH" : "ITE Fibro-Ciment",
            "code" : "VET"
        },
        {
            "codeUIC" : 386656,
            "codeCH" : "ET",
            "nameUIC" : "Vernouillet-Verneuil",
            "nameCH" : "Triage Accès Est",
            "code" : "VET"
        },
        {
            "codeUIC" : 386656,
            "codeCH" : "OT",
            "nameUIC" : "Vernouillet-Verneuil",
            "nameCH" : "Triage Accès Ouest",
            "code" : "VET"
        },
        {
            "codeUIC" : 386656,
            "codeCH" : "TR",
            "nameUIC" : "Vernouillet-Verneuil",
            "nameCH" : "Triage",
            "code" : "VET"
        },
        {
            "codeUIC" : 386664,
            "codeCH" : 0,
            "nameUIC" : "Les Clairières-de-Verneuil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "AG",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "ITE VBMDG",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "AI",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "Aiguille 83/84",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "BV",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "ED",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "ITE VBMDG (intérieur ITE)",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "ER",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "RNUR Accès Est",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "FR",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "Faisceau RNUR",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "GR",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "Garage GR",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "GS",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "Garage GS",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "OR",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "RNUR Accès Ouest",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386680,
            "codeCH" : "TI",
            "nameUIC" : "Les Mureaux",
            "nameCH" : "Tiroir",
            "code" : "LMX"
        },
        {
            "codeUIC" : 386730,
            "codeCH" : 0,
            "nameUIC" : "Aubergenville-Élisabethville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 386763,
            "codeCH" : "BV",
            "nameUIC" : "Épône-Mézières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 386763,
            "codeCH" : "ET",
            "nameUIC" : "Épône-Mézières",
            "nameCH" : "Bifurcation des Étumières",
            "code" : ""
        },
        {
            "codeUIC" : 386763,
            "codeCH" : "GR",
            "nameUIC" : "Épône-Mézières",
            "nameCH" : "Bifurcation des Graviers",
            "code" : ""
        },
        {
            "codeUIC" : 386763,
            "codeCH" : "LI",
            "nameUIC" : "Épône-Mézières",
            "nameCH" : "Jonction Voie 1G/Voie 2G",
            "code" : ""
        },
        {
            "codeUIC" : 386763,
            "codeCH" : 67,
            "nameUIC" : "Épône-Mézières",
            "nameCH" : "Carré Violet 167",
            "code" : ""
        },
        {
            "codeUIC" : 387001,
            "codeCH" : "BV",
            "nameUIC" : "Évreux-Embranchement",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EVE"
        },
        {
            "codeUIC" : 387001,
            "codeCH" : "CS",
            "nameUIC" : "Évreux-Embranchement",
            "nameCH" : "CONC Jonction IPCS",
            "code" : "EVE"
        },
        {
            "codeUIC" : 387001,
            "codeCH" : "IS",
            "nameUIC" : "Évreux-Embranchement",
            "nameCH" : "Jonction IPCS Km 106,5",
            "code" : "EVE"
        },
        {
            "codeUIC" : 387001,
            "codeCH" : "JS",
            "nameUIC" : "Évreux-Embranchement",
            "nameCH" : "Jonction IPCS Km 107,9",
            "code" : "EVE"
        },
        {
            "codeUIC" : 387001,
            "codeCH" : "MA",
            "nameUIC" : "Évreux-Embranchement",
            "nameCH" : "Mart Jonction IPCS",
            "code" : "EVE"
        },
        {
            "codeUIC" : 387001,
            "codeCH" : 2,
            "nameUIC" : "Évreux-Embranchement",
            "nameCH" : "Carré 102",
            "code" : "EVE"
        },
        {
            "codeUIC" : 387092,
            "codeCH" : 0,
            "nameUIC" : "Bueil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 387126,
            "codeCH" : 0,
            "nameUIC" : "St-Aubin-du-Vieil-Évreux",
            "nameCH" : "",
            "code" : "SVE"
        },
        {
            "codeUIC" : 387142,
            "codeCH" : 0,
            "nameUIC" : "La Bonneville-sur-Iton",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 387159,
            "codeCH" : "BV",
            "nameUIC" : "Conches",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 387175,
            "codeCH" : 0,
            "nameUIC" : "Romilly-la-Puthenaye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 387183,
            "codeCH" : 0,
            "nameUIC" : "Beaumont-le-Roger",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 387209,
            "codeCH" : "AG",
            "nameUIC" : "Prey",
            "nameCH" : "Aiguille 32",
            "code" : ""
        },
        {
            "codeUIC" : 387209,
            "codeCH" : "EP",
            "nameUIC" : "Prey",
            "nameCH" : "ITE Coopérative Garnay",
            "code" : ""
        },
        {
            "codeUIC" : 387225,
            "codeCH" : 0,
            "nameUIC" : "Damville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 387233,
            "codeCH" : "EP",
            "nameUIC" : "ITE UCASEN",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 387241,
            "codeCH" : "EP",
            "nameUIC" : "Breteuil",
            "nameCH" : "ITE ITE",
            "code" : "BTU"
        },
        {
            "codeUIC" : 387308,
            "codeCH" : "EP",
            "nameUIC" : "St-André-de-l'Eure",
            "nameCH" : "ITE Coopérative Garnay",
            "code" : "SAE"
        },
        {
            "codeUIC" : 391003,
            "codeCH" : "AV",
            "nameUIC" : "Paris-Montparnasse",
            "nameCH" : "Aiguille Accès Vaugirard",
            "code" : ""
        },
        {
            "codeUIC" : 391003,
            "codeCH" : "BV",
            "nameUIC" : "Paris-Montparnasse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 391003,
            "codeCH" : "PV",
            "nameUIC" : "Paris-Montparnasse",
            "nameCH" : "Plateau de Vouillé",
            "code" : ""
        },
        {
            "codeUIC" : 391003,
            "codeCH" : "VO",
            "nameUIC" : "Paris-Montparnasse",
            "nameCH" : "Accès Vouillé",
            "code" : ""
        },
        {
            "codeUIC" : 391102,
            "codeCH" : 0,
            "nameUIC" : "Paris-Vaugirard",
            "nameCH" : "",
            "code" : "PVA"
        },
        {
            "codeUIC" : 391300,
            "codeCH" : 0,
            "nameUIC" : "Morre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 391516,
            "codeCH" : 0,
            "nameUIC" : "Ouest-Ceinture",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 391532,
            "codeCH" : "BI",
            "nameUIC" : "Vanves-Malakoff",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 391532,
            "codeCH" : "BV",
            "nameUIC" : "Vanves-Malakoff",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 391532,
            "codeCH" : 4,
            "nameUIC" : "Vanves-Malakoff",
            "nameCH" : "Aiguille 304",
            "code" : ""
        },
        {
            "codeUIC" : 391532,
            "codeCH" : 5,
            "nameUIC" : "Vanves-Malakoff",
            "nameCH" : "Aiguille 305",
            "code" : ""
        },
        {
            "codeUIC" : 391532,
            "codeCH" : "1T",
            "nameUIC" : "Vanves-Malakoff",
            "nameCH" : "Entrée Voie 1Ter",
            "code" : ""
        },
        {
            "codeUIC" : 391565,
            "codeCH" : "TI",
            "nameUIC" : "Clamart",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 391565,
            "codeCH" : 0,
            "nameUIC" : "Clamart",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 391805,
            "codeCH" : "AP",
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Aiguille Côté Vanves",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : "AT",
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Ateliers",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : "ES",
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Aiguille 402/403",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : "GR",
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Garage de Rames",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : "M2",
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Aiguille 520",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : "SE",
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Sortie/Entrée Atelier",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : 1,
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Aiguille 601",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : 2,
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Aiguille 502",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : 11,
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Aiguille 311/312",
            "code" : "MO"
        },
        {
            "codeUIC" : 391805,
            "codeCH" : 31,
            "nameUIC" : "Montrouge-Châtillon",
            "nameCH" : "Aiguille 401/631",
            "code" : "MO"
        },
        {
            "codeUIC" : 393009,
            "codeCH" : "AO",
            "nameUIC" : "Versailles-Chantiers",
            "nameCH" : "Accès Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 393009,
            "codeCH" : "BM",
            "nameUIC" : "Versailles-Chantiers",
            "nameCH" : "Bifurcation des Matelots",
            "code" : ""
        },
        {
            "codeUIC" : 393009,
            "codeCH" : "BV",
            "nameUIC" : "Versailles-Chantiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393009,
            "codeCH" : "PO",
            "nameUIC" : "Versailles-Chantiers",
            "nameCH" : "Bifurcation de Porchefontaine",
            "code" : ""
        },
        {
            "codeUIC" : 393009,
            "codeCH" : "SG",
            "nameUIC" : "Versailles-Chantiers",
            "nameCH" : "Carré 206",
            "code" : ""
        },
        {
            "codeUIC" : 393009,
            "codeCH" : 18,
            "nameUIC" : "Versailles-Chantiers",
            "nameCH" : "Liaisons Km 18",
            "code" : ""
        },
        {
            "codeUIC" : 393033,
            "codeCH" : "BV",
            "nameUIC" : "Invalides",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393033,
            "codeCH" : 85,
            "nameUIC" : "Invalides",
            "nameCH" : "Carré 85",
            "code" : ""
        },
        {
            "codeUIC" : 393033,
            "codeCH" : 92,
            "nameUIC" : "Invalides",
            "nameCH" : "Carré 92",
            "code" : ""
        },
        {
            "codeUIC" : 393041,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-l'Alma",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393058,
            "codeCH" : "BV",
            "nameUIC" : "Champ-de-Mars-Tour-Eiffel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393058,
            "codeCH" : "TI",
            "nameUIC" : "Champ-de-Mars-Tour-Eiffel",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 393058,
            "codeCH" : 62,
            "nameUIC" : "Champ-de-Mars-Tour-Eiffel",
            "nameCH" : "Carré 422",
            "code" : ""
        },
        {
            "codeUIC" : 393058,
            "codeCH" : 63,
            "nameUIC" : "Champ-de-Mars-Tour-Eiffel",
            "nameCH" : "Carré 405",
            "code" : ""
        },
        {
            "codeUIC" : 393058,
            "codeCH" : 67,
            "nameUIC" : "Champ-de-Mars-Tour-Eiffel",
            "nameCH" : "Carré 67",
            "code" : ""
        },
        {
            "codeUIC" : 393058,
            "codeCH" : 72,
            "nameUIC" : "Champ-de-Mars-Tour-Eiffel",
            "nameCH" : "Carré 72",
            "code" : ""
        },
        {
            "codeUIC" : 393066,
            "codeCH" : 0,
            "nameUIC" : "Javel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393066,
            "codeCH" : 38,
            "nameUIC" : "Javel",
            "nameCH" : "Signal 385-387",
            "code" : ""
        },
        {
            "codeUIC" : 393074,
            "codeCH" : 0,
            "nameUIC" : "Issy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393082,
            "codeCH" : 0,
            "nameUIC" : "Meudon-Val-Fleury",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393108,
            "codeCH" : 0,
            "nameUIC" : "Meudon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393116,
            "codeCH" : 0,
            "nameUIC" : "Bellevue",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393124,
            "codeCH" : "TI",
            "nameUIC" : "Sèvres-Rive-Gauche",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 393124,
            "codeCH" : 0,
            "nameUIC" : "Sèvres-Rive-Gauche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393132,
            "codeCH" : "BV",
            "nameUIC" : "Versailles-Matelots",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VM"
        },
        {
            "codeUIC" : 393132,
            "codeCH" : "LO",
            "nameUIC" : "Versailles-Matelots",
            "nameCH" : "Local",
            "code" : "VM"
        },
        {
            "codeUIC" : 393132,
            "codeCH" : "M4",
            "nameUIC" : "Versailles-Matelots",
            "nameCH" : "Point M4",
            "code" : "VM"
        },
        {
            "codeUIC" : 393132,
            "codeCH" : "P5",
            "nameUIC" : "Versailles-Matelots",
            "nameCH" : "Poste 5",
            "code" : "VM"
        },
        {
            "codeUIC" : 393157,
            "codeCH" : 0,
            "nameUIC" : "Versailles-Rive-Gauche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393165,
            "codeCH" : 0,
            "nameUIC" : "Porchefontaine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393173,
            "codeCH" : "BV",
            "nameUIC" : "Chaville-Vélizy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393173,
            "codeCH" : 26,
            "nameUIC" : "Chaville-Vélizy",
            "nameCH" : "Carré 26",
            "code" : ""
        },
        {
            "codeUIC" : 393207,
            "codeCH" : 0,
            "nameUIC" : "Chaville-Rive-Gauche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393215,
            "codeCH" : 0,
            "nameUIC" : "Viroflay-Rive-Gauche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393223,
            "codeCH" : "BG",
            "nameUIC" : "St-Cyr",
            "nameCH" : "Bifurcation du Bois-Gazé",
            "code" : "CYR"
        },
        {
            "codeUIC" : 393223,
            "codeCH" : "BR",
            "nameUIC" : "St-Cyr",
            "nameCH" : "Bifurcation de Bois-Robert",
            "code" : "CYR"
        },
        {
            "codeUIC" : 393223,
            "codeCH" : "BV",
            "nameUIC" : "St-Cyr",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CYR"
        },
        {
            "codeUIC" : 393223,
            "codeCH" : "GR",
            "nameUIC" : "St-Cyr",
            "nameCH" : "Bifurcation de Granville",
            "code" : "CYR"
        },
        {
            "codeUIC" : 393223,
            "codeCH" : "V1",
            "nameUIC" : "St-Cyr",
            "nameCH" : "Entrée/Sortie IPCS Voie 1",
            "code" : "CYR"
        },
        {
            "codeUIC" : 393223,
            "codeCH" : "V2",
            "nameUIC" : "St-Cyr",
            "nameCH" : "Entrée/Sortie IPCS Voie 2",
            "code" : "CYR"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "AE",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Arrêt EMM",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "PD",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Poste D",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "PG",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Garage SQDF",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "P3",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Poste 3",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "P4",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Poste 4",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "QN",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Quai Navette",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "RG",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Garage Triage",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "RO",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Faisceau Réception",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393231,
            "codeCH" : "TR",
            "nameUIC" : "Trappes-Marchandises",
            "nameCH" : "Triage",
            "code" : "TRA"
        },
        {
            "codeUIC" : 393256,
            "codeCH" : "AT",
            "nameUIC" : "La Verrière",
            "nameCH" : "Accès Triage",
            "code" : ""
        },
        {
            "codeUIC" : 393256,
            "codeCH" : "BV",
            "nameUIC" : "La Verrière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393256,
            "codeCH" : "ES",
            "nameUIC" : "La Verrière",
            "nameCH" : "Entrée Voie 1Bis, Sortie Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 393256,
            "codeCH" : "ET",
            "nameUIC" : "La Verrière",
            "nameCH" : "Entrée/Sortie Triage",
            "code" : ""
        },
        {
            "codeUIC" : 393256,
            "codeCH" : "SE",
            "nameUIC" : "La Verrière",
            "nameCH" : "Sortie Voie 1Bis, Entrée Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 393256,
            "codeCH" : "ZI",
            "nameUIC" : "La Verrière",
            "nameCH" : "Zone Industrielle",
            "code" : ""
        },
        {
            "codeUIC" : 393272,
            "codeCH" : 0,
            "nameUIC" : "Coignières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393280,
            "codeCH" : 0,
            "nameUIC" : "Les Essarts-le-Roi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393298,
            "codeCH" : "BV",
            "nameUIC" : "Le Perray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393298,
            "codeCH" : "1B",
            "nameUIC" : "Le Perray",
            "nameCH" : "Entrée Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 393298,
            "codeCH" : "2B",
            "nameUIC" : "Le Perray",
            "nameCH" : "Sortie Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 393306,
            "codeCH" : "BV",
            "nameUIC" : "Issy-Val-de-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393314,
            "codeCH" : "BV",
            "nameUIC" : "Rambouillet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393314,
            "codeCH" : "B2",
            "nameUIC" : "Rambouillet",
            "nameCH" : "Liaison Voie 2Bis/Voie 2",
            "code" : ""
        },
        {
            "codeUIC" : 393314,
            "codeCH" : "SE",
            "nameUIC" : "Rambouillet",
            "nameCH" : "Liaison Voie 1Bis/Voie 1",
            "code" : ""
        },
        {
            "codeUIC" : 393314,
            "codeCH" : "2B",
            "nameUIC" : "Rambouillet",
            "nameCH" : "Liaison Voie 2/Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 393322,
            "codeCH" : "BV",
            "nameUIC" : "Pont-du-Garigliano H. Pompidou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393322,
            "codeCH" : "TI",
            "nameUIC" : "Pont-du-Garigliano H. Pompidou",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 393322,
            "codeCH" : 45,
            "nameUIC" : "Pont-du-Garigliano H. Pompidou",
            "nameCH" : "Point X",
            "code" : ""
        },
        {
            "codeUIC" : 393322,
            "codeCH" : 50,
            "nameUIC" : "Pont-du-Garigliano H. Pompidou",
            "nameCH" : "Carré 50",
            "code" : ""
        },
        {
            "codeUIC" : 393348,
            "codeCH" : 0,
            "nameUIC" : "Gazeran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393363,
            "codeCH" : "BV",
            "nameUIC" : "Beynes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393405,
            "codeCH" : 0,
            "nameUIC" : "Fontenay-le-Fleury",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393413,
            "codeCH" : 0,
            "nameUIC" : "Villepreux-les-Clayes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393421,
            "codeCH" : "BI",
            "nameUIC" : "Plaisir-Grignon",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 393421,
            "codeCH" : "BV",
            "nameUIC" : "Plaisir-Grignon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393421,
            "codeCH" : "IC",
            "nameUIC" : "Plaisir-Grignon",
            "nameCH" : "Entrée/Sortie IPCS Voie 1/Voie 2",
            "code" : ""
        },
        {
            "codeUIC" : 393439,
            "codeCH" : 0,
            "nameUIC" : "Garancières-la-Queue",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393447,
            "codeCH" : 0,
            "nameUIC" : "Orgérus-Béhoust",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393454,
            "codeCH" : 0,
            "nameUIC" : "Tacoignières-Richebourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393462,
            "codeCH" : 0,
            "nameUIC" : "Houdan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393470,
            "codeCH" : 0,
            "nameUIC" : "Marchezais-Broué",
            "nameCH" : "",
            "code" : "MBR"
        },
        {
            "codeUIC" : 393488,
            "codeCH" : "CI",
            "nameUIC" : "Dreux",
            "nameCH" : "Carré 31",
            "code" : "DX"
        },
        {
            "codeUIC" : 393488,
            "codeCH" : "CP",
            "nameUIC" : "Dreux",
            "nameCH" : "Carré 32",
            "code" : "DX"
        },
        {
            "codeUIC" : 393488,
            "codeCH" : "TI",
            "nameUIC" : "Dreux",
            "nameCH" : "Chantier Impair",
            "code" : "DX"
        },
        {
            "codeUIC" : 393488,
            "codeCH" : 0,
            "nameUIC" : "Dreux",
            "nameCH" : "",
            "code" : "DX"
        },
        {
            "codeUIC" : 393504,
            "codeCH" : 0,
            "nameUIC" : "Petit-Jouy-Les Loges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393512,
            "codeCH" : 0,
            "nameUIC" : "Jouy-en-Josas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393520,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-St-Rémy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393538,
            "codeCH" : 0,
            "nameUIC" : "Vauboyen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393546,
            "codeCH" : "BV",
            "nameUIC" : "Bièvres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393553,
            "codeCH" : "SG",
            "nameUIC" : "Nonancourt",
            "nameCH" : "Sémaphore 33",
            "code" : ""
        },
        {
            "codeUIC" : 393553,
            "codeCH" : "SP",
            "nameUIC" : "Nonancourt",
            "nameCH" : "Sémaphore 34",
            "code" : ""
        },
        {
            "codeUIC" : 393553,
            "codeCH" : 0,
            "nameUIC" : "Nonancourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393561,
            "codeCH" : 0,
            "nameUIC" : "Igny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393579,
            "codeCH" : "BV",
            "nameUIC" : "Massy-Palaiseau-Grande-Ceintur",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MAY"
        },
        {
            "codeUIC" : 393579,
            "codeCH" : "P1",
            "nameUIC" : "Massy-Palaiseau-Grande-Ceintur",
            "nameCH" : "Poste 1",
            "code" : "MAY"
        },
        {
            "codeUIC" : 393579,
            "codeCH" : "RV",
            "nameUIC" : "Massy-Palaiseau-Grande-Ceintur",
            "nameCH" : "Aiguille 52/53",
            "code" : "MAY"
        },
        {
            "codeUIC" : 393587,
            "codeCH" : "SI",
            "nameUIC" : "Tillières",
            "nameCH" : "Sémaphore 35",
            "code" : ""
        },
        {
            "codeUIC" : 393587,
            "codeCH" : "SP",
            "nameUIC" : "Tillières",
            "nameCH" : "Sémaphore 36",
            "code" : ""
        },
        {
            "codeUIC" : 393587,
            "codeCH" : 0,
            "nameUIC" : "Tillières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393595,
            "codeCH" : "C1",
            "nameUIC" : "Verneuil-sur-Avre",
            "nameCH" : "Carré 41",
            "code" : "VEA"
        },
        {
            "codeUIC" : 393595,
            "codeCH" : "C2",
            "nameUIC" : "Verneuil-sur-Avre",
            "nameCH" : "Carré 42",
            "code" : "VEA"
        },
        {
            "codeUIC" : 393595,
            "codeCH" : "SP",
            "nameUIC" : "Verneuil-sur-Avre",
            "nameCH" : "Sémaphore 38",
            "code" : "VEA"
        },
        {
            "codeUIC" : 393595,
            "codeCH" : 0,
            "nameUIC" : "Verneuil-sur-Avre",
            "nameCH" : "",
            "code" : "VEA"
        },
        {
            "codeUIC" : 393603,
            "codeCH" : "SI",
            "nameUIC" : "Bourth",
            "nameCH" : "Sémaphore 45",
            "code" : ""
        },
        {
            "codeUIC" : 393603,
            "codeCH" : "SP",
            "nameUIC" : "Bourth",
            "nameCH" : "Sémaphore 44",
            "code" : ""
        },
        {
            "codeUIC" : 393603,
            "codeCH" : 0,
            "nameUIC" : "Bourth",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393611,
            "codeCH" : 0,
            "nameUIC" : "Longjumeau",
            "nameCH" : "",
            "code" : "LJU"
        },
        {
            "codeUIC" : 393629,
            "codeCH" : 0,
            "nameUIC" : "Plaisir-Les Clayes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393637,
            "codeCH" : 0,
            "nameUIC" : "Chilly-Mazarin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393645,
            "codeCH" : 0,
            "nameUIC" : "Gravigny-Balizy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393652,
            "codeCH" : 0,
            "nameUIC" : "Petit-Vaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393660,
            "codeCH" : "EC",
            "nameUIC" : "Garnay",
            "nameCH" : "ITE Coopérative Agricole de la Région Drouaise",
            "code" : "GRY"
        },
        {
            "codeUIC" : 393678,
            "codeCH" : "EH",
            "nameUIC" : "Aunay-Tréon",
            "nameCH" : "ITE HUREL-ARC",
            "code" : "AYT"
        },
        {
            "codeUIC" : 393702,
            "codeCH" : "BV",
            "nameUIC" : "Massy-TGV",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393702,
            "codeCH" : "E3",
            "nameUIC" : "Massy-TGV",
            "nameCH" : "Aiguille 1111/1113",
            "code" : ""
        },
        {
            "codeUIC" : 393702,
            "codeCH" : "RM",
            "nameUIC" : "Massy-TGV",
            "nameCH" : "Aiguille 1107V",
            "code" : ""
        },
        {
            "codeUIC" : 393702,
            "codeCH" : "S3",
            "nameUIC" : "Massy-TGV",
            "nameCH" : "Aiguille 1121B/1127B",
            "code" : ""
        },
        {
            "codeUIC" : 393702,
            "codeCH" : 1,
            "nameUIC" : "Massy-TGV",
            "nameCH" : "Aiguille 1101/1102",
            "code" : ""
        },
        {
            "codeUIC" : 393702,
            "codeCH" : 4,
            "nameUIC" : "Massy-TGV",
            "nameCH" : "Aiguille 1104/1103",
            "code" : ""
        },
        {
            "codeUIC" : 393702,
            "codeCH" : 11,
            "nameUIC" : "Massy-TGV",
            "nameCH" : "Poste 11",
            "code" : ""
        },
        {
            "codeUIC" : 393835,
            "codeCH" : "BV",
            "nameUIC" : "Trappes-Voyageurs",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393843,
            "codeCH" : "AG",
            "nameUIC" : "St-Quentin-en-Yvelines",
            "nameCH" : "Aiguille 733a",
            "code" : ""
        },
        {
            "codeUIC" : 393843,
            "codeCH" : "BQ",
            "nameUIC" : "St-Quentin-en-Yvelines",
            "nameCH" : "Bifurcation de St-Quentin",
            "code" : ""
        },
        {
            "codeUIC" : 393843,
            "codeCH" : "BV",
            "nameUIC" : "St-Quentin-en-Yvelines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 393843,
            "codeCH" : "GO",
            "nameUIC" : "St-Quentin-en-Yvelines",
            "nameCH" : "Garages de Rames Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 393843,
            "codeCH" : "GR",
            "nameUIC" : "St-Quentin-en-Yvelines",
            "nameCH" : "Garage de Rames",
            "code" : ""
        },
        {
            "codeUIC" : 393843,
            "codeCH" : "TI",
            "nameUIC" : "St-Quentin-en-Yvelines",
            "nameCH" : "Tiroirs",
            "code" : ""
        },
        {
            "codeUIC" : 393843,
            "codeCH" : 23,
            "nameUIC" : "St-Quentin-en-Yvelines",
            "nameCH" : "Liaisons Km 23",
            "code" : ""
        },
        {
            "codeUIC" : 393850,
            "codeCH" : 0,
            "nameUIC" : "St-Cyr-Grande-Ceinture",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393876,
            "codeCH" : 0,
            "nameUIC" : "Noisy-le-Roi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393884,
            "codeCH" : 0,
            "nameUIC" : "Villiers-Neauphle-Pontchartrai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 393892,
            "codeCH" : 0,
            "nameUIC" : "Montfort-l'Amaury-Mère",
            "nameCH" : "",
            "code" : "MLM"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "AG",
            "nameUIC" : "Chartres",
            "nameCH" : "Aiguille 411",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "BD",
            "nameUIC" : "Chartres",
            "nameCH" : "Bifurcation de Dreux",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "BV",
            "nameUIC" : "Chartres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "EI",
            "nameUIC" : "Chartres",
            "nameCH" : "Entrée Évitement Impair",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "ES",
            "nameUIC" : "Chartres",
            "nameCH" : "Entrée/Sortie Voie Unique",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "E1",
            "nameUIC" : "Chartres",
            "nameCH" : "Entrée IPCS Voie 1",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "MS",
            "nameUIC" : "Chartres",
            "nameCH" : "ITE Mainvilliers",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "P4",
            "nameUIC" : "Chartres",
            "nameCH" : "Bifurcation des Vauroux",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "SI",
            "nameUIC" : "Chartres",
            "nameCH" : "Sortie Évitement Impair",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "S2",
            "nameUIC" : "Chartres",
            "nameCH" : "Sortie IPCS Voie 2",
            "code" : "CH"
        },
        {
            "codeUIC" : 394007,
            "codeCH" : "TR",
            "nameUIC" : "Chartres",
            "nameCH" : "Triage",
            "code" : "CH"
        },
        {
            "codeUIC" : 394015,
            "codeCH" : "BV",
            "nameUIC" : "Lucé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LCJ"
        },
        {
            "codeUIC" : 394015,
            "codeCH" : "EP",
            "nameUIC" : "Lucé",
            "nameCH" : "ITE Voie Mère",
            "code" : "LCJ"
        },
        {
            "codeUIC" : 394114,
            "codeCH" : "BV",
            "nameUIC" : "Épernon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 394114,
            "codeCH" : "ES",
            "nameUIC" : "Épernon",
            "nameCH" : "Entrée Voie 1Bis, Sortie Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 394130,
            "codeCH" : "BV",
            "nameUIC" : "Maintenon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 394130,
            "codeCH" : "EV",
            "nameUIC" : "Maintenon",
            "nameCH" : "Entrée Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 394130,
            "codeCH" : "IP",
            "nameUIC" : "Maintenon",
            "nameCH" : "IPCS Entrée Voie 2, Sortie Voie 1",
            "code" : ""
        },
        {
            "codeUIC" : 394130,
            "codeCH" : "M1",
            "nameUIC" : "Maintenon",
            "nameCH" : "Liaison Voie 1Bis/Voie 1",
            "code" : ""
        },
        {
            "codeUIC" : 394148,
            "codeCH" : 0,
            "nameUIC" : "St-Piat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394155,
            "codeCH" : "BV",
            "nameUIC" : "Jouy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 394155,
            "codeCH" : "EI",
            "nameUIC" : "Jouy",
            "nameCH" : "Entrée IPCS Voie 1, Voie 2",
            "code" : ""
        },
        {
            "codeUIC" : 394155,
            "codeCH" : "SI",
            "nameUIC" : "Jouy",
            "nameCH" : "Sortie IPCS Voie 1, Voie 2",
            "code" : ""
        },
        {
            "codeUIC" : 394171,
            "codeCH" : 0,
            "nameUIC" : "La Villette-St-Prest",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394205,
            "codeCH" : 0,
            "nameUIC" : "Amilly-Ouerray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394213,
            "codeCH" : 0,
            "nameUIC" : "St-Aubin-St-Luperce",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394221,
            "codeCH" : 0,
            "nameUIC" : "Courville-sur-Eure",
            "nameCH" : "",
            "code" : "CVB"
        },
        {
            "codeUIC" : 394247,
            "codeCH" : 0,
            "nameUIC" : "Pontgouin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394254,
            "codeCH" : "BV",
            "nameUIC" : "La Loupe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 394254,
            "codeCH" : "EI",
            "nameUIC" : "La Loupe",
            "nameCH" : "Entrée Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 394254,
            "codeCH" : "EP",
            "nameUIC" : "La Loupe",
            "nameCH" : "Entrée Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 394254,
            "codeCH" : "SI",
            "nameUIC" : "La Loupe",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 394254,
            "codeCH" : "SP",
            "nameUIC" : "La Loupe",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 394270,
            "codeCH" : 0,
            "nameUIC" : "Bretoncelles",
            "nameCH" : "",
            "code" : "BTO"
        },
        {
            "codeUIC" : 394288,
            "codeCH" : 0,
            "nameUIC" : "Condé-sur-Huisne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394296,
            "codeCH" : "BV",
            "nameUIC" : "Nogent-le-Rotrou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NGR"
        },
        {
            "codeUIC" : 394296,
            "codeCH" : "EP",
            "nameUIC" : "Nogent-le-Rotrou",
            "nameCH" : "Entrée Évitement Pair",
            "code" : "NGR"
        },
        {
            "codeUIC" : 394296,
            "codeCH" : "E3",
            "nameUIC" : "Nogent-le-Rotrou",
            "nameCH" : "Entrée Voie 3",
            "code" : "NGR"
        },
        {
            "codeUIC" : 394296,
            "codeCH" : "SP",
            "nameUIC" : "Nogent-le-Rotrou",
            "nameCH" : "Sortie Évitement Pair",
            "code" : "NGR"
        },
        {
            "codeUIC" : 394296,
            "codeCH" : "S3",
            "nameUIC" : "Nogent-le-Rotrou",
            "nameCH" : "Sortie Voie 3",
            "code" : "NGR"
        },
        {
            "codeUIC" : 394387,
            "codeCH" : "BV",
            "nameUIC" : "Beaulieu-Le Coudray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BUO"
        },
        {
            "codeUIC" : 394387,
            "codeCH" : "EC",
            "nameUIC" : "Beaulieu-Le Coudray",
            "nameCH" : "ITE Class-France",
            "code" : "BUO"
        },
        {
            "codeUIC" : 394387,
            "codeCH" : "EV",
            "nameUIC" : "Beaulieu-Le Coudray",
            "nameCH" : "ITE Ville de Chartres",
            "code" : "BUO"
        },
        {
            "codeUIC" : 394387,
            "codeCH" : 0,
            "nameUIC" : "Beaulieu-Le Coudray",
            "nameCH" : "",
            "code" : "BUO"
        },
        {
            "codeUIC" : 394403,
            "codeCH" : 0,
            "nameUIC" : "La Taye",
            "nameCH" : "",
            "code" : "LTA"
        },
        {
            "codeUIC" : 394411,
            "codeCH" : 0,
            "nameUIC" : "Bailleau-le-Pin",
            "nameCH" : "",
            "code" : "BAP"
        },
        {
            "codeUIC" : 394429,
            "codeCH" : 0,
            "nameUIC" : "Magny-Blandainville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394437,
            "codeCH" : 0,
            "nameUIC" : "Illiers-Combray",
            "nameCH" : "",
            "code" : "ILC"
        },
        {
            "codeUIC" : 394452,
            "codeCH" : 0,
            "nameUIC" : "Brou",
            "nameCH" : "",
            "code" : "BRO"
        },
        {
            "codeUIC" : 394486,
            "codeCH" : 0,
            "nameUIC" : "Arrou",
            "nameCH" : "",
            "code" : "AOU"
        },
        {
            "codeUIC" : 394494,
            "codeCH" : 0,
            "nameUIC" : "Courtalain-St-Pellerin",
            "nameCH" : "",
            "code" : "CSP"
        },
        {
            "codeUIC" : 394502,
            "codeCH" : 0,
            "nameUIC" : "Bailleau-l'Évêque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394528,
            "codeCH" : "ET",
            "nameUIC" : "Clévilliers",
            "nameCH" : "ITE Coopérative du Thymerais",
            "code" : ""
        },
        {
            "codeUIC" : 394528,
            "codeCH" : "LO",
            "nameUIC" : "Clévilliers",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 394528,
            "codeCH" : 18,
            "nameUIC" : "Clévilliers",
            "nameCH" : "PN 18",
            "code" : ""
        },
        {
            "codeUIC" : 394544,
            "codeCH" : "LO",
            "nameUIC" : "Theuvy-Achères",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 394544,
            "codeCH" : 25,
            "nameUIC" : "Theuvy-Achères",
            "nameCH" : "PN 25",
            "code" : ""
        },
        {
            "codeUIC" : 394569,
            "codeCH" : "EC",
            "nameUIC" : "St-Sauveur-Châteauneuf",
            "nameCH" : "ITE Coopérative Agricole de la Région Drouaise",
            "code" : "SAF"
        },
        {
            "codeUIC" : 394569,
            "codeCH" : 0,
            "nameUIC" : "St-Sauveur-Châteauneuf",
            "nameCH" : "",
            "code" : "SAF"
        },
        {
            "codeUIC" : 394569,
            "codeCH" : 28,
            "nameUIC" : "St-Sauveur-Châteauneuf",
            "nameCH" : "PN 28",
            "code" : "SAF"
        },
        {
            "codeUIC" : 394577,
            "codeCH" : 0,
            "nameUIC" : "Droué",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 394866,
            "codeCH" : "EP",
            "nameUIC" : "Coltainville",
            "nameCH" : "ITE Primagaz",
            "code" : "CTW"
        },
        {
            "codeUIC" : 394874,
            "codeCH" : "EB",
            "nameUIC" : "Gallardon-Pont",
            "nameCH" : "ITE SABLEM",
            "code" : ""
        },
        {
            "codeUIC" : 394874,
            "codeCH" : "ES",
            "nameUIC" : "Gallardon-Pont",
            "nameCH" : "ITE SCAEL",
            "code" : ""
        },
        {
            "codeUIC" : 395087,
            "codeCH" : "GA",
            "nameUIC" : "ITE Zone Industrielle Gravière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "BV",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "CR",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bifurcation de la Petite Croix",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "DO",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bifurcation des Docks",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "FO",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bifurcation des Fontaines",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "GE",
            "nameUIC" : "Le Mans",
            "nameCH" : "Rac St-Georges Est",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "GO",
            "nameUIC" : "Le Mans",
            "nameCH" : "Rac St-Georges Ouest",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "IT",
            "nameUIC" : "Le Mans",
            "nameCH" : "ITE Km 329,7",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "PE",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bifurcation de Pontlieue",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "PN",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bifurcation de la Plumasserie Nord",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "PR",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bifurcation de Préau",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "PS",
            "nameUIC" : "Le Mans",
            "nameCH" : "Bifurcation de la Plumasserie Sud",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "P7",
            "nameUIC" : "Le Mans",
            "nameCH" : "Poste 7",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "P8",
            "nameUIC" : "Le Mans",
            "nameCH" : "Poste 8",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : "TR",
            "nameUIC" : "Le Mans",
            "nameCH" : "Triage",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : 76,
            "nameUIC" : "Le Mans",
            "nameCH" : "Carré 276",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396002,
            "codeCH" : 77,
            "nameUIC" : "Le Mans",
            "nameCH" : "Carré 277",
            "code" : "LMN"
        },
        {
            "codeUIC" : 396028,
            "codeCH" : 0,
            "nameUIC" : "Neuville-sur-Sarthe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396036,
            "codeCH" : 0,
            "nameUIC" : "La Guierche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396036,
            "codeCH" : 14,
            "nameUIC" : "La Guierche",
            "nameCH" : "Sémaphore 414",
            "code" : ""
        },
        {
            "codeUIC" : 396044,
            "codeCH" : 0,
            "nameUIC" : "Montbizot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396044,
            "codeCH" : 13,
            "nameUIC" : "Montbizot",
            "nameCH" : "Sémaphore 413",
            "code" : ""
        },
        {
            "codeUIC" : 396051,
            "codeCH" : 0,
            "nameUIC" : "Teillé (Sarthe)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396077,
            "codeCH" : 0,
            "nameUIC" : "Vivoin-Beaumont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396077,
            "codeCH" : 17,
            "nameUIC" : "Vivoin-Beaumont",
            "nameCH" : "Sémaphore 417",
            "code" : ""
        },
        {
            "codeUIC" : 396077,
            "codeCH" : 18,
            "nameUIC" : "Vivoin-Beaumont",
            "nameCH" : "Sémaphore 418",
            "code" : ""
        },
        {
            "codeUIC" : 396085,
            "codeCH" : 0,
            "nameUIC" : "Piacé-St-Germain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396093,
            "codeCH" : 0,
            "nameUIC" : "La Hutte-Coulombiers",
            "nameCH" : "",
            "code" : "LHC"
        },
        {
            "codeUIC" : 396101,
            "codeCH" : 0,
            "nameUIC" : "Bourg-le-Roi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396192,
            "codeCH" : "AR",
            "nameUIC" : "La Milesse-La Bazoge",
            "nameCH" : "Bifurcation La Milesse",
            "code" : ""
        },
        {
            "codeUIC" : 396192,
            "codeCH" : "BS",
            "nameUIC" : "La Milesse-La Bazoge",
            "nameCH" : "Bifurcation St-Saturnin",
            "code" : ""
        },
        {
            "codeUIC" : 396192,
            "codeCH" : "CV",
            "nameUIC" : "La Milesse-La Bazoge",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 396192,
            "codeCH" : 0,
            "nameUIC" : "La Milesse-La Bazoge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396200,
            "codeCH" : 0,
            "nameUIC" : "Domfront (Sarthe)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396218,
            "codeCH" : 0,
            "nameUIC" : "Conlie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396226,
            "codeCH" : 0,
            "nameUIC" : "Crissé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396234,
            "codeCH" : "BV",
            "nameUIC" : "Sillé-le-Guillaume",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SLG"
        },
        {
            "codeUIC" : 396259,
            "codeCH" : 0,
            "nameUIC" : "Rouessé-Vassé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396267,
            "codeCH" : 0,
            "nameUIC" : "Yvré-l'Évêque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396275,
            "codeCH" : 0,
            "nameUIC" : "Champagné",
            "nameCH" : "",
            "code" : "CG"
        },
        {
            "codeUIC" : 396283,
            "codeCH" : 0,
            "nameUIC" : "St-Mars-la-Brière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396291,
            "codeCH" : "BV",
            "nameUIC" : "Montfort-le-Gesnois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 396291,
            "codeCH" : "GA",
            "nameUIC" : "Montfort-le-Gesnois",
            "nameCH" : "Garages",
            "code" : ""
        },
        {
            "codeUIC" : 396309,
            "codeCH" : "BL",
            "nameUIC" : "Connerré-Beillé",
            "nameCH" : "Bifurcation des Landes",
            "code" : "COB"
        },
        {
            "codeUIC" : 396309,
            "codeCH" : "BV",
            "nameUIC" : "Connerré-Beillé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COB"
        },
        {
            "codeUIC" : 396309,
            "codeCH" : "S1",
            "nameUIC" : "Connerré-Beillé",
            "nameCH" : "Bifurcation Nord LGV Atlantique",
            "code" : "COB"
        },
        {
            "codeUIC" : 396309,
            "codeCH" : "S2",
            "nameUIC" : "Connerré-Beillé",
            "nameCH" : "Bifurcation Sud LGV Atlantique",
            "code" : "COB"
        },
        {
            "codeUIC" : 396309,
            "codeCH" : "TR",
            "nameUIC" : "Connerré-Beillé",
            "nameCH" : "Entrée/Sortie Tri",
            "code" : "COB"
        },
        {
            "codeUIC" : 396317,
            "codeCH" : 0,
            "nameUIC" : "Sceaux-Boësse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396325,
            "codeCH" : "BV",
            "nameUIC" : "La Ferté-Bernard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 396325,
            "codeCH" : "EI",
            "nameUIC" : "La Ferté-Bernard",
            "nameCH" : "Entrée Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 396325,
            "codeCH" : "EP",
            "nameUIC" : "La Ferté-Bernard",
            "nameCH" : "Entrée Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 396325,
            "codeCH" : "SI",
            "nameUIC" : "La Ferté-Bernard",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 396325,
            "codeCH" : "SP",
            "nameUIC" : "La Ferté-Bernard",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 396333,
            "codeCH" : "ST",
            "nameUIC" : "Le Theil-La Rouge",
            "nameCH" : "ITE Sept",
            "code" : "LTH"
        },
        {
            "codeUIC" : 396333,
            "codeCH" : 0,
            "nameUIC" : "Le Theil-La Rouge",
            "nameCH" : "",
            "code" : "LTH"
        },
        {
            "codeUIC" : 396358,
            "codeCH" : 0,
            "nameUIC" : "Voivres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396366,
            "codeCH" : 0,
            "nameUIC" : "La Suze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396374,
            "codeCH" : 0,
            "nameUIC" : "Noyen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396382,
            "codeCH" : 0,
            "nameUIC" : "Avoise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396408,
            "codeCH" : "BC",
            "nameUIC" : "Sablé",
            "nameCH" : "Bifurcation de Châteaubriant",
            "code" : "SAB"
        },
        {
            "codeUIC" : 396408,
            "codeCH" : "BF",
            "nameUIC" : "Sablé",
            "nameCH" : "Bifurcation de Louailles",
            "code" : "SAB"
        },
        {
            "codeUIC" : 396408,
            "codeCH" : "BV",
            "nameUIC" : "Sablé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SAB"
        },
        {
            "codeUIC" : 396408,
            "codeCH" : "ES",
            "nameUIC" : "Sablé",
            "nameCH" : "Entréee/Sortie Base Travaux",
            "code" : "SAB"
        },
        {
            "codeUIC" : 396408,
            "codeCH" : "RJ",
            "nameUIC" : "Sablé",
            "nameCH" : "Raccordement de Juigné",
            "code" : "SAB"
        },
        {
            "codeUIC" : 396424,
            "codeCH" : "EC",
            "nameUIC" : "La Chapelle-du-Chêne",
            "nameCH" : "ITE CADS",
            "code" : ""
        },
        {
            "codeUIC" : 396432,
            "codeCH" : 0,
            "nameUIC" : "Mézeray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396440,
            "codeCH" : 0,
            "nameUIC" : "Malicorne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396473,
            "codeCH" : "CL",
            "nameUIC" : "La Flèche",
            "nameCH" : "ITE Cebal",
            "code" : ""
        },
        {
            "codeUIC" : 396473,
            "codeCH" : "CS",
            "nameUIC" : "La Flèche",
            "nameCH" : "ITE CADS",
            "code" : ""
        },
        {
            "codeUIC" : 396473,
            "codeCH" : "LO",
            "nameUIC" : "La Flèche",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 396549,
            "codeCH" : "BV",
            "nameUIC" : "Arnage",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AAG"
        },
        {
            "codeUIC" : 396549,
            "codeCH" : "EP",
            "nameUIC" : "Arnage",
            "nameCH" : "E Somena-Cosson",
            "code" : "AAG"
        },
        {
            "codeUIC" : 396549,
            "codeCH" : 6,
            "nameUIC" : "Arnage",
            "nameCH" : "Carré C6",
            "code" : "AAG"
        },
        {
            "codeUIC" : 396549,
            "codeCH" : 7,
            "nameUIC" : "Arnage",
            "nameCH" : "Carré C7",
            "code" : "AAG"
        },
        {
            "codeUIC" : 396556,
            "codeCH" : 0,
            "nameUIC" : "Laigné-St-Gervais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396564,
            "codeCH" : 0,
            "nameUIC" : "Écommoy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396564,
            "codeCH" : 10,
            "nameUIC" : "Écommoy",
            "nameCH" : "Carré C101 et C102",
            "code" : ""
        },
        {
            "codeUIC" : 396564,
            "codeCH" : 91,
            "nameUIC" : "Écommoy",
            "nameCH" : "Carré C91",
            "code" : ""
        },
        {
            "codeUIC" : 396564,
            "codeCH" : 92,
            "nameUIC" : "Écommoy",
            "nameCH" : "Carré C92",
            "code" : ""
        },
        {
            "codeUIC" : 396572,
            "codeCH" : "BV",
            "nameUIC" : "Aubigné-Racan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ABG"
        },
        {
            "codeUIC" : 396572,
            "codeCH" : "C7",
            "nameUIC" : "Aubigné-Racan",
            "nameCH" : "Carré C71 et C72",
            "code" : "ABG"
        },
        {
            "codeUIC" : 396572,
            "codeCH" : "C8",
            "nameUIC" : "Aubigné-Racan",
            "nameCH" : "Carré C81 et C82",
            "code" : "ABG"
        },
        {
            "codeUIC" : 396572,
            "codeCH" : "E1",
            "nameUIC" : "Aubigné-Racan",
            "nameCH" : "ITE Ergm",
            "code" : "ABG"
        },
        {
            "codeUIC" : 396580,
            "codeCH" : 0,
            "nameUIC" : "Mayet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396598,
            "codeCH" : 0,
            "nameUIC" : "Vaas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396606,
            "codeCH" : "BI",
            "nameUIC" : "Château-du-Loir",
            "nameCH" : "Bifurcation Courtalain",
            "code" : "CDL"
        },
        {
            "codeUIC" : 396606,
            "codeCH" : "BV",
            "nameUIC" : "Château-du-Loir",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDL"
        },
        {
            "codeUIC" : 396606,
            "codeCH" : 8,
            "nameUIC" : "Château-du-Loir",
            "nameCH" : "Carré C8",
            "code" : "CDL"
        },
        {
            "codeUIC" : 396606,
            "codeCH" : 15,
            "nameUIC" : "Château-du-Loir",
            "nameCH" : "Carré C15",
            "code" : "CDL"
        },
        {
            "codeUIC" : 396606,
            "codeCH" : 57,
            "nameUIC" : "Château-du-Loir",
            "nameCH" : "Carré C55 et C79",
            "code" : "CDL"
        },
        {
            "codeUIC" : 396614,
            "codeCH" : 0,
            "nameUIC" : "Le Tanchet-Lublé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396622,
            "codeCH" : "EC",
            "nameUIC" : "Château-la-Vallière",
            "nameCH" : "ITE CALCIA",
            "code" : ""
        },
        {
            "codeUIC" : 396622,
            "codeCH" : "LO",
            "nameUIC" : "Château-la-Vallière",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 396622,
            "codeCH" : "O1",
            "nameUIC" : "Château-la-Vallière",
            "nameCH" : "ITE Ciments de la Loire N° 1",
            "code" : ""
        },
        {
            "codeUIC" : 396622,
            "codeCH" : "O2",
            "nameUIC" : "Château-la-Vallière",
            "nameCH" : "ITE Ciments de la Loire N° 2",
            "code" : ""
        },
        {
            "codeUIC" : 396655,
            "codeCH" : 0,
            "nameUIC" : "Marçon-Vouvray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396671,
            "codeCH" : 0,
            "nameUIC" : "La Chartre-sur-le-Loir",
            "nameCH" : "",
            "code" : "LCL"
        },
        {
            "codeUIC" : 396689,
            "codeCH" : 0,
            "nameUIC" : "Ruillé-Poncé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396697,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Braye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396713,
            "codeCH" : "BV",
            "nameUIC" : "Bessé-sur-Braye",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BYH"
        },
        {
            "codeUIC" : 396713,
            "codeCH" : "EP",
            "nameUIC" : "Bessé-sur-Braye",
            "nameCH" : "ITE Arjomari-PR",
            "code" : "BYH"
        },
        {
            "codeUIC" : 396747,
            "codeCH" : "EF",
            "nameUIC" : "Mondoubleau",
            "nameCH" : "ITE Franciade",
            "code" : "MDO"
        },
        {
            "codeUIC" : 396747,
            "codeCH" : 0,
            "nameUIC" : "Mondoubleau",
            "nameCH" : "",
            "code" : "MDO"
        },
        {
            "codeUIC" : 396762,
            "codeCH" : 0,
            "nameUIC" : "Boursay-St-Agil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 396895,
            "codeCH" : "S6",
            "nameUIC" : "Clermont-La Rotonde",
            "nameCH" : "Sémaphore 605",
            "code" : ""
        },
        {
            "codeUIC" : 396895,
            "codeCH" : 0,
            "nameUIC" : "Clermont-La Rotonde",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 398172,
            "codeCH" : 17,
            "nameUIC" : "Poste 17",
            "nameCH" : "Changement de Voie",
            "code" : ""
        },
        {
            "codeUIC" : 399006,
            "codeCH" : 0,
            "nameUIC" : "Poste 12 Marcoussis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399014,
            "codeCH" : 0,
            "nameUIC" : "Poste 13 St-Arnoult",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399022,
            "codeCH" : "BT",
            "nameUIC" : "Poste 14 St-Léger",
            "nameCH" : "Raccordement Auneau",
            "code" : ""
        },
        {
            "codeUIC" : 399022,
            "codeCH" : 0,
            "nameUIC" : "Poste 14 St-Léger",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399030,
            "codeCH" : 0,
            "nameUIC" : "Poste 15 Rouvray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399048,
            "codeCH" : 0,
            "nameUIC" : "Poste 31 Plessis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399055,
            "codeCH" : 0,
            "nameUIC" : "Poste 32 Dollon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399063,
            "codeCH" : 0,
            "nameUIC" : "Poste 41 Droué",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399071,
            "codeCH" : 0,
            "nameUIC" : "Poste 43 St-Amand-Longpré",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399089,
            "codeCH" : 0,
            "nameUIC" : "Poste 44 St-Cyr-du-Gault",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399097,
            "codeCH" : 0,
            "nameUIC" : "Poste 45 Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 399303,
            "codeCH" : "BI",
            "nameUIC" : "Courtalain-TGV-Bifurcation",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 399360,
            "codeCH" : 0,
            "nameUIC" : "Poste 16 Dangeau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 400978,
            "codeCH" : "TL",
            "nameUIC" : "Tunnel du Crêt-d'Eau",
            "nameCH" : "Tête Longeray",
            "code" : ""
        },
        {
            "codeUIC" : 400986,
            "codeCH" : 0,
            "nameUIC" : "Magneux-Courlandon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 401323,
            "codeCH" : 0,
            "nameUIC" : "Mouans-Sartoux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 401968,
            "codeCH" : "AN",
            "nameUIC" : "ITE CAAT",
            "nameCH" : "Aiguille Km 8,3",
            "code" : ""
        },
        {
            "codeUIC" : 401968,
            "codeCH" : "AS",
            "nameUIC" : "ITE CAAT",
            "nameCH" : "Aiguille Km 7,7",
            "code" : ""
        },
        {
            "codeUIC" : 401968,
            "codeCH" : 0,
            "nameUIC" : "ITE CAAT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 402248,
            "codeCH" : 0,
            "nameUIC" : "ITE Bois et sciages de Sougy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 402255,
            "codeCH" : 0,
            "nameUIC" : "La Rochelle-Champs-de-Mars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 402263,
            "codeCH" : 0,
            "nameUIC" : "Aytré-les-Minimes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 402297,
            "codeCH" : 0,
            "nameUIC" : "Colmar-Mésanges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411017,
            "codeCH" : "CS",
            "nameUIC" : "Rouen-Rive-Droite",
            "nameCH" : "Entrée/Sortie IPCS Km 142,9",
            "code" : "RN"
        },
        {
            "codeUIC" : 411017,
            "codeCH" : 0,
            "nameUIC" : "Rouen-Rive-Droite",
            "nameCH" : "",
            "code" : "RN"
        },
        {
            "codeUIC" : 411058,
            "codeCH" : "LO",
            "nameUIC" : "Rouen-Martainville",
            "nameCH" : "Gare Locale",
            "code" : "RMT"
        },
        {
            "codeUIC" : 411058,
            "codeCH" : "PC",
            "nameUIC" : "Rouen-Martainville",
            "nameCH" : "Plateau CFI",
            "code" : "RMT"
        },
        {
            "codeUIC" : 411058,
            "codeCH" : "TM",
            "nameUIC" : "Rouen-Martainville",
            "nameCH" : "Triage Madeleine",
            "code" : "RMT"
        },
        {
            "codeUIC" : 411058,
            "codeCH" : "TO",
            "nameUIC" : "Rouen-Martainville",
            "nameCH" : "Terminal de l'Ouest",
            "code" : "RMT"
        },
        {
            "codeUIC" : 411074,
            "codeCH" : "LO",
            "nameUIC" : "Rouen-Orléans",
            "nameCH" : "Local",
            "code" : "RNO"
        },
        {
            "codeUIC" : 411074,
            "codeCH" : "P1",
            "nameUIC" : "Rouen-Orléans",
            "nameCH" : "Poste 1",
            "code" : "RNO"
        },
        {
            "codeUIC" : 411082,
            "codeCH" : "PA",
            "nameUIC" : "Petit-Quevilly",
            "nameCH" : "Plateau Anglais",
            "code" : "PQY"
        },
        {
            "codeUIC" : 411082,
            "codeCH" : "PO",
            "nameUIC" : "Petit-Quevilly",
            "nameCH" : "Poste 1",
            "code" : "PQY"
        },
        {
            "codeUIC" : 411108,
            "codeCH" : 0,
            "nameUIC" : "Grand-Quevilly",
            "nameCH" : "",
            "code" : "GRD"
        },
        {
            "codeUIC" : 411116,
            "codeCH" : "EP",
            "nameUIC" : "Petit-Couronne",
            "nameCH" : "ITE SHELL",
            "code" : "PTU"
        },
        {
            "codeUIC" : 411132,
            "codeCH" : "EP",
            "nameUIC" : "St-Pierre-lès-Elbeuf",
            "nameCH" : "ITE Witco Ifrachem",
            "code" : "SPE"
        },
        {
            "codeUIC" : 411173,
            "codeCH" : "BV",
            "nameUIC" : "Elbeuf-St-Aubin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ESA"
        },
        {
            "codeUIC" : 411173,
            "codeCH" : "ES",
            "nameUIC" : "Elbeuf-St-Aubin",
            "nameCH" : "ITE Stref",
            "code" : "ESA"
        },
        {
            "codeUIC" : 411173,
            "codeCH" : 3,
            "nameUIC" : "Elbeuf-St-Aubin",
            "nameCH" : "Carré 03",
            "code" : "ESA"
        },
        {
            "codeUIC" : 411173,
            "codeCH" : 12,
            "nameUIC" : "Elbeuf-St-Aubin",
            "nameCH" : "Carré 12",
            "code" : "ESA"
        },
        {
            "codeUIC" : 411181,
            "codeCH" : 0,
            "nameUIC" : "Tourville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411181,
            "codeCH" : 2,
            "nameUIC" : "Tourville",
            "nameCH" : "Signal 102",
            "code" : ""
        },
        {
            "codeUIC" : 411181,
            "codeCH" : 7,
            "nameUIC" : "Tourville",
            "nameCH" : "Carré 07",
            "code" : ""
        },
        {
            "codeUIC" : 411199,
            "codeCH" : 0,
            "nameUIC" : "Elbeuf-Ville",
            "nameCH" : "",
            "code" : "ELV"
        },
        {
            "codeUIC" : 411207,
            "codeCH" : "BT",
            "nameUIC" : "Oissel",
            "nameCH" : "Bifurcation de Tourville",
            "code" : "OI"
        },
        {
            "codeUIC" : 411207,
            "codeCH" : "BV",
            "nameUIC" : "Oissel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "OI"
        },
        {
            "codeUIC" : 411215,
            "codeCH" : "BV",
            "nameUIC" : "St-Étienne-du-Rouvray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SÉR"
        },
        {
            "codeUIC" : 411215,
            "codeCH" : "EP",
            "nameUIC" : "St-Étienne-du-Rouvray",
            "nameCH" : "ITE des Papèteries",
            "code" : "SÉR"
        },
        {
            "codeUIC" : 411215,
            "codeCH" : "PC",
            "nameUIC" : "St-Étienne-du-Rouvray",
            "nameCH" : "Pancarte 10 Z",
            "code" : "SÉR"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "AO",
            "nameUIC" : "Sotteville",
            "nameCH" : "Entrée/Sortie Zone P2+R",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "BE",
            "nameUIC" : "Sotteville",
            "nameCH" : "Bifurcation d'Eauplet",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "BV",
            "nameUIC" : "Sotteville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "C2",
            "nameUIC" : "Sotteville",
            "nameCH" : "Carré 225,2",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "C3",
            "nameUIC" : "Sotteville",
            "nameCH" : "Carré 354h",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "DA",
            "nameUIC" : "Sotteville",
            "nameCH" : "Dépôt Autorails",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "DE",
            "nameUIC" : "Sotteville",
            "nameCH" : "Grille Électrique",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "DL",
            "nameUIC" : "Sotteville",
            "nameCH" : "Dépôt Diesels",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "FA",
            "nameUIC" : "Sotteville",
            "nameCH" : "Faisceau Attente",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "GG",
            "nameUIC" : "Sotteville",
            "nameCH" : "Poste G",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "H1",
            "nameUIC" : "Sotteville",
            "nameCH" : "Poste H",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "JD",
            "nameUIC" : "Sotteville",
            "nameCH" : "Jonction Dépôt",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "J1",
            "nameUIC" : "Sotteville",
            "nameCH" : "Jonction Aiguilles 205-210",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "PB",
            "nameUIC" : "Sotteville",
            "nameCH" : "Poste B",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "PC",
            "nameUIC" : "Sotteville",
            "nameCH" : "Poste C",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "PE",
            "nameUIC" : "Sotteville",
            "nameCH" : "Poste E",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "PT",
            "nameUIC" : "Sotteville",
            "nameCH" : "Voies de La Poste",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "P1",
            "nameUIC" : "Sotteville",
            "nameCH" : "Poste 1 + A",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "P2",
            "nameUIC" : "Sotteville",
            "nameCH" : "Entrée/Sortie Zone Poste 2+R",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "QT",
            "nameUIC" : "Sotteville",
            "nameCH" : "Quai du Dépôt",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "Q4",
            "nameUIC" : "Sotteville",
            "nameCH" : "Quai des 4 mares",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "RA",
            "nameUIC" : "Sotteville",
            "nameCH" : "Triage Annexe",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "RO",
            "nameUIC" : "Sotteville",
            "nameCH" : "Triage Mixte",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "SP",
            "nameUIC" : "Sotteville",
            "nameCH" : "Sortie EVP et TR",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "S3",
            "nameUIC" : "Sotteville",
            "nameCH" : "Sortie Triages Voie 3",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "S4",
            "nameUIC" : "Sotteville",
            "nameCH" : "Sortie Triages Voie 4",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : "VR",
            "nameUIC" : "Sotteville",
            "nameCH" : "Garage Voitures",
            "code" : "SO"
        },
        {
            "codeUIC" : 411223,
            "codeCH" : 32,
            "nameUIC" : "Sotteville",
            "nameCH" : "Carrés 323 325 327",
            "code" : "SO"
        },
        {
            "codeUIC" : 411231,
            "codeCH" : 0,
            "nameUIC" : "Darnétal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411249,
            "codeCH" : "BV",
            "nameUIC" : "Maromme",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 411249,
            "codeCH" : "EI",
            "nameUIC" : "Maromme",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 411249,
            "codeCH" : "GA",
            "nameUIC" : "Maromme",
            "nameCH" : "Garage",
            "code" : ""
        },
        {
            "codeUIC" : 411249,
            "codeCH" : "SI",
            "nameUIC" : "Maromme",
            "nameCH" : "Entrée Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 411256,
            "codeCH" : "BM",
            "nameUIC" : "Malaunay-Le Houlme",
            "nameCH" : "Bifurcation de Malaunay",
            "code" : ""
        },
        {
            "codeUIC" : 411256,
            "codeCH" : "BV",
            "nameUIC" : "Malaunay-Le Houlme",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 411256,
            "codeCH" : "SP",
            "nameUIC" : "Malaunay-Le Houlme",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 411264,
            "codeCH" : 0,
            "nameUIC" : "Barentin-Embranchement",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411272,
            "codeCH" : 0,
            "nameUIC" : "Pavilly-Station",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411280,
            "codeCH" : "BA",
            "nameUIC" : "Motteville",
            "nameCH" : "Bifurcation d'Auzouville",
            "code" : "MOT"
        },
        {
            "codeUIC" : 411280,
            "codeCH" : "BV",
            "nameUIC" : "Motteville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MOT"
        },
        {
            "codeUIC" : 411280,
            "codeCH" : "EP",
            "nameUIC" : "Motteville",
            "nameCH" : "ITE SCETAUROUTE",
            "code" : "MOT"
        },
        {
            "codeUIC" : 411280,
            "codeCH" : "ES",
            "nameUIC" : "Motteville",
            "nameCH" : "Entrée/Sortie VUT Km 170,6",
            "code" : "MOT"
        },
        {
            "codeUIC" : 411280,
            "codeCH" : "1L",
            "nameUIC" : "Motteville",
            "nameCH" : "Sortie Voie 1L",
            "code" : "MOT"
        },
        {
            "codeUIC" : 411280,
            "codeCH" : "2L",
            "nameUIC" : "Motteville",
            "nameCH" : "Sortie Voie 2L",
            "code" : "MOT"
        },
        {
            "codeUIC" : 411363,
            "codeCH" : 0,
            "nameUIC" : "Haussez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411389,
            "codeCH" : 0,
            "nameUIC" : "Forges-les-Eaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411413,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-du-Vivier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411439,
            "codeCH" : 0,
            "nameUIC" : "Morgny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411447,
            "codeCH" : 0,
            "nameUIC" : "Longuerue-Vieux-Manoir",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411447,
            "codeCH" : 88,
            "nameUIC" : "Longuerue-Vieux-Manoir",
            "nameCH" : "Signal P188",
            "code" : ""
        },
        {
            "codeUIC" : 411447,
            "codeCH" : 98,
            "nameUIC" : "Longuerue-Vieux-Manoir",
            "nameCH" : "Signal Km 98,1",
            "code" : ""
        },
        {
            "codeUIC" : 411454,
            "codeCH" : "SI",
            "nameUIC" : "Montérolier-Buchy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411454,
            "codeCH" : 0,
            "nameUIC" : "Montérolier-Buchy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411454,
            "codeCH" : 22,
            "nameUIC" : "Montérolier-Buchy",
            "nameCH" : "Signal 23/25,1",
            "code" : ""
        },
        {
            "codeUIC" : 411454,
            "codeCH" : 70,
            "nameUIC" : "Montérolier-Buchy",
            "nameCH" : "Signal P170,1",
            "code" : ""
        },
        {
            "codeUIC" : 411462,
            "codeCH" : 0,
            "nameUIC" : "Sommery",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411462,
            "codeCH" : 65,
            "nameUIC" : "Sommery",
            "nameCH" : "Signal P165,1",
            "code" : ""
        },
        {
            "codeUIC" : 411462,
            "codeCH" : 68,
            "nameUIC" : "Sommery",
            "nameCH" : "Signal P168,1",
            "code" : ""
        },
        {
            "codeUIC" : 411470,
            "codeCH" : "SG",
            "nameUIC" : "Serqueux",
            "nameCH" : "Sémaphores 261/262",
            "code" : "SEQ"
        },
        {
            "codeUIC" : 411470,
            "codeCH" : "SI",
            "nameUIC" : "Serqueux",
            "nameCH" : "Sémaphores 251/252",
            "code" : "SEQ"
        },
        {
            "codeUIC" : 411470,
            "codeCH" : 0,
            "nameUIC" : "Serqueux",
            "nameCH" : "",
            "code" : "SEQ"
        },
        {
            "codeUIC" : 411470,
            "codeCH" : 13,
            "nameUIC" : "Serqueux",
            "nameCH" : "Carré 13",
            "code" : "SEQ"
        },
        {
            "codeUIC" : 411470,
            "codeCH" : 14,
            "nameUIC" : "Serqueux",
            "nameCH" : "Carré 14",
            "code" : "SEQ"
        },
        {
            "codeUIC" : 411504,
            "codeCH" : 0,
            "nameUIC" : "Pavilly-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411512,
            "codeCH" : 0,
            "nameUIC" : "Barentin-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411546,
            "codeCH" : "LO",
            "nameUIC" : "Duclair",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 411546,
            "codeCH" : "UC",
            "nameUIC" : "Duclair",
            "nameCH" : "ITE Usines Chimiques",
            "code" : ""
        },
        {
            "codeUIC" : 411546,
            "codeCH" : 13,
            "nameUIC" : "Duclair",
            "nameCH" : "PN 13",
            "code" : ""
        },
        {
            "codeUIC" : 411553,
            "codeCH" : 0,
            "nameUIC" : "Yainville-Jumièges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411579,
            "codeCH" : 0,
            "nameUIC" : "La Mailleraye-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411579,
            "codeCH" : 22,
            "nameUIC" : "La Mailleraye-sur-Seine",
            "nameCH" : "PN 22",
            "code" : ""
        },
        {
            "codeUIC" : 411587,
            "codeCH" : 0,
            "nameUIC" : "Caudebec-en-Caux-Marchandises",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411603,
            "codeCH" : 0,
            "nameUIC" : "Grémonville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411611,
            "codeCH" : 0,
            "nameUIC" : "Doudeville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411629,
            "codeCH" : "AE",
            "nameUIC" : "St-Vaast-Bosville",
            "nameCH" : "Aiguille E",
            "code" : ""
        },
        {
            "codeUIC" : 411629,
            "codeCH" : "BV",
            "nameUIC" : "St-Vaast-Bosville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 411637,
            "codeCH" : 0,
            "nameUIC" : "Ocqueville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411645,
            "codeCH" : 0,
            "nameUIC" : "Néville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411652,
            "codeCH" : "BV",
            "nameUIC" : "St-Valéry-en-Caux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SVY"
        },
        {
            "codeUIC" : 411652,
            "codeCH" : "SB",
            "nameUIC" : "St-Valéry-en-Caux",
            "nameCH" : "ITE EDF",
            "code" : "SVY"
        },
        {
            "codeUIC" : 411728,
            "codeCH" : 0,
            "nameUIC" : "St-Ouen-du-Breuil",
            "nameCH" : "",
            "code" : "SOB"
        },
        {
            "codeUIC" : 411801,
            "codeCH" : 0,
            "nameUIC" : "Montville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411819,
            "codeCH" : "BV",
            "nameUIC" : "Clères",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 411819,
            "codeCH" : 0,
            "nameUIC" : "Clères",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 411819,
            "codeCH" : 16,
            "nameUIC" : "Clères",
            "nameCH" : "Carré 116",
            "code" : ""
        },
        {
            "codeUIC" : 411926,
            "codeCH" : "BG",
            "nameUIC" : "Rouen-Rive-Gauche (IE)",
            "nameCH" : "Bifurcation de Grammont",
            "code" : ""
        },
        {
            "codeUIC" : 411926,
            "codeCH" : "BV",
            "nameUIC" : "Rouen-Rive-Gauche (IE)",
            "nameCH" : "Rouen-St-Sever",
            "code" : ""
        },
        {
            "codeUIC" : 411926,
            "codeCH" : "PC",
            "nameUIC" : "Rouen-Rive-Gauche (IE)",
            "nameCH" : "Poste Central",
            "code" : ""
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "AD",
            "nameUIC" : "Le Havre",
            "nameCH" : "Accès Dépôt",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "BG",
            "nameUIC" : "Le Havre",
            "nameCH" : "Bifurcation Gonfreville",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "BH",
            "nameUIC" : "Le Havre",
            "nameCH" : "Bifurcation du Havre",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "BQ",
            "nameUIC" : "Le Havre",
            "nameCH" : "Bifurcation Breque",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "BV",
            "nameUIC" : "Le Havre",
            "nameCH" : "Gare Locale",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "DP",
            "nameUIC" : "Le Havre",
            "nameCH" : "Dépôt",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "EC",
            "nameUIC" : "Le Havre",
            "nameCH" : "ITE Cofaz",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "EL",
            "nameUIC" : "Le Havre",
            "nameCH" : "ITE Lafarge",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "EN",
            "nameUIC" : "Le Havre",
            "nameCH" : "ITE Le Nickel",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "ES",
            "nameUIC" : "Le Havre",
            "nameCH" : "ITE Sogestrol",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "FA",
            "nameUIC" : "Le Havre",
            "nameCH" : "Faisceau Alluvionnaire",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "FD",
            "nameUIC" : "Le Havre",
            "nameCH" : "Faisceau de La Darse",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "GL",
            "nameUIC" : "Le Havre",
            "nameCH" : "ITE Good-Year-Lubrizol",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "HB",
            "nameUIC" : "Le Havre",
            "nameCH" : "Bifurcation d'Harfleur",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "MM",
            "nameUIC" : "Le Havre",
            "nameCH" : "Gare maritime",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "PD",
            "nameUIC" : "Le Havre",
            "nameCH" : "Poste D",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "PM",
            "nameUIC" : "Le Havre",
            "nameCH" : "Poste Multivrac",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "P2",
            "nameUIC" : "Le Havre",
            "nameCH" : "Poste 2",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "RS",
            "nameUIC" : "Le Havre",
            "nameCH" : "ITE RNUR SARH",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "SO",
            "nameUIC" : "Le Havre",
            "nameCH" : "Soquence Marchandises",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 1,
            "nameUIC" : "Le Havre",
            "nameCH" : "Carré 1327",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 2,
            "nameUIC" : "Le Havre",
            "nameCH" : "Carré 1312",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "1R",
            "nameUIC" : "Le Havre",
            "nameCH" : "Poste 1R",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "2R",
            "nameUIC" : "Le Havre",
            "nameCH" : "Poste 2R",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 20,
            "nameUIC" : "Le Havre",
            "nameCH" : "Accès Port 2000",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 21,
            "nameUIC" : "Le Havre",
            "nameCH" : "Carré Violet 121",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : "3R",
            "nameUIC" : "Le Havre",
            "nameCH" : "Poste 3R",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 74,
            "nameUIC" : "Le Havre",
            "nameCH" : "Carré 174",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 75,
            "nameUIC" : "Le Havre",
            "nameCH" : "Carré 175",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 82,
            "nameUIC" : "Le Havre",
            "nameCH" : "Carré 182",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413013,
            "codeCH" : 84,
            "nameUIC" : "Le Havre",
            "nameCH" : "Carré 184",
            "code" : "LEH"
        },
        {
            "codeUIC" : 413203,
            "codeCH" : "RO",
            "nameUIC" : "Le Havre-Graville (IE)",
            "nameCH" : "Raccordement Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 413203,
            "codeCH" : 0,
            "nameUIC" : "Le Havre-Graville (IE)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413302,
            "codeCH" : 0,
            "nameUIC" : "Harfleur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413310,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-Gainneville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413328,
            "codeCH" : 0,
            "nameUIC" : "Étainhus-St-Romain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413336,
            "codeCH" : 0,
            "nameUIC" : "Virville-Manneville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413344,
            "codeCH" : "AG",
            "nameUIC" : "Bréauté-Beuzeville",
            "nameCH" : "Entrée/Sortie Garages",
            "code" : "BTB"
        },
        {
            "codeUIC" : 413344,
            "codeCH" : "BV",
            "nameUIC" : "Bréauté-Beuzeville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BTB"
        },
        {
            "codeUIC" : 413344,
            "codeCH" : "ES",
            "nameUIC" : "Bréauté-Beuzeville",
            "nameCH" : "Entrée/Sortie VUT Km 200,9",
            "code" : "BTB"
        },
        {
            "codeUIC" : 413344,
            "codeCH" : "MS",
            "nameUIC" : "Bréauté-Beuzeville",
            "nameCH" : "Marchandises",
            "code" : "BTB"
        },
        {
            "codeUIC" : 413344,
            "codeCH" : "P1",
            "nameUIC" : "Bréauté-Beuzeville",
            "nameCH" : "Poste 1",
            "code" : "BTB"
        },
        {
            "codeUIC" : 413351,
            "codeCH" : 0,
            "nameUIC" : "Bolbec-Nointot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413377,
            "codeCH" : "BV",
            "nameUIC" : "Foucart-Alvimare",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 413377,
            "codeCH" : "ES",
            "nameUIC" : "Foucart-Alvimare",
            "nameCH" : "Entrée/Sortie VUT Km 189,9",
            "code" : ""
        },
        {
            "codeUIC" : 413385,
            "codeCH" : "BV",
            "nameUIC" : "Yvetot",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 413385,
            "codeCH" : "ES",
            "nameUIC" : "Yvetot",
            "nameCH" : "Entrée/Sortie VUT Km 176,0",
            "code" : ""
        },
        {
            "codeUIC" : 413419,
            "codeCH" : 0,
            "nameUIC" : "Bolbec-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413484,
            "codeCH" : 0,
            "nameUIC" : "Gravenchon-Port-Jérome",
            "nameCH" : "",
            "code" : "GPJ"
        },
        {
            "codeUIC" : 413526,
            "codeCH" : 0,
            "nameUIC" : "Grainville-Ymauville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413534,
            "codeCH" : 0,
            "nameUIC" : "Les Ifs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413542,
            "codeCH" : 0,
            "nameUIC" : "Fécamp",
            "nameCH" : "",
            "code" : "FEC"
        },
        {
            "codeUIC" : 413708,
            "codeCH" : 0,
            "nameUIC" : "Harfleur-Halte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413716,
            "codeCH" : 0,
            "nameUIC" : "Jacques-Monod-La Demi-Lieue",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413724,
            "codeCH" : 0,
            "nameUIC" : "Montivilliers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413732,
            "codeCH" : 0,
            "nameUIC" : "Épouville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 413740,
            "codeCH" : 0,
            "nameUIC" : "Rolleville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 414110,
            "codeCH" : 0,
            "nameUIC" : "Tunnel Bavat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415018,
            "codeCH" : "BR",
            "nameUIC" : "Dieppe",
            "nameCH" : "Bifurcation de Rouen",
            "code" : "DIP"
        },
        {
            "codeUIC" : 415018,
            "codeCH" : "BV",
            "nameUIC" : "Dieppe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DIP"
        },
        {
            "codeUIC" : 415034,
            "codeCH" : "PA",
            "nameUIC" : "Rouxmesnil",
            "nameCH" : "Pancarte Arrêt",
            "code" : "RMN"
        },
        {
            "codeUIC" : 415034,
            "codeCH" : "TR",
            "nameUIC" : "Rouxmesnil",
            "nameCH" : "Triage",
            "code" : "RMN"
        },
        {
            "codeUIC" : 415042,
            "codeCH" : "BV",
            "nameUIC" : "Arques-la-Bataille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ARB"
        },
        {
            "codeUIC" : 415042,
            "codeCH" : "EP",
            "nameUIC" : "Arques-la-Bataille",
            "nameCH" : "ITE Lefèbvre",
            "code" : "ARB"
        },
        {
            "codeUIC" : 415042,
            "codeCH" : 0,
            "nameUIC" : "Arques-la-Bataille",
            "nameCH" : "",
            "code" : "ARB"
        },
        {
            "codeUIC" : 415059,
            "codeCH" : 0,
            "nameUIC" : "Dampierre-St-Nicolas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415067,
            "codeCH" : 0,
            "nameUIC" : "St-Vaast-d'Équiqueville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415075,
            "codeCH" : 0,
            "nameUIC" : "Bures-en-Bray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415083,
            "codeCH" : 0,
            "nameUIC" : "Mesnières-en-Bray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415091,
            "codeCH" : 0,
            "nameUIC" : "Neufchâtel-en-Bray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415109,
            "codeCH" : 0,
            "nameUIC" : "Nesle-St-Saire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415117,
            "codeCH" : 0,
            "nameUIC" : "Petit-Appeville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415125,
            "codeCH" : 0,
            "nameUIC" : "St-Aubin-sur-Scie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415133,
            "codeCH" : 0,
            "nameUIC" : "Anneville-sur-Scie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415141,
            "codeCH" : 0,
            "nameUIC" : "Longueville-sur-Scie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415158,
            "codeCH" : 0,
            "nameUIC" : "Auffay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415166,
            "codeCH" : 0,
            "nameUIC" : "St-Victor",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415216,
            "codeCH" : "EP",
            "nameUIC" : "St-Pierre-le-Viger-Fontaine-le",
            "nameCH" : "ITE Safba",
            "code" : "SPVFD"
        },
        {
            "codeUIC" : 415257,
            "codeCH" : "BV",
            "nameUIC" : "Envermeu",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 415257,
            "codeCH" : "EP",
            "nameUIC" : "Envermeu",
            "nameCH" : "ITE EDF",
            "code" : ""
        },
        {
            "codeUIC" : 415307,
            "codeCH" : 0,
            "nameUIC" : "Orival",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415323,
            "codeCH" : 0,
            "nameUIC" : "Bourgtheroulde-Thuit-Hébert",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415349,
            "codeCH" : "TR",
            "nameUIC" : "Glos-Montfort",
            "nameCH" : "Entrée/Sortie Triage",
            "code" : "GLO"
        },
        {
            "codeUIC" : 415349,
            "codeCH" : 0,
            "nameUIC" : "Glos-Montfort",
            "nameCH" : "",
            "code" : "GLO"
        },
        {
            "codeUIC" : 415364,
            "codeCH" : 0,
            "nameUIC" : "Brionne (Eure)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415372,
            "codeCH" : 0,
            "nameUIC" : "La Rivière-Thibouville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415448,
            "codeCH" : 0,
            "nameUIC" : "Montfort-St-Philbert",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415489,
            "codeCH" : "BV",
            "nameUIC" : "Pont-Audemer",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PTA"
        },
        {
            "codeUIC" : 415489,
            "codeCH" : "EP",
            "nameUIC" : "Pont-Audemer",
            "nameCH" : "ITE Ahlstrom Dalle",
            "code" : "PTA"
        },
        {
            "codeUIC" : 415505,
            "codeCH" : "EP",
            "nameUIC" : "Beuzeville (Eure)",
            "nameCH" : "ITE Système U",
            "code" : "BZL"
        },
        {
            "codeUIC" : 415505,
            "codeCH" : 0,
            "nameUIC" : "Beuzeville (Eure)",
            "nameCH" : "",
            "code" : "BZL"
        },
        {
            "codeUIC" : 415604,
            "codeCH" : "BV",
            "nameUIC" : "Vernon (Eure)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 415604,
            "codeCH" : "PR",
            "nameUIC" : "Vernon (Eure)",
            "nameCH" : "Pancarte Arrêt Rever V3 Garant par Refoulement",
            "code" : ""
        },
        {
            "codeUIC" : 415604,
            "codeCH" : "P1",
            "nameUIC" : "Vernon (Eure)",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 415604,
            "codeCH" : "QE",
            "nameUIC" : "Vernon (Eure)",
            "nameCH" : "Quai Embarquement",
            "code" : ""
        },
        {
            "codeUIC" : 415604,
            "codeCH" : "V4",
            "nameUIC" : "Vernon (Eure)",
            "nameCH" : "Entrée Voie 4",
            "code" : ""
        },
        {
            "codeUIC" : 415620,
            "codeCH" : "BV",
            "nameUIC" : "Gaillon-Aubevoye",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GAA"
        },
        {
            "codeUIC" : 415620,
            "codeCH" : "EA",
            "nameUIC" : "Gaillon-Aubevoye",
            "nameCH" : "ITE L'AVEBENE",
            "code" : "GAA"
        },
        {
            "codeUIC" : 415620,
            "codeCH" : "EH",
            "nameUIC" : "Gaillon-Aubevoye",
            "nameCH" : "ITE CFPI",
            "code" : "GAA"
        },
        {
            "codeUIC" : 415620,
            "codeCH" : "ES",
            "nameUIC" : "Gaillon-Aubevoye",
            "nameCH" : "ITE Sandoz",
            "code" : "GAA"
        },
        {
            "codeUIC" : 415620,
            "codeCH" : "P1",
            "nameUIC" : "Gaillon-Aubevoye",
            "nameCH" : "Poste 1",
            "code" : "GAA"
        },
        {
            "codeUIC" : 415638,
            "codeCH" : "JG",
            "nameUIC" : "Venables",
            "nameCH" : "Jonction TCT",
            "code" : ""
        },
        {
            "codeUIC" : 415638,
            "codeCH" : "JP",
            "nameUIC" : "Venables",
            "nameCH" : "Jonction TCT",
            "code" : ""
        },
        {
            "codeUIC" : 415646,
            "codeCH" : "BV",
            "nameUIC" : "St-Pierre-du-Vauvray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SPV"
        },
        {
            "codeUIC" : 415646,
            "codeCH" : "RB",
            "nameUIC" : "St-Pierre-du-Vauvray",
            "nameCH" : "Rebroussement",
            "code" : "SPV"
        },
        {
            "codeUIC" : 415646,
            "codeCH" : 3,
            "nameUIC" : "St-Pierre-du-Vauvray",
            "nameCH" : "PN 3",
            "code" : "SPV"
        },
        {
            "codeUIC" : 415661,
            "codeCH" : "BV",
            "nameUIC" : "Pont-de-l'Arche",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PAR"
        },
        {
            "codeUIC" : 415661,
            "codeCH" : "FC",
            "nameUIC" : "Pont-de-l'Arche",
            "nameCH" : "Faisceau Plaine",
            "code" : "PAR"
        },
        {
            "codeUIC" : 415679,
            "codeCH" : 0,
            "nameUIC" : "Port-Villez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415711,
            "codeCH" : 0,
            "nameUIC" : "Pont-St-Pierre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415737,
            "codeCH" : 0,
            "nameUIC" : "Fleury-sur-Andelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415745,
            "codeCH" : 0,
            "nameUIC" : "Charleval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415802,
            "codeCH" : 0,
            "nameUIC" : "Ménesqueville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415810,
            "codeCH" : 0,
            "nameUIC" : "Saussay-Écouis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415828,
            "codeCH" : "BV",
            "nameUIC" : "Étrépagny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ETR"
        },
        {
            "codeUIC" : 415828,
            "codeCH" : "EP",
            "nameUIC" : "Étrépagny",
            "nameCH" : "ITE GEN Sucrerie",
            "code" : "ETR"
        },
        {
            "codeUIC" : 415851,
            "codeCH" : "BV",
            "nameUIC" : "Louviers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 415851,
            "codeCH" : "EU",
            "nameUIC" : "Louviers",
            "nameCH" : "ITE Eurarma",
            "code" : ""
        },
        {
            "codeUIC" : 415851,
            "codeCH" : 0,
            "nameUIC" : "Louviers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 415851,
            "codeCH" : 64,
            "nameUIC" : "Louviers",
            "nameCH" : "PN 64",
            "code" : ""
        },
        {
            "codeUIC" : 415877,
            "codeCH" : "BV",
            "nameUIC" : "Val-de-Reuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 415877,
            "codeCH" : "EH",
            "nameUIC" : "Val-de-Reuil",
            "nameCH" : "ITE Herouard",
            "code" : ""
        },
        {
            "codeUIC" : 415885,
            "codeCH" : 0,
            "nameUIC" : "Rosny-sur-Seine",
            "nameCH" : "",
            "code" : "Rsur-SU"
        },
        {
            "codeUIC" : 415893,
            "codeCH" : "BV",
            "nameUIC" : "Bonnières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BNR"
        },
        {
            "codeUIC" : 415893,
            "codeCH" : "ES",
            "nameUIC" : "Bonnières",
            "nameCH" : "ITE Singer",
            "code" : "BNR"
        },
        {
            "codeUIC" : 417444,
            "codeCH" : 0,
            "nameUIC" : "Reims-Franchet-d'Espèrey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 417451,
            "codeCH" : 0,
            "nameUIC" : "Beine-Farman",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 417501,
            "codeCH" : 0,
            "nameUIC" : "Lancey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 419002,
            "codeCH" : "EP",
            "nameUIC" : "Acquigny",
            "nameCH" : "ITE GEORGIA PACIFIC",
            "code" : ""
        },
        {
            "codeUIC" : 419002,
            "codeCH" : "LO",
            "nameUIC" : "Acquigny",
            "nameCH" : "Gare",
            "code" : ""
        },
        {
            "codeUIC" : 419036,
            "codeCH" : "EP",
            "nameUIC" : "Hondouville",
            "nameCH" : "ITE GEORGIA PACIFIC",
            "code" : ""
        },
        {
            "codeUIC" : 421065,
            "codeCH" : 0,
            "nameUIC" : "ITE SEAU",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 421073,
            "codeCH" : 0,
            "nameUIC" : "ITE SO FO EST",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 421354,
            "codeCH" : "E2",
            "nameUIC" : "Les Relais",
            "nameCH" : "Entrée IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 421354,
            "codeCH" : "S1",
            "nameUIC" : "Les Relais",
            "nameCH" : "Sortie IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 423905,
            "codeCH" : 0,
            "nameUIC" : "ITE Granulats-de-La Crau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 431791,
            "codeCH" : 0,
            "nameUIC" : "Rougemont-Chanteloup",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 437798,
            "codeCH" : 0,
            "nameUIC" : "La Rochelle-Porte-Dauphine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 437806,
            "codeCH" : 0,
            "nameUIC" : "Aytré-Plage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 438218,
            "codeCH" : "C1",
            "nameUIC" : "Chamiers",
            "nameCH" : "Carré 1",
            "code" : ""
        },
        {
            "codeUIC" : 438218,
            "codeCH" : "C4",
            "nameUIC" : "Chamiers",
            "nameCH" : "Carré 4",
            "code" : ""
        },
        {
            "codeUIC" : 438218,
            "codeCH" : "HL",
            "nameUIC" : "Chamiers",
            "nameCH" : "Halte",
            "code" : ""
        },
        {
            "codeUIC" : 439554,
            "codeCH" : 0,
            "nameUIC" : "Vitrolles-Aéroport-Marseille-P",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 441063,
            "codeCH" : 0,
            "nameUIC" : "Quetteville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 441436,
            "codeCH" : "EP",
            "nameUIC" : "Fos-Mole-Central ITE CUMPM",
            "nameCH" : "ITE EVERE",
            "code" : ""
        },
        {
            "codeUIC" : 441535,
            "codeCH" : "EP",
            "nameUIC" : "Miramas ITE SMEA",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444000,
            "codeCH" : "BC",
            "nameUIC" : "Caen",
            "nameCH" : "Bifurcation de Courseulles",
            "code" : "CN"
        },
        {
            "codeUIC" : 444000,
            "codeCH" : "BS",
            "nameUIC" : "Caen",
            "nameCH" : "Bel IPCS Km 227,6",
            "code" : "CN"
        },
        {
            "codeUIC" : 444000,
            "codeCH" : "BV",
            "nameUIC" : "Caen",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CN"
        },
        {
            "codeUIC" : 444000,
            "codeCH" : "JI",
            "nameUIC" : "Caen",
            "nameCH" : "Jonction IPCS Km 238,1",
            "code" : "CN"
        },
        {
            "codeUIC" : 444000,
            "codeCH" : "JS",
            "nameUIC" : "Caen",
            "nameCH" : "Jonction IPCS Km 239,4",
            "code" : "CN"
        },
        {
            "codeUIC" : 444042,
            "codeCH" : 0,
            "nameUIC" : "Bretteville-Norrey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444059,
            "codeCH" : 0,
            "nameUIC" : "Audrieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444067,
            "codeCH" : 0,
            "nameUIC" : "Bayeux",
            "nameCH" : "",
            "code" : "BAY"
        },
        {
            "codeUIC" : 444083,
            "codeCH" : 0,
            "nameUIC" : "Le Molay-Littry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444141,
            "codeCH" : 0,
            "nameUIC" : "Feuguerolles-St-André",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444182,
            "codeCH" : 0,
            "nameUIC" : "Frénouville-Cagny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444190,
            "codeCH" : "BV",
            "nameUIC" : "Moult-Argences",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MTA"
        },
        {
            "codeUIC" : 444208,
            "codeCH" : "BV",
            "nameUIC" : "Mézidon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MEZ"
        },
        {
            "codeUIC" : 444208,
            "codeCH" : "ER",
            "nameUIC" : "Mézidon",
            "nameCH" : "Rac de Mézidon Entrée/Sortie",
            "code" : "MEZ"
        },
        {
            "codeUIC" : 444208,
            "codeCH" : "JI",
            "nameUIC" : "Mézidon",
            "nameCH" : "Jonction IPCS Km 214,2",
            "code" : "MEZ"
        },
        {
            "codeUIC" : 444208,
            "codeCH" : "JS",
            "nameUIC" : "Mézidon",
            "nameCH" : "Jonction IPCS Km 216,6",
            "code" : "MEZ"
        },
        {
            "codeUIC" : 444208,
            "codeCH" : "RM",
            "nameUIC" : "Mézidon",
            "nameCH" : "Raccordement de Mézidon",
            "code" : "MEZ"
        },
        {
            "codeUIC" : 444208,
            "codeCH" : "TR",
            "nameUIC" : "Mézidon",
            "nameCH" : "Triage",
            "code" : "MEZ"
        },
        {
            "codeUIC" : 444216,
            "codeCH" : 0,
            "nameUIC" : "St-Pierre-sur-Dives",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444224,
            "codeCH" : 0,
            "nameUIC" : "Vendeuvre-Jort",
            "nameCH" : "",
            "code" : "VEJ"
        },
        {
            "codeUIC" : 444232,
            "codeCH" : 0,
            "nameUIC" : "Couliboeuf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "BT",
            "nameUIC" : "Lisieux",
            "nameCH" : "Bifurcation de Trouville",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "BV",
            "nameUIC" : "Lisieux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "CI",
            "nameUIC" : "Lisieux",
            "nameCH" : "Jonction IPCS Km 158,5",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "CV",
            "nameUIC" : "Lisieux",
            "nameCH" : "Carré Violet 115",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "EB",
            "nameUIC" : "Lisieux",
            "nameCH" : "Sortie Voie V2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "EI",
            "nameUIC" : "Lisieux",
            "nameCH" : "Entrée Évitement impair",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "IC",
            "nameUIC" : "Lisieux",
            "nameCH" : "Jonction IPCS Km 157,4",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "IS",
            "nameUIC" : "Lisieux",
            "nameCH" : "Jonction IPCS Km 190,5",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "JS",
            "nameUIC" : "Lisieux",
            "nameCH" : "Jonction IPCS Km 189,2",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "LE",
            "nameUIC" : "Lisieux",
            "nameCH" : "Lecaude Jonction IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "PC",
            "nameUIC" : "Lisieux",
            "nameCH" : "St-Paul Jonction IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "SB",
            "nameUIC" : "Lisieux",
            "nameCH" : "Entrée Voie V2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "SC",
            "nameUIC" : "Lisieux",
            "nameCH" : "Carrés 205/207",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "SI",
            "nameUIC" : "Lisieux",
            "nameCH" : "Sortie Évitement impair",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "SP",
            "nameUIC" : "Lisieux",
            "nameCH" : "Carrés 204/206",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "S1",
            "nameUIC" : "Lisieux",
            "nameCH" : "Sémaphore 201",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "S2",
            "nameUIC" : "Lisieux",
            "nameCH" : "Carré 202",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "S3",
            "nameUIC" : "Lisieux",
            "nameCH" : "Carré 203",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "S4",
            "nameUIC" : "Lisieux",
            "nameCH" : "Carré 154",
            "code" : ""
        },
        {
            "codeUIC" : 444265,
            "codeCH" : "S8",
            "nameUIC" : "Lisieux",
            "nameCH" : "Carré 208",
            "code" : ""
        },
        {
            "codeUIC" : 444299,
            "codeCH" : "BV",
            "nameUIC" : "Bernay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 444315,
            "codeCH" : "BR",
            "nameUIC" : "Serquigny",
            "nameCH" : "Bifurcation Rouen-IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 444315,
            "codeCH" : "BV",
            "nameUIC" : "Serquigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 444315,
            "codeCH" : "JS",
            "nameUIC" : "Serquigny",
            "nameCH" : "Jonction IPCS Km 147,2",
            "code" : ""
        },
        {
            "codeUIC" : 444315,
            "codeCH" : "P5",
            "nameUIC" : "Serquigny",
            "nameCH" : "Raccordement de Paris",
            "code" : ""
        },
        {
            "codeUIC" : 444315,
            "codeCH" : "RR",
            "nameUIC" : "Serquigny",
            "nameCH" : "Raccordement Rouen-IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 444315,
            "codeCH" : 32,
            "nameUIC" : "Serquigny",
            "nameCH" : "Carré Violet 32",
            "code" : ""
        },
        {
            "codeUIC" : 444323,
            "codeCH" : 0,
            "nameUIC" : "Le Grand-Jardin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444331,
            "codeCH" : 0,
            "nameUIC" : "Le Breuil-en-Auge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444349,
            "codeCH" : 0,
            "nameUIC" : "Pont-l'Évêque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444372,
            "codeCH" : "S0",
            "nameUIC" : "Trouville-Deauville",
            "nameCH" : "Carré 308",
            "code" : ""
        },
        {
            "codeUIC" : 444372,
            "codeCH" : "S3",
            "nameUIC" : "Trouville-Deauville",
            "nameCH" : "Carré 303",
            "code" : ""
        },
        {
            "codeUIC" : 444372,
            "codeCH" : 0,
            "nameUIC" : "Trouville-Deauville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444380,
            "codeCH" : 0,
            "nameUIC" : "Blonville-sur-Mer-Benerville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444398,
            "codeCH" : 0,
            "nameUIC" : "Villers-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444406,
            "codeCH" : 0,
            "nameUIC" : "Houlgate",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444414,
            "codeCH" : 0,
            "nameUIC" : "Dives-Cabourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444430,
            "codeCH" : "BS",
            "nameUIC" : "Honfleur",
            "nameCH" : "Bâtiment Service",
            "code" : ""
        },
        {
            "codeUIC" : 444430,
            "codeCH" : 0,
            "nameUIC" : "Honfleur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444521,
            "codeCH" : 0,
            "nameUIC" : "Montabard",
            "nameCH" : "",
            "code" : "MOB"
        },
        {
            "codeUIC" : 444539,
            "codeCH" : "BA",
            "nameUIC" : "Argentan",
            "nameCH" : "Bifurcation de Ste-Anne",
            "code" : "AR"
        },
        {
            "codeUIC" : 444539,
            "codeCH" : "BV",
            "nameUIC" : "Argentan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AR"
        },
        {
            "codeUIC" : 444539,
            "codeCH" : "C1",
            "nameUIC" : "Argentan",
            "nameCH" : "Carré 101",
            "code" : "AR"
        },
        {
            "codeUIC" : 444539,
            "codeCH" : "C2",
            "nameUIC" : "Argentan",
            "nameCH" : "Carré 102",
            "code" : "AR"
        },
        {
            "codeUIC" : 444539,
            "codeCH" : "S8",
            "nameUIC" : "Argentan",
            "nameCH" : "Sémaphore 98",
            "code" : "AR"
        },
        {
            "codeUIC" : 444539,
            "codeCH" : "S9",
            "nameUIC" : "Argentan",
            "nameCH" : "Sémaphore 99",
            "code" : "AR"
        },
        {
            "codeUIC" : 444547,
            "codeCH" : "BV",
            "nameUIC" : "Écouché",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 444547,
            "codeCH" : "SI",
            "nameUIC" : "Écouché",
            "nameCH" : "Sémaphore 147",
            "code" : ""
        },
        {
            "codeUIC" : 444547,
            "codeCH" : "SP",
            "nameUIC" : "Écouché",
            "nameCH" : "Sémaphore 148",
            "code" : ""
        },
        {
            "codeUIC" : 444562,
            "codeCH" : "BV",
            "nameUIC" : "Surdon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SDN"
        },
        {
            "codeUIC" : 444562,
            "codeCH" : "C3",
            "nameUIC" : "Surdon",
            "nameCH" : "Carré 83",
            "code" : "SDN"
        },
        {
            "codeUIC" : 444562,
            "codeCH" : "C5",
            "nameUIC" : "Surdon",
            "nameCH" : "Carré 95",
            "code" : "SDN"
        },
        {
            "codeUIC" : 444562,
            "codeCH" : "C6",
            "nameUIC" : "Surdon",
            "nameCH" : "Carré 96",
            "code" : "SDN"
        },
        {
            "codeUIC" : 444562,
            "codeCH" : 80,
            "nameUIC" : "Surdon",
            "nameCH" : "Carré 80",
            "code" : "SDN"
        },
        {
            "codeUIC" : 444562,
            "codeCH" : 85,
            "nameUIC" : "Surdon",
            "nameCH" : "Carré 85",
            "code" : "SDN"
        },
        {
            "codeUIC" : 444570,
            "codeCH" : 0,
            "nameUIC" : "Nonant-le-Pin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444588,
            "codeCH" : "SI",
            "nameUIC" : "Le Merlerault",
            "nameCH" : "Sémaphore 65",
            "code" : ""
        },
        {
            "codeUIC" : 444588,
            "codeCH" : "SP",
            "nameUIC" : "Le Merlerault",
            "nameCH" : "Sémaphore 68",
            "code" : ""
        },
        {
            "codeUIC" : 444588,
            "codeCH" : 0,
            "nameUIC" : "Le Merlerault",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444604,
            "codeCH" : "SI",
            "nameUIC" : "Ste-Gauburge",
            "nameCH" : "Sémaphore 63",
            "code" : ""
        },
        {
            "codeUIC" : 444604,
            "codeCH" : "SP",
            "nameUIC" : "Ste-Gauburge",
            "nameCH" : "Sémaphore 66",
            "code" : ""
        },
        {
            "codeUIC" : 444604,
            "codeCH" : 0,
            "nameUIC" : "Ste-Gauburge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444620,
            "codeCH" : 0,
            "nameUIC" : "Rai-Aube",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444638,
            "codeCH" : "BV",
            "nameUIC" : "L'Aigle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 444638,
            "codeCH" : "CP",
            "nameUIC" : "L'Aigle",
            "nameCH" : "Carré 62",
            "code" : ""
        },
        {
            "codeUIC" : 444638,
            "codeCH" : "SP",
            "nameUIC" : "L'Aigle",
            "nameCH" : "Sémaphore 52",
            "code" : ""
        },
        {
            "codeUIC" : 444653,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-d'Écublei",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444695,
            "codeCH" : 0,
            "nameUIC" : "Sées",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444703,
            "codeCH" : 0,
            "nameUIC" : "Vingt-Hanaps",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444711,
            "codeCH" : "BV",
            "nameUIC" : "Alençon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ALN"
        },
        {
            "codeUIC" : 444711,
            "codeCH" : 42,
            "nameUIC" : "Alençon",
            "nameCH" : "Carré 42",
            "code" : "ALN"
        },
        {
            "codeUIC" : 444711,
            "codeCH" : 51,
            "nameUIC" : "Alençon",
            "nameCH" : "Sémaphore 51",
            "code" : "ALN"
        },
        {
            "codeUIC" : 444737,
            "codeCH" : 0,
            "nameUIC" : "St-Denis-sur-Sarthon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444745,
            "codeCH" : 0,
            "nameUIC" : "La Lacelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444752,
            "codeCH" : "EP",
            "nameUIC" : "Pré-en-Pail",
            "nameCH" : "ITE Masdac",
            "code" : "PRE"
        },
        {
            "codeUIC" : 444869,
            "codeCH" : 0,
            "nameUIC" : "Vignats",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 444877,
            "codeCH" : "BV",
            "nameUIC" : "Cherbourg",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CBU"
        },
        {
            "codeUIC" : 444877,
            "codeCH" : "MA",
            "nameUIC" : "Cherbourg",
            "nameCH" : "Cherbourg-Maritime",
            "code" : "CBU"
        },
        {
            "codeUIC" : 444877,
            "codeCH" : "P1",
            "nameUIC" : "Cherbourg",
            "nameCH" : "Bifurcation du Raccordement Maritime",
            "code" : "CBU"
        },
        {
            "codeUIC" : 446179,
            "codeCH" : 0,
            "nameUIC" : "St-Cyprien-Arènes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447003,
            "codeCH" : 0,
            "nameUIC" : "St-Lô",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447110,
            "codeCH" : 0,
            "nameUIC" : "Coutances",
            "nameCH" : "",
            "code" : "CCS"
        },
        {
            "codeUIC" : 447110,
            "codeCH" : 36,
            "nameUIC" : "Coutances",
            "nameCH" : "Carré 366",
            "code" : "CCS"
        },
        {
            "codeUIC" : 447144,
            "codeCH" : 0,
            "nameUIC" : "Carantilly-Marigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447144,
            "codeCH" : 75,
            "nameUIC" : "Carantilly-Marigny",
            "nameCH" : "Sémaphore 275",
            "code" : ""
        },
        {
            "codeUIC" : 447185,
            "codeCH" : 0,
            "nameUIC" : "Pont-Hébert",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447219,
            "codeCH" : "JI",
            "nameUIC" : "Lison",
            "nameCH" : "Jonction IPCS Km 294,8",
            "code" : ""
        },
        {
            "codeUIC" : 447219,
            "codeCH" : "JS",
            "nameUIC" : "Lison",
            "nameCH" : "Jonction IPCS Km 295,6",
            "code" : ""
        },
        {
            "codeUIC" : 447219,
            "codeCH" : 0,
            "nameUIC" : "Lison",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447243,
            "codeCH" : 0,
            "nameUIC" : "Carentan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447250,
            "codeCH" : 0,
            "nameUIC" : "Chef-du-Pont-Ste-Mère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447284,
            "codeCH" : 0,
            "nameUIC" : "Valognes",
            "nameCH" : "",
            "code" : "VAL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : "BC",
            "nameUIC" : "Folligny",
            "nameCH" : "Bifurcation des Croix-Ros",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : "BV",
            "nameUIC" : "Folligny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : "RN",
            "nameUIC" : "Folligny",
            "nameCH" : "Raccordement Nord",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : "RO",
            "nameUIC" : "Folligny",
            "nameCH" : "Raccordement Ouest",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : 11,
            "nameUIC" : "Folligny",
            "nameCH" : "Carré 263",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : 13,
            "nameUIC" : "Folligny",
            "nameCH" : "Carré 265",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : 37,
            "nameUIC" : "Folligny",
            "nameCH" : "Sémaphores 371 372",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : 81,
            "nameUIC" : "Folligny",
            "nameCH" : "Carré 281",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447649,
            "codeCH" : 82,
            "nameUIC" : "Folligny",
            "nameCH" : "Carré 282",
            "code" : "FOL"
        },
        {
            "codeUIC" : 447680,
            "codeCH" : "BV",
            "nameUIC" : "Granville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GRV"
        },
        {
            "codeUIC" : 447680,
            "codeCH" : "ES",
            "nameUIC" : "Granville",
            "nameCH" : "E/S Lison Granville",
            "code" : "GRV"
        },
        {
            "codeUIC" : 447680,
            "codeCH" : "PF",
            "nameUIC" : "Granville",
            "nameCH" : "Pancarte Arrêt",
            "code" : "GRV"
        },
        {
            "codeUIC" : 447698,
            "codeCH" : 0,
            "nameUIC" : "Villedieu-les-Poêles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 447714,
            "codeCH" : "BV",
            "nameUIC" : "St-Sever (Calvados)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 447714,
            "codeCH" : "SI",
            "nameUIC" : "St-Sever (Calvados)",
            "nameCH" : "Sémaphore 225",
            "code" : ""
        },
        {
            "codeUIC" : 447714,
            "codeCH" : "SP",
            "nameUIC" : "St-Sever (Calvados)",
            "nameCH" : "Sémaphore 226",
            "code" : ""
        },
        {
            "codeUIC" : 448001,
            "codeCH" : "BV",
            "nameUIC" : "Flers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 448084,
            "codeCH" : "BV",
            "nameUIC" : "Briouze",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 448084,
            "codeCH" : "SI",
            "nameUIC" : "Briouze",
            "nameCH" : "Sémaphore 157 et 159",
            "code" : ""
        },
        {
            "codeUIC" : 448084,
            "codeCH" : "SP",
            "nameUIC" : "Briouze",
            "nameCH" : "Sémaphore 158 et 160",
            "code" : ""
        },
        {
            "codeUIC" : 448142,
            "codeCH" : "JO",
            "nameUIC" : "Viessoix",
            "nameCH" : "Jonction",
            "code" : ""
        },
        {
            "codeUIC" : 448142,
            "codeCH" : "SI",
            "nameUIC" : "Viessoix",
            "nameCH" : "Sémaphore 185",
            "code" : ""
        },
        {
            "codeUIC" : 448142,
            "codeCH" : "SP",
            "nameUIC" : "Viessoix",
            "nameCH" : "Sémaphore 186",
            "code" : ""
        },
        {
            "codeUIC" : 448159,
            "codeCH" : "BV",
            "nameUIC" : "Vire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 448514,
            "codeCH" : "S1",
            "nameUIC" : "Avranches",
            "nameCH" : "Carré 130043 et 130041",
            "code" : ""
        },
        {
            "codeUIC" : 448514,
            "codeCH" : "S2",
            "nameUIC" : "Avranches",
            "nameCH" : "Carré 130044",
            "code" : ""
        },
        {
            "codeUIC" : 448514,
            "codeCH" : "S3",
            "nameUIC" : "Avranches",
            "nameCH" : "Sémaphores 1451 et 1452",
            "code" : ""
        },
        {
            "codeUIC" : 448514,
            "codeCH" : "S4",
            "nameUIC" : "Avranches",
            "nameCH" : "Carré 1461",
            "code" : ""
        },
        {
            "codeUIC" : 448514,
            "codeCH" : "S5",
            "nameUIC" : "Avranches",
            "nameCH" : "Carré 1474",
            "code" : ""
        },
        {
            "codeUIC" : 448514,
            "codeCH" : "S6",
            "nameUIC" : "Avranches",
            "nameCH" : "Séùaphores 1481 et 1482",
            "code" : ""
        },
        {
            "codeUIC" : 448514,
            "codeCH" : 0,
            "nameUIC" : "Avranches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 448522,
            "codeCH" : 0,
            "nameUIC" : "Pontaubault",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 449918,
            "codeCH" : 0,
            "nameUIC" : "La Selle-la-Forge (ex Bif)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 462085,
            "codeCH" : 0,
            "nameUIC" : "Chamonix-Aiguille du Midi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "BD",
            "nameUIC" : "Rennes",
            "nameCH" : "Plateau de Baud",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "BR",
            "nameUIC" : "Rennes",
            "nameCH" : "Bifurcation de la Vilaine",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "BT",
            "nameUIC" : "Rennes",
            "nameCH" : "ITE Barre-Thomas",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "BV",
            "nameUIC" : "Rennes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "CH",
            "nameUIC" : "Rennes",
            "nameCH" : "Faisceau Chatillon",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "DP",
            "nameUIC" : "Rennes",
            "nameCH" : "Dépôt",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "EP",
            "nameUIC" : "Rennes",
            "nameCH" : "ITE du Polygone",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "GM",
            "nameUIC" : "Rennes",
            "nameCH" : "Garage Maintenance",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "GV",
            "nameUIC" : "Rennes",
            "nameCH" : "Garage Voyageurs",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "JB",
            "nameUIC" : "Rennes",
            "nameCH" : "Jonction BV",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "J1",
            "nameUIC" : "Rennes",
            "nameCH" : "Accès VCS",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "J3",
            "nameUIC" : "Rennes",
            "nameCH" : "Jonction 3",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "J4",
            "nameUIC" : "Rennes",
            "nameCH" : "Accès V1B/CS",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "J5",
            "nameUIC" : "Rennes",
            "nameCH" : "Sortie V2/CS",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "PC",
            "nameUIC" : "Rennes",
            "nameCH" : "Bifurcation de Port-Cahours",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "PD",
            "nameUIC" : "Rennes",
            "nameCH" : "Poste D",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "P1",
            "nameUIC" : "Rennes",
            "nameCH" : "Poste 1",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "P2",
            "nameUIC" : "Rennes",
            "nameCH" : "Poste 2",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "SG",
            "nameUIC" : "Rennes",
            "nameCH" : "ITE St-Grégoire",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : "SH",
            "nameUIC" : "Rennes",
            "nameCH" : "St-Hélier (ex-Triage)",
            "code" : "REN"
        },
        {
            "codeUIC" : 471003,
            "codeCH" : 13,
            "nameUIC" : "Rennes",
            "nameCH" : "Carrés 131, 133, 135",
            "code" : "REN"
        },
        {
            "codeUIC" : 471029,
            "codeCH" : 0,
            "nameUIC" : "Vern",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471037,
            "codeCH" : "BV",
            "nameUIC" : "Bruz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 471037,
            "codeCH" : "SP",
            "nameUIC" : "Bruz",
            "nameCH" : "Carré 62",
            "code" : ""
        },
        {
            "codeUIC" : 471045,
            "codeCH" : 0,
            "nameUIC" : "Betton",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471052,
            "codeCH" : 0,
            "nameUIC" : "St-Jacques-de-la-Lande",
            "nameCH" : "",
            "code" : "SJL"
        },
        {
            "codeUIC" : 471060,
            "codeCH" : 0,
            "nameUIC" : "L'Hermitage-Mordelles",
            "nameCH" : "",
            "code" : "LHL"
        },
        {
            "codeUIC" : 471078,
            "codeCH" : 0,
            "nameUIC" : "Montfort-sur-Meu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471086,
            "codeCH" : 0,
            "nameUIC" : "Montauban-de-Bretagne",
            "nameCH" : "",
            "code" : "MDT"
        },
        {
            "codeUIC" : 471094,
            "codeCH" : "BV",
            "nameUIC" : "La Brohinière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LBR"
        },
        {
            "codeUIC" : 471094,
            "codeCH" : "EI",
            "nameUIC" : "La Brohinière",
            "nameCH" : "Entrée Évitement Impair",
            "code" : "LBR"
        },
        {
            "codeUIC" : 471094,
            "codeCH" : "SI",
            "nameUIC" : "La Brohinière",
            "nameCH" : "Sortie Évitement Impair",
            "code" : "LBR"
        },
        {
            "codeUIC" : 471102,
            "codeCH" : 0,
            "nameUIC" : "Breteil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471144,
            "codeCH" : "SP",
            "nameUIC" : "Laillé",
            "nameCH" : "Carré 66",
            "code" : ""
        },
        {
            "codeUIC" : 471144,
            "codeCH" : 0,
            "nameUIC" : "Laillé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471151,
            "codeCH" : 0,
            "nameUIC" : "Guichen-Bourg-des-Comptes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471169,
            "codeCH" : 0,
            "nameUIC" : "St-Senoux-Pléchâtel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471177,
            "codeCH" : "SI",
            "nameUIC" : "Pléchâtel",
            "nameCH" : "Sémaphore 69",
            "code" : ""
        },
        {
            "codeUIC" : 471177,
            "codeCH" : "SP",
            "nameUIC" : "Pléchâtel",
            "nameCH" : "Sémaphore 72",
            "code" : ""
        },
        {
            "codeUIC" : 471177,
            "codeCH" : 0,
            "nameUIC" : "Pléchâtel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471185,
            "codeCH" : "SP",
            "nameUIC" : "Messac-Guipry",
            "nameCH" : "Carré 96",
            "code" : ""
        },
        {
            "codeUIC" : 471185,
            "codeCH" : 0,
            "nameUIC" : "Messac-Guipry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471201,
            "codeCH" : "SI",
            "nameUIC" : "Fougeray-Langon",
            "nameCH" : "Sémaphore 125",
            "code" : ""
        },
        {
            "codeUIC" : 471201,
            "codeCH" : "SP",
            "nameUIC" : "Fougeray-Langon",
            "nameCH" : "Sémaphore 126",
            "code" : ""
        },
        {
            "codeUIC" : 471201,
            "codeCH" : 0,
            "nameUIC" : "Fougeray-Langon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471219,
            "codeCH" : "SI",
            "nameUIC" : "Beslé",
            "nameCH" : "Sémaphore 141",
            "code" : ""
        },
        {
            "codeUIC" : 471219,
            "codeCH" : 0,
            "nameUIC" : "Beslé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471227,
            "codeCH" : 0,
            "nameUIC" : "Massérac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471235,
            "codeCH" : "CP",
            "nameUIC" : "Avessac",
            "nameCH" : "Carré 182",
            "code" : ""
        },
        {
            "codeUIC" : 471235,
            "codeCH" : "SC",
            "nameUIC" : "Avessac",
            "nameCH" : "Carré 39,8",
            "code" : ""
        },
        {
            "codeUIC" : 471235,
            "codeCH" : "SI",
            "nameUIC" : "Avessac",
            "nameCH" : "Sémaphore 145",
            "code" : ""
        },
        {
            "codeUIC" : 471235,
            "codeCH" : "SP",
            "nameUIC" : "Avessac",
            "nameCH" : "Sémaphore 146",
            "code" : ""
        },
        {
            "codeUIC" : 471235,
            "codeCH" : 0,
            "nameUIC" : "Avessac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471300,
            "codeCH" : "BI",
            "nameUIC" : "Redon",
            "nameCH" : "Raccordement Côté Rennes",
            "code" : "RDN"
        },
        {
            "codeUIC" : 471300,
            "codeCH" : "BV",
            "nameUIC" : "Redon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RDN"
        },
        {
            "codeUIC" : 471300,
            "codeCH" : "P1",
            "nameUIC" : "Redon",
            "nameCH" : "Raccordement Côté Nantes",
            "code" : "RDN"
        },
        {
            "codeUIC" : 471300,
            "codeCH" : "P2",
            "nameUIC" : "Redon",
            "nameCH" : "Bifurcation",
            "code" : "RDN"
        },
        {
            "codeUIC" : 471300,
            "codeCH" : "SI",
            "nameUIC" : "Redon",
            "nameCH" : "Sémaphore 271",
            "code" : "RDN"
        },
        {
            "codeUIC" : 471300,
            "codeCH" : "SP",
            "nameUIC" : "Redon",
            "nameCH" : "Sémaphore 272",
            "code" : "RDN"
        },
        {
            "codeUIC" : 471318,
            "codeCH" : 0,
            "nameUIC" : "St-Armel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471326,
            "codeCH" : 0,
            "nameUIC" : "Corps-Nuds",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471334,
            "codeCH" : 0,
            "nameUIC" : "Janzé",
            "nameCH" : "",
            "code" : "JZE"
        },
        {
            "codeUIC" : 471342,
            "codeCH" : 0,
            "nameUIC" : "Le Theil-de-Bretagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471359,
            "codeCH" : 0,
            "nameUIC" : "Retiers",
            "nameCH" : "",
            "code" : "RTI"
        },
        {
            "codeUIC" : 471367,
            "codeCH" : 0,
            "nameUIC" : "Martigné-Ferchaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471391,
            "codeCH" : 0,
            "nameUIC" : "Pontchaillou",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471458,
            "codeCH" : 0,
            "nameUIC" : "Chevaigné",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471508,
            "codeCH" : "BV",
            "nameUIC" : "Vitré",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VTE"
        },
        {
            "codeUIC" : 471508,
            "codeCH" : "EI",
            "nameUIC" : "Vitré",
            "nameCH" : "Évitement Entrée/Sortie Km 336,4",
            "code" : "VTE"
        },
        {
            "codeUIC" : 471508,
            "codeCH" : "EV",
            "nameUIC" : "Vitré",
            "nameCH" : "Évitement",
            "code" : "VTE"
        },
        {
            "codeUIC" : 471508,
            "codeCH" : "SI",
            "nameUIC" : "Vitré",
            "nameCH" : "Évitement Entrée/Sortie Km 337,4",
            "code" : "VTE"
        },
        {
            "codeUIC" : 471516,
            "codeCH" : 0,
            "nameUIC" : "Les Lacs",
            "nameCH" : "",
            "code" : "LAC"
        },
        {
            "codeUIC" : 471524,
            "codeCH" : "IP",
            "nameUIC" : "Châteaubourg",
            "nameCH" : "Entrée/Sortie IPCS Km 353",
            "code" : ""
        },
        {
            "codeUIC" : 471524,
            "codeCH" : 0,
            "nameUIC" : "Châteaubourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471532,
            "codeCH" : 0,
            "nameUIC" : "Servon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471540,
            "codeCH" : 0,
            "nameUIC" : "Noyal-Acigné",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471607,
            "codeCH" : "BV",
            "nameUIC" : "Fougères",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 471615,
            "codeCH" : "BV",
            "nameUIC" : "La Selle-en-Luitré",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 471631,
            "codeCH" : 0,
            "nameUIC" : "Dompierre-du-Chemin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471649,
            "codeCH" : 0,
            "nameUIC" : "Châtillon-en-Vendelais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 471664,
            "codeCH" : "EP",
            "nameUIC" : "Gérard",
            "nameCH" : "ITE Cooperl-Hunaudaye",
            "code" : "GRR"
        },
        {
            "codeUIC" : 472605,
            "codeCH" : 0,
            "nameUIC" : "Thann-Centre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 472639,
            "codeCH" : "BV",
            "nameUIC" : "ZU-Rhein",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 472639,
            "codeCH" : "LZ",
            "nameUIC" : "ZU-Rhein",
            "nameCH" : "Limite zone urbaine",
            "code" : ""
        },
        {
            "codeUIC" : 473009,
            "codeCH" : "BV",
            "nameUIC" : "St-Brieuc",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "STB"
        },
        {
            "codeUIC" : 473009,
            "codeCH" : "EP",
            "nameUIC" : "St-Brieuc",
            "nameCH" : "ITE Zone Industrielle des Châtelets",
            "code" : "STB"
        },
        {
            "codeUIC" : 473041,
            "codeCH" : 0,
            "nameUIC" : "Le Légué",
            "nameCH" : "",
            "code" : "LEG"
        },
        {
            "codeUIC" : 473058,
            "codeCH" : 0,
            "nameUIC" : "Quédillac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473066,
            "codeCH" : 0,
            "nameUIC" : "Caulnes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473074,
            "codeCH" : 0,
            "nameUIC" : "Broons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473082,
            "codeCH" : 0,
            "nameUIC" : "Plénée-Jugon",
            "nameCH" : "",
            "code" : "PLJ"
        },
        {
            "codeUIC" : 473090,
            "codeCH" : "CH",
            "nameUIC" : "Plestan",
            "nameCH" : "ITE Cooperl-Hunaudaye",
            "code" : "PLE"
        },
        {
            "codeUIC" : 473090,
            "codeCH" : 0,
            "nameUIC" : "Plestan",
            "nameCH" : "",
            "code" : "PLE"
        },
        {
            "codeUIC" : 473108,
            "codeCH" : "BV",
            "nameUIC" : "Lamballe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LAM"
        },
        {
            "codeUIC" : 473108,
            "codeCH" : "EP",
            "nameUIC" : "Lamballe",
            "nameCH" : "ITE Le Gouessant",
            "code" : "LAM"
        },
        {
            "codeUIC" : 473116,
            "codeCH" : 0,
            "nameUIC" : "Yffiniac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473124,
            "codeCH" : 0,
            "nameUIC" : "La Méaugon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473132,
            "codeCH" : 0,
            "nameUIC" : "Plouvara-Plerneuf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473140,
            "codeCH" : "BV",
            "nameUIC" : "Châtelaudren-Plouagat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDN"
        },
        {
            "codeUIC" : 473140,
            "codeCH" : "EC",
            "nameUIC" : "Châtelaudren-Plouagat",
            "nameCH" : "ITE CAB",
            "code" : "CDN"
        },
        {
            "codeUIC" : 473165,
            "codeCH" : 0,
            "nameUIC" : "Belle-Isle-Bégard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473181,
            "codeCH" : 0,
            "nameUIC" : "Plouaret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473199,
            "codeCH" : 0,
            "nameUIC" : "Plounérin",
            "nameCH" : "",
            "code" : "PNR"
        },
        {
            "codeUIC" : 473207,
            "codeCH" : 0,
            "nameUIC" : "Guingamp",
            "nameCH" : "",
            "code" : "GGP"
        },
        {
            "codeUIC" : 473215,
            "codeCH" : 0,
            "nameUIC" : "Kerauzern",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473223,
            "codeCH" : 0,
            "nameUIC" : "Lannion",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473264,
            "codeCH" : 0,
            "nameUIC" : "Landébia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473272,
            "codeCH" : 0,
            "nameUIC" : "Plancoët",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473314,
            "codeCH" : 0,
            "nameUIC" : "St-Julien (Côtes-d'Armor)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473322,
            "codeCH" : 0,
            "nameUIC" : "Plaintel",
            "nameCH" : "",
            "code" : "PIL"
        },
        {
            "codeUIC" : 473330,
            "codeCH" : "BV",
            "nameUIC" : "Quintin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "QIN"
        },
        {
            "codeUIC" : 473348,
            "codeCH" : 0,
            "nameUIC" : "Le Pas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473355,
            "codeCH" : 0,
            "nameUIC" : "Ploeuc-L'Hermitage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473363,
            "codeCH" : 0,
            "nameUIC" : "Uzel",
            "nameCH" : "",
            "code" : "UZE"
        },
        {
            "codeUIC" : 473371,
            "codeCH" : 0,
            "nameUIC" : "La Motte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473389,
            "codeCH" : "BV",
            "nameUIC" : "Loudéac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LDC"
        },
        {
            "codeUIC" : 473397,
            "codeCH" : 0,
            "nameUIC" : "Hémonstoir-St-Gonnery",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473504,
            "codeCH" : 0,
            "nameUIC" : "Carhaix",
            "nameCH" : "",
            "code" : "CRX"
        },
        {
            "codeUIC" : 473512,
            "codeCH" : 0,
            "nameUIC" : "Moustéru",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473520,
            "codeCH" : 0,
            "nameUIC" : "Pont-Melvez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473538,
            "codeCH" : 0,
            "nameUIC" : "Plougonver",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473546,
            "codeCH" : 0,
            "nameUIC" : "Callac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473553,
            "codeCH" : 0,
            "nameUIC" : "Le Pénity",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473561,
            "codeCH" : 0,
            "nameUIC" : "Carnoët-Locarn",
            "nameCH" : "",
            "code" : "CCN"
        },
        {
            "codeUIC" : 473579,
            "codeCH" : 0,
            "nameUIC" : "Coat-Guégan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473587,
            "codeCH" : 0,
            "nameUIC" : "Les Mais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473801,
            "codeCH" : 0,
            "nameUIC" : "Gourland",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473819,
            "codeCH" : 0,
            "nameUIC" : "Trégonneau-Squiffiec",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473827,
            "codeCH" : 0,
            "nameUIC" : "Brélidy-Plouëc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473835,
            "codeCH" : 0,
            "nameUIC" : "Pontrieux-Halte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473843,
            "codeCH" : 0,
            "nameUIC" : "Pontrieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473850,
            "codeCH" : 0,
            "nameUIC" : "Lancerf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473868,
            "codeCH" : 0,
            "nameUIC" : "Paimpol",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 473876,
            "codeCH" : 0,
            "nameUIC" : "Frynaudour",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474007,
            "codeCH" : "BV",
            "nameUIC" : "Brest",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BT"
        },
        {
            "codeUIC" : 474007,
            "codeCH" : "PT",
            "nameUIC" : "Brest",
            "nameCH" : "Port",
            "code" : "BT"
        },
        {
            "codeUIC" : 474015,
            "codeCH" : 0,
            "nameUIC" : "Dirinon",
            "nameCH" : "",
            "code" : "DIL"
        },
        {
            "codeUIC" : 474031,
            "codeCH" : 0,
            "nameUIC" : "Hanvec",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474049,
            "codeCH" : 0,
            "nameUIC" : "Quimerch",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474056,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Buis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474064,
            "codeCH" : 0,
            "nameUIC" : "Châteaulin-Embranchement",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474080,
            "codeCH" : "BV",
            "nameUIC" : "Quéménéven",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "QMN"
        },
        {
            "codeUIC" : 474080,
            "codeCH" : "UA",
            "nameUIC" : "Quéménéven",
            "nameCH" : "ITE Unicopa",
            "code" : "QMN"
        },
        {
            "codeUIC" : 474098,
            "codeCH" : "BV",
            "nameUIC" : "Quimper",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "QR"
        },
        {
            "codeUIC" : 474098,
            "codeCH" : "EP",
            "nameUIC" : "Quimper",
            "nameCH" : "ITE Pluguffan",
            "code" : "QR"
        },
        {
            "codeUIC" : 474098,
            "codeCH" : "SI",
            "nameUIC" : "Quimper",
            "nameCH" : "Sémaphore 721",
            "code" : "QR"
        },
        {
            "codeUIC" : 474098,
            "codeCH" : "SP",
            "nameUIC" : "Quimper",
            "nameCH" : "Sémaphore 726",
            "code" : "QR"
        },
        {
            "codeUIC" : 474155,
            "codeCH" : "BV",
            "nameUIC" : "Rosporden",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RSP"
        },
        {
            "codeUIC" : 474155,
            "codeCH" : "SI",
            "nameUIC" : "Rosporden",
            "nameCH" : "Sémaphore 691",
            "code" : "RSP"
        },
        {
            "codeUIC" : 474155,
            "codeCH" : "SP",
            "nameUIC" : "Rosporden",
            "nameCH" : "Sémaphore 722",
            "code" : "RSP"
        },
        {
            "codeUIC" : 474155,
            "codeCH" : 0,
            "nameUIC" : "Rosporden",
            "nameCH" : "",
            "code" : "RSP"
        },
        {
            "codeUIC" : 474155,
            "codeCH" : 71,
            "nameUIC" : "Rosporden",
            "nameCH" : "Carré 710",
            "code" : "RSP"
        },
        {
            "codeUIC" : 474189,
            "codeCH" : "BV",
            "nameUIC" : "Bannalec",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BNC"
        },
        {
            "codeUIC" : 474189,
            "codeCH" : "EP",
            "nameUIC" : "Bannalec",
            "nameCH" : "ITE Doux",
            "code" : "BNC"
        },
        {
            "codeUIC" : 474205,
            "codeCH" : 0,
            "nameUIC" : "Le Rody",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474213,
            "codeCH" : "BV",
            "nameUIC" : "Kerhuon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "KHO"
        },
        {
            "codeUIC" : 474213,
            "codeCH" : "EP",
            "nameUIC" : "Kerhuon",
            "nameCH" : "ITE Pyrotechnie St-Nicolas",
            "code" : "KHO"
        },
        {
            "codeUIC" : 474221,
            "codeCH" : 0,
            "nameUIC" : "La Forest",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474239,
            "codeCH" : "BV",
            "nameUIC" : "Landerneau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LDE"
        },
        {
            "codeUIC" : 474262,
            "codeCH" : 0,
            "nameUIC" : "La Roche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474270,
            "codeCH" : 0,
            "nameUIC" : "Landivisiau",
            "nameCH" : "",
            "code" : "LDI"
        },
        {
            "codeUIC" : 474296,
            "codeCH" : 0,
            "nameUIC" : "Guimiliau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474312,
            "codeCH" : 0,
            "nameUIC" : "St-Thégonnec",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474320,
            "codeCH" : 0,
            "nameUIC" : "Pleyber-Christ",
            "nameCH" : "",
            "code" : "PBC"
        },
        {
            "codeUIC" : 474338,
            "codeCH" : "BR",
            "nameUIC" : "Morlaix",
            "nameCH" : "Bifurcation de Roscoff",
            "code" : "MX"
        },
        {
            "codeUIC" : 474338,
            "codeCH" : "BV",
            "nameUIC" : "Morlaix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MX"
        },
        {
            "codeUIC" : 474379,
            "codeCH" : "EP",
            "nameUIC" : "La Boissière",
            "nameCH" : "ITE Coat Conq",
            "code" : "LBS"
        },
        {
            "codeUIC" : 474411,
            "codeCH" : 0,
            "nameUIC" : "Plouigneau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474429,
            "codeCH" : 0,
            "nameUIC" : "Le Ponthou",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474510,
            "codeCH" : 0,
            "nameUIC" : "Taulé-Henvic",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474551,
            "codeCH" : 0,
            "nameUIC" : "Plouénan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474569,
            "codeCH" : 0,
            "nameUIC" : "St-Pol-de-Léon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 474635,
            "codeCH" : 0,
            "nameUIC" : "Roscoff",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476002,
            "codeCH" : "BV",
            "nameUIC" : "Lorient",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LT"
        },
        {
            "codeUIC" : 476002,
            "codeCH" : "C1",
            "nameUIC" : "Lorient",
            "nameCH" : "Entrée IPCS Voie 1",
            "code" : "LT"
        },
        {
            "codeUIC" : 476002,
            "codeCH" : "C2",
            "nameUIC" : "Lorient",
            "nameCH" : "Sortie IPCS Voie 2",
            "code" : "LT"
        },
        {
            "codeUIC" : 476002,
            "codeCH" : "KN",
            "nameUIC" : "Lorient",
            "nameCH" : "ITE Kerpont",
            "code" : "LT"
        },
        {
            "codeUIC" : 476200,
            "codeCH" : "BP",
            "nameUIC" : "Auray",
            "nameCH" : "Bifurcation de Pontivy",
            "code" : "AY"
        },
        {
            "codeUIC" : 476200,
            "codeCH" : "BV",
            "nameUIC" : "Auray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AY"
        },
        {
            "codeUIC" : 476200,
            "codeCH" : "SI",
            "nameUIC" : "Auray",
            "nameCH" : "Carré 551",
            "code" : "AY"
        },
        {
            "codeUIC" : 476226,
            "codeCH" : "BV",
            "nameUIC" : "Landaul-Mendon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 476234,
            "codeCH" : "BV",
            "nameUIC" : "Landévant",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 476234,
            "codeCH" : "SI",
            "nameUIC" : "Landévant",
            "nameCH" : "Carré 571",
            "code" : ""
        },
        {
            "codeUIC" : 476242,
            "codeCH" : 0,
            "nameUIC" : "Brandérion",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476259,
            "codeCH" : "C1",
            "nameUIC" : "Hennebont",
            "nameCH" : "Sortie IPCS Voie 1",
            "code" : ""
        },
        {
            "codeUIC" : 476259,
            "codeCH" : "C2",
            "nameUIC" : "Hennebont",
            "nameCH" : "Entrée IPCS Voie 2",
            "code" : ""
        },
        {
            "codeUIC" : 476259,
            "codeCH" : 0,
            "nameUIC" : "Hennebont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476267,
            "codeCH" : 0,
            "nameUIC" : "Les Sables-Blancs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476275,
            "codeCH" : 0,
            "nameUIC" : "L'Isthme",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476309,
            "codeCH" : "SP",
            "nameUIC" : "Gestel",
            "nameCH" : "Carré 630,5",
            "code" : ""
        },
        {
            "codeUIC" : 476309,
            "codeCH" : 0,
            "nameUIC" : "Gestel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476317,
            "codeCH" : "SI",
            "nameUIC" : "Quimperlé",
            "nameCH" : "Sémaphore 685",
            "code" : "QPL"
        },
        {
            "codeUIC" : 476317,
            "codeCH" : "SP",
            "nameUIC" : "Quimperlé",
            "nameCH" : "Sémaphore 686",
            "code" : "QPL"
        },
        {
            "codeUIC" : 476317,
            "codeCH" : 0,
            "nameUIC" : "Quimperlé",
            "nameCH" : "",
            "code" : "QPL"
        },
        {
            "codeUIC" : 476408,
            "codeCH" : 0,
            "nameUIC" : "Belz-Ploemel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476416,
            "codeCH" : 0,
            "nameUIC" : "Plouharnel-Carnac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476424,
            "codeCH" : 0,
            "nameUIC" : "Penthièvre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476432,
            "codeCH" : 0,
            "nameUIC" : "Kerhostin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476440,
            "codeCH" : "BV",
            "nameUIC" : "St-Pierre-Quiberon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 476457,
            "codeCH" : 0,
            "nameUIC" : "Quiberon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476507,
            "codeCH" : "BV",
            "nameUIC" : "Pluvigner",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 476523,
            "codeCH" : 0,
            "nameUIC" : "Baud",
            "nameCH" : "",
            "code" : "BAU"
        },
        {
            "codeUIC" : 476531,
            "codeCH" : 0,
            "nameUIC" : "St-Rivalain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476549,
            "codeCH" : 0,
            "nameUIC" : "St-Nicolas-des-Eaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476556,
            "codeCH" : "BV",
            "nameUIC" : "Pontivy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PTY"
        },
        {
            "codeUIC" : 476556,
            "codeCH" : "CB",
            "nameUIC" : "Pontivy",
            "nameCH" : "ITE Cecab",
            "code" : "PTY"
        },
        {
            "codeUIC" : 476556,
            "codeCH" : "ES",
            "nameUIC" : "Pontivy",
            "nameCH" : "ITE Semaeb",
            "code" : "PTY"
        },
        {
            "codeUIC" : 476564,
            "codeCH" : 0,
            "nameUIC" : "St-Gérand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476606,
            "codeCH" : "SG",
            "nameUIC" : "Vannes",
            "nameCH" : "Sémaphore 421",
            "code" : "VAN"
        },
        {
            "codeUIC" : 476606,
            "codeCH" : "SI",
            "nameUIC" : "Vannes",
            "nameCH" : "Sémaphore 331",
            "code" : "VAN"
        },
        {
            "codeUIC" : 476606,
            "codeCH" : "SL",
            "nameUIC" : "Vannes",
            "nameCH" : "Sémaphore 400",
            "code" : "VAN"
        },
        {
            "codeUIC" : 476606,
            "codeCH" : "SP",
            "nameUIC" : "Vannes",
            "nameCH" : "Sémaphore 332",
            "code" : "VAN"
        },
        {
            "codeUIC" : 476606,
            "codeCH" : 0,
            "nameUIC" : "Vannes",
            "nameCH" : "",
            "code" : "VAN"
        },
        {
            "codeUIC" : 476648,
            "codeCH" : "SI",
            "nameUIC" : "Ste-Anne",
            "nameCH" : "Carré 431",
            "code" : ""
        },
        {
            "codeUIC" : 476648,
            "codeCH" : "SP",
            "nameUIC" : "Ste-Anne",
            "nameCH" : "Carré 432",
            "code" : ""
        },
        {
            "codeUIC" : 476648,
            "codeCH" : 0,
            "nameUIC" : "Ste-Anne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476671,
            "codeCH" : "SI",
            "nameUIC" : "Questembert",
            "nameCH" : "Sémaphore 321",
            "code" : "QUE"
        },
        {
            "codeUIC" : 476671,
            "codeCH" : 0,
            "nameUIC" : "Questembert",
            "nameCH" : "",
            "code" : "QUE"
        },
        {
            "codeUIC" : 476689,
            "codeCH" : "BV",
            "nameUIC" : "Malansac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 476754,
            "codeCH" : "BV",
            "nameUIC" : "Sévérac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 476762,
            "codeCH" : 0,
            "nameUIC" : "St-Gildas-des-Bois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 476770,
            "codeCH" : 0,
            "nameUIC" : "Drefféac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478016,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-sur-Ille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478024,
            "codeCH" : 0,
            "nameUIC" : "St-Médard-sur-Ille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478032,
            "codeCH" : 0,
            "nameUIC" : "Montreuil-sur-Ille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478040,
            "codeCH" : 0,
            "nameUIC" : "Dingé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478057,
            "codeCH" : 0,
            "nameUIC" : "Combourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478065,
            "codeCH" : 0,
            "nameUIC" : "Bonnemain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478073,
            "codeCH" : "BF",
            "nameUIC" : "Dol",
            "nameCH" : "Bifurcation d'Avranches",
            "code" : "DOL"
        },
        {
            "codeUIC" : 478073,
            "codeCH" : "BV",
            "nameUIC" : "Dol",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DOL"
        },
        {
            "codeUIC" : 478073,
            "codeCH" : "P1",
            "nameUIC" : "Dol",
            "nameCH" : "Poste 1",
            "code" : "DOL"
        },
        {
            "codeUIC" : 478073,
            "codeCH" : "S1",
            "nameUIC" : "Dol",
            "nameCH" : "Sémaphore 135,3",
            "code" : "DOL"
        },
        {
            "codeUIC" : 478081,
            "codeCH" : 0,
            "nameUIC" : "La Fresnais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478099,
            "codeCH" : 0,
            "nameUIC" : "La Gouesnière-Cancale",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478107,
            "codeCH" : 0,
            "nameUIC" : "St-Malo",
            "nameCH" : "",
            "code" : "SMA"
        },
        {
            "codeUIC" : 478123,
            "codeCH" : 0,
            "nameUIC" : "Plerguer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478131,
            "codeCH" : 0,
            "nameUIC" : "Miniac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478149,
            "codeCH" : 0,
            "nameUIC" : "Pleudihen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478156,
            "codeCH" : 0,
            "nameUIC" : "La Hisse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478164,
            "codeCH" : 0,
            "nameUIC" : "Dinan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478180,
            "codeCH" : 0,
            "nameUIC" : "Corseul-Languenan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478255,
            "codeCH" : 0,
            "nameUIC" : "Pleine-Fougères",
            "nameCH" : "",
            "code" : "PFS"
        },
        {
            "codeUIC" : 478263,
            "codeCH" : 0,
            "nameUIC" : "Pontorson-Mont-St-Michel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "BB",
            "nameUIC" : "Laval",
            "nameCH" : "Bifurcation Bonchamp",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "BI",
            "nameUIC" : "Laval",
            "nameCH" : "Bifurcation St-Berthevin",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "BM",
            "nameUIC" : "Laval",
            "nameCH" : "Base Maintenance",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "BP",
            "nameUIC" : "Laval",
            "nameCH" : "Bifurcation St-Berthevin P",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "BV",
            "nameUIC" : "Laval",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "IP",
            "nameUIC" : "Laval",
            "nameCH" : "Entrée/Sortie IPCS Km 299,2",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "PI",
            "nameUIC" : "Laval",
            "nameCH" : "Entrée/Sortie IPCS Km 299,8",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478404,
            "codeCH" : "P1",
            "nameUIC" : "Laval",
            "nameCH" : "Poste 1",
            "code" : "LAL"
        },
        {
            "codeUIC" : 478511,
            "codeCH" : 0,
            "nameUIC" : "Louverné",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478529,
            "codeCH" : "BV",
            "nameUIC" : "La Chapelle-Anthenaise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 478537,
            "codeCH" : "BV",
            "nameUIC" : "Montsûrs",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 478545,
            "codeCH" : "BV",
            "nameUIC" : "Neau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NEA"
        },
        {
            "codeUIC" : 478545,
            "codeCH" : "ED",
            "nameUIC" : "Neau",
            "nameCH" : "ITE Dolomie",
            "code" : "NEA"
        },
        {
            "codeUIC" : 478545,
            "codeCH" : "IP",
            "nameUIC" : "Neau",
            "nameCH" : "Entrée/Sortie IPCS Km 276,8",
            "code" : "NEA"
        },
        {
            "codeUIC" : 478545,
            "codeCH" : "SI",
            "nameUIC" : "Neau",
            "nameCH" : "Sortie Voie 1Bis",
            "code" : "NEA"
        },
        {
            "codeUIC" : 478552,
            "codeCH" : "BV",
            "nameUIC" : "Évron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 478560,
            "codeCH" : "BV",
            "nameUIC" : "Voutré",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VOU"
        },
        {
            "codeUIC" : 478560,
            "codeCH" : "SA",
            "nameUIC" : "Voutré",
            "nameCH" : "ITE SCV",
            "code" : "VOU"
        },
        {
            "codeUIC" : 478560,
            "codeCH" : "SC",
            "nameUIC" : "Voutré",
            "nameCH" : "SCV",
            "code" : "VOU"
        },
        {
            "codeUIC" : 478560,
            "codeCH" : "SP",
            "nameUIC" : "Voutré",
            "nameCH" : "Sortie Voie 2Bis",
            "code" : "VOU"
        },
        {
            "codeUIC" : 478743,
            "codeCH" : "BV",
            "nameUIC" : "Le Genest",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 478750,
            "codeCH" : 0,
            "nameUIC" : "Port-Brillet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 478768,
            "codeCH" : 0,
            "nameUIC" : "St-Pierre-la-Cour",
            "nameCH" : "",
            "code" : "SPO"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "BV",
            "nameUIC" : "Nantes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "BX",
            "nameUIC" : "Nantes",
            "nameCH" : "Bifurcation de Bordeaux",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "CL",
            "nameUIC" : "Nantes",
            "nameCH" : "Bifurcation de Clisson",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "CV",
            "nameUIC" : "Nantes",
            "nameCH" : "Carré Violet 85",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "DN",
            "nameUIC" : "Nantes",
            "nameCH" : "Bifurcation de Doulon",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "PI",
            "nameUIC" : "Nantes",
            "nameCH" : "Bifurcation de Pirmil",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "P1",
            "nameUIC" : "Nantes",
            "nameCH" : "Poste 1",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "SE",
            "nameUIC" : "Nantes",
            "nameCH" : "Bifurcation de Segré",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481002,
            "codeCH" : "VE",
            "nameUIC" : "Nantes",
            "nameCH" : "Bifurcation de Vertou",
            "code" : "NSC"
        },
        {
            "codeUIC" : 481010,
            "codeCH" : 0,
            "nameUIC" : "Nantes-État",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481036,
            "codeCH" : "ES",
            "nameUIC" : "Rezé-Pont-Rousseau",
            "nameCH" : "Entrée/Sortie GPM",
            "code" : "RPN"
        },
        {
            "codeUIC" : 481036,
            "codeCH" : "ZI",
            "nameUIC" : "Rezé-Pont-Rousseau",
            "nameCH" : "Zone Industrielle Château Bougon",
            "code" : "RPN"
        },
        {
            "codeUIC" : 481036,
            "codeCH" : 0,
            "nameUIC" : "Rezé-Pont-Rousseau",
            "nameCH" : "",
            "code" : "RPN"
        },
        {
            "codeUIC" : 481051,
            "codeCH" : "BP",
            "nameUIC" : "Chantenay",
            "nameCH" : "ITE BP",
            "code" : "CHA"
        },
        {
            "codeUIC" : 481051,
            "codeCH" : "ES",
            "nameUIC" : "Chantenay",
            "nameCH" : "Entrée/Sortie GPM",
            "code" : "CHA"
        },
        {
            "codeUIC" : 481051,
            "codeCH" : "SO",
            "nameUIC" : "Chantenay",
            "nameCH" : "ITE Segimo",
            "code" : "CHA"
        },
        {
            "codeUIC" : 481051,
            "codeCH" : 0,
            "nameUIC" : "Chantenay",
            "nameCH" : "",
            "code" : "CHA"
        },
        {
            "codeUIC" : 481069,
            "codeCH" : "BV",
            "nameUIC" : "La Basse-Indre-St-Herblain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LBI"
        },
        {
            "codeUIC" : 481077,
            "codeCH" : "DP",
            "nameUIC" : "Nantes-Blottereau",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 481077,
            "codeCH" : "FR",
            "nameUIC" : "Nantes-Blottereau",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 481077,
            "codeCH" : "P2",
            "nameUIC" : "Nantes-Blottereau",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 481077,
            "codeCH" : "P3",
            "nameUIC" : "Nantes-Blottereau",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 481077,
            "codeCH" : "P4",
            "nameUIC" : "Nantes-Blottereau",
            "nameCH" : "Poste 4",
            "code" : ""
        },
        {
            "codeUIC" : 481077,
            "codeCH" : "TR",
            "nameUIC" : "Nantes-Blottereau",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 481085,
            "codeCH" : "AT",
            "nameUIC" : "Doulon",
            "nameCH" : "Atelier Tram-Train",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : "BV",
            "nameUIC" : "Doulon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : "J1",
            "nameUIC" : "Doulon",
            "nameCH" : "Entrée/Sortie ATT CARQUEFOU",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : "J2",
            "nameUIC" : "Doulon",
            "nameCH" : "Entrée/Sortie ATT NANTES",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : "PN",
            "nameUIC" : "Doulon",
            "nameCH" : "PN 308",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : "P1",
            "nameUIC" : "Doulon",
            "nameCH" : "Poste 1",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : "ZI",
            "nameUIC" : "Doulon",
            "nameCH" : "Zone Industrielle Nord-Est de Nantes",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : 0,
            "nameUIC" : "Doulon",
            "nameCH" : "",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : 30,
            "nameUIC" : "Doulon",
            "nameCH" : "PN 310",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : 38,
            "nameUIC" : "Doulon",
            "nameCH" : "PN 308",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481085,
            "codeCH" : 39,
            "nameUIC" : "Doulon",
            "nameCH" : "PN 309",
            "code" : "DLN"
        },
        {
            "codeUIC" : 481093,
            "codeCH" : 0,
            "nameUIC" : "St-Joseph",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481135,
            "codeCH" : 0,
            "nameUIC" : "Ste-Luce",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481143,
            "codeCH" : 0,
            "nameUIC" : "Thouaré",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481150,
            "codeCH" : 0,
            "nameUIC" : "Mauves-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481168,
            "codeCH" : 0,
            "nameUIC" : "Le Cellier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481176,
            "codeCH" : 0,
            "nameUIC" : "Boussay-La Bruffière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481184,
            "codeCH" : 0,
            "nameUIC" : "Oudon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481192,
            "codeCH" : 0,
            "nameUIC" : "Ancenis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481200,
            "codeCH" : 0,
            "nameUIC" : "Bouaye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481218,
            "codeCH" : 0,
            "nameUIC" : "Port-St-Père-St-Mars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481226,
            "codeCH" : 0,
            "nameUIC" : "Ste-Pazanne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481234,
            "codeCH" : 0,
            "nameUIC" : "St-Hilaire-de-Chaléons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481242,
            "codeCH" : 0,
            "nameUIC" : "Bourgneuf-en-Retz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481259,
            "codeCH" : 0,
            "nameUIC" : "Les Moutiers-en-Retz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481267,
            "codeCH" : 0,
            "nameUIC" : "La Bernerie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481283,
            "codeCH" : 0,
            "nameUIC" : "Pornic",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481366,
            "codeCH" : "S4",
            "nameUIC" : "Machecoul",
            "nameCH" : "Sémaphores 451 452",
            "code" : ""
        },
        {
            "codeUIC" : 481366,
            "codeCH" : 0,
            "nameUIC" : "Machecoul",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481390,
            "codeCH" : "BV",
            "nameUIC" : "Challans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 481408,
            "codeCH" : 0,
            "nameUIC" : "Vertou",
            "nameCH" : "",
            "code" : "VT"
        },
        {
            "codeUIC" : 481408,
            "codeCH" : 30,
            "nameUIC" : "Vertou",
            "nameCH" : "Point d'arrêt Km 3,075",
            "code" : "VT"
        },
        {
            "codeUIC" : 481408,
            "codeCH" : 48,
            "nameUIC" : "Vertou",
            "nameCH" : "Pancarte Arrêt Km 4,875",
            "code" : "VT"
        },
        {
            "codeUIC" : 481416,
            "codeCH" : "EZ",
            "nameUIC" : "La Haie-Fouassière",
            "nameCH" : "ITE Zone Industrielle",
            "code" : ""
        },
        {
            "codeUIC" : 481416,
            "codeCH" : 0,
            "nameUIC" : "La Haie-Fouassière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481424,
            "codeCH" : 0,
            "nameUIC" : "Le Pallet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481432,
            "codeCH" : 0,
            "nameUIC" : "Gorges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481440,
            "codeCH" : "BI",
            "nameUIC" : "Clisson",
            "nameCH" : "Bifurcation",
            "code" : "CLI"
        },
        {
            "codeUIC" : 481440,
            "codeCH" : "BV",
            "nameUIC" : "Clisson",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CLI"
        },
        {
            "codeUIC" : 481440,
            "codeCH" : 73,
            "nameUIC" : "Clisson",
            "nameCH" : "Carré 731",
            "code" : "CLI"
        },
        {
            "codeUIC" : 481440,
            "codeCH" : 80,
            "nameUIC" : "Clisson",
            "nameCH" : "Carré 80",
            "code" : "CLI"
        },
        {
            "codeUIC" : 481440,
            "codeCH" : 81,
            "nameUIC" : "Clisson",
            "nameCH" : "Carré 731",
            "code" : "CLI"
        },
        {
            "codeUIC" : 481457,
            "codeCH" : 0,
            "nameUIC" : "Cugand-la-Bernardière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481465,
            "codeCH" : "BV",
            "nameUIC" : "Montaigu-Vendée",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MTV"
        },
        {
            "codeUIC" : 481465,
            "codeCH" : 74,
            "nameUIC" : "Montaigu-Vendée",
            "nameCH" : "Carré 742",
            "code" : "MTV"
        },
        {
            "codeUIC" : 481465,
            "codeCH" : 75,
            "nameUIC" : "Montaigu-Vendée",
            "nameCH" : "Carré 751",
            "code" : "MTV"
        },
        {
            "codeUIC" : 481507,
            "codeCH" : "A6",
            "nameUIC" : "Carquefou",
            "nameCH" : "Aiguille 6",
            "code" : "CAQ"
        },
        {
            "codeUIC" : 481507,
            "codeCH" : "BV",
            "nameUIC" : "Carquefou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CAQ"
        },
        {
            "codeUIC" : 481507,
            "codeCH" : "EC",
            "nameUIC" : "Carquefou",
            "nameCH" : "ITE Chaillous",
            "code" : "CAQ"
        },
        {
            "codeUIC" : 481507,
            "codeCH" : "EF",
            "nameUIC" : "Carquefou",
            "nameCH" : "ITE CEFO",
            "code" : "CAQ"
        },
        {
            "codeUIC" : 481507,
            "codeCH" : "EU",
            "nameUIC" : "Carquefou",
            "nameCH" : "ITE Système U N°1",
            "code" : "CAQ"
        },
        {
            "codeUIC" : 481507,
            "codeCH" : "PB",
            "nameUIC" : "Carquefou",
            "nameCH" : "Point B",
            "code" : "CAQ"
        },
        {
            "codeUIC" : 481507,
            "codeCH" : 0,
            "nameUIC" : "Carquefou",
            "nameCH" : "",
            "code" : "CAQ"
        },
        {
            "codeUIC" : 481564,
            "codeCH" : 0,
            "nameUIC" : "La Chapelle-Centre",
            "nameCH" : "",
            "code" : "LCS"
        },
        {
            "codeUIC" : 481572,
            "codeCH" : 0,
            "nameUIC" : "Sucé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481580,
            "codeCH" : 0,
            "nameUIC" : "Casson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481598,
            "codeCH" : "GA",
            "nameUIC" : "Nort-sur-Erdre",
            "nameCH" : "Garage",
            "code" : ""
        },
        {
            "codeUIC" : 481598,
            "codeCH" : "TI",
            "nameUIC" : "Nort-sur-Erdre",
            "nameCH" : "Titoir",
            "code" : ""
        },
        {
            "codeUIC" : 481598,
            "codeCH" : 0,
            "nameUIC" : "Nort-sur-Erdre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481614,
            "codeCH" : 0,
            "nameUIC" : "Abbaretz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481622,
            "codeCH" : 0,
            "nameUIC" : "Isse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481648,
            "codeCH" : 0,
            "nameUIC" : "Châteaubriant",
            "nameCH" : "",
            "code" : "CTB"
        },
        {
            "codeUIC" : 481689,
            "codeCH" : "BV",
            "nameUIC" : "La Croix-de-Méan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 481697,
            "codeCH" : 0,
            "nameUIC" : "La Baule-les-Pins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481705,
            "codeCH" : "BV",
            "nameUIC" : "St-Nazaire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SNI"
        },
        {
            "codeUIC" : 481705,
            "codeCH" : "ES",
            "nameUIC" : "St-Nazaire",
            "nameCH" : "Entrée/Sortie GPM",
            "code" : "SNI"
        },
        {
            "codeUIC" : 481705,
            "codeCH" : "TR",
            "nameUIC" : "St-Nazaire",
            "nameCH" : "Triage",
            "code" : "SNI"
        },
        {
            "codeUIC" : 481713,
            "codeCH" : "ES",
            "nameUIC" : "Montoir-de-Bretagne",
            "nameCH" : "Entrée/Sortie GPM",
            "code" : "MDB"
        },
        {
            "codeUIC" : 481713,
            "codeCH" : 0,
            "nameUIC" : "Montoir-de-Bretagne",
            "nameCH" : "",
            "code" : "MDB"
        },
        {
            "codeUIC" : 481721,
            "codeCH" : "AG",
            "nameUIC" : "Donges",
            "nameCH" : "Entrée/Sortie ITE ELF",
            "code" : "DGS"
        },
        {
            "codeUIC" : 481721,
            "codeCH" : "BV",
            "nameUIC" : "Donges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DGS"
        },
        {
            "codeUIC" : 481721,
            "codeCH" : "EA",
            "nameUIC" : "Donges",
            "nameCH" : "ITE ELF",
            "code" : "DGS"
        },
        {
            "codeUIC" : 481739,
            "codeCH" : 0,
            "nameUIC" : "St-André-des-Eaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481747,
            "codeCH" : 0,
            "nameUIC" : "Pornichet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481754,
            "codeCH" : 0,
            "nameUIC" : "La Baule-Escoublac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481762,
            "codeCH" : 0,
            "nameUIC" : "Le Pouliguen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481770,
            "codeCH" : 0,
            "nameUIC" : "Batz-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481788,
            "codeCH" : 0,
            "nameUIC" : "Le Croisic",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 481804,
            "codeCH" : "BV",
            "nameUIC" : "Couëron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 481804,
            "codeCH" : "PO",
            "nameUIC" : "Couëron",
            "nameCH" : "Poste",
            "code" : ""
        },
        {
            "codeUIC" : 481812,
            "codeCH" : 0,
            "nameUIC" : "St-Étienne-de-Montluc",
            "nameCH" : "",
            "code" : "SÉM"
        },
        {
            "codeUIC" : 481820,
            "codeCH" : "AG",
            "nameUIC" : "Cordemais",
            "nameCH" : "Entrée/Sortie ITE EDF",
            "code" : "CDQ"
        },
        {
            "codeUIC" : 481820,
            "codeCH" : "BV",
            "nameUIC" : "Cordemais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDQ"
        },
        {
            "codeUIC" : 481838,
            "codeCH" : "BV",
            "nameUIC" : "Savenay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 481846,
            "codeCH" : "BI",
            "nameUIC" : "Pontchâteau",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 481846,
            "codeCH" : "BV",
            "nameUIC" : "Pontchâteau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 481853,
            "codeCH" : 0,
            "nameUIC" : "Besné-Pontchâteau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484006,
            "codeCH" : "BI",
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Bifurcation de Maître-École",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : "BV",
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : "ES",
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Entrée/Sortie Faisceau",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : "FI",
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Faisceau Impair",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : "FP",
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Faiscseau Pair",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : "FR",
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Faisceau Réception",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : "TE",
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "ITE Trelaze",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : 62,
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Sémaphore 62",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484006,
            "codeCH" : 96,
            "nameUIC" : "Angers-St-Laud",
            "nameCH" : "Sémaphore 339,6",
            "code" : "ASL"
        },
        {
            "codeUIC" : 484048,
            "codeCH" : "BV",
            "nameUIC" : "Angers-Maître-École",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AME"
        },
        {
            "codeUIC" : 484048,
            "codeCH" : "I1",
            "nameUIC" : "Angers-Maître-École",
            "nameCH" : "ITE N°101",
            "code" : "AME"
        },
        {
            "codeUIC" : 484113,
            "codeCH" : "BV",
            "nameUIC" : "Écouflant",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ECO"
        },
        {
            "codeUIC" : 484113,
            "codeCH" : "EC",
            "nameUIC" : "Écouflant",
            "nameCH" : "ITE Caval",
            "code" : "ECO"
        },
        {
            "codeUIC" : 484139,
            "codeCH" : 0,
            "nameUIC" : "Le Vieux-Briollay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484147,
            "codeCH" : 0,
            "nameUIC" : "Tiercé",
            "nameCH" : "",
            "code" : "TCE"
        },
        {
            "codeUIC" : 484154,
            "codeCH" : 0,
            "nameUIC" : "Étriché-Châteauneuf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484162,
            "codeCH" : 0,
            "nameUIC" : "Le Porage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484170,
            "codeCH" : 0,
            "nameUIC" : "Morannes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484204,
            "codeCH" : 0,
            "nameUIC" : "Chalonnes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484220,
            "codeCH" : 0,
            "nameUIC" : "La Jumellière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484238,
            "codeCH" : 0,
            "nameUIC" : "Trémentines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484246,
            "codeCH" : 0,
            "nameUIC" : "Chemillé",
            "nameCH" : "",
            "code" : "CHE"
        },
        {
            "codeUIC" : 484303,
            "codeCH" : "BF",
            "nameUIC" : "Cholet",
            "nameCH" : "Bifurcation",
            "code" : "CHT"
        },
        {
            "codeUIC" : 484303,
            "codeCH" : "BV",
            "nameUIC" : "Cholet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHT"
        },
        {
            "codeUIC" : 484303,
            "codeCH" : "P1",
            "nameUIC" : "Cholet",
            "nameCH" : "Poste 1",
            "code" : "CHT"
        },
        {
            "codeUIC" : 484303,
            "codeCH" : 36,
            "nameUIC" : "Cholet",
            "nameCH" : "Sémaphore 36,8",
            "code" : "CHT"
        },
        {
            "codeUIC" : 484303,
            "codeCH" : 39,
            "nameUIC" : "Cholet",
            "nameCH" : "Sémaphore 39,7",
            "code" : "CHT"
        },
        {
            "codeUIC" : 484311,
            "codeCH" : 0,
            "nameUIC" : "La Pointe-Bouchemaine",
            "nameCH" : "",
            "code" : "LPB"
        },
        {
            "codeUIC" : 484329,
            "codeCH" : 0,
            "nameUIC" : "Savennières-Béhuard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484337,
            "codeCH" : 0,
            "nameUIC" : "La Possonnière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484352,
            "codeCH" : 0,
            "nameUIC" : "Champtocé-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484360,
            "codeCH" : "BV",
            "nameUIC" : "Ingrandes-sur-Loire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 484360,
            "codeCH" : "EI",
            "nameUIC" : "Ingrandes-sur-Loire",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 484360,
            "codeCH" : "SI",
            "nameUIC" : "Ingrandes-sur-Loire",
            "nameCH" : "Entrée Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 484378,
            "codeCH" : 0,
            "nameUIC" : "Montrelais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484386,
            "codeCH" : "BV",
            "nameUIC" : "Varades-St-Florent-le-Vieil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 484386,
            "codeCH" : "EP",
            "nameUIC" : "Varades-St-Florent-le-Vieil",
            "nameCH" : "Entrée Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 484386,
            "codeCH" : "SP",
            "nameUIC" : "Varades-St-Florent-le-Vieil",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 484659,
            "codeCH" : 0,
            "nameUIC" : "Château-Gontier",
            "nameCH" : "",
            "code" : "CIR"
        },
        {
            "codeUIC" : 484667,
            "codeCH" : 0,
            "nameUIC" : "Gennes-Longuefuye",
            "nameCH" : "",
            "code" : "GFY"
        },
        {
            "codeUIC" : 484709,
            "codeCH" : 0,
            "nameUIC" : "Segré",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484816,
            "codeCH" : 0,
            "nameUIC" : "St-Christophe-du-Bois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484824,
            "codeCH" : "LO",
            "nameUIC" : "Mortagne-sur-Sèvre",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 484824,
            "codeCH" : 56,
            "nameUIC" : "Mortagne-sur-Sèvre",
            "nameCH" : "PN 56",
            "code" : ""
        },
        {
            "codeUIC" : 484832,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-sur-Sèvre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484840,
            "codeCH" : 0,
            "nameUIC" : "Chambretaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484865,
            "codeCH" : 0,
            "nameUIC" : "Les Epesses",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 484873,
            "codeCH" : "LO",
            "nameUIC" : "Les Herbiers",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 484873,
            "codeCH" : 37,
            "nameUIC" : "Les Herbiers",
            "nameCH" : "PN 37",
            "code" : ""
        },
        {
            "codeUIC" : 485003,
            "codeCH" : "BV",
            "nameUIC" : "La Rochelle-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LRV"
        },
        {
            "codeUIC" : 485003,
            "codeCH" : "P1",
            "nameUIC" : "La Rochelle-Ville",
            "nameCH" : "Bifurcation de La Pallice",
            "code" : "LRV"
        },
        {
            "codeUIC" : 485003,
            "codeCH" : "P3",
            "nameUIC" : "La Rochelle-Ville",
            "nameCH" : "Bifurcation de Niort",
            "code" : "LRV"
        },
        {
            "codeUIC" : 485003,
            "codeCH" : 35,
            "nameUIC" : "La Rochelle-Ville",
            "nameCH" : "Carré 735",
            "code" : "LRV"
        },
        {
            "codeUIC" : 485011,
            "codeCH" : "TR",
            "nameUIC" : "La Rochelle-Pallice",
            "nameCH" : "Gare",
            "code" : "LRP"
        },
        {
            "codeUIC" : 485060,
            "codeCH" : "BV",
            "nameUIC" : "Marans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485094,
            "codeCH" : "BV",
            "nameUIC" : "Velluire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485102,
            "codeCH" : 0,
            "nameUIC" : "Angoulins-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 485110,
            "codeCH" : 0,
            "nameUIC" : "Châtelaillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485136,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-de-la-Prée",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485144,
            "codeCH" : "BV",
            "nameUIC" : "Rochefort",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RC"
        },
        {
            "codeUIC" : 485151,
            "codeCH" : 0,
            "nameUIC" : "Tonnay-Charente",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TNC"
        },
        {
            "codeUIC" : 485169,
            "codeCH" : 0,
            "nameUIC" : "Aytré",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AYR"
        },
        {
            "codeUIC" : 485185,
            "codeCH" : 0,
            "nameUIC" : "La Jarrie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 485193,
            "codeCH" : 0,
            "nameUIC" : "Aigrefeuille-Le Thou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485193,
            "codeCH" : 20,
            "nameUIC" : "Aigrefeuille-Le Thou",
            "nameCH" : "Sémaphore 120",
            "code" : ""
        },
        {
            "codeUIC" : 485193,
            "codeCH" : 21,
            "nameUIC" : "Aigrefeuille-Le Thou",
            "nameCH" : "Sémaphore 120",
            "code" : ""
        },
        {
            "codeUIC" : 485193,
            "codeCH" : 32,
            "nameUIC" : "Aigrefeuille-Le Thou",
            "nameCH" : "Carré 132,3",
            "code" : ""
        },
        {
            "codeUIC" : 485227,
            "codeCH" : "BV",
            "nameUIC" : "Surgères",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485227,
            "codeCH" : 3,
            "nameUIC" : "Surgères",
            "nameCH" : "Carré 103,1",
            "code" : ""
        },
        {
            "codeUIC" : 485227,
            "codeCH" : 92,
            "nameUIC" : "Surgères",
            "nameCH" : "Carré 92",
            "code" : ""
        },
        {
            "codeUIC" : 485227,
            "codeCH" : 95,
            "nameUIC" : "Surgères",
            "nameCH" : "Sémaphore 95",
            "code" : ""
        },
        {
            "codeUIC" : 485250,
            "codeCH" : "BV",
            "nameUIC" : "Mauzé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485250,
            "codeCH" : 72,
            "nameUIC" : "Mauzé",
            "nameCH" : "Sémaphore 72",
            "code" : ""
        },
        {
            "codeUIC" : 485250,
            "codeCH" : 81,
            "nameUIC" : "Mauzé",
            "nameCH" : "Sémaphore 81",
            "code" : ""
        },
        {
            "codeUIC" : 485250,
            "codeCH" : 95,
            "nameUIC" : "Mauzé",
            "nameCH" : "Sémaphore 81",
            "code" : ""
        },
        {
            "codeUIC" : 485268,
            "codeCH" : 0,
            "nameUIC" : "Prin-Deyrançon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485276,
            "codeCH" : 0,
            "nameUIC" : "Épannes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485300,
            "codeCH" : "BI",
            "nameUIC" : "Niort",
            "nameCH" : "Bifurcation de Fontenay",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : "BV",
            "nameUIC" : "Niort",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : "ET",
            "nameUIC" : "Niort",
            "nameCH" : "ITE PTT",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : "P1",
            "nameUIC" : "Niort",
            "nameCH" : "Bifurcation de Thouars",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : "P2",
            "nameUIC" : "Niort",
            "nameCH" : "Bifurcation de Saintes",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 7,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 607",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 41,
            "nameUIC" : "Niort",
            "nameCH" : "Signal 641",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 46,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 646",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 51,
            "nameUIC" : "Niort",
            "nameCH" : "Signal 651",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 52,
            "nameUIC" : "Niort",
            "nameCH" : "Signal 652",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 53,
            "nameUIC" : "Niort",
            "nameCH" : "Sémaphore 53",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 60,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 601",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 61,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 61",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 62,
            "nameUIC" : "Niort",
            "nameCH" : "Sémaphore 62",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 63,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 63",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 64,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 64",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 85,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 85,3",
            "code" : "NT"
        },
        {
            "codeUIC" : 485300,
            "codeCH" : 86,
            "nameUIC" : "Niort",
            "nameCH" : "Carré 86,2",
            "code" : "NT"
        },
        {
            "codeUIC" : 485318,
            "codeCH" : "EM",
            "nameUIC" : "Aiffres",
            "nameCH" : "ITE Mairie de Prahec",
            "code" : "AIF"
        },
        {
            "codeUIC" : 485318,
            "codeCH" : 0,
            "nameUIC" : "Aiffres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AIF"
        },
        {
            "codeUIC" : 485326,
            "codeCH" : 0,
            "nameUIC" : "Fors",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485334,
            "codeCH" : 0,
            "nameUIC" : "Marigny (Deux-Sèvres)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485342,
            "codeCH" : 0,
            "nameUIC" : "Beauvoir-sur-Niort",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485359,
            "codeCH" : 0,
            "nameUIC" : "Prissé-la-Charrière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485474,
            "codeCH" : 0,
            "nameUIC" : "La Crèche",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485490,
            "codeCH" : "BV",
            "nameUIC" : "St-Maixent (Deux-Sèvres)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485490,
            "codeCH" : 21,
            "nameUIC" : "St-Maixent (Deux-Sèvres)",
            "nameCH" : "Sémaphore 521",
            "code" : ""
        },
        {
            "codeUIC" : 485490,
            "codeCH" : 52,
            "nameUIC" : "St-Maixent (Deux-Sèvres)",
            "nameCH" : "Carré 52",
            "code" : ""
        },
        {
            "codeUIC" : 485508,
            "codeCH" : 0,
            "nameUIC" : "La Mothe-St-Héray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485508,
            "codeCH" : 25,
            "nameUIC" : "La Mothe-St-Héray",
            "nameCH" : "Carré 425",
            "code" : ""
        },
        {
            "codeUIC" : 485524,
            "codeCH" : 0,
            "nameUIC" : "Pamproux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485524,
            "codeCH" : 14,
            "nameUIC" : "Pamproux",
            "nameCH" : "Carré 314",
            "code" : ""
        },
        {
            "codeUIC" : 485532,
            "codeCH" : 0,
            "nameUIC" : "Rouillé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485540,
            "codeCH" : "BV",
            "nameUIC" : "Lusignan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LUI"
        },
        {
            "codeUIC" : 485557,
            "codeCH" : "AI",
            "nameUIC" : "Coulombiers",
            "nameCH" : "Aiguille 8207",
            "code" : "CUS"
        },
        {
            "codeUIC" : 485557,
            "codeCH" : "BC",
            "nameUIC" : "Coulombiers",
            "nameCH" : "Bifurcation Coulombiers",
            "code" : "CUS"
        },
        {
            "codeUIC" : 485557,
            "codeCH" : "BV",
            "nameUIC" : "Coulombiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CUS"
        },
        {
            "codeUIC" : 485557,
            "codeCH" : "EF",
            "nameUIC" : "Coulombiers",
            "nameCH" : "ITE FBISA",
            "code" : "CUS"
        },
        {
            "codeUIC" : 485573,
            "codeCH" : "BV",
            "nameUIC" : "Coulon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485573,
            "codeCH" : "EL",
            "nameUIC" : "Coulon",
            "nameCH" : "ITE Zone Industrielle de St-Liguaire",
            "code" : ""
        },
        {
            "codeUIC" : 485573,
            "codeCH" : "N7",
            "nameUIC" : "Coulon",
            "nameCH" : "PN 107",
            "code" : ""
        },
        {
            "codeUIC" : 485573,
            "codeCH" : "N9",
            "nameUIC" : "Coulon",
            "nameCH" : "PN 109",
            "code" : ""
        },
        {
            "codeUIC" : 485573,
            "codeCH" : 0,
            "nameUIC" : "Coulon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 485573,
            "codeCH" : 10,
            "nameUIC" : "Coulon",
            "nameCH" : "PN 110",
            "code" : ""
        },
        {
            "codeUIC" : 485573,
            "codeCH" : 11,
            "nameUIC" : "Coulon",
            "nameCH" : "PN 111",
            "code" : ""
        },
        {
            "codeUIC" : 485581,
            "codeCH" : "BV",
            "nameUIC" : "Benet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 485581,
            "codeCH" : "EI",
            "nameUIC" : "Benet",
            "nameCH" : "ITE Sivom Pomona",
            "code" : ""
        },
        {
            "codeUIC" : 485581,
            "codeCH" : "ES",
            "nameUIC" : "Benet",
            "nameCH" : "ITE SCPO",
            "code" : ""
        },
        {
            "codeUIC" : 485581,
            "codeCH" : "N3",
            "nameUIC" : "Benet",
            "nameCH" : "PN 103",
            "code" : ""
        },
        {
            "codeUIC" : 485581,
            "codeCH" : 0,
            "nameUIC" : "Benet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 485581,
            "codeCH" : 19,
            "nameUIC" : "Benet",
            "nameCH" : "PN 19",
            "code" : ""
        },
        {
            "codeUIC" : 485599,
            "codeCH" : "N9",
            "nameUIC" : "Nieul-Oulmes",
            "nameCH" : "PN 9",
            "code" : ""
        },
        {
            "codeUIC" : 485599,
            "codeCH" : 0,
            "nameUIC" : "Nieul-Oulmes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 485599,
            "codeCH" : 11,
            "nameUIC" : "Nieul-Oulmes",
            "nameCH" : "PN 11",
            "code" : ""
        },
        {
            "codeUIC" : 485607,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-de-Fraigneau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 485615,
            "codeCH" : "BV",
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485615,
            "codeCH" : "ES",
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "ITE SKF",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485615,
            "codeCH" : "N1",
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "PN 1",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485615,
            "codeCH" : "N2",
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "PN 2",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485615,
            "codeCH" : "N3",
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "PN 3",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485615,
            "codeCH" : "N4",
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "PN 4",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485615,
            "codeCH" : "OA",
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "PN O-2",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485615,
            "codeCH" : 0,
            "nameUIC" : "Fontenay-le-Comte",
            "nameCH" : "",
            "code" : "FLC"
        },
        {
            "codeUIC" : 485714,
            "codeCH" : 0,
            "nameUIC" : "Mazières-Verruyes",
            "nameCH" : "",
            "code" : "MV"
        },
        {
            "codeUIC" : 486019,
            "codeCH" : "BF",
            "nameUIC" : "La Roche-sur-Yon",
            "nameCH" : "Bifurcation de Thouars",
            "code" : "LRY"
        },
        {
            "codeUIC" : 486019,
            "codeCH" : "BV",
            "nameUIC" : "La Roche-sur-Yon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LRY"
        },
        {
            "codeUIC" : 486126,
            "codeCH" : "BV",
            "nameUIC" : "L'Herbergement-Les Brouzils",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LHB"
        },
        {
            "codeUIC" : 486126,
            "codeCH" : "C5",
            "nameUIC" : "L'Herbergement-Les Brouzils",
            "nameCH" : "Carré 5",
            "code" : "LHB"
        },
        {
            "codeUIC" : 486126,
            "codeCH" : 0,
            "nameUIC" : "L'Herbergement-Les Brouzils",
            "nameCH" : "",
            "code" : "LHB"
        },
        {
            "codeUIC" : 486126,
            "codeCH" : 46,
            "nameUIC" : "L'Herbergement-Les Brouzils",
            "nameCH" : "Carré 46",
            "code" : "LHB"
        },
        {
            "codeUIC" : 486126,
            "codeCH" : 51,
            "nameUIC" : "L'Herbergement-Les Brouzils",
            "nameCH" : "Carré 51",
            "code" : "LHB"
        },
        {
            "codeUIC" : 486126,
            "codeCH" : 76,
            "nameUIC" : "L'Herbergement-Les Brouzils",
            "nameCH" : "Carré 762",
            "code" : "LHB"
        },
        {
            "codeUIC" : 486142,
            "codeCH" : 0,
            "nameUIC" : "Belleville-Vendée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486225,
            "codeCH" : 0,
            "nameUIC" : "Champ-St-Père",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486258,
            "codeCH" : 0,
            "nameUIC" : "Luçon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486308,
            "codeCH" : 0,
            "nameUIC" : "La Chaize-le-Vicomte",
            "nameCH" : "",
            "code" : "LCV"
        },
        {
            "codeUIC" : 486316,
            "codeCH" : "BV",
            "nameUIC" : "Fougeré",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 486316,
            "codeCH" : "EC",
            "nameUIC" : "Fougeré",
            "nameCH" : "ITE Cavac",
            "code" : ""
        },
        {
            "codeUIC" : 486324,
            "codeCH" : 0,
            "nameUIC" : "Bournezeau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486332,
            "codeCH" : 0,
            "nameUIC" : "Chantonnay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486407,
            "codeCH" : 0,
            "nameUIC" : "Les Clouzeaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486423,
            "codeCH" : "BV",
            "nameUIC" : "La Mothe-Achard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LMC"
        },
        {
            "codeUIC" : 486423,
            "codeCH" : "IT",
            "nameUIC" : "La Mothe-Achard",
            "nameCH" : "ITE PRB",
            "code" : "LMC"
        },
        {
            "codeUIC" : 486431,
            "codeCH" : 0,
            "nameUIC" : "Olonne-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486449,
            "codeCH" : 0,
            "nameUIC" : "Les Sables-d'Olonne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486563,
            "codeCH" : "S5",
            "nameUIC" : "St-Hilaire-de-Riez",
            "nameCH" : "Sémaphores 551 552",
            "code" : ""
        },
        {
            "codeUIC" : 486563,
            "codeCH" : 0,
            "nameUIC" : "St-Hilaire-de-Riez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486571,
            "codeCH" : 0,
            "nameUIC" : "St-Gilles-Croix-de-Vie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 486894,
            "codeCH" : 0,
            "nameUIC" : "Torfou",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "A1",
            "nameUIC" : "Thouars",
            "nameCH" : "Sémaphore 374",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "A2",
            "nameUIC" : "Thouars",
            "nameCH" : "Sémaphore 364",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "BV",
            "nameUIC" : "Thouars",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "EP",
            "nameUIC" : "Thouars",
            "nameCH" : "ITE La Gouraudière",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "PA",
            "nameUIC" : "Thouars",
            "nameCH" : "Pancarte Arrêt",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "P1",
            "nameUIC" : "Thouars",
            "nameCH" : "Entrée/Sortie Triage",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "P2",
            "nameUIC" : "Thouars",
            "nameCH" : "Poste 2",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "TR",
            "nameUIC" : "Thouars",
            "nameCH" : "Triage",
            "code" : "TH"
        },
        {
            "codeUIC" : 487009,
            "codeCH" : "VT",
            "nameUIC" : "Thouars",
            "nameCH" : "Bifurcation du Viaduc du Thouet",
            "code" : "TH"
        },
        {
            "codeUIC" : 487116,
            "codeCH" : 0,
            "nameUIC" : "St-Varent",
            "nameCH" : "",
            "code" : "STV"
        },
        {
            "codeUIC" : 487140,
            "codeCH" : 0,
            "nameUIC" : "Airvault-Gare",
            "nameCH" : "",
            "code" : "AIR"
        },
        {
            "codeUIC" : 487173,
            "codeCH" : "BV",
            "nameUIC" : "Parthenay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PAY"
        },
        {
            "codeUIC" : 487231,
            "codeCH" : 0,
            "nameUIC" : "Noirterre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487249,
            "codeCH" : "BV",
            "nameUIC" : "Bressuire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 487249,
            "codeCH" : "PA",
            "nameUIC" : "Bressuire",
            "nameCH" : "Pancarte Limite de Manoeuvre",
            "code" : ""
        },
        {
            "codeUIC" : 487256,
            "codeCH" : 0,
            "nameUIC" : "Luché",
            "nameCH" : "",
            "code" : "LUC"
        },
        {
            "codeUIC" : 487280,
            "codeCH" : 0,
            "nameUIC" : "Cerizay",
            "nameCH" : "",
            "code" : "CEZ"
        },
        {
            "codeUIC" : 487298,
            "codeCH" : 0,
            "nameUIC" : "St-Mesmin-le-Vieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487314,
            "codeCH" : 0,
            "nameUIC" : "Pouzauges",
            "nameCH" : "",
            "code" : "PZ"
        },
        {
            "codeUIC" : 487330,
            "codeCH" : 0,
            "nameUIC" : "La Meilleraie (Vendée)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487348,
            "codeCH" : 0,
            "nameUIC" : "Chavagnes-les-Redoux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487355,
            "codeCH" : 0,
            "nameUIC" : "Sigournais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487512,
            "codeCH" : 0,
            "nameUIC" : "Moncoutant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487603,
            "codeCH" : "BV",
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : "P1",
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Poste 1",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 2,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Carré 122",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 7,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Carré 7",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 11,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Carré 11",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 12,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Carré 121",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 21,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Sémaphore 21",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 22,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Sémaphore 22",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 32,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Carré 132",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487603,
            "codeCH" : 86,
            "nameUIC" : "Saumur-Rive-Droite",
            "nameCH" : "Carré 86",
            "code" : "SUD"
        },
        {
            "codeUIC" : 487611,
            "codeCH" : 0,
            "nameUIC" : "Brion-près-Thouet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487637,
            "codeCH" : 0,
            "nameUIC" : "Montreuil-Bellay",
            "nameCH" : "",
            "code" : "MBL"
        },
        {
            "codeUIC" : 487652,
            "codeCH" : "IT",
            "nameUIC" : "Brézé-St-Cyr-en-Bourg",
            "nameCH" : "ITE Militaire",
            "code" : ""
        },
        {
            "codeUIC" : 487652,
            "codeCH" : 0,
            "nameUIC" : "Brézé-St-Cyr-en-Bourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487660,
            "codeCH" : 0,
            "nameUIC" : "Chacé-Varrains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487678,
            "codeCH" : "ES",
            "nameUIC" : "Nantilly-Saumur",
            "nameCH" : "ITE Seita",
            "code" : ""
        },
        {
            "codeUIC" : 487678,
            "codeCH" : 0,
            "nameUIC" : "Nantilly-Saumur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487694,
            "codeCH" : 0,
            "nameUIC" : "Saumur-Rive-Gauche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487710,
            "codeCH" : "BF",
            "nameUIC" : "Vivy",
            "nameCH" : "Bifurcation (Ex)",
            "code" : "VVY"
        },
        {
            "codeUIC" : 487710,
            "codeCH" : "BP",
            "nameUIC" : "Vivy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VVY"
        },
        {
            "codeUIC" : 487710,
            "codeCH" : "N1",
            "nameUIC" : "Vivy",
            "nameCH" : "PN 187",
            "code" : "VVY"
        },
        {
            "codeUIC" : 487710,
            "codeCH" : "N3",
            "nameUIC" : "Vivy",
            "nameCH" : "PN 193",
            "code" : "VVY"
        },
        {
            "codeUIC" : 487710,
            "codeCH" : "N4",
            "nameUIC" : "Vivy",
            "nameCH" : "PN 194",
            "code" : "VVY"
        },
        {
            "codeUIC" : 487710,
            "codeCH" : "N8",
            "nameUIC" : "Vivy",
            "nameCH" : "PN 188",
            "code" : "VVY"
        },
        {
            "codeUIC" : 487710,
            "codeCH" : 0,
            "nameUIC" : "Vivy",
            "nameCH" : "",
            "code" : "VVY"
        },
        {
            "codeUIC" : 487736,
            "codeCH" : "N7",
            "nameUIC" : "Vernantes",
            "nameCH" : "PN 177",
            "code" : ""
        },
        {
            "codeUIC" : 487736,
            "codeCH" : 0,
            "nameUIC" : "Vernantes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487751,
            "codeCH" : "LO",
            "nameUIC" : "Noyant-Méon",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 487751,
            "codeCH" : "N1",
            "nameUIC" : "Noyant-Méon",
            "nameCH" : "PN 171",
            "code" : ""
        },
        {
            "codeUIC" : 487751,
            "codeCH" : "N5",
            "nameUIC" : "Noyant-Méon",
            "nameCH" : "PN 165",
            "code" : ""
        },
        {
            "codeUIC" : 487819,
            "codeCH" : "BV",
            "nameUIC" : "Longué",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LU"
        },
        {
            "codeUIC" : 487819,
            "codeCH" : "EC",
            "nameUIC" : "Longué",
            "nameCH" : "ITE CAPL",
            "code" : "LU"
        },
        {
            "codeUIC" : 487819,
            "codeCH" : "ED",
            "nameUIC" : "Longué",
            "nameCH" : "ITE Duquesne",
            "code" : "LU"
        },
        {
            "codeUIC" : 487819,
            "codeCH" : 0,
            "nameUIC" : "Longué",
            "nameCH" : "",
            "code" : "LU"
        },
        {
            "codeUIC" : 487843,
            "codeCH" : 0,
            "nameUIC" : "La Bohalle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487876,
            "codeCH" : 0,
            "nameUIC" : "Les Rosiers-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487884,
            "codeCH" : "BV",
            "nameUIC" : "La Ménitré",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 487884,
            "codeCH" : "IP",
            "nameUIC" : "La Ménitré",
            "nameCH" : "Carré 31,Sémaphore 32",
            "code" : ""
        },
        {
            "codeUIC" : 487892,
            "codeCH" : 0,
            "nameUIC" : "St-Mathurin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 487900,
            "codeCH" : "EP",
            "nameUIC" : "Le Priory",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 487900,
            "codeCH" : "PO",
            "nameUIC" : "Le Priory",
            "nameCH" : "Poste",
            "code" : ""
        },
        {
            "codeUIC" : 491001,
            "codeCH" : "BV",
            "nameUIC" : "Saintes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SS"
        },
        {
            "codeUIC" : 491001,
            "codeCH" : "EA",
            "nameUIC" : "Saintes",
            "nameCH" : "ITE Ville de Saintes",
            "code" : "SS"
        },
        {
            "codeUIC" : 491001,
            "codeCH" : "P1",
            "nameUIC" : "Saintes",
            "nameCH" : "Bifurcation de Niort",
            "code" : "SS"
        },
        {
            "codeUIC" : 491001,
            "codeCH" : "TR",
            "nameUIC" : "Saintes",
            "nameCH" : "Triage",
            "code" : "SS"
        },
        {
            "codeUIC" : 491001,
            "codeCH" : 2,
            "nameUIC" : "Saintes",
            "nameCH" : "Signal 102",
            "code" : "SS"
        },
        {
            "codeUIC" : 491019,
            "codeCH" : 0,
            "nameUIC" : "Bords",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491035,
            "codeCH" : 0,
            "nameUIC" : "St-Savinien-sur-Charente",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491043,
            "codeCH" : 0,
            "nameUIC" : "Taillebourg",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491076,
            "codeCH" : "BV",
            "nameUIC" : "Beillant",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491076,
            "codeCH" : "EM",
            "nameUIC" : "Beillant",
            "nameCH" : "ITE MOREAU",
            "code" : ""
        },
        {
            "codeUIC" : 491076,
            "codeCH" : "IM",
            "nameUIC" : "Beillant",
            "nameCH" : "Carré 15",
            "code" : ""
        },
        {
            "codeUIC" : 491076,
            "codeCH" : "PA",
            "nameUIC" : "Beillant",
            "nameCH" : "Carré 6",
            "code" : ""
        },
        {
            "codeUIC" : 491076,
            "codeCH" : "RC",
            "nameUIC" : "Beillant",
            "nameCH" : "Raccordement",
            "code" : ""
        },
        {
            "codeUIC" : 491100,
            "codeCH" : "IM",
            "nameUIC" : "Pons",
            "nameCH" : "Carré 33",
            "code" : "PN"
        },
        {
            "codeUIC" : 491100,
            "codeCH" : "PA",
            "nameUIC" : "Pons",
            "nameCH" : "Sémaphore 16",
            "code" : "PN"
        },
        {
            "codeUIC" : 491100,
            "codeCH" : 0,
            "nameUIC" : "Pons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PN"
        },
        {
            "codeUIC" : 491134,
            "codeCH" : "IM",
            "nameUIC" : "Clion-sur-Seugne",
            "nameCH" : "Sémaphore 35",
            "code" : ""
        },
        {
            "codeUIC" : 491134,
            "codeCH" : "PA",
            "nameUIC" : "Clion-sur-Seugne",
            "nameCH" : "Sémaphore 36",
            "code" : ""
        },
        {
            "codeUIC" : 491134,
            "codeCH" : 0,
            "nameUIC" : "Clion-sur-Seugne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491142,
            "codeCH" : "IM",
            "nameUIC" : "Jonzac",
            "nameCH" : "Carré 51",
            "code" : "JO"
        },
        {
            "codeUIC" : 491142,
            "codeCH" : "PA",
            "nameUIC" : "Jonzac",
            "nameCH" : "Carré 42",
            "code" : "JO"
        },
        {
            "codeUIC" : 491142,
            "codeCH" : 0,
            "nameUIC" : "Jonzac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JO"
        },
        {
            "codeUIC" : 491159,
            "codeCH" : "IM",
            "nameUIC" : "Fontaines-d'Ozillac",
            "nameCH" : "Sémaphore 55",
            "code" : ""
        },
        {
            "codeUIC" : 491159,
            "codeCH" : "PA",
            "nameUIC" : "Fontaines-d'Ozillac",
            "nameCH" : "Sémaphore 56",
            "code" : ""
        },
        {
            "codeUIC" : 491159,
            "codeCH" : 0,
            "nameUIC" : "Fontaines-d'Ozillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491183,
            "codeCH" : "IM",
            "nameUIC" : "Montendre",
            "nameCH" : "Sémaphore 65",
            "code" : ""
        },
        {
            "codeUIC" : 491183,
            "codeCH" : "PA",
            "nameUIC" : "Montendre",
            "nameCH" : "Carré 62",
            "code" : ""
        },
        {
            "codeUIC" : 491183,
            "codeCH" : 0,
            "nameUIC" : "Montendre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491191,
            "codeCH" : 0,
            "nameUIC" : "Bussac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491209,
            "codeCH" : "ES",
            "nameUIC" : "St-Mariens-St-Yzan",
            "nameCH" : "ITE Scetauroute",
            "code" : "SMY"
        },
        {
            "codeUIC" : 491209,
            "codeCH" : "IM",
            "nameUIC" : "St-Mariens-St-Yzan",
            "nameCH" : "Carré 11",
            "code" : "SMY"
        },
        {
            "codeUIC" : 491209,
            "codeCH" : "PA",
            "nameUIC" : "St-Mariens-St-Yzan",
            "nameCH" : "Carré 72",
            "code" : "SMY"
        },
        {
            "codeUIC" : 491209,
            "codeCH" : "SI",
            "nameUIC" : "St-Mariens-St-Yzan",
            "nameCH" : "Sémaphore 31",
            "code" : "SMY"
        },
        {
            "codeUIC" : 491209,
            "codeCH" : "SP",
            "nameUIC" : "St-Mariens-St-Yzan",
            "nameCH" : "Carré 26",
            "code" : "SMY"
        },
        {
            "codeUIC" : 491209,
            "codeCH" : 0,
            "nameUIC" : "St-Mariens-St-Yzan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SMY"
        },
        {
            "codeUIC" : 491217,
            "codeCH" : "BV",
            "nameUIC" : "Cavignac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491225,
            "codeCH" : "IM",
            "nameUIC" : "Gauriaguet",
            "nameCH" : "Sémaphore 47",
            "code" : ""
        },
        {
            "codeUIC" : 491225,
            "codeCH" : "PA",
            "nameUIC" : "Gauriaguet",
            "nameCH" : "Sémaphore 48",
            "code" : ""
        },
        {
            "codeUIC" : 491225,
            "codeCH" : 0,
            "nameUIC" : "Gauriaguet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491233,
            "codeCH" : 0,
            "nameUIC" : "Aubie-St-Antoine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 491241,
            "codeCH" : "BV",
            "nameUIC" : "St-André-de-Cubzac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491241,
            "codeCH" : "IM",
            "nameUIC" : "St-André-de-Cubzac",
            "nameCH" : "Carré 1",
            "code" : ""
        },
        {
            "codeUIC" : 491241,
            "codeCH" : "PA",
            "nameUIC" : "St-André-de-Cubzac",
            "nameCH" : "Sémaphore 52",
            "code" : ""
        },
        {
            "codeUIC" : 491258,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-la-Comtesse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491266,
            "codeCH" : 0,
            "nameUIC" : "Cubzac-les-Ponts",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491274,
            "codeCH" : "BV",
            "nameUIC" : "La Grave-d'Ambarès-Gare-Inféri",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491274,
            "codeCH" : "ES",
            "nameUIC" : "La Grave-d'Ambarès-Gare-Inféri",
            "nameCH" : "Entrée/Sortie TCT",
            "code" : ""
        },
        {
            "codeUIC" : 491282,
            "codeCH" : "BV",
            "nameUIC" : "Ste-Eulalie-Carbon-Blanc",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491290,
            "codeCH" : 0,
            "nameUIC" : "Loulay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491308,
            "codeCH" : 0,
            "nameUIC" : "Fontcouverte",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491316,
            "codeCH" : 0,
            "nameUIC" : "Le Douhet-Écoyeux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LED"
        },
        {
            "codeUIC" : 491316,
            "codeCH" : 51,
            "nameUIC" : "Le Douhet-Écoyeux",
            "nameCH" : "Signal 51",
            "code" : "LED"
        },
        {
            "codeUIC" : 491316,
            "codeCH" : 52,
            "nameUIC" : "Le Douhet-Écoyeux",
            "nameCH" : "Signal 52",
            "code" : "LED"
        },
        {
            "codeUIC" : 491324,
            "codeCH" : 0,
            "nameUIC" : "St-Hilaire-Brizambourg",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491357,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-d'Angély",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SJA"
        },
        {
            "codeUIC" : 491365,
            "codeCH" : 51,
            "nameUIC" : "Vergné",
            "nameCH" : "Signal 751",
            "code" : ""
        },
        {
            "codeUIC" : 491365,
            "codeCH" : 52,
            "nameUIC" : "Vergné",
            "nameCH" : "Signal 752",
            "code" : ""
        },
        {
            "codeUIC" : 491449,
            "codeCH" : "BV",
            "nameUIC" : "Cognac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COG"
        },
        {
            "codeUIC" : 491449,
            "codeCH" : "EM",
            "nameUIC" : "Cognac",
            "nameCH" : "ITE Merpins",
            "code" : "COG"
        },
        {
            "codeUIC" : 491449,
            "codeCH" : "ER",
            "nameUIC" : "Cognac",
            "nameCH" : "ITE Renaud N°2",
            "code" : "COG"
        },
        {
            "codeUIC" : 491456,
            "codeCH" : "BV",
            "nameUIC" : "Gensac-la-Pallue",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GCA"
        },
        {
            "codeUIC" : 491456,
            "codeCH" : "EB",
            "nameUIC" : "Gensac-la-Pallue",
            "nameCH" : "ITE BSN",
            "code" : "GCA"
        },
        {
            "codeUIC" : 491456,
            "codeCH" : "EP",
            "nameUIC" : "Gensac-la-Pallue",
            "nameCH" : "EP Km 24",
            "code" : "GCA"
        },
        {
            "codeUIC" : 491472,
            "codeCH" : 0,
            "nameUIC" : "Jarnac-Charente",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JAC"
        },
        {
            "codeUIC" : 491506,
            "codeCH" : 0,
            "nameUIC" : "Châteauneuf-sur-Charente",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CSC"
        },
        {
            "codeUIC" : 491530,
            "codeCH" : 0,
            "nameUIC" : "Sireuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491563,
            "codeCH" : "EM",
            "nameUIC" : "St-Savin-de-Blaye",
            "nameCH" : "ITE Mabrivins",
            "code" : ""
        },
        {
            "codeUIC" : 491563,
            "codeCH" : "ES",
            "nameUIC" : "St-Savin-de-Blaye",
            "nameCH" : "ITE Scetauroute",
            "code" : ""
        },
        {
            "codeUIC" : 491589,
            "codeCH" : "LO",
            "nameUIC" : "Blaye",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BLY"
        },
        {
            "codeUIC" : 491589,
            "codeCH" : 14,
            "nameUIC" : "Blaye",
            "nameCH" : "PN 14",
            "code" : "BLY"
        },
        {
            "codeUIC" : 491720,
            "codeCH" : 0,
            "nameUIC" : "Clérac-Charente",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CEC"
        },
        {
            "codeUIC" : 491803,
            "codeCH" : 0,
            "nameUIC" : "Royan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 491811,
            "codeCH" : 0,
            "nameUIC" : "Saujon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SJ"
        },
        {
            "codeUIC" : 491837,
            "codeCH" : 0,
            "nameUIC" : "Pisany",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 492108,
            "codeCH" : 0,
            "nameUIC" : "Les Ardoines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 492215,
            "codeCH" : "EE",
            "nameUIC" : "Montaudran Poste",
            "nameCH" : "Entrée EC1",
            "code" : ""
        },
        {
            "codeUIC" : 492215,
            "codeCH" : "SE",
            "nameUIC" : "Montaudran Poste",
            "nameCH" : "Sortie EC1",
            "code" : ""
        },
        {
            "codeUIC" : 492280,
            "codeCH" : "LO",
            "nameUIC" : "Marseilles-lès-Aubigny",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 492280,
            "codeCH" : "PA",
            "nameUIC" : "Marseilles-lès-Aubigny",
            "nameCH" : "Pancarte Arrêt",
            "code" : ""
        },
        {
            "codeUIC" : 492348,
            "codeCH" : "BF",
            "nameUIC" : "Poste R d'Orly",
            "nameCH" : "Bifurcation de la Faisanderie",
            "code" : ""
        },
        {
            "codeUIC" : 492348,
            "codeCH" : "BI",
            "nameUIC" : "Poste R d'Orly",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 492348,
            "codeCH" : "BP",
            "nameUIC" : "Poste R d'Orly",
            "nameCH" : "Bifurcation de la Paix",
            "code" : ""
        },
        {
            "codeUIC" : 492348,
            "codeCH" : "EV",
            "nameUIC" : "Poste R d'Orly",
            "nameCH" : "Accès EV1",
            "code" : ""
        },
        {
            "codeUIC" : 492348,
            "codeCH" : "RB",
            "nameUIC" : "Poste R d'Orly",
            "nameCH" : "Tiroir de Rebroussement",
            "code" : ""
        },
        {
            "codeUIC" : 492363,
            "codeCH" : "PA",
            "nameUIC" : "Pont-Vert",
            "nameCH" : "Bifurcation Montluçon",
            "code" : ""
        },
        {
            "codeUIC" : 492363,
            "codeCH" : "PC",
            "nameUIC" : "Pont-Vert",
            "nameCH" : "Bifurcation de Vierzon",
            "code" : ""
        },
        {
            "codeUIC" : 492736,
            "codeCH" : "EA",
            "nameUIC" : "Déols",
            "nameCH" : "ITE Administration militaire",
            "code" : ""
        },
        {
            "codeUIC" : 492736,
            "codeCH" : "EI",
            "nameUIC" : "Déols",
            "nameCH" : "ITE Km 258,6",
            "code" : ""
        },
        {
            "codeUIC" : 492736,
            "codeCH" : 0,
            "nameUIC" : "Déols",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 492975,
            "codeCH" : 0,
            "nameUIC" : "Maby-d'Or (Évit. Pair)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 497461,
            "codeCH" : 0,
            "nameUIC" : "Galliéni-Cancéropole",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 498121,
            "codeCH" : 0,
            "nameUIC" : "Avenue-Thiers-Cenon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 534131,
            "codeCH" : "BV",
            "nameUIC" : "Ermont-Eaubonne (PSL)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 534321,
            "codeCH" : 0,
            "nameUIC" : "Mulhouse-Dornach (Tram/Train)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 534339,
            "codeCH" : 0,
            "nameUIC" : "Mulhouse-Musées (Tram/Train)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 534347,
            "codeCH" : 0,
            "nameUIC" : "Lutterbach (Tram/Train)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 534693,
            "codeCH" : 0,
            "nameUIC" : "Poste 86 Thervay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 534701,
            "codeCH" : "PV",
            "nameUIC" : "Poste 93 Les Magny",
            "nameCH" : "Point Changement Voie",
            "code" : ""
        },
        {
            "codeUIC" : 534701,
            "codeCH" : "TN",
            "nameUIC" : "Poste 93 Les Magny",
            "nameCH" : "Tunnel Accès Nord",
            "code" : ""
        },
        {
            "codeUIC" : 534701,
            "codeCH" : "TS",
            "nameUIC" : "Poste 93 Les Magny",
            "nameCH" : "Tunnel Accès Sud",
            "code" : ""
        },
        {
            "codeUIC" : 534701,
            "codeCH" : 0,
            "nameUIC" : "Poste 93 Les Magny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 540179,
            "codeCH" : 0,
            "nameUIC" : "Dourdan-la-Forêt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543009,
            "codeCH" : "BV",
            "nameUIC" : "Orléans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ORL"
        },
        {
            "codeUIC" : 543009,
            "codeCH" : "EC",
            "nameUIC" : "Orléans",
            "nameCH" : "ITE Cepem",
            "code" : "ORL"
        },
        {
            "codeUIC" : 543009,
            "codeCH" : "EG",
            "nameUIC" : "Orléans",
            "nameCH" : "ITE Germond",
            "code" : "ORL"
        },
        {
            "codeUIC" : 543009,
            "codeCH" : "EP",
            "nameUIC" : "Orléans",
            "nameCH" : "ITE Parc Murlins",
            "code" : "ORL"
        },
        {
            "codeUIC" : 543009,
            "codeCH" : "MA",
            "nameUIC" : "Orléans",
            "nameCH" : "Marchandises",
            "code" : "ORL"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "BC",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Bifurcation de Chartres",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "BF",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Bifurcation Châteauneuf",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "BM",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Bifurcation Malesherbes",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "BP",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Bifurcation de Paris",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "BV",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "BZ",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Bifurcation de Vierzon",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "D2",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Signal 878/678",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "EA",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "ITE Administration Militaire",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "ER",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "ITE Herveline",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "EU",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "ITE Herveline",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "EV",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "ITE Vidal",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "EZ",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "ITE ZI de Fleury",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "FN",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Faisceau Nord",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "P5",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Poste 5",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "P6",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Poste 6",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "P7",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Poste 7",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "RC",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Faisceau Réception",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "RL",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Relais Lattéral",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "RN",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Relais Nord",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "RO",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Raccordement Orléans",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "RT",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Raccordement Tours",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "SX",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "S 51(V1)53(V1B)",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : "TR",
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Triage",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543017,
            "codeCH" : 20,
            "nameUIC" : "Les Aubrais-Orléans",
            "nameCH" : "Signal 20",
            "code" : "LAO"
        },
        {
            "codeUIC" : 543033,
            "codeCH" : "BV",
            "nameUIC" : "Cercottes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 543041,
            "codeCH" : "BV",
            "nameUIC" : "Chevilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 543041,
            "codeCH" : "EC",
            "nameUIC" : "Chevilly",
            "nameCH" : "ITE SNCI",
            "code" : ""
        },
        {
            "codeUIC" : 543058,
            "codeCH" : "BV",
            "nameUIC" : "Artenay (Loiret)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ATY"
        },
        {
            "codeUIC" : 543058,
            "codeCH" : "DB",
            "nameUIC" : "Artenay (Loiret)",
            "nameCH" : "Signal 662",
            "code" : "ATY"
        },
        {
            "codeUIC" : 543058,
            "codeCH" : "D2",
            "nameUIC" : "Artenay (Loiret)",
            "nameCH" : "Signal 866/666",
            "code" : "ATY"
        },
        {
            "codeUIC" : 543058,
            "codeCH" : "EB",
            "nameUIC" : "Artenay (Loiret)",
            "nameCH" : "Sortie 857 Entrée Voie 1Bis",
            "code" : "ATY"
        },
        {
            "codeUIC" : 543058,
            "codeCH" : "S8",
            "nameUIC" : "Artenay (Loiret)",
            "nameCH" : "Sémaphore 863",
            "code" : "ATY"
        },
        {
            "codeUIC" : 543066,
            "codeCH" : 0,
            "nameUIC" : "Château-Gaillard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543074,
            "codeCH" : "BV",
            "nameUIC" : "Toury",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TY"
        },
        {
            "codeUIC" : 543074,
            "codeCH" : "DV",
            "nameUIC" : "Toury",
            "nameCH" : "V2B/V2",
            "code" : "TY"
        },
        {
            "codeUIC" : 543074,
            "codeCH" : "D1",
            "nameUIC" : "Toury",
            "nameCH" : "Poste A",
            "code" : "TY"
        },
        {
            "codeUIC" : 543074,
            "codeCH" : "FI",
            "nameUIC" : "Toury",
            "nameCH" : "Faisceau Impair",
            "code" : "TY"
        },
        {
            "codeUIC" : 543074,
            "codeCH" : "SG",
            "nameUIC" : "Toury",
            "nameCH" : "Signal 10",
            "code" : "TY"
        },
        {
            "codeUIC" : 543074,
            "codeCH" : "1B",
            "nameUIC" : "Toury",
            "nameCH" : "Sortie V1B",
            "code" : "TY"
        },
        {
            "codeUIC" : 543074,
            "codeCH" : 28,
            "nameUIC" : "Toury",
            "nameCH" : "PN 28Bis",
            "code" : "TY"
        },
        {
            "codeUIC" : 543082,
            "codeCH" : "BV",
            "nameUIC" : "Boisseaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 543082,
            "codeCH" : "V4",
            "nameUIC" : "Boisseaux",
            "nameCH" : "Sortie Voie 4",
            "code" : ""
        },
        {
            "codeUIC" : 543090,
            "codeCH" : "BV",
            "nameUIC" : "Angerville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 543090,
            "codeCH" : "DB",
            "nameUIC" : "Angerville",
            "nameCH" : "Déviation Voie 1/Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 543090,
            "codeCH" : "SB",
            "nameUIC" : "Angerville",
            "nameCH" : "Aiguille 402,Sortie Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 543108,
            "codeCH" : "LO",
            "nameUIC" : "Janville",
            "nameCH" : "Local",
            "code" : "JAN"
        },
        {
            "codeUIC" : 543108,
            "codeCH" : 24,
            "nameUIC" : "Janville",
            "nameCH" : "PN 24",
            "code" : "JAN"
        },
        {
            "codeUIC" : 543108,
            "codeCH" : 25,
            "nameUIC" : "Janville",
            "nameCH" : "PN 25",
            "code" : "JAN"
        },
        {
            "codeUIC" : 543116,
            "codeCH" : 0,
            "nameUIC" : "St-Cyr-en-Val",
            "nameCH" : "",
            "code" : "CYV"
        },
        {
            "codeUIC" : 543124,
            "codeCH" : "BV",
            "nameUIC" : "La Ferté-St-Aubin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LFA"
        },
        {
            "codeUIC" : 543124,
            "codeCH" : "EH",
            "nameUIC" : "La Ferté-St-Aubin",
            "nameCH" : "ITE Thomson-Brandt",
            "code" : "LFA"
        },
        {
            "codeUIC" : 543140,
            "codeCH" : "BV",
            "nameUIC" : "Lamotte-Beuvron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LB"
        },
        {
            "codeUIC" : 543140,
            "codeCH" : "EF",
            "nameUIC" : "Lamotte-Beuvron",
            "nameCH" : "ITE Coopérative des Agriculteurs du Loir-et-Cher",
            "code" : "LB"
        },
        {
            "codeUIC" : 543140,
            "codeCH" : "E2",
            "nameUIC" : "Lamotte-Beuvron",
            "nameCH" : "IPCS",
            "code" : "LB"
        },
        {
            "codeUIC" : 543140,
            "codeCH" : "V5",
            "nameUIC" : "Lamotte-Beuvron",
            "nameCH" : "Voie 5",
            "code" : "LB"
        },
        {
            "codeUIC" : 543140,
            "codeCH" : "V6",
            "nameUIC" : "Lamotte-Beuvron",
            "nameCH" : "Voie 6",
            "code" : "LB"
        },
        {
            "codeUIC" : 543157,
            "codeCH" : 0,
            "nameUIC" : "Nouan-le-Fuzelier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543165,
            "codeCH" : "BV",
            "nameUIC" : "Salbris",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SAL"
        },
        {
            "codeUIC" : 543165,
            "codeCH" : "ED",
            "nameUIC" : "Salbris",
            "nameCH" : "ITE Administration Militaire",
            "code" : "SAL"
        },
        {
            "codeUIC" : 543165,
            "codeCH" : "E1",
            "nameUIC" : "Salbris",
            "nameCH" : "Entrée IPCS",
            "code" : "SAL"
        },
        {
            "codeUIC" : 543165,
            "codeCH" : "S2",
            "nameUIC" : "Salbris",
            "nameCH" : "Sortie IPCS",
            "code" : "SAL"
        },
        {
            "codeUIC" : 543181,
            "codeCH" : 0,
            "nameUIC" : "Boulainvilliers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543199,
            "codeCH" : 0,
            "nameUIC" : "Theillay (Loir-et-Cher)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543207,
            "codeCH" : 0,
            "nameUIC" : "Avenue-du-Président-Kennedy-Ra",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543223,
            "codeCH" : "PN",
            "nameUIC" : "Fay-aux-Loges",
            "nameCH" : "PN 134",
            "code" : ""
        },
        {
            "codeUIC" : 543223,
            "codeCH" : 0,
            "nameUIC" : "Fay-aux-Loges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543231,
            "codeCH" : 0,
            "nameUIC" : "Vitry-aux-Loges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543272,
            "codeCH" : "EC",
            "nameUIC" : "Vennecy",
            "nameCH" : "ITE Charbonnière",
            "code" : ""
        },
        {
            "codeUIC" : 543272,
            "codeCH" : "PN",
            "nameUIC" : "Vennecy",
            "nameCH" : "PN 107",
            "code" : ""
        },
        {
            "codeUIC" : 543272,
            "codeCH" : 0,
            "nameUIC" : "Vennecy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543306,
            "codeCH" : "EA",
            "nameUIC" : "Fains-la-Folie",
            "nameCH" : "ITE AGRALYS",
            "code" : "FAN"
        },
        {
            "codeUIC" : 543306,
            "codeCH" : 0,
            "nameUIC" : "Fains-la-Folie",
            "nameCH" : "",
            "code" : "FAN"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : "EA",
            "nameUIC" : "Orgères",
            "nameCH" : "ITE AGRALYS",
            "code" : "OG"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : "ED",
            "nameUIC" : "Orgères",
            "nameCH" : "PN 56",
            "code" : "OG"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : "ES",
            "nameUIC" : "Orgères",
            "nameCH" : "ITE SCAEL",
            "code" : "OG"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : "E1",
            "nameUIC" : "Orgères",
            "nameCH" : "ITE Dunois N°1",
            "code" : "OG"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : "E2",
            "nameUIC" : "Orgères",
            "nameCH" : "ITE Dunois N°2",
            "code" : "OG"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : "LO",
            "nameUIC" : "Orgères",
            "nameCH" : "Local",
            "code" : "OG"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : 50,
            "nameUIC" : "Orgères",
            "nameCH" : "PN 50",
            "code" : "OG"
        },
        {
            "codeUIC" : 543314,
            "codeCH" : 53,
            "nameUIC" : "Orgères",
            "nameCH" : "PN 53",
            "code" : "OG"
        },
        {
            "codeUIC" : 543322,
            "codeCH" : "EA",
            "nameUIC" : "Gommiers",
            "nameCH" : "ITE Agralys",
            "code" : "GOM"
        },
        {
            "codeUIC" : 543330,
            "codeCH" : "EM",
            "nameUIC" : "Patay",
            "nameCH" : "ITE Mothiron",
            "code" : "PT"
        },
        {
            "codeUIC" : 543330,
            "codeCH" : "LO",
            "nameUIC" : "Patay",
            "nameCH" : "Local",
            "code" : "PT"
        },
        {
            "codeUIC" : 543330,
            "codeCH" : "PA",
            "nameUIC" : "Patay",
            "nameCH" : "Pancarte Arrêt",
            "code" : "PT"
        },
        {
            "codeUIC" : 543348,
            "codeCH" : 0,
            "nameUIC" : "Bricy-Boulay",
            "nameCH" : "",
            "code" : "BCY"
        },
        {
            "codeUIC" : 543355,
            "codeCH" : "EC",
            "nameUIC" : "Villeneuve-d'Ingré",
            "nameCH" : "ITE Casda N°2",
            "code" : "VVD"
        },
        {
            "codeUIC" : 543355,
            "codeCH" : "EJ",
            "nameUIC" : "Villeneuve-d'Ingré",
            "nameCH" : "ITE John Deere",
            "code" : "VVD"
        },
        {
            "codeUIC" : 543355,
            "codeCH" : "ES",
            "nameUIC" : "Villeneuve-d'Ingré",
            "nameCH" : "ITE Casval N°1",
            "code" : "VVD"
        },
        {
            "codeUIC" : 543355,
            "codeCH" : "LO",
            "nameUIC" : "Villeneuve-d'Ingré",
            "nameCH" : "Local",
            "code" : "VVD"
        },
        {
            "codeUIC" : 543371,
            "codeCH" : "BV",
            "nameUIC" : "La Chapelle-St-Mesmin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 543413,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-de-Braye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543421,
            "codeCH" : "ED",
            "nameUIC" : "Chécy-Mardié",
            "nameCH" : "ITE Danzas",
            "code" : "CYM"
        },
        {
            "codeUIC" : 543421,
            "codeCH" : 0,
            "nameUIC" : "Chécy-Mardié",
            "nameCH" : "",
            "code" : "CYM"
        },
        {
            "codeUIC" : 543439,
            "codeCH" : "BV",
            "nameUIC" : "St-Denis-Jargeau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SDS"
        },
        {
            "codeUIC" : 543439,
            "codeCH" : "EU",
            "nameUIC" : "St-Denis-Jargeau",
            "nameCH" : "ITE Unisabi",
            "code" : "SDS"
        },
        {
            "codeUIC" : 543462,
            "codeCH" : "BV",
            "nameUIC" : "Châteauneuf-sur-Loire",
            "nameCH" : "Local",
            "code" : "CSL"
        },
        {
            "codeUIC" : 543462,
            "codeCH" : "EB",
            "nameUIC" : "Châteauneuf-sur-Loire",
            "nameCH" : "ITE Baudin",
            "code" : "CSL"
        },
        {
            "codeUIC" : 543462,
            "codeCH" : "ES",
            "nameUIC" : "Châteauneuf-sur-Loire",
            "nameCH" : "ITE Sagem",
            "code" : "CSL"
        },
        {
            "codeUIC" : 543462,
            "codeCH" : "EV",
            "nameUIC" : "Châteauneuf-sur-Loire",
            "nameCH" : "ITE Salvesen",
            "code" : "CSL"
        },
        {
            "codeUIC" : 543488,
            "codeCH" : 0,
            "nameUIC" : "St-Benoît-St-Aignan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543504,
            "codeCH" : "LO",
            "nameUIC" : "Les Bordes",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 543504,
            "codeCH" : 65,
            "nameUIC" : "Les Bordes",
            "nameCH" : "PN 65",
            "code" : ""
        },
        {
            "codeUIC" : 543538,
            "codeCH" : "EC",
            "nameUIC" : "Sully-sur-Loire",
            "nameCH" : "ITE KRONOFRANCE",
            "code" : "SUL"
        },
        {
            "codeUIC" : 543538,
            "codeCH" : "LO",
            "nameUIC" : "Sully-sur-Loire",
            "nameCH" : "Local",
            "code" : "SUL"
        },
        {
            "codeUIC" : 543538,
            "codeCH" : "PA",
            "nameUIC" : "Sully-sur-Loire",
            "nameCH" : "Pancarte Zone Z",
            "code" : "SUL"
        },
        {
            "codeUIC" : 543538,
            "codeCH" : 84,
            "nameUIC" : "Sully-sur-Loire",
            "nameCH" : "PN 84",
            "code" : "SUL"
        },
        {
            "codeUIC" : 543579,
            "codeCH" : "LO",
            "nameUIC" : "Cerdon-du-Loiret",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 543579,
            "codeCH" : 98,
            "nameUIC" : "Cerdon-du-Loiret",
            "nameCH" : "PN 98",
            "code" : ""
        },
        {
            "codeUIC" : 543587,
            "codeCH" : 0,
            "nameUIC" : "Argent",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543660,
            "codeCH" : 0,
            "nameUIC" : "Sermaises-du-Loiret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543686,
            "codeCH" : 0,
            "nameUIC" : "Engenville",
            "nameCH" : "",
            "code" : "EGV"
        },
        {
            "codeUIC" : 543686,
            "codeCH" : 21,
            "nameUIC" : "Engenville",
            "nameCH" : "PN 21",
            "code" : "EGV"
        },
        {
            "codeUIC" : 543686,
            "codeCH" : 25,
            "nameUIC" : "Engenville",
            "nameCH" : "PN 25",
            "code" : "EGV"
        },
        {
            "codeUIC" : 543694,
            "codeCH" : "BV",
            "nameUIC" : "La Foulonnerie",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 543694,
            "codeCH" : "EJ",
            "nameUIC" : "La Foulonnerie",
            "nameCH" : "ITE John Deere",
            "code" : ""
        },
        {
            "codeUIC" : 543694,
            "codeCH" : "ES",
            "nameUIC" : "La Foulonnerie",
            "nameCH" : "ITE Service Santé A",
            "code" : ""
        },
        {
            "codeUIC" : 543728,
            "codeCH" : 0,
            "nameUIC" : "Neuville-aux-Bois",
            "nameCH" : "",
            "code" : "NB"
        },
        {
            "codeUIC" : 543769,
            "codeCH" : "BF",
            "nameUIC" : "Pithiviers",
            "nameCH" : "Bifurcation de Beaune-la-Rolande",
            "code" : "PIT"
        },
        {
            "codeUIC" : 543769,
            "codeCH" : "BV",
            "nameUIC" : "Pithiviers",
            "nameCH" : "Local",
            "code" : "PIT"
        },
        {
            "codeUIC" : 543769,
            "codeCH" : "EC",
            "nameUIC" : "Pithiviers",
            "nameCH" : "ITE Sucrerie",
            "code" : "PIT"
        },
        {
            "codeUIC" : 543769,
            "codeCH" : "ES",
            "nameUIC" : "Pithiviers",
            "nameCH" : "ITE Société Nouvelle Préfabrication",
            "code" : "PIT"
        },
        {
            "codeUIC" : 543769,
            "codeCH" : "EZ",
            "nameUIC" : "Pithiviers",
            "nameCH" : "ITE ZI Pithivier",
            "code" : "PIT"
        },
        {
            "codeUIC" : 543769,
            "codeCH" : "PA",
            "nameUIC" : "Pithiviers",
            "nameCH" : "Pancarte Arrêt",
            "code" : "PIT"
        },
        {
            "codeUIC" : 543769,
            "codeCH" : "P2",
            "nameUIC" : "Pithiviers",
            "nameCH" : "PN 2",
            "code" : "PIT"
        },
        {
            "codeUIC" : 543801,
            "codeCH" : 0,
            "nameUIC" : "Ascoux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 543819,
            "codeCH" : "LO",
            "nameUIC" : "Boynes",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 543819,
            "codeCH" : 15,
            "nameUIC" : "Boynes",
            "nameCH" : "PN 15",
            "code" : ""
        },
        {
            "codeUIC" : 543819,
            "codeCH" : 19,
            "nameUIC" : "Boynes",
            "nameCH" : "PN 19",
            "code" : ""
        },
        {
            "codeUIC" : 543819,
            "codeCH" : 20,
            "nameUIC" : "Boynes",
            "nameCH" : "PN 20",
            "code" : ""
        },
        {
            "codeUIC" : 543835,
            "codeCH" : "EC",
            "nameUIC" : "Beaune-la-Rolande",
            "nameCH" : "ITE COP Alleman Europe",
            "code" : "BRL"
        },
        {
            "codeUIC" : 543835,
            "codeCH" : 0,
            "nameUIC" : "Beaune-la-Rolande",
            "nameCH" : "",
            "code" : "BRL"
        },
        {
            "codeUIC" : 543835,
            "codeCH" : 27,
            "nameUIC" : "Beaune-la-Rolande",
            "nameCH" : "PN 27",
            "code" : "BRL"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "AT",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Accès Triage",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "ES",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Entrée/sortie P2/P3",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "FR",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Triage",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "PX",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Poste X",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "RO",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Faisceau RO",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "ST",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Poste 5",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "S5",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Arrêt TAA",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "S7",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Carré Violet 427",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "S8",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Sémaphore 715",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "S9",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Carré 309",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545004,
            "codeCH" : "TR",
            "nameUIC" : "Paris-Tolbiac",
            "nameCH" : "Triage Sud",
            "code" : "PTO"
        },
        {
            "codeUIC" : 545111,
            "codeCH" : 0,
            "nameUIC" : "Monnerville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545129,
            "codeCH" : "BV",
            "nameUIC" : "Guillerval",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 545129,
            "codeCH" : "DV",
            "nameUIC" : "Guillerval",
            "nameCH" : "Déviation Voie 1Bis/Voie 1, Voie 2/Voie 1P",
            "code" : ""
        },
        {
            "codeUIC" : 545137,
            "codeCH" : "BV",
            "nameUIC" : "Étampes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ETM"
        },
        {
            "codeUIC" : 545137,
            "codeCH" : "GM",
            "nameUIC" : "Étampes",
            "nameCH" : "Garage Morigny",
            "code" : "ETM"
        },
        {
            "codeUIC" : 545137,
            "codeCH" : "PB",
            "nameUIC" : "Étampes",
            "nameCH" : "Poste B",
            "code" : "ETM"
        },
        {
            "codeUIC" : 545137,
            "codeCH" : "PM",
            "nameUIC" : "Étampes",
            "nameCH" : "Poste M",
            "code" : "ETM"
        },
        {
            "codeUIC" : 545145,
            "codeCH" : 0,
            "nameUIC" : "Étréchy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545152,
            "codeCH" : 0,
            "nameUIC" : "Chamarande",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545160,
            "codeCH" : 0,
            "nameUIC" : "Lardy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545178,
            "codeCH" : 0,
            "nameUIC" : "Bouray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545186,
            "codeCH" : 0,
            "nameUIC" : "Marolles-en-Hurepoix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "BV",
            "nameUIC" : "Brétigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "PA",
            "nameUIC" : "Brétigny",
            "nameCH" : "Poste A",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "PC",
            "nameUIC" : "Brétigny",
            "nameCH" : "Poste C",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "PD",
            "nameUIC" : "Brétigny",
            "nameCH" : "Poste D",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "PF",
            "nameUIC" : "Brétigny",
            "nameCH" : "Poste F",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "TI",
            "nameUIC" : "Brétigny",
            "nameCH" : "Triage Impair",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "TR",
            "nameUIC" : "Brétigny",
            "nameCH" : "Triage Pair",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : "1B",
            "nameUIC" : "Brétigny",
            "nameCH" : "Déviation Voie 1/Voie 1Bis",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : 42,
            "nameUIC" : "Brétigny",
            "nameCH" : "Carré Violet 42",
            "code" : "BY"
        },
        {
            "codeUIC" : 545194,
            "codeCH" : 84,
            "nameUIC" : "Brétigny",
            "nameCH" : "V 83 ou V 84",
            "code" : "BY"
        },
        {
            "codeUIC" : 545202,
            "codeCH" : 0,
            "nameUIC" : "St-Michel-sur-Orge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545210,
            "codeCH" : 0,
            "nameUIC" : "Ste-Geneviève-des-Bois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545228,
            "codeCH" : 0,
            "nameUIC" : "Épinay-sur-Orge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545236,
            "codeCH" : "BV",
            "nameUIC" : "Savigny-sur-Orge",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 545236,
            "codeCH" : "PT",
            "nameUIC" : "Savigny-sur-Orge",
            "nameCH" : "Bifurcation Grande Ceinture",
            "code" : ""
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "AC",
            "nameUIC" : "Juvisy",
            "nameCH" : "Carré 312",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "AD",
            "nameUIC" : "Juvisy",
            "nameCH" : "Accès Voie DTA/Voies 2 et 2Bis",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "AN",
            "nameUIC" : "Juvisy",
            "nameCH" : "Point H Accès Nord",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "AS",
            "nameUIC" : "Juvisy",
            "nameCH" : "Point K Accès Sud",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "BV",
            "nameUIC" : "Juvisy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "ES",
            "nameUIC" : "Juvisy",
            "nameCH" : "Entrée/Sortie Voie DTA",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "FA",
            "nameUIC" : "Juvisy",
            "nameCH" : "Faisceau Attente Départ",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "FR",
            "nameUIC" : "Juvisy",
            "nameCH" : "Faisceau Réception",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "LO",
            "nameUIC" : "Juvisy",
            "nameCH" : "Faisceau Marchandises Local",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "MV",
            "nameUIC" : "Juvisy",
            "nameCH" : "Entrée MVI/Sortie MVP",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "PB",
            "nameUIC" : "Juvisy",
            "nameCH" : "Poste B",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "PC",
            "nameUIC" : "Juvisy",
            "nameCH" : "Point C",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "PD",
            "nameUIC" : "Juvisy",
            "nameCH" : "Jonctions SE/SO",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "PI",
            "nameUIC" : "Juvisy",
            "nameCH" : "Sortie V1M/Entrée V2M",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "PK",
            "nameUIC" : "Juvisy",
            "nameCH" : "Entrée/Sortie Triage",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "PO",
            "nameUIC" : "Juvisy",
            "nameCH" : "Poste O CH FOR",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "PR",
            "nameUIC" : "Juvisy",
            "nameCH" : "Sortie EV1/Entrée EV2",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "P2",
            "nameUIC" : "Juvisy",
            "nameCH" : "Poste 2",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "RB",
            "nameUIC" : "Juvisy",
            "nameCH" : "Faisceau Rebrousement",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "TI",
            "nameUIC" : "Juvisy",
            "nameCH" : "Tiroirs 102,104",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "ZN",
            "nameUIC" : "Juvisy",
            "nameCH" : "Entrée/Sortie TAC",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : 19,
            "nameUIC" : "Juvisy",
            "nameCH" : "Relais ADC",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : "2B",
            "nameUIC" : "Juvisy",
            "nameCH" : "Accès Rebroussement/ Voie 2Bis",
            "code" : "JY"
        },
        {
            "codeUIC" : 545244,
            "codeCH" : 28,
            "nameUIC" : "Juvisy",
            "nameCH" : "Signal 28",
            "code" : "JY"
        },
        {
            "codeUIC" : 545251,
            "codeCH" : 0,
            "nameUIC" : "Athis-Mons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545269,
            "codeCH" : 0,
            "nameUIC" : "Ablon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545277,
            "codeCH" : "EM",
            "nameUIC" : "Villeneuve-le-Roi",
            "nameCH" : "ITE Morillon Corvol",
            "code" : "VRI"
        },
        {
            "codeUIC" : 545277,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-le-Roi",
            "nameCH" : "",
            "code" : "VRI"
        },
        {
            "codeUIC" : 545285,
            "codeCH" : "BV",
            "nameUIC" : "Choisy-le-Roi",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CLR"
        },
        {
            "codeUIC" : 545285,
            "codeCH" : "DS",
            "nameUIC" : "Choisy-le-Roi",
            "nameCH" : "Déviation Sud",
            "code" : "CLR"
        },
        {
            "codeUIC" : 545285,
            "codeCH" : "EC",
            "nameUIC" : "Choisy-le-Roi",
            "nameCH" : "ITE Choisy Pair",
            "code" : "CLR"
        },
        {
            "codeUIC" : 545285,
            "codeCH" : "V1",
            "nameUIC" : "Choisy-le-Roi",
            "nameCH" : "Déviation Voie 1Bis/Voie 1",
            "code" : "CLR"
        },
        {
            "codeUIC" : 545285,
            "codeCH" : "1M",
            "nameUIC" : "Choisy-le-Roi",
            "nameCH" : "Entrée/Sortie Voie 1M",
            "code" : "CLR"
        },
        {
            "codeUIC" : 545293,
            "codeCH" : "BV",
            "nameUIC" : "Vitry-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VY"
        },
        {
            "codeUIC" : 545293,
            "codeCH" : "MA",
            "nameUIC" : "Vitry-sur-Seine",
            "nameCH" : "Faisceau Marchandises",
            "code" : "VY"
        },
        {
            "codeUIC" : 545293,
            "codeCH" : "RB",
            "nameUIC" : "Vitry-sur-Seine",
            "nameCH" : "Chantier Rames Banlieue",
            "code" : "VY"
        },
        {
            "codeUIC" : 545293,
            "codeCH" : "RP",
            "nameUIC" : "Vitry-sur-Seine",
            "nameCH" : "ITE Rhône-Poulenc",
            "code" : "VY"
        },
        {
            "codeUIC" : 545293,
            "codeCH" : "1M",
            "nameUIC" : "Vitry-sur-Seine",
            "nameCH" : "Accès Voie 1M",
            "code" : "VY"
        },
        {
            "codeUIC" : 545293,
            "codeCH" : "2M",
            "nameUIC" : "Vitry-sur-Seine",
            "nameCH" : "Sortie Voie 2M",
            "code" : "VY"
        },
        {
            "codeUIC" : 545293,
            "codeCH" : 81,
            "nameUIC" : "Vitry-sur-Seine",
            "nameCH" : "Carrés 811,812,813",
            "code" : "VY"
        },
        {
            "codeUIC" : 545301,
            "codeCH" : 0,
            "nameUIC" : "Ivry-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545350,
            "codeCH" : "TI",
            "nameUIC" : "St-Martin-d'Étampes",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 545350,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-d'Étampes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545418,
            "codeCH" : 0,
            "nameUIC" : "Theuville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545459,
            "codeCH" : 0,
            "nameUIC" : "La Norville-St-Germain-lès-Arp",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545467,
            "codeCH" : "BV",
            "nameUIC" : "Arpajon (Essonne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 545475,
            "codeCH" : 0,
            "nameUIC" : "Égly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545483,
            "codeCH" : 0,
            "nameUIC" : "Breuillet-Bruyères-le-Châtel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545491,
            "codeCH" : 0,
            "nameUIC" : "Breuillet-Village",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545509,
            "codeCH" : "BV",
            "nameUIC" : "St-Chéron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 545517,
            "codeCH" : 0,
            "nameUIC" : "Sermaise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545525,
            "codeCH" : "BV",
            "nameUIC" : "Dourdan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "D"
        },
        {
            "codeUIC" : 545616,
            "codeCH" : 0,
            "nameUIC" : "Berchères-les-Pierres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 545657,
            "codeCH" : 0,
            "nameUIC" : "Auneau",
            "nameCH" : "",
            "code" : "AUN"
        },
        {
            "codeUIC" : 545681,
            "codeCH" : 0,
            "nameUIC" : "Allonnes-Boisville",
            "nameCH" : "",
            "code" : "ALO"
        },
        {
            "codeUIC" : 545707,
            "codeCH" : "BV",
            "nameUIC" : "Voves",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VO"
        },
        {
            "codeUIC" : 545731,
            "codeCH" : "BV",
            "nameUIC" : "Bonneval",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BO"
        },
        {
            "codeUIC" : 545731,
            "codeCH" : "EC",
            "nameUIC" : "Bonneval",
            "nameCH" : "ITE Citec",
            "code" : "BO"
        },
        {
            "codeUIC" : 545756,
            "codeCH" : "BV",
            "nameUIC" : "Châteaudun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 545756,
            "codeCH" : "EA",
            "nameUIC" : "Châteaudun",
            "nameCH" : "ITE Ministère de l'air",
            "code" : ""
        },
        {
            "codeUIC" : 545756,
            "codeCH" : "EE",
            "nameUIC" : "Châteaudun",
            "nameCH" : "ITE Ergm",
            "code" : ""
        },
        {
            "codeUIC" : 545756,
            "codeCH" : "EG",
            "nameUIC" : "Châteaudun",
            "nameCH" : "ITE GDF",
            "code" : ""
        },
        {
            "codeUIC" : 545756,
            "codeCH" : "EM",
            "nameUIC" : "Châteaudun",
            "nameCH" : "ITE Mafca",
            "code" : ""
        },
        {
            "codeUIC" : 545756,
            "codeCH" : "ER",
            "nameUIC" : "Châteaudun",
            "nameCH" : "ITE Rousset et Fils",
            "code" : ""
        },
        {
            "codeUIC" : 545772,
            "codeCH" : "EM",
            "nameUIC" : "Péronville",
            "nameCH" : "ITE SA Moreau",
            "code" : ""
        },
        {
            "codeUIC" : 545772,
            "codeCH" : "LO",
            "nameUIC" : "Péronville",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 545772,
            "codeCH" : 40,
            "nameUIC" : "Péronville",
            "nameCH" : "PN 40",
            "code" : ""
        },
        {
            "codeUIC" : 545780,
            "codeCH" : "EC",
            "nameUIC" : "Civry-St-Cloud",
            "nameCH" : "ITE Coopérative Agricole DUNOIS",
            "code" : "CYD"
        },
        {
            "codeUIC" : 545780,
            "codeCH" : "LO",
            "nameUIC" : "Civry-St-Cloud",
            "nameCH" : "Local",
            "code" : "CYD"
        },
        {
            "codeUIC" : 545780,
            "codeCH" : 28,
            "nameUIC" : "Civry-St-Cloud",
            "nameCH" : "PN 28",
            "code" : "CYD"
        },
        {
            "codeUIC" : 545780,
            "codeCH" : 32,
            "nameUIC" : "Civry-St-Cloud",
            "nameCH" : "PN 32",
            "code" : "CYD"
        },
        {
            "codeUIC" : 546002,
            "codeCH" : "AO",
            "nameUIC" : "Rungis (MIN)",
            "nameCH" : "Accès Ouest",
            "code" : "RGS"
        },
        {
            "codeUIC" : 546002,
            "codeCH" : "GE",
            "nameUIC" : "Rungis (MIN)",
            "nameCH" : "Arrêt",
            "code" : "RGS"
        },
        {
            "codeUIC" : 546002,
            "codeCH" : 40,
            "nameUIC" : "Rungis (MIN)",
            "nameCH" : "Carré 40",
            "code" : "RGS"
        },
        {
            "codeUIC" : 546192,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Rungis-Aéroport-d'Orly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 546200,
            "codeCH" : 0,
            "nameUIC" : "Orly-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 546226,
            "codeCH" : 0,
            "nameUIC" : "Les Saules",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 546291,
            "codeCH" : 0,
            "nameUIC" : "Rungis-la-Fraternelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 546309,
            "codeCH" : 0,
            "nameUIC" : "Wissous",
            "nameCH" : "",
            "code" : "WIS"
        },
        {
            "codeUIC" : 546317,
            "codeCH" : 0,
            "nameUIC" : "Chemin-d'Antony",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 546416,
            "codeCH" : "BV",
            "nameUIC" : "Loury-Rebréchien",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "BV",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "CM",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Chantier des Messageries",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "DP",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "ES",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Entrée/Sortie Voie 2Ter",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "MA",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Massena",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "PA",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Point d'Arrêt Faisceau 31 à 34",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "1S",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Carré 505 et 705",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : "2S",
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Carré 106",
            "code" : ""
        },
        {
            "codeUIC" : 547000,
            "codeCH" : 42,
            "nameUIC" : "Paris-Austerlitz",
            "nameCH" : "Carré 142",
            "code" : ""
        },
        {
            "codeUIC" : 547026,
            "codeCH" : "BV",
            "nameUIC" : "Paris-Austerlitz (Banlieue)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 547026,
            "codeCH" : 31,
            "nameUIC" : "Paris-Austerlitz (Banlieue)",
            "nameCH" : "Carré 583",
            "code" : ""
        },
        {
            "codeUIC" : 547307,
            "codeCH" : "BV",
            "nameUIC" : "Musée-d'Orsay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 547307,
            "codeCH" : "GG",
            "nameUIC" : "Musée-d'Orsay",
            "nameCH" : "Garage",
            "code" : ""
        },
        {
            "codeUIC" : 547307,
            "codeCH" : "SG",
            "nameUIC" : "Musée-d'Orsay",
            "nameCH" : "Carré 112",
            "code" : ""
        },
        {
            "codeUIC" : 547307,
            "codeCH" : "SI",
            "nameUIC" : "Musée-d'Orsay",
            "nameCH" : "Carré 103",
            "code" : ""
        },
        {
            "codeUIC" : 547315,
            "codeCH" : 0,
            "nameUIC" : "St-Michel-Notre-Dame",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 547315,
            "codeCH" : 56,
            "nameUIC" : "St-Michel-Notre-Dame",
            "nameCH" : "Carrés 568 et 566",
            "code" : ""
        },
        {
            "codeUIC" : 547323,
            "codeCH" : 0,
            "nameUIC" : "Boulevard-Masséna",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 556621,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 580,4",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 557777,
            "codeCH" : 0,
            "nameUIC" : "ITE Forges-d'Allevard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 557868,
            "codeCH" : 0,
            "nameUIC" : "ITE Bois-du-Dauphiné",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559070,
            "codeCH" : 0,
            "nameUIC" : "Nevers-Le Banlay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559443,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 181",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559450,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 201",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559468,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 202",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559476,
            "codeCH" : 0,
            "nameUIC" : "Carré 172",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559831,
            "codeCH" : 0,
            "nameUIC" : "Puy-Griou",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559849,
            "codeCH" : 0,
            "nameUIC" : "Viaduc de la Tardes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559856,
            "codeCH" : 0,
            "nameUIC" : "Viaduc De Neuvial",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559864,
            "codeCH" : 0,
            "nameUIC" : "Viaduc De La Sioule",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559872,
            "codeCH" : 0,
            "nameUIC" : "Viaduc du Bellon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 559880,
            "codeCH" : 0,
            "nameUIC" : "Viaduc de la Bouble",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 561712,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 126,6",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 561779,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 124",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 561795,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 107",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 561811,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 123",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 562421,
            "codeCH" : "C2",
            "nameUIC" : "Aulnat-Aéroport",
            "nameCH" : "Carré 299",
            "code" : "AUL"
        },
        {
            "codeUIC" : 562421,
            "codeCH" : 0,
            "nameUIC" : "Aulnat-Aéroport",
            "nameCH" : "",
            "code" : "AUL"
        },
        {
            "codeUIC" : 565143,
            "codeCH" : 0,
            "nameUIC" : "Halte de Fontanil-Lycée Drap",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 566919,
            "codeCH" : "AG",
            "nameUIC" : "Lentilly-Charpenay",
            "nameCH" : "Aiguille 316",
            "code" : ""
        },
        {
            "codeUIC" : 566919,
            "codeCH" : 0,
            "nameUIC" : "Lentilly-Charpenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 566935,
            "codeCH" : 0,
            "nameUIC" : "ITE Coop Agricole de Grenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 566943,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 10,6",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 566950,
            "codeCH" : 0,
            "nameUIC" : "ITE Km 11,1",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 567354,
            "codeCH" : 0,
            "nameUIC" : "ITE Récup Métaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571000,
            "codeCH" : "BV",
            "nameUIC" : "Tours",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TO"
        },
        {
            "codeUIC" : 571000,
            "codeCH" : "PO",
            "nameUIC" : "Tours",
            "nameCH" : "Poste O",
            "code" : "TO"
        },
        {
            "codeUIC" : 571216,
            "codeCH" : "BV",
            "nameUIC" : "Vendôme-Villiers-sur-Loir",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "BG",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Bifurcation de Grammont",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "BJ",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Bifurcation de Joué-les-Tours",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "BT",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Bifurcation de Tours",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "BV",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "FA",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Faisceau Attente Départ",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "IM",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Carré 237",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "PA",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Signal S102",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "P1",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 1",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "P2",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 2",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "P3",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 3",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "P5",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 5",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "RA",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Relais Annexe",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "RC",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Réception",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "RN",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Rac Entrée/Sortie Sud",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "RO",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Relais",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "RS",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Raccordement Sud",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "SC",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "St-Come",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "SE",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Sortie V1B/Entrée V2B",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "S2",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Sortie IPCS Voie 2",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "S9",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Dépôt Signal 97",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : 11,
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 11",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : 12,
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 12",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : 14,
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 14",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "4N",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 4 Nord",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571240,
            "codeCH" : "4S",
            "nameUIC" : "St-Pierre-des-Corps",
            "nameCH" : "Poste 4 Sud",
            "code" : "SPC"
        },
        {
            "codeUIC" : 571273,
            "codeCH" : "BV",
            "nameUIC" : "Montlouis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 571273,
            "codeCH" : "RN",
            "nameUIC" : "Montlouis",
            "nameCH" : "Raccordement Nord",
            "code" : ""
        },
        {
            "codeUIC" : 571273,
            "codeCH" : "RS",
            "nameUIC" : "Montlouis",
            "nameCH" : "Rac Entrée/Sortie Sud",
            "code" : ""
        },
        {
            "codeUIC" : 571406,
            "codeCH" : "BV",
            "nameUIC" : "Montbazon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 571414,
            "codeCH" : 0,
            "nameUIC" : "Veigné",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571422,
            "codeCH" : 0,
            "nameUIC" : "Esvres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571430,
            "codeCH" : 0,
            "nameUIC" : "Cormery",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571448,
            "codeCH" : 0,
            "nameUIC" : "Courçay-Tauxigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571455,
            "codeCH" : 0,
            "nameUIC" : "Reignac",
            "nameCH" : "",
            "code" : "RGA"
        },
        {
            "codeUIC" : 571463,
            "codeCH" : 0,
            "nameUIC" : "Chambourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571471,
            "codeCH" : 0,
            "nameUIC" : "Loches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571489,
            "codeCH" : 0,
            "nameUIC" : "Perrusson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571497,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-en-Touraine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571505,
            "codeCH" : 0,
            "nameUIC" : "Fondettes-St-Cyr",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571513,
            "codeCH" : "BV",
            "nameUIC" : "La Membrolle-sur-Choisille",
            "nameCH" : "BV Bifurcation PARIS",
            "code" : ""
        },
        {
            "codeUIC" : 571513,
            "codeCH" : 1,
            "nameUIC" : "La Membrolle-sur-Choisille",
            "nameCH" : "Carré C1",
            "code" : ""
        },
        {
            "codeUIC" : 571513,
            "codeCH" : 2,
            "nameUIC" : "La Membrolle-sur-Choisille",
            "nameCH" : "Signal 102",
            "code" : ""
        },
        {
            "codeUIC" : 571521,
            "codeCH" : 0,
            "nameUIC" : "Mettray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571539,
            "codeCH" : 0,
            "nameUIC" : "St-Antoine-du-Rocher",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571547,
            "codeCH" : 0,
            "nameUIC" : "Neuillé-Pont-Pierre",
            "nameCH" : "",
            "code" : "NPP"
        },
        {
            "codeUIC" : 571547,
            "codeCH" : 21,
            "nameUIC" : "Neuillé-Pont-Pierre",
            "nameCH" : "Carré C21",
            "code" : "NPP"
        },
        {
            "codeUIC" : 571547,
            "codeCH" : 22,
            "nameUIC" : "Neuillé-Pont-Pierre",
            "nameCH" : "Carré C22",
            "code" : "NPP"
        },
        {
            "codeUIC" : 571547,
            "codeCH" : 31,
            "nameUIC" : "Neuillé-Pont-Pierre",
            "nameCH" : "Carré C31",
            "code" : "NPP"
        },
        {
            "codeUIC" : 571547,
            "codeCH" : 32,
            "nameUIC" : "Neuillé-Pont-Pierre",
            "nameCH" : "Carré C32",
            "code" : "NPP"
        },
        {
            "codeUIC" : 571554,
            "codeCH" : 0,
            "nameUIC" : "St-Paterne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571554,
            "codeCH" : 36,
            "nameUIC" : "St-Paterne",
            "nameCH" : "Sémaphore S35 et S36",
            "code" : ""
        },
        {
            "codeUIC" : 571562,
            "codeCH" : 0,
            "nameUIC" : "Dissay-sous-Courcillon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571604,
            "codeCH" : 0,
            "nameUIC" : "Ballan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571612,
            "codeCH" : 0,
            "nameUIC" : "Druye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571620,
            "codeCH" : 0,
            "nameUIC" : "Vallères",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571638,
            "codeCH" : 0,
            "nameUIC" : "Azay-le-Rideau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571653,
            "codeCH" : 0,
            "nameUIC" : "Rivarennes-Quinçay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571661,
            "codeCH" : 0,
            "nameUIC" : "St-Benoît-Rigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571661,
            "codeCH" : 33,
            "nameUIC" : "St-Benoît-Rigny",
            "nameCH" : "Sémaphores 331 332",
            "code" : ""
        },
        {
            "codeUIC" : 571687,
            "codeCH" : "BP",
            "nameUIC" : "Chinon",
            "nameCH" : "Bifurcation Port-Piles",
            "code" : ""
        },
        {
            "codeUIC" : 571687,
            "codeCH" : "BV",
            "nameUIC" : "Chinon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 571703,
            "codeCH" : 0,
            "nameUIC" : "La Roche-Clermault",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571711,
            "codeCH" : "BV",
            "nameUIC" : "Beuxes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 571711,
            "codeCH" : "EU",
            "nameUIC" : "Beuxes",
            "nameCH" : "ITE Union Vienne-Loire",
            "code" : ""
        },
        {
            "codeUIC" : 571745,
            "codeCH" : 0,
            "nameUIC" : "Ligré-Rivière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571786,
            "codeCH" : 0,
            "nameUIC" : "Varennes-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571794,
            "codeCH" : 0,
            "nameUIC" : "Port-Boulet",
            "nameCH" : "",
            "code" : "PBT"
        },
        {
            "codeUIC" : 571794,
            "codeCH" : 1,
            "nameUIC" : "Port-Boulet",
            "nameCH" : "Signal 101",
            "code" : "PBT"
        },
        {
            "codeUIC" : 571794,
            "codeCH" : 2,
            "nameUIC" : "Port-Boulet",
            "nameCH" : "Signal 102",
            "code" : "PBT"
        },
        {
            "codeUIC" : 571794,
            "codeCH" : 11,
            "nameUIC" : "Port-Boulet",
            "nameCH" : "Signal 111",
            "code" : "PBT"
        },
        {
            "codeUIC" : 571794,
            "codeCH" : 12,
            "nameUIC" : "Port-Boulet",
            "nameCH" : "Signal 112",
            "code" : "PBT"
        },
        {
            "codeUIC" : 571794,
            "codeCH" : 91,
            "nameUIC" : "Port-Boulet",
            "nameCH" : "Signal 91",
            "code" : "PBT"
        },
        {
            "codeUIC" : 571802,
            "codeCH" : "EL",
            "nameUIC" : "La Chapelle-sur-Loire",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 571802,
            "codeCH" : 0,
            "nameUIC" : "La Chapelle-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571810,
            "codeCH" : 0,
            "nameUIC" : "St-Patrice",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : "BV",
            "nameUIC" : "Langeais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : 31,
            "nameUIC" : "Langeais",
            "nameCH" : "Signal 31",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : 32,
            "nameUIC" : "Langeais",
            "nameCH" : "Signal 32",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : 42,
            "nameUIC" : "Langeais",
            "nameCH" : "Signal 42",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : 51,
            "nameUIC" : "Langeais",
            "nameCH" : "Signal 51",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : 52,
            "nameUIC" : "Langeais",
            "nameCH" : "Signal 52",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : 72,
            "nameUIC" : "Langeais",
            "nameCH" : "Signal 72",
            "code" : ""
        },
        {
            "codeUIC" : 571828,
            "codeCH" : 81,
            "nameUIC" : "Langeais",
            "nameCH" : "Signal 81",
            "code" : ""
        },
        {
            "codeUIC" : 571836,
            "codeCH" : "IP",
            "nameUIC" : "Cinq-Mars",
            "nameCH" : "Signal 22",
            "code" : ""
        },
        {
            "codeUIC" : 571836,
            "codeCH" : 0,
            "nameUIC" : "Cinq-Mars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571844,
            "codeCH" : 0,
            "nameUIC" : "Savonnières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571851,
            "codeCH" : 0,
            "nameUIC" : "St-Genouph",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 571885,
            "codeCH" : "BV",
            "nameUIC" : "Joué-lès-Tours",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JLT"
        },
        {
            "codeUIC" : 571885,
            "codeCH" : "IT",
            "nameUIC" : "Joué-lès-Tours",
            "nameCH" : "ITE SOCOFER",
            "code" : "JLT"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "AN",
            "nameUIC" : "Blois",
            "nameCH" : "Accès Nord",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "AS",
            "nameUIC" : "Blois",
            "nameCH" : "Accès Sud",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "BV",
            "nameUIC" : "Blois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "EB",
            "nameUIC" : "Blois",
            "nameCH" : "ITE SLB",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "EL",
            "nameUIC" : "Blois",
            "nameCH" : "ITE LIGEA 2-Franciade 2",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "EQ",
            "nameUIC" : "Blois",
            "nameCH" : "ITE QUEBECOR",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "ES",
            "nameUIC" : "Blois",
            "nameCH" : "ITE LIGEA 3-SERCO",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "EU",
            "nameUIC" : "Blois",
            "nameCH" : "ITE Unisabi",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : "EV",
            "nameUIC" : "Blois",
            "nameCH" : "ITE Coopérative des agriculteurs du Loir-et-Cher",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : 69,
            "nameUIC" : "Blois",
            "nameCH" : "PN 69",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : 70,
            "nameUIC" : "Blois",
            "nameCH" : "PN 70",
            "code" : "BL"
        },
        {
            "codeUIC" : 574004,
            "codeCH" : 72,
            "nameUIC" : "Blois",
            "nameCH" : "PN 72",
            "code" : "BL"
        },
        {
            "codeUIC" : 574111,
            "codeCH" : 0,
            "nameUIC" : "Chaingy-Fourneaux-Plage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574129,
            "codeCH" : "BV",
            "nameUIC" : "St-Ay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574129,
            "codeCH" : "IC",
            "nameUIC" : "St-Ay",
            "nameCH" : "IPCS La-Barre",
            "code" : ""
        },
        {
            "codeUIC" : 574137,
            "codeCH" : "BV",
            "nameUIC" : "Meung-sur-Loire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574137,
            "codeCH" : "SE",
            "nameUIC" : "Meung-sur-Loire",
            "nameCH" : "Sortie Évitement impair",
            "code" : ""
        },
        {
            "codeUIC" : 574152,
            "codeCH" : 0,
            "nameUIC" : "Baule",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574160,
            "codeCH" : "BV",
            "nameUIC" : "Beaugency",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574160,
            "codeCH" : "EF",
            "nameUIC" : "Beaugency",
            "nameCH" : "ITE Coopérative Céréales du Loir-et-Cher",
            "code" : ""
        },
        {
            "codeUIC" : 574160,
            "codeCH" : "IC",
            "nameUIC" : "Beaugency",
            "nameCH" : "IPCS Villeneuve",
            "code" : ""
        },
        {
            "codeUIC" : 574194,
            "codeCH" : "BV",
            "nameUIC" : "Mer (Loir-et-Cher)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MER"
        },
        {
            "codeUIC" : 574194,
            "codeCH" : "EP",
            "nameUIC" : "Mer (Loir-et-Cher)",
            "nameCH" : "Sortie Évitement Pair",
            "code" : "MER"
        },
        {
            "codeUIC" : 574194,
            "codeCH" : "IC",
            "nameUIC" : "Mer (Loir-et-Cher)",
            "nameCH" : "IPCS Beaumont",
            "code" : "MER"
        },
        {
            "codeUIC" : 574194,
            "codeCH" : "V3",
            "nameUIC" : "Mer (Loir-et-Cher)",
            "nameCH" : "Sortie Évitement Impair",
            "code" : "MER"
        },
        {
            "codeUIC" : 574236,
            "codeCH" : 0,
            "nameUIC" : "Suèvres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574244,
            "codeCH" : "BV",
            "nameUIC" : "Menars",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574244,
            "codeCH" : "IC",
            "nameUIC" : "Menars",
            "nameCH" : "IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 574251,
            "codeCH" : 0,
            "nameUIC" : "La Chaussée-St-Victor",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574301,
            "codeCH" : 0,
            "nameUIC" : "Chouzy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574319,
            "codeCH" : "BV",
            "nameUIC" : "Onzain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574319,
            "codeCH" : "IC",
            "nameUIC" : "Onzain",
            "nameCH" : "IPCS Km 192",
            "code" : ""
        },
        {
            "codeUIC" : 574319,
            "codeCH" : "SI",
            "nameUIC" : "Onzain",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 574319,
            "codeCH" : "SP",
            "nameUIC" : "Onzain",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 574327,
            "codeCH" : 0,
            "nameUIC" : "Veuves-Monteaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574335,
            "codeCH" : "BV",
            "nameUIC" : "Limeray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574335,
            "codeCH" : "IC",
            "nameUIC" : "Limeray",
            "nameCH" : "IPCS Limeray",
            "code" : ""
        },
        {
            "codeUIC" : 574343,
            "codeCH" : "BV",
            "nameUIC" : "Amboise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574343,
            "codeCH" : "SB",
            "nameUIC" : "Amboise",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 574343,
            "codeCH" : "SI",
            "nameUIC" : "Amboise",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 574368,
            "codeCH" : "BV",
            "nameUIC" : "Noizay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574368,
            "codeCH" : "IC",
            "nameUIC" : "Noizay",
            "nameCH" : "IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 574376,
            "codeCH" : 0,
            "nameUIC" : "Vernou-les-Varennes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574400,
            "codeCH" : "BV",
            "nameUIC" : "Fossé-Marolles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FOM"
        },
        {
            "codeUIC" : 574400,
            "codeCH" : "EJ",
            "nameUIC" : "Fossé-Marolles",
            "nameCH" : "ITE Jean Lefèvre",
            "code" : "FOM"
        },
        {
            "codeUIC" : 574400,
            "codeCH" : 64,
            "nameUIC" : "Fossé-Marolles",
            "nameCH" : "PN 64",
            "code" : "FOM"
        },
        {
            "codeUIC" : 574418,
            "codeCH" : "BV",
            "nameUIC" : "La Chapelle-Vendômoise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574418,
            "codeCH" : 59,
            "nameUIC" : "La Chapelle-Vendômoise",
            "nameCH" : "PN 59",
            "code" : ""
        },
        {
            "codeUIC" : 574418,
            "codeCH" : 60,
            "nameUIC" : "La Chapelle-Vendômoise",
            "nameCH" : "PN 60-2",
            "code" : ""
        },
        {
            "codeUIC" : 574426,
            "codeCH" : "EA",
            "nameUIC" : "Villefrancoeur",
            "nameCH" : "ITE Agrinégoce",
            "code" : ""
        },
        {
            "codeUIC" : 574426,
            "codeCH" : 0,
            "nameUIC" : "Villefrancoeur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574434,
            "codeCH" : 0,
            "nameUIC" : "Veretz-Montlouis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574442,
            "codeCH" : "BV",
            "nameUIC" : "Azay-sur-Cher",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574442,
            "codeCH" : "IM",
            "nameUIC" : "Azay-sur-Cher",
            "nameCH" : "Signal S95",
            "code" : ""
        },
        {
            "codeUIC" : 574442,
            "codeCH" : "PA",
            "nameUIC" : "Azay-sur-Cher",
            "nameCH" : "Signal S96",
            "code" : ""
        },
        {
            "codeUIC" : 574459,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-le-Beau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574475,
            "codeCH" : "BV",
            "nameUIC" : "Bléré-la-Croix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574475,
            "codeCH" : "IM",
            "nameUIC" : "Bléré-la-Croix",
            "nameCH" : "Carré 81",
            "code" : ""
        },
        {
            "codeUIC" : 574475,
            "codeCH" : "IP",
            "nameUIC" : "Bléré-la-Croix",
            "nameCH" : "Carré 91",
            "code" : ""
        },
        {
            "codeUIC" : 574475,
            "codeCH" : "PA",
            "nameUIC" : "Bléré-la-Croix",
            "nameCH" : "Signal S82",
            "code" : ""
        },
        {
            "codeUIC" : 574475,
            "codeCH" : "PI",
            "nameUIC" : "Bléré-la-Croix",
            "nameCH" : "Carré 92",
            "code" : ""
        },
        {
            "codeUIC" : 574483,
            "codeCH" : "BV",
            "nameUIC" : "Civray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574483,
            "codeCH" : "EM",
            "nameUIC" : "Civray",
            "nameCH" : "ITE Meunier",
            "code" : ""
        },
        {
            "codeUIC" : 574483,
            "codeCH" : "N0",
            "nameUIC" : "Civray",
            "nameCH" : "PN 300",
            "code" : ""
        },
        {
            "codeUIC" : 574483,
            "codeCH" : "N1",
            "nameUIC" : "Civray",
            "nameCH" : "PN 301",
            "code" : ""
        },
        {
            "codeUIC" : 574483,
            "codeCH" : 0,
            "nameUIC" : "Civray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574491,
            "codeCH" : 0,
            "nameUIC" : "Chenonceaux-Chisseaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574509,
            "codeCH" : 0,
            "nameUIC" : "Cloyes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574533,
            "codeCH" : 0,
            "nameUIC" : "Fréteval-Morée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574541,
            "codeCH" : "BV",
            "nameUIC" : "Pezou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PEZ"
        },
        {
            "codeUIC" : 574541,
            "codeCH" : "EU",
            "nameUIC" : "Pezou",
            "nameCH" : "ITE U C Agricole du Loir-et-Cher",
            "code" : "PEZ"
        },
        {
            "codeUIC" : 574558,
            "codeCH" : "BV",
            "nameUIC" : "Vendôme",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "Vosves"
        },
        {
            "codeUIC" : 574558,
            "codeCH" : "EC",
            "nameUIC" : "Vendôme",
            "nameCH" : "ITE Comp Française de Raffinage",
            "code" : "Vosves"
        },
        {
            "codeUIC" : 574558,
            "codeCH" : "ES",
            "nameUIC" : "Vendôme",
            "nameCH" : "ITE Sepchat",
            "code" : "Vosves"
        },
        {
            "codeUIC" : 574558,
            "codeCH" : "RP",
            "nameUIC" : "Vendôme",
            "nameCH" : "Bifurcation de Braye",
            "code" : "Vosves"
        },
        {
            "codeUIC" : 574640,
            "codeCH" : 0,
            "nameUIC" : "St-Amand-de-Vendôme",
            "nameCH" : "",
            "code" : "SAV"
        },
        {
            "codeUIC" : 574665,
            "codeCH" : 0,
            "nameUIC" : "Château-Renault",
            "nameCH" : "",
            "code" : "CRA"
        },
        {
            "codeUIC" : 574673,
            "codeCH" : "ET",
            "nameUIC" : "Crotelles",
            "nameCH" : "ITE Établissement G. Tessier",
            "code" : "CID"
        },
        {
            "codeUIC" : 574681,
            "codeCH" : "BV",
            "nameUIC" : "Monnaie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MON"
        },
        {
            "codeUIC" : 574681,
            "codeCH" : "EA",
            "nameUIC" : "Monnaie",
            "nameCH" : "ITE Service des Essences",
            "code" : "MON"
        },
        {
            "codeUIC" : 574699,
            "codeCH" : 0,
            "nameUIC" : "Notre-Dame-d'Oé",
            "nameCH" : "",
            "code" : "NDA"
        },
        {
            "codeUIC" : 574715,
            "codeCH" : "LO",
            "nameUIC" : "Selommes",
            "nameCH" : "Local",
            "code" : "SOE"
        },
        {
            "codeUIC" : 574715,
            "codeCH" : 48,
            "nameUIC" : "Selommes",
            "nameCH" : "PN 48",
            "code" : "SOE"
        },
        {
            "codeUIC" : 574715,
            "codeCH" : 49,
            "nameUIC" : "Selommes",
            "nameCH" : "PN 49",
            "code" : "SOE"
        },
        {
            "codeUIC" : 574723,
            "codeCH" : "LO",
            "nameUIC" : "Villetrun-Coulommiers",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 574723,
            "codeCH" : 43,
            "nameUIC" : "Villetrun-Coulommiers",
            "nameCH" : "PN 43",
            "code" : ""
        },
        {
            "codeUIC" : 574756,
            "codeCH" : "LO",
            "nameUIC" : "Thoré-la-Rochette",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 574756,
            "codeCH" : 27,
            "nameUIC" : "Thoré-la-Rochette",
            "nameCH" : "PN 27",
            "code" : ""
        },
        {
            "codeUIC" : 574756,
            "codeCH" : 31,
            "nameUIC" : "Thoré-la-Rochette",
            "nameCH" : "PN 31",
            "code" : ""
        },
        {
            "codeUIC" : 574756,
            "codeCH" : 36,
            "nameUIC" : "Thoré-la-Rochette",
            "nameCH" : "PN 36",
            "code" : ""
        },
        {
            "codeUIC" : 574764,
            "codeCH" : "EA",
            "nameUIC" : "Montoire-sur-le-Loir",
            "nameCH" : "ITE AGRALYS",
            "code" : "MUO"
        },
        {
            "codeUIC" : 574764,
            "codeCH" : "EF",
            "nameUIC" : "Montoire-sur-le-Loir",
            "nameCH" : "ITE Coopérative des agriculteurs du Loir-et-Cher",
            "code" : "MUO"
        },
        {
            "codeUIC" : 574764,
            "codeCH" : "EL",
            "nameUIC" : "Montoire-sur-le-Loir",
            "nameCH" : "ITE Coopérative des agriculteurs du Loir-et-Cher",
            "code" : "MUO"
        },
        {
            "codeUIC" : 574764,
            "codeCH" : "LO",
            "nameUIC" : "Montoire-sur-le-Loir",
            "nameCH" : "Local",
            "code" : "MUO"
        },
        {
            "codeUIC" : 574764,
            "codeCH" : 20,
            "nameUIC" : "Montoire-sur-le-Loir",
            "nameCH" : "PN 20",
            "code" : "MUO"
        },
        {
            "codeUIC" : 574772,
            "codeCH" : 0,
            "nameUIC" : "Troo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574848,
            "codeCH" : 0,
            "nameUIC" : "Chissay-en-Touraine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574855,
            "codeCH" : "BV",
            "nameUIC" : "Montrichard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 574855,
            "codeCH" : "IM",
            "nameUIC" : "Montrichard",
            "nameCH" : "Signal S75",
            "code" : ""
        },
        {
            "codeUIC" : 574855,
            "codeCH" : "PA",
            "nameUIC" : "Montrichard",
            "nameCH" : "Signal S76",
            "code" : ""
        },
        {
            "codeUIC" : 574863,
            "codeCH" : 0,
            "nameUIC" : "Bourré",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 574897,
            "codeCH" : "BV",
            "nameUIC" : "St-Aignan-Noyers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SAA"
        },
        {
            "codeUIC" : 574897,
            "codeCH" : "EC",
            "nameUIC" : "St-Aignan-Noyers",
            "nameCH" : "ITE Coopérative Agricole",
            "code" : "SAA"
        },
        {
            "codeUIC" : 574897,
            "codeCH" : "IM",
            "nameUIC" : "St-Aignan-Noyers",
            "nameCH" : "Carré 71",
            "code" : "SAA"
        },
        {
            "codeUIC" : 574897,
            "codeCH" : "IP",
            "nameUIC" : "St-Aignan-Noyers",
            "nameCH" : "Carré 61",
            "code" : "SAA"
        },
        {
            "codeUIC" : 574897,
            "codeCH" : "PA",
            "nameUIC" : "St-Aignan-Noyers",
            "nameCH" : "Carré 72",
            "code" : "SAA"
        },
        {
            "codeUIC" : 574897,
            "codeCH" : "PI",
            "nameUIC" : "St-Aignan-Noyers",
            "nameCH" : "Carré 62",
            "code" : "SAA"
        },
        {
            "codeUIC" : 575001,
            "codeCH" : "BC",
            "nameUIC" : "Poitiers",
            "nameCH" : "Bifurcation de Chalandray",
            "code" : "PS"
        },
        {
            "codeUIC" : 575001,
            "codeCH" : "BV",
            "nameUIC" : "Poitiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PS"
        },
        {
            "codeUIC" : 575001,
            "codeCH" : "P3",
            "nameUIC" : "Poitiers",
            "nameCH" : "Poste 3",
            "code" : "PS"
        },
        {
            "codeUIC" : 575001,
            "codeCH" : "P4",
            "nameUIC" : "Poitiers",
            "nameCH" : "Poste 4",
            "code" : "PS"
        },
        {
            "codeUIC" : 575019,
            "codeCH" : "EN",
            "nameUIC" : "Grand-Pont-Garage",
            "nameCH" : "Grand-Pont Nord",
            "code" : ""
        },
        {
            "codeUIC" : 575019,
            "codeCH" : "ES",
            "nameUIC" : "Grand-Pont-Garage",
            "nameCH" : "Grand-Pont Sud",
            "code" : ""
        },
        {
            "codeUIC" : 575019,
            "codeCH" : 0,
            "nameUIC" : "Grand-Pont-Garage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 575035,
            "codeCH" : "BV",
            "nameUIC" : "Monts (Indre-et-Loire)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575035,
            "codeCH" : "IC",
            "nameUIC" : "Monts (Indre-et-Loire)",
            "nameCH" : "IPCS de Monts",
            "code" : ""
        },
        {
            "codeUIC" : 575035,
            "codeCH" : "V1",
            "nameUIC" : "Monts (Indre-et-Loire)",
            "nameCH" : "Bifurcation V1",
            "code" : ""
        },
        {
            "codeUIC" : 575035,
            "codeCH" : "V2",
            "nameUIC" : "Monts (Indre-et-Loire)",
            "nameCH" : "Bifurcation V2",
            "code" : ""
        },
        {
            "codeUIC" : 575043,
            "codeCH" : "BV",
            "nameUIC" : "Villeperdue",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VIP"
        },
        {
            "codeUIC" : 575043,
            "codeCH" : "IC",
            "nameUIC" : "Villeperdue",
            "nameCH" : "IPCS",
            "code" : "VIP"
        },
        {
            "codeUIC" : 575043,
            "codeCH" : "SI",
            "nameUIC" : "Villeperdue",
            "nameCH" : "Sortie Évitement Impair",
            "code" : "VIP"
        },
        {
            "codeUIC" : 575043,
            "codeCH" : "SP",
            "nameUIC" : "Villeperdue",
            "nameCH" : "Sortie Évitement Pair",
            "code" : "VIP"
        },
        {
            "codeUIC" : 575050,
            "codeCH" : 0,
            "nameUIC" : "Ste-Maure-Noyant",
            "nameCH" : "",
            "code" : "SMR"
        },
        {
            "codeUIC" : 575068,
            "codeCH" : "IC",
            "nameUIC" : "Maillé",
            "nameCH" : "IPCS de Maillé",
            "code" : ""
        },
        {
            "codeUIC" : 575068,
            "codeCH" : 0,
            "nameUIC" : "Maillé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 575076,
            "codeCH" : "AN",
            "nameUIC" : "Port-de-Piles",
            "nameCH" : "Accès Nord",
            "code" : "PP"
        },
        {
            "codeUIC" : 575076,
            "codeCH" : "AS",
            "nameUIC" : "Port-de-Piles",
            "nameCH" : "Accès Sud",
            "code" : "PP"
        },
        {
            "codeUIC" : 575076,
            "codeCH" : "BV",
            "nameUIC" : "Port-de-Piles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PP"
        },
        {
            "codeUIC" : 575076,
            "codeCH" : "EE",
            "nameUIC" : "Port-de-Piles",
            "nameCH" : "ITE Everitube",
            "code" : "PP"
        },
        {
            "codeUIC" : 575076,
            "codeCH" : "SE",
            "nameUIC" : "Port-de-Piles",
            "nameCH" : "Sortie Évitement Impair",
            "code" : "PP"
        },
        {
            "codeUIC" : 575084,
            "codeCH" : "BC",
            "nameUIC" : "Loudun",
            "nameCH" : "Bifurcation de Chatteler",
            "code" : "LOD"
        },
        {
            "codeUIC" : 575084,
            "codeCH" : "BV",
            "nameUIC" : "Loudun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LOD"
        },
        {
            "codeUIC" : 575100,
            "codeCH" : "BF",
            "nameUIC" : "Arçay",
            "nameCH" : "Bifurcation St-Jean-de-Sauves",
            "code" : ""
        },
        {
            "codeUIC" : 575100,
            "codeCH" : "PA",
            "nameUIC" : "Arçay",
            "nameCH" : "Pancarte Arrêt",
            "code" : ""
        },
        {
            "codeUIC" : 575100,
            "codeCH" : "PN",
            "nameUIC" : "Arçay",
            "nameCH" : "PN 184",
            "code" : ""
        },
        {
            "codeUIC" : 575100,
            "codeCH" : 0,
            "nameUIC" : "Arçay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575118,
            "codeCH" : 0,
            "nameUIC" : "Les Ormes-sur-Vienne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LMV"
        },
        {
            "codeUIC" : 575126,
            "codeCH" : 0,
            "nameUIC" : "Dangé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575134,
            "codeCH" : "BV",
            "nameUIC" : "Ingrandes-sur-Vienne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "IG"
        },
        {
            "codeUIC" : 575134,
            "codeCH" : "EV",
            "nameUIC" : "Ingrandes-sur-Vienne",
            "nameCH" : "ITE Société Économie Mixte Région de Chatellerault",
            "code" : "IG"
        },
        {
            "codeUIC" : 575134,
            "codeCH" : "IC",
            "nameUIC" : "Ingrandes-sur-Vienne",
            "nameCH" : "IPCS La Fayette",
            "code" : "IG"
        },
        {
            "codeUIC" : 575134,
            "codeCH" : "PA",
            "nameUIC" : "Ingrandes-sur-Vienne",
            "nameCH" : "ITE St-Ustre-Poste A",
            "code" : "IG"
        },
        {
            "codeUIC" : 575134,
            "codeCH" : "PB",
            "nameUIC" : "Ingrandes-sur-Vienne",
            "nameCH" : "ITE St-Ustre-Poste B",
            "code" : "IG"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : "AN",
            "nameUIC" : "Châtellerault",
            "nameCH" : "Accès Nord",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : "AS",
            "nameUIC" : "Châtellerault",
            "nameCH" : "Accès Sud",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : "BL",
            "nameUIC" : "Châtellerault",
            "nameCH" : "Bifurcation de Loudun",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : "BV",
            "nameUIC" : "Châtellerault",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : "E2",
            "nameUIC" : "Châtellerault",
            "nameCH" : "Sortie Évitement Pair",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : "SC",
            "nameUIC" : "Châtellerault",
            "nameCH" : "Sortie IPCS",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : 65,
            "nameUIC" : "Châtellerault",
            "nameCH" : "PN 65",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575142,
            "codeCH" : 66,
            "nameUIC" : "Châtellerault",
            "nameCH" : "PN 66",
            "code" : "CRT"
        },
        {
            "codeUIC" : 575159,
            "codeCH" : "LO",
            "nameUIC" : "Châtellerault-Châteauneuf",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575159,
            "codeCH" : 64,
            "nameUIC" : "Châtellerault-Châteauneuf",
            "nameCH" : "PN 64",
            "code" : ""
        },
        {
            "codeUIC" : 575167,
            "codeCH" : 0,
            "nameUIC" : "Nerpuy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 575175,
            "codeCH" : "BV",
            "nameUIC" : "Naintré-les-Barres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NIB"
        },
        {
            "codeUIC" : 575183,
            "codeCH" : "IC",
            "nameUIC" : "La Tricherie",
            "nameCH" : "Poste 27",
            "code" : "LTR"
        },
        {
            "codeUIC" : 575183,
            "codeCH" : 0,
            "nameUIC" : "La Tricherie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LTR"
        },
        {
            "codeUIC" : 575191,
            "codeCH" : "BV",
            "nameUIC" : "Dissay (Vienne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575191,
            "codeCH" : "EM",
            "nameUIC" : "Dissay (Vienne)",
            "nameCH" : "ITE Municipalité",
            "code" : ""
        },
        {
            "codeUIC" : 575217,
            "codeCH" : "BV",
            "nameUIC" : "Jaunay-Clan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JAU"
        },
        {
            "codeUIC" : 575217,
            "codeCH" : "EZ",
            "nameUIC" : "Jaunay-Clan",
            "nameCH" : "ITE ZI Chasseneuil",
            "code" : "JAU"
        },
        {
            "codeUIC" : 575225,
            "codeCH" : "BV",
            "nameUIC" : "Chasseneuil (Vienne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHN"
        },
        {
            "codeUIC" : 575225,
            "codeCH" : "EE",
            "nameUIC" : "Chasseneuil (Vienne)",
            "nameCH" : "ITE Société Esso-Standard",
            "code" : "CHN"
        },
        {
            "codeUIC" : 575233,
            "codeCH" : 0,
            "nameUIC" : "Grand-Pont-Preuilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575258,
            "codeCH" : 0,
            "nameUIC" : "Martaizé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575266,
            "codeCH" : "BL",
            "nameUIC" : "St-Benoît (Vienne)",
            "nameCH" : "Bifurcation de Limoges",
            "code" : ""
        },
        {
            "codeUIC" : 575266,
            "codeCH" : "BV",
            "nameUIC" : "St-Benoît (Vienne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575266,
            "codeCH" : "IP",
            "nameUIC" : "St-Benoît (Vienne)",
            "nameCH" : "Carré 15",
            "code" : ""
        },
        {
            "codeUIC" : 575266,
            "codeCH" : "P2",
            "nameUIC" : "St-Benoît (Vienne)",
            "nameCH" : "Carré 2,3",
            "code" : ""
        },
        {
            "codeUIC" : 575266,
            "codeCH" : 11,
            "nameUIC" : "St-Benoît (Vienne)",
            "nameCH" : "Carré 11",
            "code" : ""
        },
        {
            "codeUIC" : 575274,
            "codeCH" : "BV",
            "nameUIC" : "Ligugé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575290,
            "codeCH" : 0,
            "nameUIC" : "Vivonne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575308,
            "codeCH" : 0,
            "nameUIC" : "Moncontour-de-Poitou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575316,
            "codeCH" : "LO",
            "nameUIC" : "St-Jean-de-Sauves",
            "nameCH" : "Local",
            "code" : "SJS"
        },
        {
            "codeUIC" : 575316,
            "codeCH" : 61,
            "nameUIC" : "St-Jean-de-Sauves",
            "nameCH" : "PN 61",
            "code" : "SJS"
        },
        {
            "codeUIC" : 575316,
            "codeCH" : 70,
            "nameUIC" : "St-Jean-de-Sauves",
            "nameCH" : "PN 70",
            "code" : "SJS"
        },
        {
            "codeUIC" : 575316,
            "codeCH" : 80,
            "nameUIC" : "St-Jean-de-Sauves",
            "nameCH" : "PN 80",
            "code" : "SJS"
        },
        {
            "codeUIC" : 575324,
            "codeCH" : "LO",
            "nameUIC" : "L'Île-Bouchard",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575324,
            "codeCH" : 47,
            "nameUIC" : "L'Île-Bouchard",
            "nameCH" : "PN 47",
            "code" : ""
        },
        {
            "codeUIC" : 575324,
            "codeCH" : 48,
            "nameUIC" : "L'Île-Bouchard",
            "nameCH" : "PN 48",
            "code" : ""
        },
        {
            "codeUIC" : 575324,
            "codeCH" : 49,
            "nameUIC" : "L'Île-Bouchard",
            "nameCH" : "PN 49",
            "code" : ""
        },
        {
            "codeUIC" : 575324,
            "codeCH" : 51,
            "nameUIC" : "L'Île-Bouchard",
            "nameCH" : "PN 51",
            "code" : ""
        },
        {
            "codeUIC" : 575324,
            "codeCH" : 52,
            "nameUIC" : "L'Île-Bouchard",
            "nameCH" : "PN 52",
            "code" : ""
        },
        {
            "codeUIC" : 575332,
            "codeCH" : 0,
            "nameUIC" : "Anché-Voulon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575340,
            "codeCH" : "BV",
            "nameUIC" : "Couhé-Vérac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COH"
        },
        {
            "codeUIC" : 575340,
            "codeCH" : "IC",
            "nameUIC" : "Couhé-Vérac",
            "nameCH" : "IPCS Les Chaumes",
            "code" : "COH"
        },
        {
            "codeUIC" : 575340,
            "codeCH" : "SI",
            "nameUIC" : "Couhé-Vérac",
            "nameCH" : "Sortie Voie Pair",
            "code" : "COH"
        },
        {
            "codeUIC" : 575340,
            "codeCH" : "SP",
            "nameUIC" : "Couhé-Vérac",
            "nameCH" : "Sortie Voie Impair",
            "code" : "COH"
        },
        {
            "codeUIC" : 575357,
            "codeCH" : 0,
            "nameUIC" : "Épanvilliers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575365,
            "codeCH" : "ET",
            "nameUIC" : "St-Saviol",
            "nameCH" : "ITE Textile industriel de l'ouest",
            "code" : "SSV"
        },
        {
            "codeUIC" : 575365,
            "codeCH" : 0,
            "nameUIC" : "St-Saviol",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SSV"
        },
        {
            "codeUIC" : 575399,
            "codeCH" : "EC",
            "nameUIC" : "Trogues",
            "nameCH" : "ITE SA Chaux",
            "code" : "TRG"
        },
        {
            "codeUIC" : 575399,
            "codeCH" : "LO",
            "nameUIC" : "Trogues",
            "nameCH" : "Local",
            "code" : "TRG"
        },
        {
            "codeUIC" : 575399,
            "codeCH" : 60,
            "nameUIC" : "Trogues",
            "nameCH" : "PN 60",
            "code" : "TRG"
        },
        {
            "codeUIC" : 575464,
            "codeCH" : "LO",
            "nameUIC" : "Le Bouchet",
            "nameCH" : "Local",
            "code" : "LBU"
        },
        {
            "codeUIC" : 575480,
            "codeCH" : "BV",
            "nameUIC" : "Montmorillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MMN"
        },
        {
            "codeUIC" : 575480,
            "codeCH" : "EC",
            "nameUIC" : "Montmorillon",
            "nameCH" : "ITE Centredis",
            "code" : "MMN"
        },
        {
            "codeUIC" : 575498,
            "codeCH" : "BV",
            "nameUIC" : "Lathus",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575498,
            "codeCH" : 25,
            "nameUIC" : "Lathus",
            "nameCH" : "Sémaphores 25 (31 et 32)",
            "code" : ""
        },
        {
            "codeUIC" : 575506,
            "codeCH" : 0,
            "nameUIC" : "Grand-Pont",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575514,
            "codeCH" : 0,
            "nameUIC" : "Migné-les-Lourdines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575522,
            "codeCH" : 0,
            "nameUIC" : "Avanton-Pache",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575530,
            "codeCH" : 0,
            "nameUIC" : "Neuville-de-Poitou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575548,
            "codeCH" : "LO",
            "nameUIC" : "Pouzay",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575555,
            "codeCH" : 0,
            "nameUIC" : "Villiers-Vouillé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 575563,
            "codeCH" : "BV",
            "nameUIC" : "Ayron-Latillé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AYA"
        },
        {
            "codeUIC" : 575563,
            "codeCH" : "EP",
            "nameUIC" : "Ayron-Latillé",
            "nameCH" : "ITE Terrena",
            "code" : "AYA"
        },
        {
            "codeUIC" : 575563,
            "codeCH" : 0,
            "nameUIC" : "Ayron-Latillé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AYA"
        },
        {
            "codeUIC" : 575571,
            "codeCH" : "BV",
            "nameUIC" : "Chalandray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDV"
        },
        {
            "codeUIC" : 575571,
            "codeCH" : "EP",
            "nameUIC" : "Chalandray",
            "nameCH" : "ITE Coopérative de Cherves",
            "code" : "CDV"
        },
        {
            "codeUIC" : 575571,
            "codeCH" : 0,
            "nameUIC" : "Chalandray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDV"
        },
        {
            "codeUIC" : 575589,
            "codeCH" : 0,
            "nameUIC" : "La Ferrière-Thénezay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575597,
            "codeCH" : 0,
            "nameUIC" : "La Peyratte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 575639,
            "codeCH" : "EM",
            "nameUIC" : "Nouatre-Maillé",
            "nameCH" : "ITE des Magasins de l'Administration Militaire",
            "code" : ""
        },
        {
            "codeUIC" : 575639,
            "codeCH" : "LO",
            "nameUIC" : "Nouatre-Maillé",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575639,
            "codeCH" : 67,
            "nameUIC" : "Nouatre-Maillé",
            "nameCH" : "PN 67",
            "code" : ""
        },
        {
            "codeUIC" : 575647,
            "codeCH" : "EB",
            "nameUIC" : "La Haye-Descartes",
            "nameCH" : "ITE Barbot",
            "code" : "LHD"
        },
        {
            "codeUIC" : 575647,
            "codeCH" : "LO",
            "nameUIC" : "La Haye-Descartes",
            "nameCH" : "Local",
            "code" : "LHD"
        },
        {
            "codeUIC" : 575647,
            "codeCH" : "PN",
            "nameUIC" : "La Haye-Descartes",
            "nameCH" : "PN 209",
            "code" : "LHD"
        },
        {
            "codeUIC" : 575654,
            "codeCH" : 0,
            "nameUIC" : "Civray-Ville",
            "nameCH" : "",
            "code" : "CVV"
        },
        {
            "codeUIC" : 575662,
            "codeCH" : "LO",
            "nameUIC" : "Charroux",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575662,
            "codeCH" : "N7",
            "nameUIC" : "Charroux",
            "nameCH" : "PN 297",
            "code" : ""
        },
        {
            "codeUIC" : 575688,
            "codeCH" : "LO",
            "nameUIC" : "St-Martin-Usson",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575688,
            "codeCH" : "N3",
            "nameUIC" : "St-Martin-Usson",
            "nameCH" : "PN283",
            "code" : ""
        },
        {
            "codeUIC" : 575688,
            "codeCH" : "N4",
            "nameUIC" : "St-Martin-Usson",
            "nameCH" : "PN284",
            "code" : ""
        },
        {
            "codeUIC" : 575688,
            "codeCH" : "N8",
            "nameUIC" : "St-Martin-Usson",
            "nameCH" : "PN 288",
            "code" : ""
        },
        {
            "codeUIC" : 575696,
            "codeCH" : "LO",
            "nameUIC" : "Abilly",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575696,
            "codeCH" : "PN",
            "nameUIC" : "Abilly",
            "nameCH" : "PN 215",
            "code" : ""
        },
        {
            "codeUIC" : 575704,
            "codeCH" : "BV",
            "nameUIC" : "Mignaloux-Nouaillé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575704,
            "codeCH" : "PN",
            "nameUIC" : "Mignaloux-Nouaillé",
            "nameCH" : "PN 2",
            "code" : ""
        },
        {
            "codeUIC" : 575704,
            "codeCH" : 27,
            "nameUIC" : "Mignaloux-Nouaillé",
            "nameCH" : "Sémaphores 27 (31 et 32)",
            "code" : ""
        },
        {
            "codeUIC" : 575712,
            "codeCH" : "EP",
            "nameUIC" : "St-Julien-L'Ars",
            "nameCH" : "ITE SICA-Poitou",
            "code" : ""
        },
        {
            "codeUIC" : 575712,
            "codeCH" : "LO",
            "nameUIC" : "St-Julien-L'Ars",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575712,
            "codeCH" : 14,
            "nameUIC" : "St-Julien-L'Ars",
            "nameCH" : "PN 14",
            "code" : ""
        },
        {
            "codeUIC" : 575712,
            "codeCH" : 15,
            "nameUIC" : "St-Julien-L'Ars",
            "nameCH" : "PN 15",
            "code" : ""
        },
        {
            "codeUIC" : 575720,
            "codeCH" : 0,
            "nameUIC" : "Jardres",
            "nameCH" : "",
            "code" : "JAR"
        },
        {
            "codeUIC" : 575738,
            "codeCH" : 0,
            "nameUIC" : "Chauvigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575746,
            "codeCH" : 0,
            "nameUIC" : "Le Grand-Pressigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 575753,
            "codeCH" : "LO",
            "nameUIC" : "Paizay-le-Sec",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575761,
            "codeCH" : "LO",
            "nameUIC" : "St-Savin",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575779,
            "codeCH" : "LO",
            "nameUIC" : "Preuilly-sur-Claise",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575779,
            "codeCH" : "PN",
            "nameUIC" : "Preuilly-sur-Claise",
            "nameCH" : "PN 236",
            "code" : ""
        },
        {
            "codeUIC" : 575787,
            "codeCH" : "LO",
            "nameUIC" : "Bossay",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 575787,
            "codeCH" : "PN",
            "nameUIC" : "Bossay",
            "nameCH" : "PN 238",
            "code" : ""
        },
        {
            "codeUIC" : 575795,
            "codeCH" : 0,
            "nameUIC" : "Tournon-St-Martin",
            "nameCH" : "",
            "code" : "TSM"
        },
        {
            "codeUIC" : 575837,
            "codeCH" : "EP",
            "nameUIC" : "Civaux",
            "nameCH" : "ITE Centrale",
            "code" : ""
        },
        {
            "codeUIC" : 575837,
            "codeCH" : 0,
            "nameUIC" : "Civaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 575845,
            "codeCH" : 0,
            "nameUIC" : "Lussac-les-Châteaux",
            "nameCH" : "",
            "code" : "LLX"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "BV",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "FC",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Faisceau Central",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "FN",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Faisceau Nord",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "FS",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Relais Sud",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "IM",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Carré 114",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "IN",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Nord Entrée/Sortie IPCS",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "IT",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "ITE Km 204,4",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "PA",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Carré 64",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "PB",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Poste B",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "P5",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Poste 5",
            "code" : "V"
        },
        {
            "codeUIC" : 576009,
            "codeCH" : "P6",
            "nameUIC" : "Vierzon-Ville",
            "nameCH" : "Poste 6",
            "code" : "V"
        },
        {
            "codeUIC" : 576025,
            "codeCH" : 0,
            "nameUIC" : "Romorantin (Voie étroite)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576082,
            "codeCH" : 0,
            "nameUIC" : "La Ferté-Imbault",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576090,
            "codeCH" : 0,
            "nameUIC" : "Vierzon-Forges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576108,
            "codeCH" : "BV",
            "nameUIC" : "Foëcy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 576116,
            "codeCH" : 0,
            "nameUIC" : "Mehun-sur-Yèvre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576132,
            "codeCH" : 0,
            "nameUIC" : "Marmagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576140,
            "codeCH" : 0,
            "nameUIC" : "Selles-St-Denis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576157,
            "codeCH" : 0,
            "nameUIC" : "Loreux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576165,
            "codeCH" : 0,
            "nameUIC" : "Villeherviers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576173,
            "codeCH" : 0,
            "nameUIC" : "Faubourg-d'Orléans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576181,
            "codeCH" : 0,
            "nameUIC" : "Les Quatre-Roues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576199,
            "codeCH" : 0,
            "nameUIC" : "Pruniers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576207,
            "codeCH" : "BM",
            "nameUIC" : "Bourges",
            "nameCH" : "Bifurcation Montluçon",
            "code" : "BGS"
        },
        {
            "codeUIC" : 576207,
            "codeCH" : "BV",
            "nameUIC" : "Bourges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BGS"
        },
        {
            "codeUIC" : 576207,
            "codeCH" : "EF",
            "nameUIC" : "Bourges",
            "nameCH" : "ITE Finot-Unidis N°1",
            "code" : "BGS"
        },
        {
            "codeUIC" : 576207,
            "codeCH" : "EN",
            "nameUIC" : "Bourges",
            "nameCH" : "ITE Nabon-Unidis N°2",
            "code" : "BGS"
        },
        {
            "codeUIC" : 576215,
            "codeCH" : 0,
            "nameUIC" : "Valençay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576223,
            "codeCH" : 0,
            "nameUIC" : "Chabris",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576231,
            "codeCH" : 0,
            "nameUIC" : "Varennes-sur-Fouzon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576256,
            "codeCH" : 0,
            "nameUIC" : "LA CHAP.ST URSIN BOURG",
            "nameCH" : 0,
            "code" : ""
        },
        {
            "codeUIC" : 576264,
            "codeCH" : "EB",
            "nameUIC" : "La Chapelle-St-Ursin-Morthomie",
            "nameCH" : "ITE Béton Enrobé",
            "code" : "LCU"
        },
        {
            "codeUIC" : 576264,
            "codeCH" : "EL",
            "nameUIC" : "La Chapelle-St-Ursin-Morthomie",
            "nameCH" : "ITE Luchaire",
            "code" : "LCU"
        },
        {
            "codeUIC" : 576264,
            "codeCH" : "ES",
            "nameUIC" : "La Chapelle-St-Ursin-Morthomie",
            "nameCH" : "ITE Aérospatiale",
            "code" : "LCU"
        },
        {
            "codeUIC" : 576264,
            "codeCH" : "LO",
            "nameUIC" : "La Chapelle-St-Ursin-Morthomie",
            "nameCH" : "ITE Bridier",
            "code" : "LCU"
        },
        {
            "codeUIC" : 576272,
            "codeCH" : "BV",
            "nameUIC" : "St-Florent-sur-Cher",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SFC"
        },
        {
            "codeUIC" : 576272,
            "codeCH" : "ER",
            "nameUIC" : "St-Florent-sur-Cher",
            "nameCH" : "ITE Rosières",
            "code" : "SFC"
        },
        {
            "codeUIC" : 576280,
            "codeCH" : 0,
            "nameUIC" : "Lunery",
            "nameCH" : "",
            "code" : "LNY"
        },
        {
            "codeUIC" : 576298,
            "codeCH" : 0,
            "nameUIC" : "Châteauneuf-sur-Cher",
            "nameCH" : "",
            "code" : "CHC"
        },
        {
            "codeUIC" : 576306,
            "codeCH" : 0,
            "nameUIC" : "Bigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576322,
            "codeCH" : 0,
            "nameUIC" : "St-Amand-Montrond-Orval",
            "nameCH" : "",
            "code" : "STO"
        },
        {
            "codeUIC" : 576348,
            "codeCH" : 0,
            "nameUIC" : "Urçay",
            "nameCH" : "",
            "code" : "UCY"
        },
        {
            "codeUIC" : 576355,
            "codeCH" : 0,
            "nameUIC" : "La Gauterie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576363,
            "codeCH" : 0,
            "nameUIC" : "Luçay-le-Mâle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576405,
            "codeCH" : "BV",
            "nameUIC" : "St-Germain-du-Puy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SGP"
        },
        {
            "codeUIC" : 576413,
            "codeCH" : "EU",
            "nameUIC" : "Moulins-sur-Yèvre",
            "nameCH" : "ITE Epicentre",
            "code" : "MSY"
        },
        {
            "codeUIC" : 576421,
            "codeCH" : "IA",
            "nameUIC" : "Savigny-en-Septaine",
            "nameCH" : "ITE des Armées",
            "code" : ""
        },
        {
            "codeUIC" : 576439,
            "codeCH" : "BV",
            "nameUIC" : "Avord",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AD"
        },
        {
            "codeUIC" : 576439,
            "codeCH" : "EA",
            "nameUIC" : "Avord",
            "nameCH" : "ITE Agricher",
            "code" : "AD"
        },
        {
            "codeUIC" : 576439,
            "codeCH" : "EV",
            "nameUIC" : "Avord",
            "nameCH" : "Voie 4",
            "code" : "AD"
        },
        {
            "codeUIC" : 576447,
            "codeCH" : 0,
            "nameUIC" : "Bengy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576454,
            "codeCH" : "BV",
            "nameUIC" : "Nérondes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NDS"
        },
        {
            "codeUIC" : 576454,
            "codeCH" : "EU",
            "nameUIC" : "Nérondes",
            "nameCH" : "ITE Udcac",
            "code" : "NDS"
        },
        {
            "codeUIC" : 576462,
            "codeCH" : 0,
            "nameUIC" : "Tendron",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576470,
            "codeCH" : 0,
            "nameUIC" : "La Guerche-sur-l'Aubois",
            "nameCH" : "",
            "code" : "LGA"
        },
        {
            "codeUIC" : 576504,
            "codeCH" : 0,
            "nameUIC" : "Asnières-lès-Bourges",
            "nameCH" : "",
            "code" : "ASR"
        },
        {
            "codeUIC" : 576579,
            "codeCH" : "EB",
            "nameUIC" : "Aubigny-sur-Nère",
            "nameCH" : "ITE Sicaworms",
            "code" : "ABY"
        },
        {
            "codeUIC" : 576579,
            "codeCH" : "ED",
            "nameUIC" : "Aubigny-sur-Nère",
            "nameCH" : "ITE Dornberger",
            "code" : "ABY"
        },
        {
            "codeUIC" : 576579,
            "codeCH" : "EO",
            "nameUIC" : "Aubigny-sur-Nère",
            "nameCH" : "ITE Drouard",
            "code" : "ABY"
        },
        {
            "codeUIC" : 576579,
            "codeCH" : "ER",
            "nameUIC" : "Aubigny-sur-Nère",
            "nameCH" : "ITE Rateau",
            "code" : "ABY"
        },
        {
            "codeUIC" : 576579,
            "codeCH" : "EU",
            "nameUIC" : "Aubigny-sur-Nère",
            "nameCH" : "ITE Urg",
            "code" : "ABY"
        },
        {
            "codeUIC" : 576579,
            "codeCH" : "LO",
            "nameUIC" : "Aubigny-sur-Nère",
            "nameCH" : "Local",
            "code" : "ABY"
        },
        {
            "codeUIC" : 576587,
            "codeCH" : 0,
            "nameUIC" : "Argent",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576702,
            "codeCH" : "BV",
            "nameUIC" : "Thénioux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 576702,
            "codeCH" : "IM",
            "nameUIC" : "Thénioux",
            "nameCH" : "Signal 15",
            "code" : ""
        },
        {
            "codeUIC" : 576702,
            "codeCH" : "PA",
            "nameUIC" : "Thénioux",
            "nameCH" : "Signal 16",
            "code" : ""
        },
        {
            "codeUIC" : 576710,
            "codeCH" : 0,
            "nameUIC" : "Châtres (Loir-et-Cher)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576728,
            "codeCH" : "BV",
            "nameUIC" : "Mennetou-sur-Cher",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 576736,
            "codeCH" : 0,
            "nameUIC" : "Langon (Loir-et-Cher)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 576868,
            "codeCH" : "BV",
            "nameUIC" : "Selles-sur-Cher",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 576868,
            "codeCH" : "IM",
            "nameUIC" : "Selles-sur-Cher",
            "nameCH" : "Signal S51",
            "code" : ""
        },
        {
            "codeUIC" : 576868,
            "codeCH" : "IP",
            "nameUIC" : "Selles-sur-Cher",
            "nameCH" : "Carré 41",
            "code" : ""
        },
        {
            "codeUIC" : 576868,
            "codeCH" : "PA",
            "nameUIC" : "Selles-sur-Cher",
            "nameCH" : "Carré 52",
            "code" : ""
        },
        {
            "codeUIC" : 576868,
            "codeCH" : "PI",
            "nameUIC" : "Selles-sur-Cher",
            "nameCH" : "Carré 42",
            "code" : ""
        },
        {
            "codeUIC" : 576876,
            "codeCH" : "BV",
            "nameUIC" : "Gièvres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GS"
        },
        {
            "codeUIC" : 576876,
            "codeCH" : "IP",
            "nameUIC" : "Gièvres",
            "nameCH" : "Carré 37",
            "code" : "GS"
        },
        {
            "codeUIC" : 576876,
            "codeCH" : "PA",
            "nameUIC" : "Gièvres",
            "nameCH" : "Carré 38",
            "code" : "GS"
        },
        {
            "codeUIC" : 576884,
            "codeCH" : "BV",
            "nameUIC" : "Villefranche-sur-Cher",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VSC"
        },
        {
            "codeUIC" : 576884,
            "codeCH" : "IC",
            "nameUIC" : "Villefranche-sur-Cher",
            "nameCH" : "ITE IGN",
            "code" : "VSC"
        },
        {
            "codeUIC" : 576884,
            "codeCH" : "IM",
            "nameUIC" : "Villefranche-sur-Cher",
            "nameCH" : "Carré 1",
            "code" : "VSC"
        },
        {
            "codeUIC" : 576884,
            "codeCH" : "IP",
            "nameUIC" : "Villefranche-sur-Cher",
            "nameCH" : "Carré 3",
            "code" : "VSC"
        },
        {
            "codeUIC" : 576884,
            "codeCH" : "PA",
            "nameUIC" : "Villefranche-sur-Cher",
            "nameCH" : "Pancarte Arrêt",
            "code" : "VSC"
        },
        {
            "codeUIC" : 576892,
            "codeCH" : 0,
            "nameUIC" : "Romorantin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 577403,
            "codeCH" : 0,
            "nameUIC" : "Iteuil",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 580340,
            "codeCH" : 0,
            "nameUIC" : "Marseille-Euroméditerranée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "AN",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Accès Nord",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "AS",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Accès Sud",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "BC",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Bifurcation de Circulation St-Jean",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "BV",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "DE",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Dépôt",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "EN",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Entrée Nord",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "ES",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Entrée/Sortie Chantier Marchandises",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "IC",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Jonction IPCS",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "P3",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Poste 3",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "RA",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Chantier Marchandises",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "SI",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Carré 325",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "SP",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Carré 176",
            "code" : "BX"
        },
        {
            "codeUIC" : 581009,
            "codeCH" : "T6",
            "nameUIC" : "Bordeaux-St-Jean",
            "nameCH" : "Tiroir 6",
            "code" : "BX"
        },
        {
            "codeUIC" : 581017,
            "codeCH" : "IT",
            "nameUIC" : "Bordeaux-Bastide",
            "nameCH" : "ITE Km 581,7",
            "code" : "BBD"
        },
        {
            "codeUIC" : 581017,
            "codeCH" : "LO",
            "nameUIC" : "Bordeaux-Bastide",
            "nameCH" : "Local ou Triage",
            "code" : "BBD"
        },
        {
            "codeUIC" : 581017,
            "codeCH" : "PB",
            "nameUIC" : "Bordeaux-Bastide",
            "nameCH" : "Poste 1",
            "code" : "BBD"
        },
        {
            "codeUIC" : 581017,
            "codeCH" : "PE",
            "nameUIC" : "Bordeaux-Bastide",
            "nameCH" : "Bifurcation de Raccordement Circulation",
            "code" : "BBD"
        },
        {
            "codeUIC" : 581033,
            "codeCH" : 0,
            "nameUIC" : "Bordeaux-Passerelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 581041,
            "codeCH" : "BV",
            "nameUIC" : "Bordeaux-Bénauge",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581058,
            "codeCH" : "EA",
            "nameUIC" : "La Souys",
            "nameCH" : "ITE Antar",
            "code" : ""
        },
        {
            "codeUIC" : 581058,
            "codeCH" : "ED",
            "nameUIC" : "La Souys",
            "nameCH" : "ITE Desse",
            "code" : ""
        },
        {
            "codeUIC" : 581058,
            "codeCH" : "EE",
            "nameUIC" : "La Souys",
            "nameCH" : "ITE EDF",
            "code" : ""
        },
        {
            "codeUIC" : 581058,
            "codeCH" : "LO",
            "nameUIC" : "La Souys",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 581058,
            "codeCH" : "PN",
            "nameUIC" : "La Souys",
            "nameCH" : "PN 2",
            "code" : ""
        },
        {
            "codeUIC" : 581181,
            "codeCH" : 0,
            "nameUIC" : "Ludon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581199,
            "codeCH" : 0,
            "nameUIC" : "Macau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581207,
            "codeCH" : 0,
            "nameUIC" : "Labarde",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581215,
            "codeCH" : 0,
            "nameUIC" : "Margaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581231,
            "codeCH" : 0,
            "nameUIC" : "Moulis-Listrac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581249,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-St-Julien",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581256,
            "codeCH" : 0,
            "nameUIC" : "Pauillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581272,
            "codeCH" : "CH",
            "nameUIC" : "Trompeloup",
            "nameCH" : "Chantier",
            "code" : ""
        },
        {
            "codeUIC" : 581272,
            "codeCH" : 0,
            "nameUIC" : "Trompeloup",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581280,
            "codeCH" : 0,
            "nameUIC" : "St-Estèphe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581314,
            "codeCH" : "AD",
            "nameUIC" : "Lesparre",
            "nameCH" : "Arrêt Désserte",
            "code" : ""
        },
        {
            "codeUIC" : 581314,
            "codeCH" : "BV",
            "nameUIC" : "Lesparre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581330,
            "codeCH" : 0,
            "nameUIC" : "Queyrac-Montalivet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581371,
            "codeCH" : 0,
            "nameUIC" : "Soulac-sur-Mer",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581389,
            "codeCH" : "BV",
            "nameUIC" : "Le Verdon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LEV"
        },
        {
            "codeUIC" : 581397,
            "codeCH" : 0,
            "nameUIC" : "La Pointe-de-Grave",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581504,
            "codeCH" : "BF",
            "nameUIC" : "Talence-Médoquine",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 581538,
            "codeCH" : "BV",
            "nameUIC" : "Caudéran-Mérignac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581538,
            "codeCH" : "TI",
            "nameUIC" : "Caudéran-Mérignac",
            "nameCH" : "Tiroirs",
            "code" : ""
        },
        {
            "codeUIC" : 581538,
            "codeCH" : 0,
            "nameUIC" : "Caudéran-Mérignac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 581595,
            "codeCH" : "BL",
            "nameUIC" : "Cenon",
            "nameCH" : "Bifurcation de Lormont",
            "code" : ""
        },
        {
            "codeUIC" : 581595,
            "codeCH" : "BR",
            "nameUIC" : "Cenon",
            "nameCH" : "Bifurcation de Raccordement Circulation",
            "code" : ""
        },
        {
            "codeUIC" : 581595,
            "codeCH" : "BS",
            "nameUIC" : "Cenon",
            "nameCH" : "Bifurcation de Saintes",
            "code" : ""
        },
        {
            "codeUIC" : 581595,
            "codeCH" : "BV",
            "nameUIC" : "Cenon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581611,
            "codeCH" : 0,
            "nameUIC" : "Bordeaux-Docks",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 581710,
            "codeCH" : 0,
            "nameUIC" : "Parempuyre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581728,
            "codeCH" : "BV",
            "nameUIC" : "Blanquefort",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BQF"
        },
        {
            "codeUIC" : 581728,
            "codeCH" : "EC",
            "nameUIC" : "Blanquefort",
            "nameCH" : "ITE CUB",
            "code" : "BQF"
        },
        {
            "codeUIC" : 581736,
            "codeCH" : "BV",
            "nameUIC" : "Bruges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BUG"
        },
        {
            "codeUIC" : 581751,
            "codeCH" : "BV",
            "nameUIC" : "Pessac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581751,
            "codeCH" : "EA",
            "nameUIC" : "Pessac",
            "nameCH" : "ITE Acieries",
            "code" : ""
        },
        {
            "codeUIC" : 581751,
            "codeCH" : "EB",
            "nameUIC" : "Pessac",
            "nameCH" : "ITE BRENEZ",
            "code" : ""
        },
        {
            "codeUIC" : 581793,
            "codeCH" : 0,
            "nameUIC" : "Alouette-France",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581801,
            "codeCH" : "BV",
            "nameUIC" : "Bègles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BGL"
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "AN",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Accès Nord",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "AS",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Accès Sud",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "CM",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Chantier Multi-Technique",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "FE",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Faisceau Réception Est",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "FO",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Faisceau Réception Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "FR",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "PA",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Aiguille 306",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "RE",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Réception",
            "code" : ""
        },
        {
            "codeUIC" : 581827,
            "codeCH" : "RT",
            "nameUIC" : "Bordeaux-Hourcade-Triage",
            "nameCH" : "Relais et Triage",
            "code" : ""
        },
        {
            "codeUIC" : 581835,
            "codeCH" : 0,
            "nameUIC" : "Villenave-d'Ornon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : "AT",
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Accès Triage",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : "BV",
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : "PL",
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Poste L",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : "PR",
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Poste R",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : "SB",
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Sabarèges",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : "SG",
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Carré 443",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : "SR",
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Carré 121",
            "code" : ""
        },
        {
            "codeUIC" : 581850,
            "codeCH" : 56,
            "nameUIC" : "Bassens-Ville",
            "nameCH" : "Carré 56",
            "code" : ""
        },
        {
            "codeUIC" : 581868,
            "codeCH" : 0,
            "nameUIC" : "Bassens-Appontements",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BTT"
        },
        {
            "codeUIC" : 581876,
            "codeCH" : "EB",
            "nameUIC" : "La Chapelle-d'Ambès",
            "nameCH" : "ITE Bernard",
            "code" : "LCA"
        },
        {
            "codeUIC" : 581876,
            "codeCH" : "ED",
            "nameUIC" : "La Chapelle-d'Ambès",
            "nameCH" : "ITE EDF",
            "code" : "LCA"
        },
        {
            "codeUIC" : 581876,
            "codeCH" : "EF",
            "nameUIC" : "La Chapelle-d'Ambès",
            "nameCH" : "ITE Fer-Inox",
            "code" : "LCA"
        },
        {
            "codeUIC" : 581876,
            "codeCH" : "EN",
            "nameUIC" : "La Chapelle-d'Ambès",
            "nameCH" : "ITE Hydro Agri",
            "code" : "LCA"
        },
        {
            "codeUIC" : 581876,
            "codeCH" : "LO",
            "nameUIC" : "La Chapelle-d'Ambès",
            "nameCH" : "Local",
            "code" : "LCA"
        },
        {
            "codeUIC" : 581884,
            "codeCH" : "EP",
            "nameUIC" : "Le Bec-d'Ambès",
            "nameCH" : "ITE Ponticelli",
            "code" : "LBC"
        },
        {
            "codeUIC" : 581884,
            "codeCH" : "LO",
            "nameUIC" : "Le Bec-d'Ambès",
            "nameCH" : "Local",
            "code" : "LBC"
        },
        {
            "codeUIC" : 582403,
            "codeCH" : "BV",
            "nameUIC" : "Gazinet-Cestas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GAC"
        },
        {
            "codeUIC" : 582403,
            "codeCH" : "EE",
            "nameUIC" : "Gazinet-Cestas",
            "nameCH" : "ITE EDF",
            "code" : "GAC"
        },
        {
            "codeUIC" : 582403,
            "codeCH" : "EL",
            "nameUIC" : "Gazinet-Cestas",
            "nameCH" : "ITE SCASO Leclerc",
            "code" : "GAC"
        },
        {
            "codeUIC" : 582403,
            "codeCH" : "EZ",
            "nameUIC" : "Gazinet-Cestas",
            "nameCH" : "ITE Zone Industrielle de Cestas",
            "code" : "GAC"
        },
        {
            "codeUIC" : 582429,
            "codeCH" : "BV",
            "nameUIC" : "Pierroton",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582437,
            "codeCH" : 0,
            "nameUIC" : "Croix-d'Hins",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582445,
            "codeCH" : 0,
            "nameUIC" : "Marcheprime",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MCP"
        },
        {
            "codeUIC" : 582478,
            "codeCH" : 0,
            "nameUIC" : "Facture-Biganos",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FAB"
        },
        {
            "codeUIC" : 582494,
            "codeCH" : "S3",
            "nameUIC" : "Bif de Lamothe",
            "nameCH" : "Sortie Voie 3",
            "code" : ""
        },
        {
            "codeUIC" : 582494,
            "codeCH" : "S4",
            "nameUIC" : "Bif de Lamothe",
            "nameCH" : "Sortie Voie 4",
            "code" : ""
        },
        {
            "codeUIC" : 582494,
            "codeCH" : 0,
            "nameUIC" : "Bif de Lamothe",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 582510,
            "codeCH" : 0,
            "nameUIC" : "Caudos",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582528,
            "codeCH" : 0,
            "nameUIC" : "Lugos",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582536,
            "codeCH" : 0,
            "nameUIC" : "Ychoux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "YX"
        },
        {
            "codeUIC" : 582551,
            "codeCH" : 0,
            "nameUIC" : "Labouheyre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582577,
            "codeCH" : 0,
            "nameUIC" : "Solférino",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SFE"
        },
        {
            "codeUIC" : 582601,
            "codeCH" : 0,
            "nameUIC" : "Le Teich",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582619,
            "codeCH" : 0,
            "nameUIC" : "Gujan-Mestras",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582635,
            "codeCH" : 0,
            "nameUIC" : "La Hume",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582643,
            "codeCH" : 0,
            "nameUIC" : "La Teste",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LTS"
        },
        {
            "codeUIC" : 582668,
            "codeCH" : 0,
            "nameUIC" : "Arcachon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582684,
            "codeCH" : 0,
            "nameUIC" : "Cadaujac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582692,
            "codeCH" : "BV",
            "nameUIC" : "St-Médard-d'Eyrans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582692,
            "codeCH" : "EB",
            "nameUIC" : "St-Médard-d'Eyrans",
            "nameCH" : "ITE Beaumartin",
            "code" : ""
        },
        {
            "codeUIC" : 582700,
            "codeCH" : 0,
            "nameUIC" : "Beautiran",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582718,
            "codeCH" : 0,
            "nameUIC" : "Portets",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582726,
            "codeCH" : 0,
            "nameUIC" : "Arbanats",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582734,
            "codeCH" : 0,
            "nameUIC" : "Podensac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582742,
            "codeCH" : 0,
            "nameUIC" : "Cérons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582759,
            "codeCH" : 0,
            "nameUIC" : "Barsac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582767,
            "codeCH" : 0,
            "nameUIC" : "Preignac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582775,
            "codeCH" : "J1",
            "nameUIC" : "Langon",
            "nameCH" : "Jonction",
            "code" : "LG"
        },
        {
            "codeUIC" : 582775,
            "codeCH" : 0,
            "nameUIC" : "Langon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LG"
        },
        {
            "codeUIC" : 582775,
            "codeCH" : 61,
            "nameUIC" : "Langon",
            "nameCH" : "Carré 211",
            "code" : "LG"
        },
        {
            "codeUIC" : 582825,
            "codeCH" : "PA",
            "nameUIC" : "St-Macaire",
            "nameCH" : "Carré 210",
            "code" : ""
        },
        {
            "codeUIC" : 582825,
            "codeCH" : 0,
            "nameUIC" : "St-Macaire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582833,
            "codeCH" : 0,
            "nameUIC" : "St-Pierre-d'Aurillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582841,
            "codeCH" : "IM",
            "nameUIC" : "Caudrot",
            "nameCH" : "Carré 52,9",
            "code" : ""
        },
        {
            "codeUIC" : 582841,
            "codeCH" : "PA",
            "nameUIC" : "Caudrot",
            "nameCH" : "Sémaphore 48,7",
            "code" : ""
        },
        {
            "codeUIC" : 582841,
            "codeCH" : 0,
            "nameUIC" : "Caudrot",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582858,
            "codeCH" : "IM",
            "nameUIC" : "Gironde",
            "nameCH" : "Carré 131",
            "code" : ""
        },
        {
            "codeUIC" : 582858,
            "codeCH" : "IP",
            "nameUIC" : "Gironde",
            "nameCH" : "Carré 57,8",
            "code" : ""
        },
        {
            "codeUIC" : 582858,
            "codeCH" : 0,
            "nameUIC" : "Gironde",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582866,
            "codeCH" : "PA",
            "nameUIC" : "La Réole",
            "nameCH" : "Carré 57,7",
            "code" : ""
        },
        {
            "codeUIC" : 582866,
            "codeCH" : 0,
            "nameUIC" : "La Réole",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 582882,
            "codeCH" : "PA",
            "nameUIC" : "Lamothe-Landerron",
            "nameCH" : "Carré 4502",
            "code" : ""
        },
        {
            "codeUIC" : 582882,
            "codeCH" : 0,
            "nameUIC" : "Lamothe-Landerron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583005,
            "codeCH" : "BA",
            "nameUIC" : "Angoulême",
            "nameCH" : "Bifurcation des Alliers",
            "code" : "A"
        },
        {
            "codeUIC" : 583005,
            "codeCH" : "BL",
            "nameUIC" : "Angoulême",
            "nameCH" : "Bifurcation de Limoges",
            "code" : "A"
        },
        {
            "codeUIC" : 583005,
            "codeCH" : "BV",
            "nameUIC" : "Angoulême",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "A"
        },
        {
            "codeUIC" : 583005,
            "codeCH" : "EC",
            "nameUIC" : "Angoulême",
            "nameCH" : "ITE de la Chambre de Commerce et de l'Industrie",
            "code" : "A"
        },
        {
            "codeUIC" : 583005,
            "codeCH" : "EG",
            "nameUIC" : "Angoulême",
            "nameCH" : "ITE Gond-Pontouvre",
            "code" : "A"
        },
        {
            "codeUIC" : 583005,
            "codeCH" : "EL",
            "nameUIC" : "Angoulême",
            "nameCH" : "ITE Ciments Lafarge",
            "code" : "A"
        },
        {
            "codeUIC" : 583005,
            "codeCH" : "ES",
            "nameUIC" : "Angoulême",
            "nameCH" : "ITE Semoulerie",
            "code" : "A"
        },
        {
            "codeUIC" : 583153,
            "codeCH" : 0,
            "nameUIC" : "St-Michel-sur-Charente",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SMH"
        },
        {
            "codeUIC" : 583203,
            "codeCH" : 0,
            "nameUIC" : "Ruelle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RUL"
        },
        {
            "codeUIC" : 583427,
            "codeCH" : 0,
            "nameUIC" : "La Rochefoucauld",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583443,
            "codeCH" : "BV",
            "nameUIC" : "Chasseneuil-sur-Bonnieure",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583468,
            "codeCH" : 0,
            "nameUIC" : "Roumazières-Loubert",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583617,
            "codeCH" : "RC",
            "nameUIC" : "La Couronne",
            "nameCH" : "Raccordement La Couronne",
            "code" : "LCN"
        },
        {
            "codeUIC" : 583641,
            "codeCH" : "BV",
            "nameUIC" : "Mouthiers (Charente)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583641,
            "codeCH" : "EV",
            "nameUIC" : "Mouthiers (Charente)",
            "nameCH" : "Evitements",
            "code" : ""
        },
        {
            "codeUIC" : 583641,
            "codeCH" : "SI",
            "nameUIC" : "Mouthiers (Charente)",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 583641,
            "codeCH" : "SP",
            "nameUIC" : "Mouthiers (Charente)",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 583658,
            "codeCH" : 0,
            "nameUIC" : "Charmant",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CRM"
        },
        {
            "codeUIC" : 583674,
            "codeCH" : "SI",
            "nameUIC" : "Montmoreau",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 583674,
            "codeCH" : "SP",
            "nameUIC" : "Montmoreau",
            "nameCH" : "Sortie Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 583674,
            "codeCH" : 0,
            "nameUIC" : "Montmoreau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583690,
            "codeCH" : 0,
            "nameUIC" : "Chalais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583724,
            "codeCH" : 0,
            "nameUIC" : "Parcoul-Médillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583807,
            "codeCH" : 0,
            "nameUIC" : "Vars",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583823,
            "codeCH" : "AB",
            "nameUIC" : "Luxé",
            "nameCH" : "Accès Maintenance",
            "code" : ""
        },
        {
            "codeUIC" : 583823,
            "codeCH" : "BV",
            "nameUIC" : "Luxé",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 583823,
            "codeCH" : "ES",
            "nameUIC" : "Luxé",
            "nameCH" : "Entrée/Sortie V3-LV",
            "code" : ""
        },
        {
            "codeUIC" : 583823,
            "codeCH" : "IC",
            "nameUIC" : "Luxé",
            "nameCH" : "IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 583823,
            "codeCH" : "RJ",
            "nameUIC" : "Luxé",
            "nameCH" : "Raccordement Juillé",
            "code" : ""
        },
        {
            "codeUIC" : 583823,
            "codeCH" : "SI",
            "nameUIC" : "Luxé",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 583849,
            "codeCH" : "IC",
            "nameUIC" : "Ruffec (Charente)",
            "nameCH" : "IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 583849,
            "codeCH" : "SI",
            "nameUIC" : "Ruffec (Charente)",
            "nameCH" : "Sortie Évitement Impair",
            "code" : ""
        },
        {
            "codeUIC" : 583849,
            "codeCH" : 0,
            "nameUIC" : "Ruffec (Charente)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584052,
            "codeCH" : "AN",
            "nameUIC" : "Libourne",
            "nameCH" : "Accès Nord IPCS",
            "code" : "LI"
        },
        {
            "codeUIC" : 584052,
            "codeCH" : "AS",
            "nameUIC" : "Libourne",
            "nameCH" : "Accès Sud IPCS",
            "code" : "LI"
        },
        {
            "codeUIC" : 584052,
            "codeCH" : "BV",
            "nameUIC" : "Libourne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LI"
        },
        {
            "codeUIC" : 584052,
            "codeCH" : "SG",
            "nameUIC" : "Libourne",
            "nameCH" : "Carré 117",
            "code" : "LI"
        },
        {
            "codeUIC" : 584052,
            "codeCH" : "SL",
            "nameUIC" : "Libourne",
            "nameCH" : "Carré 110",
            "code" : "LI"
        },
        {
            "codeUIC" : 584052,
            "codeCH" : "V6",
            "nameUIC" : "Libourne",
            "nameCH" : "Sortie Voie 6",
            "code" : "LI"
        },
        {
            "codeUIC" : 584102,
            "codeCH" : 0,
            "nameUIC" : "St-Émilion",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584128,
            "codeCH" : 0,
            "nameUIC" : "St-Étienne-de-Lisse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584136,
            "codeCH" : 0,
            "nameUIC" : "Castillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584151,
            "codeCH" : 0,
            "nameUIC" : "Lamothe-Montravel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584169,
            "codeCH" : 0,
            "nameUIC" : "Montcaret",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584177,
            "codeCH" : 0,
            "nameUIC" : "Vélines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584193,
            "codeCH" : 0,
            "nameUIC" : "Ste-Foy-la-Grande",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584201,
            "codeCH" : 0,
            "nameUIC" : "St-Antoine-de-Breuilh",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584235,
            "codeCH" : 0,
            "nameUIC" : "Gardonne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584243,
            "codeCH" : "BV",
            "nameUIC" : "Lamonzie-St-Martin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584243,
            "codeCH" : "EB",
            "nameUIC" : "Lamonzie-St-Martin",
            "nameCH" : "ITE Bardon",
            "code" : ""
        },
        {
            "codeUIC" : 584250,
            "codeCH" : 0,
            "nameUIC" : "Prigonrieux-La Force",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584268,
            "codeCH" : "BV",
            "nameUIC" : "Bergerac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BGC"
        },
        {
            "codeUIC" : 584268,
            "codeCH" : "EA",
            "nameUIC" : "Bergerac",
            "nameCH" : "ITE Km 605,2",
            "code" : "BGC"
        },
        {
            "codeUIC" : 584268,
            "codeCH" : "EP",
            "nameUIC" : "Bergerac",
            "nameCH" : "ITE Poudrerie Nationale de Bergerac",
            "code" : "BGC"
        },
        {
            "codeUIC" : 584391,
            "codeCH" : 0,
            "nameUIC" : "Creysse-Mouleydier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584417,
            "codeCH" : 0,
            "nameUIC" : "St-Capraise-de-Lalinde",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584425,
            "codeCH" : 0,
            "nameUIC" : "Couze",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584441,
            "codeCH" : 0,
            "nameUIC" : "Lalinde",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584458,
            "codeCH" : 0,
            "nameUIC" : "Sauveboeuf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 584466,
            "codeCH" : 0,
            "nameUIC" : "Mauzac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 584474,
            "codeCH" : 0,
            "nameUIC" : "Trémolat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 584482,
            "codeCH" : 0,
            "nameUIC" : "Alles-sur-Dordogne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 584508,
            "codeCH" : 0,
            "nameUIC" : "St-Denis-de-Pile",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584516,
            "codeCH" : "BV",
            "nameUIC" : "Coutras",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COU"
        },
        {
            "codeUIC" : 584516,
            "codeCH" : "P3",
            "nameUIC" : "Coutras",
            "nameCH" : "Accès Sud IPCS",
            "code" : "COU"
        },
        {
            "codeUIC" : 584516,
            "codeCH" : "SG",
            "nameUIC" : "Coutras",
            "nameCH" : "Carré 107",
            "code" : "COU"
        },
        {
            "codeUIC" : 584516,
            "codeCH" : "SL",
            "nameUIC" : "Coutras",
            "nameCH" : "Carré 100",
            "code" : "COU"
        },
        {
            "codeUIC" : 584516,
            "codeCH" : "TR",
            "nameUIC" : "Coutras",
            "nameCH" : "Triage",
            "code" : "COU"
        },
        {
            "codeUIC" : 584516,
            "codeCH" : "ZI",
            "nameUIC" : "Coutras",
            "nameCH" : "ITE ZI N1",
            "code" : "COU"
        },
        {
            "codeUIC" : 584516,
            "codeCH" : "Z2",
            "nameUIC" : "Coutras",
            "nameCH" : "ITE Zone Industrielle N° 2",
            "code" : "COU"
        },
        {
            "codeUIC" : 584532,
            "codeCH" : 0,
            "nameUIC" : "Les Églisottes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584540,
            "codeCH" : "BV",
            "nameUIC" : "St-Aigulin-La Roche-Chalais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584540,
            "codeCH" : "IC",
            "nameUIC" : "St-Aigulin-La Roche-Chalais",
            "nameCH" : "IPCS Les Frissons",
            "code" : ""
        },
        {
            "codeUIC" : 584607,
            "codeCH" : 0,
            "nameUIC" : "St-Médard-de-Guizières",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584615,
            "codeCH" : "BV",
            "nameUIC" : "St-Seurin-sur-l'Isle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584615,
            "codeCH" : "EP",
            "nameUIC" : "St-Seurin-sur-l'Isle",
            "nameCH" : "ITE Carrières de Thiviers",
            "code" : ""
        },
        {
            "codeUIC" : 584615,
            "codeCH" : "ES",
            "nameUIC" : "St-Seurin-sur-l'Isle",
            "nameCH" : "ITE Socar",
            "code" : ""
        },
        {
            "codeUIC" : 584615,
            "codeCH" : "S1",
            "nameUIC" : "St-Seurin-sur-l'Isle",
            "nameCH" : "Sémaphore 111",
            "code" : ""
        },
        {
            "codeUIC" : 584714,
            "codeCH" : "BV",
            "nameUIC" : "Vayres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VYR"
        },
        {
            "codeUIC" : 584714,
            "codeCH" : "EB",
            "nameUIC" : "Vayres",
            "nameCH" : "ITE BSN",
            "code" : "VYR"
        },
        {
            "codeUIC" : 584722,
            "codeCH" : "BV",
            "nameUIC" : "St-Sulpice-Izon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584722,
            "codeCH" : "IC",
            "nameUIC" : "St-Sulpice-Izon",
            "nameCH" : "IPCS Izon",
            "code" : ""
        },
        {
            "codeUIC" : 584730,
            "codeCH" : 0,
            "nameUIC" : "St-Loubès",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584748,
            "codeCH" : "BV",
            "nameUIC" : "La Grave-d'Ambarès-Gare-Supéri",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 584748,
            "codeCH" : "RE",
            "nameUIC" : "La Grave-d'Ambarès-Gare-Supéri",
            "nameCH" : "Raccordement",
            "code" : ""
        },
        {
            "codeUIC" : 584755,
            "codeCH" : 0,
            "nameUIC" : "La Gorp",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "BA",
            "nameUIC" : "Agen",
            "nameCH" : "Bifurcation de Auch",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "BP",
            "nameUIC" : "Agen",
            "nameCH" : "Bifurcation de Périgeux",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "BV",
            "nameUIC" : "Agen",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "EB",
            "nameUIC" : "Agen",
            "nameCH" : "ITE SHELL BERRE",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "ED",
            "nameUIC" : "Agen",
            "nameCH" : "ITE District Agglomération Agenaise",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "EG",
            "nameUIC" : "Agen",
            "nameCH" : "ITE Sologemin",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "ES",
            "nameUIC" : "Agen",
            "nameCH" : "ITE Sangosse N2",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "ET",
            "nameUIC" : "Agen",
            "nameCH" : "ITE PTT",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : "PA",
            "nameUIC" : "Agen",
            "nameCH" : "Sémaphore 312",
            "code" : ""
        },
        {
            "codeUIC" : 586008,
            "codeCH" : 50,
            "nameUIC" : "Agen",
            "nameCH" : "Carré 50",
            "code" : ""
        },
        {
            "codeUIC" : 586107,
            "codeCH" : "BV",
            "nameUIC" : "Port-Ste-Marie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PSM"
        },
        {
            "codeUIC" : 586107,
            "codeCH" : "EB",
            "nameUIC" : "Port-Ste-Marie",
            "nameCH" : "ITE Bourgela",
            "code" : "PSM"
        },
        {
            "codeUIC" : 586107,
            "codeCH" : "IP",
            "nameUIC" : "Port-Ste-Marie",
            "nameCH" : "Carré 291",
            "code" : "PSM"
        },
        {
            "codeUIC" : 586107,
            "codeCH" : "PA",
            "nameUIC" : "Port-Ste-Marie",
            "nameCH" : "Carré 272",
            "code" : "PSM"
        },
        {
            "codeUIC" : 586107,
            "codeCH" : "PI",
            "nameUIC" : "Port-Ste-Marie",
            "nameCH" : "Carré 301",
            "code" : "PSM"
        },
        {
            "codeUIC" : 586107,
            "codeCH" : "PN",
            "nameUIC" : "Port-Ste-Marie",
            "nameCH" : "PN 0",
            "code" : "PSM"
        },
        {
            "codeUIC" : 586107,
            "codeCH" : 26,
            "nameUIC" : "Port-Ste-Marie",
            "nameCH" : "Carré 262",
            "code" : "PSM"
        },
        {
            "codeUIC" : 586149,
            "codeCH" : "PA",
            "nameUIC" : "Colayrac",
            "nameCH" : "Carré 302",
            "code" : "CLY"
        },
        {
            "codeUIC" : 586149,
            "codeCH" : "PI",
            "nameUIC" : "Colayrac",
            "nameCH" : "Sémaphore 132,0",
            "code" : "CLY"
        },
        {
            "codeUIC" : 586149,
            "codeCH" : 0,
            "nameUIC" : "Colayrac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CLY"
        },
        {
            "codeUIC" : 586149,
            "codeCH" : 29,
            "nameUIC" : "Colayrac",
            "nameCH" : "Carré 292",
            "code" : "CLY"
        },
        {
            "codeUIC" : 586164,
            "codeCH" : "BV",
            "nameUIC" : "Bon-Encontre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BTR"
        },
        {
            "codeUIC" : 586164,
            "codeCH" : "IM",
            "nameUIC" : "Bon-Encontre",
            "nameCH" : "Signal Avertissement 144,1",
            "code" : "BTR"
        },
        {
            "codeUIC" : 586164,
            "codeCH" : "IP",
            "nameUIC" : "Bon-Encontre",
            "nameCH" : "Carré 142,1",
            "code" : "BTR"
        },
        {
            "codeUIC" : 586164,
            "codeCH" : "IR",
            "nameUIC" : "Bon-Encontre",
            "nameCH" : "Sémaphore 146,8",
            "code" : "BTR"
        },
        {
            "codeUIC" : 586164,
            "codeCH" : "PM",
            "nameUIC" : "Bon-Encontre",
            "nameCH" : "Point M",
            "code" : "BTR"
        },
        {
            "codeUIC" : 586180,
            "codeCH" : "IM",
            "nameUIC" : "St-Nicolas-St-Romain",
            "nameCH" : "Carré 431",
            "code" : ""
        },
        {
            "codeUIC" : 586180,
            "codeCH" : "IP",
            "nameUIC" : "St-Nicolas-St-Romain",
            "nameCH" : "Carré 421",
            "code" : ""
        },
        {
            "codeUIC" : 586180,
            "codeCH" : "PA",
            "nameUIC" : "St-Nicolas-St-Romain",
            "nameCH" : "Carré 60",
            "code" : ""
        },
        {
            "codeUIC" : 586180,
            "codeCH" : 0,
            "nameUIC" : "St-Nicolas-St-Romain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586263,
            "codeCH" : "BV",
            "nameUIC" : "Lectoure",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586263,
            "codeCH" : "EZ",
            "nameUIC" : "Lectoure",
            "nameCH" : "ITE Zone Industrielle",
            "code" : ""
        },
        {
            "codeUIC" : 586305,
            "codeCH" : 0,
            "nameUIC" : "Layrac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LYG"
        },
        {
            "codeUIC" : 586313,
            "codeCH" : "EP",
            "nameUIC" : "Goulens",
            "nameCH" : "ITE Bunge",
            "code" : ""
        },
        {
            "codeUIC" : 586321,
            "codeCH" : 0,
            "nameUIC" : "Astaffort",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586339,
            "codeCH" : "BV",
            "nameUIC" : "Fleurance",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FLE"
        },
        {
            "codeUIC" : 586339,
            "codeCH" : "EZ",
            "nameUIC" : "Fleurance",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "FLE"
        },
        {
            "codeUIC" : 586362,
            "codeCH" : "ER",
            "nameUIC" : "Ste-Christie",
            "nameCH" : "ITE Raffinerie du Midi",
            "code" : "STE"
        },
        {
            "codeUIC" : 586404,
            "codeCH" : 0,
            "nameUIC" : "Pont-du-Casse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586412,
            "codeCH" : 0,
            "nameUIC" : "Laroque",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586420,
            "codeCH" : 0,
            "nameUIC" : "Hautefage-Auradou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586438,
            "codeCH" : 0,
            "nameUIC" : "Penne (Lot-et-Garonne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PNE"
        },
        {
            "codeUIC" : 586446,
            "codeCH" : 0,
            "nameUIC" : "Trentels-Ladignac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586453,
            "codeCH" : 0,
            "nameUIC" : "Monsempron-Libos",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586461,
            "codeCH" : 0,
            "nameUIC" : "Cuzorn",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586479,
            "codeCH" : "IP",
            "nameUIC" : "Ste-Bazeille",
            "nameCH" : "Sémaphore 73",
            "code" : ""
        },
        {
            "codeUIC" : 586479,
            "codeCH" : "PI",
            "nameUIC" : "Ste-Bazeille",
            "nameCH" : "Carré 201",
            "code" : ""
        },
        {
            "codeUIC" : 586479,
            "codeCH" : 0,
            "nameUIC" : "Ste-Bazeille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586495,
            "codeCH" : "BV",
            "nameUIC" : "Sauveterre-la-Lémance",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586495,
            "codeCH" : "EC",
            "nameUIC" : "Sauveterre-la-Lémance",
            "nameCH" : "ITE Chaux Perig",
            "code" : ""
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "EA",
            "nameUIC" : "Marmande",
            "nameCH" : "ITE Complexe agricole",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "IC",
            "nameUIC" : "Marmande",
            "nameCH" : "IPCS",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "IM",
            "nameUIC" : "Marmande",
            "nameCH" : "Carré 225",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "IP",
            "nameUIC" : "Marmande",
            "nameCH" : "Carré 221",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "PA",
            "nameUIC" : "Marmande",
            "nameCH" : "Carré 182",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "PI",
            "nameUIC" : "Marmande",
            "nameCH" : "Carré 231",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "PN",
            "nameUIC" : "Marmande",
            "nameCH" : "PN 1",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "PR",
            "nameUIC" : "Marmande",
            "nameCH" : "Carré 72,7",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : "TH",
            "nameUIC" : "Marmande",
            "nameCH" : "Thivras",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586545,
            "codeCH" : 0,
            "nameUIC" : "Marmande",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MMD"
        },
        {
            "codeUIC" : 586586,
            "codeCH" : 0,
            "nameUIC" : "Fumel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586602,
            "codeCH" : "BV",
            "nameUIC" : "Villeneuve-sur-Lot",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586644,
            "codeCH" : 0,
            "nameUIC" : "Ste-Livrade-sur-Lot",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "BV",
            "nameUIC" : "Tonneins",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "EC",
            "nameUIC" : "Tonneins",
            "nameCH" : "ITE Ciments Lafarge",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "ES",
            "nameUIC" : "Tonneins",
            "nameCH" : "ITE Seita N°2",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "EV",
            "nameUIC" : "Tonneins",
            "nameCH" : "ITE Ville de Tonneins",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "IM",
            "nameUIC" : "Tonneins",
            "nameCH" : "Sémaphore 104",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "IP",
            "nameUIC" : "Tonneins",
            "nameCH" : "Carré 245",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "PA",
            "nameUIC" : "Tonneins",
            "nameCH" : "Carré 220",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : "PI",
            "nameUIC" : "Tonneins",
            "nameCH" : "Carré 251",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : 21,
            "nameUIC" : "Tonneins",
            "nameCH" : "Carré 212",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : 22,
            "nameUIC" : "Tonneins",
            "nameCH" : "Carré 226",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : 23,
            "nameUIC" : "Tonneins",
            "nameCH" : "Carré 232",
            "code" : ""
        },
        {
            "codeUIC" : 586651,
            "codeCH" : 41,
            "nameUIC" : "Tonneins",
            "nameCH" : "Sémaphore 241",
            "code" : ""
        },
        {
            "codeUIC" : 586693,
            "codeCH" : "ES",
            "nameUIC" : "Aiguillon",
            "nameCH" : "ITE S A P C A",
            "code" : ""
        },
        {
            "codeUIC" : 586693,
            "codeCH" : "IP",
            "nameUIC" : "Aiguillon",
            "nameCH" : "Carré 261",
            "code" : ""
        },
        {
            "codeUIC" : 586693,
            "codeCH" : "PA",
            "nameUIC" : "Aiguillon",
            "nameCH" : "Carré 252",
            "code" : ""
        },
        {
            "codeUIC" : 586693,
            "codeCH" : "PI",
            "nameUIC" : "Aiguillon",
            "nameCH" : "Carré 271",
            "code" : ""
        },
        {
            "codeUIC" : 586693,
            "codeCH" : "PR",
            "nameUIC" : "Aiguillon",
            "nameCH" : "Carré 242",
            "code" : ""
        },
        {
            "codeUIC" : 586693,
            "codeCH" : 0,
            "nameUIC" : "Aiguillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586701,
            "codeCH" : "BV",
            "nameUIC" : "Vianne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586701,
            "codeCH" : "EP",
            "nameUIC" : "Vianne",
            "nameCH" : "ITE Verreries",
            "code" : ""
        },
        {
            "codeUIC" : 586701,
            "codeCH" : 0,
            "nameUIC" : "Vianne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586719,
            "codeCH" : 0,
            "nameUIC" : "Lavardac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 586727,
            "codeCH" : "EI",
            "nameUIC" : "Samazan",
            "nameCH" : "ITE Isorel",
            "code" : ""
        },
        {
            "codeUIC" : 586727,
            "codeCH" : "LO",
            "nameUIC" : "Samazan",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 586735,
            "codeCH" : "BV",
            "nameUIC" : "Nérac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NC"
        },
        {
            "codeUIC" : 586735,
            "codeCH" : "EC",
            "nameUIC" : "Nérac",
            "nameCH" : "ITE Copalma",
            "code" : "NC"
        },
        {
            "codeUIC" : 586735,
            "codeCH" : "ES",
            "nameUIC" : "Nérac",
            "nameCH" : "ITE SNPA",
            "code" : "NC"
        },
        {
            "codeUIC" : 586735,
            "codeCH" : "EV",
            "nameUIC" : "Nérac",
            "nameCH" : "ITE Chevalier",
            "code" : "NC"
        },
        {
            "codeUIC" : 586750,
            "codeCH" : "ES",
            "nameUIC" : "Lasserre",
            "nameCH" : "ITE Siladour",
            "code" : "LAS"
        },
        {
            "codeUIC" : 586768,
            "codeCH" : "ES",
            "nameUIC" : "Moncrabeau",
            "nameCH" : "ITE Société Siladour",
            "code" : ""
        },
        {
            "codeUIC" : 586768,
            "codeCH" : "LO",
            "nameUIC" : "Moncrabeau",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 586776,
            "codeCH" : "EI",
            "nameUIC" : "Casteljaloux",
            "nameCH" : "ITE Isorel",
            "code" : ""
        },
        {
            "codeUIC" : 586776,
            "codeCH" : "EM",
            "nameUIC" : "Casteljaloux",
            "nameCH" : "ITE Municipalité de Casteljaloux",
            "code" : ""
        },
        {
            "codeUIC" : 586776,
            "codeCH" : "LO",
            "nameUIC" : "Casteljaloux",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 586800,
            "codeCH" : "EP",
            "nameUIC" : "Mézin",
            "nameCH" : "ITE Urthaler",
            "code" : "MZI"
        },
        {
            "codeUIC" : 586800,
            "codeCH" : 0,
            "nameUIC" : "Mézin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MZI"
        },
        {
            "codeUIC" : 586818,
            "codeCH" : "EV",
            "nameUIC" : "Condom",
            "nameCH" : "ITE Ville de Condom",
            "code" : "COM"
        },
        {
            "codeUIC" : 586818,
            "codeCH" : "LO",
            "nameUIC" : "Condom",
            "nameCH" : "Local",
            "code" : "COM"
        },
        {
            "codeUIC" : 586818,
            "codeCH" : 36,
            "nameUIC" : "Condom",
            "nameCH" : "PN 36",
            "code" : "COM"
        },
        {
            "codeUIC" : 586818,
            "codeCH" : 37,
            "nameUIC" : "Condom",
            "nameCH" : "PN 37",
            "code" : "COM"
        },
        {
            "codeUIC" : 586818,
            "codeCH" : 38,
            "nameUIC" : "Condom",
            "nameCH" : "PN 38",
            "code" : "COM"
        },
        {
            "codeUIC" : 586859,
            "codeCH" : 0,
            "nameUIC" : "Mouchan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 590299,
            "codeCH" : 0,
            "nameUIC" : "Halte Nice-Pont-Michel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 590331,
            "codeCH" : "JO",
            "nameUIC" : "Haluchère",
            "nameCH" : "Jonction",
            "code" : ""
        },
        {
            "codeUIC" : 590331,
            "codeCH" : 0,
            "nameUIC" : "Haluchère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 590349,
            "codeCH" : 0,
            "nameUIC" : "Babinière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 590356,
            "codeCH" : 0,
            "nameUIC" : "Erdre Active",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 590364,
            "codeCH" : 0,
            "nameUIC" : "La Chapelle Aulnay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 590372,
            "codeCH" : 0,
            "nameUIC" : "Châteaubriant TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 590828,
            "codeCH" : "GA",
            "nameUIC" : "Suce-sur-Erdre",
            "nameCH" : "Garage",
            "code" : ""
        },
        {
            "codeUIC" : 590828,
            "codeCH" : "TI",
            "nameUIC" : "Suce-sur-Erdre",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 590828,
            "codeCH" : 0,
            "nameUIC" : "Suce-sur-Erdre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592006,
            "codeCH" : "BV",
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : "EE",
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "ITE Electrolyse",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : "PK",
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Bifurcation de Périgueux",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : 61,
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Carré 161",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : 62,
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Carré 162",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : 75,
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Carré 175",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : 91,
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Carré 191",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : 92,
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Carré 192",
            "code" : "LS"
        },
        {
            "codeUIC" : 592006,
            "codeCH" : 94,
            "nameUIC" : "Limoges-Bénédictins",
            "nameCH" : "Carré 194",
            "code" : "LS"
        },
        {
            "codeUIC" : 592014,
            "codeCH" : "FC",
            "nameUIC" : "Limoges-Puy-Imbert",
            "nameCH" : "Faisceau Central",
            "code" : "LPI"
        },
        {
            "codeUIC" : 592014,
            "codeCH" : "FV",
            "nameUIC" : "Limoges-Puy-Imbert",
            "nameCH" : "Faisceau Ventenat",
            "code" : "LPI"
        },
        {
            "codeUIC" : 592014,
            "codeCH" : "PA",
            "nameUIC" : "Limoges-Puy-Imbert",
            "nameCH" : "Poste A",
            "code" : "LPI"
        },
        {
            "codeUIC" : 592014,
            "codeCH" : "PC",
            "nameUIC" : "Limoges-Puy-Imbert",
            "nameCH" : "Poste C",
            "code" : "LPI"
        },
        {
            "codeUIC" : 592014,
            "codeCH" : "PM",
            "nameUIC" : "Limoges-Puy-Imbert",
            "nameCH" : "Entrée Voie M",
            "code" : "LPI"
        },
        {
            "codeUIC" : 592022,
            "codeCH" : 0,
            "nameUIC" : "Limoges-Montjovis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592030,
            "codeCH" : "BV",
            "nameUIC" : "Le Palais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592048,
            "codeCH" : "BV",
            "nameUIC" : "Les Bardys",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LBY"
        },
        {
            "codeUIC" : 592048,
            "codeCH" : "EP",
            "nameUIC" : "Les Bardys",
            "nameCH" : "ITE Primagaz",
            "code" : "LBY"
        },
        {
            "codeUIC" : 592055,
            "codeCH" : "BV",
            "nameUIC" : "St-Priest-Taurion",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592063,
            "codeCH" : 0,
            "nameUIC" : "Brignac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592071,
            "codeCH" : 0,
            "nameUIC" : "St-Léonard-de-Noblat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592097,
            "codeCH" : 0,
            "nameUIC" : "St-Denis-des-Murs",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592105,
            "codeCH" : 0,
            "nameUIC" : "Châteauneuf-Bujaleuf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592121,
            "codeCH" : 0,
            "nameUIC" : "Eymoutiers-Lac-de-Vassivière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592147,
            "codeCH" : 0,
            "nameUIC" : "La Celle-Corrèze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592162,
            "codeCH" : "FR",
            "nameUIC" : "Bugeat",
            "nameCH" : "Chantier FRET de Viam",
            "code" : ""
        },
        {
            "codeUIC" : 592162,
            "codeCH" : 0,
            "nameUIC" : "Bugeat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592170,
            "codeCH" : 0,
            "nameUIC" : "Pérols",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592188,
            "codeCH" : 0,
            "nameUIC" : "Barsanges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592246,
            "codeCH" : 0,
            "nameUIC" : "Solignac-Le Vigen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592253,
            "codeCH" : 0,
            "nameUIC" : "Pierre-Buffière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592279,
            "codeCH" : 0,
            "nameUIC" : "Magnac-Vicq",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592287,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-les-Belles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592295,
            "codeCH" : 0,
            "nameUIC" : "La Porcherie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592311,
            "codeCH" : 0,
            "nameUIC" : "Ambazac",
            "nameCH" : "",
            "code" : "ABZ"
        },
        {
            "codeUIC" : 592337,
            "codeCH" : "BV",
            "nameUIC" : "La Jonchère",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592345,
            "codeCH" : "BV",
            "nameUIC" : "St-Sulpice-Laurière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SSL"
        },
        {
            "codeUIC" : 592352,
            "codeCH" : "BP",
            "nameUIC" : "Bersac",
            "nameCH" : "Bifurcation de Poitiers",
            "code" : ""
        },
        {
            "codeUIC" : 592352,
            "codeCH" : "BV",
            "nameUIC" : "Bersac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592352,
            "codeCH" : "C3",
            "nameUIC" : "Bersac",
            "nameCH" : "Carré 213",
            "code" : ""
        },
        {
            "codeUIC" : 592352,
            "codeCH" : "SG",
            "nameUIC" : "Bersac",
            "nameCH" : "Carré 213",
            "code" : ""
        },
        {
            "codeUIC" : 592360,
            "codeCH" : 0,
            "nameUIC" : "Fromental",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592378,
            "codeCH" : "BV",
            "nameUIC" : "La Souterraine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LSO"
        },
        {
            "codeUIC" : 592394,
            "codeCH" : "BV",
            "nameUIC" : "Forgevieille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592394,
            "codeCH" : "SE",
            "nameUIC" : "Forgevieille",
            "nameCH" : "Sortie Évitement Circulation",
            "code" : ""
        },
        {
            "codeUIC" : 592402,
            "codeCH" : "BV",
            "nameUIC" : "Couzeix-Chaptelat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592410,
            "codeCH" : "BV",
            "nameUIC" : "Nieul (Haute-Vienne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592410,
            "codeCH" : 22,
            "nameUIC" : "Nieul (Haute-Vienne)",
            "nameCH" : "Sémaphore 22 (31 et 32)",
            "code" : ""
        },
        {
            "codeUIC" : 592428,
            "codeCH" : 0,
            "nameUIC" : "Peyrilhac-St-Jouvent",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592444,
            "codeCH" : 0,
            "nameUIC" : "Nantiat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592451,
            "codeCH" : 0,
            "nameUIC" : "Vaulry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592477,
            "codeCH" : "EP",
            "nameUIC" : "ITE Km 644,2",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592485,
            "codeCH" : "BV",
            "nameUIC" : "Bellac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592485,
            "codeCH" : 24,
            "nameUIC" : "Bellac",
            "nameCH" : "Sémaphore 24 (31 et 32)",
            "code" : ""
        },
        {
            "codeUIC" : 592493,
            "codeCH" : 0,
            "nameUIC" : "St-Sébastien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592501,
            "codeCH" : 0,
            "nameUIC" : "La Coquille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592519,
            "codeCH" : 0,
            "nameUIC" : "Mavaleix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592543,
            "codeCH" : 0,
            "nameUIC" : "Le Dorat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592618,
            "codeCH" : 0,
            "nameUIC" : "Châteauponsac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592626,
            "codeCH" : "EC",
            "nameUIC" : "Bessines",
            "nameCH" : "ITE Cogema N°1",
            "code" : "BHU"
        },
        {
            "codeUIC" : 592626,
            "codeCH" : "LO",
            "nameUIC" : "Bessines",
            "nameCH" : "Local",
            "code" : "BHU"
        },
        {
            "codeUIC" : 592626,
            "codeCH" : "N8",
            "nameUIC" : "Bessines",
            "nameCH" : "PN 298",
            "code" : "BHU"
        },
        {
            "codeUIC" : 592626,
            "codeCH" : "N9",
            "nameUIC" : "Bessines",
            "nameCH" : "PN 299",
            "code" : "BHU"
        },
        {
            "codeUIC" : 592634,
            "codeCH" : "BV",
            "nameUIC" : "L'Aiguille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592634,
            "codeCH" : "EL",
            "nameUIC" : "L'Aiguille",
            "nameCH" : "ITE LACAUX",
            "code" : ""
        },
        {
            "codeUIC" : 592642,
            "codeCH" : 0,
            "nameUIC" : "Beynac (Haute-Vienne)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592667,
            "codeCH" : "BV",
            "nameUIC" : "Aixe-sur-Vienne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592675,
            "codeCH" : "BV",
            "nameUIC" : "Verneuil-sur-Vienne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VSV"
        },
        {
            "codeUIC" : 592675,
            "codeCH" : "EC",
            "nameUIC" : "Verneuil-sur-Vienne",
            "nameCH" : "ITE Pagnac",
            "code" : "VSV"
        },
        {
            "codeUIC" : 592683,
            "codeCH" : "BV",
            "nameUIC" : "St-Victurnien",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592691,
            "codeCH" : 0,
            "nameUIC" : "St-Brice-sur-Vienne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592709,
            "codeCH" : 0,
            "nameUIC" : "St-Junien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592717,
            "codeCH" : 0,
            "nameUIC" : "Saillat-Chassenon",
            "nameCH" : "",
            "code" : "ST"
        },
        {
            "codeUIC" : 592725,
            "codeCH" : 0,
            "nameUIC" : "Nexon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592733,
            "codeCH" : 0,
            "nameUIC" : "Lafarge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592741,
            "codeCH" : 0,
            "nameUIC" : "Bussière-Galant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592808,
            "codeCH" : 0,
            "nameUIC" : "La Meyze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592824,
            "codeCH" : "BV",
            "nameUIC" : "St-Yrieix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SYX"
        },
        {
            "codeUIC" : 592840,
            "codeCH" : 0,
            "nameUIC" : "Coussac-Bonneval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 592873,
            "codeCH" : 0,
            "nameUIC" : "Chabanais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 592881,
            "codeCH" : "BV",
            "nameUIC" : "Exideuil-sur-Vienne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EXC"
        },
        {
            "codeUIC" : 592881,
            "codeCH" : "EC",
            "nameUIC" : "Exideuil-sur-Vienne",
            "nameCH" : "ITE des Carrières",
            "code" : "EXC"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : "BV",
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : "EE",
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "ITE Disque Bleu",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : "EJ",
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "ITE Jaubert",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : "EP",
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "ITE Entrepots Pétroliers de la Corrèze",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : "EV",
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "ITE Ville de Brive",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : "EZ",
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : "PA",
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "Bifurcation de Périgueux et Nexon",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : 1,
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "Carré 101",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594002,
            "codeCH" : 10,
            "nameUIC" : "Brive-la-Gaillarde",
            "nameCH" : "Carré 100",
            "code" : "BLG"
        },
        {
            "codeUIC" : 594010,
            "codeCH" : "TR",
            "nameUIC" : "Estavel-Triage",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 594010,
            "codeCH" : "VA",
            "nameUIC" : "Estavel-Triage",
            "nameCH" : "Voies d'Attente",
            "code" : ""
        },
        {
            "codeUIC" : 594085,
            "codeCH" : 0,
            "nameUIC" : "Jassonneix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594093,
            "codeCH" : "BV",
            "nameUIC" : "Ussel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "U"
        },
        {
            "codeUIC" : 594093,
            "codeCH" : "EM",
            "nameUIC" : "Ussel",
            "nameCH" : "ITE Montupet",
            "code" : "U"
        },
        {
            "codeUIC" : 594101,
            "codeCH" : 0,
            "nameUIC" : "Gimel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594119,
            "codeCH" : 0,
            "nameUIC" : "Corrèze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594127,
            "codeCH" : 0,
            "nameUIC" : "Eyrein",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594135,
            "codeCH" : 0,
            "nameUIC" : "Montaignac-St-Hippolyte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594150,
            "codeCH" : 0,
            "nameUIC" : "Rosiers-d'Égletons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594168,
            "codeCH" : 0,
            "nameUIC" : "Égletons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594184,
            "codeCH" : 0,
            "nameUIC" : "Soudeilles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594192,
            "codeCH" : 0,
            "nameUIC" : "Salon-la-Tour",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594200,
            "codeCH" : 0,
            "nameUIC" : "Uzerche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594218,
            "codeCH" : 0,
            "nameUIC" : "Vigeois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594226,
            "codeCH" : 0,
            "nameUIC" : "Estivaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594234,
            "codeCH" : 0,
            "nameUIC" : "Allassac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594242,
            "codeCH" : 0,
            "nameUIC" : "Donzenac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594259,
            "codeCH" : "EP",
            "nameUIC" : "Ussac",
            "nameCH" : "ITE Km 493,5",
            "code" : ""
        },
        {
            "codeUIC" : 594267,
            "codeCH" : 0,
            "nameUIC" : "Maussac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594275,
            "codeCH" : 0,
            "nameUIC" : "Meymac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594283,
            "codeCH" : 0,
            "nameUIC" : "Alleyrat-Chaveroche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594291,
            "codeCH" : 0,
            "nameUIC" : "Aix-la-Marsalouse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594309,
            "codeCH" : 0,
            "nameUIC" : "Noailles (Corrèze)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594325,
            "codeCH" : "BV",
            "nameUIC" : "Gignac-Cressensac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 594333,
            "codeCH" : "BV",
            "nameUIC" : "Souillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SOU"
        },
        {
            "codeUIC" : 594333,
            "codeCH" : "EC",
            "nameUIC" : "Souillac",
            "nameCH" : "Sortie Évitement Circulation",
            "code" : "SOU"
        },
        {
            "codeUIC" : 594382,
            "codeCH" : "BV",
            "nameUIC" : "Condat-le-Lardin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDT"
        },
        {
            "codeUIC" : 594382,
            "codeCH" : 32,
            "nameUIC" : "Condat-le-Lardin",
            "nameCH" : "Carré 32",
            "code" : "CDT"
        },
        {
            "codeUIC" : 594382,
            "codeCH" : 33,
            "nameUIC" : "Condat-le-Lardin",
            "nameCH" : "Carré 33",
            "code" : "CDT"
        },
        {
            "codeUIC" : 594408,
            "codeCH" : "BV",
            "nameUIC" : "Terrasson-Lavilledieu",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TER"
        },
        {
            "codeUIC" : 594408,
            "codeCH" : 52,
            "nameUIC" : "Terrasson-Lavilledieu",
            "nameCH" : "Carré 52",
            "code" : "TER"
        },
        {
            "codeUIC" : 594408,
            "codeCH" : 53,
            "nameUIC" : "Terrasson-Lavilledieu",
            "nameCH" : "Carré 53",
            "code" : "TER"
        },
        {
            "codeUIC" : 594416,
            "codeCH" : 0,
            "nameUIC" : "La Rivière-de-Mansac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594424,
            "codeCH" : "BV",
            "nameUIC" : "Larche",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 594424,
            "codeCH" : 13,
            "nameUIC" : "Larche",
            "nameCH" : "Signal 137",
            "code" : ""
        },
        {
            "codeUIC" : 594465,
            "codeCH" : "BV",
            "nameUIC" : "Aubazine-St-Hilaire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 594481,
            "codeCH" : 0,
            "nameUIC" : "Cornil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594499,
            "codeCH" : 0,
            "nameUIC" : "Tulle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594556,
            "codeCH" : 0,
            "nameUIC" : "Turenne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594564,
            "codeCH" : 0,
            "nameUIC" : "Les Quatre-Routes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594572,
            "codeCH" : 0,
            "nameUIC" : "St-Denis-près-Martel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594606,
            "codeCH" : 0,
            "nameUIC" : "Varetz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594614,
            "codeCH" : 0,
            "nameUIC" : "Le Burg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594622,
            "codeCH" : 0,
            "nameUIC" : "St-Aulaire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594630,
            "codeCH" : "BV",
            "nameUIC" : "Objat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 594655,
            "codeCH" : 0,
            "nameUIC" : "Vignols-St-Solve",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594663,
            "codeCH" : "BV",
            "nameUIC" : "Pompadour",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 594671,
            "codeCH" : 0,
            "nameUIC" : "Lubersac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594739,
            "codeCH" : 0,
            "nameUIC" : "Vayrac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594747,
            "codeCH" : 0,
            "nameUIC" : "Bétaille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594754,
            "codeCH" : 0,
            "nameUIC" : "Puybrun",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594762,
            "codeCH" : "BV",
            "nameUIC" : "Bretenoux-Biars",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BXB"
        },
        {
            "codeUIC" : 594796,
            "codeCH" : "BV",
            "nameUIC" : "Laval-de-Cère",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 594820,
            "codeCH" : 0,
            "nameUIC" : "Masseret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594861,
            "codeCH" : 0,
            "nameUIC" : "St-Pardoux-le-Vieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594879,
            "codeCH" : 0,
            "nameUIC" : "Sornac-St-Rémy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 594887,
            "codeCH" : 0,
            "nameUIC" : "La Courtine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595009,
            "codeCH" : "BC",
            "nameUIC" : "Périgueux",
            "nameCH" : "Bifurcation de Coutras",
            "code" : "PX"
        },
        {
            "codeUIC" : 595009,
            "codeCH" : "BV",
            "nameUIC" : "Périgueux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PX"
        },
        {
            "codeUIC" : 595009,
            "codeCH" : "CH",
            "nameUIC" : "Périgueux",
            "nameCH" : "Chamiers",
            "code" : "PX"
        },
        {
            "codeUIC" : 595009,
            "codeCH" : "EC",
            "nameUIC" : "Périgueux",
            "nameCH" : "ITE Chambre de Commerce",
            "code" : "PX"
        },
        {
            "codeUIC" : 595124,
            "codeCH" : "BP",
            "nameUIC" : "Thiviers",
            "nameCH" : "Bifurcation de Périgeux",
            "code" : "TIV"
        },
        {
            "codeUIC" : 595124,
            "codeCH" : "BV",
            "nameUIC" : "Thiviers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TIV"
        },
        {
            "codeUIC" : 595132,
            "codeCH" : 0,
            "nameUIC" : "Négrondes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595157,
            "codeCH" : 0,
            "nameUIC" : "Agonac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595165,
            "codeCH" : 0,
            "nameUIC" : "Château-l'Évêque",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595207,
            "codeCH" : 0,
            "nameUIC" : "Marsac (Dordogne)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595215,
            "codeCH" : 0,
            "nameUIC" : "La Cave",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595223,
            "codeCH" : 0,
            "nameUIC" : "Razac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595249,
            "codeCH" : "BV",
            "nameUIC" : "St-Astier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595249,
            "codeCH" : "C1",
            "nameUIC" : "St-Astier",
            "nameCH" : "Carré 139",
            "code" : ""
        },
        {
            "codeUIC" : 595249,
            "codeCH" : "S2",
            "nameUIC" : "St-Astier",
            "nameCH" : "Sémaphore 140",
            "code" : ""
        },
        {
            "codeUIC" : 595264,
            "codeCH" : 0,
            "nameUIC" : "St-Léon-sur-l'Isle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595272,
            "codeCH" : 0,
            "nameUIC" : "Neuvic (Dordogne)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595280,
            "codeCH" : 0,
            "nameUIC" : "Douzillac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595298,
            "codeCH" : "C1",
            "nameUIC" : "Mussidan",
            "nameCH" : "Carré 133",
            "code" : "MUS"
        },
        {
            "codeUIC" : 595298,
            "codeCH" : "C2",
            "nameUIC" : "Mussidan",
            "nameCH" : "Carré 124",
            "code" : "MUS"
        },
        {
            "codeUIC" : 595298,
            "codeCH" : "S1",
            "nameUIC" : "Mussidan",
            "nameCH" : "Sémaphore 119",
            "code" : "MUS"
        },
        {
            "codeUIC" : 595298,
            "codeCH" : "S2",
            "nameUIC" : "Mussidan",
            "nameCH" : "Sémaphore 136",
            "code" : "MUS"
        },
        {
            "codeUIC" : 595298,
            "codeCH" : 0,
            "nameUIC" : "Mussidan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MUS"
        },
        {
            "codeUIC" : 595322,
            "codeCH" : "S1",
            "nameUIC" : "Montpon-Ménestérol",
            "nameCH" : "Sémaphore 115",
            "code" : ""
        },
        {
            "codeUIC" : 595322,
            "codeCH" : "S2",
            "nameUIC" : "Montpon-Ménestérol",
            "nameCH" : "Sémaphore 122",
            "code" : ""
        },
        {
            "codeUIC" : 595322,
            "codeCH" : 0,
            "nameUIC" : "Montpon-Ménestérol",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595504,
            "codeCH" : 0,
            "nameUIC" : "Périgueux-St-Georges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595512,
            "codeCH" : 0,
            "nameUIC" : "Niversac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NEC"
        },
        {
            "codeUIC" : 595520,
            "codeCH" : 0,
            "nameUIC" : "St-Pierre-de-Chignac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595538,
            "codeCH" : "BV",
            "nameUIC" : "Milhac-d'Auberoche",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595538,
            "codeCH" : 97,
            "nameUIC" : "Milhac-d'Auberoche",
            "nameCH" : "Signal 97",
            "code" : ""
        },
        {
            "codeUIC" : 595553,
            "codeCH" : 0,
            "nameUIC" : "Limeyrat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595579,
            "codeCH" : "BV",
            "nameUIC" : "Thenon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595579,
            "codeCH" : 22,
            "nameUIC" : "Thenon",
            "nameCH" : "Carré 22",
            "code" : ""
        },
        {
            "codeUIC" : 595579,
            "codeCH" : 23,
            "nameUIC" : "Thenon",
            "nameCH" : "Carré 23",
            "code" : ""
        },
        {
            "codeUIC" : 595595,
            "codeCH" : 0,
            "nameUIC" : "La Bachellerie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595702,
            "codeCH" : 0,
            "nameUIC" : "Les Versannes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595710,
            "codeCH" : 0,
            "nameUIC" : "La Gélie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595728,
            "codeCH" : 0,
            "nameUIC" : "Mauzens-Miremont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 595736,
            "codeCH" : 0,
            "nameUIC" : "Les Eyzies",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595744,
            "codeCH" : "BV",
            "nameUIC" : "Le Bugue",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595744,
            "codeCH" : "EM",
            "nameUIC" : "Le Bugue",
            "nameCH" : "ITE de la Mairie",
            "code" : ""
        },
        {
            "codeUIC" : 595751,
            "codeCH" : 0,
            "nameUIC" : "Le Buisson",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595769,
            "codeCH" : 0,
            "nameUIC" : "Siorac-en-Périgord",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595777,
            "codeCH" : 0,
            "nameUIC" : "St-Cyprien-en-Dordogne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595819,
            "codeCH" : 0,
            "nameUIC" : "Vézac-Beynac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595827,
            "codeCH" : 0,
            "nameUIC" : "Sarlat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595876,
            "codeCH" : 0,
            "nameUIC" : "Belvès",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595884,
            "codeCH" : 0,
            "nameUIC" : "Le Got",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 595892,
            "codeCH" : 0,
            "nameUIC" : "Villefranche-du-Périgord",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "BV",
            "nameUIC" : "Châteauroux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "C8",
            "nameUIC" : "Châteauroux",
            "nameCH" : "Carré 208",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EB",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Pétroles BP",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EC",
            "nameUIC" : "Châteauroux",
            "nameCH" : "Sortie Évitement Circulation",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "ED",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Chambre de Commerce",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EF",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Corming Fra",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EL",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Lepine",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EM",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE VVE Montenay",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EP",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Picard",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "ES",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Bernis",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EU",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE UAP",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EV",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Ville de Châteauroux",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EY",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE B Y S",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "EZ",
            "nameUIC" : "Châteauroux",
            "nameCH" : "ITE Zone Industrielle de Châteauroux",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "PB",
            "nameUIC" : "Châteauroux",
            "nameCH" : "Bifurcation de La Châtre",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597005,
            "codeCH" : "PF",
            "nameUIC" : "Châteauroux",
            "nameCH" : "Bifurcation de Buzançais",
            "code" : "CAX"
        },
        {
            "codeUIC" : 597021,
            "codeCH" : 0,
            "nameUIC" : "Chéry-Lury",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597039,
            "codeCH" : "BV",
            "nameUIC" : "Reuilly (Indre)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 597039,
            "codeCH" : "EC",
            "nameUIC" : "Reuilly (Indre)",
            "nameCH" : "ITE Cantin",
            "code" : ""
        },
        {
            "codeUIC" : 597047,
            "codeCH" : 0,
            "nameUIC" : "Diou (Indre)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597054,
            "codeCH" : 0,
            "nameUIC" : "Ste-Lizaigne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597062,
            "codeCH" : "BV",
            "nameUIC" : "Issoudun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "IS"
        },
        {
            "codeUIC" : 597062,
            "codeCH" : "ER",
            "nameUIC" : "Issoudun",
            "nameCH" : "ITE RAQUIN",
            "code" : "IS"
        },
        {
            "codeUIC" : 597062,
            "codeCH" : "ES",
            "nameUIC" : "Issoudun",
            "nameCH" : "ITE SICA",
            "code" : "IS"
        },
        {
            "codeUIC" : 597070,
            "codeCH" : "BV",
            "nameUIC" : "Neuvy-Pailloux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NP"
        },
        {
            "codeUIC" : 597070,
            "codeCH" : "EC",
            "nameUIC" : "Neuvy-Pailloux",
            "nameCH" : "ITE CFPI",
            "code" : "NP"
        },
        {
            "codeUIC" : 597070,
            "codeCH" : "EE",
            "nameUIC" : "Neuvy-Pailloux",
            "nameCH" : "ITE ERGM",
            "code" : "NP"
        },
        {
            "codeUIC" : 597070,
            "codeCH" : "EP",
            "nameUIC" : "Neuvy-Pailloux",
            "nameCH" : "ITE Pichard",
            "code" : "NP"
        },
        {
            "codeUIC" : 597088,
            "codeCH" : "EP",
            "nameUIC" : "Montierchaume",
            "nameCH" : "ITE Km 255,8",
            "code" : "MCM"
        },
        {
            "codeUIC" : 597096,
            "codeCH" : 0,
            "nameUIC" : "Luant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597104,
            "codeCH" : "BV",
            "nameUIC" : "Lothiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 597112,
            "codeCH" : 0,
            "nameUIC" : "Chabenet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597120,
            "codeCH" : "BA",
            "nameUIC" : "Argenton-sur-Creuse",
            "nameCH" : "Bifurcation d'Argenton",
            "code" : "AGC"
        },
        {
            "codeUIC" : 597120,
            "codeCH" : "BV",
            "nameUIC" : "Argenton-sur-Creuse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AGC"
        },
        {
            "codeUIC" : 597146,
            "codeCH" : "BV",
            "nameUIC" : "Celon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CLD"
        },
        {
            "codeUIC" : 597153,
            "codeCH" : 0,
            "nameUIC" : "Éguzon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597179,
            "codeCH" : "LO",
            "nameUIC" : "St-Gaultier",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 597179,
            "codeCH" : "N5",
            "nameUIC" : "St-Gaultier",
            "nameCH" : "PN 285",
            "code" : ""
        },
        {
            "codeUIC" : 597187,
            "codeCH" : "LO",
            "nameUIC" : "Scoury",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 597187,
            "codeCH" : "N2",
            "nameUIC" : "Scoury",
            "nameCH" : "PN 272",
            "code" : ""
        },
        {
            "codeUIC" : 597211,
            "codeCH" : "N3",
            "nameUIC" : "Le Blanc",
            "nameCH" : "PN 263",
            "code" : ""
        },
        {
            "codeUIC" : 597211,
            "codeCH" : "N7",
            "nameUIC" : "Le Blanc",
            "nameCH" : "PN 267",
            "code" : ""
        },
        {
            "codeUIC" : 597211,
            "codeCH" : 0,
            "nameUIC" : "Le Blanc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597294,
            "codeCH" : "LO",
            "nameUIC" : "St-Hilaire (Indre)",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 597310,
            "codeCH" : "LO",
            "nameUIC" : "La Trimouille",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 597344,
            "codeCH" : "BV",
            "nameUIC" : "St-Maur-sur-Indre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SMI"
        },
        {
            "codeUIC" : 597344,
            "codeCH" : "EA",
            "nameUIC" : "St-Maur-sur-Indre",
            "nameCH" : "ITE Coopérative Agricole",
            "code" : "SMI"
        },
        {
            "codeUIC" : 597344,
            "codeCH" : "EC",
            "nameUIC" : "St-Maur-sur-Indre",
            "nameCH" : "ITE Épis Centre",
            "code" : "SMI"
        },
        {
            "codeUIC" : 597344,
            "codeCH" : "LO",
            "nameUIC" : "St-Maur-sur-Indre",
            "nameCH" : "Local",
            "code" : "SMI"
        },
        {
            "codeUIC" : 597369,
            "codeCH" : 0,
            "nameUIC" : "Villedieu-sur-Indre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597377,
            "codeCH" : "EV",
            "nameUIC" : "Buzançais",
            "nameCH" : "ITE Ville de Buzançais",
            "code" : "BCS"
        },
        {
            "codeUIC" : 597377,
            "codeCH" : 0,
            "nameUIC" : "Buzançais",
            "nameCH" : "",
            "code" : "BCS"
        },
        {
            "codeUIC" : 597393,
            "codeCH" : "PN",
            "nameUIC" : "Palluau-St-Genou",
            "nameCH" : "PN 149",
            "code" : ""
        },
        {
            "codeUIC" : 597393,
            "codeCH" : 0,
            "nameUIC" : "Palluau-St-Genou",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597401,
            "codeCH" : 0,
            "nameUIC" : "Clion-sur-Indre",
            "nameCH" : "",
            "code" : "CEW"
        },
        {
            "codeUIC" : 597419,
            "codeCH" : "EC",
            "nameUIC" : "Châtillon-sur-Indre",
            "nameCH" : "ITE CFPEI",
            "code" : "CNI"
        },
        {
            "codeUIC" : 597419,
            "codeCH" : "PN",
            "nameUIC" : "Châtillon-sur-Indre",
            "nameCH" : "PN 121",
            "code" : "CNI"
        },
        {
            "codeUIC" : 597419,
            "codeCH" : 0,
            "nameUIC" : "Châtillon-sur-Indre",
            "nameCH" : "",
            "code" : "CNI"
        },
        {
            "codeUIC" : 597427,
            "codeCH" : "EP",
            "nameUIC" : "Fléré-la-Rivière",
            "nameCH" : "ITE Basque",
            "code" : "FVR"
        },
        {
            "codeUIC" : 597427,
            "codeCH" : 0,
            "nameUIC" : "Fléré-la-Rivière",
            "nameCH" : "",
            "code" : "FVR"
        },
        {
            "codeUIC" : 597443,
            "codeCH" : 0,
            "nameUIC" : "Verneuil-St-Germain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597443,
            "codeCH" : 98,
            "nameUIC" : "Verneuil-St-Germain",
            "nameCH" : "PN 98",
            "code" : ""
        },
        {
            "codeUIC" : 597476,
            "codeCH" : "EA",
            "nameUIC" : "Ardentes",
            "nameCH" : "ITE Administration Militaire",
            "code" : ""
        },
        {
            "codeUIC" : 597476,
            "codeCH" : "ER",
            "nameUIC" : "Ardentes",
            "nameCH" : "ITE Robert",
            "code" : ""
        },
        {
            "codeUIC" : 597476,
            "codeCH" : "N1",
            "nameUIC" : "Ardentes",
            "nameCH" : "PN 221",
            "code" : ""
        },
        {
            "codeUIC" : 597476,
            "codeCH" : "N3",
            "nameUIC" : "Ardentes",
            "nameCH" : "PN 213",
            "code" : ""
        },
        {
            "codeUIC" : 597476,
            "codeCH" : "N8",
            "nameUIC" : "Ardentes",
            "nameCH" : "PN 208",
            "code" : ""
        },
        {
            "codeUIC" : 597476,
            "codeCH" : 0,
            "nameUIC" : "Ardentes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597476,
            "codeCH" : 28,
            "nameUIC" : "Ardentes",
            "nameCH" : "PN 228",
            "code" : ""
        },
        {
            "codeUIC" : 597526,
            "codeCH" : "BA",
            "nameUIC" : "La Châtre",
            "nameCH" : "Bifurcation d'Aigurande",
            "code" : ""
        },
        {
            "codeUIC" : 597526,
            "codeCH" : "BC",
            "nameUIC" : "La Châtre",
            "nameCH" : "Bifurcation de Châteauroux",
            "code" : ""
        },
        {
            "codeUIC" : 597526,
            "codeCH" : "EC",
            "nameUIC" : "La Châtre",
            "nameCH" : "ITE Coopérative Agricole",
            "code" : ""
        },
        {
            "codeUIC" : 597526,
            "codeCH" : "N2",
            "nameUIC" : "La Châtre",
            "nameCH" : "PN 242",
            "code" : ""
        },
        {
            "codeUIC" : 597526,
            "codeCH" : "N4",
            "nameUIC" : "La Châtre",
            "nameCH" : "PN 244",
            "code" : ""
        },
        {
            "codeUIC" : 597526,
            "codeCH" : 0,
            "nameUIC" : "La Châtre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597609,
            "codeCH" : "BV",
            "nameUIC" : "Guéret",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GT"
        },
        {
            "codeUIC" : 597625,
            "codeCH" : "SM",
            "nameUIC" : "Marsac (Creuse)",
            "nameCH" : "Sémaphore 327",
            "code" : ""
        },
        {
            "codeUIC" : 597625,
            "codeCH" : 0,
            "nameUIC" : "Marsac (Creuse)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597633,
            "codeCH" : 0,
            "nameUIC" : "Vieilleville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597641,
            "codeCH" : 0,
            "nameUIC" : "St-Dizier-Leyrenne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597666,
            "codeCH" : "EC",
            "nameUIC" : "Bourganeuf",
            "nameCH" : "ITE Ville de Bourganeuf",
            "code" : "BF"
        },
        {
            "codeUIC" : 597666,
            "codeCH" : "LO",
            "nameUIC" : "Bourganeuf",
            "nameCH" : "Local",
            "code" : "BF"
        },
        {
            "codeUIC" : 597674,
            "codeCH" : "SG",
            "nameUIC" : "Montaigut",
            "nameCH" : "Sémaphore 309",
            "code" : ""
        },
        {
            "codeUIC" : 597674,
            "codeCH" : 0,
            "nameUIC" : "Montaigut",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597682,
            "codeCH" : 0,
            "nameUIC" : "La Brionne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597690,
            "codeCH" : 0,
            "nameUIC" : "Ste-Feyre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597708,
            "codeCH" : 0,
            "nameUIC" : "Busseau-sur-Creuse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597716,
            "codeCH" : 0,
            "nameUIC" : "Cressat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597724,
            "codeCH" : 0,
            "nameUIC" : "Parsac-Gouzon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597732,
            "codeCH" : 0,
            "nameUIC" : "Chanon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597740,
            "codeCH" : "BV",
            "nameUIC" : "Lavaufranche",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LVF"
        },
        {
            "codeUIC" : 597740,
            "codeCH" : "EV",
            "nameUIC" : "Lavaufranche",
            "nameCH" : "ITE Km 354,2",
            "code" : "LVF"
        },
        {
            "codeUIC" : 597740,
            "codeCH" : "S7",
            "nameUIC" : "Lavaufranche",
            "nameCH" : "Carré 7",
            "code" : "LVF"
        },
        {
            "codeUIC" : 597757,
            "codeCH" : 0,
            "nameUIC" : "Boussac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597773,
            "codeCH" : 0,
            "nameUIC" : "Ahun",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597781,
            "codeCH" : 0,
            "nameUIC" : "Lavaveix-les-Mines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597799,
            "codeCH" : 0,
            "nameUIC" : "St-Martial-le-Mont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597807,
            "codeCH" : 0,
            "nameUIC" : "Vaveix-Issoudun",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597815,
            "codeCH" : 0,
            "nameUIC" : "Fourneaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597823,
            "codeCH" : "BV",
            "nameUIC" : "Aubusson",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 597823,
            "codeCH" : "EG",
            "nameUIC" : "Aubusson",
            "nameCH" : "ITE Gaz de France",
            "code" : ""
        },
        {
            "codeUIC" : 597831,
            "codeCH" : 0,
            "nameUIC" : "Moutier-Rozeille-Bourg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 597849,
            "codeCH" : 0,
            "nameUIC" : "Felletin",
            "nameCH" : "",
            "code" : "FEL"
        },
        {
            "codeUIC" : 598169,
            "codeCH" : 0,
            "nameUIC" : "ITE Étang - Feldspaths",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 598219,
            "codeCH" : "WS",
            "nameUIC" : "Le Locle-Col-des-Roches",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 598482,
            "codeCH" : 0,
            "nameUIC" : "ITE Ciments Lafarge (Viviers)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 598706,
            "codeCH" : 0,
            "nameUIC" : "Poste Km 416",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 598748,
            "codeCH" : 0,
            "nameUIC" : "ITE Coopadou",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 598755,
            "codeCH" : 0,
            "nameUIC" : "Limoux-Flassian",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 598789,
            "codeCH" : 0,
            "nameUIC" : "Carré 300",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 598797,
            "codeCH" : 0,
            "nameUIC" : "Carré 301",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 608802,
            "codeCH" : "BV",
            "nameUIC" : "Créteil-Pompadour",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "AA",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "ITE Ergmt",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "BV",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "EA",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "ITE Administration militaire",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "EM",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "ITE Société des Magasins Généraux",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "E2",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Entrée EC2",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "J1",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Aiguilles 7102/7103",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "J3",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Jonction 3",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "LC",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Poste T",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "P1",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "P6",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Poste 6",
            "code" : ""
        },
        {
            "codeUIC" : 611004,
            "codeCH" : "S2",
            "nameUIC" : "Toulouse-Matabiau",
            "nameCH" : "Sortie EC2",
            "code" : ""
        },
        {
            "codeUIC" : 611012,
            "codeCH" : "L5",
            "nameUIC" : "Toulouse-Raynal-Triage",
            "nameCH" : "Point de Manoeuvre G5",
            "code" : ""
        },
        {
            "codeUIC" : 611012,
            "codeCH" : "TR",
            "nameUIC" : "Toulouse-Raynal-Triage",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 611020,
            "codeCH" : "BF",
            "nameUIC" : "Empalot",
            "nameCH" : "Bifurcation",
            "code" : "EPT"
        },
        {
            "codeUIC" : 611020,
            "codeCH" : "EP",
            "nameUIC" : "Empalot",
            "nameCH" : "ITE Km 5,7",
            "code" : "EPT"
        },
        {
            "codeUIC" : 611038,
            "codeCH" : "BV",
            "nameUIC" : "Muret",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MET"
        },
        {
            "codeUIC" : 611038,
            "codeCH" : "EA",
            "nameUIC" : "Muret",
            "nameCH" : "ITE Km 17,9",
            "code" : "MET"
        },
        {
            "codeUIC" : 611038,
            "codeCH" : "EM",
            "nameUIC" : "Muret",
            "nameCH" : "ITE MEUNERIE",
            "code" : "MET"
        },
        {
            "codeUIC" : 611038,
            "codeCH" : 22,
            "nameUIC" : "Muret",
            "nameCH" : "Carré 322",
            "code" : "MET"
        },
        {
            "codeUIC" : 611038,
            "codeCH" : 29,
            "nameUIC" : "Muret",
            "nameCH" : "Carré 329",
            "code" : "MET"
        },
        {
            "codeUIC" : 611046,
            "codeCH" : 0,
            "nameUIC" : "Fauga",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611046,
            "codeCH" : 46,
            "nameUIC" : "Fauga",
            "nameCH" : "Signal 346",
            "code" : ""
        },
        {
            "codeUIC" : 611053,
            "codeCH" : 0,
            "nameUIC" : "Longages-Noé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611053,
            "codeCH" : 50,
            "nameUIC" : "Longages-Noé",
            "nameCH" : "Carré 102",
            "code" : ""
        },
        {
            "codeUIC" : 611061,
            "codeCH" : "BV",
            "nameUIC" : "Carbonne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COA"
        },
        {
            "codeUIC" : 611061,
            "codeCH" : "ES",
            "nameUIC" : "Carbonne",
            "nameCH" : "ITE Km 39,1",
            "code" : "COA"
        },
        {
            "codeUIC" : 611061,
            "codeCH" : 51,
            "nameUIC" : "Carbonne",
            "nameCH" : "carré 351",
            "code" : "COA"
        },
        {
            "codeUIC" : 611061,
            "codeCH" : 54,
            "nameUIC" : "Carbonne",
            "nameCH" : "carré 354",
            "code" : "COA"
        },
        {
            "codeUIC" : 611079,
            "codeCH" : "BV",
            "nameUIC" : "Cazères-sur-Garonne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CZG"
        },
        {
            "codeUIC" : 611079,
            "codeCH" : "ES",
            "nameUIC" : "Cazères-sur-Garonne",
            "nameCH" : "ITE Km 58,5",
            "code" : "CZG"
        },
        {
            "codeUIC" : 611079,
            "codeCH" : 56,
            "nameUIC" : "Cazères-sur-Garonne",
            "nameCH" : "Carré 56,2",
            "code" : "CZG"
        },
        {
            "codeUIC" : 611079,
            "codeCH" : 87,
            "nameUIC" : "Cazères-sur-Garonne",
            "nameCH" : "Carré 287",
            "code" : "CZG"
        },
        {
            "codeUIC" : 611087,
            "codeCH" : 0,
            "nameUIC" : "Martres-Tolosane",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611095,
            "codeCH" : "BF",
            "nameUIC" : "Boussens",
            "nameCH" : "Bifurcation du Fourc",
            "code" : "BSS"
        },
        {
            "codeUIC" : 611095,
            "codeCH" : "BV",
            "nameUIC" : "Boussens",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BSS"
        },
        {
            "codeUIC" : 611095,
            "codeCH" : "EA",
            "nameUIC" : "Boussens",
            "nameCH" : "ITE Km 67,5",
            "code" : "BSS"
        },
        {
            "codeUIC" : 611095,
            "codeCH" : "EE",
            "nameUIC" : "Boussens",
            "nameCH" : "ITE Km 67,6",
            "code" : "BSS"
        },
        {
            "codeUIC" : 611095,
            "codeCH" : "EL",
            "nameUIC" : "Boussens",
            "nameCH" : "ITE Km 64,7",
            "code" : "BSS"
        },
        {
            "codeUIC" : 611095,
            "codeCH" : 1,
            "nameUIC" : "Boussens",
            "nameCH" : "Carré 601",
            "code" : "BSS"
        },
        {
            "codeUIC" : 611095,
            "codeCH" : 92,
            "nameUIC" : "Boussens",
            "nameCH" : "Signal 692",
            "code" : "BSS"
        },
        {
            "codeUIC" : 611103,
            "codeCH" : 0,
            "nameUIC" : "St-Martory",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611111,
            "codeCH" : 0,
            "nameUIC" : "Lestelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611129,
            "codeCH" : 0,
            "nameUIC" : "Labarthe-Inard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611137,
            "codeCH" : "BV",
            "nameUIC" : "St-Gaudens",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SGE"
        },
        {
            "codeUIC" : 611137,
            "codeCH" : "EC",
            "nameUIC" : "St-Gaudens",
            "nameCH" : "ITE Km 91,7",
            "code" : "SGE"
        },
        {
            "codeUIC" : 611152,
            "codeCH" : 0,
            "nameUIC" : "Montréjeau-Gourdan-Polignan",
            "nameCH" : "",
            "code" : "MTJ"
        },
        {
            "codeUIC" : 611178,
            "codeCH" : 0,
            "nameUIC" : "Loures-Barbazan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611194,
            "codeCH" : 0,
            "nameUIC" : "Marignac-St-Béat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611236,
            "codeCH" : 0,
            "nameUIC" : "Luchon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611244,
            "codeCH" : "BV",
            "nameUIC" : "Montauban-Ville-Bourbon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MBN"
        },
        {
            "codeUIC" : 611244,
            "codeCH" : "EA",
            "nameUIC" : "Montauban-Ville-Bourbon",
            "nameCH" : "ITE ALBASUD",
            "code" : "MBN"
        },
        {
            "codeUIC" : 611244,
            "codeCH" : "EE",
            "nameUIC" : "Montauban-Ville-Bourbon",
            "nameCH" : "ITE Km 209,6",
            "code" : "MBN"
        },
        {
            "codeUIC" : 611244,
            "codeCH" : "EF",
            "nameUIC" : "Montauban-Ville-Bourbon",
            "nameCH" : "ITE Km 196,0",
            "code" : "MBN"
        },
        {
            "codeUIC" : 611244,
            "codeCH" : "EZ",
            "nameUIC" : "Montauban-Ville-Bourbon",
            "nameCH" : "ITE Zone Industrielle de Montauban",
            "code" : "MBN"
        },
        {
            "codeUIC" : 611301,
            "codeCH" : 0,
            "nameUIC" : "St-Agne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611327,
            "codeCH" : 0,
            "nameUIC" : "Varilhes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611343,
            "codeCH" : "EP",
            "nameUIC" : "Pamiers",
            "nameCH" : "Km 59,8",
            "code" : "PMS"
        },
        {
            "codeUIC" : 611343,
            "codeCH" : 0,
            "nameUIC" : "Pamiers",
            "nameCH" : "",
            "code" : "PMS"
        },
        {
            "codeUIC" : 611350,
            "codeCH" : "BV",
            "nameUIC" : "Vernet-d'Ariège",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611368,
            "codeCH" : 0,
            "nameUIC" : "Saverdun",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611368,
            "codeCH" : 51,
            "nameUIC" : "Saverdun",
            "nameCH" : "Embranchement 51,2",
            "code" : ""
        },
        {
            "codeUIC" : 611368,
            "codeCH" : 53,
            "nameUIC" : "Saverdun",
            "nameCH" : "Embranchement 53,3",
            "code" : ""
        },
        {
            "codeUIC" : 611376,
            "codeCH" : 0,
            "nameUIC" : "Cintegabelle",
            "nameCH" : "",
            "code" : "CTG"
        },
        {
            "codeUIC" : 611384,
            "codeCH" : "BV",
            "nameUIC" : "Auterive",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611384,
            "codeCH" : "EB",
            "nameUIC" : "Auterive",
            "nameCH" : "ITE Produits de Blé",
            "code" : ""
        },
        {
            "codeUIC" : 611392,
            "codeCH" : 0,
            "nameUIC" : "Vénerque-le-Vernet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611400,
            "codeCH" : "BV",
            "nameUIC" : "Portet-St-Simon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PSS"
        },
        {
            "codeUIC" : 611400,
            "codeCH" : "ED",
            "nameUIC" : "Portet-St-Simon",
            "nameCH" : "ITE DARTUS",
            "code" : "PSS"
        },
        {
            "codeUIC" : 611400,
            "codeCH" : "ES",
            "nameUIC" : "Portet-St-Simon",
            "nameCH" : "ITE Km 9,6",
            "code" : "PSS"
        },
        {
            "codeUIC" : 611418,
            "codeCH" : 0,
            "nameUIC" : "Pinsaguel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611426,
            "codeCH" : "G4",
            "nameUIC" : "Toulouse-Matabiau (MIN)",
            "nameCH" : "ITE Min",
            "code" : ""
        },
        {
            "codeUIC" : 611434,
            "codeCH" : "BV",
            "nameUIC" : "Lacourtensourt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611434,
            "codeCH" : "EA",
            "nameUIC" : "Lacourtensourt",
            "nameCH" : "ITE Km 252,0",
            "code" : ""
        },
        {
            "codeUIC" : 611434,
            "codeCH" : "EG",
            "nameUIC" : "Lacourtensourt",
            "nameCH" : "ITE Soferti",
            "code" : ""
        },
        {
            "codeUIC" : 611442,
            "codeCH" : "ES",
            "nameUIC" : "Fenouillet",
            "nameCH" : "Chantier Novatrans",
            "code" : "FEN"
        },
        {
            "codeUIC" : 611459,
            "codeCH" : "IP",
            "nameUIC" : "Lavilledieu",
            "nameCH" : "Carré 531",
            "code" : ""
        },
        {
            "codeUIC" : 611459,
            "codeCH" : "PA",
            "nameUIC" : "Lavilledieu",
            "nameCH" : "Sémaphore 189",
            "code" : ""
        },
        {
            "codeUIC" : 611459,
            "codeCH" : "PR",
            "nameUIC" : "Lavilledieu",
            "nameCH" : "Carré 522",
            "code" : ""
        },
        {
            "codeUIC" : 611459,
            "codeCH" : 0,
            "nameUIC" : "Lavilledieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611459,
            "codeCH" : 51,
            "nameUIC" : "Lavilledieu",
            "nameCH" : "Carré 512",
            "code" : ""
        },
        {
            "codeUIC" : 611467,
            "codeCH" : "BV",
            "nameUIC" : "Colomiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COL"
        },
        {
            "codeUIC" : 611467,
            "codeCH" : "EZ",
            "nameUIC" : "Colomiers",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "COL"
        },
        {
            "codeUIC" : 611483,
            "codeCH" : 0,
            "nameUIC" : "Latour-de-Carol-Enveitg",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611491,
            "codeCH" : 0,
            "nameUIC" : "Porta",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611509,
            "codeCH" : 0,
            "nameUIC" : "Porté-Puymorens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611517,
            "codeCH" : 0,
            "nameUIC" : "L'Hospitalet-près-l'Andorre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611525,
            "codeCH" : 0,
            "nameUIC" : "Mérens-les-Vals",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611533,
            "codeCH" : 0,
            "nameUIC" : "Ax-les-Thermes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611541,
            "codeCH" : 0,
            "nameUIC" : "Le Castelet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611558,
            "codeCH" : 0,
            "nameUIC" : "Luzenac-Garanou",
            "nameCH" : "",
            "code" : "LUG"
        },
        {
            "codeUIC" : 611566,
            "codeCH" : 0,
            "nameUIC" : "Vèbre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611574,
            "codeCH" : "BV",
            "nameUIC" : "Les Cabannes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611582,
            "codeCH" : 0,
            "nameUIC" : "Ussat-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611590,
            "codeCH" : "BV",
            "nameUIC" : "Tarascon-sur-Ariège",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TRC"
        },
        {
            "codeUIC" : 611590,
            "codeCH" : "EP",
            "nameUIC" : "Tarascon-sur-Ariège",
            "nameCH" : "ITE Km 99,3/6",
            "code" : "TRC"
        },
        {
            "codeUIC" : 611608,
            "codeCH" : 0,
            "nameUIC" : "St-Paul-St-Antoine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611616,
            "codeCH" : 0,
            "nameUIC" : "Foix",
            "nameCH" : "",
            "code" : "FX"
        },
        {
            "codeUIC" : 611624,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-de-Verges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611632,
            "codeCH" : 0,
            "nameUIC" : "Beaumont-de-Lomagne",
            "nameCH" : "",
            "code" : "BDM"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : "BV",
            "nameUIC" : "St-Jory",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : "CN",
            "nameUIC" : "St-Jory",
            "nameCH" : "Chantier CNC",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : "FA",
            "nameUIC" : "St-Jory",
            "nameCH" : "Faisceau Attente au Départ",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : "QU",
            "nameUIC" : "St-Jory",
            "nameCH" : "Quai",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : "RC",
            "nameUIC" : "St-Jory",
            "nameCH" : "Chantier Équipement",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : "RT",
            "nameUIC" : "St-Jory",
            "nameCH" : "Triage",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : 10,
            "nameUIC" : "St-Jory",
            "nameCH" : "Poste 10",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : 12,
            "nameUIC" : "St-Jory",
            "nameCH" : "Poste 12",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : 14,
            "nameUIC" : "St-Jory",
            "nameCH" : "Poste 14",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611657,
            "codeCH" : 15,
            "nameUIC" : "St-Jory",
            "nameCH" : "Poste 15",
            "code" : "SJR"
        },
        {
            "codeUIC" : 611665,
            "codeCH" : "EP",
            "nameUIC" : "Castelnau-d'Estrétefonds",
            "nameCH" : "ITE Km 236,7",
            "code" : "CDF"
        },
        {
            "codeUIC" : 611665,
            "codeCH" : "P1",
            "nameUIC" : "Castelnau-d'Estrétefonds",
            "nameCH" : "Poste 1",
            "code" : "CDF"
        },
        {
            "codeUIC" : 611665,
            "codeCH" : 0,
            "nameUIC" : "Castelnau-d'Estrétefonds",
            "nameCH" : "",
            "code" : "CDF"
        },
        {
            "codeUIC" : 611673,
            "codeCH" : "BV",
            "nameUIC" : "Grisolles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GRI"
        },
        {
            "codeUIC" : 611681,
            "codeCH" : "BV",
            "nameUIC" : "Dieupentale",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611681,
            "codeCH" : "ES",
            "nameUIC" : "Dieupentale",
            "nameCH" : "ITE Km 227,6",
            "code" : ""
        },
        {
            "codeUIC" : 611681,
            "codeCH" : "EU",
            "nameUIC" : "Dieupentale",
            "nameCH" : "ITE Société Durand",
            "code" : ""
        },
        {
            "codeUIC" : 611699,
            "codeCH" : "BV",
            "nameUIC" : "Montbartier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MNG"
        },
        {
            "codeUIC" : 611707,
            "codeCH" : "BV",
            "nameUIC" : "Escalquens",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EQ"
        },
        {
            "codeUIC" : 611707,
            "codeCH" : "EC",
            "nameUIC" : "Escalquens",
            "nameCH" : "ITE Carosse",
            "code" : "EQ"
        },
        {
            "codeUIC" : 611707,
            "codeCH" : "EG",
            "nameUIC" : "Escalquens",
            "nameCH" : "ITE Grande Métairie",
            "code" : "EQ"
        },
        {
            "codeUIC" : 611707,
            "codeCH" : "EM",
            "nameUIC" : "Escalquens",
            "nameCH" : "ITE SMEI",
            "code" : "EQ"
        },
        {
            "codeUIC" : 611707,
            "codeCH" : "ES",
            "nameUIC" : "Escalquens",
            "nameCH" : "ITE Stela",
            "code" : "EQ"
        },
        {
            "codeUIC" : 611723,
            "codeCH" : 0,
            "nameUIC" : "Baziège",
            "nameCH" : "",
            "code" : "BAZ"
        },
        {
            "codeUIC" : 611749,
            "codeCH" : "BV",
            "nameUIC" : "Auch",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AH"
        },
        {
            "codeUIC" : 611749,
            "codeCH" : "EC",
            "nameUIC" : "Auch",
            "nameCH" : "ITE Km 191,8/192,3",
            "code" : "AH"
        },
        {
            "codeUIC" : 611749,
            "codeCH" : "EZ",
            "nameUIC" : "Auch",
            "nameCH" : "ITE ZI Lucante",
            "code" : "AH"
        },
        {
            "codeUIC" : 611764,
            "codeCH" : 0,
            "nameUIC" : "Aubiet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611772,
            "codeCH" : 0,
            "nameUIC" : "Gimont-Cahuzac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611798,
            "codeCH" : 0,
            "nameUIC" : "Monferran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611806,
            "codeCH" : 0,
            "nameUIC" : "L'Isle-Jourdain (Gers)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611814,
            "codeCH" : 0,
            "nameUIC" : "Mérenvielle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611822,
            "codeCH" : 0,
            "nameUIC" : "Brax-Léguevin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611830,
            "codeCH" : 0,
            "nameUIC" : "Pibrac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611848,
            "codeCH" : "J1",
            "nameUIC" : "Montrabé",
            "nameCH" : "Aiguille 7101",
            "code" : ""
        },
        {
            "codeUIC" : 611848,
            "codeCH" : 0,
            "nameUIC" : "Montrabé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611855,
            "codeCH" : "PA",
            "nameUIC" : "Lamagistère",
            "nameCH" : "Sémaphore 151,1",
            "code" : ""
        },
        {
            "codeUIC" : 611855,
            "codeCH" : 0,
            "nameUIC" : "Lamagistère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611863,
            "codeCH" : "BV",
            "nameUIC" : "Valence-d'Agen",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VCD"
        },
        {
            "codeUIC" : 611863,
            "codeCH" : "ED",
            "nameUIC" : "Valence-d'Agen",
            "nameCH" : "ITE Duquesne",
            "code" : "VCD"
        },
        {
            "codeUIC" : 611863,
            "codeCH" : "EF",
            "nameUIC" : "Valence-d'Agen",
            "nameCH" : "ITE EDF Golfech",
            "code" : "VCD"
        },
        {
            "codeUIC" : 611863,
            "codeCH" : "IM",
            "nameUIC" : "Valence-d'Agen",
            "nameCH" : "Carré 441",
            "code" : "VCD"
        },
        {
            "codeUIC" : 611863,
            "codeCH" : "IP",
            "nameUIC" : "Valence-d'Agen",
            "nameCH" : "Carré 461",
            "code" : "VCD"
        },
        {
            "codeUIC" : 611863,
            "codeCH" : "ZI",
            "nameUIC" : "Valence-d'Agen",
            "nameCH" : "ITE Zone Industrielle de Valence-d'Agen",
            "code" : "VCD"
        },
        {
            "codeUIC" : 611871,
            "codeCH" : "IM",
            "nameUIC" : "Malause",
            "nameCH" : "Carré 491",
            "code" : "MSP"
        },
        {
            "codeUIC" : 611871,
            "codeCH" : "IP",
            "nameUIC" : "Malause",
            "nameCH" : "Carré 481",
            "code" : "MSP"
        },
        {
            "codeUIC" : 611871,
            "codeCH" : 0,
            "nameUIC" : "Malause",
            "nameCH" : "",
            "code" : "MSP"
        },
        {
            "codeUIC" : 611871,
            "codeCH" : 46,
            "nameUIC" : "Malause",
            "nameCH" : "Sémaphore 462",
            "code" : "MSP"
        },
        {
            "codeUIC" : 611889,
            "codeCH" : "BV",
            "nameUIC" : "Moissac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 611889,
            "codeCH" : "IM",
            "nameUIC" : "Moissac",
            "nameCH" : "Carré 184,8",
            "code" : ""
        },
        {
            "codeUIC" : 611889,
            "codeCH" : "IP",
            "nameUIC" : "Moissac",
            "nameCH" : "Sémaphore 180,6",
            "code" : ""
        },
        {
            "codeUIC" : 611889,
            "codeCH" : "PA",
            "nameUIC" : "Moissac",
            "nameCH" : "Carré 472",
            "code" : ""
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "BV",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "EA",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE Km 190,5",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "EB",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE Km 194,4/9",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "EC",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE Km 203,0/4",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "ED",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE Km 188,2",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "EM",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE CAMB",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "EP",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE Parc Caténaire",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "ES",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE Km 189,2",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "EU",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "ITE Km 207,7/208,2",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "IP",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "Carré 521",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : "LM",
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "Pancarte LM",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : 0,
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611897,
            "codeCH" : 49,
            "nameUIC" : "Castelsarrasin",
            "nameCH" : "Carré 492",
            "code" : "CTN"
        },
        {
            "codeUIC" : 611921,
            "codeCH" : 0,
            "nameUIC" : "Colomiers-Lycée-International",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611939,
            "codeCH" : 0,
            "nameUIC" : "Lardenne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611947,
            "codeCH" : 0,
            "nameUIC" : "Lalande-Église",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611954,
            "codeCH" : 0,
            "nameUIC" : "Route-de-Launaguet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 611962,
            "codeCH" : 0,
            "nameUIC" : "Fenouillet-St-Alban",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 612002,
            "codeCH" : 0,
            "nameUIC" : "Labège-Innopole",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 612010,
            "codeCH" : 0,
            "nameUIC" : "Montaudran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 612028,
            "codeCH" : 0,
            "nameUIC" : "Labège-Village",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613000,
            "codeCH" : "BC",
            "nameUIC" : "Cahors",
            "nameCH" : "Bifurcation de Capdenac",
            "code" : "CAH"
        },
        {
            "codeUIC" : 613000,
            "codeCH" : "BV",
            "nameUIC" : "Cahors",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CAH"
        },
        {
            "codeUIC" : 613026,
            "codeCH" : 0,
            "nameUIC" : "Floirac (Lot)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613042,
            "codeCH" : "BV",
            "nameUIC" : "Rocamadour-Padirac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 613042,
            "codeCH" : "EA",
            "nameUIC" : "Rocamadour-Padirac",
            "nameCH" : "ITE Armée de l'Air",
            "code" : ""
        },
        {
            "codeUIC" : 613059,
            "codeCH" : "BV",
            "nameUIC" : "Gramat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 613059,
            "codeCH" : "EM",
            "nameUIC" : "Gramat",
            "nameCH" : "ITE Administration Militaire",
            "code" : ""
        },
        {
            "codeUIC" : 613067,
            "codeCH" : 0,
            "nameUIC" : "Flaujac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613075,
            "codeCH" : 0,
            "nameUIC" : "Assier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613083,
            "codeCH" : 0,
            "nameUIC" : "Le Pournel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613091,
            "codeCH" : "BV",
            "nameUIC" : "Figeac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 613091,
            "codeCH" : "EG",
            "nameUIC" : "Figeac",
            "nameCH" : "ITE GLEYE",
            "code" : ""
        },
        {
            "codeUIC" : 613109,
            "codeCH" : "BV",
            "nameUIC" : "Capdenac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CDC"
        },
        {
            "codeUIC" : 613109,
            "codeCH" : "RC",
            "nameUIC" : "Capdenac",
            "nameCH" : "Raccordement de Cahors",
            "code" : "CDC"
        },
        {
            "codeUIC" : 613117,
            "codeCH" : 0,
            "nameUIC" : "La Chapelle-de-Mareuil",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613125,
            "codeCH" : 0,
            "nameUIC" : "Lamothe-Fénelon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613133,
            "codeCH" : 0,
            "nameUIC" : "Anglars-Nozac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613141,
            "codeCH" : "BV",
            "nameUIC" : "Gourdon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 613166,
            "codeCH" : 0,
            "nameUIC" : "St-Clair",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613174,
            "codeCH" : 0,
            "nameUIC" : "Dégagnac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613182,
            "codeCH" : "BV",
            "nameUIC" : "Thédirac-Peyrilles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "THÉ"
        },
        {
            "codeUIC" : 613190,
            "codeCH" : 0,
            "nameUIC" : "St-Denis-Catus",
            "nameCH" : "",
            "code" : "SDS"
        },
        {
            "codeUIC" : 613216,
            "codeCH" : "BV",
            "nameUIC" : "Espère-Caillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 613216,
            "codeCH" : "EG",
            "nameUIC" : "Espère-Caillac",
            "nameCH" : "ITE G CAMPS",
            "code" : ""
        },
        {
            "codeUIC" : 613216,
            "codeCH" : "EV",
            "nameUIC" : "Espère-Caillac",
            "nameCH" : "ITE Ville de Cahors",
            "code" : ""
        },
        {
            "codeUIC" : 613224,
            "codeCH" : 0,
            "nameUIC" : "Aubin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613232,
            "codeCH" : 0,
            "nameUIC" : "Cransac",
            "nameCH" : "",
            "code" : "CRN"
        },
        {
            "codeUIC" : 613240,
            "codeCH" : 0,
            "nameUIC" : "Auzits-Aussibals",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613257,
            "codeCH" : "VT",
            "nameUIC" : "St-Christophe",
            "nameCH" : "Viaduc de Tournemire",
            "code" : ""
        },
        {
            "codeUIC" : 613257,
            "codeCH" : 0,
            "nameUIC" : "St-Christophe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613273,
            "codeCH" : "VC",
            "nameUIC" : "Nuces",
            "nameCH" : "Val Cougouse",
            "code" : ""
        },
        {
            "codeUIC" : 613273,
            "codeCH" : 0,
            "nameUIC" : "Nuces",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613299,
            "codeCH" : "VS",
            "nameUIC" : "Salles-la-Source",
            "nameCH" : "Viaduc de Souyri",
            "code" : ""
        },
        {
            "codeUIC" : 613315,
            "codeCH" : 0,
            "nameUIC" : "Cieurac (Lot)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613323,
            "codeCH" : "BV",
            "nameUIC" : "Lalbenque-Fontanes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 613331,
            "codeCH" : 0,
            "nameUIC" : "Montpezat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613349,
            "codeCH" : 0,
            "nameUIC" : "Borredon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613356,
            "codeCH" : 0,
            "nameUIC" : "Caussade (Tarn-et-Garonne)",
            "nameCH" : "",
            "code" : "CDE"
        },
        {
            "codeUIC" : 613380,
            "codeCH" : 0,
            "nameUIC" : "Réalville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613398,
            "codeCH" : 0,
            "nameUIC" : "Albias",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613422,
            "codeCH" : "BV",
            "nameUIC" : "Rodez",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ROZ"
        },
        {
            "codeUIC" : 613422,
            "codeCH" : "ER",
            "nameUIC" : "Rodez",
            "nameCH" : "ITE Km 619,6",
            "code" : "ROZ"
        },
        {
            "codeUIC" : 613422,
            "codeCH" : "ZI",
            "nameUIC" : "Rodez",
            "nameCH" : "ITE Km 622,5",
            "code" : "ROZ"
        },
        {
            "codeUIC" : 613430,
            "codeCH" : 0,
            "nameUIC" : "Paraire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613455,
            "codeCH" : 0,
            "nameUIC" : "Monteils",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613463,
            "codeCH" : 0,
            "nameUIC" : "Najac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613471,
            "codeCH" : 0,
            "nameUIC" : "Laguépie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613489,
            "codeCH" : 0,
            "nameUIC" : "Lexos",
            "nameCH" : "",
            "code" : "LEX"
        },
        {
            "codeUIC" : 613497,
            "codeCH" : 0,
            "nameUIC" : "Viazac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613505,
            "codeCH" : 0,
            "nameUIC" : "Cabessut",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613513,
            "codeCH" : 0,
            "nameUIC" : "Arcambal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613521,
            "codeCH" : 0,
            "nameUIC" : "Vers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613539,
            "codeCH" : 0,
            "nameUIC" : "St-Géry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613547,
            "codeCH" : 0,
            "nameUIC" : "Conduché",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613554,
            "codeCH" : 0,
            "nameUIC" : "St-Cirq-Lapopie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613570,
            "codeCH" : 0,
            "nameUIC" : "Larnagol-Calvignac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613588,
            "codeCH" : 0,
            "nameUIC" : "Cajarc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613604,
            "codeCH" : "BV",
            "nameUIC" : "Bagnac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BAG"
        },
        {
            "codeUIC" : 613604,
            "codeCH" : "EB",
            "nameUIC" : "Bagnac",
            "nameCH" : "ITE Larive",
            "code" : "BAG"
        },
        {
            "codeUIC" : 613604,
            "codeCH" : "EC",
            "nameUIC" : "Bagnac",
            "nameCH" : "ITE des Carrières de Bagnac",
            "code" : "BAG"
        },
        {
            "codeUIC" : 613612,
            "codeCH" : 0,
            "nameUIC" : "Toirac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613620,
            "codeCH" : 0,
            "nameUIC" : "La Madeleine-sur-Lot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613646,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-de-Bouillac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613661,
            "codeCH" : "BV",
            "nameUIC" : "Viviez-Decazeville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VZ"
        },
        {
            "codeUIC" : 613661,
            "codeCH" : "EH",
            "nameUIC" : "Viviez-Decazeville",
            "nameCH" : "ITE Km 256,1",
            "code" : "VZ"
        },
        {
            "codeUIC" : 613802,
            "codeCH" : 0,
            "nameUIC" : "Cordes-Vindrac",
            "nameCH" : "",
            "code" : "CVD"
        },
        {
            "codeUIC" : 613810,
            "codeCH" : 0,
            "nameUIC" : "Donnazac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613869,
            "codeCH" : 0,
            "nameUIC" : "Naussac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613877,
            "codeCH" : 0,
            "nameUIC" : "Salles-Courbatiès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613885,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-d'Aveyron",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 613893,
            "codeCH" : "BV",
            "nameUIC" : "Villefranche-de-Rouergue",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VR"
        },
        {
            "codeUIC" : 613893,
            "codeCH" : "EB",
            "nameUIC" : "Villefranche-de-Rouergue",
            "nameCH" : "ITE BACH",
            "code" : "VR"
        },
        {
            "codeUIC" : 613893,
            "codeCH" : "EZ",
            "nameUIC" : "Villefranche-de-Rouergue",
            "nameCH" : "ITE Km 275,5",
            "code" : "VR"
        },
        {
            "codeUIC" : 615005,
            "codeCH" : "AG",
            "nameUIC" : "Albi-Ville",
            "nameCH" : "Bifurcation",
            "code" : "AL"
        },
        {
            "codeUIC" : 615005,
            "codeCH" : "BV",
            "nameUIC" : "Albi-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AL"
        },
        {
            "codeUIC" : 615005,
            "codeCH" : "EE",
            "nameUIC" : "Albi-Ville",
            "nameCH" : "ITE Km 350,9",
            "code" : "AL"
        },
        {
            "codeUIC" : 615005,
            "codeCH" : "EL",
            "nameUIC" : "Albi-Ville",
            "nameCH" : "ITE Km 410,2/4",
            "code" : "AL"
        },
        {
            "codeUIC" : 615005,
            "codeCH" : "ER",
            "nameUIC" : "Albi-Ville",
            "nameCH" : "ITE Km 417,9",
            "code" : "AL"
        },
        {
            "codeUIC" : 615005,
            "codeCH" : "ES",
            "nameUIC" : "Albi-Ville",
            "nameCH" : "ITE Stela",
            "code" : "AL"
        },
        {
            "codeUIC" : 615005,
            "codeCH" : "EU",
            "nameUIC" : "Albi-Ville",
            "nameCH" : "ITE Km 418,7",
            "code" : "AL"
        },
        {
            "codeUIC" : 615013,
            "codeCH" : 0,
            "nameUIC" : "Villefranche-de-Lauragais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615021,
            "codeCH" : 0,
            "nameUIC" : "Ségala",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615039,
            "codeCH" : "BV",
            "nameUIC" : "Castelnaudary",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CTY"
        },
        {
            "codeUIC" : 615039,
            "codeCH" : "SG",
            "nameUIC" : "Castelnaudary",
            "nameCH" : "Carré 204",
            "code" : "CTY"
        },
        {
            "codeUIC" : 615039,
            "codeCH" : 12,
            "nameUIC" : "Castelnaudary",
            "nameCH" : "PN 12",
            "code" : "CTY"
        },
        {
            "codeUIC" : 615039,
            "codeCH" : 18,
            "nameUIC" : "Castelnaudary",
            "nameCH" : "PN 18",
            "code" : "CTY"
        },
        {
            "codeUIC" : 615047,
            "codeCH" : 0,
            "nameUIC" : "Pexiora",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615054,
            "codeCH" : 0,
            "nameUIC" : "Bram",
            "nameCH" : "",
            "code" : "BRM"
        },
        {
            "codeUIC" : 615062,
            "codeCH" : 0,
            "nameUIC" : "Trèbes",
            "nameCH" : "",
            "code" : "TBS"
        },
        {
            "codeUIC" : 615088,
            "codeCH" : 0,
            "nameUIC" : "Capendu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615096,
            "codeCH" : 0,
            "nameUIC" : "Moux (Aude)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615112,
            "codeCH" : 0,
            "nameUIC" : "Lézignan (Aude)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615120,
            "codeCH" : 0,
            "nameUIC" : "Couffoulens-Leuc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615138,
            "codeCH" : 0,
            "nameUIC" : "Verzeille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615146,
            "codeCH" : 0,
            "nameUIC" : "Pomas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615161,
            "codeCH" : "BV",
            "nameUIC" : "Limoux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 615161,
            "codeCH" : "EP",
            "nameUIC" : "Limoux",
            "nameCH" : "ITE Bargues",
            "code" : ""
        },
        {
            "codeUIC" : 615187,
            "codeCH" : 0,
            "nameUIC" : "Alet-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615195,
            "codeCH" : 0,
            "nameUIC" : "Albi-Madeleine",
            "nameCH" : "",
            "code" : "ALM"
        },
        {
            "codeUIC" : 615203,
            "codeCH" : 0,
            "nameUIC" : "Couiza-Montazels",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615211,
            "codeCH" : 0,
            "nameUIC" : "Espéraza",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615229,
            "codeCH" : 0,
            "nameUIC" : "Campagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615245,
            "codeCH" : 0,
            "nameUIC" : "Tessonnières",
            "nameCH" : "",
            "code" : "TES"
        },
        {
            "codeUIC" : 615252,
            "codeCH" : "BV",
            "nameUIC" : "Gaillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GAI"
        },
        {
            "codeUIC" : 615260,
            "codeCH" : 0,
            "nameUIC" : "Quillan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615286,
            "codeCH" : "AG",
            "nameUIC" : "Carcassonne",
            "nameCH" : "Éntrée-Sortie",
            "code" : "CCE"
        },
        {
            "codeUIC" : 615286,
            "codeCH" : "BF",
            "nameUIC" : "Carcassonne",
            "nameCH" : "Bifurcation de Quillan",
            "code" : "CCE"
        },
        {
            "codeUIC" : 615286,
            "codeCH" : "CA",
            "nameUIC" : "Carcassonne",
            "nameCH" : "Carré 209",
            "code" : "CCE"
        },
        {
            "codeUIC" : 615286,
            "codeCH" : "GA",
            "nameUIC" : "Carcassonne",
            "nameCH" : "Voie 20G",
            "code" : "CCE"
        },
        {
            "codeUIC" : 615286,
            "codeCH" : 0,
            "nameUIC" : "Carcassonne",
            "nameCH" : "",
            "code" : "CCE"
        },
        {
            "codeUIC" : 615310,
            "codeCH" : 0,
            "nameUIC" : "Lisle-sur-Tarn",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615328,
            "codeCH" : 0,
            "nameUIC" : "Rabastens-Couffouleux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615344,
            "codeCH" : "C7",
            "nameUIC" : "St-Sulpice (Tarn)",
            "nameCH" : "Carré 7504",
            "code" : ""
        },
        {
            "codeUIC" : 615344,
            "codeCH" : "J1",
            "nameUIC" : "St-Sulpice (Tarn)",
            "nameCH" : "Aiguille 7527/7528",
            "code" : ""
        },
        {
            "codeUIC" : 615344,
            "codeCH" : 0,
            "nameUIC" : "St-Sulpice (Tarn)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615351,
            "codeCH" : 0,
            "nameUIC" : "Roquesérière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615369,
            "codeCH" : "J1",
            "nameUIC" : "Montastruc-la-Conseillère",
            "nameCH" : "Aiguille 7318",
            "code" : ""
        },
        {
            "codeUIC" : 615369,
            "codeCH" : 0,
            "nameUIC" : "Montastruc-la-Conseillère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615377,
            "codeCH" : 0,
            "nameUIC" : "Gragnague",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615419,
            "codeCH" : 0,
            "nameUIC" : "Marssac-sur-Tarn",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615468,
            "codeCH" : "BV",
            "nameUIC" : "Castres (Tarn)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CT"
        },
        {
            "codeUIC" : 615468,
            "codeCH" : "EC",
            "nameUIC" : "Castres (Tarn)",
            "nameCH" : "ITE Km 362,6",
            "code" : "CT"
        },
        {
            "codeUIC" : 615476,
            "codeCH" : "BV",
            "nameUIC" : "Lavaur",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LVR"
        },
        {
            "codeUIC" : 615476,
            "codeCH" : "C2",
            "nameUIC" : "Lavaur",
            "nameCH" : "Carré 2",
            "code" : "LVR"
        },
        {
            "codeUIC" : 615476,
            "codeCH" : "ED",
            "nameUIC" : "Lavaur",
            "nameCH" : "ITE Km 271,1",
            "code" : "LVR"
        },
        {
            "codeUIC" : 615484,
            "codeCH" : 0,
            "nameUIC" : "Damiatte-St-Paul",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615492,
            "codeCH" : 0,
            "nameUIC" : "Vielmur-sur-Agout",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615500,
            "codeCH" : "ER",
            "nameUIC" : "St-Juéry",
            "nameCH" : "ITE Km 420,5",
            "code" : "SJY"
        },
        {
            "codeUIC" : 615500,
            "codeCH" : "EV",
            "nameUIC" : "St-Juéry",
            "nameCH" : "ITE Km 421,4",
            "code" : "SJY"
        },
        {
            "codeUIC" : 615500,
            "codeCH" : "EZ",
            "nameUIC" : "St-Juéry",
            "nameCH" : "ITE Km 419,1",
            "code" : "SJY"
        },
        {
            "codeUIC" : 615500,
            "codeCH" : "LO",
            "nameUIC" : "St-Juéry",
            "nameCH" : "Local",
            "code" : "SJY"
        },
        {
            "codeUIC" : 615518,
            "codeCH" : 0,
            "nameUIC" : "Sémalens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615526,
            "codeCH" : "BV",
            "nameUIC" : "Labruguière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 615542,
            "codeCH" : "BV",
            "nameUIC" : "Mazamet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MAZ"
        },
        {
            "codeUIC" : 615542,
            "codeCH" : "CT",
            "nameUIC" : "Mazamet",
            "nameCH" : "ITE Transcontainers",
            "code" : "MAZ"
        },
        {
            "codeUIC" : 615542,
            "codeCH" : "EG",
            "nameUIC" : "Mazamet",
            "nameCH" : "ITE Gondrand",
            "code" : "MAZ"
        },
        {
            "codeUIC" : 615542,
            "codeCH" : "EZ",
            "nameUIC" : "Mazamet",
            "nameCH" : "ITE Zone Industrielle Ausillon",
            "code" : "MAZ"
        },
        {
            "codeUIC" : 615542,
            "codeCH" : 23,
            "nameUIC" : "Mazamet",
            "nameCH" : "PN 23",
            "code" : "MAZ"
        },
        {
            "codeUIC" : 615617,
            "codeCH" : 0,
            "nameUIC" : "Le Garric",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615625,
            "codeCH" : 0,
            "nameUIC" : "Carmaux",
            "nameCH" : "",
            "code" : "CMX"
        },
        {
            "codeUIC" : 615674,
            "codeCH" : 0,
            "nameUIC" : "Tanus",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615732,
            "codeCH" : 0,
            "nameUIC" : "Naucelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615757,
            "codeCH" : 0,
            "nameUIC" : "Baraqueville-Carcenac-Peyralès",
            "nameCH" : "",
            "code" : "BAQ"
        },
        {
            "codeUIC" : 615773,
            "codeCH" : "BV",
            "nameUIC" : "Luc-Primaube",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LPA"
        },
        {
            "codeUIC" : 615773,
            "codeCH" : "EC",
            "nameUIC" : "Luc-Primaube",
            "nameCH" : "ITE Km 480,9",
            "code" : "LPA"
        },
        {
            "codeUIC" : 615773,
            "codeCH" : "ES",
            "nameUIC" : "Luc-Primaube",
            "nameCH" : "ITE Km 480,6",
            "code" : "LPA"
        },
        {
            "codeUIC" : 615799,
            "codeCH" : "BV",
            "nameUIC" : "Revel-Sorèze",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RVS"
        },
        {
            "codeUIC" : 615799,
            "codeCH" : "ES",
            "nameUIC" : "Revel-Sorèze",
            "nameCH" : "ITE Km 333,7",
            "code" : "RVS"
        },
        {
            "codeUIC" : 615815,
            "codeCH" : 0,
            "nameUIC" : "Pezens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 615849,
            "codeCH" : 0,
            "nameUIC" : "Madame",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 616011,
            "codeCH" : 0,
            "nameUIC" : "Avignonet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 618058,
            "codeCH" : "PI",
            "nameUIC" : "Pommevic",
            "nameCH" : "Carré 471",
            "code" : ""
        },
        {
            "codeUIC" : 618058,
            "codeCH" : 0,
            "nameUIC" : "Pommevic",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 618058,
            "codeCH" : 45,
            "nameUIC" : "Pommevic",
            "nameCH" : "Carré 452",
            "code" : ""
        },
        {
            "codeUIC" : 618082,
            "codeCH" : 0,
            "nameUIC" : "Golfech",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 618116,
            "codeCH" : 0,
            "nameUIC" : "Pins-Justaret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 618207,
            "codeCH" : "P3",
            "nameUIC" : "Montlaur",
            "nameCH" : "Entrée/Sortie IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 618207,
            "codeCH" : 0,
            "nameUIC" : "Montlaur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 618215,
            "codeCH" : 0,
            "nameUIC" : "Villenouvelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 618348,
            "codeCH" : "BV",
            "nameUIC" : "Mercus-Garrabet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 618587,
            "codeCH" : 55,
            "nameUIC" : "St-Julien-sur-Garonne",
            "nameCH" : "Carré 355",
            "code" : ""
        },
        {
            "codeUIC" : 618587,
            "codeCH" : 56,
            "nameUIC" : "St-Julien-sur-Garonne",
            "nameCH" : "Carré 356",
            "code" : ""
        },
        {
            "codeUIC" : 618710,
            "codeCH" : 0,
            "nameUIC" : "Saléchan-Siradan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 621037,
            "codeCH" : 0,
            "nameUIC" : "PN 58",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 621045,
            "codeCH" : 0,
            "nameUIC" : "PN 10",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 623835,
            "codeCH" : "V3",
            "nameUIC" : "Vestric",
            "nameCH" : "Voie 3 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 623835,
            "codeCH" : 0,
            "nameUIC" : "Vestric",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 635581,
            "codeCH" : 0,
            "nameUIC" : "La Barasse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 636373,
            "codeCH" : "ES",
            "nameUIC" : "St-Avertin",
            "nameCH" : "Entrée/Sortie SEA",
            "code" : ""
        },
        {
            "codeUIC" : 636381,
            "codeCH" : "RM",
            "nameUIC" : "Poste 51 Sorigny",
            "nameCH" : "Raccordement Monts-Sud",
            "code" : ""
        },
        {
            "codeUIC" : 636399,
            "codeCH" : "CV",
            "nameUIC" : "Poste 52 Ste-Catherine-de-Fier",
            "nameCH" : "Changement de voies",
            "code" : ""
        },
        {
            "codeUIC" : 636407,
            "codeCH" : "AI",
            "nameUIC" : "La Celle-St-Avant",
            "nameCH" : "Aiguille 4205a",
            "code" : ""
        },
        {
            "codeUIC" : 636407,
            "codeCH" : "RC",
            "nameUIC" : "La Celle-St-Avant",
            "nameCH" : "Raccordement La Celle",
            "code" : ""
        },
        {
            "codeUIC" : 636415,
            "codeCH" : 54,
            "nameUIC" : "Poste 54 Thure",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 636423,
            "codeCH" : "CV",
            "nameUIC" : "Poste 55 Marigny-Brisay",
            "nameCH" : "Changement de voies",
            "code" : ""
        },
        {
            "codeUIC" : 636431,
            "codeCH" : "RM",
            "nameUIC" : "Poste 70 Chasseneuil",
            "nameCH" : "Raccordement Migné-Auxances",
            "code" : ""
        },
        {
            "codeUIC" : 636449,
            "codeCH" : "RM",
            "nameUIC" : "Migné-Auxances",
            "nameCH" : "Raccordement Migné-Auxances",
            "code" : ""
        },
        {
            "codeUIC" : 636456,
            "codeCH" : "BT",
            "nameUIC" : "La Touche",
            "nameCH" : "Bifurcation La Touche",
            "code" : ""
        },
        {
            "codeUIC" : 636456,
            "codeCH" : "CV",
            "nameUIC" : "La Touche",
            "nameCH" : "Changement voie",
            "code" : ""
        },
        {
            "codeUIC" : 636464,
            "codeCH" : "CV",
            "nameUIC" : "Poste 59 Marçay",
            "nameCH" : "Changement de voies",
            "code" : ""
        },
        {
            "codeUIC" : 636472,
            "codeCH" : "SI",
            "nameUIC" : "Poste 60 Pliboux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 636480,
            "codeCH" : "RJ",
            "nameUIC" : "Poste 61 Juillé",
            "nameCH" : "Raccordement Juillé",
            "code" : ""
        },
        {
            "codeUIC" : 636498,
            "codeCH" : "RV",
            "nameUIC" : "Vervant",
            "nameCH" : "Raccordement Villognon",
            "code" : ""
        },
        {
            "codeUIC" : 636506,
            "codeCH" : "CA",
            "nameUIC" : "Poste 63 Asnières-sur-Nouère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 636514,
            "codeCH" : "CV",
            "nameUIC" : "Poste 65 Cressac-St-Genis",
            "nameCH" : "Changement de voies",
            "code" : ""
        },
        {
            "codeUIC" : 636522,
            "codeCH" : "PR",
            "nameUIC" : "Poste 66 Neuvicq",
            "nameCH" : "Pont Route",
            "code" : ""
        },
        {
            "codeUIC" : 636522,
            "codeCH" : "TI",
            "nameUIC" : "Poste 66 Neuvicq",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 636530,
            "codeCH" : "AB",
            "nameUIC" : "Poste 67 Clérac",
            "nameCH" : "Accès Base Maintenance",
            "code" : ""
        },
        {
            "codeUIC" : 636530,
            "codeCH" : "BM",
            "nameUIC" : "Poste 67 Clérac",
            "nameCH" : "Base Maintenance",
            "code" : ""
        },
        {
            "codeUIC" : 636530,
            "codeCH" : "GF",
            "nameUIC" : "Poste 67 Clérac",
            "nameCH" : "Traversée VU",
            "code" : ""
        },
        {
            "codeUIC" : 636548,
            "codeCH" : "CV",
            "nameUIC" : "Poste 68 Laruscade",
            "nameCH" : "Changement de voies",
            "code" : ""
        },
        {
            "codeUIC" : 636555,
            "codeCH" : "AN",
            "nameUIC" : "Poste 58 Fontaine-Le Comte",
            "nameCH" : "Raccordement Nord",
            "code" : ""
        },
        {
            "codeUIC" : 636555,
            "codeCH" : "RS",
            "nameUIC" : "Poste 58 Fontaine-Le Comte",
            "nameCH" : "Raccordement Sud",
            "code" : ""
        },
        {
            "codeUIC" : 636571,
            "codeCH" : "BM",
            "nameUIC" : "Poste 57 Poitiers-LGV",
            "nameCH" : "Base Maintenance",
            "code" : ""
        },
        {
            "codeUIC" : 636571,
            "codeCH" : "TI",
            "nameUIC" : "Poste 57 Poitiers-LGV",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 636589,
            "codeCH" : "AS",
            "nameUIC" : "Monts-Sud",
            "nameCH" : "Raccordement St-Avertin",
            "code" : ""
        },
        {
            "codeUIC" : 636589,
            "codeCH" : "RM",
            "nameUIC" : "Monts-Sud",
            "nameCH" : "Raccordement Monts-Sud",
            "code" : ""
        },
        {
            "codeUIC" : 637058,
            "codeCH" : 29,
            "nameUIC" : "Poste 29 Lesse",
            "nameCH" : "Poste",
            "code" : ""
        },
        {
            "codeUIC" : 637066,
            "codeCH" : 30,
            "nameUIC" : "Poste 30 Domnon-les-Dieuze",
            "nameCH" : "Poste",
            "code" : ""
        },
        {
            "codeUIC" : 637074,
            "codeCH" : "RR",
            "nameUIC" : "Poste 31 Vieux-Lixheim",
            "nameCH" : "Raccordement Réding",
            "code" : ""
        },
        {
            "codeUIC" : 637074,
            "codeCH" : 17,
            "nameUIC" : "Poste 31 Vieux-Lixheim",
            "nameCH" : "Poste 17",
            "code" : ""
        },
        {
            "codeUIC" : 637074,
            "codeCH" : 31,
            "nameUIC" : "Poste 31 Vieux-Lixheim",
            "nameCH" : "Poste",
            "code" : ""
        },
        {
            "codeUIC" : 637082,
            "codeCH" : 32,
            "nameUIC" : "Poste 32 Steinbourg",
            "nameCH" : "Poste",
            "code" : ""
        },
        {
            "codeUIC" : 638023,
            "codeCH" : "BM",
            "nameUIC" : "Poste 62 Villognon",
            "nameCH" : "Base Maintenance",
            "code" : ""
        },
        {
            "codeUIC" : 638023,
            "codeCH" : "RV",
            "nameUIC" : "Poste 62 Villognon",
            "nameCH" : "Raccordement Villognon",
            "code" : ""
        },
        {
            "codeUIC" : 638031,
            "codeCH" : "AL",
            "nameUIC" : "Poste 64 Claix",
            "nameCH" : "Raccordement La Couronne",
            "code" : ""
        },
        {
            "codeUIC" : 641001,
            "codeCH" : "A8",
            "nameUIC" : "Montluçon-Ville",
            "nameCH" : "Aiguille 8 Km 324,1",
            "code" : "ML"
        },
        {
            "codeUIC" : 641001,
            "codeCH" : "BE",
            "nameUIC" : "Montluçon-Ville",
            "nameCH" : "Bifurcation de Eygurande",
            "code" : "ML"
        },
        {
            "codeUIC" : 641001,
            "codeCH" : "BV",
            "nameUIC" : "Montluçon-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ML"
        },
        {
            "codeUIC" : 641001,
            "codeCH" : "EC",
            "nameUIC" : "Montluçon-Ville",
            "nameCH" : "ITE Mory-TNTE",
            "code" : "ML"
        },
        {
            "codeUIC" : 641001,
            "codeCH" : "EG",
            "nameUIC" : "Montluçon-Ville",
            "nameCH" : "Eau Garage",
            "code" : "ML"
        },
        {
            "codeUIC" : 641001,
            "codeCH" : "P3",
            "nameUIC" : "Montluçon-Ville",
            "nameCH" : "Bifurcation de St-Sulpice",
            "code" : "ML"
        },
        {
            "codeUIC" : 641035,
            "codeCH" : 0,
            "nameUIC" : "Hyds",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641043,
            "codeCH" : "CV",
            "nameUIC" : "Lapeyrouse",
            "nameCH" : "Carré Violet 22",
            "code" : ""
        },
        {
            "codeUIC" : 641043,
            "codeCH" : 0,
            "nameUIC" : "Lapeyrouse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641050,
            "codeCH" : 0,
            "nameUIC" : "Louroux-de-Bouble",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641068,
            "codeCH" : 0,
            "nameUIC" : "Bellenaves",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641076,
            "codeCH" : 0,
            "nameUIC" : "St-Bonnet-de-Rochefort",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641084,
            "codeCH" : 0,
            "nameUIC" : "Lannet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641092,
            "codeCH" : "BV",
            "nameUIC" : "St-Éloy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SEL"
        },
        {
            "codeUIC" : 641092,
            "codeCH" : "EP",
            "nameUIC" : "St-Éloy",
            "nameCH" : "ITE Sautereau",
            "code" : "SEL"
        },
        {
            "codeUIC" : 641100,
            "codeCH" : 0,
            "nameUIC" : "La Bouble",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641118,
            "codeCH" : 0,
            "nameUIC" : "Youx",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641126,
            "codeCH" : 0,
            "nameUIC" : "Gouttières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641134,
            "codeCH" : 0,
            "nameUIC" : "St-Gervais-Châteauneuf",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641142,
            "codeCH" : 0,
            "nameUIC" : "St-Priest-Sauret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641159,
            "codeCH" : 0,
            "nameUIC" : "Les Fades",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641167,
            "codeCH" : "BV",
            "nameUIC" : "Les Ancizes-St-Georges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LSG"
        },
        {
            "codeUIC" : 641167,
            "codeCH" : "EP",
            "nameUIC" : "Les Ancizes-St-Georges",
            "nameCH" : "ITE Sema",
            "code" : "LSG"
        },
        {
            "codeUIC" : 641175,
            "codeCH" : 0,
            "nameUIC" : "Les Richards",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641183,
            "codeCH" : 0,
            "nameUIC" : "Manzat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641191,
            "codeCH" : 0,
            "nameUIC" : "La Ville-Gozet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641209,
            "codeCH" : 0,
            "nameUIC" : "Domérat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641217,
            "codeCH" : 0,
            "nameUIC" : "Huriel",
            "nameCH" : "",
            "code" : "HIL"
        },
        {
            "codeUIC" : 641225,
            "codeCH" : 0,
            "nameUIC" : "Archignat",
            "nameCH" : "",
            "code" : "ARC"
        },
        {
            "codeUIC" : 641233,
            "codeCH" : 0,
            "nameUIC" : "Treignat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641241,
            "codeCH" : 0,
            "nameUIC" : "Eygurande-Merlines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641258,
            "codeCH" : 0,
            "nameUIC" : "La Cellette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641266,
            "codeCH" : 0,
            "nameUIC" : "Bourg-Lastic-Messeix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641274,
            "codeCH" : "BV",
            "nameUIC" : "Laqueuille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LAQ"
        },
        {
            "codeUIC" : 641282,
            "codeCH" : 0,
            "nameUIC" : "Bourgeade",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641290,
            "codeCH" : 0,
            "nameUIC" : "La Miouze-Rochefort",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641308,
            "codeCH" : 0,
            "nameUIC" : "Les Rosiers-sur-Sioule",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641316,
            "codeCH" : 0,
            "nameUIC" : "Pontgibaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641324,
            "codeCH" : 0,
            "nameUIC" : "St-Ours-les-Roches",
            "nameCH" : "",
            "code" : "SOR"
        },
        {
            "codeUIC" : 641357,
            "codeCH" : 0,
            "nameUIC" : "Vallon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641365,
            "codeCH" : 0,
            "nameUIC" : "Magnette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641373,
            "codeCH" : 0,
            "nameUIC" : "Les Trillers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641399,
            "codeCH" : 0,
            "nameUIC" : "Le Vauriat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641407,
            "codeCH" : 0,
            "nameUIC" : "Chamblet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641415,
            "codeCH" : 0,
            "nameUIC" : "Commentry",
            "nameCH" : "",
            "code" : "CMY"
        },
        {
            "codeUIC" : 641423,
            "codeCH" : 0,
            "nameUIC" : "Volvic",
            "nameCH" : "",
            "code" : "VOL"
        },
        {
            "codeUIC" : 641431,
            "codeCH" : 0,
            "nameUIC" : "Chanat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641449,
            "codeCH" : "SE",
            "nameUIC" : "Durtol-Nohanent",
            "nameCH" : "S.PR 606",
            "code" : ""
        },
        {
            "codeUIC" : 641449,
            "codeCH" : 0,
            "nameUIC" : "Durtol-Nohanent",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641456,
            "codeCH" : 0,
            "nameUIC" : "St-Sauves",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641464,
            "codeCH" : 0,
            "nameUIC" : "La Bourboule",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641472,
            "codeCH" : 0,
            "nameUIC" : "Mont-Dore",
            "nameCH" : "",
            "code" : "MND"
        },
        {
            "codeUIC" : 641498,
            "codeCH" : 0,
            "nameUIC" : "Charbonnières-les-Varennes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641506,
            "codeCH" : 0,
            "nameUIC" : "Doyet-la-Presle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641514,
            "codeCH" : 0,
            "nameUIC" : "Villefranche-d'Allier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641530,
            "codeCH" : 0,
            "nameUIC" : "Chavenon",
            "nameCH" : "",
            "code" : "CVO"
        },
        {
            "codeUIC" : 641548,
            "codeCH" : 0,
            "nameUIC" : "St-Sornin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641555,
            "codeCH" : 0,
            "nameUIC" : "Tronget",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641571,
            "codeCH" : 0,
            "nameUIC" : "Noyant-d'Allier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641589,
            "codeCH" : 0,
            "nameUIC" : "Souvigny",
            "nameCH" : "",
            "code" : "SUY"
        },
        {
            "codeUIC" : 641795,
            "codeCH" : 0,
            "nameUIC" : "Lignerolles (Allier)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641803,
            "codeCH" : 0,
            "nameUIC" : "Teillet-Argenty",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641811,
            "codeCH" : 0,
            "nameUIC" : "Budelière-Chambon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641829,
            "codeCH" : 0,
            "nameUIC" : "Évaux-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641837,
            "codeCH" : 0,
            "nameUIC" : "Reterre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641845,
            "codeCH" : 0,
            "nameUIC" : "Auzances",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641852,
            "codeCH" : 0,
            "nameUIC" : "Les Mars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641860,
            "codeCH" : 0,
            "nameUIC" : "Mérinchal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641878,
            "codeCH" : 0,
            "nameUIC" : "Létrade",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641886,
            "codeCH" : 0,
            "nameUIC" : "Giat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 641894,
            "codeCH" : 0,
            "nameUIC" : "St-Merd-la-Breuille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 643031,
            "codeCH" : "BS",
            "nameUIC" : "Poste 73 Poillé-sur-Vègre",
            "nameCH" : "Raccordement Sablé",
            "code" : ""
        },
        {
            "codeUIC" : 643031,
            "codeCH" : "CV",
            "nameUIC" : "Poste 73 Poillé-sur-Vègre",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 643049,
            "codeCH" : "BM",
            "nameUIC" : "Poste 74 Sirion",
            "nameCH" : "Base Maintenance Juigné",
            "code" : ""
        },
        {
            "codeUIC" : 643049,
            "codeCH" : "PT",
            "nameUIC" : "Poste 74 Sirion",
            "nameCH" : "Poste Technique",
            "code" : ""
        },
        {
            "codeUIC" : 643056,
            "codeCH" : "BA",
            "nameUIC" : "Poste 75 Auvers-le-Hamon",
            "nameCH" : "Raccordement Auvers",
            "code" : ""
        },
        {
            "codeUIC" : 643064,
            "codeCH" : "AR",
            "nameUIC" : "Poste 72 Aigné",
            "nameCH" : "Raccordement Fret",
            "code" : ""
        },
        {
            "codeUIC" : 643064,
            "codeCH" : "BR",
            "nameUIC" : "Poste 72 Aigné",
            "nameCH" : "Raccordement Voyageur",
            "code" : ""
        },
        {
            "codeUIC" : 643064,
            "codeCH" : "CV",
            "nameUIC" : "Poste 72 Aigné",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 643072,
            "codeCH" : "BE",
            "nameUIC" : "Poste 76 Argentré",
            "nameCH" : "Raccordement Est",
            "code" : ""
        },
        {
            "codeUIC" : 643072,
            "codeCH" : "CV",
            "nameUIC" : "Poste 76 Argentré",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 643080,
            "codeCH" : "BO",
            "nameUIC" : "Poste 77 Loiron",
            "nameCH" : "Raccordement Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 643098,
            "codeCH" : "CV",
            "nameUIC" : "Poste 78 St-Cyr-le-Gravelais",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 643114,
            "codeCH" : "CV",
            "nameUIC" : "Poste 79 Louvigné-de-Bais",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 645002,
            "codeCH" : "BV",
            "nameUIC" : "Aurillac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AC"
        },
        {
            "codeUIC" : 645002,
            "codeCH" : "PV",
            "nameUIC" : "Aurillac",
            "nameCH" : "Point C",
            "code" : "AC"
        },
        {
            "codeUIC" : 645002,
            "codeCH" : "SI",
            "nameUIC" : "Aurillac",
            "nameCH" : "ITE Simvra",
            "code" : "AC"
        },
        {
            "codeUIC" : 645101,
            "codeCH" : 0,
            "nameUIC" : "Ytrac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645119,
            "codeCH" : 0,
            "nameUIC" : "Viescamp-sous-Jalles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645127,
            "codeCH" : 0,
            "nameUIC" : "Miécaze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645135,
            "codeCH" : 0,
            "nameUIC" : "Laroquebrou",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645143,
            "codeCH" : 0,
            "nameUIC" : "Lamativie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645150,
            "codeCH" : "VR",
            "nameUIC" : "La Capelle-Viescamp",
            "nameCH" : "Viaduc de Ribeyres",
            "code" : ""
        },
        {
            "codeUIC" : 645150,
            "codeCH" : 0,
            "nameUIC" : "La Capelle-Viescamp",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645168,
            "codeCH" : 0,
            "nameUIC" : "Maurs",
            "nameCH" : "",
            "code" : "MRS"
        },
        {
            "codeUIC" : 645176,
            "codeCH" : 0,
            "nameUIC" : "Pers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645184,
            "codeCH" : 0,
            "nameUIC" : "Boisset (Cantal)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645192,
            "codeCH" : 0,
            "nameUIC" : "Le Rouget",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645200,
            "codeCH" : 0,
            "nameUIC" : "Nieudan-St-Victor",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645218,
            "codeCH" : 0,
            "nameUIC" : "St-Illide",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645226,
            "codeCH" : 0,
            "nameUIC" : "Loupiac-St-Christophe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645234,
            "codeCH" : 0,
            "nameUIC" : "Drignac-Ally",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645242,
            "codeCH" : 0,
            "nameUIC" : "Drugeac-Salers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645259,
            "codeCH" : 0,
            "nameUIC" : "Salins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645267,
            "codeCH" : 0,
            "nameUIC" : "Mauriac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645275,
            "codeCH" : 0,
            "nameUIC" : "Jaleyrac-Sourniac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645283,
            "codeCH" : 0,
            "nameUIC" : "Vendes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645291,
            "codeCH" : 0,
            "nameUIC" : "Largnac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645317,
            "codeCH" : 0,
            "nameUIC" : "Champagnac-les-Mines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645325,
            "codeCH" : "BV",
            "nameUIC" : "Saignes-Ydes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 645408,
            "codeCH" : "BV",
            "nameUIC" : "Arpajon-sur-Cère",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 645424,
            "codeCH" : 0,
            "nameUIC" : "Yolet-le-Doux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645432,
            "codeCH" : "CH",
            "nameUIC" : "Polminhac",
            "nameCH" : "Château de Peisteils",
            "code" : ""
        },
        {
            "codeUIC" : 645432,
            "codeCH" : 0,
            "nameUIC" : "Polminhac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645440,
            "codeCH" : 0,
            "nameUIC" : "Vic-sur-Cère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645457,
            "codeCH" : 0,
            "nameUIC" : "Thiézac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645465,
            "codeCH" : 0,
            "nameUIC" : "St-Jacques",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645473,
            "codeCH" : 0,
            "nameUIC" : "Le Lioran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645481,
            "codeCH" : 0,
            "nameUIC" : "Murat",
            "nameCH" : "",
            "code" : "MUR"
        },
        {
            "codeUIC" : 645507,
            "codeCH" : "BV",
            "nameUIC" : "Neussargues",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NEU"
        },
        {
            "codeUIC" : 645507,
            "codeCH" : "PA",
            "nameUIC" : "Neussargues",
            "nameCH" : "Poste A de la Bif",
            "code" : "NEU"
        },
        {
            "codeUIC" : 645606,
            "codeCH" : 0,
            "nameUIC" : "Ste-Anastasie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645614,
            "codeCH" : 0,
            "nameUIC" : "Allanche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645622,
            "codeCH" : 0,
            "nameUIC" : "Landeyrat-Marcenat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645630,
            "codeCH" : 0,
            "nameUIC" : "St-Saturnin-St-Bonnet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645648,
            "codeCH" : 0,
            "nameUIC" : "Lugarde-Marchastel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645655,
            "codeCH" : 0,
            "nameUIC" : "Condat-St-Amandin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645663,
            "codeCH" : 0,
            "nameUIC" : "Riom-ès-Montagnes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645689,
            "codeCH" : 0,
            "nameUIC" : "St-Étienne-Menet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645697,
            "codeCH" : 0,
            "nameUIC" : "Antignac-Vebret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 645705,
            "codeCH" : 0,
            "nameUIC" : "Bort-les-Orgues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 654798,
            "codeCH" : "BV",
            "nameUIC" : "Rosa Parks",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671008,
            "codeCH" : "BV",
            "nameUIC" : "Tarbes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TS"
        },
        {
            "codeUIC" : 671008,
            "codeCH" : "EZ",
            "nameUIC" : "Tarbes",
            "nameCH" : "ITE Km 241,9",
            "code" : "TS"
        },
        {
            "codeUIC" : 671008,
            "codeCH" : 5,
            "nameUIC" : "Tarbes",
            "nameCH" : "Sémaphore 605",
            "code" : "TS"
        },
        {
            "codeUIC" : 671008,
            "codeCH" : 10,
            "nameUIC" : "Tarbes",
            "nameCH" : "Carré 210",
            "code" : "TS"
        },
        {
            "codeUIC" : 671008,
            "codeCH" : 82,
            "nameUIC" : "Tarbes",
            "nameCH" : "Sémaphore 582",
            "code" : "TS"
        },
        {
            "codeUIC" : 671016,
            "codeCH" : "EF",
            "nameUIC" : "Mont-de-Marsan",
            "nameCH" : "ITE Farbos",
            "code" : "MM"
        },
        {
            "codeUIC" : 671016,
            "codeCH" : "ES",
            "nameUIC" : "Mont-de-Marsan",
            "nameCH" : "ITE Scalandes",
            "code" : "MM"
        },
        {
            "codeUIC" : 671016,
            "codeCH" : "ET",
            "nameUIC" : "Mont-de-Marsan",
            "nameCH" : "ITE Tamboury",
            "code" : "MM"
        },
        {
            "codeUIC" : 671016,
            "codeCH" : "Z1",
            "nameUIC" : "Mont-de-Marsan",
            "nameCH" : "ITE Zone Industrielle N°1",
            "code" : "MM"
        },
        {
            "codeUIC" : 671016,
            "codeCH" : "Z2",
            "nameUIC" : "Mont-de-Marsan",
            "nameCH" : "ITE Zone Industrielle N°2",
            "code" : "MM"
        },
        {
            "codeUIC" : 671016,
            "codeCH" : 0,
            "nameUIC" : "Mont-de-Marsan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MM"
        },
        {
            "codeUIC" : 671024,
            "codeCH" : "BV",
            "nameUIC" : "Grenade-sur-l'Adour",
            "nameCH" : "Local",
            "code" : "GLD"
        },
        {
            "codeUIC" : 671024,
            "codeCH" : "EA",
            "nameUIC" : "Grenade-sur-l'Adour",
            "nameCH" : "ITE Coopérative Agricole",
            "code" : "GLD"
        },
        {
            "codeUIC" : 671024,
            "codeCH" : "EC",
            "nameUIC" : "Grenade-sur-l'Adour",
            "nameCH" : "ITE Coopérative agricole",
            "code" : "GLD"
        },
        {
            "codeUIC" : 671024,
            "codeCH" : "SI",
            "nameUIC" : "Grenade-sur-l'Adour",
            "nameCH" : "ITE Siladour",
            "code" : "GLD"
        },
        {
            "codeUIC" : 671040,
            "codeCH" : "BV",
            "nameUIC" : "Aire-sur-l'Adour",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AA"
        },
        {
            "codeUIC" : 671040,
            "codeCH" : "EA",
            "nameUIC" : "Aire-sur-l'Adour",
            "nameCH" : "ITE Coopérative Agricole",
            "code" : "AA"
        },
        {
            "codeUIC" : 671040,
            "codeCH" : "EB",
            "nameUIC" : "Aire-sur-l'Adour",
            "nameCH" : "ITE Sabm",
            "code" : "AA"
        },
        {
            "codeUIC" : 671040,
            "codeCH" : "EC",
            "nameUIC" : "Aire-sur-l'Adour",
            "nameCH" : "ITE SMSO",
            "code" : "AA"
        },
        {
            "codeUIC" : 671040,
            "codeCH" : "EI",
            "nameUIC" : "Aire-sur-l'Adour",
            "nameCH" : "ITE Siladour",
            "code" : "AA"
        },
        {
            "codeUIC" : 671040,
            "codeCH" : "ES",
            "nameUIC" : "Aire-sur-l'Adour",
            "nameCH" : "ITE Sica Pau",
            "code" : "AA"
        },
        {
            "codeUIC" : 671073,
            "codeCH" : "BV",
            "nameUIC" : "Riscle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671073,
            "codeCH" : "ES",
            "nameUIC" : "Riscle",
            "nameCH" : "ITE SMSO",
            "code" : ""
        },
        {
            "codeUIC" : 671081,
            "codeCH" : 0,
            "nameUIC" : "Castelnau-Rivière-Basse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671123,
            "codeCH" : 0,
            "nameUIC" : "Lannemezan",
            "nameCH" : "",
            "code" : "LNZ"
        },
        {
            "codeUIC" : 671149,
            "codeCH" : 0,
            "nameUIC" : "Nogaro",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671156,
            "codeCH" : 0,
            "nameUIC" : "Capvern",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671172,
            "codeCH" : 0,
            "nameUIC" : "Tournay (Hautes-Pyrénées)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671172,
            "codeCH" : 42,
            "nameUIC" : "Tournay (Hautes-Pyrénées)",
            "nameCH" : "Carré 542",
            "code" : ""
        },
        {
            "codeUIC" : 671172,
            "codeCH" : 62,
            "nameUIC" : "Tournay (Hautes-Pyrénées)",
            "nameCH" : "Sémaphore 562",
            "code" : ""
        },
        {
            "codeUIC" : 671206,
            "codeCH" : 0,
            "nameUIC" : "Manciet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671214,
            "codeCH" : 0,
            "nameUIC" : "Eauze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671230,
            "codeCH" : "BF",
            "nameUIC" : "Ampèrevielle",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 671230,
            "codeCH" : "EA",
            "nameUIC" : "Ampèrevielle",
            "nameCH" : "ITE Alsthom 2",
            "code" : ""
        },
        {
            "codeUIC" : 671230,
            "codeCH" : "LO",
            "nameUIC" : "Ampèrevielle",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 671230,
            "codeCH" : "N6",
            "nameUIC" : "Ampèrevielle",
            "nameCH" : "PN 106",
            "code" : ""
        },
        {
            "codeUIC" : 671255,
            "codeCH" : "EL",
            "nameUIC" : "St-Avit",
            "nameCH" : "ITE Locatelli",
            "code" : ""
        },
        {
            "codeUIC" : 671255,
            "codeCH" : "LO",
            "nameUIC" : "St-Avit",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 671255,
            "codeCH" : 70,
            "nameUIC" : "St-Avit",
            "nameCH" : "PN 70",
            "code" : ""
        },
        {
            "codeUIC" : 671255,
            "codeCH" : 72,
            "nameUIC" : "St-Avit",
            "nameCH" : "PN 72",
            "code" : ""
        },
        {
            "codeUIC" : 671263,
            "codeCH" : "ED",
            "nameUIC" : "Roquefort (Landes)",
            "nameCH" : "ITE Ducros",
            "code" : "ROQ"
        },
        {
            "codeUIC" : 671263,
            "codeCH" : "LO",
            "nameUIC" : "Roquefort (Landes)",
            "nameCH" : "Local",
            "code" : "ROQ"
        },
        {
            "codeUIC" : 671263,
            "codeCH" : 62,
            "nameUIC" : "Roquefort (Landes)",
            "nameCH" : "PN 62",
            "code" : "ROQ"
        },
        {
            "codeUIC" : 671263,
            "codeCH" : 64,
            "nameUIC" : "Roquefort (Landes)",
            "nameCH" : "PN 64",
            "code" : "ROQ"
        },
        {
            "codeUIC" : 671305,
            "codeCH" : "EB",
            "nameUIC" : "Juillan",
            "nameCH" : "ITE S G Boissons",
            "code" : ""
        },
        {
            "codeUIC" : 671305,
            "codeCH" : "EC",
            "nameUIC" : "Juillan",
            "nameCH" : "ITE Coopérative Agricole HP",
            "code" : ""
        },
        {
            "codeUIC" : 671305,
            "codeCH" : "LO",
            "nameUIC" : "Juillan",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 671313,
            "codeCH" : 0,
            "nameUIC" : "Ossun",
            "nameCH" : "",
            "code" : "OS"
        },
        {
            "codeUIC" : 671339,
            "codeCH" : "BV",
            "nameUIC" : "Lourdes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LD"
        },
        {
            "codeUIC" : 671339,
            "codeCH" : "IP",
            "nameUIC" : "Lourdes",
            "nameCH" : "Carré 652",
            "code" : "LD"
        },
        {
            "codeUIC" : 671339,
            "codeCH" : "PI",
            "nameUIC" : "Lourdes",
            "nameCH" : "Carré 42",
            "code" : "LD"
        },
        {
            "codeUIC" : 671362,
            "codeCH" : 0,
            "nameUIC" : "St-Pé-de-Bigorre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671404,
            "codeCH" : "BV",
            "nameUIC" : "Labarthe-Avezac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LBA"
        },
        {
            "codeUIC" : 671404,
            "codeCH" : "EP",
            "nameUIC" : "Labarthe-Avezac",
            "nameCH" : "EP UGINE K",
            "code" : "LBA"
        },
        {
            "codeUIC" : 671404,
            "codeCH" : "N2",
            "nameUIC" : "Labarthe-Avezac",
            "nameCH" : "PN 2",
            "code" : "LBA"
        },
        {
            "codeUIC" : 671446,
            "codeCH" : "BV",
            "nameUIC" : "Sarrancolin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671446,
            "codeCH" : "EF",
            "nameUIC" : "Sarrancolin",
            "nameCH" : "ITE Sofrem",
            "code" : ""
        },
        {
            "codeUIC" : 671446,
            "codeCH" : "N8",
            "nameUIC" : "Sarrancolin",
            "nameCH" : "PN 8",
            "code" : ""
        },
        {
            "codeUIC" : 671461,
            "codeCH" : 0,
            "nameUIC" : "Arjuzanx",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671479,
            "codeCH" : "BV",
            "nameUIC" : "Arengosse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671479,
            "codeCH" : "ES",
            "nameUIC" : "Arengosse",
            "nameCH" : "ITE SOPALAND",
            "code" : ""
        },
        {
            "codeUIC" : 671487,
            "codeCH" : 0,
            "nameUIC" : "Ygos",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671495,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-d'Oney",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671578,
            "codeCH" : 0,
            "nameUIC" : "Bagnères-de-Bigorre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671578,
            "codeCH" : 10,
            "nameUIC" : "Bagnères-de-Bigorre",
            "nameCH" : "PN 110",
            "code" : ""
        },
        {
            "codeUIC" : 671578,
            "codeCH" : 14,
            "nameUIC" : "Bagnères-de-Bigorre",
            "nameCH" : "PN 114",
            "code" : ""
        },
        {
            "codeUIC" : 671636,
            "codeCH" : "BV",
            "nameUIC" : "Vic-Bigorre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671636,
            "codeCH" : "EC",
            "nameUIC" : "Vic-Bigorre",
            "nameCH" : "ITE Coopérative des céréales des Hautes-Pyrénées",
            "code" : ""
        },
        {
            "codeUIC" : 671636,
            "codeCH" : "ES",
            "nameUIC" : "Vic-Bigorre",
            "nameCH" : "ITE Km 223,3",
            "code" : ""
        },
        {
            "codeUIC" : 671651,
            "codeCH" : "BV",
            "nameUIC" : "Maubourguet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MGE"
        },
        {
            "codeUIC" : 671651,
            "codeCH" : "EP",
            "nameUIC" : "Maubourguet",
            "nameCH" : "ITE Km 220/221",
            "code" : "MGE"
        },
        {
            "codeUIC" : 671669,
            "codeCH" : "BV",
            "nameUIC" : "Caussade (Hautes-Pyrénées)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671669,
            "codeCH" : "EC",
            "nameUIC" : "Caussade (Hautes-Pyrénées)",
            "nameCH" : "ITE Km 206,3",
            "code" : ""
        },
        {
            "codeUIC" : 671842,
            "codeCH" : 0,
            "nameUIC" : "Augreilh",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 671867,
            "codeCH" : 0,
            "nameUIC" : "Mauco-Benquet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MOQ"
        },
        {
            "codeUIC" : 671875,
            "codeCH" : "PA",
            "nameUIC" : "St-Sever (Landes)",
            "nameCH" : "Pancarte PN 52",
            "code" : ""
        },
        {
            "codeUIC" : 671875,
            "codeCH" : "RH",
            "nameUIC" : "St-Sever (Landes)",
            "nameCH" : "Raccordement Hagetmau",
            "code" : ""
        },
        {
            "codeUIC" : 671875,
            "codeCH" : 0,
            "nameUIC" : "St-Sever (Landes)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 671875,
            "codeCH" : 52,
            "nameUIC" : "St-Sever (Landes)",
            "nameCH" : "PN 52",
            "code" : ""
        },
        {
            "codeUIC" : 671875,
            "codeCH" : 56,
            "nameUIC" : "St-Sever (Landes)",
            "nameCH" : "PN 56",
            "code" : ""
        },
        {
            "codeUIC" : 671883,
            "codeCH" : "ES",
            "nameUIC" : "Audignon",
            "nameCH" : "ITE SICA",
            "code" : ""
        },
        {
            "codeUIC" : 671883,
            "codeCH" : "LO",
            "nameUIC" : "Audignon",
            "nameCH" : "Local",
            "code" : ""
        },
        {
            "codeUIC" : 671891,
            "codeCH" : 0,
            "nameUIC" : "Hagetmau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HGU"
        },
        {
            "codeUIC" : 672006,
            "codeCH" : "BV",
            "nameUIC" : "Pau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PAU"
        },
        {
            "codeUIC" : 672006,
            "codeCH" : "ED",
            "nameUIC" : "Pau",
            "nameCH" : "ITE DUBOIS",
            "code" : "PAU"
        },
        {
            "codeUIC" : 672006,
            "codeCH" : "EI",
            "nameUIC" : "Pau",
            "nameCH" : "ITE INDUSPAL",
            "code" : "PAU"
        },
        {
            "codeUIC" : 672113,
            "codeCH" : 0,
            "nameUIC" : "Montaut-Bétharram",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672139,
            "codeCH" : 0,
            "nameUIC" : "Coarraze-Nay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672162,
            "codeCH" : 0,
            "nameUIC" : "Assat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672170,
            "codeCH" : "BV",
            "nameUIC" : "Lescar",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LSR"
        },
        {
            "codeUIC" : 672170,
            "codeCH" : "EB",
            "nameUIC" : "Lescar",
            "nameCH" : "ITE C E Bétail",
            "code" : "LSR"
        },
        {
            "codeUIC" : 672170,
            "codeCH" : "EH",
            "nameUIC" : "Lescar",
            "nameCH" : "ITE Hourcade",
            "code" : "LSR"
        },
        {
            "codeUIC" : 672170,
            "codeCH" : "ES",
            "nameUIC" : "Lescar",
            "nameCH" : "ITE CACBA",
            "code" : "LSR"
        },
        {
            "codeUIC" : 672170,
            "codeCH" : 21,
            "nameUIC" : "Lescar",
            "nameCH" : "Carrés 21 et 22",
            "code" : "LSR"
        },
        {
            "codeUIC" : 672170,
            "codeCH" : 23,
            "nameUIC" : "Lescar",
            "nameCH" : "Sémaphore 23",
            "code" : "LSR"
        },
        {
            "codeUIC" : 672212,
            "codeCH" : "BV",
            "nameUIC" : "Artix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ATI"
        },
        {
            "codeUIC" : 672212,
            "codeCH" : "PA",
            "nameUIC" : "Artix",
            "nameCH" : "Pancarte 233",
            "code" : "ATI"
        },
        {
            "codeUIC" : 672212,
            "codeCH" : 0,
            "nameUIC" : "Artix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ATI"
        },
        {
            "codeUIC" : 672212,
            "codeCH" : 31,
            "nameUIC" : "Artix",
            "nameCH" : "Carré 31",
            "code" : "ATI"
        },
        {
            "codeUIC" : 672212,
            "codeCH" : 32,
            "nameUIC" : "Artix",
            "nameCH" : "Sémaphore 32",
            "code" : "ATI"
        },
        {
            "codeUIC" : 672220,
            "codeCH" : "BV",
            "nameUIC" : "Lacq",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LQ"
        },
        {
            "codeUIC" : 672220,
            "codeCH" : "EL",
            "nameUIC" : "Lacq",
            "nameCH" : "ITE Lacadee",
            "code" : "LQ"
        },
        {
            "codeUIC" : 672220,
            "codeCH" : "PA",
            "nameUIC" : "Lacq",
            "nameCH" : "Pancarte 246",
            "code" : "LQ"
        },
        {
            "codeUIC" : 672220,
            "codeCH" : 52,
            "nameUIC" : "Lacq",
            "nameCH" : "Carré 52",
            "code" : "LQ"
        },
        {
            "codeUIC" : 672220,
            "codeCH" : 61,
            "nameUIC" : "Lacq",
            "nameCH" : "Carré 61",
            "code" : "LQ"
        },
        {
            "codeUIC" : 672220,
            "codeCH" : 63,
            "nameUIC" : "Lacq",
            "nameCH" : "Carré 62 et Sémaphore 63",
            "code" : "LQ"
        },
        {
            "codeUIC" : 672253,
            "codeCH" : "BV",
            "nameUIC" : "Orthez",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672253,
            "codeCH" : 0,
            "nameUIC" : "Orthez",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672253,
            "codeCH" : 71,
            "nameUIC" : "Orthez",
            "nameCH" : "Carré 71",
            "code" : ""
        },
        {
            "codeUIC" : 672253,
            "codeCH" : 72,
            "nameUIC" : "Orthez",
            "nameCH" : "Carré 72",
            "code" : ""
        },
        {
            "codeUIC" : 672253,
            "codeCH" : 82,
            "nameUIC" : "Orthez",
            "nameCH" : "Carré 82",
            "code" : ""
        },
        {
            "codeUIC" : 672253,
            "codeCH" : 83,
            "nameUIC" : "Orthez",
            "nameCH" : "Sémaphore 83",
            "code" : ""
        },
        {
            "codeUIC" : 672279,
            "codeCH" : "AD",
            "nameUIC" : "Puyoô",
            "nameCH" : "Accès Dax",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672279,
            "codeCH" : "BV",
            "nameUIC" : "Puyoô",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672279,
            "codeCH" : 0,
            "nameUIC" : "Puyoô",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672279,
            "codeCH" : 12,
            "nameUIC" : "Puyoô",
            "nameCH" : "Carré 102",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672279,
            "codeCH" : 13,
            "nameUIC" : "Puyoô",
            "nameCH" : "Sémaphore 103",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672279,
            "codeCH" : 91,
            "nameUIC" : "Puyoô",
            "nameCH" : "Carré 91",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672279,
            "codeCH" : 92,
            "nameUIC" : "Puyoô",
            "nameCH" : "Sémaphore 92",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672279,
            "codeCH" : 94,
            "nameUIC" : "Puyoô",
            "nameCH" : "Carré 94",
            "code" : "PUO"
        },
        {
            "codeUIC" : 672287,
            "codeCH" : "BV",
            "nameUIC" : "Misson-Habas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672287,
            "codeCH" : 12,
            "nameUIC" : "Misson-Habas",
            "nameCH" : "Sémaphores 121 et 122",
            "code" : ""
        },
        {
            "codeUIC" : 672295,
            "codeCH" : "BV",
            "nameUIC" : "Mimbaste",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672295,
            "codeCH" : 0,
            "nameUIC" : "Mimbaste",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672295,
            "codeCH" : 11,
            "nameUIC" : "Mimbaste",
            "nameCH" : "Carré 131",
            "code" : ""
        },
        {
            "codeUIC" : 672295,
            "codeCH" : 12,
            "nameUIC" : "Mimbaste",
            "nameCH" : "Carré 132",
            "code" : ""
        },
        {
            "codeUIC" : 672303,
            "codeCH" : 0,
            "nameUIC" : "Labatut",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672337,
            "codeCH" : "BV",
            "nameUIC" : "Peyrehorade",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672386,
            "codeCH" : 0,
            "nameUIC" : "Urt",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672402,
            "codeCH" : 0,
            "nameUIC" : "Salies-de-Béarn",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672444,
            "codeCH" : "BM",
            "nameUIC" : "Autevielle",
            "nameCH" : "Bifurcation de Mauléon",
            "code" : ""
        },
        {
            "codeUIC" : 672444,
            "codeCH" : "BS",
            "nameUIC" : "Autevielle",
            "nameCH" : "Bifurcation de St-Palais",
            "code" : ""
        },
        {
            "codeUIC" : 672444,
            "codeCH" : 0,
            "nameUIC" : "Autevielle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672444,
            "codeCH" : 16,
            "nameUIC" : "Autevielle",
            "nameCH" : "PN 16",
            "code" : ""
        },
        {
            "codeUIC" : 672451,
            "codeCH" : "ES",
            "nameUIC" : "Sauveterre-de-Béarn",
            "nameCH" : "ITE Socomaf",
            "code" : ""
        },
        {
            "codeUIC" : 672451,
            "codeCH" : 0,
            "nameUIC" : "Sauveterre-de-Béarn",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672451,
            "codeCH" : 18,
            "nameUIC" : "Sauveterre-de-Béarn",
            "nameCH" : "PN 18",
            "code" : ""
        },
        {
            "codeUIC" : 672451,
            "codeCH" : 22,
            "nameUIC" : "Sauveterre-de-Béarn",
            "nameCH" : "PN 22",
            "code" : ""
        },
        {
            "codeUIC" : 672477,
            "codeCH" : 0,
            "nameUIC" : "Rivehaute",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672550,
            "codeCH" : 0,
            "nameUIC" : "Mauléon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672550,
            "codeCH" : 32,
            "nameUIC" : "Mauléon",
            "nameCH" : "PN 32",
            "code" : ""
        },
        {
            "codeUIC" : 672550,
            "codeCH" : 33,
            "nameUIC" : "Mauléon",
            "nameCH" : "PN 33",
            "code" : ""
        },
        {
            "codeUIC" : 672600,
            "codeCH" : 0,
            "nameUIC" : "La Croix-du-Prince",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672618,
            "codeCH" : 0,
            "nameUIC" : "Gan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672634,
            "codeCH" : "S4",
            "nameUIC" : "Buzy-en-Béarn",
            "nameCH" : "Carré 4",
            "code" : ""
        },
        {
            "codeUIC" : 672634,
            "codeCH" : 0,
            "nameUIC" : "Buzy-en-Béarn",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672642,
            "codeCH" : "N3",
            "nameUIC" : "Arudy",
            "nameCH" : "PN 3",
            "code" : ""
        },
        {
            "codeUIC" : 672642,
            "codeCH" : 0,
            "nameUIC" : "Arudy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672709,
            "codeCH" : 0,
            "nameUIC" : "Ogeu-les-Bains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672725,
            "codeCH" : "BV",
            "nameUIC" : "Oloron-Ste-Marie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672741,
            "codeCH" : "ET",
            "nameUIC" : "St-Christau-Lurbe",
            "nameCH" : "ITE TIMAC",
            "code" : ""
        },
        {
            "codeUIC" : 672741,
            "codeCH" : 0,
            "nameUIC" : "St-Christau-Lurbe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 672766,
            "codeCH" : 0,
            "nameUIC" : "Sarrance",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 672774,
            "codeCH" : 0,
            "nameUIC" : "Bedous",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 673004,
            "codeCH" : "BM",
            "nameUIC" : "Bayonne",
            "nameCH" : "Bifurcation de Mousserolles",
            "code" : "BYE"
        },
        {
            "codeUIC" : 673004,
            "codeCH" : "BV",
            "nameUIC" : "Bayonne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BYE"
        },
        {
            "codeUIC" : 673004,
            "codeCH" : "EM",
            "nameUIC" : "Bayonne",
            "nameCH" : "ITE MI VA CEF",
            "code" : "BYE"
        },
        {
            "codeUIC" : 673004,
            "codeCH" : "P1",
            "nameUIC" : "Bayonne",
            "nameCH" : "Poste 1",
            "code" : "BYE"
        },
        {
            "codeUIC" : 673004,
            "codeCH" : "RM",
            "nameUIC" : "Bayonne",
            "nameCH" : "Raccordement Marracq",
            "code" : "BYE"
        },
        {
            "codeUIC" : 673004,
            "codeCH" : "S8",
            "nameUIC" : "Bayonne",
            "nameCH" : "Carré 98",
            "code" : "BYE"
        },
        {
            "codeUIC" : 673004,
            "codeCH" : "TR",
            "nameUIC" : "Bayonne",
            "nameCH" : "Triage",
            "code" : "BYE"
        },
        {
            "codeUIC" : 673020,
            "codeCH" : 0,
            "nameUIC" : "Marracq",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673095,
            "codeCH" : 0,
            "nameUIC" : "Le Boucau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LBO"
        },
        {
            "codeUIC" : 673103,
            "codeCH" : "BV",
            "nameUIC" : "Morcenx",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MCX"
        },
        {
            "codeUIC" : 673103,
            "codeCH" : "EE",
            "nameUIC" : "Morcenx",
            "nameCH" : "ITE Ville de Morcenx",
            "code" : "MCX"
        },
        {
            "codeUIC" : 673111,
            "codeCH" : "EP",
            "nameUIC" : "Rion-des-Landes",
            "nameCH" : "CCPT/EGGER",
            "code" : ""
        },
        {
            "codeUIC" : 673111,
            "codeCH" : 0,
            "nameUIC" : "Rion-des-Landes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673129,
            "codeCH" : "FI",
            "nameUIC" : "Laluque",
            "nameCH" : "",
            "code" : "LLQ"
        },
        {
            "codeUIC" : 673129,
            "codeCH" : 0,
            "nameUIC" : "Laluque",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LLQ"
        },
        {
            "codeUIC" : 673202,
            "codeCH" : "BV",
            "nameUIC" : "Dax",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673202,
            "codeCH" : "EB",
            "nameUIC" : "Dax",
            "nameCH" : "ITE B Impregnes",
            "code" : ""
        },
        {
            "codeUIC" : 673202,
            "codeCH" : "EM",
            "nameUIC" : "Dax",
            "nameCH" : "ITE Sietam",
            "code" : ""
        },
        {
            "codeUIC" : 673202,
            "codeCH" : "ES",
            "nameUIC" : "Dax",
            "nameCH" : "ITE Silandes",
            "code" : ""
        },
        {
            "codeUIC" : 673202,
            "codeCH" : 22,
            "nameUIC" : "Dax",
            "nameCH" : "Carré 222",
            "code" : ""
        },
        {
            "codeUIC" : 673202,
            "codeCH" : 26,
            "nameUIC" : "Dax",
            "nameCH" : "Sémaphore 264",
            "code" : ""
        },
        {
            "codeUIC" : 673202,
            "codeCH" : 29,
            "nameUIC" : "Dax",
            "nameCH" : "Sémaphore 298",
            "code" : ""
        },
        {
            "codeUIC" : 673244,
            "codeCH" : "EC",
            "nameUIC" : "Rivière",
            "nameCH" : "ITE Ciments Français",
            "code" : ""
        },
        {
            "codeUIC" : 673244,
            "codeCH" : 0,
            "nameUIC" : "Rivière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673251,
            "codeCH" : 0,
            "nameUIC" : "Saubusse-les-Bains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673269,
            "codeCH" : 0,
            "nameUIC" : "St-Geours-de-Maremne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673277,
            "codeCH" : "BV",
            "nameUIC" : "St-Vincent-de-Tyrosse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673277,
            "codeCH" : "EM",
            "nameUIC" : "St-Vincent-de-Tyrosse",
            "nameCH" : "ITE MERLE",
            "code" : ""
        },
        {
            "codeUIC" : 673285,
            "codeCH" : 0,
            "nameUIC" : "Bénesse-Maremne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673293,
            "codeCH" : "EG",
            "nameUIC" : "Labenne",
            "nameCH" : "ITE Guyenne",
            "code" : ""
        },
        {
            "codeUIC" : 673293,
            "codeCH" : 0,
            "nameUIC" : "Labenne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673319,
            "codeCH" : 0,
            "nameUIC" : "Ondres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673400,
            "codeCH" : 0,
            "nameUIC" : "Biarritz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673459,
            "codeCH" : 0,
            "nameUIC" : "Guéthary",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673608,
            "codeCH" : 0,
            "nameUIC" : "Behereharta",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 673616,
            "codeCH" : 0,
            "nameUIC" : "Villefranque (Pyrénées-Atlanti",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673624,
            "codeCH" : 0,
            "nameUIC" : "Ustaritz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673632,
            "codeCH" : 0,
            "nameUIC" : "Jatxou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673640,
            "codeCH" : 0,
            "nameUIC" : "Halsou-Larressore",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673657,
            "codeCH" : 0,
            "nameUIC" : "Cambo-les-Bains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673665,
            "codeCH" : 0,
            "nameUIC" : "Itxassou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673673,
            "codeCH" : 0,
            "nameUIC" : "Louhossoa",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 673699,
            "codeCH" : 0,
            "nameUIC" : "Pont-Noblia",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673707,
            "codeCH" : "BV",
            "nameUIC" : "Ossès-St-Martin-d'Arrossa",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673707,
            "codeCH" : "ES",
            "nameUIC" : "Ossès-St-Martin-d'Arrossa",
            "nameCH" : "ITE Sagral",
            "code" : ""
        },
        {
            "codeUIC" : 673707,
            "codeCH" : "N3",
            "nameUIC" : "Ossès-St-Martin-d'Arrossa",
            "nameCH" : "PN 3",
            "code" : ""
        },
        {
            "codeUIC" : 673707,
            "codeCH" : "N7",
            "nameUIC" : "Ossès-St-Martin-d'Arrossa",
            "nameCH" : "PN 7",
            "code" : ""
        },
        {
            "codeUIC" : 673723,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-Pied-de-Port",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673772,
            "codeCH" : 0,
            "nameUIC" : "St-Étienne-de-Baïgorry",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 673814,
            "codeCH" : 0,
            "nameUIC" : "Lahonce",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 674770,
            "codeCH" : "BS",
            "nameUIC" : "Aix-en-Provence-Marchandises",
            "nameCH" : "BS",
            "code" : "APM"
        },
        {
            "codeUIC" : 674770,
            "codeCH" : "CH",
            "nameUIC" : "Aix-en-Provence-Marchandises",
            "nameCH" : "BS Chantier",
            "code" : "APM"
        },
        {
            "codeUIC" : 674770,
            "codeCH" : "PA",
            "nameUIC" : "Aix-en-Provence-Marchandises",
            "nameCH" : "Pancarte Arrêt Km 0,9",
            "code" : "APM"
        },
        {
            "codeUIC" : 674770,
            "codeCH" : "PN",
            "nameUIC" : "Aix-en-Provence-Marchandises",
            "nameCH" : "PN 1",
            "code" : "APM"
        },
        {
            "codeUIC" : 674960,
            "codeCH" : "BM",
            "nameUIC" : "Poste 53 Maillé",
            "nameCH" : "Base Maintenance Nouâtre-Maillé",
            "code" : ""
        },
        {
            "codeUIC" : 674960,
            "codeCH" : "RC",
            "nameUIC" : "Poste 53 Maillé",
            "nameCH" : "Raccordement La Celle",
            "code" : ""
        },
        {
            "codeUIC" : 674978,
            "codeCH" : "BA",
            "nameUIC" : "Ambarès",
            "nameCH" : "Bifurcation Ambarès",
            "code" : ""
        },
        {
            "codeUIC" : 677005,
            "codeCH" : "XE",
            "nameUIC" : "Hendaye",
            "nameCH" : "Frontière France-Espagne",
            "code" : "HE"
        },
        {
            "codeUIC" : 677005,
            "codeCH" : 0,
            "nameUIC" : "Hendaye",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HE"
        },
        {
            "codeUIC" : 677104,
            "codeCH" : 0,
            "nameUIC" : "Les Deux Jumeaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 677120,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-de-Luz-Ciboure",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 677914,
            "codeCH" : "WE",
            "nameUIC" : "Irun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 679894,
            "codeCH" : "BP",
            "nameUIC" : "Poste 33 Connerré",
            "nameCH" : "Bifurcation du Parc",
            "code" : ""
        },
        {
            "codeUIC" : 679910,
            "codeCH" : "AR",
            "nameUIC" : "Poste 71 Montfort",
            "nameCH" : "Raccordement Fret",
            "code" : ""
        },
        {
            "codeUIC" : 679928,
            "codeCH" : "CV",
            "nameUIC" : "Poste 80 Cesson-Sévigné",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 681007,
            "codeCH" : "BM",
            "nameUIC" : "Corbeil-Essonnes",
            "nameCH" : "Bifurcation Km 33",
            "code" : "COE"
        },
        {
            "codeUIC" : 681007,
            "codeCH" : "BV",
            "nameUIC" : "Corbeil-Essonnes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COE"
        },
        {
            "codeUIC" : 681007,
            "codeCH" : "V3",
            "nameUIC" : "Corbeil-Essonnes",
            "nameCH" : "Voie 3 Garage",
            "code" : "COE"
        },
        {
            "codeUIC" : 681007,
            "codeCH" : 12,
            "nameUIC" : "Corbeil-Essonnes",
            "nameCH" : "Voie 12 Garage",
            "code" : "COE"
        },
        {
            "codeUIC" : 681155,
            "codeCH" : 0,
            "nameUIC" : "Maisons-Alfort-Alfortville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681163,
            "codeCH" : "NO",
            "nameUIC" : "Pompadour-Maisons-Alfort",
            "nameCH" : "Chantier Novatrans",
            "code" : "PMA"
        },
        {
            "codeUIC" : 681163,
            "codeCH" : 0,
            "nameUIC" : "Pompadour-Maisons-Alfort",
            "nameCH" : "",
            "code" : "PMA"
        },
        {
            "codeUIC" : 681247,
            "codeCH" : "A8",
            "nameUIC" : "Le Vert-de-Maisons",
            "nameCH" : "Aiguille 8 Rebroussement",
            "code" : ""
        },
        {
            "codeUIC" : 681247,
            "codeCH" : "BF",
            "nameUIC" : "Le Vert-de-Maisons",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 681247,
            "codeCH" : "BV",
            "nameUIC" : "Le Vert-de-Maisons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 681304,
            "codeCH" : 0,
            "nameUIC" : "Vigneux-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681312,
            "codeCH" : 0,
            "nameUIC" : "Viry-Châtillon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681320,
            "codeCH" : 0,
            "nameUIC" : "Grigny-Val-de-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681338,
            "codeCH" : "BV",
            "nameUIC" : "Ris-Orangis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RIS"
        },
        {
            "codeUIC" : 681338,
            "codeCH" : "EP",
            "nameUIC" : "Ris-Orangis",
            "nameCH" : "ITE Zone Industrielle Noues de Seine",
            "code" : "RIS"
        },
        {
            "codeUIC" : 681338,
            "codeCH" : "LO",
            "nameUIC" : "Ris-Orangis",
            "nameCH" : "ITE Longuet",
            "code" : "RIS"
        },
        {
            "codeUIC" : 681346,
            "codeCH" : 0,
            "nameUIC" : "Orangis-Bois-de-l'Épine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681353,
            "codeCH" : 0,
            "nameUIC" : "Grand-Bourg (Essonne)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681361,
            "codeCH" : 0,
            "nameUIC" : "Évry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681379,
            "codeCH" : 0,
            "nameUIC" : "Grigny-Centre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681387,
            "codeCH" : 0,
            "nameUIC" : "Évry-Courcouronnes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681395,
            "codeCH" : 0,
            "nameUIC" : "Le Bras-de-fer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681403,
            "codeCH" : 0,
            "nameUIC" : "Moulin-Galant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681411,
            "codeCH" : 0,
            "nameUIC" : "Mennecy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681429,
            "codeCH" : 0,
            "nameUIC" : "Fontenay-le-Vicomte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681437,
            "codeCH" : 0,
            "nameUIC" : "Ballancourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681452,
            "codeCH" : 0,
            "nameUIC" : "La Ferté-Alais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681478,
            "codeCH" : 0,
            "nameUIC" : "Boutigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681486,
            "codeCH" : "BV",
            "nameUIC" : "Maisse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 681486,
            "codeCH" : "EP",
            "nameUIC" : "Maisse",
            "nameCH" : "ITE SIC",
            "code" : ""
        },
        {
            "codeUIC" : 681510,
            "codeCH" : 0,
            "nameUIC" : "Buno-Gironville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681601,
            "codeCH" : 0,
            "nameUIC" : "Essonnes-Robinson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681619,
            "codeCH" : 0,
            "nameUIC" : "Villabé",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681627,
            "codeCH" : 0,
            "nameUIC" : "Le Plessis-Chenet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681635,
            "codeCH" : 0,
            "nameUIC" : "Coudray-Montceaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "AT",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Atelier Matériel",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "BF",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Bifurcation Km 8,9/9,1",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "BV",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "CV",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Carré Violet 19",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "C5",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Carré 55",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "DN",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Faisceau Départ Nord",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "DP",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Dépôt",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "DS",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Faisceau Départ Sud",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "FD",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Faisceau Débranchement",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "FR",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Faisceau Réception",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "FV",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Faisceau Relais",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "PA",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Poste A",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "PB",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Poste B",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "PK",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Poste K",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "P2",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Poste 2",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "P3",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Poste 3",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : "RB",
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Faisceau Rebroussement",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : 11,
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Carré 11",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681809,
            "codeCH" : 19,
            "nameUIC" : "Villeneuve-St-Georges-Triage",
            "nameCH" : "Panneau 19",
            "code" : "VSG"
        },
        {
            "codeUIC" : 681825,
            "codeCH" : "BM",
            "nameUIC" : "Villeneuve-St-Georges",
            "nameCH" : "Bifurcation Km 17,090",
            "code" : ""
        },
        {
            "codeUIC" : 681825,
            "codeCH" : "BV",
            "nameUIC" : "Villeneuve-St-Georges",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 681825,
            "codeCH" : "PX",
            "nameUIC" : "Villeneuve-St-Georges",
            "nameCH" : "Point X",
            "code" : ""
        },
        {
            "codeUIC" : 681825,
            "codeCH" : 15,
            "nameUIC" : "Villeneuve-St-Georges",
            "nameCH" : "Bifurcation Km 15,9",
            "code" : ""
        },
        {
            "codeUIC" : 681825,
            "codeCH" : 17,
            "nameUIC" : "Villeneuve-St-Georges",
            "nameCH" : "Bifurcation Km 17,4",
            "code" : ""
        },
        {
            "codeUIC" : 681858,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-Prairie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "AA",
            "nameUIC" : "Melun",
            "nameCH" : "ITE Actif-Avto",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "AS",
            "nameUIC" : "Melun",
            "nameCH" : "Bifurcation Km 58,4",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "BM",
            "nameUIC" : "Melun",
            "nameCH" : "V2BIS Côté Moret",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "BV",
            "nameUIC" : "Melun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "CS",
            "nameUIC" : "Melun",
            "nameCH" : "Aiguille Communication Sud",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "DV",
            "nameUIC" : "Melun",
            "nameCH" : "ITE Devoiselle",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "P1",
            "nameUIC" : "Melun",
            "nameCH" : "Poste 1",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "P2",
            "nameUIC" : "Melun",
            "nameCH" : "Poste 2",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "V3",
            "nameUIC" : "Melun",
            "nameCH" : "Voie 3 Garage",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "V5",
            "nameUIC" : "Melun",
            "nameCH" : "Voie 5 Garage",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682005,
            "codeCH" : "V6",
            "nameUIC" : "Melun",
            "nameCH" : "Voie 6 Circulation",
            "code" : "MEL"
        },
        {
            "codeUIC" : 682104,
            "codeCH" : 0,
            "nameUIC" : "Montgeron-Crosne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682112,
            "codeCH" : 0,
            "nameUIC" : "Yerres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682120,
            "codeCH" : 0,
            "nameUIC" : "Brunoy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682138,
            "codeCH" : 0,
            "nameUIC" : "Boussy-St-Antoine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682146,
            "codeCH" : "BF",
            "nameUIC" : "Combs-la-Ville-Quincy",
            "nameCH" : "Bifurcation LGV",
            "code" : ""
        },
        {
            "codeUIC" : 682146,
            "codeCH" : "BV",
            "nameUIC" : "Combs-la-Ville-Quincy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 682153,
            "codeCH" : "BV",
            "nameUIC" : "Lieusaint-Moissy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LIU"
        },
        {
            "codeUIC" : 682153,
            "codeCH" : "EP",
            "nameUIC" : "Lieusaint-Moissy",
            "nameCH" : "ITE ZAI de Savigny-le-Temple",
            "code" : "LIU"
        },
        {
            "codeUIC" : 682153,
            "codeCH" : "PE",
            "nameUIC" : "Lieusaint-Moissy",
            "nameCH" : "ITE EPAMS ZAC des Hauldres",
            "code" : "LIU"
        },
        {
            "codeUIC" : 682153,
            "codeCH" : "SE",
            "nameUIC" : "Lieusaint-Moissy",
            "nameCH" : "ITE Sezac",
            "code" : "LIU"
        },
        {
            "codeUIC" : 682161,
            "codeCH" : 0,
            "nameUIC" : "Cesson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682179,
            "codeCH" : 0,
            "nameUIC" : "Le Mée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682187,
            "codeCH" : 0,
            "nameUIC" : "Savigny-le-Temple-Nandy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682203,
            "codeCH" : 0,
            "nameUIC" : "Bois-le-Roi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682211,
            "codeCH" : "BF",
            "nameUIC" : "Fontainebleau-Avon",
            "nameCH" : "Aiguille IPCS Km 56,3",
            "code" : "FON"
        },
        {
            "codeUIC" : 682211,
            "codeCH" : "BV",
            "nameUIC" : "Fontainebleau-Avon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FON"
        },
        {
            "codeUIC" : 682211,
            "codeCH" : "PA",
            "nameUIC" : "Fontainebleau-Avon",
            "nameCH" : "Point Arrêt Km 55,1",
            "code" : "FON"
        },
        {
            "codeUIC" : 682252,
            "codeCH" : 0,
            "nameUIC" : "Thomery",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "AN",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Bifurcation Km 65,5",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "BV",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "CV",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Carré 7",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "ES",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Bifurcation Entrée/Sortie",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "FP",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Faisceau Pair",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "SD",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Moret-Sud",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "VI",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Carré Violet 160",
            "code" : ""
        },
        {
            "codeUIC" : 682278,
            "codeCH" : "1B",
            "nameUIC" : "Moret-Veneux-les-Sablons",
            "nameCH" : "Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 682294,
            "codeCH" : 0,
            "nameUIC" : "St-Mammès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "AB",
            "nameUIC" : "Montereau",
            "nameCH" : "Voies A et B",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "BV",
            "nameUIC" : "Montereau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "IC",
            "nameUIC" : "Montereau",
            "nameCH" : "ITE CEMEX",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "PD",
            "nameUIC" : "Montereau",
            "nameCH" : "Voie 5 - PVD",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "PU",
            "nameUIC" : "Montereau",
            "nameCH" : "Poste Central",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "PV",
            "nameUIC" : "Montereau",
            "nameCH" : "Voie 3",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "V3",
            "nameUIC" : "Montereau",
            "nameCH" : "Bifurcation Km 76,9",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "1M",
            "nameUIC" : "Montereau",
            "nameCH" : "Bifurcation Km 86,7",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : "2M",
            "nameUIC" : "Montereau",
            "nameCH" : "Bifurcation Km 86,8",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682302,
            "codeCH" : 26,
            "nameUIC" : "Montereau",
            "nameCH" : "Voie 26 Garage",
            "code" : "MTU"
        },
        {
            "codeUIC" : 682310,
            "codeCH" : "BV",
            "nameUIC" : "Châtenay-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 682401,
            "codeCH" : 0,
            "nameUIC" : "Livry-sur-Seine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682419,
            "codeCH" : 0,
            "nameUIC" : "Chartrettes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682427,
            "codeCH" : 0,
            "nameUIC" : "Fontaine-le-Port",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682435,
            "codeCH" : 0,
            "nameUIC" : "Héricy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682443,
            "codeCH" : 0,
            "nameUIC" : "Vulaines-sur-Seine-Samoreau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682450,
            "codeCH" : "BV",
            "nameUIC" : "Champagne-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 682468,
            "codeCH" : "BV",
            "nameUIC" : "Vernou-sur-Seine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VSS"
        },
        {
            "codeUIC" : 682468,
            "codeCH" : "EP",
            "nameUIC" : "Vernou-sur-Seine",
            "nameCH" : "ITE EDF",
            "code" : "VSS"
        },
        {
            "codeUIC" : 682476,
            "codeCH" : 0,
            "nameUIC" : "La Grande-Paroisse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682500,
            "codeCH" : 0,
            "nameUIC" : "Vosves",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682518,
            "codeCH" : 0,
            "nameUIC" : "Boissise-le-Roi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682526,
            "codeCH" : 0,
            "nameUIC" : "Ponthierry-Pringy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 682542,
            "codeCH" : 0,
            "nameUIC" : "St-Fargeau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683003,
            "codeCH" : "BV",
            "nameUIC" : "Sens",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SES"
        },
        {
            "codeUIC" : 683003,
            "codeCH" : "P1",
            "nameUIC" : "Sens",
            "nameCH" : "Poste 1",
            "code" : "SES"
        },
        {
            "codeUIC" : 683003,
            "codeCH" : "P2",
            "nameUIC" : "Sens",
            "nameCH" : "Poste 2",
            "code" : "SES"
        },
        {
            "codeUIC" : 683003,
            "codeCH" : "V3",
            "nameUIC" : "Sens",
            "nameCH" : "Voie 3 Garage",
            "code" : "SES"
        },
        {
            "codeUIC" : 683003,
            "codeCH" : "ZI",
            "nameUIC" : "Sens",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "SES"
        },
        {
            "codeUIC" : 683011,
            "codeCH" : "BV",
            "nameUIC" : "Sens-St-Clément",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 683011,
            "codeCH" : 3,
            "nameUIC" : "Sens-St-Clément",
            "nameCH" : "PN 203",
            "code" : ""
        },
        {
            "codeUIC" : 683011,
            "codeCH" : "1B",
            "nameUIC" : "Sens-St-Clément",
            "nameCH" : "PN 201Bis",
            "code" : ""
        },
        {
            "codeUIC" : 683102,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-la-Guyard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683128,
            "codeCH" : 0,
            "nameUIC" : "Champigny-sur-Yonne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683136,
            "codeCH" : 0,
            "nameUIC" : "Pont-sur-Yonne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683201,
            "codeCH" : 0,
            "nameUIC" : "Étigny-Véron",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683219,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-sur-Yonne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683227,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-du-Sault",
            "nameCH" : "",
            "code" : "SJT"
        },
        {
            "codeUIC" : 683235,
            "codeCH" : 0,
            "nameUIC" : "Cézy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683243,
            "codeCH" : 0,
            "nameUIC" : "Joigny",
            "nameCH" : "",
            "code" : "JOI"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "BV",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "CV",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Carré Violet 46",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "FB",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Faisceau Bercy",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "ND",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Laroche Nord",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "P1",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Poste 1",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "P2",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Poste 2",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "P3",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Poste 3",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "P4",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Poste 4",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "P5",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Poste 5",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "SD",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Laroche Sud",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "V4",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Voies 4 à 10",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : "V7",
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Voies 7 à 11",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : 10,
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Voie 10 Tiroir",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : 12,
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Carré Violet 12",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683268,
            "codeCH" : 52,
            "nameUIC" : "Laroche-Migennes",
            "nameCH" : "Carré Violet 52",
            "code" : "LAR"
        },
        {
            "codeUIC" : 683292,
            "codeCH" : 0,
            "nameUIC" : "Brienon",
            "nameCH" : "",
            "code" : "BIW"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "BV",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "G1",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Aiguille Km 167,5",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "G2",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Aiguille Km 168,5",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "P2",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Bifurcation Km 173,8",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "V3",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Voie 3 Garage",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "V4",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Voie 4 Garage",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "1B",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Aiguille Km 165,9",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683318,
            "codeCH" : "2B",
            "nameUIC" : "St-Florentin-Vergigny",
            "nameCH" : "Aiguille Km 168,3",
            "code" : "SFE"
        },
        {
            "codeUIC" : 683334,
            "codeCH" : 0,
            "nameUIC" : "St-Florentin-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683342,
            "codeCH" : 0,
            "nameUIC" : "Neuvy-Sautour",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683508,
            "codeCH" : 0,
            "nameUIC" : "Cheny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683516,
            "codeCH" : "EP",
            "nameUIC" : "Bonnard-Bassou",
            "nameCH" : "ITE Moulins de Bonnard-Vaudois",
            "code" : "BNY"
        },
        {
            "codeUIC" : 683516,
            "codeCH" : 0,
            "nameUIC" : "Bonnard-Bassou",
            "nameCH" : "",
            "code" : "BNY"
        },
        {
            "codeUIC" : 683532,
            "codeCH" : "BV",
            "nameUIC" : "Chemilly-Appoigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CAP"
        },
        {
            "codeUIC" : 683532,
            "codeCH" : "EP",
            "nameUIC" : "Chemilly-Appoigny",
            "nameCH" : "ITE Munitions",
            "code" : "CAP"
        },
        {
            "codeUIC" : 683557,
            "codeCH" : "BV",
            "nameUIC" : "Monéteau-Gurgy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 683573,
            "codeCH" : "BV",
            "nameUIC" : "Auxerre-St-Gervais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AUX"
        },
        {
            "codeUIC" : 683573,
            "codeCH" : "CR",
            "nameUIC" : "Auxerre-St-Gervais",
            "nameCH" : "ITE Ceraliment",
            "code" : "AUX"
        },
        {
            "codeUIC" : 683573,
            "codeCH" : "P1",
            "nameUIC" : "Auxerre-St-Gervais",
            "nameCH" : "Poste 1",
            "code" : "AUX"
        },
        {
            "codeUIC" : 683573,
            "codeCH" : "P2",
            "nameUIC" : "Auxerre-St-Gervais",
            "nameCH" : "Poste 2",
            "code" : "AUX"
        },
        {
            "codeUIC" : 683573,
            "codeCH" : "SB",
            "nameUIC" : "Auxerre-St-Gervais",
            "nameCH" : "ITE Shell-Berre",
            "code" : "AUX"
        },
        {
            "codeUIC" : 683607,
            "codeCH" : 0,
            "nameUIC" : "Augy-Vaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683615,
            "codeCH" : "BV",
            "nameUIC" : "Champs-St-Bris",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 683615,
            "codeCH" : "EP",
            "nameUIC" : "Champs-St-Bris",
            "nameCH" : "ITE St-Nicolas",
            "code" : ""
        },
        {
            "codeUIC" : 683631,
            "codeCH" : 0,
            "nameUIC" : "Vincelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683649,
            "codeCH" : "BV",
            "nameUIC" : "Cravant-Bazarnes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CBZ"
        },
        {
            "codeUIC" : 683649,
            "codeCH" : "P2",
            "nameUIC" : "Cravant-Bazarnes",
            "nameCH" : "Poste 2",
            "code" : "CBZ"
        },
        {
            "codeUIC" : 683656,
            "codeCH" : 0,
            "nameUIC" : "Accolay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683664,
            "codeCH" : 0,
            "nameUIC" : "Vermenton",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683672,
            "codeCH" : 0,
            "nameUIC" : "Lucy-sur-Cure-Bessy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683680,
            "codeCH" : 0,
            "nameUIC" : "Arcy-sur-Cure",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683706,
            "codeCH" : 0,
            "nameUIC" : "Voutenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683714,
            "codeCH" : "AO",
            "nameUIC" : "Poste 63 Chevry-Cossigny",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 683714,
            "codeCH" : "PR",
            "nameUIC" : "Poste 63 Chevry-Cossigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683722,
            "codeCH" : 0,
            "nameUIC" : "Sermizelles-Vézelay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 683755,
            "codeCH" : 0,
            "nameUIC" : "Annay-la-Côte",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 683789,
            "codeCH" : "BV",
            "nameUIC" : "Avallon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AVA"
        },
        {
            "codeUIC" : 683789,
            "codeCH" : "EP",
            "nameUIC" : "Avallon",
            "nameCH" : "ITE Schiever",
            "code" : "AVA"
        },
        {
            "codeUIC" : 683847,
            "codeCH" : 0,
            "nameUIC" : "Mailly-la-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684001,
            "codeCH" : "BF",
            "nameUIC" : "Montargis",
            "nameCH" : "Bifurcation",
            "code" : "MS"
        },
        {
            "codeUIC" : 684001,
            "codeCH" : "BV",
            "nameUIC" : "Montargis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MS"
        },
        {
            "codeUIC" : 684001,
            "codeCH" : "FI",
            "nameUIC" : "Montargis",
            "nameCH" : "Faisceau Impair",
            "code" : "MS"
        },
        {
            "codeUIC" : 684001,
            "codeCH" : "FP",
            "nameUIC" : "Montargis",
            "nameCH" : "Faisceau Pair",
            "code" : "MS"
        },
        {
            "codeUIC" : 684001,
            "codeCH" : "ND",
            "nameUIC" : "Montargis",
            "nameCH" : "Montargis-Nord",
            "code" : "MS"
        },
        {
            "codeUIC" : 684001,
            "codeCH" : "PA",
            "nameUIC" : "Montargis",
            "nameCH" : "Sortie Faisceau Impair",
            "code" : "MS"
        },
        {
            "codeUIC" : 684001,
            "codeCH" : "PD",
            "nameUIC" : "Montargis",
            "nameCH" : "Poste D",
            "code" : "MS"
        },
        {
            "codeUIC" : 684100,
            "codeCH" : 0,
            "nameUIC" : "Montigny-sur-Loing",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684118,
            "codeCH" : "BV",
            "nameUIC" : "Bourron-Marlotte-Grez",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BOM"
        },
        {
            "codeUIC" : 684118,
            "codeCH" : "EP",
            "nameUIC" : "Bourron-Marlotte-Grez",
            "nameCH" : "ITE Sablières",
            "code" : "BOM"
        },
        {
            "codeUIC" : 684126,
            "codeCH" : "BV",
            "nameUIC" : "Nemours-St-Pierre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NSP"
        },
        {
            "codeUIC" : 684191,
            "codeCH" : 0,
            "nameUIC" : "Bagneaux-sur-Loing",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684217,
            "codeCH" : "BV",
            "nameUIC" : "Souppes-Château-Landon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SCL"
        },
        {
            "codeUIC" : 684233,
            "codeCH" : 0,
            "nameUIC" : "Dordives",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684241,
            "codeCH" : 0,
            "nameUIC" : "Ferrières-Fontenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684274,
            "codeCH" : 0,
            "nameUIC" : "Nogent-sur-Vernisson",
            "nameCH" : "",
            "code" : "NGI"
        },
        {
            "codeUIC" : 684290,
            "codeCH" : 0,
            "nameUIC" : "Gien",
            "nameCH" : "",
            "code" : "GIE"
        },
        {
            "codeUIC" : 684407,
            "codeCH" : 0,
            "nameUIC" : "Boigneville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684415,
            "codeCH" : "BV",
            "nameUIC" : "Malesherbes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MAL"
        },
        {
            "codeUIC" : 684415,
            "codeCH" : "EP",
            "nameUIC" : "Malesherbes",
            "nameCH" : "ITE Samin",
            "code" : "MAL"
        },
        {
            "codeUIC" : 684415,
            "codeCH" : 0,
            "nameUIC" : "Malesherbes",
            "nameCH" : "",
            "code" : "MAL"
        },
        {
            "codeUIC" : 684456,
            "codeCH" : 0,
            "nameUIC" : "La Brosse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684472,
            "codeCH" : 0,
            "nameUIC" : "Puiseaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684514,
            "codeCH" : 0,
            "nameUIC" : "Beaumont-Boësses",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684530,
            "codeCH" : "BV",
            "nameUIC" : "Auxy-Juranville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AXJ"
        },
        {
            "codeUIC" : 684530,
            "codeCH" : "EP",
            "nameUIC" : "Auxy-Juranville",
            "nameCH" : "ITE CERAPRO",
            "code" : "AXJ"
        },
        {
            "codeUIC" : 684530,
            "codeCH" : 0,
            "nameUIC" : "Auxy-Juranville",
            "nameCH" : "",
            "code" : "AXJ"
        },
        {
            "codeUIC" : 684548,
            "codeCH" : 0,
            "nameUIC" : "Lorcy",
            "nameCH" : "",
            "code" : "LOR"
        },
        {
            "codeUIC" : 684563,
            "codeCH" : "EP",
            "nameUIC" : "Mignères-Gondréville",
            "nameCH" : "ITE SAPRR",
            "code" : "MGG"
        },
        {
            "codeUIC" : 684563,
            "codeCH" : 0,
            "nameUIC" : "Mignères-Gondréville",
            "nameCH" : "",
            "code" : "MGG"
        },
        {
            "codeUIC" : 684621,
            "codeCH" : "BV",
            "nameUIC" : "La Chapelle-la-Reine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LCR"
        },
        {
            "codeUIC" : 684621,
            "codeCH" : "EP",
            "nameUIC" : "La Chapelle-la-Reine",
            "nameCH" : "ITE GSI",
            "code" : "LCR"
        },
        {
            "codeUIC" : 684654,
            "codeCH" : 0,
            "nameUIC" : "Pannes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684662,
            "codeCH" : 0,
            "nameUIC" : "St-Maurice-sur-Fessard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684670,
            "codeCH" : 0,
            "nameUIC" : "Ladon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684688,
            "codeCH" : "BV",
            "nameUIC" : "Bellegarde-Quiers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 684704,
            "codeCH" : "BV",
            "nameUIC" : "Amilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 684712,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-des-Prés (Loiret)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684720,
            "codeCH" : "BV",
            "nameUIC" : "Château-Renard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 684746,
            "codeCH" : 0,
            "nameUIC" : "Triguères",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684803,
            "codeCH" : 0,
            "nameUIC" : "Douchy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684811,
            "codeCH" : "BV",
            "nameUIC" : "Charny (Yonne)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 684811,
            "codeCH" : "EP",
            "nameUIC" : "Charny (Yonne)",
            "nameCH" : "ITE Coopérative Agricole",
            "code" : ""
        },
        {
            "codeUIC" : 684837,
            "codeCH" : "BV",
            "nameUIC" : "Grandchamp",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 684837,
            "codeCH" : "EP",
            "nameUIC" : "Grandchamp",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 684852,
            "codeCH" : "BV",
            "nameUIC" : "Toucy-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 684852,
            "codeCH" : 48,
            "nameUIC" : "Toucy-Ville",
            "nameCH" : "PN 48",
            "code" : ""
        },
        {
            "codeUIC" : 684852,
            "codeCH" : 49,
            "nameUIC" : "Toucy-Ville",
            "nameCH" : "PN 49",
            "code" : ""
        },
        {
            "codeUIC" : 684860,
            "codeCH" : 0,
            "nameUIC" : "Toucy-Moulins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 684878,
            "codeCH" : 0,
            "nameUIC" : "Fontenoy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "AG",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Aiguille Km 9,6",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "BI",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Bifurcation de Créteil",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "BV",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "KM",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Aiguille Km 10,550",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "PL",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Plateau Lettres",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "PP",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Parc Postal Charolais",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "P1",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Poste 1 et 2",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : "RM",
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Remisage",
            "code" : ""
        },
        {
            "codeUIC" : 686006,
            "codeCH" : 21,
            "nameUIC" : "Paris-Gare-de-Lyon",
            "nameCH" : "Aiguille Km 2,7",
            "code" : ""
        },
        {
            "codeUIC" : 686030,
            "codeCH" : 0,
            "nameUIC" : "Paris-Gare-de-Lyon (Banlieue)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 686220,
            "codeCH" : "AI",
            "nameUIC" : "Poste 61 Yerres",
            "nameCH" : "Aiguille",
            "code" : ""
        },
        {
            "codeUIC" : 686220,
            "codeCH" : "BM",
            "nameUIC" : "Poste 61 Yerres",
            "nameCH" : "Bifurcation de Massy",
            "code" : ""
        },
        {
            "codeUIC" : 686220,
            "codeCH" : "PR",
            "nameUIC" : "Poste 61 Yerres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 686667,
            "codeCH" : "BV",
            "nameUIC" : "Paris-Bercy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "CC",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Atelier Matériel",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "P1",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "P2",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "P3",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "RM",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Remisage Départ",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "RN",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Faisceau NICOLAI",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "RP",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Remisage Pair",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "TR",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Remisage Banlieue Triage",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "V4",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Voies 4 à 10 (Remisage Pair)",
            "code" : ""
        },
        {
            "codeUIC" : 686675,
            "codeCH" : "1P",
            "nameUIC" : "Paris-Bercy-Conflans",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 688754,
            "codeCH" : "RL",
            "nameUIC" : "Lattes",
            "nameCH" : "Raccordement Lattes",
            "code" : ""
        },
        {
            "codeUIC" : 688762,
            "codeCH" : "BM",
            "nameUIC" : "Nîmes (CNM)",
            "nameCH" : "Bifurcation Nîmes CNM",
            "code" : ""
        },
        {
            "codeUIC" : 688770,
            "codeCH" : "CV",
            "nameUIC" : "Saturargues",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 688887,
            "codeCH" : "BL",
            "nameUIC" : "Montpellier (CNM)",
            "nameCH" : "Bifurcation Lattes",
            "code" : ""
        },
        {
            "codeUIC" : 688887,
            "codeCH" : "BV",
            "nameUIC" : "Montpellier (CNM)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 688887,
            "codeCH" : "EE",
            "nameUIC" : "Montpellier (CNM)",
            "nameCH" : "Entrée Est",
            "code" : ""
        },
        {
            "codeUIC" : 688887,
            "codeCH" : "EO",
            "nameUIC" : "Montpellier (CNM)",
            "nameCH" : "Entrée Est",
            "code" : ""
        },
        {
            "codeUIC" : 688903,
            "codeCH" : "VS",
            "nameUIC" : "St-Gervasy",
            "nameCH" : "Virgulette sud",
            "code" : ""
        },
        {
            "codeUIC" : 688911,
            "codeCH" : "BC",
            "nameUIC" : "Costières",
            "nameCH" : "Bifurcation Costières",
            "code" : ""
        },
        {
            "codeUIC" : 688911,
            "codeCH" : "BJ",
            "nameUIC" : "Costières",
            "nameCH" : "Bifurcation Jonquières",
            "code" : ""
        },
        {
            "codeUIC" : 688911,
            "codeCH" : "1G",
            "nameUIC" : "Costières",
            "nameCH" : "Carré 1337",
            "code" : ""
        },
        {
            "codeUIC" : 691311,
            "codeCH" : 0,
            "nameUIC" : "Villechaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691337,
            "codeCH" : 0,
            "nameUIC" : "Les Girarmes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691394,
            "codeCH" : 0,
            "nameUIC" : "La Marche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691436,
            "codeCH" : 0,
            "nameUIC" : "Garchizy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691469,
            "codeCH" : 0,
            "nameUIC" : "Vauzelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691600,
            "codeCH" : 0,
            "nameUIC" : "Les Perrières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691618,
            "codeCH" : 0,
            "nameUIC" : "St-Éloi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691725,
            "codeCH" : 0,
            "nameUIC" : "Avrée",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 691949,
            "codeCH" : 0,
            "nameUIC" : "Bidos",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 694000,
            "codeCH" : "BV",
            "nameUIC" : "Le Creusot",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LEU"
        },
        {
            "codeUIC" : 694000,
            "codeCH" : "CL",
            "nameUIC" : "Le Creusot",
            "nameCH" : "ITE Creusot-Loire",
            "code" : "LEU"
        },
        {
            "codeUIC" : 694000,
            "codeCH" : "C6",
            "nameUIC" : "Le Creusot",
            "nameCH" : "Carré 6",
            "code" : "LEU"
        },
        {
            "codeUIC" : 694000,
            "codeCH" : "EP",
            "nameUIC" : "Le Creusot",
            "nameCH" : "ITE Abattoirs",
            "code" : "LEU"
        },
        {
            "codeUIC" : 694109,
            "codeCH" : 0,
            "nameUIC" : "Le Creusot-Montceau-Montchanin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694133,
            "codeCH" : 0,
            "nameUIC" : "St-Didier-sur-Arroux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694141,
            "codeCH" : "ET",
            "nameUIC" : "Étang",
            "nameCH" : "Carré 1",
            "code" : ""
        },
        {
            "codeUIC" : 694141,
            "codeCH" : 0,
            "nameUIC" : "Étang",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694158,
            "codeCH" : 0,
            "nameUIC" : "Mesvres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694166,
            "codeCH" : "BV",
            "nameUIC" : "Broye",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 694174,
            "codeCH" : 0,
            "nameUIC" : "St-Symphorien-de-Marmagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694182,
            "codeCH" : "BV",
            "nameUIC" : "Marmagne-sous-Creusot",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MMS"
        },
        {
            "codeUIC" : 694224,
            "codeCH" : 0,
            "nameUIC" : "Villedieu-Chanliau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694307,
            "codeCH" : "BN",
            "nameUIC" : "Montchanin",
            "nameCH" : "Bifurcation Km 109,2/132,5",
            "code" : "MCH"
        },
        {
            "codeUIC" : 694307,
            "codeCH" : "BV",
            "nameUIC" : "Montchanin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MCH"
        },
        {
            "codeUIC" : 694307,
            "codeCH" : "C2",
            "nameUIC" : "Montchanin",
            "nameCH" : "Carré 202",
            "code" : "MCH"
        },
        {
            "codeUIC" : 694307,
            "codeCH" : "PA",
            "nameUIC" : "Montchanin",
            "nameCH" : "Pancarte Arrêt",
            "code" : "MCH"
        },
        {
            "codeUIC" : 694331,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-Écuisses",
            "nameCH" : "",
            "code" : "SJE"
        },
        {
            "codeUIC" : 694364,
            "codeCH" : "C1",
            "nameUIC" : "St-Léger-sur-Dheune",
            "nameCH" : "Carré 211",
            "code" : ""
        },
        {
            "codeUIC" : 694364,
            "codeCH" : "C2",
            "nameUIC" : "St-Léger-sur-Dheune",
            "nameCH" : "Carré 222",
            "code" : ""
        },
        {
            "codeUIC" : 694364,
            "codeCH" : "C3",
            "nameUIC" : "St-Léger-sur-Dheune",
            "nameCH" : "Carré 221",
            "code" : ""
        },
        {
            "codeUIC" : 694364,
            "codeCH" : "S2",
            "nameUIC" : "St-Léger-sur-Dheune",
            "nameCH" : "Sémaphore 212",
            "code" : ""
        },
        {
            "codeUIC" : 694364,
            "codeCH" : 0,
            "nameUIC" : "St-Léger-sur-Dheune",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694380,
            "codeCH" : "BV",
            "nameUIC" : "St-Gilles (Saône-et-Loire)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 694398,
            "codeCH" : 0,
            "nameUIC" : "Cheilly-lès-Maranges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694406,
            "codeCH" : 0,
            "nameUIC" : "Maison-Dieu (Yonne)",
            "nameCH" : "",
            "code" : "MAD"
        },
        {
            "codeUIC" : 694414,
            "codeCH" : 0,
            "nameUIC" : "Brécy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694422,
            "codeCH" : 0,
            "nameUIC" : "St-André-en-Terre-Plaine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694430,
            "codeCH" : 0,
            "nameUIC" : "Sincey-lès-Rouvray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694448,
            "codeCH" : 0,
            "nameUIC" : "La Roche-en-Brenil",
            "nameCH" : "",
            "code" : "LRE"
        },
        {
            "codeUIC" : 694455,
            "codeCH" : 0,
            "nameUIC" : "Molphey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694463,
            "codeCH" : 0,
            "nameUIC" : "St-Didier (Côte-d'Or)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694471,
            "codeCH" : 0,
            "nameUIC" : "Saulieu",
            "nameCH" : "",
            "code" : "SIU"
        },
        {
            "codeUIC" : 694489,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-de-la-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694497,
            "codeCH" : "BV",
            "nameUIC" : "Liernais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 694497,
            "codeCH" : "EP",
            "nameUIC" : "Liernais",
            "nameCH" : "ITE Mavil",
            "code" : ""
        },
        {
            "codeUIC" : 694513,
            "codeCH" : 0,
            "nameUIC" : "Brazey-en-Morvan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694521,
            "codeCH" : "BV",
            "nameUIC" : "Manlay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 694539,
            "codeCH" : 0,
            "nameUIC" : "Barnay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694547,
            "codeCH" : 0,
            "nameUIC" : "Cordesse-Igornay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694554,
            "codeCH" : "BV",
            "nameUIC" : "Dracy-St-Loup",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 694562,
            "codeCH" : 0,
            "nameUIC" : "L'Orme-St-Pantaléon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694570,
            "codeCH" : "BV",
            "nameUIC" : "Autun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AU"
        },
        {
            "codeUIC" : 694570,
            "codeCH" : "IS",
            "nameUIC" : "Autun",
            "nameCH" : "ITE Km 13,1",
            "code" : "AU"
        },
        {
            "codeUIC" : 694570,
            "codeCH" : "SF",
            "nameUIC" : "Autun",
            "nameCH" : "ITE Câbles de Lyon",
            "code" : "AU"
        },
        {
            "codeUIC" : 694596,
            "codeCH" : 0,
            "nameUIC" : "Brion-Laizy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694604,
            "codeCH" : "BV",
            "nameUIC" : "Blanzy-Poste",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BZY"
        },
        {
            "codeUIC" : 694604,
            "codeCH" : "PO",
            "nameUIC" : "Blanzy-Poste",
            "nameCH" : "Poste BV",
            "code" : "BZY"
        },
        {
            "codeUIC" : 694604,
            "codeCH" : "ZI",
            "nameUIC" : "Blanzy-Poste",
            "nameCH" : "ITE Zone Industrielle de Torcy",
            "code" : "BZY"
        },
        {
            "codeUIC" : 694612,
            "codeCH" : 0,
            "nameUIC" : "Blanzy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694620,
            "codeCH" : "BV",
            "nameUIC" : "Montceau-les-Mines",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MMI"
        },
        {
            "codeUIC" : 694620,
            "codeCH" : "FA",
            "nameUIC" : "Montceau-les-Mines",
            "nameCH" : "Faisceau",
            "code" : "MMI"
        },
        {
            "codeUIC" : 694620,
            "codeCH" : "ZI",
            "nameUIC" : "Montceau-les-Mines",
            "nameCH" : "ITE ZI de Montceau",
            "code" : "MMI"
        },
        {
            "codeUIC" : 694638,
            "codeCH" : 0,
            "nameUIC" : "Galuzot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694646,
            "codeCH" : 0,
            "nameUIC" : "Ciry-le-Noble",
            "nameCH" : "",
            "code" : "CYL"
        },
        {
            "codeUIC" : 694653,
            "codeCH" : 0,
            "nameUIC" : "Génelard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694661,
            "codeCH" : 0,
            "nameUIC" : "Palinges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694687,
            "codeCH" : "BV",
            "nameUIC" : "Paray-le-Monial",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PLM"
        },
        {
            "codeUIC" : 694687,
            "codeCH" : "ET",
            "nameUIC" : "Paray-le-Monial",
            "nameCH" : "ITE Société Eternit",
            "code" : "PLM"
        },
        {
            "codeUIC" : 694687,
            "codeCH" : "RO",
            "nameUIC" : "Paray-le-Monial",
            "nameCH" : "ITE ICPF",
            "code" : "PLM"
        },
        {
            "codeUIC" : 694695,
            "codeCH" : "AL",
            "nameUIC" : "Digoin",
            "nameCH" : "ITE Allia",
            "code" : "DIG"
        },
        {
            "codeUIC" : 694695,
            "codeCH" : "BV",
            "nameUIC" : "Digoin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DIG"
        },
        {
            "codeUIC" : 694695,
            "codeCH" : "EP",
            "nameUIC" : "Digoin",
            "nameCH" : "ITE Stock Génie",
            "code" : "DIG"
        },
        {
            "codeUIC" : 694703,
            "codeCH" : 0,
            "nameUIC" : "Lugny-lès-Charolles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694711,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-Changy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694729,
            "codeCH" : 0,
            "nameUIC" : "Dyo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694737,
            "codeCH" : 0,
            "nameUIC" : "La Clayette-Baudemont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694778,
            "codeCH" : 0,
            "nameUIC" : "Chauffailles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694786,
            "codeCH" : 0,
            "nameUIC" : "Belleroche-Belmont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694851,
            "codeCH" : 0,
            "nameUIC" : "St-Agnan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 694869,
            "codeCH" : "BV",
            "nameUIC" : "Gilly-sur-Loire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GSL"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "AE",
            "nameUIC" : "Nevers",
            "nameCH" : "Aiguille Km 1,3",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "AG",
            "nameUIC" : "Nevers",
            "nameCH" : "Aiguille Km 251,5",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "BC",
            "nameUIC" : "Nevers",
            "nameCH" : "Bifurcation de Chagny",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "BF",
            "nameUIC" : "Nevers",
            "nameCH" : "Bifurcation Km 253,3",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "BL",
            "nameUIC" : "Nevers",
            "nameCH" : "Bifurcation de Laroche",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "BV",
            "nameUIC" : "Nevers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "DP",
            "nameUIC" : "Nevers",
            "nameCH" : "Dépôt",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "ES",
            "nameUIC" : "Nevers",
            "nameCH" : "Entrée/Sortie Triage",
            "code" : "NV"
        },
        {
            "codeUIC" : 696005,
            "codeCH" : "TR",
            "nameUIC" : "Nevers",
            "nameCH" : "Triage",
            "code" : "NV"
        },
        {
            "codeUIC" : 696088,
            "codeCH" : 0,
            "nameUIC" : "Briare",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696104,
            "codeCH" : 0,
            "nameUIC" : "Châtillon-sur-Loire",
            "nameCH" : "",
            "code" : "COO"
        },
        {
            "codeUIC" : 696120,
            "codeCH" : 0,
            "nameUIC" : "Neuvy-sur-Loire",
            "nameCH" : "",
            "code" : "NEV"
        },
        {
            "codeUIC" : 696138,
            "codeCH" : 0,
            "nameUIC" : "Myennes",
            "nameCH" : "",
            "code" : "MSQ"
        },
        {
            "codeUIC" : 696146,
            "codeCH" : 0,
            "nameUIC" : "Cosne",
            "nameCH" : "",
            "code" : "COS"
        },
        {
            "codeUIC" : 696161,
            "codeCH" : 0,
            "nameUIC" : "Tracy-Sancerre",
            "nameCH" : "",
            "code" : "TAY"
        },
        {
            "codeUIC" : 696179,
            "codeCH" : 0,
            "nameUIC" : "Pouilly-sur-Loire",
            "nameCH" : "",
            "code" : "Psur-L-"
        },
        {
            "codeUIC" : 696187,
            "codeCH" : 0,
            "nameUIC" : "Mesves-Bulcy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696195,
            "codeCH" : "BV",
            "nameUIC" : "La Charité",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LCH"
        },
        {
            "codeUIC" : 696203,
            "codeCH" : 0,
            "nameUIC" : "Tronsanges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696211,
            "codeCH" : 0,
            "nameUIC" : "Pougues-les-Eaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696229,
            "codeCH" : "BV",
            "nameUIC" : "Fourchambault",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FLT"
        },
        {
            "codeUIC" : 696229,
            "codeCH" : "ER",
            "nameUIC" : "Fourchambault",
            "nameCH" : "ITE Ergm",
            "code" : "FLT"
        },
        {
            "codeUIC" : 696245,
            "codeCH" : 0,
            "nameUIC" : "Sampanges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696252,
            "codeCH" : 0,
            "nameUIC" : "ITE Total gaz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696260,
            "codeCH" : "AG",
            "nameUIC" : "Saincaize",
            "nameCH" : "Aiguille Km 264,3",
            "code" : "SZE"
        },
        {
            "codeUIC" : 696260,
            "codeCH" : "AS",
            "nameUIC" : "Saincaize",
            "nameCH" : "Aiguille Km 265,3",
            "code" : "SZE"
        },
        {
            "codeUIC" : 696260,
            "codeCH" : "BV",
            "nameUIC" : "Saincaize",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SZE"
        },
        {
            "codeUIC" : 696260,
            "codeCH" : "NV",
            "nameUIC" : "Saincaize",
            "nameCH" : "Bifurcation de Nevers",
            "code" : "SZE"
        },
        {
            "codeUIC" : 696260,
            "codeCH" : "TR",
            "nameUIC" : "Saincaize",
            "nameCH" : "Triage",
            "code" : "SZE"
        },
        {
            "codeUIC" : 696260,
            "codeCH" : "VZ",
            "nameUIC" : "Saincaize",
            "nameCH" : "Bifurcation de Vierzon",
            "code" : "SZE"
        },
        {
            "codeUIC" : 696260,
            "codeCH" : "V5",
            "nameUIC" : "Saincaize",
            "nameCH" : "Garage Voie 5",
            "code" : "SZE"
        },
        {
            "codeUIC" : 696278,
            "codeCH" : 0,
            "nameUIC" : "Le Guetin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696294,
            "codeCH" : 0,
            "nameUIC" : "St-Pierre-le-Moûtier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696302,
            "codeCH" : 0,
            "nameUIC" : "Chantenay-St-Imbert",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696310,
            "codeCH" : "BV",
            "nameUIC" : "Villeneuve-sur-Allier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 696310,
            "codeCH" : "PK",
            "nameUIC" : "Villeneuve-sur-Allier",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 696328,
            "codeCH" : "BF",
            "nameUIC" : "Moulins-sur-Allier",
            "nameCH" : "Aiguille Km 314,3",
            "code" : "MSA"
        },
        {
            "codeUIC" : 696328,
            "codeCH" : "BV",
            "nameUIC" : "Moulins-sur-Allier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MSA"
        },
        {
            "codeUIC" : 696328,
            "codeCH" : "FP",
            "nameUIC" : "Moulins-sur-Allier",
            "nameCH" : "Faisceau Pair",
            "code" : "MSA"
        },
        {
            "codeUIC" : 696336,
            "codeCH" : 0,
            "nameUIC" : "Bessay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696344,
            "codeCH" : "BV",
            "nameUIC" : "La Ferté-Hauterive",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LFH"
        },
        {
            "codeUIC" : 696344,
            "codeCH" : "IT",
            "nameUIC" : "La Ferté-Hauterive",
            "nameCH" : "ITE CSTO",
            "code" : "LFH"
        },
        {
            "codeUIC" : 696351,
            "codeCH" : "BV",
            "nameUIC" : "Varennes-sur-Allier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VAA"
        },
        {
            "codeUIC" : 696351,
            "codeCH" : "CA",
            "nameUIC" : "Varennes-sur-Allier",
            "nameCH" : "ITE C.A.B.L",
            "code" : "VAA"
        },
        {
            "codeUIC" : 696393,
            "codeCH" : "BV",
            "nameUIC" : "St-Pourçain-sur-Sioule",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SPS"
        },
        {
            "codeUIC" : 696393,
            "codeCH" : "EP",
            "nameUIC" : "St-Pourçain-sur-Sioule",
            "nameCH" : "ITE COOPAVAL",
            "code" : "SPS"
        },
        {
            "codeUIC" : 696393,
            "codeCH" : "IT",
            "nameUIC" : "St-Pourçain-sur-Sioule",
            "nameCH" : "ITE Km 341,3",
            "code" : "SPS"
        },
        {
            "codeUIC" : 696401,
            "codeCH" : 0,
            "nameUIC" : "Montbeugny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696419,
            "codeCH" : 0,
            "nameUIC" : "Thiel-sur-Acolin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696427,
            "codeCH" : "BV",
            "nameUIC" : "Dompierre-Sept-Fons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DOP"
        },
        {
            "codeUIC" : 696427,
            "codeCH" : "EP",
            "nameUIC" : "Dompierre-Sept-Fons",
            "nameCH" : "ITE Automobiles Peugeot",
            "code" : "DOP"
        },
        {
            "codeUIC" : 696435,
            "codeCH" : 0,
            "nameUIC" : "Diou (Allier)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696450,
            "codeCH" : "C6",
            "nameUIC" : "Imphy",
            "nameCH" : "Carré 16",
            "code" : "IPY"
        },
        {
            "codeUIC" : 696450,
            "codeCH" : 0,
            "nameUIC" : "Imphy",
            "nameCH" : "",
            "code" : "IPY"
        },
        {
            "codeUIC" : 696468,
            "codeCH" : "S1",
            "nameUIC" : "Béard",
            "nameCH" : "Sémaphore 21",
            "code" : ""
        },
        {
            "codeUIC" : 696468,
            "codeCH" : "S4",
            "nameUIC" : "Béard",
            "nameCH" : "Sémaphore 24",
            "code" : ""
        },
        {
            "codeUIC" : 696468,
            "codeCH" : 0,
            "nameUIC" : "Béard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696476,
            "codeCH" : "C5",
            "nameUIC" : "Sougy",
            "nameCH" : "Carré 25",
            "code" : ""
        },
        {
            "codeUIC" : 696476,
            "codeCH" : "S7",
            "nameUIC" : "Sougy",
            "nameCH" : "Sémaphore 27",
            "code" : ""
        },
        {
            "codeUIC" : 696476,
            "codeCH" : 0,
            "nameUIC" : "Sougy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696484,
            "codeCH" : "BV",
            "nameUIC" : "Decize",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DZE"
        },
        {
            "codeUIC" : 696484,
            "codeCH" : "DE",
            "nameUIC" : "Decize",
            "nameCH" : "Carrés 43-46",
            "code" : "DZE"
        },
        {
            "codeUIC" : 696492,
            "codeCH" : "BC",
            "nameUIC" : "Cercy-la-Tour",
            "nameCH" : "Bifurcation Km 52,6",
            "code" : "CLT"
        },
        {
            "codeUIC" : 696492,
            "codeCH" : "BV",
            "nameUIC" : "Cercy-la-Tour",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CLT"
        },
        {
            "codeUIC" : 696492,
            "codeCH" : "C0",
            "nameUIC" : "Cercy-la-Tour",
            "nameCH" : "Carrés 50",
            "code" : "CLT"
        },
        {
            "codeUIC" : 696492,
            "codeCH" : "C1",
            "nameUIC" : "Cercy-la-Tour",
            "nameCH" : "Carrés 51",
            "code" : "CLT"
        },
        {
            "codeUIC" : 696518,
            "codeCH" : "F0",
            "nameUIC" : "Fours",
            "nameCH" : "Carrés 61-64",
            "code" : ""
        },
        {
            "codeUIC" : 696518,
            "codeCH" : 0,
            "nameUIC" : "Fours",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696526,
            "codeCH" : 0,
            "nameUIC" : "Rémilly-St-Honoré-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696534,
            "codeCH" : "BV",
            "nameUIC" : "Luzy (Nièvre)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LUY"
        },
        {
            "codeUIC" : 696534,
            "codeCH" : "EP",
            "nameUIC" : "Luzy (Nièvre)",
            "nameCH" : "ITE Moulin-Neuf",
            "code" : "LUY"
        },
        {
            "codeUIC" : 696542,
            "codeCH" : 0,
            "nameUIC" : "Millay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696567,
            "codeCH" : "BV",
            "nameUIC" : "Moulins-Engilbert",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 696567,
            "codeCH" : "PN",
            "nameUIC" : "Moulins-Engilbert",
            "nameCH" : "PN 66",
            "code" : ""
        },
        {
            "codeUIC" : 696575,
            "codeCH" : "BV",
            "nameUIC" : "Tamnay-Châtillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TCH"
        },
        {
            "codeUIC" : 696591,
            "codeCH" : 0,
            "nameUIC" : "Epiry-Montreuillon",
            "nameCH" : "",
            "code" : "ELO"
        },
        {
            "codeUIC" : 696609,
            "codeCH" : "EP",
            "nameUIC" : "La Vauvelle-Garage",
            "nameCH" : "ITE Carrières",
            "code" : ""
        },
        {
            "codeUIC" : 696617,
            "codeCH" : "BV",
            "nameUIC" : "Corbigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CBY"
        },
        {
            "codeUIC" : 696617,
            "codeCH" : "PN",
            "nameUIC" : "Corbigny",
            "nameCH" : "PN 25",
            "code" : "CBY"
        },
        {
            "codeUIC" : 696633,
            "codeCH" : "BV",
            "nameUIC" : "Flez-Cuzy-Tannay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 696641,
            "codeCH" : 0,
            "nameUIC" : "Asnois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 696682,
            "codeCH" : "BV",
            "nameUIC" : "Château-Chinon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CCI"
        },
        {
            "codeUIC" : 696716,
            "codeCH" : "BV",
            "nameUIC" : "Guérigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GEG"
        },
        {
            "codeUIC" : 696716,
            "codeCH" : "EP",
            "nameUIC" : "Guérigny",
            "nameCH" : "ITE SCAN",
            "code" : "GEG"
        },
        {
            "codeUIC" : 696716,
            "codeCH" : "PN",
            "nameUIC" : "Guérigny",
            "nameCH" : "PN 115",
            "code" : "GEG"
        },
        {
            "codeUIC" : 696732,
            "codeCH" : "BV",
            "nameUIC" : "Prémery",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PEM"
        },
        {
            "codeUIC" : 696732,
            "codeCH" : "PN",
            "nameUIC" : "Prémery",
            "nameCH" : "PN 97",
            "code" : "PEM"
        },
        {
            "codeUIC" : 696740,
            "codeCH" : "EP",
            "nameUIC" : "Arzembouy",
            "nameCH" : "ITE Seragri",
            "code" : "ARZ"
        },
        {
            "codeUIC" : 696740,
            "codeCH" : 0,
            "nameUIC" : "Arzembouy",
            "nameCH" : "",
            "code" : "ARZ"
        },
        {
            "codeUIC" : 696807,
            "codeCH" : "AG",
            "nameUIC" : "Clamecy",
            "nameCH" : "Aiguille Km 229,023",
            "code" : "CLA"
        },
        {
            "codeUIC" : 696807,
            "codeCH" : "BV",
            "nameUIC" : "Clamecy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CLA"
        },
        {
            "codeUIC" : 696807,
            "codeCH" : "EP",
            "nameUIC" : "Clamecy",
            "nameCH" : "ITE COVYNO",
            "code" : "CLA"
        },
        {
            "codeUIC" : 696807,
            "codeCH" : "PN",
            "nameUIC" : "Clamecy",
            "nameCH" : "PN 4",
            "code" : "CLA"
        },
        {
            "codeUIC" : 696807,
            "codeCH" : "P2",
            "nameUIC" : "Clamecy",
            "nameCH" : "Poste 2",
            "code" : "CLA"
        },
        {
            "codeUIC" : 696831,
            "codeCH" : 0,
            "nameUIC" : "Coulanges-sur-Yonne",
            "nameCH" : "",
            "code" : "CAO"
        },
        {
            "codeUIC" : 696856,
            "codeCH" : 0,
            "nameUIC" : "Châtel-Censoir",
            "nameCH" : "",
            "code" : "CAL"
        },
        {
            "codeUIC" : 696880,
            "codeCH" : "BV",
            "nameUIC" : "Étais (GS)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ETA"
        },
        {
            "codeUIC" : 696880,
            "codeCH" : "EP",
            "nameUIC" : "Étais (GS)",
            "nameCH" : "ITE Coopérative de céréales",
            "code" : "ETA"
        },
        {
            "codeUIC" : 696880,
            "codeCH" : "PN",
            "nameUIC" : "Étais (GS)",
            "nameCH" : "PN 13",
            "code" : "ETA"
        },
        {
            "codeUIC" : 696880,
            "codeCH" : "PV",
            "nameUIC" : "Étais (GS)",
            "nameCH" : "PN 11",
            "code" : "ETA"
        },
        {
            "codeUIC" : 696880,
            "codeCH" : "UR",
            "nameUIC" : "Étais (GS)",
            "nameCH" : "ITE Urago S.A.",
            "code" : "ETA"
        },
        {
            "codeUIC" : 696898,
            "codeCH" : "BV",
            "nameUIC" : "Entrains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ETS"
        },
        {
            "codeUIC" : 696898,
            "codeCH" : "CO",
            "nameUIC" : "Entrains",
            "nameCH" : "ITE COVINO",
            "code" : "ETS"
        },
        {
            "codeUIC" : 697292,
            "codeCH" : 0,
            "nameUIC" : "Épinay-sur-Seine TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 697300,
            "codeCH" : 0,
            "nameUIC" : "Épinay-Villetaneuse TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 697318,
            "codeCH" : 0,
            "nameUIC" : "Villetaneuse-Université TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 697326,
            "codeCH" : 0,
            "nameUIC" : "Pierrefite-Stains TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 697334,
            "codeCH" : 0,
            "nameUIC" : "Stains-Cerisaie TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 697342,
            "codeCH" : 0,
            "nameUIC" : "Dugny-La Courneuve TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 697359,
            "codeCH" : 0,
            "nameUIC" : "Le Bourget TT",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 699223,
            "codeCH" : 0,
            "nameUIC" : "Base Aérienne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 700146,
            "codeCH" : 0,
            "nameUIC" : "Danjoutin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 700153,
            "codeCH" : 0,
            "nameUIC" : "Joncherey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 701532,
            "codeCH" : 0,
            "nameUIC" : "Trélaze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 703975,
            "codeCH" : "BV",
            "nameUIC" : "NIMES PONT-DU-GARD",
            "nameCH" : "Gare Nîmes Manduel Redessan",
            "code" : ""
        },
        {
            "codeUIC" : 710723,
            "codeCH" : 0,
            "nameUIC" : "Portes-de-Vesoul",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 710731,
            "codeCH" : 0,
            "nameUIC" : "École-Valentin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 710749,
            "codeCH" : 0,
            "nameUIC" : "Poste 87 Moncley",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 710756,
            "codeCH" : 0,
            "nameUIC" : "Poste 92 Sorans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712018,
            "codeCH" : 0,
            "nameUIC" : "Serrigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712026,
            "codeCH" : 0,
            "nameUIC" : "Rully",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712182,
            "codeCH" : "BV",
            "nameUIC" : "Neuilly-lès-Dijon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 712182,
            "codeCH" : "EP",
            "nameUIC" : "Neuilly-lès-Dijon",
            "nameCH" : "ITE Zone Industrielle Chevigny-St-Sauveur",
            "code" : ""
        },
        {
            "codeUIC" : 712265,
            "codeCH" : 0,
            "nameUIC" : "Bretigny-Norges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712323,
            "codeCH" : "PV",
            "nameUIC" : "Lantenay",
            "nameCH" : "Point de changement de voie",
            "code" : ""
        },
        {
            "codeUIC" : 712323,
            "codeCH" : 0,
            "nameUIC" : "Lantenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712331,
            "codeCH" : 0,
            "nameUIC" : "Velars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712414,
            "codeCH" : 0,
            "nameUIC" : "ITE ZI Rochefort-sur-Nenon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712422,
            "codeCH" : 0,
            "nameUIC" : "Grand-Contour",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712521,
            "codeCH" : "PA",
            "nameUIC" : "Chemin-Peseux",
            "nameCH" : "Pancarte Arrêt",
            "code" : "CNX"
        },
        {
            "codeUIC" : 712588,
            "codeCH" : 0,
            "nameUIC" : "Port-Sec-de-Pacy",
            "nameCH" : "",
            "code" : "POR"
        },
        {
            "codeUIC" : 712604,
            "codeCH" : 0,
            "nameUIC" : "Ouges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712620,
            "codeCH" : 0,
            "nameUIC" : "Longecourt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 712679,
            "codeCH" : 0,
            "nameUIC" : "Chaugey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713008,
            "codeCH" : "BV",
            "nameUIC" : "Dijon-Porte-Neuve",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DPN"
        },
        {
            "codeUIC" : 713008,
            "codeCH" : "PG",
            "nameUIC" : "Dijon-Porte-Neuve",
            "nameCH" : "Poste",
            "code" : "DPN"
        },
        {
            "codeUIC" : 713008,
            "codeCH" : "V4",
            "nameUIC" : "Dijon-Porte-Neuve",
            "nameCH" : "Voie 4",
            "code" : "DPN"
        },
        {
            "codeUIC" : 713008,
            "codeCH" : 47,
            "nameUIC" : "Dijon-Porte-Neuve",
            "nameCH" : "Carré 47",
            "code" : "DPN"
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "FD",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Faisceau Départ",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "FR",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "PM",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Plateforme Multimodale",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "P1",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "P2",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "P3",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "P4",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Poste 4",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "RI",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Relais Impair",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "RO",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Relais Pair",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "RP",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Voies 12 à 19",
            "code" : ""
        },
        {
            "codeUIC" : 713032,
            "codeCH" : "TR",
            "nameUIC" : "Gevrey-Triage",
            "nameCH" : "Voies 44 à 56",
            "code" : ""
        },
        {
            "codeUIC" : 713040,
            "codeCH" : "AN",
            "nameUIC" : "Dijon-Ville",
            "nameCH" : "Aiguilles Nord",
            "code" : "DN"
        },
        {
            "codeUIC" : 713040,
            "codeCH" : "AS",
            "nameUIC" : "Dijon-Ville",
            "nameCH" : "Aiguilles Sud",
            "code" : "DN"
        },
        {
            "codeUIC" : 713040,
            "codeCH" : "BV",
            "nameUIC" : "Dijon-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DN"
        },
        {
            "codeUIC" : 713057,
            "codeCH" : "PB",
            "nameUIC" : "Plombières",
            "nameCH" : "Evitements de Plombières",
            "code" : ""
        },
        {
            "codeUIC" : 713057,
            "codeCH" : "PV",
            "nameUIC" : "Plombières",
            "nameCH" : "Point de changement de voie",
            "code" : ""
        },
        {
            "codeUIC" : 713065,
            "codeCH" : "BV",
            "nameUIC" : "Mâlain",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 713065,
            "codeCH" : "PV",
            "nameUIC" : "Mâlain",
            "nameCH" : "Point de changement de voie",
            "code" : ""
        },
        {
            "codeUIC" : 713073,
            "codeCH" : 0,
            "nameUIC" : "Blaisy-Bas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713081,
            "codeCH" : 0,
            "nameUIC" : "Verrey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713099,
            "codeCH" : 0,
            "nameUIC" : "Thenissey",
            "nameCH" : "",
            "code" : "REI"
        },
        {
            "codeUIC" : 713107,
            "codeCH" : 0,
            "nameUIC" : "Darcey (Côte-d'Or)",
            "nameCH" : "",
            "code" : "DAR"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "AN",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Bifurcation Km 256,1",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "AS",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Bifurcation Km 259",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "BN",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Bifurcation Km 256,4",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "BS",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Bifurcation Km 258,7",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "BV",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "FE",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Faisceau Escale",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "FI",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Faisceau Impair",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "NO",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Côté Nord Ouest",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : "SE",
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "Côté Sud-Est",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : 24,
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "C2462/2464",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713115,
            "codeCH" : 80,
            "nameUIC" : "Les Laumes-Alésia",
            "nameCH" : "PN 80",
            "code" : "LLA"
        },
        {
            "codeUIC" : 713131,
            "codeCH" : "BV",
            "nameUIC" : "Montbard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MBA"
        },
        {
            "codeUIC" : 713131,
            "codeCH" : "EV",
            "nameUIC" : "Montbard",
            "nameCH" : "Évitement de Nogent",
            "code" : "MBA"
        },
        {
            "codeUIC" : 713131,
            "codeCH" : 22,
            "nameUIC" : "Montbard",
            "nameCH" : "",
            "code" : "MBA"
        },
        {
            "codeUIC" : 713149,
            "codeCH" : "BV",
            "nameUIC" : "Aisy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 713149,
            "codeCH" : "EP",
            "nameUIC" : "Aisy",
            "nameCH" : "Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 713149,
            "codeCH" : "GV",
            "nameUIC" : "Aisy",
            "nameCH" : "Raccordement LGV",
            "code" : ""
        },
        {
            "codeUIC" : 713156,
            "codeCH" : 0,
            "nameUIC" : "Nuits-sous-Ravières",
            "nameCH" : "",
            "code" : "NUR"
        },
        {
            "codeUIC" : 713164,
            "codeCH" : 0,
            "nameUIC" : "Ancy-le-Franc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713172,
            "codeCH" : 0,
            "nameUIC" : "Lézinnes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713180,
            "codeCH" : 0,
            "nameUIC" : "Tanlay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713198,
            "codeCH" : "BV",
            "nameUIC" : "Tonnerre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 713198,
            "codeCH" : "VA",
            "nameUIC" : "Tonnerre",
            "nameCH" : "Voie A",
            "code" : ""
        },
        {
            "codeUIC" : 713198,
            "codeCH" : "VB",
            "nameUIC" : "Tonnerre",
            "nameCH" : "Voie B",
            "code" : ""
        },
        {
            "codeUIC" : 713206,
            "codeCH" : 0,
            "nameUIC" : "Flogny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713263,
            "codeCH" : 0,
            "nameUIC" : "Semur-en-Auxois",
            "nameCH" : "",
            "code" : "SRX"
        },
        {
            "codeUIC" : 713271,
            "codeCH" : "BV",
            "nameUIC" : "Époisses",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EPO"
        },
        {
            "codeUIC" : 713271,
            "codeCH" : 51,
            "nameUIC" : "Époisses",
            "nameCH" : "PN 51",
            "code" : "EPO"
        },
        {
            "codeUIC" : 713271,
            "codeCH" : 52,
            "nameUIC" : "Époisses",
            "nameCH" : "PN 52",
            "code" : "EPO"
        },
        {
            "codeUIC" : 713313,
            "codeCH" : "BV",
            "nameUIC" : "Genlis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GLS"
        },
        {
            "codeUIC" : 713313,
            "codeCH" : "E1",
            "nameUIC" : "Genlis",
            "nameCH" : "Évitement V1",
            "code" : "GLS"
        },
        {
            "codeUIC" : 713313,
            "codeCH" : "E2",
            "nameUIC" : "Genlis",
            "nameCH" : "Évitement V2",
            "code" : "GLS"
        },
        {
            "codeUIC" : 713313,
            "codeCH" : "PV",
            "nameUIC" : "Genlis",
            "nameCH" : "Point Changement Voie",
            "code" : "GLS"
        },
        {
            "codeUIC" : 713321,
            "codeCH" : 0,
            "nameUIC" : "Collonges (Côte-d'Or)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713339,
            "codeCH" : "RV",
            "nameUIC" : "Villers-les-Pots",
            "nameCH" : "Raccordement LGV",
            "code" : ""
        },
        {
            "codeUIC" : 713339,
            "codeCH" : 0,
            "nameUIC" : "Villers-les-Pots",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713347,
            "codeCH" : 0,
            "nameUIC" : "Auxonne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713354,
            "codeCH" : 0,
            "nameUIC" : "Villers-Rotin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713370,
            "codeCH" : 0,
            "nameUIC" : "Lamarche-sur-Saône",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713388,
            "codeCH" : 0,
            "nameUIC" : "Pontailler-sur-Saône",
            "nameCH" : "",
            "code" : "PTS"
        },
        {
            "codeUIC" : 713404,
            "codeCH" : 0,
            "nameUIC" : "Champvans-les-Dole",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713412,
            "codeCH" : "BI",
            "nameUIC" : "Dole-Ville",
            "nameCH" : "Bifurcation Km 358,9",
            "code" : "DLE"
        },
        {
            "codeUIC" : 713412,
            "codeCH" : "BV",
            "nameUIC" : "Dole-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "DLE"
        },
        {
            "codeUIC" : 713412,
            "codeCH" : "EP",
            "nameUIC" : "Dole-Ville",
            "nameCH" : "ITE Ciments",
            "code" : "DLE"
        },
        {
            "codeUIC" : 713412,
            "codeCH" : "P1",
            "nameUIC" : "Dole-Ville",
            "nameCH" : "Poste 1",
            "code" : "DLE"
        },
        {
            "codeUIC" : 713412,
            "codeCH" : "P2",
            "nameUIC" : "Dole-Ville",
            "nameCH" : "Poste 2",
            "code" : "DLE"
        },
        {
            "codeUIC" : 713412,
            "codeCH" : "TR",
            "nameUIC" : "Dole-Ville",
            "nameCH" : "Triage Marchandises",
            "code" : "DLE"
        },
        {
            "codeUIC" : 713487,
            "codeCH" : 0,
            "nameUIC" : "Tavaux-Solvay",
            "nameCH" : "ITE Solvay",
            "code" : "TAV"
        },
        {
            "codeUIC" : 713495,
            "codeCH" : 0,
            "nameUIC" : "Tavaux",
            "nameCH" : "",
            "code" : "TAV"
        },
        {
            "codeUIC" : 713503,
            "codeCH" : 0,
            "nameUIC" : "Gevrey-Chambertin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713511,
            "codeCH" : "BV",
            "nameUIC" : "Vougeot-Gilly-lès-Cîteaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 713529,
            "codeCH" : "V9",
            "nameUIC" : "Nuits-St-Georges",
            "nameCH" : "Voie 9 Garage",
            "code" : "NUI"
        },
        {
            "codeUIC" : 713529,
            "codeCH" : 0,
            "nameUIC" : "Nuits-St-Georges",
            "nameCH" : "",
            "code" : "NUI"
        },
        {
            "codeUIC" : 713529,
            "codeCH" : 12,
            "nameUIC" : "Nuits-St-Georges",
            "nameCH" : "Voie 12 Garage",
            "code" : "NUI"
        },
        {
            "codeUIC" : 713537,
            "codeCH" : 0,
            "nameUIC" : "Corgoloin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713545,
            "codeCH" : "EN",
            "nameUIC" : "Beaune",
            "nameCH" : "Entrée Nord",
            "code" : "BEA"
        },
        {
            "codeUIC" : 713545,
            "codeCH" : 0,
            "nameUIC" : "Beaune",
            "nameCH" : "",
            "code" : "BEA"
        },
        {
            "codeUIC" : 713560,
            "codeCH" : 0,
            "nameUIC" : "Meursault",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713578,
            "codeCH" : "BN",
            "nameUIC" : "Chagny",
            "nameCH" : "Bifurcation Nord",
            "code" : ""
        },
        {
            "codeUIC" : 713578,
            "codeCH" : "BS",
            "nameUIC" : "Chagny",
            "nameCH" : "Bifurcation Sud",
            "code" : ""
        },
        {
            "codeUIC" : 713578,
            "codeCH" : "BV",
            "nameUIC" : "Chagny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 713578,
            "codeCH" : "EN",
            "nameUIC" : "Chagny",
            "nameCH" : "Entrée Nord",
            "code" : ""
        },
        {
            "codeUIC" : 713578,
            "codeCH" : "RN",
            "nameUIC" : "Chagny",
            "nameCH" : "Km 2,9 Faisceau",
            "code" : ""
        },
        {
            "codeUIC" : 713578,
            "codeCH" : "RS",
            "nameUIC" : "Chagny",
            "nameCH" : "Bifurcation Km 160,8",
            "code" : ""
        },
        {
            "codeUIC" : 713578,
            "codeCH" : "3E",
            "nameUIC" : "Chagny",
            "nameCH" : "Voie 3 Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 713586,
            "codeCH" : 0,
            "nameUIC" : "Fontaines-Mercurey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713594,
            "codeCH" : "BE",
            "nameUIC" : "Santenay-les-Bains",
            "nameCH" : "Bifurcation Km 157,7",
            "code" : ""
        },
        {
            "codeUIC" : 713594,
            "codeCH" : 0,
            "nameUIC" : "Santenay-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713628,
            "codeCH" : "V3",
            "nameUIC" : "Saulon",
            "nameCH" : "Voie 3 Garage",
            "code" : "SLN"
        },
        {
            "codeUIC" : 713628,
            "codeCH" : 0,
            "nameUIC" : "Saulon",
            "nameCH" : "",
            "code" : "SLN"
        },
        {
            "codeUIC" : 713636,
            "codeCH" : 0,
            "nameUIC" : "Aiserey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713644,
            "codeCH" : 0,
            "nameUIC" : "Brazey-en-Plaine",
            "nameCH" : "",
            "code" : "BEP"
        },
        {
            "codeUIC" : 713651,
            "codeCH" : "BV",
            "nameUIC" : "St-Jean-de-Losne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SJO"
        },
        {
            "codeUIC" : 713651,
            "codeCH" : "V3",
            "nameUIC" : "St-Jean-de-Losne",
            "nameCH" : "Voie 3 Garage",
            "code" : "SJO"
        },
        {
            "codeUIC" : 713651,
            "codeCH" : "V4",
            "nameUIC" : "St-Jean-de-Losne",
            "nameCH" : "Voie 4 Garage",
            "code" : "SJO"
        },
        {
            "codeUIC" : 713669,
            "codeCH" : 0,
            "nameUIC" : "Pagny (Côte-d'Or)",
            "nameCH" : "",
            "code" : "PAG"
        },
        {
            "codeUIC" : 713677,
            "codeCH" : 0,
            "nameUIC" : "Seurre",
            "nameCH" : "",
            "code" : "SEU"
        },
        {
            "codeUIC" : 713685,
            "codeCH" : 0,
            "nameUIC" : "Navilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713693,
            "codeCH" : 0,
            "nameUIC" : "St-Bonnet-en-Bresse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713701,
            "codeCH" : "BV",
            "nameUIC" : "Mervans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 713701,
            "codeCH" : "1B",
            "nameUIC" : "Mervans",
            "nameCH" : "Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 713701,
            "codeCH" : "2B",
            "nameUIC" : "Mervans",
            "nameCH" : "Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 713719,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-du-Bois-Devrouze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713727,
            "codeCH" : 0,
            "nameUIC" : "Simard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713735,
            "codeCH" : "BV",
            "nameUIC" : "Louhans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 713735,
            "codeCH" : "EP",
            "nameUIC" : "Louhans",
            "nameCH" : "ITE Zone industrielle de Louhans",
            "code" : ""
        },
        {
            "codeUIC" : 713743,
            "codeCH" : 0,
            "nameUIC" : "Ste-Croix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713750,
            "codeCH" : 0,
            "nameUIC" : "Frontenaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713768,
            "codeCH" : 0,
            "nameUIC" : "Dommartin-lès-Cuiseaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713826,
            "codeCH" : 0,
            "nameUIC" : "Ruffey-lès-Echirey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713834,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-Clénay",
            "nameCH" : "",
            "code" : "SJE"
        },
        {
            "codeUIC" : 713842,
            "codeCH" : 0,
            "nameUIC" : "Gemeaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 713859,
            "codeCH" : "V3",
            "nameUIC" : "Is-sur-Tille",
            "nameCH" : "Voie 3 Garage",
            "code" : "IST"
        },
        {
            "codeUIC" : 713859,
            "codeCH" : 0,
            "nameUIC" : "Is-sur-Tille",
            "nameCH" : "",
            "code" : "IST"
        },
        {
            "codeUIC" : 713867,
            "codeCH" : "ZI",
            "nameUIC" : "Dijon-Porte-d'Ouche-Perrigny",
            "nameCH" : "ITE Zone Industrielle Dijon Sud",
            "code" : "DDP"
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "AT",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Atelier TER",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "BE",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Bifurcation Km 318,2",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "BK",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Bif Km 316,2",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "CH",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "ITE ZI Chenove",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "DP",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Dépôt",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "EM",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Faisceau Embranchement",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "ES",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Entrée/Sortie",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "FE",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "IS",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Bifurcation Km 318,9",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "LO",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Bifurcation Km 1,5/0,0",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "NT",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Quai Navette",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "PA",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Point d'arrêt Km 317,770",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "PK",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Carré 78",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "PS",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Passage Km 316,776",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "PV",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Point de Changement de voie",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "P2",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "P3",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "RA",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Faisceau Relais",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "RE",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Aiguille Km 0,6",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "RI",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Bifurcation Km 1,1",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "RL",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Raccordement Lyon",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "RO",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Faisceau Escale",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "RP",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Bifurcation Km 320",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "RT",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Raccordement TGV",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "TR",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : 13,
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Carré Violet 13",
            "code" : ""
        },
        {
            "codeUIC" : 713875,
            "codeCH" : "2B",
            "nameUIC" : "Perrigny-Triage",
            "nameCH" : "Bifurcation Km 317,3",
            "code" : ""
        },
        {
            "codeUIC" : 715003,
            "codeCH" : "BV",
            "nameUIC" : "Pontarlier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PTL"
        },
        {
            "codeUIC" : 715003,
            "codeCH" : "XS",
            "nameUIC" : "Pontarlier",
            "nameCH" : "Frontière France-Suisse",
            "code" : "PTL"
        },
        {
            "codeUIC" : 715102,
            "codeCH" : "BV",
            "nameUIC" : "Andelot (Jura)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AND"
        },
        {
            "codeUIC" : 715110,
            "codeCH" : 0,
            "nameUIC" : "La Joux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715128,
            "codeCH" : 0,
            "nameUIC" : "Boujailles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715136,
            "codeCH" : "BV",
            "nameUIC" : "Frasne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 715136,
            "codeCH" : "FP",
            "nameUIC" : "Frasne",
            "nameCH" : "Faisceau Pair",
            "code" : ""
        },
        {
            "codeUIC" : 715144,
            "codeCH" : 0,
            "nameUIC" : "La Rivière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715151,
            "codeCH" : 0,
            "nameUIC" : "Ste-Colombe (Doubs)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715169,
            "codeCH" : 0,
            "nameUIC" : "Les Granges-Narboz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715201,
            "codeCH" : 0,
            "nameUIC" : "Vaux-et-Chantegrue",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715219,
            "codeCH" : 0,
            "nameUIC" : "Labergement-Ste-Marie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715227,
            "codeCH" : "XS",
            "nameUIC" : "Les Longevilles-Rochejean",
            "nameCH" : "Frontière France-Suisse",
            "code" : ""
        },
        {
            "codeUIC" : 715227,
            "codeCH" : 0,
            "nameUIC" : "Les Longevilles-Rochejean",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715300,
            "codeCH" : 0,
            "nameUIC" : "Vers-en-Montagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715326,
            "codeCH" : "BV",
            "nameUIC" : "Champagnole",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CPG"
        },
        {
            "codeUIC" : 715367,
            "codeCH" : 0,
            "nameUIC" : "Syam",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715375,
            "codeCH" : 0,
            "nameUIC" : "Le Vaudioux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715383,
            "codeCH" : "BV",
            "nameUIC" : "La Chaux-des-Crotenay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 715391,
            "codeCH" : 0,
            "nameUIC" : "La Chaumusse-Fort-du-Plasne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715417,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent (Jura)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715433,
            "codeCH" : 0,
            "nameUIC" : "Morbier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715441,
            "codeCH" : 0,
            "nameUIC" : "Morez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 715714,
            "codeCH" : "CV",
            "nameUIC" : "La Mine de Longemaison",
            "nameCH" : "Classe Verte",
            "code" : ""
        },
        {
            "codeUIC" : 715938,
            "codeCH" : "WS",
            "nameUIC" : "Vallorbe",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 718007,
            "codeCH" : "AG",
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "Aiguille Km 403,2",
            "code" : "BN"
        },
        {
            "codeUIC" : 718007,
            "codeCH" : "BM",
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "Bifurcation Km 407,3",
            "code" : "BN"
        },
        {
            "codeUIC" : 718007,
            "codeCH" : "BV",
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BN"
        },
        {
            "codeUIC" : 718007,
            "codeCH" : "FI",
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "March F Impair",
            "code" : "BN"
        },
        {
            "codeUIC" : 718007,
            "codeCH" : "FP",
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "March F Pair",
            "code" : "BN"
        },
        {
            "codeUIC" : 718007,
            "codeCH" : "P1",
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "Poste 1",
            "code" : "BN"
        },
        {
            "codeUIC" : 718007,
            "codeCH" : "P2",
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "Poste 2",
            "code" : "BN"
        },
        {
            "codeUIC" : 718007,
            "codeCH" : 43,
            "nameUIC" : "Besançon-Viotte",
            "nameCH" : "Carré Violet 43",
            "code" : "BN"
        },
        {
            "codeUIC" : 718015,
            "codeCH" : 0,
            "nameUIC" : "Besançon-Mouillère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718049,
            "codeCH" : "EP",
            "nameUIC" : "Miserey-Salines",
            "nameCH" : "ITE Halle Fret",
            "code" : "MIS"
        },
        {
            "codeUIC" : 718049,
            "codeCH" : 0,
            "nameUIC" : "Miserey-Salines",
            "nameCH" : "",
            "code" : "MIS"
        },
        {
            "codeUIC" : 718056,
            "codeCH" : 0,
            "nameUIC" : "Devecey",
            "nameCH" : "",
            "code" : "DVY"
        },
        {
            "codeUIC" : 718106,
            "codeCH" : 0,
            "nameUIC" : "Montferrand-Thoraise",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718114,
            "codeCH" : 0,
            "nameUIC" : "Torpes-Boussières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718122,
            "codeCH" : 0,
            "nameUIC" : "Byans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718130,
            "codeCH" : 0,
            "nameUIC" : "Liesle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718155,
            "codeCH" : "BV",
            "nameUIC" : "Arbois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 718155,
            "codeCH" : "EP",
            "nameUIC" : "Arbois",
            "nameCH" : "ITE Henry Maire",
            "code" : ""
        },
        {
            "codeUIC" : 718171,
            "codeCH" : 0,
            "nameUIC" : "Grozon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718189,
            "codeCH" : 0,
            "nameUIC" : "Poligny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718197,
            "codeCH" : 0,
            "nameUIC" : "St-Lothain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718205,
            "codeCH" : "BV",
            "nameUIC" : "Passenans",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 718213,
            "codeCH" : 0,
            "nameUIC" : "Domblans-Voiteur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718221,
            "codeCH" : 0,
            "nameUIC" : "Montain-Lavigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718239,
            "codeCH" : 0,
            "nameUIC" : "Lons-le-Saunier",
            "nameCH" : "",
            "code" : "LLS"
        },
        {
            "codeUIC" : 718270,
            "codeCH" : 0,
            "nameUIC" : "Cousance",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718270,
            "codeCH" : 32,
            "nameUIC" : "Cousance",
            "nameCH" : "Sémaphore 632",
            "code" : ""
        },
        {
            "codeUIC" : 718270,
            "codeCH" : 35,
            "nameUIC" : "Cousance",
            "nameCH" : "Carré 635",
            "code" : ""
        },
        {
            "codeUIC" : 718288,
            "codeCH" : 0,
            "nameUIC" : "Cuiseaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718296,
            "codeCH" : "BE",
            "nameUIC" : "St-Amour",
            "nameCH" : "1Bis Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 718296,
            "codeCH" : "BF",
            "nameUIC" : "St-Amour",
            "nameCH" : "Bifurcation Km 424,6/473,8",
            "code" : ""
        },
        {
            "codeUIC" : 718296,
            "codeCH" : "BV",
            "nameUIC" : "St-Amour",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 718296,
            "codeCH" : "C2",
            "nameUIC" : "St-Amour",
            "nameCH" : "Carré 712",
            "code" : ""
        },
        {
            "codeUIC" : 718304,
            "codeCH" : "BB",
            "nameUIC" : "Franois",
            "nameCH" : "Bifurcation Km 398,4",
            "code" : "FOI"
        },
        {
            "codeUIC" : 718304,
            "codeCH" : "BV",
            "nameUIC" : "Franois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FOI"
        },
        {
            "codeUIC" : 718312,
            "codeCH" : 0,
            "nameUIC" : "Dannemarie-Velesmes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718320,
            "codeCH" : "BV",
            "nameUIC" : "St-Vit",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SVI"
        },
        {
            "codeUIC" : 718320,
            "codeCH" : "EU",
            "nameUIC" : "St-Vit",
            "nameCH" : "ITE Unico",
            "code" : "SVI"
        },
        {
            "codeUIC" : 718320,
            "codeCH" : "E1",
            "nameUIC" : "St-Vit",
            "nameCH" : "Évitement V1",
            "code" : "SVI"
        },
        {
            "codeUIC" : 718320,
            "codeCH" : "E2",
            "nameUIC" : "St-Vit",
            "nameCH" : "Évitement V2",
            "code" : "SVI"
        },
        {
            "codeUIC" : 718338,
            "codeCH" : 0,
            "nameUIC" : "Ranchot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718353,
            "codeCH" : 0,
            "nameUIC" : "Labarre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718361,
            "codeCH" : 0,
            "nameUIC" : "Orchamps (Jura)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718387,
            "codeCH" : 0,
            "nameUIC" : "Moulin-Rouge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718395,
            "codeCH" : 0,
            "nameUIC" : "Rochefort-sur-Nenon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718403,
            "codeCH" : "V8",
            "nameUIC" : "Roche-lez-Beaupré",
            "nameCH" : "Voie 8 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 718403,
            "codeCH" : 0,
            "nameUIC" : "Roche-lez-Beaupré",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718411,
            "codeCH" : 0,
            "nameUIC" : "Novillars",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718429,
            "codeCH" : 0,
            "nameUIC" : "Deluz",
            "nameCH" : "",
            "code" : "DLZ"
        },
        {
            "codeUIC" : 718437,
            "codeCH" : 0,
            "nameUIC" : "Laissey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718445,
            "codeCH" : 0,
            "nameUIC" : "Douvot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718452,
            "codeCH" : 0,
            "nameUIC" : "Ougney-Douvot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718460,
            "codeCH" : 0,
            "nameUIC" : "Baume-les-Dames",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718478,
            "codeCH" : 0,
            "nameUIC" : "Hyèvre-Paroisse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718502,
            "codeCH" : 0,
            "nameUIC" : "Saône",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718510,
            "codeCH" : 0,
            "nameUIC" : "Mamirolle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718528,
            "codeCH" : 0,
            "nameUIC" : "L'Hôpital-du-Grosbois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718536,
            "codeCH" : 0,
            "nameUIC" : "Étalans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718544,
            "codeCH" : "BV",
            "nameUIC" : "Le Valdahon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LEV"
        },
        {
            "codeUIC" : 718551,
            "codeCH" : 0,
            "nameUIC" : "Avoudrey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718569,
            "codeCH" : 0,
            "nameUIC" : "Longemaison",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718577,
            "codeCH" : 0,
            "nameUIC" : "Gilley",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718593,
            "codeCH" : 0,
            "nameUIC" : "Grand'Combe-Châteleu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718601,
            "codeCH" : 0,
            "nameUIC" : "Le Valdahon-Camp-militaire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718619,
            "codeCH" : "XS",
            "nameUIC" : "Morteau",
            "nameCH" : "Frontière France-Suisse",
            "code" : ""
        },
        {
            "codeUIC" : 718619,
            "codeCH" : 0,
            "nameUIC" : "Morteau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718833,
            "codeCH" : "CV",
            "nameUIC" : "Mouchard",
            "nameCH" : "Carré Violet 10",
            "code" : ""
        },
        {
            "codeUIC" : 718833,
            "codeCH" : 0,
            "nameUIC" : "Mouchard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718841,
            "codeCH" : 0,
            "nameUIC" : "Arc-et-Senans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718858,
            "codeCH" : 0,
            "nameUIC" : "Chatelay-Chissey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718866,
            "codeCH" : 0,
            "nameUIC" : "Montbarrey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718874,
            "codeCH" : 0,
            "nameUIC" : "Mesnay-Arbois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 718882,
            "codeCH" : 0,
            "nameUIC" : "Pont-d'Héry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "AN",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Arrivée Nord",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "AS",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Arrivée Sud",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "BV",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "CA",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Centre Autorail",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "FP",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Faisceau Pair",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "P1",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Poste 1",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "P2",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Poste 2",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "RA",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Raccordement Gorge de Loup",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : "TU",
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Tunnel Ste-Irénée",
            "code" : "LV"
        },
        {
            "codeUIC" : 721001,
            "codeCH" : 13,
            "nameUIC" : "Lyon-Vaise",
            "nameCH" : "Voie 13 Garage",
            "code" : "LV"
        },
        {
            "codeUIC" : 721035,
            "codeCH" : "ES",
            "nameUIC" : "Amplepuis",
            "nameCH" : "Tunnel des Sauvages",
            "code" : ""
        },
        {
            "codeUIC" : 721035,
            "codeCH" : 0,
            "nameUIC" : "Amplepuis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721043,
            "codeCH" : 0,
            "nameUIC" : "St-Victor-Thizy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721050,
            "codeCH" : 0,
            "nameUIC" : "Régny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721068,
            "codeCH" : 0,
            "nameUIC" : "L'Hôpital (Loire)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721159,
            "codeCH" : 0,
            "nameUIC" : "Lyon-St-Paul",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721175,
            "codeCH" : "AG",
            "nameUIC" : "Lyon-Gorge-de-Loup",
            "nameCH" : "Aiguille 170",
            "code" : ""
        },
        {
            "codeUIC" : 721175,
            "codeCH" : "BV",
            "nameUIC" : "Lyon-Gorge-de-Loup",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 721175,
            "codeCH" : "EP",
            "nameUIC" : "Lyon-Gorge-de-Loup",
            "nameCH" : "ITE Km 0,1",
            "code" : ""
        },
        {
            "codeUIC" : 721175,
            "codeCH" : "PK",
            "nameUIC" : "Lyon-Gorge-de-Loup",
            "nameCH" : "Aiguille Km 0,3",
            "code" : ""
        },
        {
            "codeUIC" : 721225,
            "codeCH" : 0,
            "nameUIC" : "Collonges-Fontaines",
            "nameCH" : "",
            "code" : "COF"
        },
        {
            "codeUIC" : 721241,
            "codeCH" : 0,
            "nameUIC" : "Couzon-au-Mont-d'Or",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721266,
            "codeCH" : 0,
            "nameUIC" : "Albigny-Neuville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721282,
            "codeCH" : "A2",
            "nameUIC" : "St-Germain-au-Mont-d'Or",
            "nameCH" : "Bifurcation Km 494,6",
            "code" : "SGM"
        },
        {
            "codeUIC" : 721282,
            "codeCH" : "BV",
            "nameUIC" : "St-Germain-au-Mont-d'Or",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SGM"
        },
        {
            "codeUIC" : 721282,
            "codeCH" : "FE",
            "nameUIC" : "St-Germain-au-Mont-d'Or",
            "nameCH" : "Faisceau Escale",
            "code" : "SGM"
        },
        {
            "codeUIC" : 721282,
            "codeCH" : "P1",
            "nameUIC" : "St-Germain-au-Mont-d'Or",
            "nameCH" : "Poste 1",
            "code" : "SGM"
        },
        {
            "codeUIC" : 721282,
            "codeCH" : "P2",
            "nameUIC" : "St-Germain-au-Mont-d'Or",
            "nameCH" : "Poste 2",
            "code" : "SGM"
        },
        {
            "codeUIC" : 721282,
            "codeCH" : "TR",
            "nameUIC" : "St-Germain-au-Mont-d'Or",
            "nameCH" : "Triage",
            "code" : "SGM"
        },
        {
            "codeUIC" : 721282,
            "codeCH" : "VG",
            "nameUIC" : "St-Germain-au-Mont-d'Or",
            "nameCH" : "Voie 12 Garage",
            "code" : "SGM"
        },
        {
            "codeUIC" : 721290,
            "codeCH" : "BV",
            "nameUIC" : "Quincieux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 721290,
            "codeCH" : "KM",
            "nameUIC" : "Quincieux",
            "nameCH" : "Km 485,4",
            "code" : ""
        },
        {
            "codeUIC" : 721290,
            "codeCH" : "PK",
            "nameUIC" : "Quincieux",
            "nameCH" : "Km 484,1",
            "code" : ""
        },
        {
            "codeUIC" : 721324,
            "codeCH" : 0,
            "nameUIC" : "Anse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721332,
            "codeCH" : "BV",
            "nameUIC" : "Villefranche-sur-Saône",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VIS"
        },
        {
            "codeUIC" : 721332,
            "codeCH" : "PK",
            "nameUIC" : "Villefranche-sur-Saône",
            "nameCH" : "Km 478,3",
            "code" : "VIS"
        },
        {
            "codeUIC" : 721340,
            "codeCH" : "BV",
            "nameUIC" : "St-Georges-de-Reneins",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 721340,
            "codeCH" : "PK",
            "nameUIC" : "St-Georges-de-Reneins",
            "nameCH" : "Km 471,3",
            "code" : ""
        },
        {
            "codeUIC" : 721357,
            "codeCH" : "V3",
            "nameUIC" : "Belleville-sur-Saône",
            "nameCH" : "Voie 3 Garage",
            "code" : "BVL"
        },
        {
            "codeUIC" : 721357,
            "codeCH" : 0,
            "nameUIC" : "Belleville-sur-Saône",
            "nameCH" : "",
            "code" : "BVL"
        },
        {
            "codeUIC" : 721407,
            "codeCH" : 0,
            "nameUIC" : "Les Chères-Chasselay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721415,
            "codeCH" : 0,
            "nameUIC" : "Chazay-Marcilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721423,
            "codeCH" : "BR",
            "nameUIC" : "Lozanne",
            "nameCH" : "Bifurcation Km 484,0/95,2",
            "code" : "LOZ"
        },
        {
            "codeUIC" : 721423,
            "codeCH" : "BV",
            "nameUIC" : "Lozanne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LOZ"
        },
        {
            "codeUIC" : 721423,
            "codeCH" : "EP",
            "nameUIC" : "Lozanne",
            "nameCH" : "ITE Lafarge",
            "code" : "LOZ"
        },
        {
            "codeUIC" : 721423,
            "codeCH" : 30,
            "nameUIC" : "Lozanne",
            "nameCH" : "Carré 30",
            "code" : "LOZ"
        },
        {
            "codeUIC" : 721431,
            "codeCH" : "AT",
            "nameUIC" : "L'Arbresle",
            "nameCH" : "Atel Tram-Train",
            "code" : ""
        },
        {
            "codeUIC" : 721431,
            "codeCH" : "M1",
            "nameUIC" : "L'Arbresle",
            "nameCH" : "Atelier Tram-Train Point M1",
            "code" : ""
        },
        {
            "codeUIC" : 721431,
            "codeCH" : "M2",
            "nameUIC" : "L'Arbresle",
            "nameCH" : "Atelier Tram-Train Point M2",
            "code" : ""
        },
        {
            "codeUIC" : 721431,
            "codeCH" : 0,
            "nameUIC" : "L'Arbresle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721449,
            "codeCH" : 0,
            "nameUIC" : "Dardilly-le-Jubin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721456,
            "codeCH" : 0,
            "nameUIC" : "St-Romain-de-Popey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721464,
            "codeCH" : 0,
            "nameUIC" : "Pontcharra-St-Forgeux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721472,
            "codeCH" : 0,
            "nameUIC" : "Tarare",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721480,
            "codeCH" : 0,
            "nameUIC" : "Dardilly-les-Mouilles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721506,
            "codeCH" : 0,
            "nameUIC" : "Écully-la-Demi-Lune",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721514,
            "codeCH" : "KM",
            "nameUIC" : "Tassin",
            "nameCH" : "ITE Km 118,8",
            "code" : ""
        },
        {
            "codeUIC" : 721514,
            "codeCH" : "SE",
            "nameUIC" : "Tassin",
            "nameCH" : "Shunt ECULY",
            "code" : ""
        },
        {
            "codeUIC" : 721514,
            "codeCH" : "SF",
            "nameUIC" : "Tassin",
            "nameCH" : "Shunt FRANCHEVILLE",
            "code" : ""
        },
        {
            "codeUIC" : 721514,
            "codeCH" : 0,
            "nameUIC" : "Tassin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721522,
            "codeCH" : 0,
            "nameUIC" : "Le Méridien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721530,
            "codeCH" : 0,
            "nameUIC" : "Charbonnières-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721548,
            "codeCH" : 0,
            "nameUIC" : "La Tour-de-Salvagny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721555,
            "codeCH" : 0,
            "nameUIC" : "Lentilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721563,
            "codeCH" : 0,
            "nameUIC" : "Fleurieux-sur-l'Arbresle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721605,
            "codeCH" : 0,
            "nameUIC" : "Sain-Bel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721621,
            "codeCH" : 0,
            "nameUIC" : "Bessenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721639,
            "codeCH" : "BV",
            "nameUIC" : "Courzieu-Brussieu",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CZB"
        },
        {
            "codeUIC" : 721639,
            "codeCH" : "EP",
            "nameUIC" : "Courzieu-Brussieu",
            "nameCH" : "ITE Val du Ross",
            "code" : "CZB"
        },
        {
            "codeUIC" : 721654,
            "codeCH" : "BV",
            "nameUIC" : "Ste-Foy-l'Argentière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 721654,
            "codeCH" : "EP",
            "nameUIC" : "Ste-Foy-l'Argentière",
            "nameCH" : "ITE Km 38,1",
            "code" : ""
        },
        {
            "codeUIC" : 721704,
            "codeCH" : 0,
            "nameUIC" : "Les Flachères",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721720,
            "codeCH" : 0,
            "nameUIC" : "Limonest",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721738,
            "codeCH" : 0,
            "nameUIC" : "Dommartin-Lissieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721746,
            "codeCH" : 0,
            "nameUIC" : "Civrieux-d'Azergues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721753,
            "codeCH" : 0,
            "nameUIC" : "Châtillon-d'Azergues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721761,
            "codeCH" : 0,
            "nameUIC" : "Chessy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721779,
            "codeCH" : 0,
            "nameUIC" : "Le Breuil (Rhône)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721787,
            "codeCH" : 0,
            "nameUIC" : "Bois-d'Oingt-Légny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721795,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-d'Oingt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721811,
            "codeCH" : 0,
            "nameUIC" : "Ternand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721829,
            "codeCH" : 0,
            "nameUIC" : "Chamelet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721837,
            "codeCH" : "BV",
            "nameUIC" : "St-Just-d'Avray",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 721845,
            "codeCH" : 0,
            "nameUIC" : "Allières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721852,
            "codeCH" : 0,
            "nameUIC" : "Grandris-Allières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721860,
            "codeCH" : 0,
            "nameUIC" : "Lamure-sur-Azergues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721878,
            "codeCH" : 0,
            "nameUIC" : "St-Nizier-d'Azergues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721886,
            "codeCH" : 0,
            "nameUIC" : "Claveisolles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 721894,
            "codeCH" : "BV",
            "nameUIC" : "Poule",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 722009,
            "codeCH" : "AG",
            "nameUIC" : "Lyon-Perrache-Marchandises",
            "nameCH" : "Aiguille Km 506,8",
            "code" : ""
        },
        {
            "codeUIC" : 722009,
            "codeCH" : "P1",
            "nameUIC" : "Lyon-Perrache-Marchandises",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 722009,
            "codeCH" : 21,
            "nameUIC" : "Lyon-Perrache-Marchandises",
            "nameCH" : "Poste 21",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "BB",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Bifurcation Part-Dieu",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "BV",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "CA",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Carré 322",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "JM",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Halte Jean Macé",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "K1",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Km 510,5",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "PS",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Km 511,411",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "P1",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "P2",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 722025,
            "codeCH" : "4T",
            "nameUIC" : "Lyon-Perrache-Voyageurs",
            "nameCH" : "Voie 4 Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 722207,
            "codeCH" : "BV",
            "nameUIC" : "Oullins",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722207,
            "codeCH" : "EP",
            "nameUIC" : "Oullins",
            "nameCH" : "ITE Trayvou",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722207,
            "codeCH" : "LH",
            "nameUIC" : "Oullins",
            "nameCH" : "ITE Atochem N° 2",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722207,
            "codeCH" : "ME",
            "nameUIC" : "Oullins",
            "nameCH" : "ITE Société mécanique",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722207,
            "codeCH" : "M2",
            "nameUIC" : "Oullins",
            "nameCH" : "ITE Commune d'Irigny",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722207,
            "codeCH" : "OM",
            "nameUIC" : "Oullins",
            "nameCH" : "Technicentre",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722207,
            "codeCH" : "UG",
            "nameUIC" : "Oullins",
            "nameCH" : "ITE UK Sud",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722207,
            "codeCH" : 0,
            "nameUIC" : "Oullins",
            "nameCH" : "",
            "code" : "OUL"
        },
        {
            "codeUIC" : 722231,
            "codeCH" : 0,
            "nameUIC" : "Pierre-Bénite",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722249,
            "codeCH" : "BV",
            "nameUIC" : "Irigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 722249,
            "codeCH" : "LA",
            "nameUIC" : "Irigny",
            "nameCH" : "ITE Lambert et Rivière",
            "code" : ""
        },
        {
            "codeUIC" : 722249,
            "codeCH" : 0,
            "nameUIC" : "Irigny-Yvours",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722264,
            "codeCH" : "BV",
            "nameUIC" : "Vernaison",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 722280,
            "codeCH" : 0,
            "nameUIC" : "Grigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722298,
            "codeCH" : 0,
            "nameUIC" : "Le Sablon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722306,
            "codeCH" : "FA",
            "nameUIC" : "Badan",
            "nameCH" : "Faisceau",
            "code" : "BAD"
        },
        {
            "codeUIC" : 722306,
            "codeCH" : "P1",
            "nameUIC" : "Badan",
            "nameCH" : "Poste 1",
            "code" : "BAD"
        },
        {
            "codeUIC" : 722306,
            "codeCH" : "P2",
            "nameUIC" : "Badan",
            "nameCH" : "Poste 2",
            "code" : "BAD"
        },
        {
            "codeUIC" : 722306,
            "codeCH" : "P3",
            "nameUIC" : "Badan",
            "nameCH" : "Point L",
            "code" : "BAD"
        },
        {
            "codeUIC" : 722306,
            "codeCH" : "RN",
            "nameUIC" : "Badan",
            "nameCH" : "Relais Nord/Sud",
            "code" : "BAD"
        },
        {
            "codeUIC" : 722306,
            "codeCH" : "RS",
            "nameUIC" : "Badan",
            "nameCH" : "Relais Sud/Centre",
            "code" : "BAD"
        },
        {
            "codeUIC" : 722314,
            "codeCH" : "BV",
            "nameUIC" : "Loire",
            "nameCH" : "Poste 1",
            "code" : "LOI"
        },
        {
            "codeUIC" : 722322,
            "codeCH" : 0,
            "nameUIC" : "Ste-Colombe-les-Vienne-St-Roma",
            "nameCH" : "",
            "code" : "STE"
        },
        {
            "codeUIC" : 722348,
            "codeCH" : "VA",
            "nameUIC" : "Condrieu",
            "nameCH" : "Voie A",
            "code" : ""
        },
        {
            "codeUIC" : 722348,
            "codeCH" : 0,
            "nameUIC" : "Condrieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722405,
            "codeCH" : "BV",
            "nameUIC" : "Givors-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GIV"
        },
        {
            "codeUIC" : 722405,
            "codeCH" : "V4",
            "nameUIC" : "Givors-Ville",
            "nameCH" : "Voie 4 Garage",
            "code" : "GIV"
        },
        {
            "codeUIC" : 722439,
            "codeCH" : "AR",
            "nameUIC" : "Givors-Canal",
            "nameCH" : "Carré 12",
            "code" : ""
        },
        {
            "codeUIC" : 722439,
            "codeCH" : "BR",
            "nameUIC" : "Givors-Canal",
            "nameCH" : "Bifurcation Km 2,6",
            "code" : ""
        },
        {
            "codeUIC" : 722439,
            "codeCH" : "BV",
            "nameUIC" : "Givors-Canal",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 722439,
            "codeCH" : "KM",
            "nameUIC" : "Givors-Canal",
            "nameCH" : "Km 540",
            "code" : ""
        },
        {
            "codeUIC" : 722439,
            "codeCH" : 21,
            "nameUIC" : "Givors-Canal",
            "nameCH" : "Bifurcation Km 2,1",
            "code" : ""
        },
        {
            "codeUIC" : 722447,
            "codeCH" : 0,
            "nameUIC" : "St-Romain-en-Gier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "BN",
            "nameUIC" : "Sibelin",
            "nameCH" : "Bifurcation Nord",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "BS",
            "nameUIC" : "Sibelin",
            "nameCH" : "Bifurcation Sud",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "FF",
            "nameUIC" : "Sibelin",
            "nameCH" : "Faisceau Formation",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "FR",
            "nameUIC" : "Sibelin",
            "nameCH" : "Faisceau Réception",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "IT",
            "nameUIC" : "Sibelin",
            "nameCH" : "ITE Km 2,0",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "NS",
            "nameUIC" : "Sibelin",
            "nameCH" : "Relais Nord-sud",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "PA",
            "nameUIC" : "Sibelin",
            "nameCH" : "Local Agent de Manoeuvre",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "PB",
            "nameUIC" : "Sibelin",
            "nameCH" : "Faisceau Départ",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "PD",
            "nameUIC" : "Sibelin",
            "nameCH" : "Poste D",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "PN",
            "nameUIC" : "Sibelin",
            "nameCH" : "Panneau 215",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "PV",
            "nameUIC" : "Sibelin",
            "nameCH" : "",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "P1",
            "nameUIC" : "Sibelin",
            "nameCH" : "Poste 1",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722520,
            "codeCH" : "SN",
            "nameUIC" : "Sibelin",
            "nameCH" : "Relais Sud-Nord",
            "code" : "SIB"
        },
        {
            "codeUIC" : 722538,
            "codeCH" : 0,
            "nameUIC" : "Feyzin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722546,
            "codeCH" : 0,
            "nameUIC" : "Sérézin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722553,
            "codeCH" : 0,
            "nameUIC" : "Ternay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722561,
            "codeCH" : "BV",
            "nameUIC" : "Chasse-sur-Rhône",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CSR"
        },
        {
            "codeUIC" : 722561,
            "codeCH" : "FR",
            "nameUIC" : "Chasse-sur-Rhône",
            "nameCH" : "Faisceau Réception",
            "code" : "CSR"
        },
        {
            "codeUIC" : 722561,
            "codeCH" : "HA",
            "nameUIC" : "Chasse-sur-Rhône",
            "nameCH" : "Point Arrêt Km 531,060",
            "code" : "CSR"
        },
        {
            "codeUIC" : 722561,
            "codeCH" : "P1",
            "nameUIC" : "Chasse-sur-Rhône",
            "nameCH" : "Poste 1",
            "code" : "CSR"
        },
        {
            "codeUIC" : 722579,
            "codeCH" : "BV",
            "nameUIC" : "Estressin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 722579,
            "codeCH" : "PK",
            "nameUIC" : "Estressin",
            "nameCH" : "Km 541",
            "code" : ""
        },
        {
            "codeUIC" : 722579,
            "codeCH" : "UR",
            "nameUIC" : "Estressin",
            "nameCH" : "ITE Urlac",
            "code" : ""
        },
        {
            "codeUIC" : 722587,
            "codeCH" : "BV",
            "nameUIC" : "Vienne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 722587,
            "codeCH" : "PU",
            "nameUIC" : "Vienne",
            "nameCH" : "Poste",
            "code" : ""
        },
        {
            "codeUIC" : 722587,
            "codeCH" : "1B",
            "nameUIC" : "Vienne",
            "nameCH" : "Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 722587,
            "codeCH" : "2B",
            "nameUIC" : "Vienne",
            "nameCH" : "Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 722645,
            "codeCH" : 0,
            "nameUIC" : "Vaugris",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722652,
            "codeCH" : "V3",
            "nameUIC" : "St-Clair-les-Roches",
            "nameCH" : "Voie 3 Garage",
            "code" : "SCR"
        },
        {
            "codeUIC" : 722652,
            "codeCH" : "V4",
            "nameUIC" : "St-Clair-les-Roches",
            "nameCH" : "Voie 4 Garage",
            "code" : "SCR"
        },
        {
            "codeUIC" : 722652,
            "codeCH" : 0,
            "nameUIC" : "St-Clair-les-Roches",
            "nameCH" : "",
            "code" : "SCR"
        },
        {
            "codeUIC" : 722660,
            "codeCH" : "BV",
            "nameUIC" : "Clonas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "C"
        },
        {
            "codeUIC" : 722678,
            "codeCH" : 0,
            "nameUIC" : "Le Péage-de-Roussillon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722686,
            "codeCH" : 0,
            "nameUIC" : "Salaise",
            "nameCH" : "",
            "code" : "SAS"
        },
        {
            "codeUIC" : 722702,
            "codeCH" : "AG",
            "nameUIC" : "Francheville",
            "nameCH" : "Aiguille 406",
            "code" : ""
        },
        {
            "codeUIC" : 722702,
            "codeCH" : 0,
            "nameUIC" : "Francheville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722710,
            "codeCH" : 0,
            "nameUIC" : "Chaponost",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722728,
            "codeCH" : "AG",
            "nameUIC" : "Brignais",
            "nameCH" : "Aiguille 101",
            "code" : ""
        },
        {
            "codeUIC" : 722728,
            "codeCH" : "PN",
            "nameUIC" : "Brignais",
            "nameCH" : "PN 105",
            "code" : ""
        },
        {
            "codeUIC" : 722728,
            "codeCH" : "RE",
            "nameUIC" : "Brignais",
            "nameCH" : "Tiroir",
            "code" : ""
        },
        {
            "codeUIC" : 722728,
            "codeCH" : 0,
            "nameUIC" : "Brignais",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722736,
            "codeCH" : 0,
            "nameUIC" : "Millery-Montagny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 722744,
            "codeCH" : 0,
            "nameUIC" : "Alai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "AN",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Km 506,650",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "AS",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Km 507,847",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "BF",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Aiguille Km 509",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "BV",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "EP",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "ITE Des Tabacs",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "ES",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Tunnel Calluire",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "G1",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Guillotière P15",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "KN",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Km 507,378",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "KS",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Km 508,508",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "PO",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Portique Km 504,6",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "RM",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Remisage",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723197,
            "codeCH" : "SM",
            "nameUIC" : "Lyon-Part-Dieu",
            "nameCH" : "Saut de Mouton",
            "code" : "LYD"
        },
        {
            "codeUIC" : 723254,
            "codeCH" : 0,
            "nameUIC" : "Lyon-St-Clair",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "BV",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "CA",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Centre Autorail",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "CV",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Carré Violet 543",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "FI",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Faisceau Impair",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "FP",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Faisceau Pair",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "FR",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "PA",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Bifurcation Km 6,6",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "P1",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : "VG",
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "Voies 114-116",
            "code" : ""
        },
        {
            "codeUIC" : 723320,
            "codeCH" : 10,
            "nameUIC" : "Vénissieux-Voyageurs",
            "nameCH" : "TIV Fixe 90",
            "code" : ""
        },
        {
            "codeUIC" : 723353,
            "codeCH" : "BV",
            "nameUIC" : "St-Priest",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SRI"
        },
        {
            "codeUIC" : 723353,
            "codeCH" : "CA",
            "nameUIC" : "St-Priest",
            "nameCH" : "Carré 590",
            "code" : "SRI"
        },
        {
            "codeUIC" : 723353,
            "codeCH" : "SO",
            "nameUIC" : "St-Priest",
            "nameCH" : "ITE Sotragal",
            "code" : "SRI"
        },
        {
            "codeUIC" : 723353,
            "codeCH" : "TP",
            "nameUIC" : "St-Priest",
            "nameCH" : "ITE Km 10,7",
            "code" : "SRI"
        },
        {
            "codeUIC" : 723361,
            "codeCH" : "BV",
            "nameUIC" : "Chandieu-Toussieu",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CHD"
        },
        {
            "codeUIC" : 723379,
            "codeCH" : 0,
            "nameUIC" : "Heyrieux",
            "nameCH" : "",
            "code" : "HEY"
        },
        {
            "codeUIC" : 723387,
            "codeCH" : "BF",
            "nameUIC" : "St-Quentin-Fallavier",
            "nameCH" : "Bifurcation LGV",
            "code" : "SQT"
        },
        {
            "codeUIC" : 723387,
            "codeCH" : "BV",
            "nameUIC" : "St-Quentin-Fallavier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SQT"
        },
        {
            "codeUIC" : 723395,
            "codeCH" : 0,
            "nameUIC" : "La Verpillière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723429,
            "codeCH" : "BV",
            "nameUIC" : "Bourgoin-Jallieu",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BRG"
        },
        {
            "codeUIC" : 723429,
            "codeCH" : "EP",
            "nameUIC" : "Bourgoin-Jallieu",
            "nameCH" : "ITE Sobedo",
            "code" : "BRG"
        },
        {
            "codeUIC" : 723429,
            "codeCH" : "3G",
            "nameUIC" : "Bourgoin-Jallieu",
            "nameCH" : "Voie 3 Garage",
            "code" : "BRG"
        },
        {
            "codeUIC" : 723452,
            "codeCH" : 0,
            "nameUIC" : "Cessieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723478,
            "codeCH" : 0,
            "nameUIC" : "La Tour-du-Pin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723494,
            "codeCH" : "VU",
            "nameUIC" : "St-André-le-Gaz",
            "nameCH" : "Carré 123",
            "code" : "SAG"
        },
        {
            "codeUIC" : 723494,
            "codeCH" : 0,
            "nameUIC" : "St-André-le-Gaz",
            "nameCH" : "",
            "code" : "SAG"
        },
        {
            "codeUIC" : 723502,
            "codeCH" : 0,
            "nameUIC" : "Crépieux-la-Pape",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723528,
            "codeCH" : 0,
            "nameUIC" : "Miribel",
            "nameCH" : "",
            "code" : "MIB"
        },
        {
            "codeUIC" : 723536,
            "codeCH" : 0,
            "nameUIC" : "St-Maurice-de-Beynost",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723544,
            "codeCH" : "BV",
            "nameUIC" : "Beynost",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 723551,
            "codeCH" : 0,
            "nameUIC" : "La Boisse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723569,
            "codeCH" : "BV",
            "nameUIC" : "Montluel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MLU"
        },
        {
            "codeUIC" : 723569,
            "codeCH" : "1E",
            "nameUIC" : "Montluel",
            "nameCH" : "Voie 1 Évitement",
            "code" : "MLU"
        },
        {
            "codeUIC" : 723577,
            "codeCH" : 0,
            "nameUIC" : "La Valbonne",
            "nameCH" : "",
            "code" : "LVA"
        },
        {
            "codeUIC" : 723585,
            "codeCH" : 0,
            "nameUIC" : "Meximieux-Pérouges",
            "nameCH" : "",
            "code" : "MEX"
        },
        {
            "codeUIC" : 723619,
            "codeCH" : 0,
            "nameUIC" : "Villieu-Loyes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723700,
            "codeCH" : "BV",
            "nameUIC" : "Sathonay-Rillieux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SYR"
        },
        {
            "codeUIC" : 723700,
            "codeCH" : "PK",
            "nameUIC" : "Sathonay-Rillieux",
            "nameCH" : "Km 386",
            "code" : "SYR"
        },
        {
            "codeUIC" : 723700,
            "codeCH" : "SG",
            "nameUIC" : "Sathonay-Rillieux",
            "nameCH" : "Signal 2 et 5",
            "code" : "SYR"
        },
        {
            "codeUIC" : 723718,
            "codeCH" : 0,
            "nameUIC" : "Les Échets",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723726,
            "codeCH" : 0,
            "nameUIC" : "Mionnay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723734,
            "codeCH" : "BV",
            "nameUIC" : "St-André-de-Corcy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SAC"
        },
        {
            "codeUIC" : 723734,
            "codeCH" : "EP",
            "nameUIC" : "St-André-de-Corcy",
            "nameCH" : "ITE Km 28,8",
            "code" : "SAC"
        },
        {
            "codeUIC" : 723742,
            "codeCH" : 0,
            "nameUIC" : "St-Marcel-en-Dombes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723759,
            "codeCH" : "BV",
            "nameUIC" : "Villars-les-Dombes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VLD"
        },
        {
            "codeUIC" : 723767,
            "codeCH" : 0,
            "nameUIC" : "Marlieux-Châtillon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723783,
            "codeCH" : 0,
            "nameUIC" : "St-Paul-de-Varax",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723791,
            "codeCH" : "BV",
            "nameUIC" : "Servas-Lent",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 723791,
            "codeCH" : "EP",
            "nameUIC" : "Servas-Lent",
            "nameCH" : "ITE Ciments",
            "code" : ""
        },
        {
            "codeUIC" : 723809,
            "codeCH" : 0,
            "nameUIC" : "Fontaines-sur-Saône",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 723825,
            "codeCH" : "BV",
            "nameUIC" : "Neuville-sur-Saône",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NSS"
        },
        {
            "codeUIC" : 723825,
            "codeCH" : "EP",
            "nameUIC" : "Neuville-sur-Saône",
            "nameCH" : "ITE Zone industrielle",
            "code" : "NSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "AC",
            "nameUIC" : "Châlons-sur-Saône",
            "nameCH" : "Accès Châlons",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "AS",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Aiguille Km 384",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "BF",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Bifurcation Nord",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "BV",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "EP",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "ITE Schiever",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "FI",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Faisceau Impair",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "FP",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Faisceau Pair",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "GI",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Voie 65 Garage",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "GP",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Garage Pair",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "IT",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "ITE Km 104,3",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "PA",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Poste PAI",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : "V4",
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "Voie 4 Garage",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725002,
            "codeCH" : 62,
            "nameUIC" : "Chalon-sur-Saône",
            "nameCH" : "PN 62",
            "code" : "CSS"
        },
        {
            "codeUIC" : 725028,
            "codeCH" : 0,
            "nameUIC" : "Crissey",
            "nameCH" : "",
            "code" : "CRS"
        },
        {
            "codeUIC" : 725507,
            "codeCH" : 0,
            "nameUIC" : "Gergy",
            "nameCH" : "",
            "code" : "GGY"
        },
        {
            "codeUIC" : 725515,
            "codeCH" : 0,
            "nameUIC" : "Allerey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725523,
            "codeCH" : 0,
            "nameUIC" : "Verdun-sur-le-Doubs",
            "nameCH" : "",
            "code" : "VUS"
        },
        {
            "codeUIC" : 725606,
            "codeCH" : 0,
            "nameUIC" : "Varennes-le-Grand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725614,
            "codeCH" : "AG",
            "nameUIC" : "Sennecey-le-Grand",
            "nameCH" : "Aiguille Sortie Voie 5",
            "code" : "SRD"
        },
        {
            "codeUIC" : 725614,
            "codeCH" : "PV",
            "nameUIC" : "Sennecey-le-Grand",
            "nameCH" : "Point de Changement de voie",
            "code" : "SRD"
        },
        {
            "codeUIC" : 725614,
            "codeCH" : "V5",
            "nameUIC" : "Sennecey-le-Grand",
            "nameCH" : "Voie 5 Garage",
            "code" : "SRD"
        },
        {
            "codeUIC" : 725614,
            "codeCH" : 0,
            "nameUIC" : "Sennecey-le-Grand",
            "nameCH" : "",
            "code" : "SRD"
        },
        {
            "codeUIC" : 725614,
            "codeCH" : "2B",
            "nameUIC" : "Sennecey-le-Grand",
            "nameCH" : "Voie 2Bis",
            "code" : "SRD"
        },
        {
            "codeUIC" : 725622,
            "codeCH" : "AG",
            "nameUIC" : "Tournus",
            "nameCH" : "Aiguille Sortie Voie V1BIS",
            "code" : ""
        },
        {
            "codeUIC" : 725622,
            "codeCH" : 0,
            "nameUIC" : "Tournus",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725622,
            "codeCH" : "1B",
            "nameUIC" : "Tournus",
            "nameCH" : "Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 725655,
            "codeCH" : "AG",
            "nameUIC" : "Uchizy",
            "nameCH" : "Aiguille Sortie Voie 2BIS",
            "code" : ""
        },
        {
            "codeUIC" : 725655,
            "codeCH" : "BV",
            "nameUIC" : "Uchizy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 725655,
            "codeCH" : "EV",
            "nameUIC" : "Uchizy",
            "nameCH" : "Évitement Pair",
            "code" : ""
        },
        {
            "codeUIC" : 725655,
            "codeCH" : "PV",
            "nameUIC" : "Uchizy",
            "nameCH" : "Point de Changement de voie",
            "code" : ""
        },
        {
            "codeUIC" : 725663,
            "codeCH" : "V3",
            "nameUIC" : "Fleurville-Pont-de-Vaux",
            "nameCH" : "Voie 3 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 725663,
            "codeCH" : 0,
            "nameUIC" : "Fleurville-Pont-de-Vaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725671,
            "codeCH" : 0,
            "nameUIC" : "Senozan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "BF",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Bifurcation Km 440,9",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "BV",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "CH",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Carré 117",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "FA",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Faisceau pair",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "KM",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Km 441,6",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "PK",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Km 441,9",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "PP",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Parc Postal",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "PV",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Point de Changement de Voie",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "RD",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Raccordement de Dijon",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "RN",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Rac Nord TGV",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : "RS",
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "Rac Sud TGV",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725689,
            "codeCH" : 23,
            "nameUIC" : "Mâcon-Ville",
            "nameCH" : "",
            "code" : "MAC"
        },
        {
            "codeUIC" : 725705,
            "codeCH" : "BV",
            "nameUIC" : "Mâcon-Loché-TGV",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 725705,
            "codeCH" : "RD",
            "nameUIC" : "Mâcon-Loché-TGV",
            "nameCH" : "Raccordement Nord de Mâcon",
            "code" : ""
        },
        {
            "codeUIC" : 725705,
            "codeCH" : "SA",
            "nameUIC" : "Mâcon-Loché-TGV",
            "nameCH" : "Bifurcation de Savoie",
            "code" : ""
        },
        {
            "codeUIC" : 725713,
            "codeCH" : 0,
            "nameUIC" : "Crêches-sur-Saône",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725721,
            "codeCH" : "V3",
            "nameUIC" : "Pontanevaux-la-Chapelle",
            "nameCH" : "Voie 3 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 725721,
            "codeCH" : "V4",
            "nameUIC" : "Pontanevaux-la-Chapelle",
            "nameCH" : "Voie 4 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 725721,
            "codeCH" : 0,
            "nameUIC" : "Pontanevaux-la-Chapelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725739,
            "codeCH" : "BV",
            "nameUIC" : "Romanèche-Thorins",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 725739,
            "codeCH" : "PK",
            "nameUIC" : "Romanèche-Thorins",
            "nameCH" : "Carré 452,7",
            "code" : ""
        },
        {
            "codeUIC" : 725804,
            "codeCH" : "V3",
            "nameUIC" : "Pont-de-Veyle",
            "nameCH" : "Voie 3 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 725804,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Veyle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725812,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-sur-Veyle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725820,
            "codeCH" : 0,
            "nameUIC" : "Vonnas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725838,
            "codeCH" : 0,
            "nameUIC" : "Mézériat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725846,
            "codeCH" : 0,
            "nameUIC" : "Polliat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 725895,
            "codeCH" : 0,
            "nameUIC" : "Échirolles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726000,
            "codeCH" : "BV",
            "nameUIC" : "St-Étienne-Châteaucreux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SÉN"
        },
        {
            "codeUIC" : 726000,
            "codeCH" : "DP",
            "nameUIC" : "St-Étienne-Châteaucreux",
            "nameCH" : "",
            "code" : "SÉN"
        },
        {
            "codeUIC" : 726000,
            "codeCH" : "FR",
            "nameUIC" : "St-Étienne-Châteaucreux",
            "nameCH" : "Faisceau réception",
            "code" : "SÉN"
        },
        {
            "codeUIC" : 726000,
            "codeCH" : "P1",
            "nameUIC" : "St-Étienne-Châteaucreux",
            "nameCH" : "Poste 1",
            "code" : "SÉN"
        },
        {
            "codeUIC" : 726000,
            "codeCH" : "P2",
            "nameUIC" : "St-Étienne-Châteaucreux",
            "nameCH" : "Poste 2",
            "code" : "SÉN"
        },
        {
            "codeUIC" : 726042,
            "codeCH" : 0,
            "nameUIC" : "Pouilly-sous-Charlieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726133,
            "codeCH" : "FA",
            "nameUIC" : "St-Étienne-Pont-de-l'Âne",
            "nameCH" : "Faisceau",
            "code" : "STP"
        },
        {
            "codeUIC" : 726133,
            "codeCH" : "FT",
            "nameUIC" : "St-Étienne-Pont-de-l'Âne",
            "nameCH" : "Faisceau Terrenoire",
            "code" : "STP"
        },
        {
            "codeUIC" : 726133,
            "codeCH" : "PU",
            "nameUIC" : "St-Étienne-Pont-de-l'Âne",
            "nameCH" : "Portique Km 505,7",
            "code" : "STP"
        },
        {
            "codeUIC" : 726133,
            "codeCH" : "P2",
            "nameUIC" : "St-Étienne-Pont-de-l'Âne",
            "nameCH" : "Poste 2",
            "code" : "STP"
        },
        {
            "codeUIC" : 726133,
            "codeCH" : "P6",
            "nameUIC" : "St-Étienne-Pont-de-l'Âne",
            "nameCH" : "Point B",
            "code" : "STP"
        },
        {
            "codeUIC" : 726158,
            "codeCH" : "BV",
            "nameUIC" : "St-Étienne-La Terrasse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726158,
            "codeCH" : "ST",
            "nameUIC" : "St-Étienne-La Terrasse",
            "nameCH" : "Le Stade",
            "code" : ""
        },
        {
            "codeUIC" : 726174,
            "codeCH" : "BV",
            "nameUIC" : "St-Étienne-Le Clapier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726190,
            "codeCH" : 0,
            "nameUIC" : "St-Étienne-Bellevue",
            "nameCH" : "",
            "code" : "SÉN"
        },
        {
            "codeUIC" : 726208,
            "codeCH" : "BV",
            "nameUIC" : "St-Galmier-Veauche",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SGI"
        },
        {
            "codeUIC" : 726208,
            "codeCH" : "V3",
            "nameUIC" : "St-Galmier-Veauche",
            "nameCH" : "Voie 3",
            "code" : "SGI"
        },
        {
            "codeUIC" : 726216,
            "codeCH" : "BV",
            "nameUIC" : "Bouthéon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BOH"
        },
        {
            "codeUIC" : 726216,
            "codeCH" : "Z1",
            "nameUIC" : "Bouthéon",
            "nameCH" : "ITE Zone Industrielle N°1",
            "code" : "BOH"
        },
        {
            "codeUIC" : 726224,
            "codeCH" : 0,
            "nameUIC" : "St-Just-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726232,
            "codeCH" : "BV",
            "nameUIC" : "La Fouillouse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LFO"
        },
        {
            "codeUIC" : 726240,
            "codeCH" : 0,
            "nameUIC" : "Villars (Loire)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726307,
            "codeCH" : "BV",
            "nameUIC" : "St-Chamond",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726315,
            "codeCH" : "BV",
            "nameUIC" : "Grand-Croix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726323,
            "codeCH" : "BV",
            "nameUIC" : "Lorette",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726323,
            "codeCH" : "TS",
            "nameUIC" : "Lorette",
            "nameCH" : "ITE Km 520,5",
            "code" : ""
        },
        {
            "codeUIC" : 726331,
            "codeCH" : 0,
            "nameUIC" : "Rive-de-Gier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726349,
            "codeCH" : "BV",
            "nameUIC" : "Couzon (Loire)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "COZ"
        },
        {
            "codeUIC" : 726356,
            "codeCH" : 0,
            "nameUIC" : "Trèves-Burel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726406,
            "codeCH" : 0,
            "nameUIC" : "Andrézieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726414,
            "codeCH" : "BV",
            "nameUIC" : "Bonson",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BSN"
        },
        {
            "codeUIC" : 726422,
            "codeCH" : 0,
            "nameUIC" : "Sury-le-Comtal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726430,
            "codeCH" : 0,
            "nameUIC" : "St-Romain-le-Puy",
            "nameCH" : "",
            "code" : "SRP"
        },
        {
            "codeUIC" : 726448,
            "codeCH" : "BV",
            "nameUIC" : "Montbrison",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726471,
            "codeCH" : 0,
            "nameUIC" : "Boën",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726661,
            "codeCH" : 0,
            "nameUIC" : "St-Didier-La Séauve",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726679,
            "codeCH" : 0,
            "nameUIC" : "St-Pal-St-Romain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726687,
            "codeCH" : 0,
            "nameUIC" : "Dunières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726703,
            "codeCH" : "BV",
            "nameUIC" : "La Ricamarie",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726711,
            "codeCH" : "BV",
            "nameUIC" : "Le Chambon-Feugerolles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726729,
            "codeCH" : "BF",
            "nameUIC" : "Firminy",
            "nameCH" : "Bifurcation Km 0,4/123,3",
            "code" : ""
        },
        {
            "codeUIC" : 726729,
            "codeCH" : "BV",
            "nameUIC" : "Firminy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 726729,
            "codeCH" : "TR",
            "nameUIC" : "Firminy",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 726737,
            "codeCH" : 0,
            "nameUIC" : "Fraisses-Unieux",
            "nameCH" : "",
            "code" : "FRA"
        },
        {
            "codeUIC" : 726745,
            "codeCH" : 0,
            "nameUIC" : "Le Pertuiset",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726752,
            "codeCH" : 0,
            "nameUIC" : "Semène",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726760,
            "codeCH" : 0,
            "nameUIC" : "Aurec",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726778,
            "codeCH" : 0,
            "nameUIC" : "Bas-Monistrol",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726786,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Lignon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726794,
            "codeCH" : 0,
            "nameUIC" : "Beauzac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726802,
            "codeCH" : "BV",
            "nameUIC" : "Roanne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ROA"
        },
        {
            "codeUIC" : 726802,
            "codeCH" : "CA",
            "nameUIC" : "Roanne",
            "nameCH" : "Carré 100",
            "code" : "ROA"
        },
        {
            "codeUIC" : 726802,
            "codeCH" : "CR",
            "nameUIC" : "Roanne",
            "nameCH" : "Carré 103",
            "code" : "ROA"
        },
        {
            "codeUIC" : 726828,
            "codeCH" : 0,
            "nameUIC" : "Le Coteau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726844,
            "codeCH" : 0,
            "nameUIC" : "Vendranges-St-Priest",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726851,
            "codeCH" : 0,
            "nameUIC" : "St-Jodard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726869,
            "codeCH" : 0,
            "nameUIC" : "Balbigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 726877,
            "codeCH" : "BV",
            "nameUIC" : "Feurs",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FEU"
        },
        {
            "codeUIC" : 726885,
            "codeCH" : 0,
            "nameUIC" : "Montrond-les-Bains",
            "nameCH" : "",
            "code" : "MRB"
        },
        {
            "codeUIC" : 726901,
            "codeCH" : 0,
            "nameUIC" : "St-Étienne-Carnot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "AG",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Aiguilles V1C/V2R",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "BX",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Bifurcation de Vénissieux",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "CB",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Croix-Barret",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "CV",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Carré Violet 75",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "EH",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Port EH",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "ES",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Entrée/Sortie Technicentre",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "FR",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Faisceau réception",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "J1",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Jonction Entrée/Sortie PEH",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "KM",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Aiguilles Km 514",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "LM",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Lyon-Mouche",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "MT",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Bifurcation du Moulin-à-Vent",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "PG",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Poste G",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "PO",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Portique C2701/C2707",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "P2",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Poste 2",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "P3",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Poste 3",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "P4",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Poste 4",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "P7",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Poste 7",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "RI",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Relais Impair",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "RP",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Relais Pair",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "SC",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Scaronne",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "SF",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Bifurcation de St-Fons",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "TR",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Triage",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "VI",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Carré Violet 74",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : 27,
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Carrés 2701 à 2707",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : 31,
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Voie 31 Fret",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : 57,
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Carré Violet 57",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : 59,
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Carré Violet 459",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : "7P",
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Poste 17",
            "code" : "LN"
        },
        {
            "codeUIC" : 728006,
            "codeCH" : 74,
            "nameUIC" : "Lyon-Guillotière",
            "nameCH" : "Carré 2702",
            "code" : "LN"
        },
        {
            "codeUIC" : 728501,
            "codeCH" : "BV",
            "nameUIC" : "St-Fons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SFS"
        },
        {
            "codeUIC" : 730887,
            "codeCH" : "BG",
            "nameUIC" : "Clermont-les-Gravanches Triage",
            "nameCH" : "Aiguille Km 412,3",
            "code" : ""
        },
        {
            "codeUIC" : 730887,
            "codeCH" : "CH",
            "nameUIC" : "Clermont-les-Gravanches Triage",
            "nameCH" : "Faisceau de la Chèvre",
            "code" : ""
        },
        {
            "codeUIC" : 730887,
            "codeCH" : "G1",
            "nameUIC" : "Clermont-les-Gravanches Triage",
            "nameCH" : "Aiguille Km 413,2",
            "code" : ""
        },
        {
            "codeUIC" : 730887,
            "codeCH" : "G2",
            "nameUIC" : "Clermont-les-Gravanches Triage",
            "nameCH" : "Aiguille Km 414,8",
            "code" : ""
        },
        {
            "codeUIC" : 730887,
            "codeCH" : "PB",
            "nameUIC" : "Clermont-les-Gravanches Triage",
            "nameCH" : "Poste B",
            "code" : ""
        },
        {
            "codeUIC" : 730887,
            "codeCH" : "TG",
            "nameUIC" : "Clermont-les-Gravanches Triage",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 730887,
            "codeCH" : 21,
            "nameUIC" : "Clermont-les-Gravanches Triage",
            "nameCH" : "Carré Violet210",
            "code" : ""
        },
        {
            "codeUIC" : 731604,
            "codeCH" : 0,
            "nameUIC" : "Celles-sur-Durolle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731653,
            "codeCH" : 0,
            "nameUIC" : "Néronde-sur-Dore",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731802,
            "codeCH" : 0,
            "nameUIC" : "Aurac-Lafayette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731810,
            "codeCH" : 0,
            "nameUIC" : "Rougeac-Chavaniac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731828,
            "codeCH" : 0,
            "nameUIC" : "Lachaud-Curmilhac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731836,
            "codeCH" : 0,
            "nameUIC" : "Lissac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731844,
            "codeCH" : 0,
            "nameUIC" : "St-Vidal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731877,
            "codeCH" : 0,
            "nameUIC" : "Prades-St-Julien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 731885,
            "codeCH" : 0,
            "nameUIC" : "Alleyras",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 732008,
            "codeCH" : "BV",
            "nameUIC" : "Vichy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VHY"
        },
        {
            "codeUIC" : 732008,
            "codeCH" : "P1",
            "nameUIC" : "Vichy",
            "nameCH" : "Bifurcation de Cusset",
            "code" : "VHY"
        },
        {
            "codeUIC" : 732008,
            "codeCH" : "P2",
            "nameUIC" : "Vichy",
            "nameCH" : "Bifurcation de Puy-Guillaume",
            "code" : "VHY"
        },
        {
            "codeUIC" : 732156,
            "codeCH" : "EP",
            "nameUIC" : "Cusset",
            "nameCH" : "ITE Lafarge",
            "code" : "CST"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "BC",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "Bifurcation de Clermont",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "BR",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "Bifurcation de Roanne",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "BV",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "BY",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "Bifurcation de Vichy",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "EP",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "ITE Vicat",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "FA",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "Faisceau",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "P1",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "Aiguille 1 Km 353,6",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732206,
            "codeCH" : "P2",
            "nameUIC" : "St-Germain-des-Fossés",
            "nameCH" : "Aiguille Km 354,8",
            "code" : "SGF"
        },
        {
            "codeUIC" : 732248,
            "codeCH" : 0,
            "nameUIC" : "Randan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 732263,
            "codeCH" : 0,
            "nameUIC" : "Thuret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 732305,
            "codeCH" : "BV",
            "nameUIC" : "St-Yorre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SYO"
        },
        {
            "codeUIC" : 732321,
            "codeCH" : 0,
            "nameUIC" : "Puy-Guillaume",
            "nameCH" : "",
            "code" : "PUG"
        },
        {
            "codeUIC" : 732404,
            "codeCH" : "BV",
            "nameUIC" : "Gannat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GAN"
        },
        {
            "codeUIC" : 732404,
            "codeCH" : "P1",
            "nameUIC" : "Gannat",
            "nameCH" : "Poste 1",
            "code" : "GAN"
        },
        {
            "codeUIC" : 732529,
            "codeCH" : 0,
            "nameUIC" : "St-Rémy-en-Rollat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 732602,
            "codeCH" : 0,
            "nameUIC" : "St-Gérand-le-Puy-Magnet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 732610,
            "codeCH" : 0,
            "nameUIC" : "Lapalisse-St-Prix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 732651,
            "codeCH" : "TU",
            "nameUIC" : "St-Martin-Sail-les-Bains",
            "nameCH" : "Tunnel Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 732651,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-Sail-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 732685,
            "codeCH" : 0,
            "nameUIC" : "St-Germain-l'Espinasse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "AG",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Aiguille Km 417,2",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "AL",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Aulnat",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "BG",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Aiguille Km 412,3",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "BT",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Bifurcation de Thiers",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "BV",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "ES",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Aiguille Km 418,2",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "GB",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Gare Basse",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "KM",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Aiguille Km 1,7",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "SE",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "S.PR 605",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734004,
            "codeCH" : "TU",
            "nameUIC" : "Clermont-Ferrand",
            "nameCH" : "Bifurcation de Tulle",
            "code" : "CFR"
        },
        {
            "codeUIC" : 734038,
            "codeCH" : "S1",
            "nameUIC" : "Royat-Chamalières",
            "nameCH" : "Sémaphore 607",
            "code" : ""
        },
        {
            "codeUIC" : 734038,
            "codeCH" : "S2",
            "nameUIC" : "Royat-Chamalières",
            "nameCH" : "Sémaphore 604",
            "code" : ""
        },
        {
            "codeUIC" : 734038,
            "codeCH" : 0,
            "nameUIC" : "Royat-Chamalières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734046,
            "codeCH" : 0,
            "nameUIC" : "Gerzat",
            "nameCH" : "",
            "code" : "GEZ"
        },
        {
            "codeUIC" : 734053,
            "codeCH" : "BV",
            "nameUIC" : "Riom-Châtel-Guyon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RCÂ"
        },
        {
            "codeUIC" : 734053,
            "codeCH" : "C1",
            "nameUIC" : "Riom-Châtel-Guyon",
            "nameCH" : "Carré 101",
            "code" : "RCÂ"
        },
        {
            "codeUIC" : 734053,
            "codeCH" : "EP",
            "nameUIC" : "Riom-Châtel-Guyon",
            "nameCH" : "ITE SEV",
            "code" : "RCÂ"
        },
        {
            "codeUIC" : 734061,
            "codeCH" : 0,
            "nameUIC" : "Ennezat-Clerlande",
            "nameCH" : "",
            "code" : "ENC"
        },
        {
            "codeUIC" : 734087,
            "codeCH" : 0,
            "nameUIC" : "Pont-du-Château",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734103,
            "codeCH" : 0,
            "nameUIC" : "Pontmort",
            "nameCH" : "",
            "code" : "PMT"
        },
        {
            "codeUIC" : 734111,
            "codeCH" : 0,
            "nameUIC" : "Aubiat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734129,
            "codeCH" : 0,
            "nameUIC" : "Aigueperse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734152,
            "codeCH" : "BV",
            "nameUIC" : "Sarliève-Cournon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SLC"
        },
        {
            "codeUIC" : 734152,
            "codeCH" : "ES",
            "nameUIC" : "Sarliève-Cournon",
            "nameCH" : "ITE Esso Standard",
            "code" : "SLC"
        },
        {
            "codeUIC" : 734160,
            "codeCH" : 0,
            "nameUIC" : "Le Cendre-Orcet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734178,
            "codeCH" : 0,
            "nameUIC" : "Les Martres-de-Veyre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734186,
            "codeCH" : "AG",
            "nameUIC" : "Vic-le-Comte",
            "nameCH" : "Aiguille 505",
            "code" : ""
        },
        {
            "codeUIC" : 734186,
            "codeCH" : 0,
            "nameUIC" : "Vic-le-Comte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734194,
            "codeCH" : 0,
            "nameUIC" : "Parent-Coudes-Champeix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734202,
            "codeCH" : "BV",
            "nameUIC" : "Issoire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ISS"
        },
        {
            "codeUIC" : 734202,
            "codeCH" : "ES",
            "nameUIC" : "Issoire",
            "nameCH" : "ITE SCAL",
            "code" : "ISS"
        },
        {
            "codeUIC" : 734228,
            "codeCH" : "SE",
            "nameUIC" : "Le Breuil-sur-Couze",
            "nameCH" : "S.PR 192",
            "code" : ""
        },
        {
            "codeUIC" : 734228,
            "codeCH" : 0,
            "nameUIC" : "Le Breuil-sur-Couze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734244,
            "codeCH" : "BV",
            "nameUIC" : "Brassac-les-Mines-Ste-Florine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 734251,
            "codeCH" : "BV",
            "nameUIC" : "Arvant",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ARV"
        },
        {
            "codeUIC" : 734251,
            "codeCH" : "PK",
            "nameUIC" : "Arvant",
            "nameCH" : "Aiguille Ba",
            "code" : "ARV"
        },
        {
            "codeUIC" : 734269,
            "codeCH" : 0,
            "nameUIC" : "Brioude",
            "nameCH" : "",
            "code" : "BRD"
        },
        {
            "codeUIC" : 734293,
            "codeCH" : 0,
            "nameUIC" : "Paulhaguet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734301,
            "codeCH" : 0,
            "nameUIC" : "St-Georges-d'Aurac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734319,
            "codeCH" : "BV",
            "nameUIC" : "Langeac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LNG"
        },
        {
            "codeUIC" : 734327,
            "codeCH" : 0,
            "nameUIC" : "Chanteuges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734335,
            "codeCH" : "BV",
            "nameUIC" : "Monistrol-d'Allier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 734343,
            "codeCH" : "VI",
            "nameUIC" : "Chapeauroux",
            "nameCH" : "Viaduc",
            "code" : ""
        },
        {
            "codeUIC" : 734343,
            "codeCH" : 0,
            "nameUIC" : "Chapeauroux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734350,
            "codeCH" : 0,
            "nameUIC" : "Langogne",
            "nameCH" : "",
            "code" : "LGG"
        },
        {
            "codeUIC" : 734368,
            "codeCH" : 0,
            "nameUIC" : "Luc (Lozère)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734418,
            "codeCH" : "BV",
            "nameUIC" : "Pont-du-Château (FRET)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PTC"
        },
        {
            "codeUIC" : 734426,
            "codeCH" : "BV",
            "nameUIC" : "Vertaizon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 734434,
            "codeCH" : 0,
            "nameUIC" : "Seychalles-Moissat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734442,
            "codeCH" : 0,
            "nameUIC" : "Lezoux",
            "nameCH" : "",
            "code" : "LEZ"
        },
        {
            "codeUIC" : 734459,
            "codeCH" : "IT",
            "nameUIC" : "Pont-de-Dore",
            "nameCH" : "ITE Km 402,4",
            "code" : "PDO"
        },
        {
            "codeUIC" : 734459,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Dore",
            "nameCH" : "",
            "code" : "PDO"
        },
        {
            "codeUIC" : 734475,
            "codeCH" : 0,
            "nameUIC" : "Thiers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734483,
            "codeCH" : 0,
            "nameUIC" : "La Monnerie-St-Rémy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734491,
            "codeCH" : 0,
            "nameUIC" : "Chabreloche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734509,
            "codeCH" : 0,
            "nameUIC" : "Noirétable",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734525,
            "codeCH" : "BV",
            "nameUIC" : "Courpière",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 734525,
            "codeCH" : "EP",
            "nameUIC" : "Courpière",
            "nameCH" : "ITE Laiteries Richemont",
            "code" : ""
        },
        {
            "codeUIC" : 734533,
            "codeCH" : 0,
            "nameUIC" : "Giroux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734541,
            "codeCH" : 0,
            "nameUIC" : "Olliergues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734558,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-David",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734566,
            "codeCH" : 0,
            "nameUIC" : "Vertolaye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734574,
            "codeCH" : 0,
            "nameUIC" : "Ambert",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734582,
            "codeCH" : 0,
            "nameUIC" : "Marsac-en-Livradois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734590,
            "codeCH" : 0,
            "nameUIC" : "Arlanc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734673,
            "codeCH" : 0,
            "nameUIC" : "Darsac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734681,
            "codeCH" : 0,
            "nameUIC" : "Borne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734699,
            "codeCH" : 0,
            "nameUIC" : "Le Puy-en-Velay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734707,
            "codeCH" : 0,
            "nameUIC" : "Lavoûte-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734715,
            "codeCH" : 0,
            "nameUIC" : "St-Vincent-le-Château",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734723,
            "codeCH" : 0,
            "nameUIC" : "Vorey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734731,
            "codeCH" : 0,
            "nameUIC" : "Chamalières-sur-Loire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734749,
            "codeCH" : 0,
            "nameUIC" : "Retournac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734855,
            "codeCH" : 0,
            "nameUIC" : "Lempdes (Haute-Loire)",
            "nameCH" : "",
            "code" : "LMP"
        },
        {
            "codeUIC" : 734863,
            "codeCH" : 0,
            "nameUIC" : "Blesle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734871,
            "codeCH" : 0,
            "nameUIC" : "Massiac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734889,
            "codeCH" : 0,
            "nameUIC" : "Molompize",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 734897,
            "codeCH" : 0,
            "nameUIC" : "Ferrières-St-Mary",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741009,
            "codeCH" : "AN",
            "nameUIC" : "Chambéry-Challes-les-Eaux",
            "nameCH" : "Aiguille Km 136,8",
            "code" : "CR"
        },
        {
            "codeUIC" : 741009,
            "codeCH" : "AS",
            "nameUIC" : "Chambéry-Challes-les-Eaux",
            "nameCH" : "Aiguille Km 137,9",
            "code" : "CR"
        },
        {
            "codeUIC" : 741009,
            "codeCH" : "BV",
            "nameUIC" : "Chambéry-Challes-les-Eaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CR"
        },
        {
            "codeUIC" : 741009,
            "codeCH" : "DP",
            "nameUIC" : "Chambéry-Challes-les-Eaux",
            "nameCH" : "Dépôt",
            "code" : "CR"
        },
        {
            "codeUIC" : 741009,
            "codeCH" : "EP",
            "nameUIC" : "Chambéry-Challes-les-Eaux",
            "nameCH" : "ITE Gerland",
            "code" : "CR"
        },
        {
            "codeUIC" : 741009,
            "codeCH" : "EV",
            "nameUIC" : "Chambéry-Challes-les-Eaux",
            "nameCH" : "ITE Vicat",
            "code" : "CR"
        },
        {
            "codeUIC" : 741009,
            "codeCH" : "VU",
            "nameUIC" : "Chambéry-Challes-les-Eaux",
            "nameCH" : "Carré 102",
            "code" : "CR"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "AE",
            "nameUIC" : "Culoz",
            "nameCH" : "Aiguille Km 99,3",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "BC",
            "nameUIC" : "Culoz",
            "nameCH" : "Bifurcation de Chambéry",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "BG",
            "nameUIC" : "Culoz",
            "nameCH" : "Bifurcation de Genève",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "BV",
            "nameUIC" : "Culoz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "FI",
            "nameUIC" : "Culoz",
            "nameCH" : "Faisceau Impair",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "FP",
            "nameUIC" : "Culoz",
            "nameCH" : "Faisceau Pair",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "S1",
            "nameUIC" : "Culoz",
            "nameCH" : "Sémaphore 11",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "S2",
            "nameUIC" : "Culoz",
            "nameCH" : "Carré 318",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "VC",
            "nameUIC" : "Culoz",
            "nameCH" : "Voie 4 Circulation",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741074,
            "codeCH" : "V3",
            "nameUIC" : "Culoz",
            "nameCH" : "Voie 3 Garage",
            "code" : "CUZ"
        },
        {
            "codeUIC" : 741108,
            "codeCH" : 0,
            "nameUIC" : "Vions-Chanaz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741116,
            "codeCH" : 0,
            "nameUIC" : "Chindrieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741132,
            "codeCH" : "BV",
            "nameUIC" : "Aix-les-Bains-Le Revard",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 741132,
            "codeCH" : "PK",
            "nameUIC" : "Aix-les-Bains-Le Revard",
            "nameCH" : "Pk 124,9",
            "code" : ""
        },
        {
            "codeUIC" : 741132,
            "codeCH" : "P1",
            "nameUIC" : "Aix-les-Bains-Le Revard",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 741132,
            "codeCH" : "P2",
            "nameUIC" : "Aix-les-Bains-Le Revard",
            "nameCH" : "Voie Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 741132,
            "codeCH" : "VU",
            "nameUIC" : "Aix-les-Bains-Le Revard",
            "nameCH" : "Bifurcation Km 0,376",
            "code" : ""
        },
        {
            "codeUIC" : 741140,
            "codeCH" : 0,
            "nameUIC" : "Viviers-du-Lac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741181,
            "codeCH" : "BG",
            "nameUIC" : "Montmélian",
            "nameCH" : "Bifurcation de Grenoble",
            "code" : "MML"
        },
        {
            "codeUIC" : 741181,
            "codeCH" : "BM",
            "nameUIC" : "Montmélian",
            "nameCH" : "Bifurcation Km 150,6",
            "code" : "MML"
        },
        {
            "codeUIC" : 741181,
            "codeCH" : "BR",
            "nameUIC" : "Montmélian",
            "nameCH" : "Bif Rac 22",
            "code" : "MML"
        },
        {
            "codeUIC" : 741181,
            "codeCH" : "BV",
            "nameUIC" : "Montmélian",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MML"
        },
        {
            "codeUIC" : 741181,
            "codeCH" : "PB",
            "nameUIC" : "Montmélian",
            "nameCH" : "Poste B",
            "code" : "MML"
        },
        {
            "codeUIC" : 741181,
            "codeCH" : 22,
            "nameUIC" : "Montmélian",
            "nameCH" : "Voie 22-Faisceau",
            "code" : "MML"
        },
        {
            "codeUIC" : 741199,
            "codeCH" : 0,
            "nameUIC" : "Ste-Hélène-du-Lac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741223,
            "codeCH" : "BA",
            "nameUIC" : "St-Pierre-d'Albigny",
            "nameCH" : "Bifurcation Km 1,8",
            "code" : "SPR"
        },
        {
            "codeUIC" : 741223,
            "codeCH" : "BB",
            "nameUIC" : "St-Pierre-d'Albigny",
            "nameCH" : "Bifurcation Km 164,1",
            "code" : "SPR"
        },
        {
            "codeUIC" : 741223,
            "codeCH" : "BV",
            "nameUIC" : "St-Pierre-d'Albigny",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SPR"
        },
        {
            "codeUIC" : 741231,
            "codeCH" : 0,
            "nameUIC" : "Chamousset",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741256,
            "codeCH" : "BV",
            "nameUIC" : "Aiguebelle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AEB"
        },
        {
            "codeUIC" : 741280,
            "codeCH" : "BV",
            "nameUIC" : "Épierre-St-Léger",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "EPS"
        },
        {
            "codeUIC" : 741280,
            "codeCH" : "UK",
            "nameUIC" : "Épierre-St-Léger",
            "nameCH" : "ITE Atochem",
            "code" : "EPS"
        },
        {
            "codeUIC" : 741280,
            "codeCH" : "1B",
            "nameUIC" : "Épierre-St-Léger",
            "nameCH" : "Voie 1Bis",
            "code" : "EPS"
        },
        {
            "codeUIC" : 741280,
            "codeCH" : "2B",
            "nameUIC" : "Épierre-St-Léger",
            "nameCH" : "Voie 2Bis",
            "code" : "EPS"
        },
        {
            "codeUIC" : 741306,
            "codeCH" : "AN",
            "nameUIC" : "St-Avre-la-Chambre",
            "nameCH" : "Aiguille Km 198,7",
            "code" : "SAC"
        },
        {
            "codeUIC" : 741306,
            "codeCH" : "AS",
            "nameUIC" : "St-Avre-la-Chambre",
            "nameCH" : "Aiguille Km 200,1",
            "code" : "SAC"
        },
        {
            "codeUIC" : 741306,
            "codeCH" : "BV",
            "nameUIC" : "St-Avre-la-Chambre",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SAC"
        },
        {
            "codeUIC" : 741306,
            "codeCH" : "RL",
            "nameUIC" : "St-Avre-la-Chambre",
            "nameCH" : "Faisceau Relais",
            "code" : "SAC"
        },
        {
            "codeUIC" : 741306,
            "codeCH" : "1B",
            "nameUIC" : "St-Avre-la-Chambre",
            "nameCH" : "Voie 1Bis",
            "code" : "SAC"
        },
        {
            "codeUIC" : 741413,
            "codeCH" : 0,
            "nameUIC" : "St-Cassin-la-Cascade",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741421,
            "codeCH" : 0,
            "nameUIC" : "Aiguebelette-le-Lac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741439,
            "codeCH" : 0,
            "nameUIC" : "Lépin-le-Lac-La Bauche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741447,
            "codeCH" : 0,
            "nameUIC" : "La Bridoire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741454,
            "codeCH" : 0,
            "nameUIC" : "St-Béron-La Bridoire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741470,
            "codeCH" : 0,
            "nameUIC" : "Pont-de-Beauvoisin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741488,
            "codeCH" : 0,
            "nameUIC" : "Pressins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741496,
            "codeCH" : 0,
            "nameUIC" : "Les Abrets-Fitilieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741504,
            "codeCH" : "BF",
            "nameUIC" : "Virieu-le-Grand-Belley",
            "nameCH" : "AG IPCS Km 89,1",
            "code" : ""
        },
        {
            "codeUIC" : 741504,
            "codeCH" : "BV",
            "nameUIC" : "Virieu-le-Grand-Belley",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 741553,
            "codeCH" : 0,
            "nameUIC" : "Belley",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741561,
            "codeCH" : "BV",
            "nameUIC" : "Brens-Virignin",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BVG"
        },
        {
            "codeUIC" : 741561,
            "codeCH" : "EP",
            "nameUIC" : "Brens-Virignin",
            "nameCH" : "ITE Cérégrain",
            "code" : "BVG"
        },
        {
            "codeUIC" : 741603,
            "codeCH" : 0,
            "nameUIC" : "Fréterive",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741611,
            "codeCH" : 0,
            "nameUIC" : "Grésy-sur-Isère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741629,
            "codeCH" : 0,
            "nameUIC" : "Frontenex",
            "nameCH" : "",
            "code" : "FOX"
        },
        {
            "codeUIC" : 741637,
            "codeCH" : 0,
            "nameUIC" : "La Rachy-Gilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741645,
            "codeCH" : "BV",
            "nameUIC" : "Albertville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ALV"
        },
        {
            "codeUIC" : 741645,
            "codeCH" : "EP",
            "nameUIC" : "Albertville",
            "nameCH" : "ITE Péchiney",
            "code" : "ALV"
        },
        {
            "codeUIC" : 741645,
            "codeCH" : "EV",
            "nameUIC" : "Albertville",
            "nameCH" : "ITE Vignier",
            "code" : "ALV"
        },
        {
            "codeUIC" : 741645,
            "codeCH" : "KM",
            "nameUIC" : "Albertville",
            "nameCH" : "Pk 22,380",
            "code" : "ALV"
        },
        {
            "codeUIC" : 741645,
            "codeCH" : "PK",
            "nameUIC" : "Albertville",
            "nameCH" : "Pk 26,420",
            "code" : "ALV"
        },
        {
            "codeUIC" : 741660,
            "codeCH" : 0,
            "nameUIC" : "Tours (Savoie)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741678,
            "codeCH" : 0,
            "nameUIC" : "La Bâthie",
            "nameCH" : "",
            "code" : "LBT"
        },
        {
            "codeUIC" : 741686,
            "codeCH" : 0,
            "nameUIC" : "Cevins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741694,
            "codeCH" : "BV",
            "nameUIC" : "Notre-Dame-de-Briançon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NAB"
        },
        {
            "codeUIC" : 741702,
            "codeCH" : 0,
            "nameUIC" : "Petit-Coeur-La Léchère-les-Bai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741710,
            "codeCH" : 0,
            "nameUIC" : "Aigueblanche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741728,
            "codeCH" : 0,
            "nameUIC" : "Moûtiers-Salins-Brides-les-Bai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741744,
            "codeCH" : 0,
            "nameUIC" : "Pomblière-St-Marcel",
            "nameCH" : "",
            "code" : "PBM"
        },
        {
            "codeUIC" : 741751,
            "codeCH" : 0,
            "nameUIC" : "Centron",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741769,
            "codeCH" : 0,
            "nameUIC" : "Aime-La Plagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741777,
            "codeCH" : 0,
            "nameUIC" : "Landry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741785,
            "codeCH" : 0,
            "nameUIC" : "Hauteville-Gondon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 741793,
            "codeCH" : "PK",
            "nameUIC" : "Bourg-St-Maurice",
            "nameCH" : "Pk 78,940",
            "code" : "BSM"
        },
        {
            "codeUIC" : 741793,
            "codeCH" : 0,
            "nameUIC" : "Bourg-St-Maurice",
            "nameCH" : "",
            "code" : "BSM"
        },
        {
            "codeUIC" : 741819,
            "codeCH" : "BV",
            "nameUIC" : "Ugine",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "UGI"
        },
        {
            "codeUIC" : 741819,
            "codeCH" : "EP",
            "nameUIC" : "Ugine",
            "nameCH" : "ITE Ugine Aciers",
            "code" : "UGI"
        },
        {
            "codeUIC" : 742007,
            "codeCH" : "AG",
            "nameUIC" : "Modane",
            "nameCH" : "Accès Faisceau",
            "code" : "MOD"
        },
        {
            "codeUIC" : 742007,
            "codeCH" : "BV",
            "nameUIC" : "Modane",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MOD"
        },
        {
            "codeUIC" : 742007,
            "codeCH" : "FI",
            "nameUIC" : "Modane",
            "nameCH" : "Faisceau Impair",
            "code" : "MOD"
        },
        {
            "codeUIC" : 742007,
            "codeCH" : "FP",
            "nameUIC" : "Modane",
            "nameCH" : "Faisceau Pair",
            "code" : "MOD"
        },
        {
            "codeUIC" : 742007,
            "codeCH" : "PO",
            "nameUIC" : "Modane",
            "nameCH" : "Portique",
            "code" : "MOD"
        },
        {
            "codeUIC" : 742007,
            "codeCH" : "XI",
            "nameUIC" : "Modane",
            "nameCH" : "Frontière France-Italie",
            "code" : "MOD"
        },
        {
            "codeUIC" : 742031,
            "codeCH" : "XS",
            "nameUIC" : "Ambilly",
            "nameCH" : "Frontière France-Suisse",
            "code" : ""
        },
        {
            "codeUIC" : 742031,
            "codeCH" : 0,
            "nameUIC" : "Ambilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 742114,
            "codeCH" : 0,
            "nameUIC" : "ITE Entrepôt de munitions",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 742122,
            "codeCH" : 0,
            "nameUIC" : "Modane-Berchette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 742148,
            "codeCH" : 0,
            "nameUIC" : "Chambéry-Poste-Bois",
            "nameCH" : "Poste de Bois-Plan",
            "code" : ""
        },
        {
            "codeUIC" : 742312,
            "codeCH" : 0,
            "nameUIC" : "Pontamafrey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 742320,
            "codeCH" : "BV",
            "nameUIC" : "St-Jean-de-Maurienne-Arvan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SJM"
        },
        {
            "codeUIC" : 742320,
            "codeCH" : "CR",
            "nameUIC" : "St-Jean-de-Maurienne-Arvan",
            "nameCH" : "Carré Rocheray",
            "code" : "SJM"
        },
        {
            "codeUIC" : 742320,
            "codeCH" : "FR",
            "nameUIC" : "St-Jean-de-Maurienne-Arvan",
            "nameCH" : "Faisceau Réception",
            "code" : "SJM"
        },
        {
            "codeUIC" : 742320,
            "codeCH" : "P1",
            "nameUIC" : "St-Jean-de-Maurienne-Arvan",
            "nameCH" : "Poste 1",
            "code" : "SJM"
        },
        {
            "codeUIC" : 742320,
            "codeCH" : "TR",
            "nameUIC" : "St-Jean-de-Maurienne-Arvan",
            "nameCH" : "Triage",
            "code" : "SJM"
        },
        {
            "codeUIC" : 742353,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-Montricher",
            "nameCH" : "",
            "code" : "SJE"
        },
        {
            "codeUIC" : 742361,
            "codeCH" : 0,
            "nameUIC" : "St-Michel-Valloire",
            "nameCH" : "",
            "code" : "SME"
        },
        {
            "codeUIC" : 742395,
            "codeCH" : 0,
            "nameUIC" : "La Praz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 742700,
            "codeCH" : "A1",
            "nameUIC" : "Publier",
            "nameCH" : "Accès ITE Saeme",
            "code" : ""
        },
        {
            "codeUIC" : 742700,
            "codeCH" : "BR",
            "nameUIC" : "Publier",
            "nameCH" : "ITE Bolloré Technologie",
            "code" : ""
        },
        {
            "codeUIC" : 742700,
            "codeCH" : "EP",
            "nameUIC" : "Publier",
            "nameCH" : "ITE Saeme",
            "code" : ""
        },
        {
            "codeUIC" : 742700,
            "codeCH" : "P1",
            "nameUIC" : "Publier",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 742700,
            "codeCH" : 0,
            "nameUIC" : "Publier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743005,
            "codeCH" : "BV",
            "nameUIC" : "Bourg-en-Bresse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BGB"
        },
        {
            "codeUIC" : 743005,
            "codeCH" : "P1",
            "nameUIC" : "Bourg-en-Bresse",
            "nameCH" : "Poste 1",
            "code" : "BGB"
        },
        {
            "codeUIC" : 743005,
            "codeCH" : "P2",
            "nameUIC" : "Bourg-en-Bresse",
            "nameCH" : "Poste 2",
            "code" : "BGB"
        },
        {
            "codeUIC" : 743005,
            "codeCH" : "SG",
            "nameUIC" : "Bourg-en-Bresse",
            "nameCH" : "Signal 65 et 15",
            "code" : "BGB"
        },
        {
            "codeUIC" : 743104,
            "codeCH" : "BV",
            "nameUIC" : "La Vavrette-Tossiat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 743112,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-du-Mont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743120,
            "codeCH" : 0,
            "nameUIC" : "Pont-d'Ain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743146,
            "codeCH" : "BV",
            "nameUIC" : "Ambronay-Priay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AAI"
        },
        {
            "codeUIC" : 743146,
            "codeCH" : "SM",
            "nameUIC" : "Ambronay-Priay",
            "nameCH" : "ITE Service de l'intendance",
            "code" : "AAI"
        },
        {
            "codeUIC" : 743203,
            "codeCH" : 0,
            "nameUIC" : "St-Étienne-du-Bois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743211,
            "codeCH" : 0,
            "nameUIC" : "Le Moulin-des-Ponts",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743302,
            "codeCH" : "A1",
            "nameUIC" : "Ceyzériat",
            "nameCH" : "Aiguille côté Bourg-en-Bresse",
            "code" : ""
        },
        {
            "codeUIC" : 743302,
            "codeCH" : "A2",
            "nameUIC" : "Ceyzériat",
            "nameCH" : "Aiguille côté Bellegarde",
            "code" : ""
        },
        {
            "codeUIC" : 743302,
            "codeCH" : "BV",
            "nameUIC" : "Ceyzériat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 743302,
            "codeCH" : 0,
            "nameUIC" : "Ceyzériat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743310,
            "codeCH" : 0,
            "nameUIC" : "Senissiat-Revonnas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743328,
            "codeCH" : "A1",
            "nameUIC" : "Villereversure",
            "nameCH" : "Aiguille côté Bourg-en-Bresse",
            "code" : ""
        },
        {
            "codeUIC" : 743328,
            "codeCH" : "A2",
            "nameUIC" : "Villereversure",
            "nameCH" : "Aiguille côté Bellegarde",
            "code" : ""
        },
        {
            "codeUIC" : 743328,
            "codeCH" : "BV",
            "nameUIC" : "Villereversure",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 743328,
            "codeCH" : 0,
            "nameUIC" : "Villereversure",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743336,
            "codeCH" : 0,
            "nameUIC" : "Simandre-sur-Suran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743344,
            "codeCH" : "A1",
            "nameUIC" : "Cize-Bolozon",
            "nameCH" : "Aiguille côté Bourg-en-Bresse",
            "code" : ""
        },
        {
            "codeUIC" : 743344,
            "codeCH" : "A2",
            "nameUIC" : "Cize-Bolozon",
            "nameCH" : "Aiguille côté Bellegarde",
            "code" : ""
        },
        {
            "codeUIC" : 743344,
            "codeCH" : "BV",
            "nameUIC" : "Cize-Bolozon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 743344,
            "codeCH" : 0,
            "nameUIC" : "Cize-Bolozon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743351,
            "codeCH" : "A1",
            "nameUIC" : "Nurieux",
            "nameCH" : "Aiguille côté Bourg-en-Bresse",
            "code" : "NEX"
        },
        {
            "codeUIC" : 743351,
            "codeCH" : "A2",
            "nameUIC" : "Nurieux",
            "nameCH" : "Aiguille côté Bellegarde",
            "code" : "NEX"
        },
        {
            "codeUIC" : 743351,
            "codeCH" : "BV",
            "nameUIC" : "Nurieux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NEX"
        },
        {
            "codeUIC" : 743351,
            "codeCH" : 0,
            "nameUIC" : "Nurieux",
            "nameCH" : "",
            "code" : "NEX"
        },
        {
            "codeUIC" : 743401,
            "codeCH" : 0,
            "nameUIC" : "La Cluse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743419,
            "codeCH" : 0,
            "nameUIC" : "Nantua",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743435,
            "codeCH" : 0,
            "nameUIC" : "Les Neyrolles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743526,
            "codeCH" : 0,
            "nameUIC" : "Bellignat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743534,
            "codeCH" : "BV",
            "nameUIC" : "Oyonnax",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 743534,
            "codeCH" : 0,
            "nameUIC" : "Oyonnax",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743567,
            "codeCH" : 0,
            "nameUIC" : "Dortan-Lavancia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743575,
            "codeCH" : 0,
            "nameUIC" : "Jeurre-Vaux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743583,
            "codeCH" : 0,
            "nameUIC" : "Vaux-lès-St-Claude",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743591,
            "codeCH" : 0,
            "nameUIC" : "Molinges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743609,
            "codeCH" : 0,
            "nameUIC" : "Chassal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743617,
            "codeCH" : 0,
            "nameUIC" : "Lavans-St-Lupicin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743633,
            "codeCH" : "BV",
            "nameUIC" : "St-Claude",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 743666,
            "codeCH" : 0,
            "nameUIC" : "La Rixouse-Villard",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743674,
            "codeCH" : 0,
            "nameUIC" : "Lézat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743682,
            "codeCH" : 0,
            "nameUIC" : "Tancua",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743708,
            "codeCH" : "BV",
            "nameUIC" : "Leyment",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LEY"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "AG",
            "nameUIC" : "Ambérieu",
            "nameCH" : "ITE DDE",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "AR",
            "nameUIC" : "Ambérieu",
            "nameCH" : "ITE Ortrans",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "BV",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "DP",
            "nameUIC" : "Ambérieu",
            "nameCH" : "",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "EP",
            "nameUIC" : "Ambérieu",
            "nameCH" : "ITE Zone Industrielle Marcelpoil",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "FD",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Faisceau départ",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "FR",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Faisceau Réception",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "P1",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Poste 1",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "P2",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Poste 2",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "P3",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Poste 3",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "P4",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Aiguille Km 53,6",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "P5",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Poste 5",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "RL",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Faisceau Relais Lyon",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "RR",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Faisceau Relais",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "TR",
            "nameUIC" : "Ambérieu",
            "nameCH" : "Triage",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : "ZI",
            "nameUIC" : "Ambérieu",
            "nameCH" : "ITE du Pipa",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : 25,
            "nameUIC" : "Ambérieu",
            "nameCH" : "Carré 25",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : 34,
            "nameUIC" : "Ambérieu",
            "nameCH" : "Carré 33",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : 47,
            "nameUIC" : "Ambérieu",
            "nameCH" : "Signal 49",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743716,
            "codeCH" : 49,
            "nameUIC" : "Ambérieu",
            "nameCH" : "Carré 49",
            "code" : "AMB"
        },
        {
            "codeUIC" : 743732,
            "codeCH" : 0,
            "nameUIC" : "Torcieu",
            "nameCH" : "Km 57,1",
            "code" : ""
        },
        {
            "codeUIC" : 743740,
            "codeCH" : 0,
            "nameUIC" : "St-Rambert-en-Bugey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743757,
            "codeCH" : "VA",
            "nameUIC" : "Tenay-Hauteville",
            "nameCH" : "Voie A Garage",
            "code" : ""
        },
        {
            "codeUIC" : 743757,
            "codeCH" : 0,
            "nameUIC" : "Tenay-Hauteville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 743815,
            "codeCH" : 0,
            "nameUIC" : "Lagnieu",
            "nameCH" : "",
            "code" : "LGU"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "AG",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Aiguille Km 63,9",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "AI",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Aiguille Km 135,1",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "AS",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Aiguilles Sud",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "BV",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "FP",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Faisceau Pair",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "P1",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Poste 1",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "SI",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "ITE SIDEFAGE",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : "S2",
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Sémaphore 52",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745000,
            "codeCH" : 42,
            "nameUIC" : "Bellegarde (Ain)",
            "nameCH" : "Poste 2",
            "code" : "BGD"
        },
        {
            "codeUIC" : 745109,
            "codeCH" : "A2",
            "nameUIC" : "Longeray-Léaz",
            "nameCH" : "Aiguille côté Évian",
            "code" : ""
        },
        {
            "codeUIC" : 745109,
            "codeCH" : "BV",
            "nameUIC" : "Longeray-Léaz",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 745117,
            "codeCH" : 0,
            "nameUIC" : "Fort-l'Écluse-Collonges",
            "nameCH" : "",
            "code" : "FOR"
        },
        {
            "codeUIC" : 745125,
            "codeCH" : 0,
            "nameUIC" : "Airans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745133,
            "codeCH" : 0,
            "nameUIC" : "Paradis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745141,
            "codeCH" : 0,
            "nameUIC" : "Péron-Farges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745158,
            "codeCH" : 0,
            "nameUIC" : "Greny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745166,
            "codeCH" : 0,
            "nameUIC" : "Sous-Péron",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745174,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-de-Gonville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745182,
            "codeCH" : 0,
            "nameUIC" : "Fenières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745190,
            "codeCH" : 0,
            "nameUIC" : "Thoiry",
            "nameCH" : "",
            "code" : "TOI"
        },
        {
            "codeUIC" : 745216,
            "codeCH" : 0,
            "nameUIC" : "Allemogne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745224,
            "codeCH" : 0,
            "nameUIC" : "Sergy-St-Genis",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745232,
            "codeCH" : 0,
            "nameUIC" : "Flies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745240,
            "codeCH" : 0,
            "nameUIC" : "Chevry",
            "nameCH" : "",
            "code" : "CV"
        },
        {
            "codeUIC" : 745257,
            "codeCH" : 0,
            "nameUIC" : "Cessy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745265,
            "codeCH" : 0,
            "nameUIC" : "Échenevex",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745273,
            "codeCH" : 0,
            "nameUIC" : "Gex",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745299,
            "codeCH" : 0,
            "nameUIC" : "Tutegny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745315,
            "codeCH" : 0,
            "nameUIC" : "Pitegny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745323,
            "codeCH" : 0,
            "nameUIC" : "Grilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745331,
            "codeCH" : 0,
            "nameUIC" : "Arbère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745349,
            "codeCH" : 0,
            "nameUIC" : "Divonne-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745356,
            "codeCH" : 0,
            "nameUIC" : "Champfleury",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745372,
            "codeCH" : 0,
            "nameUIC" : "Les Chartreux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745380,
            "codeCH" : "XS",
            "nameUIC" : "Pougny-Chancy",
            "nameCH" : "Frontière France-Suisse",
            "code" : ""
        },
        {
            "codeUIC" : 745380,
            "codeCH" : 0,
            "nameUIC" : "Pougny-Chancy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745406,
            "codeCH" : 0,
            "nameUIC" : "Chevrier-Vulbens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745414,
            "codeCH" : "A1",
            "nameUIC" : "Valleiry",
            "nameCH" : "Aiguille côté Longeray",
            "code" : "VEY"
        },
        {
            "codeUIC" : 745414,
            "codeCH" : "A2",
            "nameUIC" : "Valleiry",
            "nameCH" : "Aiguille côté Évian",
            "code" : "VEY"
        },
        {
            "codeUIC" : 745414,
            "codeCH" : "BV",
            "nameUIC" : "Valleiry",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VEY"
        },
        {
            "codeUIC" : 745414,
            "codeCH" : "EP",
            "nameUIC" : "Valleiry",
            "nameCH" : "ITE FOL",
            "code" : "VEY"
        },
        {
            "codeUIC" : 745422,
            "codeCH" : "A1",
            "nameUIC" : "Viry",
            "nameCH" : "Aiguille côté Longeray",
            "code" : "VIY"
        },
        {
            "codeUIC" : 745422,
            "codeCH" : "A2",
            "nameUIC" : "Viry",
            "nameCH" : "Aiguille côté Évian",
            "code" : "VIY"
        },
        {
            "codeUIC" : 745422,
            "codeCH" : "BV",
            "nameUIC" : "Viry",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VIY"
        },
        {
            "codeUIC" : 745430,
            "codeCH" : "A1",
            "nameUIC" : "St-Julien-en-Genevois",
            "nameCH" : "Aiguille côté Longeray",
            "code" : "SJG"
        },
        {
            "codeUIC" : 745430,
            "codeCH" : "A2",
            "nameUIC" : "St-Julien-en-Genevois",
            "nameCH" : "Aiguille côté Évian",
            "code" : "SJG"
        },
        {
            "codeUIC" : 745430,
            "codeCH" : "BV",
            "nameUIC" : "St-Julien-en-Genevois",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SJG"
        },
        {
            "codeUIC" : 745430,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-en-Genevois",
            "nameCH" : "",
            "code" : "SJG"
        },
        {
            "codeUIC" : 745471,
            "codeCH" : "A1",
            "nameUIC" : "Bossey-Veyrier",
            "nameCH" : "Aiguille côté Longeray",
            "code" : "BSV"
        },
        {
            "codeUIC" : 745471,
            "codeCH" : "A2",
            "nameUIC" : "Bossey-Veyrier",
            "nameCH" : "Aiguille côté Évian",
            "code" : "BSV"
        },
        {
            "codeUIC" : 745471,
            "codeCH" : "BV",
            "nameUIC" : "Bossey-Veyrier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BSV"
        },
        {
            "codeUIC" : 745497,
            "codeCH" : "BV",
            "nameUIC" : "Annemasse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AE"
        },
        {
            "codeUIC" : 745497,
            "codeCH" : "J1",
            "nameUIC" : "Annemasse",
            "nameCH" : "Jonction 1",
            "code" : "AE"
        },
        {
            "codeUIC" : 745497,
            "codeCH" : "J2",
            "nameUIC" : "Annemasse",
            "nameCH" : "Jonction 2",
            "code" : "AE"
        },
        {
            "codeUIC" : 745497,
            "codeCH" : "P1",
            "nameUIC" : "Annemasse",
            "nameCH" : "Poste 1",
            "code" : "AE"
        },
        {
            "codeUIC" : 745497,
            "codeCH" : "P2",
            "nameUIC" : "Annemasse",
            "nameCH" : "Poste 2",
            "code" : "AE"
        },
        {
            "codeUIC" : 745513,
            "codeCH" : 0,
            "nameUIC" : "ITE Bozel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745521,
            "codeCH" : 0,
            "nameUIC" : "Aig IPCS Km 64,2",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745539,
            "codeCH" : 0,
            "nameUIC" : "La Burbanche",
            "nameCH" : "Aiguille IPCS Km 76,0",
            "code" : ""
        },
        {
            "codeUIC" : 745554,
            "codeCH" : 0,
            "nameUIC" : "ITE SERS",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745570,
            "codeCH" : "A1",
            "nameUIC" : "St-Cergues-les-Voirons",
            "nameCH" : "Aiguille côté Longeray",
            "code" : "SCV"
        },
        {
            "codeUIC" : 745570,
            "codeCH" : "A2",
            "nameUIC" : "St-Cergues-les-Voirons",
            "nameCH" : "Aiguille côté Évian",
            "code" : "SCV"
        },
        {
            "codeUIC" : 745570,
            "codeCH" : "BV",
            "nameUIC" : "St-Cergues-les-Voirons",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SCV"
        },
        {
            "codeUIC" : 745588,
            "codeCH" : "A1",
            "nameUIC" : "Machilly",
            "nameCH" : "Aiguille côté Longeray",
            "code" : ""
        },
        {
            "codeUIC" : 745588,
            "codeCH" : "A2",
            "nameUIC" : "Machilly",
            "nameCH" : "Aiguille côté Évian",
            "code" : ""
        },
        {
            "codeUIC" : 745588,
            "codeCH" : "BV",
            "nameUIC" : "Machilly",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 745588,
            "codeCH" : 0,
            "nameUIC" : "Machilly",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745596,
            "codeCH" : "A1",
            "nameUIC" : "Bons-en-Chablais",
            "nameCH" : "Aiguille côté Longeray",
            "code" : ""
        },
        {
            "codeUIC" : 745596,
            "codeCH" : "A2",
            "nameUIC" : "Bons-en-Chablais",
            "nameCH" : "Aiguille côté Évian",
            "code" : ""
        },
        {
            "codeUIC" : 745596,
            "codeCH" : "BV",
            "nameUIC" : "Bons-en-Chablais",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 745620,
            "codeCH" : "A1",
            "nameUIC" : "Perrignier",
            "nameCH" : "Aiguille côté Longeray",
            "code" : "PEG"
        },
        {
            "codeUIC" : 745620,
            "codeCH" : "A2",
            "nameUIC" : "Perrignier",
            "nameCH" : "Aiguille côté Évian",
            "code" : "PEG"
        },
        {
            "codeUIC" : 745620,
            "codeCH" : "BV",
            "nameUIC" : "Perrignier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PEG"
        },
        {
            "codeUIC" : 745638,
            "codeCH" : 0,
            "nameUIC" : "Allinges-Mesinges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745646,
            "codeCH" : "A1",
            "nameUIC" : "Thonon-les-Bains",
            "nameCH" : "Aiguille côté Longeray",
            "code" : "THO"
        },
        {
            "codeUIC" : 745646,
            "codeCH" : "A2",
            "nameUIC" : "Thonon-les-Bains",
            "nameCH" : "Aiguille côté Évian",
            "code" : "THO"
        },
        {
            "codeUIC" : 745646,
            "codeCH" : "BV",
            "nameUIC" : "Thonon-les-Bains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "THO"
        },
        {
            "codeUIC" : 745679,
            "codeCH" : "BV",
            "nameUIC" : "Évian-les-Bains",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ELB"
        },
        {
            "codeUIC" : 745711,
            "codeCH" : "A1",
            "nameUIC" : "Châtillon-en-Michaille",
            "nameCH" : "Aiguille côté Bourg-en-Bresse",
            "code" : ""
        },
        {
            "codeUIC" : 745711,
            "codeCH" : "A2",
            "nameUIC" : "Châtillon-en-Michaille",
            "nameCH" : "Aiguille côté Bellegarde",
            "code" : ""
        },
        {
            "codeUIC" : 745711,
            "codeCH" : "BV",
            "nameUIC" : "Châtillon-en-Michaille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 745711,
            "codeCH" : 0,
            "nameUIC" : "Châtillon-en-Michaille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745737,
            "codeCH" : "A1",
            "nameUIC" : "Charix-Lalleyriat",
            "nameCH" : "Aiguille côté Bourg-en-Bresse",
            "code" : ""
        },
        {
            "codeUIC" : 745737,
            "codeCH" : "A2",
            "nameUIC" : "Charix-Lalleyriat",
            "nameCH" : "Aiguille côté Bellegarde",
            "code" : ""
        },
        {
            "codeUIC" : 745737,
            "codeCH" : "BV",
            "nameUIC" : "Charix-Lalleyriat",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 745737,
            "codeCH" : "CR",
            "nameUIC" : "Charix-Lalleyriat",
            "nameCH" : "Croisements (Ex BV)",
            "code" : ""
        },
        {
            "codeUIC" : 745737,
            "codeCH" : 0,
            "nameUIC" : "Charix-Lalleyriat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 745810,
            "codeCH" : "BV",
            "nameUIC" : "Pyrimont-Chanay",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 745810,
            "codeCH" : "S1",
            "nameUIC" : "Pyrimont-Chanay",
            "nameCH" : "Carré 45",
            "code" : ""
        },
        {
            "codeUIC" : 745810,
            "codeCH" : "S2",
            "nameUIC" : "Pyrimont-Chanay",
            "nameCH" : "Carré 46",
            "code" : ""
        },
        {
            "codeUIC" : 745828,
            "codeCH" : "BV",
            "nameUIC" : "Seyssel-Corbonod",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 745828,
            "codeCH" : "S1",
            "nameUIC" : "Seyssel-Corbonod",
            "nameCH" : "Sémaphore 41",
            "code" : ""
        },
        {
            "codeUIC" : 745828,
            "codeCH" : "S2",
            "nameUIC" : "Seyssel-Corbonod",
            "nameCH" : "Carré 32",
            "code" : ""
        },
        {
            "codeUIC" : 745836,
            "codeCH" : "BV",
            "nameUIC" : "Anglefort",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ANF"
        },
        {
            "codeUIC" : 745836,
            "codeCH" : "S1",
            "nameUIC" : "Anglefort",
            "nameCH" : "Sémaphore 21",
            "code" : "ANF"
        },
        {
            "codeUIC" : 745836,
            "codeCH" : "S2",
            "nameUIC" : "Anglefort",
            "nameCH" : "Sémaphore 22",
            "code" : "ANF"
        },
        {
            "codeUIC" : 745943,
            "codeCH" : "XS",
            "nameUIC" : "St-Gingolph-Frontière (IE)",
            "nameCH" : "Frontière France-Suisse",
            "code" : ""
        },
        {
            "codeUIC" : 745950,
            "codeCH" : "WS",
            "nameUIC" : "La Plaine",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 746008,
            "codeCH" : "BV",
            "nameUIC" : "Annecy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ACY"
        },
        {
            "codeUIC" : 746008,
            "codeCH" : "PK",
            "nameUIC" : "Annecy",
            "nameCH" : "Carré 29",
            "code" : "ACY"
        },
        {
            "codeUIC" : 746008,
            "codeCH" : "P1",
            "nameUIC" : "Annecy",
            "nameCH" : "Poste 1",
            "code" : "ACY"
        },
        {
            "codeUIC" : 746008,
            "codeCH" : "P2",
            "nameUIC" : "Annecy",
            "nameCH" : "Poste 2",
            "code" : "ACY"
        },
        {
            "codeUIC" : 746107,
            "codeCH" : 0,
            "nameUIC" : "Grésy-sur-Aix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746115,
            "codeCH" : 0,
            "nameUIC" : "Albens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746131,
            "codeCH" : 0,
            "nameUIC" : "Bloye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746149,
            "codeCH" : 0,
            "nameUIC" : "Rumilly",
            "nameCH" : "",
            "code" : "RUM"
        },
        {
            "codeUIC" : 746172,
            "codeCH" : 0,
            "nameUIC" : "Marcellaz-Hauteville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746180,
            "codeCH" : "BV",
            "nameUIC" : "Lovagny-Gorges-du-Fier",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 746206,
            "codeCH" : "BV",
            "nameUIC" : "Pringy (Haute-Savoie)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PGY"
        },
        {
            "codeUIC" : 746214,
            "codeCH" : 0,
            "nameUIC" : "Argonay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746222,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-Bellevue",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746230,
            "codeCH" : 0,
            "nameUIC" : "Charvonnex",
            "nameCH" : "PN 52",
            "code" : ""
        },
        {
            "codeUIC" : 746248,
            "codeCH" : 0,
            "nameUIC" : "Groisy-Thorens-la-Caille",
            "nameCH" : "",
            "code" : "GTC"
        },
        {
            "codeUIC" : 746263,
            "codeCH" : 0,
            "nameUIC" : "Évires",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746289,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent (Haute-Savoie)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746305,
            "codeCH" : "AB",
            "nameUIC" : "La Roche-sur-Foron",
            "nameCH" : "Bifurcation Km 77,1",
            "code" : "LRF"
        },
        {
            "codeUIC" : 746305,
            "codeCH" : "BV",
            "nameUIC" : "La Roche-sur-Foron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LRF"
        },
        {
            "codeUIC" : 746305,
            "codeCH" : "GB",
            "nameUIC" : "La Roche-sur-Foron",
            "nameCH" : "Bifurcation Km 0,5",
            "code" : "LRF"
        },
        {
            "codeUIC" : 746313,
            "codeCH" : 0,
            "nameUIC" : "St-Pierre-en-Faucigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746339,
            "codeCH" : 0,
            "nameUIC" : "Bonneville",
            "nameCH" : "",
            "code" : "BON"
        },
        {
            "codeUIC" : 746347,
            "codeCH" : 0,
            "nameUIC" : "Marignier",
            "nameCH" : "",
            "code" : "MRG"
        },
        {
            "codeUIC" : 746370,
            "codeCH" : "BV",
            "nameUIC" : "Cluses",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 746370,
            "codeCH" : "EP",
            "nameUIC" : "Cluses",
            "nameCH" : "ITE La Maladière",
            "code" : ""
        },
        {
            "codeUIC" : 746412,
            "codeCH" : 0,
            "nameUIC" : "Magland",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746438,
            "codeCH" : 0,
            "nameUIC" : "Sallanches-Combloux-Megève",
            "nameCH" : "",
            "code" : "SLS"
        },
        {
            "codeUIC" : 746479,
            "codeCH" : 0,
            "nameUIC" : "St-Gervais-les-Bains-Le Fayet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746511,
            "codeCH" : 0,
            "nameUIC" : "Reignier",
            "nameCH" : "",
            "code" : "REI"
        },
        {
            "codeUIC" : 746701,
            "codeCH" : 0,
            "nameUIC" : "Chedde-Voie-Étroite",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746719,
            "codeCH" : 0,
            "nameUIC" : "Servoz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746727,
            "codeCH" : 0,
            "nameUIC" : "Vaudagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746735,
            "codeCH" : 0,
            "nameUIC" : "Viaduc-Ste-Marie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746743,
            "codeCH" : 0,
            "nameUIC" : "Les Houches",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746750,
            "codeCH" : 0,
            "nameUIC" : "Taconnaz",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746768,
            "codeCH" : 0,
            "nameUIC" : "Les Bossons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746776,
            "codeCH" : 0,
            "nameUIC" : "Les Pélerins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746784,
            "codeCH" : 0,
            "nameUIC" : "Chamonix-Mont-Blanc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746818,
            "codeCH" : 0,
            "nameUIC" : "Les Moussoux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746826,
            "codeCH" : 0,
            "nameUIC" : "Les Praz-de-Chamonix",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746834,
            "codeCH" : 0,
            "nameUIC" : "Les Tines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746842,
            "codeCH" : 0,
            "nameUIC" : "La Joux (Haute-Savoie)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746859,
            "codeCH" : 0,
            "nameUIC" : "Argentière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746867,
            "codeCH" : 0,
            "nameUIC" : "Montroc-le-Planet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746875,
            "codeCH" : "XS",
            "nameUIC" : "Vallorcine",
            "nameCH" : "Frontière France-Suisse",
            "code" : ""
        },
        {
            "codeUIC" : 746875,
            "codeCH" : 0,
            "nameUIC" : "Vallorcine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 746883,
            "codeCH" : 0,
            "nameUIC" : "Le Buet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "AL",
            "nameUIC" : "Grenoble",
            "nameCH" : "ITE Neypric",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "BV",
            "nameUIC" : "Grenoble",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "FP",
            "nameUIC" : "Grenoble",
            "nameCH" : "Faisceau Pair",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "LC",
            "nameUIC" : "Grenoble",
            "nameCH" : "ITE La Capuche",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "MI",
            "nameUIC" : "Grenoble",
            "nameCH" : "ITE M I N",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "PB",
            "nameUIC" : "Grenoble",
            "nameCH" : "Poste B",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "P1",
            "nameUIC" : "Grenoble",
            "nameCH" : "Poste 1",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "P2",
            "nameUIC" : "Grenoble",
            "nameCH" : "Poste 2",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "P3",
            "nameUIC" : "Grenoble",
            "nameCH" : "Poste 3",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "VN",
            "nameUIC" : "Grenoble",
            "nameCH" : "Bifurcation de Veynes",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "V1",
            "nameUIC" : "Grenoble",
            "nameCH" : "Aiguille Km 127,9",
            "code" : "GE"
        },
        {
            "codeUIC" : 747006,
            "codeCH" : "3M",
            "nameUIC" : "Grenoble",
            "nameCH" : "Quai Militaire",
            "code" : "GE"
        },
        {
            "codeUIC" : 747204,
            "codeCH" : 0,
            "nameUIC" : "Virieu-sur-Bourbre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747212,
            "codeCH" : 0,
            "nameUIC" : "Châbons",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747220,
            "codeCH" : 0,
            "nameUIC" : "Le Grand-Lemps",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747238,
            "codeCH" : 0,
            "nameUIC" : "Beaucroissant",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747246,
            "codeCH" : 0,
            "nameUIC" : "Rives",
            "nameCH" : "",
            "code" : "RIV"
        },
        {
            "codeUIC" : 747279,
            "codeCH" : 0,
            "nameUIC" : "Réaumont-St-Cassien",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747287,
            "codeCH" : 0,
            "nameUIC" : "Voiron",
            "nameCH" : "",
            "code" : "VOR"
        },
        {
            "codeUIC" : 747311,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-de-Moirans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747329,
            "codeCH" : 0,
            "nameUIC" : "Moirans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747337,
            "codeCH" : "BV",
            "nameUIC" : "Voreppe",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VPE"
        },
        {
            "codeUIC" : 747337,
            "codeCH" : "EP",
            "nameUIC" : "Voreppe",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "VPE"
        },
        {
            "codeUIC" : 747352,
            "codeCH" : "BV",
            "nameUIC" : "St-Égrève-St-Robert",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SER"
        },
        {
            "codeUIC" : 747352,
            "codeCH" : "EP",
            "nameUIC" : "St-Égrève-St-Robert",
            "nameCH" : "ITE BP et Setag",
            "code" : "SER"
        },
        {
            "codeUIC" : 747402,
            "codeCH" : "IT",
            "nameUIC" : "Grenoble-Université-Gières",
            "nameCH" : "ITE Km 10,8",
            "code" : "GEU"
        },
        {
            "codeUIC" : 747402,
            "codeCH" : 0,
            "nameUIC" : "Grenoble-Université-Gières",
            "nameCH" : "",
            "code" : "GEU"
        },
        {
            "codeUIC" : 747410,
            "codeCH" : 0,
            "nameUIC" : "Domène",
            "nameCH" : "",
            "code" : "DOM"
        },
        {
            "codeUIC" : 747451,
            "codeCH" : 0,
            "nameUIC" : "Brignoud",
            "nameCH" : "",
            "code" : "BNU"
        },
        {
            "codeUIC" : 747469,
            "codeCH" : 0,
            "nameUIC" : "Tencin-Theys",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747477,
            "codeCH" : 0,
            "nameUIC" : "Goncelin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747477,
            "codeCH" : 25,
            "nameUIC" : "Goncelin",
            "nameCH" : "Km 25,5",
            "code" : ""
        },
        {
            "codeUIC" : 747485,
            "codeCH" : 0,
            "nameUIC" : "Le Cheylas-La Buissière",
            "nameCH" : "",
            "code" : "LEL"
        },
        {
            "codeUIC" : 747493,
            "codeCH" : "IT",
            "nameUIC" : "Pontcharra-sur-Breda-Allevard",
            "nameCH" : "ITE Km 35,7",
            "code" : "PCA"
        },
        {
            "codeUIC" : 747493,
            "codeCH" : 0,
            "nameUIC" : "Pontcharra-sur-Breda-Allevard",
            "nameCH" : "",
            "code" : "PCA"
        },
        {
            "codeUIC" : 747519,
            "codeCH" : "BV",
            "nameUIC" : "Pont-de-Claix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PDC"
        },
        {
            "codeUIC" : 747519,
            "codeCH" : "PL",
            "nameUIC" : "Pont-de-Claix",
            "nameCH" : "ITE Enichem",
            "code" : "PDC"
        },
        {
            "codeUIC" : 747519,
            "codeCH" : "PR",
            "nameUIC" : "Pont-de-Claix",
            "nameCH" : "ITE Rhône-Poulenc",
            "code" : "PDC"
        },
        {
            "codeUIC" : 747535,
            "codeCH" : "BV",
            "nameUIC" : "Jarrie-Vizille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "JAV"
        },
        {
            "codeUIC" : 747535,
            "codeCH" : "DA",
            "nameUIC" : "Jarrie-Vizille",
            "nameCH" : "ITE Atochem N° 1",
            "code" : "JAV"
        },
        {
            "codeUIC" : 747535,
            "codeCH" : "NA",
            "nameUIC" : "Jarrie-Vizille",
            "nameCH" : "ITE Atochem N° 4",
            "code" : "JAV"
        },
        {
            "codeUIC" : 747535,
            "codeCH" : "UK",
            "nameUIC" : "Jarrie-Vizille",
            "nameCH" : "ITE Ugine-Kuhl",
            "code" : "JAV"
        },
        {
            "codeUIC" : 747568,
            "codeCH" : 0,
            "nameUIC" : "St-Georges-de-Commiers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747576,
            "codeCH" : "BV",
            "nameUIC" : "Vif",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VIF"
        },
        {
            "codeUIC" : 747584,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-de-la-Cluze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747592,
            "codeCH" : 0,
            "nameUIC" : "Monestier-de-Clermont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747618,
            "codeCH" : 0,
            "nameUIC" : "St-Michel-les-Portes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747626,
            "codeCH" : 0,
            "nameUIC" : "Clelles-Mens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747634,
            "codeCH" : 0,
            "nameUIC" : "Percy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747642,
            "codeCH" : 0,
            "nameUIC" : "St-Maurice-en-Trièves",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747659,
            "codeCH" : 0,
            "nameUIC" : "Col-de-la-Croix-Haute-Lalley",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747667,
            "codeCH" : 0,
            "nameUIC" : "Lus-la-Croix-Haute",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747675,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-en-Beauchêne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747683,
            "codeCH" : 0,
            "nameUIC" : "La Faurie-Montbrand",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 747691,
            "codeCH" : 0,
            "nameUIC" : "Moirans-la-Galifette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "BA",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Bifurcation d'Aix",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "BC",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Bifurcation des Chartreux",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "BF",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Bifurcation des Tuileries",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "BT",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Bifurcation de Toulon",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "BV",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "EG",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Entrée/Sortie Gare",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "JO",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Bifurcation de Joliette",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "LA",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Lavage",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "MS",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Marchandises",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "P1",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Poste 1",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "SA",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "SAS",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751008,
            "codeCH" : "SD",
            "nameUIC" : "Marseille-St-Charles",
            "nameCH" : "Dépôt",
            "code" : "MIM"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "AB",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Bifurcation Km 2,0 Voie Bis",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "AS",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Bifurcation Km 3,6",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "AT",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Aiguille Km 4,2",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "BP",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Bifurcation du Prado Voie 2",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "BV",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "CD",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Km 1,7",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "DV",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "ITE PTT",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "J1",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Jonction 1",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "OR",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Ordures Prado",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "PN",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Panneau 507 Voie 2T",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "PR",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Poste R",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : "SA",
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "ITE Sante",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751081,
            "codeCH" : 12,
            "nameUIC" : "Marseille-Blancarde",
            "nameCH" : "Bifurcation Km 2,7",
            "code" : "MBC"
        },
        {
            "codeUIC" : 751198,
            "codeCH" : 0,
            "nameUIC" : "Mison",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751206,
            "codeCH" : "BV",
            "nameUIC" : "Sisteron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SIO"
        },
        {
            "codeUIC" : 751206,
            "codeCH" : "EP",
            "nameUIC" : "Sisteron",
            "nameCH" : "ITE Sapchim",
            "code" : "SIO"
        },
        {
            "codeUIC" : 751230,
            "codeCH" : "BV",
            "nameUIC" : "Château-Arnoux-St-Auban",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CAA"
        },
        {
            "codeUIC" : 751248,
            "codeCH" : 0,
            "nameUIC" : "Peyruis-Les Mées",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751271,
            "codeCH" : 0,
            "nameUIC" : "La Brillanne-Oraison",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751313,
            "codeCH" : 0,
            "nameUIC" : "Volx",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751321,
            "codeCH" : 0,
            "nameUIC" : "Manosque-Gréoux-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751339,
            "codeCH" : 0,
            "nameUIC" : "Ste-Tulle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751354,
            "codeCH" : 0,
            "nameUIC" : "Mirabeau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751362,
            "codeCH" : "BV",
            "nameUIC" : "Pertuis",
            "nameCH" : "BS",
            "code" : ""
        },
        {
            "codeUIC" : 751370,
            "codeCH" : "BF",
            "nameUIC" : "Meyrargues",
            "nameCH" : "Aiguille Km 371,178",
            "code" : ""
        },
        {
            "codeUIC" : 751370,
            "codeCH" : "BV",
            "nameUIC" : "Meyrargues",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751370,
            "codeCH" : 0,
            "nameUIC" : "Meyrargues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751370,
            "codeCH" : 25,
            "nameUIC" : "Meyrargues",
            "nameCH" : "Carré 25",
            "code" : ""
        },
        {
            "codeUIC" : 751388,
            "codeCH" : 0,
            "nameUIC" : "Venelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751396,
            "codeCH" : 0,
            "nameUIC" : "La Calade-Éguilles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751404,
            "codeCH" : "BR",
            "nameUIC" : "Aix-en-Provence",
            "nameCH" : "Aiguille Km 407,6",
            "code" : ""
        },
        {
            "codeUIC" : 751404,
            "codeCH" : "BV",
            "nameUIC" : "Aix-en-Provence",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751404,
            "codeCH" : 24,
            "nameUIC" : "Aix-en-Provence",
            "nameCH" : "Aiguille Km 24,7",
            "code" : ""
        },
        {
            "codeUIC" : 751420,
            "codeCH" : "BF",
            "nameUIC" : "Gardanne",
            "nameCH" : "Bifurcation Km 417,263",
            "code" : "GDN"
        },
        {
            "codeUIC" : 751420,
            "codeCH" : "BV",
            "nameUIC" : "Gardanne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "GDN"
        },
        {
            "codeUIC" : 751420,
            "codeCH" : "EP",
            "nameUIC" : "Gardanne",
            "nameCH" : "ITE Centrale Thermique",
            "code" : "GDN"
        },
        {
            "codeUIC" : 751420,
            "codeCH" : "VT",
            "nameUIC" : "Gardanne",
            "nameCH" : "Voie Trafic restreint",
            "code" : "GDN"
        },
        {
            "codeUIC" : 751438,
            "codeCH" : 0,
            "nameUIC" : "Simiane",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751446,
            "codeCH" : "BS",
            "nameUIC" : "Bouc-Cabriès",
            "nameCH" : "Bâtiment Service",
            "code" : ""
        },
        {
            "codeUIC" : 751446,
            "codeCH" : 0,
            "nameUIC" : "Bouc-Cabriès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751453,
            "codeCH" : 0,
            "nameUIC" : "Malijai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751461,
            "codeCH" : 0,
            "nameUIC" : "Mallemoisson",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751495,
            "codeCH" : 0,
            "nameUIC" : "Digne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751511,
            "codeCH" : "BV",
            "nameUIC" : "Les Milles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751529,
            "codeCH" : 0,
            "nameUIC" : "L'Ariane",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751537,
            "codeCH" : 0,
            "nameUIC" : "Pourcieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751545,
            "codeCH" : "BS",
            "nameUIC" : "Trets",
            "nameCH" : "Bâtiment service",
            "code" : ""
        },
        {
            "codeUIC" : 751545,
            "codeCH" : "BV",
            "nameUIC" : "Trets",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751552,
            "codeCH" : "BS",
            "nameUIC" : "Peynier-Rousset",
            "nameCH" : "Bâtiment service",
            "code" : ""
        },
        {
            "codeUIC" : 751552,
            "codeCH" : "BV",
            "nameUIC" : "Peynier-Rousset",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751560,
            "codeCH" : "BS",
            "nameUIC" : "La Barque-Fuveau",
            "nameCH" : "Bâtiment service",
            "code" : "LBF"
        },
        {
            "codeUIC" : 751560,
            "codeCH" : 0,
            "nameUIC" : "La Barque-Fuveau",
            "nameCH" : "",
            "code" : "LBF"
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "AB",
            "nameUIC" : "L'Estaque",
            "nameCH" : "Voie M3 de Garage",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "AS",
            "nameUIC" : "L'Estaque",
            "nameCH" : "Accès Sud",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "BM",
            "nameUIC" : "L'Estaque",
            "nameCH" : "Bifurcation Mourepiane",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "BV",
            "nameUIC" : "L'Estaque",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "CA",
            "nameUIC" : "L'Estaque",
            "nameCH" : "ITE Casino",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "KM",
            "nameUIC" : "L'Estaque",
            "nameCH" : "Point Km",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "PA",
            "nameUIC" : "L'Estaque",
            "nameCH" : "Panneau 901",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "PG",
            "nameUIC" : "L'Estaque",
            "nameCH" : "ITE PROCTER",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "PN",
            "nameUIC" : "L'Estaque",
            "nameCH" : "ITE Pennaroya",
            "code" : ""
        },
        {
            "codeUIC" : 751602,
            "codeCH" : "2T",
            "nameUIC" : "L'Estaque",
            "nameCH" : "Voie M4 de Garage",
            "code" : ""
        },
        {
            "codeUIC" : 751636,
            "codeCH" : 0,
            "nameUIC" : "Séon-St-Henry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751644,
            "codeCH" : "AB",
            "nameUIC" : "St-Louis-les-Aygalades",
            "nameCH" : "Km 857,206",
            "code" : "SLA"
        },
        {
            "codeUIC" : 751644,
            "codeCH" : 0,
            "nameUIC" : "St-Louis-les-Aygalades",
            "nameCH" : "",
            "code" : "SLA"
        },
        {
            "codeUIC" : 751685,
            "codeCH" : 0,
            "nameUIC" : "Le Canet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751693,
            "codeCH" : 0,
            "nameUIC" : "St-Barthélémy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751701,
            "codeCH" : "AE",
            "nameUIC" : "St-Marcel",
            "nameCH" : "Aiguille Km 10,3 V2E",
            "code" : "SME"
        },
        {
            "codeUIC" : 751701,
            "codeCH" : "AO",
            "nameUIC" : "St-Marcel",
            "nameCH" : "Aiguille Km 6,6 V2E",
            "code" : "SME"
        },
        {
            "codeUIC" : 751701,
            "codeCH" : "BV",
            "nameUIC" : "St-Marcel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SME"
        },
        {
            "codeUIC" : 751701,
            "codeCH" : "J2",
            "nameUIC" : "St-Marcel",
            "nameCH" : "Jonction 2",
            "code" : "SME"
        },
        {
            "codeUIC" : 751701,
            "codeCH" : "TB",
            "nameUIC" : "St-Marcel",
            "nameCH" : "Km 10,228 V 2E",
            "code" : "SME"
        },
        {
            "codeUIC" : 751701,
            "codeCH" : "V3",
            "nameUIC" : "St-Marcel",
            "nameCH" : "V 3 garage",
            "code" : "SME"
        },
        {
            "codeUIC" : 751719,
            "codeCH" : 0,
            "nameUIC" : "La Pomme",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751743,
            "codeCH" : "BV",
            "nameUIC" : "La Penne-sur-Huveaune",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751743,
            "codeCH" : "EP",
            "nameUIC" : "La Penne-sur-Huveaune",
            "nameCH" : "ITE Km 11,1",
            "code" : ""
        },
        {
            "codeUIC" : 751743,
            "codeCH" : "OR",
            "nameUIC" : "La Penne-sur-Huveaune",
            "nameCH" : "ITE Atochem",
            "code" : ""
        },
        {
            "codeUIC" : 751750,
            "codeCH" : "J3",
            "nameUIC" : "Aubagne",
            "nameCH" : "Jonction 3",
            "code" : "AUB"
        },
        {
            "codeUIC" : 751750,
            "codeCH" : 0,
            "nameUIC" : "Aubagne",
            "nameCH" : "",
            "code" : "AUB"
        },
        {
            "codeUIC" : 751776,
            "codeCH" : "BV",
            "nameUIC" : "Cassis",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CSI"
        },
        {
            "codeUIC" : 751784,
            "codeCH" : 0,
            "nameUIC" : "La Ciotat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751792,
            "codeCH" : 0,
            "nameUIC" : "St-Antoine (M) Poste 1",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751800,
            "codeCH" : "BV",
            "nameUIC" : "Septèmes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 751826,
            "codeCH" : "BF",
            "nameUIC" : "St-Antoine (MR)",
            "nameCH" : "Bifurcation Km 435,670",
            "code" : ""
        },
        {
            "codeUIC" : 751826,
            "codeCH" : 0,
            "nameUIC" : "St-Antoine (MR)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 751842,
            "codeCH" : 0,
            "nameUIC" : "Ste-Marthe-en-Provence",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 752709,
            "codeCH" : "PJ",
            "nameUIC" : "Marseille-Maritime-Joliette",
            "nameCH" : "Poste J",
            "code" : ""
        },
        {
            "codeUIC" : 752709,
            "codeCH" : "PT",
            "nameUIC" : "Marseille-Maritime-Joliette",
            "nameCH" : "Poste T",
            "code" : ""
        },
        {
            "codeUIC" : 752709,
            "codeCH" : 0,
            "nameUIC" : "Marseille-Maritime-Joliette",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 752717,
            "codeCH" : "TR",
            "nameUIC" : "Marseille-Maritime-Canet",
            "nameCH" : "Triage PA",
            "code" : "MMC"
        },
        {
            "codeUIC" : 752725,
            "codeCH" : "AC",
            "nameUIC" : "Marseille-Maritime-Mourepiane",
            "nameCH" : "CH. Mourepiane",
            "code" : ""
        },
        {
            "codeUIC" : 752725,
            "codeCH" : "ES",
            "nameUIC" : "Marseille-Maritime-Mourepiane",
            "nameCH" : "Carré 948",
            "code" : ""
        },
        {
            "codeUIC" : 752725,
            "codeCH" : "TR",
            "nameUIC" : "Marseille-Maritime-Mourepiane",
            "nameCH" : "Poste A",
            "code" : ""
        },
        {
            "codeUIC" : 752808,
            "codeCH" : "FS",
            "nameUIC" : "Marseille-Maritime-Arenc",
            "nameCH" : "Faisceau Sud",
            "code" : "MMA"
        },
        {
            "codeUIC" : 752808,
            "codeCH" : "P1",
            "nameUIC" : "Marseille-Maritime-Arenc",
            "nameCH" : "Poste 1",
            "code" : "MMA"
        },
        {
            "codeUIC" : 752808,
            "codeCH" : "P2",
            "nameUIC" : "Marseille-Maritime-Arenc",
            "nameCH" : "Poste 2",
            "code" : "MMA"
        },
        {
            "codeUIC" : 752808,
            "codeCH" : "RC",
            "nameUIC" : "Marseille-Maritime-Arenc",
            "nameCH" : "Bifurcation Raccordement Canet",
            "code" : "MMA"
        },
        {
            "codeUIC" : 752808,
            "codeCH" : "TR",
            "nameUIC" : "Marseille-Maritime-Arenc",
            "nameCH" : "Triage",
            "code" : "MMA"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "AN",
            "nameUIC" : "Miramas",
            "nameCH" : "Aiguille Km 805,1",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "AR",
            "nameUIC" : "Miramas",
            "nameCH" : "Aiguille Km 818,2",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "AS",
            "nameUIC" : "Miramas",
            "nameCH" : "Bifurcation Km 811,8",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "AT",
            "nameUIC" : "Miramas",
            "nameCH" : "Aiguille Km 806,7",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "A1",
            "nameUIC" : "Miramas",
            "nameCH" : "Bifurcation Km 809,5",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "A2",
            "nameUIC" : "Miramas",
            "nameCH" : "Aiguille 401 et 402",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "BN",
            "nameUIC" : "Miramas",
            "nameCH" : "Bifurcation Km 67",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "BV",
            "nameUIC" : "Miramas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "FD",
            "nameUIC" : "Miramas",
            "nameCH" : "Faisceau Départ",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "FR",
            "nameUIC" : "Miramas",
            "nameCH" : "Faisceau Réception",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "PA",
            "nameUIC" : "Miramas",
            "nameCH" : "Panneau 626",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "PC",
            "nameUIC" : "Miramas",
            "nameCH" : "Triage PC",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "PN",
            "nameUIC" : "Miramas",
            "nameCH" : "Panneau 601",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "RC",
            "nameUIC" : "Miramas",
            "nameCH" : "Bifurcation Cavaillon",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "RL",
            "nameUIC" : "Miramas",
            "nameCH" : "Faisceau Relais",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "SC",
            "nameUIC" : "Miramas",
            "nameCH" : "Panneau 304",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "V3",
            "nameUIC" : "Miramas",
            "nameCH" : "V 3 garage",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753004,
            "codeCH" : "2B",
            "nameUIC" : "Miramas",
            "nameCH" : "Aiguille Km 808,5",
            "code" : "MAS"
        },
        {
            "codeUIC" : 753111,
            "codeCH" : 0,
            "nameUIC" : "Velaux-Coudoux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753202,
            "codeCH" : 0,
            "nameUIC" : "St-Chamas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753228,
            "codeCH" : 0,
            "nameUIC" : "Berre",
            "nameCH" : "",
            "code" : "BRR"
        },
        {
            "codeUIC" : 753228,
            "codeCH" : "4G",
            "nameUIC" : "Berre",
            "nameCH" : "V 2 d'Évitement",
            "code" : "BRR"
        },
        {
            "codeUIC" : 753251,
            "codeCH" : "BV",
            "nameUIC" : "Rognac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RGC"
        },
        {
            "codeUIC" : 753251,
            "codeCH" : "PA",
            "nameUIC" : "Rognac",
            "nameCH" : "Poste A",
            "code" : "RGC"
        },
        {
            "codeUIC" : 753251,
            "codeCH" : "TR",
            "nameUIC" : "Rognac",
            "nameCH" : "Triage",
            "code" : "RGC"
        },
        {
            "codeUIC" : 753251,
            "codeCH" : "ZI",
            "nameUIC" : "Rognac",
            "nameCH" : "ITE ZI Rognac",
            "code" : "RGC"
        },
        {
            "codeUIC" : 753251,
            "codeCH" : "1B",
            "nameUIC" : "Rognac",
            "nameCH" : "Voie 1Bis",
            "code" : "RGC"
        },
        {
            "codeUIC" : 753251,
            "codeCH" : "4G",
            "nameUIC" : "Rognac",
            "nameCH" : "V 4 Garage",
            "code" : "RGC"
        },
        {
            "codeUIC" : 753285,
            "codeCH" : "BV",
            "nameUIC" : "Pas-des-Lanciers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PDL"
        },
        {
            "codeUIC" : 753285,
            "codeCH" : "EP",
            "nameUIC" : "Pas-des-Lanciers",
            "nameCH" : "ITE SCI EUROPA",
            "code" : "PDL"
        },
        {
            "codeUIC" : 753285,
            "codeCH" : "FD",
            "nameUIC" : "Pas-des-Lanciers",
            "nameCH" : "Faisceau Départ",
            "code" : "PDL"
        },
        {
            "codeUIC" : 753285,
            "codeCH" : "4G",
            "nameUIC" : "Pas-des-Lanciers",
            "nameCH" : "V 4 Garage",
            "code" : "PDL"
        },
        {
            "codeUIC" : 753418,
            "codeCH" : "BV",
            "nameUIC" : "Istres",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 753426,
            "codeCH" : 0,
            "nameUIC" : "Rassuen",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753434,
            "codeCH" : "AI",
            "nameUIC" : "Fos-Coussoul",
            "nameCH" : "Aiguille Km 4,8",
            "code" : "FOC"
        },
        {
            "codeUIC" : 753434,
            "codeCH" : "DC",
            "nameUIC" : "Fos-Coussoul",
            "nameCH" : "ITE Descours et Cabaud",
            "code" : "FOC"
        },
        {
            "codeUIC" : 753434,
            "codeCH" : "PT",
            "nameUIC" : "Fos-Coussoul",
            "nameCH" : "Poste des Pétroles",
            "code" : "FOC"
        },
        {
            "codeUIC" : 753434,
            "codeCH" : "P1",
            "nameUIC" : "Fos-Coussoul",
            "nameCH" : "Poste 1",
            "code" : "FOC"
        },
        {
            "codeUIC" : 753434,
            "codeCH" : "SO",
            "nameUIC" : "Fos-Coussoul",
            "nameCH" : "ITE Solmer",
            "code" : "FOC"
        },
        {
            "codeUIC" : 753442,
            "codeCH" : "BV",
            "nameUIC" : "Fos-sur-Mer",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 753442,
            "codeCH" : "EP",
            "nameUIC" : "Fos-sur-Mer",
            "nameCH" : "ITE Lafarge",
            "code" : ""
        },
        {
            "codeUIC" : 753459,
            "codeCH" : "BV",
            "nameUIC" : "Port-de-Bouc",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PDB"
        },
        {
            "codeUIC" : 753459,
            "codeCH" : "CR",
            "nameUIC" : "Port-de-Bouc",
            "nameCH" : "Caronte",
            "code" : "PDB"
        },
        {
            "codeUIC" : 753459,
            "codeCH" : "EP",
            "nameUIC" : "Port-de-Bouc",
            "nameCH" : "ITE Lesieur",
            "code" : "PDB"
        },
        {
            "codeUIC" : 753459,
            "codeCH" : "TI",
            "nameUIC" : "Port-de-Bouc",
            "nameCH" : "Tiroir",
            "code" : "PDB"
        },
        {
            "codeUIC" : 753467,
            "codeCH" : "EP",
            "nameUIC" : "Fos-Graveleau",
            "nameCH" : "ITE Distriport",
            "code" : "FOG"
        },
        {
            "codeUIC" : 753467,
            "codeCH" : 0,
            "nameUIC" : "Fos-Graveleau",
            "nameCH" : "",
            "code" : "FOG"
        },
        {
            "codeUIC" : 753483,
            "codeCH" : "AQ",
            "nameUIC" : "Fos-Mole-Central",
            "nameCH" : "ITE F AQ",
            "code" : "FMC"
        },
        {
            "codeUIC" : 753483,
            "codeCH" : "PM",
            "nameUIC" : "Fos-Mole-Central",
            "nameCH" : "P Mole-Central",
            "code" : "FMC"
        },
        {
            "codeUIC" : 753491,
            "codeCH" : 0,
            "nameUIC" : "Croix-Sainte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753509,
            "codeCH" : "BV",
            "nameUIC" : "Martigues",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MTG"
        },
        {
            "codeUIC" : 753509,
            "codeCH" : "CA",
            "nameUIC" : "Martigues",
            "nameCH" : "Carré Violet 877",
            "code" : "MTG"
        },
        {
            "codeUIC" : 753509,
            "codeCH" : "EP",
            "nameUIC" : "Martigues",
            "nameCH" : "ITE GIE Lavéra Fer",
            "code" : "MTG"
        },
        {
            "codeUIC" : 753509,
            "codeCH" : "NA",
            "nameUIC" : "Martigues",
            "nameCH" : "ITE Naphtachimie",
            "code" : "MTG"
        },
        {
            "codeUIC" : 753509,
            "codeCH" : "RM",
            "nameUIC" : "Martigues",
            "nameCH" : "Aiguille Raccordement La Mède",
            "code" : "MTG"
        },
        {
            "codeUIC" : 753509,
            "codeCH" : "SI",
            "nameUIC" : "Martigues",
            "nameCH" : "Carré Violet 878",
            "code" : "MTG"
        },
        {
            "codeUIC" : 753525,
            "codeCH" : "AL",
            "nameUIC" : "Fos-Viguerat",
            "nameCH" : "ITE Air Liquide",
            "code" : "FOV"
        },
        {
            "codeUIC" : 753525,
            "codeCH" : "CF",
            "nameUIC" : "Fos-Viguerat",
            "nameCH" : "ITE Cfem-Atochem-Arco",
            "code" : "FOV"
        },
        {
            "codeUIC" : 753525,
            "codeCH" : "P1",
            "nameUIC" : "Fos-Viguerat",
            "nameCH" : "Poste 1",
            "code" : "FOV"
        },
        {
            "codeUIC" : 753525,
            "codeCH" : "UG",
            "nameUIC" : "Fos-Viguerat",
            "nameCH" : "ITE Ugine-Aciers",
            "code" : "FOV"
        },
        {
            "codeUIC" : 753533,
            "codeCH" : 0,
            "nameUIC" : "Ponteau-St-Pierre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753541,
            "codeCH" : "BV",
            "nameUIC" : "La Couronne-Carro",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 753541,
            "codeCH" : "EP",
            "nameUIC" : "La Couronne-Carro",
            "nameCH" : "ITE EDF",
            "code" : ""
        },
        {
            "codeUIC" : 753558,
            "codeCH" : "SE",
            "nameUIC" : "Sausset-les-Pins",
            "nameCH" : "Sémaphore 885",
            "code" : ""
        },
        {
            "codeUIC" : 753558,
            "codeCH" : 0,
            "nameUIC" : "Sausset-les-Pins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753566,
            "codeCH" : 0,
            "nameUIC" : "Carry-le-Rouet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753574,
            "codeCH" : 0,
            "nameUIC" : "La Redonne-Ensuès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753582,
            "codeCH" : 0,
            "nameUIC" : "Niolon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753608,
            "codeCH" : "BV",
            "nameUIC" : "Trinquetaille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 753657,
            "codeCH" : "AR",
            "nameUIC" : "Arles",
            "nameCH" : "AR",
            "code" : "ARS"
        },
        {
            "codeUIC" : 753657,
            "codeCH" : "BV",
            "nameUIC" : "Arles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ARS"
        },
        {
            "codeUIC" : 753657,
            "codeCH" : "ZI",
            "nameUIC" : "Arles",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "ARS"
        },
        {
            "codeUIC" : 753657,
            "codeCH" : "3G",
            "nameUIC" : "Arles",
            "nameCH" : "V 3 Garage",
            "code" : "ARS"
        },
        {
            "codeUIC" : 753657,
            "codeCH" : "4G",
            "nameUIC" : "Arles",
            "nameCH" : "V 4 Garage",
            "code" : "ARS"
        },
        {
            "codeUIC" : 753657,
            "codeCH" : "6G",
            "nameUIC" : "Arles",
            "nameCH" : "V 6 Garage",
            "code" : "ARS"
        },
        {
            "codeUIC" : 753673,
            "codeCH" : 0,
            "nameUIC" : "Raphèle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753681,
            "codeCH" : "EP",
            "nameUIC" : "St-Martin-de-Crau",
            "nameCH" : "ITE des Poudres",
            "code" : "SMC"
        },
        {
            "codeUIC" : 753681,
            "codeCH" : "IT",
            "nameUIC" : "St-Martin-de-Crau",
            "nameCH" : "ITE Km 792",
            "code" : "SMC"
        },
        {
            "codeUIC" : 753681,
            "codeCH" : "KM",
            "nameUIC" : "St-Martin-de-Crau",
            "nameCH" : "Km 799",
            "code" : "SMC"
        },
        {
            "codeUIC" : 753681,
            "codeCH" : 0,
            "nameUIC" : "St-Martin-de-Crau",
            "nameCH" : "",
            "code" : "SMC"
        },
        {
            "codeUIC" : 753681,
            "codeCH" : "3G",
            "nameUIC" : "St-Martin-de-Crau",
            "nameCH" : "V 3 Garage",
            "code" : "SMC"
        },
        {
            "codeUIC" : 753681,
            "codeCH" : "4G",
            "nameUIC" : "St-Martin-de-Crau",
            "nameCH" : "V 4 Garage",
            "code" : "SMC"
        },
        {
            "codeUIC" : 753699,
            "codeCH" : 0,
            "nameUIC" : "ITE Ville de Marseille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753707,
            "codeCH" : 0,
            "nameUIC" : "Orgon",
            "nameCH" : "",
            "code" : "ORG"
        },
        {
            "codeUIC" : 753715,
            "codeCH" : 0,
            "nameUIC" : "Sénas",
            "nameCH" : "",
            "code" : "S"
        },
        {
            "codeUIC" : 753731,
            "codeCH" : 0,
            "nameUIC" : "Lamanon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753764,
            "codeCH" : 0,
            "nameUIC" : "Salon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 753848,
            "codeCH" : "AS",
            "nameUIC" : "L'Eysselle",
            "nameCH" : "Accès Nord",
            "code" : ""
        },
        {
            "codeUIC" : 753848,
            "codeCH" : "EP",
            "nameUIC" : "L'Eysselle",
            "nameCH" : "ITE Salins du midi",
            "code" : ""
        },
        {
            "codeUIC" : 753855,
            "codeCH" : "MS",
            "nameUIC" : "Port-St-Louis-du-Rhône",
            "nameCH" : "Marchandises",
            "code" : "PLR"
        },
        {
            "codeUIC" : 755009,
            "codeCH" : "BV",
            "nameUIC" : "Toulon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 755009,
            "codeCH" : "V4",
            "nameUIC" : "Toulon",
            "nameCH" : "V 4 de garage",
            "code" : ""
        },
        {
            "codeUIC" : 755215,
            "codeCH" : "V3",
            "nameUIC" : "St-Cyr-les-Lècques-La Cadière",
            "nameCH" : "V 3 de Garage",
            "code" : ""
        },
        {
            "codeUIC" : 755215,
            "codeCH" : "V4",
            "nameUIC" : "St-Cyr-les-Lècques-La Cadière",
            "nameCH" : "V 4 de Garage",
            "code" : ""
        },
        {
            "codeUIC" : 755215,
            "codeCH" : 0,
            "nameUIC" : "St-Cyr-les-Lècques-La Cadière",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755223,
            "codeCH" : 0,
            "nameUIC" : "Bandol",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755231,
            "codeCH" : 0,
            "nameUIC" : "Ollioules-Sanary-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755264,
            "codeCH" : "BV",
            "nameUIC" : "La Seyne-Tamaris-sur-Mer",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LST"
        },
        {
            "codeUIC" : 755264,
            "codeCH" : "PA",
            "nameUIC" : "La Seyne-Tamaris-sur-Mer",
            "nameCH" : "Aiguille Km 59,9 PA",
            "code" : "LST"
        },
        {
            "codeUIC" : 755264,
            "codeCH" : "P1",
            "nameUIC" : "La Seyne-Tamaris-sur-Mer",
            "nameCH" : "Poste 1(Triage)",
            "code" : "LST"
        },
        {
            "codeUIC" : 755306,
            "codeCH" : "BV",
            "nameUIC" : "La Garde",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 755314,
            "codeCH" : "BF",
            "nameUIC" : "La Pauline-Hyères",
            "nameCH" : "Bifurcation Km 77,3",
            "code" : "LPH"
        },
        {
            "codeUIC" : 755314,
            "codeCH" : "BV",
            "nameUIC" : "La Pauline-Hyères",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LPH"
        },
        {
            "codeUIC" : 755314,
            "codeCH" : "GZ",
            "nameUIC" : "La Pauline-Hyères",
            "nameCH" : "ITE Zone Industrielle de Toulon-Est",
            "code" : "LPH"
        },
        {
            "codeUIC" : 755322,
            "codeCH" : 0,
            "nameUIC" : "La Farlède",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755330,
            "codeCH" : 0,
            "nameUIC" : "Solliès-Pont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755355,
            "codeCH" : 0,
            "nameUIC" : "Cuers-Pierrefeu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755363,
            "codeCH" : 0,
            "nameUIC" : "Puget-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755371,
            "codeCH" : 0,
            "nameUIC" : "Carnoules",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755389,
            "codeCH" : 0,
            "nameUIC" : "Pignans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755397,
            "codeCH" : 0,
            "nameUIC" : "Gonfaron",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755405,
            "codeCH" : "EP",
            "nameUIC" : "Le Luc-et-Le Cannet",
            "nameCH" : "ITE Les Lauves",
            "code" : "LEA"
        },
        {
            "codeUIC" : 755405,
            "codeCH" : 0,
            "nameUIC" : "Le Luc-et-Le Cannet",
            "nameCH" : "",
            "code" : "LEA"
        },
        {
            "codeUIC" : 755405,
            "codeCH" : 11,
            "nameUIC" : "Le Luc-et-Le Cannet",
            "nameCH" : "V 11 de Garage",
            "code" : "LEA"
        },
        {
            "codeUIC" : 755421,
            "codeCH" : 0,
            "nameUIC" : "Vidauban",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755447,
            "codeCH" : "V3",
            "nameUIC" : "Les Arcs-Draguignan",
            "nameCH" : "V 3 de Garage",
            "code" : "LAD"
        },
        {
            "codeUIC" : 755447,
            "codeCH" : "V4",
            "nameUIC" : "Les Arcs-Draguignan",
            "nameCH" : "V 4 de Garage",
            "code" : "LAD"
        },
        {
            "codeUIC" : 755447,
            "codeCH" : 0,
            "nameUIC" : "Les Arcs-Draguignan",
            "nameCH" : "",
            "code" : "LAD"
        },
        {
            "codeUIC" : 755462,
            "codeCH" : 0,
            "nameUIC" : "Le Muy",
            "nameCH" : "",
            "code" : "LMY"
        },
        {
            "codeUIC" : 755488,
            "codeCH" : 0,
            "nameUIC" : "Roquebrune-sur-Argens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755496,
            "codeCH" : "BV",
            "nameUIC" : "Puget-sur-Argens",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 755496,
            "codeCH" : "QM",
            "nameUIC" : "Puget-sur-Argens",
            "nameCH" : "ITE Quai Militaire",
            "code" : ""
        },
        {
            "codeUIC" : 755611,
            "codeCH" : "S5",
            "nameUIC" : "La Crau",
            "nameCH" : "Sémaphore 5,300",
            "code" : ""
        },
        {
            "codeUIC" : 755611,
            "codeCH" : 0,
            "nameUIC" : "La Crau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755629,
            "codeCH" : "BV",
            "nameUIC" : "Hyères",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "HYE"
        },
        {
            "codeUIC" : 755702,
            "codeCH" : 0,
            "nameUIC" : "Besse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755736,
            "codeCH" : "BS",
            "nameUIC" : "Brignoles",
            "nameCH" : "Bâtiment service",
            "code" : ""
        },
        {
            "codeUIC" : 755736,
            "codeCH" : "BV",
            "nameUIC" : "Brignoles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 755769,
            "codeCH" : "BS",
            "nameUIC" : "Les Censies",
            "nameCH" : "Bâtiment service",
            "code" : ""
        },
        {
            "codeUIC" : 755777,
            "codeCH" : 0,
            "nameUIC" : "Tourves",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 755785,
            "codeCH" : "BS",
            "nameUIC" : "St-Maximin",
            "nameCH" : "Bâtiment service",
            "code" : ""
        },
        {
            "codeUIC" : 755835,
            "codeCH" : 0,
            "nameUIC" : "La Motte-Ste-Rosseline",
            "nameCH" : "",
            "code" : "LMS"
        },
        {
            "codeUIC" : 756007,
            "codeCH" : "BV",
            "nameUIC" : "Nice-St-Roch",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NSR"
        },
        {
            "codeUIC" : 756007,
            "codeCH" : "EP",
            "nameUIC" : "Nice-St-Roch",
            "nameCH" : "ITE Gerland",
            "code" : "NSR"
        },
        {
            "codeUIC" : 756007,
            "codeCH" : "PA",
            "nameUIC" : "Nice-St-Roch",
            "nameCH" : "Poste A",
            "code" : "NSR"
        },
        {
            "codeUIC" : 756007,
            "codeCH" : "P1",
            "nameUIC" : "Nice-St-Roch",
            "nameCH" : "Poste 1(Triage)",
            "code" : "NSR"
        },
        {
            "codeUIC" : 756056,
            "codeCH" : 0,
            "nameUIC" : "Nice-Ville",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756239,
            "codeCH" : 0,
            "nameUIC" : "ITE MINNice-St-Augustin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756254,
            "codeCH" : "BA",
            "nameUIC" : "Nice-St-Augustin",
            "nameCH" : "Entrée/Sortie VBA",
            "code" : ""
        },
        {
            "codeUIC" : 756254,
            "codeCH" : 0,
            "nameUIC" : "Nice-St-Augustin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756254,
            "codeCH" : "3G",
            "nameUIC" : "Nice-St-Augustin",
            "nameCH" : "Voie 3G",
            "code" : ""
        },
        {
            "codeUIC" : 756304,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-Loubet-Plage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756312,
            "codeCH" : 0,
            "nameUIC" : "Hippodrome-de-la-Côte-d'Azur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756320,
            "codeCH" : "BV",
            "nameUIC" : "Cagnes-sur-Mer",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 756320,
            "codeCH" : "MA",
            "nameUIC" : "Cagnes-sur-Mer",
            "nameCH" : "Marchandises",
            "code" : ""
        },
        {
            "codeUIC" : 756320,
            "codeCH" : "V3",
            "nameUIC" : "Cagnes-sur-Mer",
            "nameCH" : "Voie 3",
            "code" : ""
        },
        {
            "codeUIC" : 756338,
            "codeCH" : 0,
            "nameUIC" : "Cros-de-Cagnes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756346,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-du-Var",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756353,
            "codeCH" : 0,
            "nameUIC" : "Nice-Riquier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756361,
            "codeCH" : 0,
            "nameUIC" : "Villefranche-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756379,
            "codeCH" : 0,
            "nameUIC" : "Beaulieu-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756387,
            "codeCH" : 0,
            "nameUIC" : "Èze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756395,
            "codeCH" : 0,
            "nameUIC" : "Cap-d'Ail",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756403,
            "codeCH" : "CS",
            "nameUIC" : "Monaco-Monte-Carlo",
            "nameCH" : "IPCS KM 239,6",
            "code" : ""
        },
        {
            "codeUIC" : 756403,
            "codeCH" : "IP",
            "nameUIC" : "Monaco-Monte-Carlo",
            "nameCH" : "IPCS KM 240,4",
            "code" : ""
        },
        {
            "codeUIC" : 756403,
            "codeCH" : 0,
            "nameUIC" : "Monaco-Monte-Carlo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756460,
            "codeCH" : 0,
            "nameUIC" : "Cap-Martin-Roquebrune",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756478,
            "codeCH" : 0,
            "nameUIC" : "Carnolès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756486,
            "codeCH" : "BF",
            "nameUIC" : "Menton",
            "nameCH" : "Bifurcation de Calandre",
            "code" : ""
        },
        {
            "codeUIC" : 756486,
            "codeCH" : "PV",
            "nameUIC" : "Menton",
            "nameCH" : "Changement Voies",
            "code" : ""
        },
        {
            "codeUIC" : 756486,
            "codeCH" : "WI",
            "nameUIC" : "Menton",
            "nameCH" : "Bifurcation de Calandre",
            "code" : ""
        },
        {
            "codeUIC" : 756486,
            "codeCH" : 0,
            "nameUIC" : "Menton",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756494,
            "codeCH" : "XI",
            "nameUIC" : "Menton-Garavan",
            "nameCH" : "Frontière France-Italie",
            "code" : ""
        },
        {
            "codeUIC" : 756494,
            "codeCH" : 0,
            "nameUIC" : "Menton-Garavan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756742,
            "codeCH" : "WI",
            "nameUIC" : "Limone",
            "nameCH" : "Bâtiment voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 756767,
            "codeCH" : "BV",
            "nameUIC" : "La Trinité-Victor",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 756775,
            "codeCH" : 0,
            "nameUIC" : "Drap-Cantaron",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756783,
            "codeCH" : 0,
            "nameUIC" : "Peillon-Ste-Thècle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756791,
            "codeCH" : 0,
            "nameUIC" : "Peille",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756809,
            "codeCH" : 0,
            "nameUIC" : "L'Escarène",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756817,
            "codeCH" : 0,
            "nameUIC" : "Touët-de-l'Escarène",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756825,
            "codeCH" : 0,
            "nameUIC" : "Sospel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756833,
            "codeCH" : "BF",
            "nameUIC" : "Breil-sur-Roya",
            "nameCH" : "Bifurcation",
            "code" : ""
        },
        {
            "codeUIC" : 756833,
            "codeCH" : "BV",
            "nameUIC" : "Breil-sur-Roya",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 756833,
            "codeCH" : "XI",
            "nameUIC" : "Breil-sur-Roya",
            "nameCH" : "Frontière France-Italie",
            "code" : ""
        },
        {
            "codeUIC" : 756833,
            "codeCH" : 0,
            "nameUIC" : "Breil-sur-Roya",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756858,
            "codeCH" : 0,
            "nameUIC" : "Fontan-Saorge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756866,
            "codeCH" : 0,
            "nameUIC" : "St-Dalmas-de-Tende",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756874,
            "codeCH" : 0,
            "nameUIC" : "La Brigue",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756882,
            "codeCH" : 0,
            "nameUIC" : "Tende",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756890,
            "codeCH" : "XI",
            "nameUIC" : "Viévola",
            "nameCH" : "Frontière France-Italie",
            "code" : ""
        },
        {
            "codeUIC" : 756890,
            "codeCH" : 0,
            "nameUIC" : "Viévola",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 756908,
            "codeCH" : "BV",
            "nameUIC" : "Vintimille",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VIN"
        },
        {
            "codeUIC" : 756908,
            "codeCH" : "WI",
            "nameUIC" : "Vintimille",
            "nameCH" : "Bâtiment voyageurs",
            "code" : "VIN"
        },
        {
            "codeUIC" : 756908,
            "codeCH" : "W4",
            "nameUIC" : "Vintimille",
            "nameCH" : "LA Roya",
            "code" : "VIN"
        },
        {
            "codeUIC" : 756908,
            "codeCH" : "W5",
            "nameUIC" : "Vintimille",
            "nameCH" : "Faisceau Impair",
            "code" : "VIN"
        },
        {
            "codeUIC" : 757005,
            "codeCH" : "BG",
            "nameUIC" : "Cannes-Marchandises",
            "nameCH" : "Bifurcation de Grasse",
            "code" : "CPH"
        },
        {
            "codeUIC" : 757005,
            "codeCH" : "PO",
            "nameUIC" : "Cannes-Marchandises",
            "nameCH" : "Portique Km 191,3",
            "code" : "CPH"
        },
        {
            "codeUIC" : 757005,
            "codeCH" : "P1",
            "nameUIC" : "Cannes-Marchandises",
            "nameCH" : "Poste 1(Triage)",
            "code" : "CPH"
        },
        {
            "codeUIC" : 757500,
            "codeCH" : "BV",
            "nameUIC" : "Fréjus",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 757500,
            "codeCH" : "QM",
            "nameUIC" : "Fréjus",
            "nameCH" : "ITE QM Frejus",
            "code" : ""
        },
        {
            "codeUIC" : 757518,
            "codeCH" : 0,
            "nameUIC" : "Fréjus-St-Raphaël",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757526,
            "codeCH" : "BV",
            "nameUIC" : "St-Raphaël-Valescure",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 757526,
            "codeCH" : "P1",
            "nameUIC" : "St-Raphaël-Valescure",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 757534,
            "codeCH" : 0,
            "nameUIC" : "Boulouris-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757542,
            "codeCH" : 0,
            "nameUIC" : "Le Dramont",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757559,
            "codeCH" : 0,
            "nameUIC" : "Agay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757567,
            "codeCH" : "BV",
            "nameUIC" : "Anthéor-Cap-Roux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 757567,
            "codeCH" : "VA",
            "nameUIC" : "Anthéor-Cap-Roux",
            "nameCH" : "Voie d'Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 757575,
            "codeCH" : 0,
            "nameUIC" : "Le Trayas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757583,
            "codeCH" : 0,
            "nameUIC" : "Théoule-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757591,
            "codeCH" : 0,
            "nameUIC" : "Mandelieu-la-Napoule",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757609,
            "codeCH" : 0,
            "nameUIC" : "Pinède-de-la-Siagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757617,
            "codeCH" : 0,
            "nameUIC" : "Cannes-la-Bocca",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757625,
            "codeCH" : 0,
            "nameUIC" : "Cannes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757641,
            "codeCH" : 0,
            "nameUIC" : "Golfe-Juan-Vallauris",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757666,
            "codeCH" : 0,
            "nameUIC" : "Juan-les-Pins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757674,
            "codeCH" : "V4",
            "nameUIC" : "Antibes",
            "nameCH" : "V 4 de Garage",
            "code" : "ATB"
        },
        {
            "codeUIC" : 757674,
            "codeCH" : "V9",
            "nameUIC" : "Antibes",
            "nameCH" : "V 9 de Garage",
            "code" : "ATB"
        },
        {
            "codeUIC" : 757674,
            "codeCH" : 0,
            "nameUIC" : "Antibes",
            "nameCH" : "",
            "code" : "ATB"
        },
        {
            "codeUIC" : 757674,
            "codeCH" : 11,
            "nameUIC" : "Antibes",
            "nameCH" : "Voie 11",
            "code" : "ATB"
        },
        {
            "codeUIC" : 757690,
            "codeCH" : 0,
            "nameUIC" : "Biot",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757724,
            "codeCH" : 0,
            "nameUIC" : "Grasse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757732,
            "codeCH" : 0,
            "nameUIC" : "Ranguin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757740,
            "codeCH" : 0,
            "nameUIC" : "La Frayère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757757,
            "codeCH" : 0,
            "nameUIC" : "Le Bosquet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757807,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 53",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 757815,
            "codeCH" : "PR",
            "nameUIC" : "Sémaphore 54",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 758029,
            "codeCH" : 0,
            "nameUIC" : "Nanterre-Préfecture",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 758607,
            "codeCH" : "BV",
            "nameUIC" : "Chatelet-les-Halles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 758607,
            "codeCH" : "LR",
            "nameUIC" : "Chatelet-les-Halles",
            "nameCH" : "Limite RATP/SNCF",
            "code" : ""
        },
        {
            "codeUIC" : 758607,
            "codeCH" : 0,
            "nameUIC" : "Chatelet-les-Halles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 759290,
            "codeCH" : 0,
            "nameUIC" : "Picon-Busserine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 759316,
            "codeCH" : 0,
            "nameUIC" : "St-Joseph-Le Castellas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "AS",
            "nameUIC" : "Valence",
            "nameCH" : "Aiguille Km 621,1",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "BF",
            "nameUIC" : "Valence",
            "nameCH" : "Bifurcation LGV",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "BG",
            "nameUIC" : "Valence",
            "nameCH" : "Bifurcation Cagnard",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "BV",
            "nameUIC" : "Valence",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "DP",
            "nameUIC" : "Valence",
            "nameCH" : "P Société des Métaux",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "IT",
            "nameUIC" : "Valence",
            "nameCH" : "ITE Km 611,695",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "MA",
            "nameUIC" : "Valence",
            "nameCH" : "ITE Marion",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "P1",
            "nameUIC" : "Valence",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "P2",
            "nameUIC" : "Valence",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "P3",
            "nameUIC" : "Valence",
            "nameCH" : "Poste 3",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "SO",
            "nameUIC" : "Valence",
            "nameCH" : "ITE Sogeme",
            "code" : ""
        },
        {
            "codeUIC" : 761007,
            "codeCH" : "VA",
            "nameUIC" : "Valence",
            "nameCH" : "Voie K",
            "code" : ""
        },
        {
            "codeUIC" : 761106,
            "codeCH" : "AP",
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Pancarte Radio",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : "AV",
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "PN 2",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : "A1",
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "PN 1",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : "BN",
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Bifurcation Km 570,2",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : "BS",
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Bifurcation Km 572,3",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : "BV",
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : "NS",
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Bifurcation Km 83,3",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : 1,
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Pancarte Gare",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : 12,
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Voie 12 Garage",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761106,
            "codeCH" : 62,
            "nameUIC" : "St-Rambert-d'Albon",
            "nameCH" : "Signal 62",
            "code" : "SRE"
        },
        {
            "codeUIC" : 761114,
            "codeCH" : 0,
            "nameUIC" : "Casatorra",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761122,
            "codeCH" : "BV",
            "nameUIC" : "Andancette",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761130,
            "codeCH" : 0,
            "nameUIC" : "St-Vallier-sur-Rhône",
            "nameCH" : "",
            "code" : "SUO"
        },
        {
            "codeUIC" : 761130,
            "codeCH" : "4G",
            "nameUIC" : "St-Vallier-sur-Rhône",
            "nameCH" : "Voie 4 Garage",
            "code" : "SUO"
        },
        {
            "codeUIC" : 761130,
            "codeCH" : "5G",
            "nameUIC" : "St-Vallier-sur-Rhône",
            "nameCH" : "Voie 5 Garage",
            "code" : "SUO"
        },
        {
            "codeUIC" : 761163,
            "codeCH" : 0,
            "nameUIC" : "Tain-l'Hermitage-Tournon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761171,
            "codeCH" : 0,
            "nameUIC" : "Lucciana",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761189,
            "codeCH" : "BV",
            "nameUIC" : "La Roche-de-Glun",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761205,
            "codeCH" : "AS",
            "nameUIC" : "Portes",
            "nameCH" : "Aiguille Km 625,8",
            "code" : "POR"
        },
        {
            "codeUIC" : 761205,
            "codeCH" : "BV",
            "nameUIC" : "Portes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "POR"
        },
        {
            "codeUIC" : 761205,
            "codeCH" : "FR",
            "nameUIC" : "Portes",
            "nameCH" : "Faisceau Réception",
            "code" : "POR"
        },
        {
            "codeUIC" : 761205,
            "codeCH" : "P1",
            "nameUIC" : "Portes",
            "nameCH" : "Poste 1",
            "code" : "POR"
        },
        {
            "codeUIC" : 761205,
            "codeCH" : "P2",
            "nameUIC" : "Portes",
            "nameCH" : "Poste 2",
            "code" : "POR"
        },
        {
            "codeUIC" : 761205,
            "codeCH" : "P3",
            "nameUIC" : "Portes",
            "nameCH" : "Poste 3",
            "code" : "POR"
        },
        {
            "codeUIC" : 761205,
            "codeCH" : "RP",
            "nameUIC" : "Portes",
            "nameCH" : "Relais Pair",
            "code" : "POR"
        },
        {
            "codeUIC" : 761221,
            "codeCH" : 0,
            "nameUIC" : "Étoile",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761239,
            "codeCH" : "BV",
            "nameUIC" : "L'Isle-d'Abeau",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "BS",
            "nameUIC" : "Livron",
            "nameCH" : "Bifurcation Km 635,5",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "BV",
            "nameUIC" : "Livron",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "EP",
            "nameUIC" : "Livron",
            "nameCH" : "ITE Allex Grane",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "NS",
            "nameUIC" : "Livron",
            "nameCH" : "Bifurcation Km 0,7",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "P1",
            "nameUIC" : "Livron",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "P2",
            "nameUIC" : "Livron",
            "nameCH" : "Poste 2",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "SC",
            "nameUIC" : "Livron",
            "nameCH" : "Panneau 19 et 20",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "S9",
            "nameUIC" : "Livron",
            "nameCH" : "Carré 9",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "V3",
            "nameUIC" : "Livron",
            "nameCH" : "Voie 3 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : "V4",
            "nameUIC" : "Livron",
            "nameCH" : "Voie 4 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 761247,
            "codeCH" : 17,
            "nameUIC" : "Livron",
            "nameCH" : "Panneau 17",
            "code" : ""
        },
        {
            "codeUIC" : 761254,
            "codeCH" : 0,
            "nameUIC" : "Eurre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761262,
            "codeCH" : 0,
            "nameUIC" : "Loriol",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761304,
            "codeCH" : 0,
            "nameUIC" : "Savaggio",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761312,
            "codeCH" : "AP",
            "nameUIC" : "Épinouze",
            "nameCH" : "AP",
            "code" : ""
        },
        {
            "codeUIC" : 761312,
            "codeCH" : "AV",
            "nameUIC" : "Épinouze",
            "nameCH" : "AV",
            "code" : ""
        },
        {
            "codeUIC" : 761312,
            "codeCH" : "A7",
            "nameUIC" : "Épinouze",
            "nameCH" : "PN 7",
            "code" : ""
        },
        {
            "codeUIC" : 761312,
            "codeCH" : "BV",
            "nameUIC" : "Épinouze",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761312,
            "codeCH" : 7,
            "nameUIC" : "Épinouze",
            "nameCH" : "Pancarte Radio",
            "code" : ""
        },
        {
            "codeUIC" : 761320,
            "codeCH" : "AV",
            "nameUIC" : "Manthes-Lapeyrouse",
            "nameCH" : "PN 12",
            "code" : "MHP"
        },
        {
            "codeUIC" : 761320,
            "codeCH" : "A1",
            "nameUIC" : "Manthes-Lapeyrouse",
            "nameCH" : "PN 11",
            "code" : "MHP"
        },
        {
            "codeUIC" : 761320,
            "codeCH" : "BV",
            "nameUIC" : "Manthes-Lapeyrouse",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MHP"
        },
        {
            "codeUIC" : 761338,
            "codeCH" : "AP",
            "nameUIC" : "Beaurepaire",
            "nameCH" : "Pancarte PN",
            "code" : "BRP"
        },
        {
            "codeUIC" : 761338,
            "codeCH" : "BV",
            "nameUIC" : "Beaurepaire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BRP"
        },
        {
            "codeUIC" : 761353,
            "codeCH" : "BV",
            "nameUIC" : "Marcilloles",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761361,
            "codeCH" : "BV",
            "nameUIC" : "La Côte-St-André-le-Rival",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761395,
            "codeCH" : "BV",
            "nameUIC" : "Izeaux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761403,
            "codeCH" : "AP",
            "nameUIC" : "Peyraud",
            "nameCH" : "AP",
            "code" : ""
        },
        {
            "codeUIC" : 761403,
            "codeCH" : "AV",
            "nameUIC" : "Peyraud",
            "nameCH" : "AV",
            "code" : ""
        },
        {
            "codeUIC" : 761403,
            "codeCH" : "BV",
            "nameUIC" : "Peyraud",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761403,
            "codeCH" : "CV",
            "nameUIC" : "Peyraud",
            "nameCH" : "Carré Violet 1",
            "code" : ""
        },
        {
            "codeUIC" : 761403,
            "codeCH" : "NS",
            "nameUIC" : "Peyraud",
            "nameCH" : "Bifurcation Km 82,7",
            "code" : ""
        },
        {
            "codeUIC" : 761403,
            "codeCH" : "P1",
            "nameUIC" : "Peyraud",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 761403,
            "codeCH" : "RN",
            "nameUIC" : "Peyraud",
            "nameCH" : "Bifurcation Raccordement Nord",
            "code" : ""
        },
        {
            "codeUIC" : 761437,
            "codeCH" : 0,
            "nameUIC" : "Sarras",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761460,
            "codeCH" : "BV",
            "nameUIC" : "Tournon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761486,
            "codeCH" : 0,
            "nameUIC" : "Mauves (Ardèche)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761494,
            "codeCH" : 0,
            "nameUIC" : "St-Péray",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761502,
            "codeCH" : "EP",
            "nameUIC" : "Soyons",
            "nameCH" : "ITE Km 622,3",
            "code" : ""
        },
        {
            "codeUIC" : 761528,
            "codeCH" : 0,
            "nameUIC" : "Beauchastel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761544,
            "codeCH" : "BP",
            "nameUIC" : "La Voulte-sur-Rhône",
            "nameCH" : "Aiguille Km 634,9",
            "code" : "LVO"
        },
        {
            "codeUIC" : 761544,
            "codeCH" : "BT",
            "nameUIC" : "La Voulte-sur-Rhône",
            "nameCH" : "Aiguille Km 635,3",
            "code" : "LVO"
        },
        {
            "codeUIC" : 761544,
            "codeCH" : "NS",
            "nameUIC" : "La Voulte-sur-Rhône",
            "nameCH" : "Bifurcation Km 4,7",
            "code" : "LVO"
        },
        {
            "codeUIC" : 761544,
            "codeCH" : "P1",
            "nameUIC" : "La Voulte-sur-Rhône",
            "nameCH" : "Poste 1",
            "code" : "LVO"
        },
        {
            "codeUIC" : 761544,
            "codeCH" : "TR",
            "nameUIC" : "La Voulte-sur-Rhône",
            "nameCH" : "Triage (Voies 4 à 8)",
            "code" : "LVO"
        },
        {
            "codeUIC" : 761544,
            "codeCH" : "1B",
            "nameUIC" : "La Voulte-sur-Rhône",
            "nameCH" : "Voie 1Bis",
            "code" : "LVO"
        },
        {
            "codeUIC" : 761619,
            "codeCH" : "BF",
            "nameUIC" : "Alixan-Châteauneuf-d'Isère",
            "nameCH" : "Bifurcation Valenc-TGV",
            "code" : "ALI"
        },
        {
            "codeUIC" : 761619,
            "codeCH" : "BV",
            "nameUIC" : "Alixan-Châteauneuf-d'Isère",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ALI"
        },
        {
            "codeUIC" : 761650,
            "codeCH" : "CP",
            "nameUIC" : "Romans-Bourg-de-Péage",
            "nameCH" : "Changement Parité",
            "code" : "ROM"
        },
        {
            "codeUIC" : 761650,
            "codeCH" : "GA",
            "nameUIC" : "Romans-Bourg-de-Péage",
            "nameCH" : "Garage V13",
            "code" : "ROM"
        },
        {
            "codeUIC" : 761650,
            "codeCH" : 0,
            "nameUIC" : "Romans-Bourg-de-Péage",
            "nameCH" : "",
            "code" : "ROM"
        },
        {
            "codeUIC" : 761668,
            "codeCH" : 0,
            "nameUIC" : "St-Paul-lès-Romans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761684,
            "codeCH" : "A1",
            "nameUIC" : "St-Hilaire-St-Nazaire",
            "nameCH" : "Aiguille 311",
            "code" : ""
        },
        {
            "codeUIC" : 761684,
            "codeCH" : "A2",
            "nameUIC" : "St-Hilaire-St-Nazaire",
            "nameCH" : "Aiguille 322",
            "code" : ""
        },
        {
            "codeUIC" : 761684,
            "codeCH" : 0,
            "nameUIC" : "St-Hilaire-St-Nazaire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761718,
            "codeCH" : "S5",
            "nameUIC" : "St-Marcellin (Isère)",
            "nameCH" : "Sémaphores 511 et 512",
            "code" : ""
        },
        {
            "codeUIC" : 761718,
            "codeCH" : 0,
            "nameUIC" : "St-Marcellin (Isère)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761726,
            "codeCH" : 0,
            "nameUIC" : "Vinay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761734,
            "codeCH" : 0,
            "nameUIC" : "L'Albenc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761742,
            "codeCH" : "BV",
            "nameUIC" : "Poliénas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PES"
        },
        {
            "codeUIC" : 761742,
            "codeCH" : "P6",
            "nameUIC" : "Poliénas",
            "nameCH" : "Poste6",
            "code" : "PES"
        },
        {
            "codeUIC" : 761759,
            "codeCH" : 0,
            "nameUIC" : "Tullins-Fures",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761783,
            "codeCH" : "BV",
            "nameUIC" : "Crest",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761791,
            "codeCH" : 0,
            "nameUIC" : "Aouste-sur-Sye",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761817,
            "codeCH" : 0,
            "nameUIC" : "Saillans",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761825,
            "codeCH" : 0,
            "nameUIC" : "Vercheny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761841,
            "codeCH" : "BV",
            "nameUIC" : "Die",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 761841,
            "codeCH" : "EP",
            "nameUIC" : "Die",
            "nameCH" : "ITE Cave coopérative Clairette de Die",
            "code" : ""
        },
        {
            "codeUIC" : 761866,
            "codeCH" : 0,
            "nameUIC" : "Recoubeau",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761874,
            "codeCH" : 0,
            "nameUIC" : "Luc-en-Diois",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761890,
            "codeCH" : 0,
            "nameUIC" : "Beaurières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761908,
            "codeCH" : "AP",
            "nameUIC" : "Algajola",
            "nameCH" : "Aregno-Plage",
            "code" : ""
        },
        {
            "codeUIC" : 761908,
            "codeCH" : "PA",
            "nameUIC" : "Algajola",
            "nameCH" : "Plage Algajola",
            "code" : ""
        },
        {
            "codeUIC" : 761908,
            "codeCH" : 0,
            "nameUIC" : "Algajola",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761924,
            "codeCH" : 0,
            "nameUIC" : "Barchetta",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761932,
            "codeCH" : 0,
            "nameUIC" : "Belgodere",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761940,
            "codeCH" : "CV",
            "nameUIC" : "Biguglia",
            "nameCH" : "Campo-Vallone",
            "code" : ""
        },
        {
            "codeUIC" : 761940,
            "codeCH" : 0,
            "nameUIC" : "Biguglia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761957,
            "codeCH" : 0,
            "nameUIC" : "Bocognano",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761965,
            "codeCH" : 0,
            "nameUIC" : "Borgo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761973,
            "codeCH" : 0,
            "nameUIC" : "Caldaniccia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761981,
            "codeCH" : "CC",
            "nameUIC" : "Lumio",
            "nameCH" : "Club A Cavallu",
            "code" : ""
        },
        {
            "codeUIC" : 761981,
            "codeCH" : "CH",
            "nameUIC" : "Lumio",
            "nameCH" : "Club hippique",
            "code" : ""
        },
        {
            "codeUIC" : 761981,
            "codeCH" : "CM",
            "nameUIC" : "Lumio",
            "nameCH" : "Club olympique",
            "code" : ""
        },
        {
            "codeUIC" : 761981,
            "codeCH" : "ON",
            "nameUIC" : "Lumio",
            "nameCH" : "Ondari",
            "code" : ""
        },
        {
            "codeUIC" : 761981,
            "codeCH" : "SR",
            "nameUIC" : "Lumio",
            "nameCH" : "Ste RESTITUDE",
            "code" : ""
        },
        {
            "codeUIC" : 761981,
            "codeCH" : 0,
            "nameUIC" : "Lumio",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 761999,
            "codeCH" : 0,
            "nameUIC" : "Carbuccia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762005,
            "codeCH" : 0,
            "nameUIC" : "Casamozza",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762013,
            "codeCH" : 0,
            "nameUIC" : "Corte",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762021,
            "codeCH" : 0,
            "nameUIC" : "Francardo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762039,
            "codeCH" : 0,
            "nameUIC" : "Furiani",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762047,
            "codeCH" : "GC",
            "nameUIC" : "Le Regino",
            "nameCH" : "Golf-Club",
            "code" : ""
        },
        {
            "codeUIC" : 762047,
            "codeCH" : "VP",
            "nameUIC" : "Le Regino",
            "nameCH" : "Ville Di Paraso",
            "code" : ""
        },
        {
            "codeUIC" : 762047,
            "codeCH" : 0,
            "nameUIC" : "Le Regino",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762054,
            "codeCH" : 0,
            "nameUIC" : "Mezzana",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762062,
            "codeCH" : 0,
            "nameUIC" : "Novella",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762070,
            "codeCH" : 0,
            "nameUIC" : "Omessa",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762088,
            "codeCH" : 0,
            "nameUIC" : "Palasca",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762096,
            "codeCH" : 0,
            "nameUIC" : "Pietralba",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762104,
            "codeCH" : 0,
            "nameUIC" : "Poggio-Riventosa",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762112,
            "codeCH" : 0,
            "nameUIC" : "Ponte-Leccia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762120,
            "codeCH" : 0,
            "nameUIC" : "Ponte-Nuovo",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762138,
            "codeCH" : 0,
            "nameUIC" : "San-Gavino",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762146,
            "codeCH" : 0,
            "nameUIC" : "Soveria",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762153,
            "codeCH" : 0,
            "nameUIC" : "Tavera",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762161,
            "codeCH" : "PV",
            "nameUIC" : "Venaco",
            "nameCH" : "Piscine Venaco",
            "code" : ""
        },
        {
            "codeUIC" : 762161,
            "codeCH" : 0,
            "nameUIC" : "Venaco",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762179,
            "codeCH" : 0,
            "nameUIC" : "Vivario",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762187,
            "codeCH" : 0,
            "nameUIC" : "Vizzavona",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762203,
            "codeCH" : 0,
            "nameUIC" : "Tattone",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762211,
            "codeCH" : 0,
            "nameUIC" : "Ucciani",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762229,
            "codeCH" : 0,
            "nameUIC" : "Campo-dell-Oro",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762237,
            "codeCH" : 0,
            "nameUIC" : "Les Salines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762245,
            "codeCH" : 0,
            "nameUIC" : "Tennis-Club",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762252,
            "codeCH" : 0,
            "nameUIC" : "Club Med Cocody",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762260,
            "codeCH" : "GI",
            "nameUIC" : "Sant'-Ambroggio",
            "nameCH" : "Giogio",
            "code" : ""
        },
        {
            "codeUIC" : 762260,
            "codeCH" : 0,
            "nameUIC" : "Sant'-Ambroggio",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762286,
            "codeCH" : 0,
            "nameUIC" : "Davia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762294,
            "codeCH" : 0,
            "nameUIC" : "Lupino",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762302,
            "codeCH" : 0,
            "nameUIC" : "Rivoli",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762310,
            "codeCH" : 0,
            "nameUIC" : "Bassanese",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762328,
            "codeCH" : 0,
            "nameUIC" : "L'Arinella",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762336,
            "codeCH" : "ER",
            "nameUIC" : "Montesoro",
            "nameCH" : "Erbajolo",
            "code" : ""
        },
        {
            "codeUIC" : 762336,
            "codeCH" : "SM",
            "nameUIC" : "Montesoro",
            "nameCH" : "Sole-Meo",
            "code" : ""
        },
        {
            "codeUIC" : 762336,
            "codeCH" : 0,
            "nameUIC" : "Montesoro",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762344,
            "codeCH" : 0,
            "nameUIC" : "La Rocade",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762351,
            "codeCH" : 0,
            "nameUIC" : "Fornacina",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762369,
            "codeCH" : 0,
            "nameUIC" : "Ceppe",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762906,
            "codeCH" : 0,
            "nameUIC" : "Lyon-St-Exupéry-TGV",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762930,
            "codeCH" : 0,
            "nameUIC" : "Montanay-Bif",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762948,
            "codeCH" : 0,
            "nameUIC" : "Miribel-LGV",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762963,
            "codeCH" : "BG",
            "nameUIC" : "Poste 44 Grenay",
            "nameCH" : "Bifurcation de Grenoble",
            "code" : ""
        },
        {
            "codeUIC" : 762963,
            "codeCH" : "BL",
            "nameUIC" : "Poste 44 Grenay",
            "nameCH" : "Bifurcation de Lyon",
            "code" : ""
        },
        {
            "codeUIC" : 762963,
            "codeCH" : "TV",
            "nameUIC" : "Poste 44 Grenay",
            "nameCH" : "Transition TVM",
            "code" : ""
        },
        {
            "codeUIC" : 762971,
            "codeCH" : "BM",
            "nameUIC" : "Grenay-PRG",
            "nameCH" : "Bifurcation Marseille",
            "code" : ""
        },
        {
            "codeUIC" : 762971,
            "codeCH" : "BS",
            "nameUIC" : "Grenay-PRG",
            "nameCH" : "Bifurcation de Satolas",
            "code" : ""
        },
        {
            "codeUIC" : 762971,
            "codeCH" : "RE",
            "nameUIC" : "Grenay-PRG",
            "nameCH" : "Repère 4431-Pk 419,4",
            "code" : ""
        },
        {
            "codeUIC" : 762989,
            "codeCH" : "AI",
            "nameUIC" : "St-Georges-d'Espéranche",
            "nameCH" : "Aiguille",
            "code" : ""
        },
        {
            "codeUIC" : 762989,
            "codeCH" : 0,
            "nameUIC" : "St-Georges-d'Espéranche",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 762997,
            "codeCH" : "AI",
            "nameUIC" : "Lapeyrouse-Mornay",
            "nameCH" : "Aiguille",
            "code" : ""
        },
        {
            "codeUIC" : 762997,
            "codeCH" : 0,
            "nameUIC" : "Lapeyrouse-Mornay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763003,
            "codeCH" : "BV",
            "nameUIC" : "Gap",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 763011,
            "codeCH" : 0,
            "nameUIC" : "Claveyson-Bren",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763029,
            "codeCH" : "BF",
            "nameUIC" : "Valence-TGV",
            "nameCH" : "Bifurcation de Valence",
            "code" : ""
        },
        {
            "codeUIC" : 763029,
            "codeCH" : "BV",
            "nameUIC" : "Valence-TGV",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 763029,
            "codeCH" : "J3",
            "nameUIC" : "Valence-TGV",
            "nameCH" : "Jonction V33/V5R",
            "code" : ""
        },
        {
            "codeUIC" : 763029,
            "codeCH" : "M5",
            "nameUIC" : "Valence-TGV",
            "nameCH" : "Jalon M 5105",
            "code" : ""
        },
        {
            "codeUIC" : 763029,
            "codeCH" : "M7",
            "nameUIC" : "Valence-TGV",
            "nameCH" : "Jalon M 5107",
            "code" : ""
        },
        {
            "codeUIC" : 763219,
            "codeCH" : 0,
            "nameUIC" : "La Beaume",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763250,
            "codeCH" : 0,
            "nameUIC" : "Aspres-sur-Buëch",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763300,
            "codeCH" : "BF",
            "nameUIC" : "Veynes-Dévoluy",
            "nameCH" : "Bifurcation 235,9/244,1",
            "code" : "VDY"
        },
        {
            "codeUIC" : 763300,
            "codeCH" : "BV",
            "nameUIC" : "Veynes-Dévoluy",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VDY"
        },
        {
            "codeUIC" : 763326,
            "codeCH" : 0,
            "nameUIC" : "Montmaur",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763334,
            "codeCH" : 0,
            "nameUIC" : "La Roche-des-Arnauds",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763342,
            "codeCH" : 0,
            "nameUIC" : "La Freissinouse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763417,
            "codeCH" : 0,
            "nameUIC" : "La Bâtie-Neuve-Le Laus",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763425,
            "codeCH" : 0,
            "nameUIC" : "Chorges",
            "nameCH" : "",
            "code" : "CRG"
        },
        {
            "codeUIC" : 763458,
            "codeCH" : 0,
            "nameUIC" : "Savines",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763466,
            "codeCH" : 0,
            "nameUIC" : "Embrun",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763482,
            "codeCH" : 0,
            "nameUIC" : "Châteauroux (Hautes-Alpes)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763508,
            "codeCH" : 0,
            "nameUIC" : "Montdauphin-Guillestre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763532,
            "codeCH" : 0,
            "nameUIC" : "La Roche-de-Rame",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763557,
            "codeCH" : 0,
            "nameUIC" : "L'Argentière-les-Écrins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763581,
            "codeCH" : 0,
            "nameUIC" : "Prelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763607,
            "codeCH" : 0,
            "nameUIC" : "Briançon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763722,
            "codeCH" : 0,
            "nameUIC" : "Serres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 763755,
            "codeCH" : 0,
            "nameUIC" : "Laragne",
            "nameCH" : "",
            "code" : "LRN"
        },
        {
            "codeUIC" : 764001,
            "codeCH" : "BV",
            "nameUIC" : "Montélimar",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 764001,
            "codeCH" : "ZI",
            "nameUIC" : "Montélimar",
            "nameCH" : "Garage ITE Zone Industrielle",
            "code" : ""
        },
        {
            "codeUIC" : 764001,
            "codeCH" : "1B",
            "nameUIC" : "Montélimar",
            "nameCH" : "Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 764001,
            "codeCH" : "4G",
            "nameUIC" : "Montélimar",
            "nameCH" : "Voie 4 Garage",
            "code" : ""
        },
        {
            "codeUIC" : 764241,
            "codeCH" : 0,
            "nameUIC" : "La Coucourde-Condillac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 764290,
            "codeCH" : 0,
            "nameUIC" : "Donzère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 764308,
            "codeCH" : "AG",
            "nameUIC" : "Pierrelatte",
            "nameCH" : "Aiguille 53 Km 691,7",
            "code" : "PRL"
        },
        {
            "codeUIC" : 764308,
            "codeCH" : "BF",
            "nameUIC" : "Pierrelatte",
            "nameCH" : "Bifurcation de Bollène",
            "code" : "PRL"
        },
        {
            "codeUIC" : 764308,
            "codeCH" : "BV",
            "nameUIC" : "Pierrelatte",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PRL"
        },
        {
            "codeUIC" : 764308,
            "codeCH" : "RL",
            "nameUIC" : "Pierrelatte",
            "nameCH" : "Bifurcation de Lamotte",
            "code" : "PRL"
        },
        {
            "codeUIC" : 764357,
            "codeCH" : 0,
            "nameUIC" : "Bollène-la-Croisière",
            "nameCH" : "",
            "code" : "BLN"
        },
        {
            "codeUIC" : 764407,
            "codeCH" : 0,
            "nameUIC" : "Le Pouzin",
            "nameCH" : "",
            "code" : "LEI"
        },
        {
            "codeUIC" : 764407,
            "codeCH" : "7G",
            "nameUIC" : "Le Pouzin",
            "nameCH" : "Voie 7 Garage",
            "code" : "LEI"
        },
        {
            "codeUIC" : 764449,
            "codeCH" : "BV",
            "nameUIC" : "Cruas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CUA"
        },
        {
            "codeUIC" : 764472,
            "codeCH" : "AS",
            "nameUIC" : "Le Teil (Ardèche)",
            "nameCH" : "Aiguille Km 667,0",
            "code" : "LTI"
        },
        {
            "codeUIC" : 764472,
            "codeCH" : "BV",
            "nameUIC" : "Le Teil (Ardèche)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "LTI"
        },
        {
            "codeUIC" : 764472,
            "codeCH" : "FA",
            "nameUIC" : "Le Teil (Ardèche)",
            "nameCH" : "Faisceau",
            "code" : "LTI"
        },
        {
            "codeUIC" : 764472,
            "codeCH" : "PN",
            "nameUIC" : "Le Teil (Ardèche)",
            "nameCH" : "Aiguille Km 664,1",
            "code" : "LTI"
        },
        {
            "codeUIC" : 764506,
            "codeCH" : "EP",
            "nameUIC" : "Viviers-sur-Rhône",
            "nameCH" : "ITE Ben Fares",
            "code" : ""
        },
        {
            "codeUIC" : 764522,
            "codeCH" : "BV",
            "nameUIC" : "Bourg-St-Andéol",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 764522,
            "codeCH" : "EP",
            "nameUIC" : "Bourg-St-Andéol",
            "nameCH" : "ITE Clergeau",
            "code" : ""
        },
        {
            "codeUIC" : 764522,
            "codeCH" : "2B",
            "nameUIC" : "Bourg-St-Andéol",
            "nameCH" : "Voie 2Bis",
            "code" : ""
        },
        {
            "codeUIC" : 764555,
            "codeCH" : 0,
            "nameUIC" : "Pont-St-Esprit",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 764571,
            "codeCH" : "BV",
            "nameUIC" : "Bagnols",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 764571,
            "codeCH" : "EP",
            "nameUIC" : "Bagnols",
            "nameCH" : "ITE Cogema",
            "code" : ""
        },
        {
            "codeUIC" : 764613,
            "codeCH" : 0,
            "nameUIC" : "L'Ardoise",
            "nameCH" : "",
            "code" : "LRD"
        },
        {
            "codeUIC" : 764613,
            "codeCH" : "1B",
            "nameUIC" : "L'Ardoise",
            "nameCH" : "Voie 1Bis",
            "code" : "LRD"
        },
        {
            "codeUIC" : 764613,
            "codeCH" : "2B",
            "nameUIC" : "L'Ardoise",
            "nameCH" : "Voie 2Bis",
            "code" : "LRD"
        },
        {
            "codeUIC" : 764704,
            "codeCH" : "EP",
            "nameUIC" : "Aubignas-Alba",
            "nameCH" : "ITE Basaltes",
            "code" : ""
        },
        {
            "codeUIC" : 764712,
            "codeCH" : "EP",
            "nameUIC" : "St-Jean-Le Centenier",
            "nameCH" : "ITE",
            "code" : ""
        },
        {
            "codeUIC" : 765008,
            "codeCH" : "BC",
            "nameUIC" : "Avignon",
            "nameCH" : "Bifurcation Cavaillon",
            "code" : "AV"
        },
        {
            "codeUIC" : 765008,
            "codeCH" : "BR",
            "nameUIC" : "Avignon",
            "nameCH" : "Bifurcation Raccordement",
            "code" : "AV"
        },
        {
            "codeUIC" : 765008,
            "codeCH" : "BV",
            "nameUIC" : "Avignon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AV"
        },
        {
            "codeUIC" : 765024,
            "codeCH" : "A1",
            "nameUIC" : "Avignon-Fontcouverte",
            "nameCH" : "Aiguille Km 3,305",
            "code" : "AFT"
        },
        {
            "codeUIC" : 765024,
            "codeCH" : "A2",
            "nameUIC" : "Avignon-Fontcouverte",
            "nameCH" : "Aiguille Km 3,383",
            "code" : "AFT"
        },
        {
            "codeUIC" : 765024,
            "codeCH" : "DP",
            "nameUIC" : "Avignon-Fontcouverte",
            "nameCH" : "Dépôt",
            "code" : "AFT"
        },
        {
            "codeUIC" : 765024,
            "codeCH" : "P1",
            "nameUIC" : "Avignon-Fontcouverte",
            "nameCH" : "Poste 1",
            "code" : "AFT"
        },
        {
            "codeUIC" : 765024,
            "codeCH" : "RI",
            "nameUIC" : "Avignon-Fontcouverte",
            "nameCH" : "Faisceau Réception Impair",
            "code" : "AFT"
        },
        {
            "codeUIC" : 765024,
            "codeCH" : "RP",
            "nameUIC" : "Avignon-Fontcouverte",
            "nameCH" : "Faisceau Réception Pair",
            "code" : "AFT"
        },
        {
            "codeUIC" : 765024,
            "codeCH" : "TA",
            "nameUIC" : "Avignon-Fontcouverte",
            "nameCH" : "Chantier TAC",
            "code" : "AFT"
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "BC",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Aiguilles Km 743,7",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "CA",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Panneau 182",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "CF",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Aiguille Km 742,9",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "DU",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Aiguille Km 744,5",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "EC",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Échange",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "FR",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "GH",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Voies G et H",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "IM",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Impasse",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "NO",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Chantier Multitechnique",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "PB",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Poste B",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "PC",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Poste C",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "PM",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Panneau 353",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "PN",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Panneau 174",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "UD",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Aiguille Km 744,4",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "VC",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Aiguille Km 743,9",
            "code" : ""
        },
        {
            "codeUIC" : 765040,
            "codeCH" : "2T",
            "nameUIC" : "Avignon-Champfleury",
            "nameCH" : "Aiguille Km 743,5",
            "code" : ""
        },
        {
            "codeUIC" : 765107,
            "codeCH" : "AS",
            "nameUIC" : "Orange",
            "nameCH" : "Aiguille Km 715,2",
            "code" : "OGE"
        },
        {
            "codeUIC" : 765107,
            "codeCH" : "BV",
            "nameUIC" : "Orange",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "OGE"
        },
        {
            "codeUIC" : 765107,
            "codeCH" : "KP",
            "nameUIC" : "Orange",
            "nameCH" : "Km 712,788 V2",
            "code" : "OGE"
        },
        {
            "codeUIC" : 765107,
            "codeCH" : "PK",
            "nameUIC" : "Orange",
            "nameCH" : "Km 712,125 V1",
            "code" : "OGE"
        },
        {
            "codeUIC" : 765107,
            "codeCH" : "PR",
            "nameUIC" : "Orange",
            "nameCH" : "Portique Km 714,6",
            "code" : "OGE"
        },
        {
            "codeUIC" : 765107,
            "codeCH" : "V4",
            "nameUIC" : "Orange",
            "nameCH" : "V 4 de Garage",
            "code" : "OGE"
        },
        {
            "codeUIC" : 765107,
            "codeCH" : "2B",
            "nameUIC" : "Orange",
            "nameCH" : "Voie 2Bis",
            "code" : "OGE"
        },
        {
            "codeUIC" : 765180,
            "codeCH" : "BV",
            "nameUIC" : "Courthézon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 765198,
            "codeCH" : 0,
            "nameUIC" : "Bédarrides",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765206,
            "codeCH" : "C1",
            "nameUIC" : "Sorgues-Châteauneuf-du-Pape",
            "nameCH" : "janv-02",
            "code" : "SCP"
        },
        {
            "codeUIC" : 765206,
            "codeCH" : "EP",
            "nameUIC" : "Sorgues-Châteauneuf-du-Pape",
            "nameCH" : "ITE Lotis Ind",
            "code" : "SCP"
        },
        {
            "codeUIC" : 765206,
            "codeCH" : 0,
            "nameUIC" : "Sorgues-Châteauneuf-du-Pape",
            "nameCH" : "",
            "code" : "SCP"
        },
        {
            "codeUIC" : 765206,
            "codeCH" : "2B",
            "nameUIC" : "Sorgues-Châteauneuf-du-Pape",
            "nameCH" : "Voie 2Bis",
            "code" : "SCP"
        },
        {
            "codeUIC" : 765206,
            "codeCH" : "3G",
            "nameUIC" : "Sorgues-Châteauneuf-du-Pape",
            "nameCH" : "V 3 de Garage",
            "code" : "SCP"
        },
        {
            "codeUIC" : 765255,
            "codeCH" : "BV",
            "nameUIC" : "Le Pontet",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 765305,
            "codeCH" : 0,
            "nameUIC" : "Barbentane-Rognonas",
            "nameCH" : "",
            "code" : "BBT"
        },
        {
            "codeUIC" : 765339,
            "codeCH" : "V3",
            "nameUIC" : "Graveson-Maillane",
            "nameCH" : "V 3 d'Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 765339,
            "codeCH" : "V4",
            "nameUIC" : "Graveson-Maillane",
            "nameCH" : "V 4 d'Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 765339,
            "codeCH" : 0,
            "nameUIC" : "Graveson-Maillane",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "BN",
            "nameUIC" : "Tarascon",
            "nameCH" : "Bifurcation de Nîmes",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "BV",
            "nameUIC" : "Tarascon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "EP",
            "nameUIC" : "Tarascon",
            "nameCH" : "Voie des ITE",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "KP",
            "nameUIC" : "Tarascon",
            "nameCH" : "Sortie IPCS Km 763,7",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "PK",
            "nameUIC" : "Tarascon",
            "nameCH" : "Poste Km 759,1",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "P5",
            "nameUIC" : "Tarascon",
            "nameCH" : "Panneau 5",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "RN",
            "nameUIC" : "Tarascon",
            "nameCH" : "Bifurcation Raccordement Nord",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "RS",
            "nameUIC" : "Tarascon",
            "nameCH" : "Bifurcation Raccordement Sud",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "VA",
            "nameUIC" : "Tarascon",
            "nameCH" : "V A de Garage",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "VB",
            "nameUIC" : "Tarascon",
            "nameCH" : "Voie B Circulation",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "VM",
            "nameUIC" : "Tarascon",
            "nameCH" : "Voies 6 à 10",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "V4",
            "nameUIC" : "Tarascon",
            "nameCH" : "V 4 de Garage",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765354,
            "codeCH" : "V5",
            "nameUIC" : "Tarascon",
            "nameCH" : "V 5 de Garage",
            "code" : "TCN"
        },
        {
            "codeUIC" : 765412,
            "codeCH" : 0,
            "nameUIC" : "Montfavet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765420,
            "codeCH" : 0,
            "nameUIC" : "Morières-lès-Avignon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765438,
            "codeCH" : 0,
            "nameUIC" : "St-Saturnin-d'Avignon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765446,
            "codeCH" : 0,
            "nameUIC" : "Gadagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765453,
            "codeCH" : 0,
            "nameUIC" : "Le Thor",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765479,
            "codeCH" : "VA",
            "nameUIC" : "L'Isle-Fontaine-de-Vaucluse",
            "nameCH" : "V A d'Évitement",
            "code" : ""
        },
        {
            "codeUIC" : 765479,
            "codeCH" : 0,
            "nameUIC" : "L'Isle-Fontaine-de-Vaucluse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765503,
            "codeCH" : "BT",
            "nameUIC" : "Cavaillon",
            "nameCH" : "Base Travaux",
            "code" : "CVN"
        },
        {
            "codeUIC" : 765503,
            "codeCH" : "BV",
            "nameUIC" : "Cavaillon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CVN"
        },
        {
            "codeUIC" : 765503,
            "codeCH" : "CB",
            "nameUIC" : "Cavaillon",
            "nameCH" : "Bifurcation ChevalBlanc",
            "code" : "CVN"
        },
        {
            "codeUIC" : 765578,
            "codeCH" : 0,
            "nameUIC" : "Lauris",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765586,
            "codeCH" : 0,
            "nameUIC" : "Cadenet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765594,
            "codeCH" : 0,
            "nameUIC" : "Villelaure",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765701,
            "codeCH" : "RJ",
            "nameUIC" : "Jonquières",
            "nameCH" : "Raccordement Jonquières",
            "code" : ""
        },
        {
            "codeUIC" : 765701,
            "codeCH" : "2J",
            "nameUIC" : "Jonquières",
            "nameCH" : "Carré 35106",
            "code" : ""
        },
        {
            "codeUIC" : 765735,
            "codeCH" : "BV",
            "nameUIC" : "Carpentras",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CAS"
        },
        {
            "codeUIC" : 765735,
            "codeCH" : "EP",
            "nameUIC" : "Carpentras",
            "nameCH" : "ITE Marché d'Intérêt National",
            "code" : "CAS"
        },
        {
            "codeUIC" : 765735,
            "codeCH" : "ZI",
            "nameUIC" : "Carpentras",
            "nameCH" : "ITE Zone Industrielle",
            "code" : "CAS"
        },
        {
            "codeUIC" : 765735,
            "codeCH" : 42,
            "nameUIC" : "Carpentras",
            "nameCH" : "C4102",
            "code" : "CAS"
        },
        {
            "codeUIC" : 765818,
            "codeCH" : "BV",
            "nameUIC" : "Monteux",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "MTX"
        },
        {
            "codeUIC" : 765826,
            "codeCH" : "BV",
            "nameUIC" : "Entraigues-sur-la-Sorgue",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 765826,
            "codeCH" : "EP",
            "nameUIC" : "Entraigues-sur-la-Sorgue",
            "nameCH" : "ITE Sifraco",
            "code" : ""
        },
        {
            "codeUIC" : 765842,
            "codeCH" : "EP",
            "nameUIC" : "Roquemaure-Tavel",
            "nameCH" : "ITE Cavaignac",
            "code" : ""
        },
        {
            "codeUIC" : 765875,
            "codeCH" : "BR",
            "nameUIC" : "Villeneuve-lès-Avignon",
            "nameCH" : "Bifurcation de Nîmes",
            "code" : ""
        },
        {
            "codeUIC" : 765875,
            "codeCH" : "BV",
            "nameUIC" : "Villeneuve-lès-Avignon",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 765875,
            "codeCH" : "RN",
            "nameUIC" : "Villeneuve-lès-Avignon",
            "nameCH" : "Rac Nord",
            "code" : ""
        },
        {
            "codeUIC" : 765875,
            "codeCH" : "RS",
            "nameUIC" : "Villeneuve-lès-Avignon",
            "nameCH" : "Rac Sud",
            "code" : ""
        },
        {
            "codeUIC" : 765883,
            "codeCH" : 0,
            "nameUIC" : "Aramon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 765883,
            "codeCH" : "1B",
            "nameUIC" : "Aramon",
            "nameCH" : "Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 772301,
            "codeCH" : 0,
            "nameUIC" : "Poste 10 Lieusaint-Moissy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772319,
            "codeCH" : 0,
            "nameUIC" : "Poste 11 Le Châtelet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772327,
            "codeCH" : "PK",
            "nameUIC" : "Poste 12 Marolles",
            "nameCH" : "Repère 1201",
            "code" : ""
        },
        {
            "codeUIC" : 772327,
            "codeCH" : 0,
            "nameUIC" : "Poste 12 Marolles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772335,
            "codeCH" : 0,
            "nameUIC" : "Poste 13 Cuy",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772343,
            "codeCH" : 0,
            "nameUIC" : "Poste 14 Vaumort",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772350,
            "codeCH" : "BI",
            "nameUIC" : "Poste 15 Vergigny",
            "nameCH" : "Km 117,9",
            "code" : ""
        },
        {
            "codeUIC" : 772350,
            "codeCH" : "PK",
            "nameUIC" : "Poste 15 Vergigny",
            "nameCH" : "Km 118,7",
            "code" : ""
        },
        {
            "codeUIC" : 772350,
            "codeCH" : "RP",
            "nameUIC" : "Poste 15 Vergigny",
            "nameCH" : "Repère 1530",
            "code" : ""
        },
        {
            "codeUIC" : 772350,
            "codeCH" : 0,
            "nameUIC" : "Poste 15 Vergigny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772368,
            "codeCH" : 0,
            "nameUIC" : "Poste 16 Tonnerre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772376,
            "codeCH" : "KM",
            "nameUIC" : "Pasilly",
            "nameCH" : "Km 160,2",
            "code" : ""
        },
        {
            "codeUIC" : 772376,
            "codeCH" : 0,
            "nameUIC" : "Pasilly",
            "nameCH" : "Poste 17",
            "code" : ""
        },
        {
            "codeUIC" : 772384,
            "codeCH" : "KM",
            "nameUIC" : "Poste 30 Étivey",
            "nameCH" : "Km 8,3",
            "code" : ""
        },
        {
            "codeUIC" : 772384,
            "codeCH" : 0,
            "nameUIC" : "Poste 30 Étivey",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772392,
            "codeCH" : 0,
            "nameUIC" : "Poste 18 Toutry",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772400,
            "codeCH" : 0,
            "nameUIC" : "Poste 19 Lacour-d'Arcenay",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772418,
            "codeCH" : 0,
            "nameUIC" : "Poste 20 Vianges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772426,
            "codeCH" : "PK",
            "nameUIC" : "Poste 21 Sully",
            "nameCH" : "Km 271,6",
            "code" : ""
        },
        {
            "codeUIC" : 772426,
            "codeCH" : 0,
            "nameUIC" : "Poste 21 Sully",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772434,
            "codeCH" : 0,
            "nameUIC" : "Poste 23 Vaux-en-Pré",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772442,
            "codeCH" : 0,
            "nameUIC" : "Poste 24 Cluny",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 772459,
            "codeCH" : 0,
            "nameUIC" : "Poste 27 Cesseins",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 773002,
            "codeCH" : "AR",
            "nameUIC" : "Montpellier-St-Roch",
            "nameCH" : "Arènes",
            "code" : ""
        },
        {
            "codeUIC" : 773002,
            "codeCH" : "AS",
            "nameUIC" : "Montpellier-St-Roch",
            "nameCH" : "Aiguille Km 77,9",
            "code" : ""
        },
        {
            "codeUIC" : 773002,
            "codeCH" : "BV",
            "nameUIC" : "Montpellier-St-Roch",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 773002,
            "codeCH" : "ES",
            "nameUIC" : "Montpellier-St-Roch",
            "nameCH" : "Entrée/Sortie Remisage",
            "code" : ""
        },
        {
            "codeUIC" : 773002,
            "codeCH" : "IP",
            "nameUIC" : "Montpellier-St-Roch",
            "nameCH" : "Sortie IPCS",
            "code" : ""
        },
        {
            "codeUIC" : 773002,
            "codeCH" : "PA",
            "nameUIC" : "Montpellier-St-Roch",
            "nameCH" : "Carré 373",
            "code" : ""
        },
        {
            "codeUIC" : 773002,
            "codeCH" : "RE",
            "nameUIC" : "Montpellier-St-Roch",
            "nameCH" : "Près d'Arènes",
            "code" : ""
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "AS",
            "nameUIC" : "Sète",
            "nameCH" : "Aiguille Km 102,3",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "BF",
            "nameUIC" : "Sète",
            "nameCH" : "Bifurcation Km 101,0",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "BN",
            "nameUIC" : "Sète",
            "nameCH" : "Bifurcation Km 98,0",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "BS",
            "nameUIC" : "Sète",
            "nameCH" : "Bifurcation Km 105,4",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "BV",
            "nameUIC" : "Sète",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "EF",
            "nameUIC" : "Sète",
            "nameCH" : "Entrée Faisceau",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "EP",
            "nameUIC" : "Sète",
            "nameCH" : "ITE Mobil-oil",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "FM",
            "nameUIC" : "Sète",
            "nameCH" : "F Méditérranée",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "GI",
            "nameUIC" : "Sète",
            "nameCH" : "Garage Impair",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "GP",
            "nameUIC" : "Sète",
            "nameCH" : "Garage Pair",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "J1",
            "nameUIC" : "Sète",
            "nameCH" : "Jonction Aig774 A/B",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "SM",
            "nameUIC" : "Sète",
            "nameCH" : "Marchandises",
            "code" : "STE"
        },
        {
            "codeUIC" : 773200,
            "codeCH" : "VT",
            "nameUIC" : "Sète",
            "nameCH" : "Tiroirs TMA/TMB",
            "code" : "STE"
        },
        {
            "codeUIC" : 773291,
            "codeCH" : "BB",
            "nameUIC" : "Sète-Bassin-de-Thau (Mèze)",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 773291,
            "codeCH" : "E1",
            "nameUIC" : "Sète-Bassin-de-Thau (Mèze)",
            "nameCH" : "E1",
            "code" : ""
        },
        {
            "codeUIC" : 773291,
            "codeCH" : "E2",
            "nameUIC" : "Sète-Bassin-de-Thau (Mèze)",
            "nameCH" : "E2",
            "code" : ""
        },
        {
            "codeUIC" : 773291,
            "codeCH" : "E3",
            "nameUIC" : "Sète-Bassin-de-Thau (Mèze)",
            "nameCH" : "E3",
            "code" : ""
        },
        {
            "codeUIC" : 773291,
            "codeCH" : "E5",
            "nameUIC" : "Sète-Bassin-de-Thau (Mèze)",
            "nameCH" : "E5",
            "code" : ""
        },
        {
            "codeUIC" : 773291,
            "codeCH" : "E6",
            "nameUIC" : "Sète-Bassin-de-Thau (Mèze)",
            "nameCH" : "E6",
            "code" : ""
        },
        {
            "codeUIC" : 773291,
            "codeCH" : 6,
            "nameUIC" : "Sète-Bassin-de-Thau (Mèze)",
            "nameCH" : "PN 6",
            "code" : ""
        },
        {
            "codeUIC" : 773408,
            "codeCH" : "BV",
            "nameUIC" : "Lunel",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 773408,
            "codeCH" : "TR",
            "nameUIC" : "Lunel",
            "nameCH" : "Triage",
            "code" : ""
        },
        {
            "codeUIC" : 773408,
            "codeCH" : "1B",
            "nameUIC" : "Lunel",
            "nameCH" : "Voie 1Bis",
            "code" : ""
        },
        {
            "codeUIC" : 773424,
            "codeCH" : 0,
            "nameUIC" : "Lunel-Viel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 773432,
            "codeCH" : 0,
            "nameUIC" : "Valergues-Lansargues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 773440,
            "codeCH" : 0,
            "nameUIC" : "St-Brès-Mudaison",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 773457,
            "codeCH" : "BV",
            "nameUIC" : "Baillargues",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 773457,
            "codeCH" : "P1",
            "nameUIC" : "Baillargues",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 773465,
            "codeCH" : 0,
            "nameUIC" : "St-Aunès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 773473,
            "codeCH" : 0,
            "nameUIC" : "Les Mazes-le-Crès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 773515,
            "codeCH" : "BV",
            "nameUIC" : "Villeneuve-lès-Maguelone",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 773515,
            "codeCH" : "P1",
            "nameUIC" : "Villeneuve-lès-Maguelone",
            "nameCH" : "Poste 1",
            "code" : ""
        },
        {
            "codeUIC" : 773531,
            "codeCH" : 0,
            "nameUIC" : "Vic-Mireval",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 773556,
            "codeCH" : "BV",
            "nameUIC" : "Frontignan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "AG",
            "nameUIC" : "Nîmes",
            "nameCH" : "Aiguille fin DV",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "AI",
            "nameUIC" : "Nîmes",
            "nameCH" : "Aiguille Km 25,9",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "A1",
            "nameUIC" : "Nîmes",
            "nameCH" : "IPCS Tarascon",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "A2",
            "nameUIC" : "Nîmes",
            "nameCH" : "IPCS Narbonne",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "BA",
            "nameUIC" : "Nîmes",
            "nameCH" : "Bifurcation côté Alès",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "BF",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste B ou F",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "BO",
            "nameUIC" : "Nîmes",
            "nameCH" : "Bifurcation de l'Observance",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "BV",
            "nameUIC" : "Nîmes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "CH",
            "nameUIC" : "Nîmes",
            "nameCH" : "Carré 27",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "CM",
            "nameUIC" : "Nîmes",
            "nameCH" : "Centre Maintenance régional",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "DP",
            "nameUIC" : "Nîmes",
            "nameCH" : "Dépôt",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "FR",
            "nameUIC" : "Nîmes",
            "nameCH" : "Faisceau Réception",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "J1",
            "nameUIC" : "Nîmes",
            "nameCH" : "Jonction 1",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "PA",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste A",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "PC",
            "nameUIC" : "Nîmes",
            "nameCH" : "Signal 34",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "PJ",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste J",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "P1",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste 1",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "P2",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste 2",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "P3",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste 3",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "P4",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste 4",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "VA",
            "nameUIC" : "Nîmes",
            "nameCH" : "V A Rebroussement",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "1P",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste 1",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "2B",
            "nameUIC" : "Nîmes",
            "nameCH" : "V2B Rebroussement",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : "2P",
            "nameUIC" : "Nîmes",
            "nameCH" : "Poste 2",
            "code" : "NI"
        },
        {
            "codeUIC" : 775007,
            "codeCH" : 28,
            "nameUIC" : "Nîmes",
            "nameCH" : "Carré Violet 28",
            "code" : "NI"
        },
        {
            "codeUIC" : 775023,
            "codeCH" : 0,
            "nameUIC" : "Beaucaire",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775031,
            "codeCH" : "EP",
            "nameUIC" : "Beaucaire-Marchandises",
            "nameCH" : "ITE Martini",
            "code" : "BCA"
        },
        {
            "codeUIC" : 775031,
            "codeCH" : "P1",
            "nameUIC" : "Beaucaire-Marchandises",
            "nameCH" : "Poste St-Montan",
            "code" : "BCA"
        },
        {
            "codeUIC" : 775031,
            "codeCH" : "ZN",
            "nameUIC" : "Beaucaire-Marchandises",
            "nameCH" : "Zone Portuaire",
            "code" : "BCA"
        },
        {
            "codeUIC" : 775049,
            "codeCH" : 0,
            "nameUIC" : "Jonquières-St-Vincent",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775064,
            "codeCH" : "BF",
            "nameUIC" : "Manduel-Redessan",
            "nameCH" : "Bifurcation LGV",
            "code" : ""
        },
        {
            "codeUIC" : 775064,
            "codeCH" : "BV",
            "nameUIC" : "Manduel-Redessan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775072,
            "codeCH" : "BV",
            "nameUIC" : "St-Césaire",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SCE"
        },
        {
            "codeUIC" : 775072,
            "codeCH" : "FA",
            "nameUIC" : "St-Césaire",
            "nameCH" : "Faisceau",
            "code" : "SCE"
        },
        {
            "codeUIC" : 775072,
            "codeCH" : "P1",
            "nameUIC" : "St-Césaire",
            "nameCH" : "Bifurcation Km 31,6",
            "code" : "SCE"
        },
        {
            "codeUIC" : 775072,
            "codeCH" : "2B",
            "nameUIC" : "St-Césaire",
            "nameCH" : "Voie 2 Bis",
            "code" : "SCE"
        },
        {
            "codeUIC" : 775098,
            "codeCH" : 0,
            "nameUIC" : "Milhaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775106,
            "codeCH" : 0,
            "nameUIC" : "Uchaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775114,
            "codeCH" : "BV",
            "nameUIC" : "Vergèze-Codognan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VZC"
        },
        {
            "codeUIC" : 775122,
            "codeCH" : 0,
            "nameUIC" : "Aigues-Vives",
            "nameCH" : "",
            "code" : "AIV"
        },
        {
            "codeUIC" : 775130,
            "codeCH" : 0,
            "nameUIC" : "Gallargues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775148,
            "codeCH" : 0,
            "nameUIC" : "Chasseradès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775171,
            "codeCH" : "AG",
            "nameUIC" : "La Bastide-St-Laurent-les-Bain",
            "nameCH" : "Aiguille Km 692 0",
            "code" : ""
        },
        {
            "codeUIC" : 775171,
            "codeCH" : 0,
            "nameUIC" : "La Bastide-St-Laurent-les-Bain",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775189,
            "codeCH" : 0,
            "nameUIC" : "Prévenchères-Gorges-du-Chassez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775197,
            "codeCH" : 0,
            "nameUIC" : "Villefort",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775205,
            "codeCH" : 0,
            "nameUIC" : "Concoules-Ponteils",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775213,
            "codeCH" : 0,
            "nameUIC" : "Génolhac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775221,
            "codeCH" : 0,
            "nameUIC" : "Chamborigaud",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775239,
            "codeCH" : 0,
            "nameUIC" : "Ste-Cécile-d'Andorge",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775247,
            "codeCH" : 0,
            "nameUIC" : "La Levade",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775254,
            "codeCH" : "BV",
            "nameUIC" : "Grand'Combe-La Pise",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 775262,
            "codeCH" : 0,
            "nameUIC" : "Malbosc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775270,
            "codeCH" : 0,
            "nameUIC" : "Tamaris",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775288,
            "codeCH" : "BV",
            "nameUIC" : "Alès",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "ALE"
        },
        {
            "codeUIC" : 775288,
            "codeCH" : "CE",
            "nameUIC" : "Alès",
            "nameCH" : "ITE CEC",
            "code" : "ALE"
        },
        {
            "codeUIC" : 775288,
            "codeCH" : "CR",
            "nameUIC" : "Alès",
            "nameCH" : "ITE Cruvellier",
            "code" : "ALE"
        },
        {
            "codeUIC" : 775288,
            "codeCH" : "PA",
            "nameUIC" : "Alès",
            "nameCH" : "Carré 104",
            "code" : "ALE"
        },
        {
            "codeUIC" : 775304,
            "codeCH" : 0,
            "nameUIC" : "St-Hilaire-de-Brethmas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775312,
            "codeCH" : 0,
            "nameUIC" : "Mas-des-Gardies",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775320,
            "codeCH" : 0,
            "nameUIC" : "Vézénobres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775346,
            "codeCH" : 0,
            "nameUIC" : "Boucoiran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775353,
            "codeCH" : 0,
            "nameUIC" : "Nozières-Brignon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775361,
            "codeCH" : 0,
            "nameUIC" : "St-Geniès-de-Malgoirès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775379,
            "codeCH" : "S7",
            "nameUIC" : "Fons-St-Mamert",
            "nameCH" : "Sémaphore 708,4",
            "code" : ""
        },
        {
            "codeUIC" : 775379,
            "codeCH" : 0,
            "nameUIC" : "Fons-St-Mamert",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775387,
            "codeCH" : "BV",
            "nameUIC" : "Mas-de-Ponge",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 775395,
            "codeCH" : 0,
            "nameUIC" : "Remoulins-Pont-du-Gard",
            "nameCH" : "",
            "code" : "RMS"
        },
        {
            "codeUIC" : 775403,
            "codeCH" : "EP",
            "nameUIC" : "Lédenon",
            "nameCH" : "ITE Km 774,3",
            "code" : ""
        },
        {
            "codeUIC" : 775403,
            "codeCH" : 0,
            "nameUIC" : "Lédenon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775411,
            "codeCH" : "RF",
            "nameUIC" : "St-Gervasy-Bezouce",
            "nameCH" : "Raccordement St-Gervasy",
            "code" : ""
        },
        {
            "codeUIC" : 775411,
            "codeCH" : "VO",
            "nameUIC" : "St-Gervasy-Bezouce",
            "nameCH" : "Virgulette Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 775494,
            "codeCH" : 0,
            "nameUIC" : "Bessèges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775502,
            "codeCH" : 0,
            "nameUIC" : "Robiac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775510,
            "codeCH" : 0,
            "nameUIC" : "Gammal",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775528,
            "codeCH" : 0,
            "nameUIC" : "Molières-sur-Cèze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775536,
            "codeCH" : "BV",
            "nameUIC" : "St-Ambroix",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 775544,
            "codeCH" : 0,
            "nameUIC" : "St-Julien-les-Fumades",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775551,
            "codeCH" : 0,
            "nameUIC" : "Salindres",
            "nameCH" : "",
            "code" : "SLD"
        },
        {
            "codeUIC" : 775718,
            "codeCH" : 0,
            "nameUIC" : "Quissac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775734,
            "codeCH" : 0,
            "nameUIC" : "Sommières",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775791,
            "codeCH" : "BV",
            "nameUIC" : "Générac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 775791,
            "codeCH" : "EP",
            "nameUIC" : "Générac",
            "nameCH" : "ITE Covial",
            "code" : ""
        },
        {
            "codeUIC" : 775809,
            "codeCH" : 0,
            "nameUIC" : "Beauvoisin",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775817,
            "codeCH" : "BV",
            "nameUIC" : "Vauvert",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "VAT"
        },
        {
            "codeUIC" : 775817,
            "codeCH" : "EP",
            "nameUIC" : "Vauvert",
            "nameCH" : "ITE Commune de Vauvert",
            "code" : "VAT"
        },
        {
            "codeUIC" : 775825,
            "codeCH" : "BT",
            "nameUIC" : "Le Cailar",
            "nameCH" : "B Trinquetaille",
            "code" : ""
        },
        {
            "codeUIC" : 775825,
            "codeCH" : "BV",
            "nameUIC" : "Le Cailar",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 775833,
            "codeCH" : 0,
            "nameUIC" : "Aimargues",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775841,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-d'Aigouze",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775858,
            "codeCH" : "BV",
            "nameUIC" : "Aigues-Mortes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "AIM"
        },
        {
            "codeUIC" : 775858,
            "codeCH" : "EP",
            "nameUIC" : "Aigues-Mortes",
            "nameCH" : "ITE Salins du Midi",
            "code" : "AIM"
        },
        {
            "codeUIC" : 775866,
            "codeCH" : 0,
            "nameUIC" : "Le Grau-du-Roi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775874,
            "codeCH" : 0,
            "nameUIC" : "Poste Km 365,7",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 775882,
            "codeCH" : "BS",
            "nameUIC" : "St-Gilles-du-Gard",
            "nameCH" : "Bâtiment Service",
            "code" : "SGG"
        },
        {
            "codeUIC" : 775882,
            "codeCH" : "EC",
            "nameUIC" : "St-Gilles-du-Gard",
            "nameCH" : "ITE Claeys Luck",
            "code" : "SGG"
        },
        {
            "codeUIC" : 775882,
            "codeCH" : "EG",
            "nameUIC" : "St-Gilles-du-Gard",
            "nameCH" : "ITE Guichard",
            "code" : "SGG"
        },
        {
            "codeUIC" : 776021,
            "codeCH" : 0,
            "nameUIC" : "Orthoux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 776039,
            "codeCH" : 0,
            "nameUIC" : "Fontanès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781005,
            "codeCH" : "AE",
            "nameUIC" : "Béziers",
            "nameCH" : "Aiguille Km 433,030",
            "code" : "BS"
        },
        {
            "codeUIC" : 781005,
            "codeCH" : "BV",
            "nameUIC" : "Béziers",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "BS"
        },
        {
            "codeUIC" : 781005,
            "codeCH" : "P2",
            "nameUIC" : "Béziers",
            "nameCH" : "Aiguille Km 431,2",
            "code" : "BS"
        },
        {
            "codeUIC" : 781005,
            "codeCH" : "P8",
            "nameUIC" : "Béziers",
            "nameCH" : "Poste 8 Km 434",
            "code" : "BS"
        },
        {
            "codeUIC" : 781005,
            "codeCH" : "TR",
            "nameUIC" : "Béziers",
            "nameCH" : "Capiscol",
            "code" : "BS"
        },
        {
            "codeUIC" : 781005,
            "codeCH" : "ZI",
            "nameUIC" : "Béziers",
            "nameCH" : "ZI de Béziers",
            "code" : "BS"
        },
        {
            "codeUIC" : 781005,
            "codeCH" : 14,
            "nameUIC" : "Béziers",
            "nameCH" : "Voies 14 et 15",
            "code" : "BS"
        },
        {
            "codeUIC" : 781039,
            "codeCH" : "TC",
            "nameUIC" : "Marcorignan",
            "nameCH" : "TCT Km 391,350",
            "code" : ""
        },
        {
            "codeUIC" : 781039,
            "codeCH" : 0,
            "nameUIC" : "Marcorignan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781054,
            "codeCH" : "BV",
            "nameUIC" : "Gruissan-Tournebelle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 781054,
            "codeCH" : "EB",
            "nameUIC" : "Gruissan-Tournebelle",
            "nameCH" : "Voie Barlabe",
            "code" : ""
        },
        {
            "codeUIC" : 781062,
            "codeCH" : "BV",
            "nameUIC" : "Port-la-Nouvelle",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PLN"
        },
        {
            "codeUIC" : 781062,
            "codeCH" : "EP",
            "nameUIC" : "Port-la-Nouvelle",
            "nameCH" : "ITE SMS",
            "code" : "PLN"
        },
        {
            "codeUIC" : 781062,
            "codeCH" : "1B",
            "nameUIC" : "Port-la-Nouvelle",
            "nameCH" : "Voie 1 Bis",
            "code" : "PLN"
        },
        {
            "codeUIC" : 781088,
            "codeCH" : 0,
            "nameUIC" : "Leucate-la-Franqui",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "AC",
            "nameUIC" : "Narbonne",
            "nameCH" : "Aiguille Km 403,3",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "AN",
            "nameUIC" : "Narbonne",
            "nameCH" : "Aiguille Km 406,8",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "BB",
            "nameUIC" : "Narbonne",
            "nameCH" : "Bifurcation de Toulouse",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "BP",
            "nameUIC" : "Narbonne",
            "nameCH" : "Bifurcation Port-Bou",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "BV",
            "nameUIC" : "Narbonne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "DP",
            "nameUIC" : "Narbonne",
            "nameCH" : "Dépôt",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "EC",
            "nameUIC" : "Narbonne",
            "nameCH" : "ITE Comurhex",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "LP",
            "nameUIC" : "Narbonne",
            "nameCH" : "Bifurcation Km 404,7 (LGV)",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "PL",
            "nameUIC" : "Narbonne",
            "nameCH" : "Bifurcation Km 404,7",
            "code" : "NA"
        },
        {
            "codeUIC" : 781104,
            "codeCH" : "PN",
            "nameUIC" : "Narbonne",
            "nameCH" : "Carré 12",
            "code" : "NA"
        },
        {
            "codeUIC" : 781161,
            "codeCH" : 0,
            "nameUIC" : "Coursan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781179,
            "codeCH" : 0,
            "nameUIC" : "Nissan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781187,
            "codeCH" : 0,
            "nameUIC" : "Colombiers",
            "nameCH" : "",
            "code" : "CBS"
        },
        {
            "codeUIC" : 781245,
            "codeCH" : 0,
            "nameUIC" : "Villeneuve-lès-Béziers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781260,
            "codeCH" : 0,
            "nameUIC" : "Vias",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781278,
            "codeCH" : 0,
            "nameUIC" : "Agde",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781294,
            "codeCH" : 0,
            "nameUIC" : "Marseillan-Plage",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781310,
            "codeCH" : 0,
            "nameUIC" : "Bessan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781328,
            "codeCH" : "BV",
            "nameUIC" : "Florensac",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "FEA"
        },
        {
            "codeUIC" : 781328,
            "codeCH" : "EP",
            "nameUIC" : "Florensac",
            "nameCH" : "ITE Gleize",
            "code" : "FEA"
        },
        {
            "codeUIC" : 781344,
            "codeCH" : "BV",
            "nameUIC" : "Pézenas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 781344,
            "codeCH" : "EP",
            "nameUIC" : "Pézenas",
            "nameCH" : "ITE Carrières et Travaux du Sud-Ouest",
            "code" : ""
        },
        {
            "codeUIC" : 781518,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-et-St-Paul",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781526,
            "codeCH" : 0,
            "nameUIC" : "Lauglanet-St-Beaulize",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781534,
            "codeCH" : 0,
            "nameUIC" : "Montpaon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781542,
            "codeCH" : 0,
            "nameUIC" : "Ceilhes-Roqueredonde",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781559,
            "codeCH" : 0,
            "nameUIC" : "Les Cabrils",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781567,
            "codeCH" : 0,
            "nameUIC" : "Joncels",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781575,
            "codeCH" : 0,
            "nameUIC" : "Lunas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781583,
            "codeCH" : 0,
            "nameUIC" : "Le Bousquet-d'Orb",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781609,
            "codeCH" : 0,
            "nameUIC" : "Bédarieux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781641,
            "codeCH" : 0,
            "nameUIC" : "Faugères",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781658,
            "codeCH" : 0,
            "nameUIC" : "Laurens",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781666,
            "codeCH" : 0,
            "nameUIC" : "Magalas",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781674,
            "codeCH" : 0,
            "nameUIC" : "Espondeilhan",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781690,
            "codeCH" : 0,
            "nameUIC" : "Ribaute-les-Lieuran",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 781856,
            "codeCH" : 0,
            "nameUIC" : "Sallèles-d'Aude",
            "nameCH" : "",
            "code" : "SUF"
        },
        {
            "codeUIC" : 781864,
            "codeCH" : "EP",
            "nameUIC" : "Mirepeisset-Argeliers",
            "nameCH" : "ITE TDF",
            "code" : ""
        },
        {
            "codeUIC" : 781872,
            "codeCH" : 0,
            "nameUIC" : "Bize (Aude)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 782607,
            "codeCH" : 0,
            "nameUIC" : "Clermont-La Pardieu",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783001,
            "codeCH" : 0,
            "nameUIC" : "Millau",
            "nameCH" : "",
            "code" : "MAU"
        },
        {
            "codeUIC" : 783159,
            "codeCH" : 0,
            "nameUIC" : "Talizat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783167,
            "codeCH" : 0,
            "nameUIC" : "Andelat",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783175,
            "codeCH" : 0,
            "nameUIC" : "St-Flour-Chaudes-Aigues",
            "nameCH" : "",
            "code" : "SFA"
        },
        {
            "codeUIC" : 783209,
            "codeCH" : 0,
            "nameUIC" : "Ruynes-en-Margeride",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783217,
            "codeCH" : 0,
            "nameUIC" : "Garabit",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783225,
            "codeCH" : 0,
            "nameUIC" : "Loubaresse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783233,
            "codeCH" : 0,
            "nameUIC" : "Arcomie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783241,
            "codeCH" : 0,
            "nameUIC" : "St-Chély-d'Apcher",
            "nameCH" : "",
            "code" : "SCY"
        },
        {
            "codeUIC" : 783266,
            "codeCH" : 0,
            "nameUIC" : "Aumont-Aubrac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783274,
            "codeCH" : 0,
            "nameUIC" : "St-Sauveur-de-Peyre",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783282,
            "codeCH" : 0,
            "nameUIC" : "Marvejols",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783290,
            "codeCH" : 0,
            "nameUIC" : "Chirac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783308,
            "codeCH" : 0,
            "nameUIC" : "Le Monastier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783316,
            "codeCH" : 0,
            "nameUIC" : "Auxillac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783324,
            "codeCH" : 0,
            "nameUIC" : "Banassac-La Canourgue",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783332,
            "codeCH" : 0,
            "nameUIC" : "St-Laurent-d'Olt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783340,
            "codeCH" : 0,
            "nameUIC" : "Campagnac-St-Geniez",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783365,
            "codeCH" : "BV",
            "nameUIC" : "Sévérac-le-Château",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "SVC"
        },
        {
            "codeUIC" : 783365,
            "codeCH" : "CG",
            "nameUIC" : "Sévérac-le-Château",
            "nameCH" : "Pancarte Col de La Garde",
            "code" : "SVC"
        },
        {
            "codeUIC" : 783365,
            "codeCH" : "CV",
            "nameUIC" : "Sévérac-le-Château",
            "nameCH" : "Carré violet 28",
            "code" : "SVC"
        },
        {
            "codeUIC" : 783381,
            "codeCH" : 0,
            "nameUIC" : "Engayresque",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783399,
            "codeCH" : 0,
            "nameUIC" : "Aguessac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783423,
            "codeCH" : 0,
            "nameUIC" : "St-Georges-de-Luzençon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783431,
            "codeCH" : 0,
            "nameUIC" : "St-Rome-de-Cernon",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783456,
            "codeCH" : 0,
            "nameUIC" : "Tournemire-Roquefort",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783514,
            "codeCH" : 0,
            "nameUIC" : "Les Salelles",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783530,
            "codeCH" : 0,
            "nameUIC" : "Chanac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783548,
            "codeCH" : 0,
            "nameUIC" : "Le Bruel",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783563,
            "codeCH" : 0,
            "nameUIC" : "Barjac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783589,
            "codeCH" : 0,
            "nameUIC" : "Balsièges",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783605,
            "codeCH" : "BV",
            "nameUIC" : "Mende",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 783621,
            "codeCH" : 0,
            "nameUIC" : "Badaroux",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783647,
            "codeCH" : 0,
            "nameUIC" : "Bagnols-Chadenet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783654,
            "codeCH" : 0,
            "nameUIC" : "Allenc",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783662,
            "codeCH" : 0,
            "nameUIC" : "Larzalier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783670,
            "codeCH" : 0,
            "nameUIC" : "Belvezet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783688,
            "codeCH" : 0,
            "nameUIC" : "Daufage-le-Goulet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783738,
            "codeCH" : 0,
            "nameUIC" : "Gages",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783753,
            "codeCH" : 0,
            "nameUIC" : "Bertholène",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 783761,
            "codeCH" : 0,
            "nameUIC" : "Laissac",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "AC",
            "nameUIC" : "Perpignan",
            "nameCH" : "Accès Dépôt/Base travaux",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "AI",
            "nameUIC" : "Perpignan",
            "nameCH" : "Aiguille Km 467,1",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "A1",
            "nameUIC" : "Perpignan",
            "nameCH" : "Aiguille Km 468,6",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "A2",
            "nameUIC" : "Perpignan",
            "nameCH" : "Aiguille Km 469,8",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "BF",
            "nameUIC" : "Perpignan",
            "nameCH" : "Bifurcation Km 471",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "BV",
            "nameUIC" : "Perpignan",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "CR",
            "nameUIC" : "Perpignan",
            "nameCH" : "St-Charles",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "DP",
            "nameUIC" : "Perpignan",
            "nameCH" : "Dépôt/Base travaux",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "ES",
            "nameUIC" : "Perpignan",
            "nameCH" : "Entrée/Sortie LGV",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "EV",
            "nameUIC" : "Perpignan",
            "nameCH" : "Faisceau Chef de Bien",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "FI",
            "nameUIC" : "Perpignan",
            "nameCH" : "Faisceau International",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "FR",
            "nameUIC" : "Perpignan",
            "nameCH" : "Entrée/Sortie Faisceau",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "RV",
            "nameUIC" : "Perpignan",
            "nameCH" : "Remisage Voyageurs",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784009,
            "codeCH" : "TR",
            "nameUIC" : "Perpignan",
            "nameCH" : "Triage",
            "code" : "PPN"
        },
        {
            "codeUIC" : 784157,
            "codeCH" : 0,
            "nameUIC" : "Salses",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784173,
            "codeCH" : "BV",
            "nameUIC" : "Rivesaltes",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "RVT"
        },
        {
            "codeUIC" : 784173,
            "codeCH" : "JF",
            "nameUIC" : "Rivesaltes",
            "nameCH" : "ITE Semer",
            "code" : "RVT"
        },
        {
            "codeUIC" : 784173,
            "codeCH" : "OM",
            "nameUIC" : "Rivesaltes",
            "nameCH" : "ITE Omya",
            "code" : "RVT"
        },
        {
            "codeUIC" : 784173,
            "codeCH" : "SP",
            "nameUIC" : "Rivesaltes",
            "nameCH" : "ITE Sopagly",
            "code" : "RVT"
        },
        {
            "codeUIC" : 784207,
            "codeCH" : "BV",
            "nameUIC" : "Elne",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 784207,
            "codeCH" : "PT",
            "nameUIC" : "Elne",
            "nameCH" : "Le Tech",
            "code" : ""
        },
        {
            "codeUIC" : 784231,
            "codeCH" : 0,
            "nameUIC" : "Argelès-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784256,
            "codeCH" : "BV",
            "nameUIC" : "Collioure",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 784264,
            "codeCH" : "BV",
            "nameUIC" : "Port-Vendres-Ville",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 784298,
            "codeCH" : 0,
            "nameUIC" : "Banyuls-sur-Mer",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784322,
            "codeCH" : 0,
            "nameUIC" : "Banyuls-dels-Aspres",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784330,
            "codeCH" : 0,
            "nameUIC" : "Le Boulou-Perthus",
            "nameCH" : "",
            "code" : "LBP"
        },
        {
            "codeUIC" : 784355,
            "codeCH" : 0,
            "nameUIC" : "St-Jean-Pla-de-Corts",
            "nameCH" : "",
            "code" : "SJP"
        },
        {
            "codeUIC" : 784363,
            "codeCH" : 0,
            "nameUIC" : "Céret",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784439,
            "codeCH" : "BS",
            "nameUIC" : "Lapradelle",
            "nameCH" : "Bâtiment Service",
            "code" : "LPW"
        },
        {
            "codeUIC" : 784447,
            "codeCH" : "BS",
            "nameUIC" : "Caudiès",
            "nameCH" : "Bâtiment Service",
            "code" : "CDI"
        },
        {
            "codeUIC" : 784454,
            "codeCH" : "BS",
            "nameUIC" : "St-Paul-de-Fenouillet",
            "nameCH" : "Bâtiment Service",
            "code" : "SPF"
        },
        {
            "codeUIC" : 784470,
            "codeCH" : "BS",
            "nameUIC" : "Estagel",
            "nameCH" : "Bâtiment Service",
            "code" : ""
        },
        {
            "codeUIC" : 784488,
            "codeCH" : "BS",
            "nameUIC" : "Cases-de-Pène",
            "nameCH" : "Bâtiment Service",
            "code" : "CDP"
        },
        {
            "codeUIC" : 784488,
            "codeCH" : "EC",
            "nameUIC" : "Cases-de-Pène",
            "nameCH" : "ITE Carrières Agly",
            "code" : "CDP"
        },
        {
            "codeUIC" : 784512,
            "codeCH" : 0,
            "nameUIC" : "Le Soler",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784520,
            "codeCH" : 0,
            "nameUIC" : "St-Féliu-d'Avall",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784546,
            "codeCH" : "BV",
            "nameUIC" : "Millas",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 784546,
            "codeCH" : 48,
            "nameUIC" : "Millas",
            "nameCH" : "Sémaphore 481",
            "code" : ""
        },
        {
            "codeUIC" : 784561,
            "codeCH" : 0,
            "nameUIC" : "Ille-sur-Têt",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784595,
            "codeCH" : 0,
            "nameUIC" : "Bouleternère",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784611,
            "codeCH" : 0,
            "nameUIC" : "Vinça",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784629,
            "codeCH" : 0,
            "nameUIC" : "Marquixanes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784637,
            "codeCH" : 0,
            "nameUIC" : "Prades-Molitg-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784660,
            "codeCH" : "BV",
            "nameUIC" : "Ria",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 784660,
            "codeCH" : "EP",
            "nameUIC" : "Ria",
            "nameCH" : "ITE Denain/Anz",
            "code" : ""
        },
        {
            "codeUIC" : 784686,
            "codeCH" : 0,
            "nameUIC" : "Villefranche-Vernet-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784702,
            "codeCH" : 0,
            "nameUIC" : "Serdinya",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784710,
            "codeCH" : 0,
            "nameUIC" : "Joncet",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784728,
            "codeCH" : 0,
            "nameUIC" : "Olette-Canaveilles-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784736,
            "codeCH" : 0,
            "nameUIC" : "Nyers",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784744,
            "codeCH" : 0,
            "nameUIC" : "Thuès-les-Bains",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784751,
            "codeCH" : 0,
            "nameUIC" : "Thuès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784769,
            "codeCH" : 0,
            "nameUIC" : "Fontpédrouse-St-Thomas-les-Bai",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784777,
            "codeCH" : 0,
            "nameUIC" : "Sauto",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784785,
            "codeCH" : 0,
            "nameUIC" : "Planès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784793,
            "codeCH" : 0,
            "nameUIC" : "Mont-Louis-La Cabanasse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784801,
            "codeCH" : 0,
            "nameUIC" : "Bolquère-Eyne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784819,
            "codeCH" : 0,
            "nameUIC" : "Font-Romeu-Odeillo-Via",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784827,
            "codeCH" : 0,
            "nameUIC" : "Estavar",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784835,
            "codeCH" : 0,
            "nameUIC" : "Saillagouse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784843,
            "codeCH" : 0,
            "nameUIC" : "Err",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784850,
            "codeCH" : 0,
            "nameUIC" : "Ste-Léocadie",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784868,
            "codeCH" : 0,
            "nameUIC" : "Osséja",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784876,
            "codeCH" : 0,
            "nameUIC" : "Bourg-Madame",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784884,
            "codeCH" : 0,
            "nameUIC" : "Ur-les-Escaldes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 784892,
            "codeCH" : 0,
            "nameUIC" : "Béna-Fanès",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 785006,
            "codeCH" : "BV",
            "nameUIC" : "Cerbère",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : "CE"
        },
        {
            "codeUIC" : 785006,
            "codeCH" : "PF",
            "nameUIC" : "Cerbère",
            "nameCH" : "Poste F",
            "code" : "CE"
        },
        {
            "codeUIC" : 785006,
            "codeCH" : "P1",
            "nameUIC" : "Cerbère",
            "nameCH" : "Poste 1",
            "code" : "CE"
        },
        {
            "codeUIC" : 785006,
            "codeCH" : "P2",
            "nameUIC" : "Cerbère",
            "nameCH" : "Poste 2",
            "code" : "CE"
        },
        {
            "codeUIC" : 785006,
            "codeCH" : "XE",
            "nameUIC" : "Cerbère",
            "nameCH" : "Frontière France-Espagne",
            "code" : "CE"
        },
        {
            "codeUIC" : 785907,
            "codeCH" : "WE",
            "nameUIC" : "Cerbère (IE)",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 785956,
            "codeCH" : "WE",
            "nameUIC" : "Portbou",
            "nameCH" : "Bâtiment Voyageurs",
            "code" : ""
        },
        {
            "codeUIC" : 789370,
            "codeCH" : "BF",
            "nameUIC" : "Le Castellas",
            "nameCH" : "Bifurcation Km 462,4",
            "code" : ""
        },
        {
            "codeUIC" : 846089,
            "codeCH" : 0,
            "nameUIC" : "PN 150",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 860817,
            "codeCH" : 0,
            "nameUIC" : "Ajaccio",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 860825,
            "codeCH" : 0,
            "nameUIC" : "Bastia",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 860833,
            "codeCH" : "CH",
            "nameUIC" : "Calvi",
            "nameCH" : "Club Horizon",
            "code" : ""
        },
        {
            "codeUIC" : 860833,
            "codeCH" : "LB",
            "nameUIC" : "Calvi",
            "nameCH" : "Le Balagne",
            "code" : ""
        },
        {
            "codeUIC" : 860833,
            "codeCH" : "LP",
            "nameUIC" : "Calvi",
            "nameCH" : "Lido-Plage",
            "code" : ""
        },
        {
            "codeUIC" : 860833,
            "codeCH" : 0,
            "nameUIC" : "Calvi",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 860841,
            "codeCH" : "AQ",
            "nameUIC" : "L'Île-Rousse",
            "nameCH" : "Aquarium",
            "code" : ""
        },
        {
            "codeUIC" : 860841,
            "codeCH" : "BO",
            "nameUIC" : "L'Île-Rousse",
            "nameCH" : "Bodri",
            "code" : ""
        },
        {
            "codeUIC" : 860841,
            "codeCH" : "MC",
            "nameUIC" : "L'Île-Rousse",
            "nameCH" : "Monticello",
            "code" : ""
        },
        {
            "codeUIC" : 860841,
            "codeCH" : 0,
            "nameUIC" : "L'Île-Rousse",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 868141,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 345",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 868679,
            "codeCH" : 0,
            "nameUIC" : "Sémaphore 352",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 879197,
            "codeCH" : 0,
            "nameUIC" : "Tunnel des Redoutes",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 888974,
            "codeCH" : 0,
            "nameUIC" : "ITE Camp-Militaire-Carpiagne",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 894410,
            "codeCH" : 0,
            "nameUIC" : "PN 51",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 910497,
            "codeCH" : 0,
            "nameUIC" : "PN 136",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 929273,
            "codeCH" : 0,
            "nameUIC" : "Aig Malebarge Km 19,6",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 929281,
            "codeCH" : "FR",
            "nameUIC" : "Lavalduc",
            "nameCH" : "Faisceau Réception",
            "code" : ""
        },
        {
            "codeUIC" : 929281,
            "codeCH" : "NS",
            "nameUIC" : "Lavalduc",
            "nameCH" : "Bifurcation Km 0,8 Sud",
            "code" : ""
        },
        {
            "codeUIC" : 929281,
            "codeCH" : "PL",
            "nameUIC" : "Lavalduc",
            "nameCH" : "Bifurcation RN Poste",
            "code" : ""
        },
        {
            "codeUIC" : 929281,
            "codeCH" : "SN",
            "nameUIC" : "Lavalduc",
            "nameCH" : "Bifurcation Km 826 Sud",
            "code" : ""
        },
        {
            "codeUIC" : 929281,
            "codeCH" : "VB",
            "nameUIC" : "Lavalduc",
            "nameCH" : "Bifurcation Km 823,3 V2B",
            "code" : ""
        },
        {
            "codeUIC" : 981902,
            "codeCH" : 0,
            "nameUIC" : "Avignon-TGV-Courtine",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 988709,
            "codeCH" : 0,
            "nameUIC" : "Remise-à-Jorelle",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 988717,
            "codeCH" : 0,
            "nameUIC" : "Lycée-Henri-Sellier",
            "nameCH" : "",
            "code" : ""
        },
        {
            "codeUIC" : 988725,
            "codeCH" : 0,
            "nameUIC" : "Rougemont-Chanteloup",
            "nameCH" : "",
            "code" : ""
        }
    ]
        }
    }
}