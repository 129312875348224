<template>
	<div>
		<b-card-header>
			{{ $t('order-details') }}
		</b-card-header>
		<b-card-body>
			<AddRoute :order="orderHandle" />
			<div v-for="(road, index) in order.transportRoutes" :key="index">
				<h3 class="mt-4">
					{{ $t('road') }} {{ order.transportRoutes[index].name }}
				</h3>
				<div class="d-flex">
					<ul id="list-transport-plan">
						<li v-for="(service, i) in order.transportRoutes[index].services" :key="i">
							<Segment v-if="service.type == 'segment'" :segment="service.segment" :id="service.serviceId"
								@set-selected="e => {setSelected(service.serviceId, e)}" :selected="selected" />
							<Handling v-else :handling="service.type" :id="service.serviceId"
								:is-selected="selected.id == service.serviceId"
								@set-selected="e => {setSelected(service.serviceId, e)}" />
						</li>
					</ul>
					<Information v-if="selected.id && selected.type"
						:selected="selected" :actors="actors" :current-service="findServicesById(selected.id,
							order.transportRoutes[index].services)"
						:current-location="findLocationByIdService(selected.id,
							order.transportRoutes[index].services)"
						:segment="findSegmentByService(selected.id,
							order.transportRoutes[index])" />
				</div>
			</div>
		</b-card-body>
	</div>
</template>
<script>

import { AddRoute, Segment, Handling, Information } from './form/';

export default {
	components : {
		AddRoute,
		Segment,
		Handling,
		Information
	},
	props : {
		order : {
			type     : Object,
			required : true
		},
		orderHandle : {
			type     : Object,
			required : true
		},
		locations : {
			type     : Array,
			required : true
		},
		actors : {
			type     : Array,
			required : true
		},
		isOwner : {
			type     : Boolean,
			required : true,
		}
	},
	data() {
		return {
			selected : {}
		};
	},
	methods : {
		change() {
			this.$emit('tr-change', this.order.transportRoutes);
		},
		setSelected(id, type) {
			if(type) {
				this.selected = {
					id   : id,
					type : type
				};
			} else
				this.selected = {};
		},
		findServicesById(id, services) {
			return services.find((x) => x.serviceId == id);
		},
		findLocationByIdService(id, services) {
			const service = services.find((x) => x.serviceId == id);
			if(service.segment) {
				const location = service.segment[this.selected.type];
				if(location) {
					return this.locations.find((x) => x.name == location.name);
				}
			}
		},
		findSegmentByService(id, route) {
			const service = route.services.find((x) => x.serviceId == id);
			if(service.segment) {
				const segment = route.segments.find((x) => x._id == service.segment._id);
				return segment;
			}
		}
	}

};
</script>
<i18n>
{
	"fr": {
        "order-details": "Détails de la commande"
	},
	"en": {
        "order-details": "Order details"

	}
}
</i18n>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#list-transport-plan {
	margin-left: 24px;
}
#list-transport-plan /deep/div:not(.typology) > .multiple-select,
#list-transport-plan /deep/div:not(.typology) > .container-info {
	&:after {
		display: flex;
		content: '';
		background-color: var(--thirdary) !important;
		border-radius: 50%;
		position: absolute;
		left: -24px;
		top: 50%;
		margin-top: -8px;
		width: 18px;
		height: 18px;
		border: 3px solid $white;
	}
}
/deep/ .origin .container-info {
	&:before {
		display: flex;
		content: '';
		border-radius: $border-radius;
		background-color: var(--thirdary);
		position: absolute;
		left: -20px;
		width: 10px;
		height: 100px;
		top: 18px;
	}
}
/deep/.container-info {
	border: 1px solid #ced4da;
	justify-content: space-between;
	border-radius: $border-radius;
	height: 35px;
	text-transform: initial;
	cursor: pointer;
	align-items: center;
	width: 245px;
	display: flex;
	position: relative;
	font-weight: 600;
	color: var(--primary);
	margin-bottom: 1rem;
	padding: 0.375rem 0.375rem 0.375rem 0.75rem;
}
/deep/.container-info.selected {
	background-color: var(--thirdary);
	color: $white;
}
</style>