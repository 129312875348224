<template>
	<div id="state-incidents">
		<Header />
		<b-card-body class="rounded" v-if="!loading">
			<b-row>
				<b-col lg="6" md="12">
					<div class="group-inline">
						<label>{{ $t('type') }}</label>
						<b-form-select class="content" v-model="type" :options="typesOfIncident">
							<template v-slot:first>
								<b-form-select-option :value="null" disabled>
									_
								</b-form-select-option>
							</template>
						</b-form-select>
					</div>
				</b-col>
				<b-col lg="6" md="12">
					<div class="group-inline">
						<label>{{ $t('title') }}</label>
						<b-input type="text" placeholder="_" class="content" v-model="incident.title" />
					</div>
				</b-col>
				<b-col lg="6" md="12" v-if="type && type != 'other'">
					<div class="group-block">
						<label>{{ $t(type + 's') }}</label>
						<MultipleSelect class="content" v-model="event[type + 's']"
							:searchable="true" track="_id"
							:multiple="true" :options="operation.segment[type + 's']" />
					</div>
				</b-col>
				<b-col lg="6" md="12">
					<div class="group-block">
						<label>{{ $t('description') }}</label>
						<b-form-textarea class="content" v-model="event.comment" placeholder="_"
							no-resize max-rows="8" />
					</div>
				</b-col>
				<!-- 	<b-col lg="12" md="12">
					<div class="group-inline">
						<label for="file">{{ $t('picture') }}</label>
						<b-form-file id="file" :placeholder="$t('join-a-file')" class="content" />
					</div>
				</b-col> -->
				<b-col md="6" sm="12">
					<b-form-group :label="$t('date')">
						<b-form-input type="date" v-model="event.date" placeholder="_" />
					</b-form-group>
				</b-col>
				<!-- <b-col md="6" sm="12">
					<b-form-group :label="$t('hours')">
						<b-form-input type="time" v-model="event.time" placeholder="_" />
					</b-form-group>
				</b-col> -->
			</b-row>
		</b-card-body>
		<b-spinner variant="primary" class="loader" v-else />
		<Footer previous-step-path="/operation/state">
			<b-button variant="primary" @click="addIncident" :class="getClass">
				<span v-if="this.query && this.query.index">{{ $t('edit') }}</span>
				<span v-else>{{ $t('report') }}</span>
			</b-button>
		</Footer>
	</div>
</template>
<script>
import { Header, Footer } from './components/';
export default {
	data() {
		return {
			incident        : {
				title       : '',
				events      : [],
				status      : 'test',
				description : 'test',
				closingUser : 'test',
				reporter    : 'test',
			},
			type        : null,
			event       : {
				comment     : '',
				user        : null,
				wagons      : [],
				segments    : [],
				orders      : [],
				action      : 'test',
				date        : this.toDateInput(new Date()),
			},
			query   : this.$route.query,
			loading : false,
		};
	},
	components : {
		Header,
		Footer
	},
	created() {
		const query = this.$route.query;
		if(query && query.index) {
			this.incident = this.$store.getters['operation/get'].incidents[query.index];
		}
	},
	computed : {
		operation() {
			return this.$store.getters['operation/get'];
		},
		userIsDriver() {
			return JSON.parse(this.$store.getters['login/user']).scope.includes('driver');
		},
		userIsHandler() {
			return JSON.parse(this.$store.getters['login/user']).scope.includes('handler');
		},
		typesOfIncident() {
			return [
				{
					value : 'wagon',
					text  : this._i18n.t('wagon')
				},
				{
					value : 'order',
					text  : this._i18n.t('order')
				},
				{
					value : 'segment',
					text  : this._i18n.t('segment')
				},
				{
					value : 'other',
					text  : this._i18n.t('other')
				},
			];
		},
		getClass() {
			const incident = this.incident;
			if(!this.type || !incident.title || !this.event[this.type + 's'] || !incident.description) {
				return 'disabled';
			}
			return '';
		},
	},
	methods : {
		addIncident() {
			const query = this.$route.query;
			if(query && query.index) {
				this.$store.commit('operation/editIncident', this.incident, query.index);
				this.$router.push('/operation/state');
			} else {
				this.loading = true;
				this.event.user = this.$_clone(this.user._id);
				Object.keys(this.event).forEach(e => {
					var value = this.event[e];
					if(Array.isArray(value)) {
						if(!value.length) {
							delete this.event[e];
						}
						this.event[e] = value.map(x => {
							return x._id;
						});
					}
				});
				this.incident.segments = [];
				this.incident.segments[0] = { _id : this.operation.segment._id };
				this.incident.events.push(this.event);
				this.$store.dispatch('incidents/create', this.incident).then(() => {
					this.loading = false;
					this.$router.push('/operation/state');
				}).catch(err => {
					this.loading = false;
					this.$_makeToast('danger', this._i18n.t(err), this._i18n.t('error'));
				});
			}
		}
	}
};
</script>