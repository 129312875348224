import { Login, Register, SendMailConfirmation, MailConfirmation } from '@/views/guest/';
export const guest =
[
	{
		path       : '/login',
		name       : 'login',
		component  : Login,
		meta       : {
			permission : ['guest']
		}
	},
	{
		path       : '/register/:step',
		name       : 'register',
		component  : Register,
		meta       : {
			permission : ['guest']
		}
	},
	{
		path       : '/mail-confirmation/:key',
		name       : 'MailConfirmation',
		component  : MailConfirmation,
		meta       : {
			permission : ['guest']
		}
	},
	{
		path       : '/send-mail-confirmation',
		name       : 'SendMailConfirmation',
		component  : SendMailConfirmation,
		meta       : {
			permission : ['guest']
		}
	},
];
