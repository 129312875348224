<template>
	<b-modal :id="'modal-schedules-' + id" centered :title="title" size="md">
		<template #modal-header-close="">
			<Icon icon="cross" />
		</template>
		<b-row>
			<b-col md="6">
				<legend>{{ $t('select-days') }}</legend>
				<div class="technical-informations light w-100">
					<ul>
						<li v-for="(day, indexSchedules) in days" :key="indexSchedules">
							<span>
								<b-form-checkbox v-model="day.active" :id="'checkboxday' + indexSchedules">
									{{ getLabelOfDay(day.dayOfWeek) }}
								</b-form-checkbox>
							</span>

							<span v-if="findSchedulesByDay(day.dayOfWeek)">
								<span v-for="(daytime, inde) in findSchedulesByDay(day.dayOfWeek).times" :key="inde">
									{{ daytime.departureTime.slice(0, -3) }} -
									{{ daytime.arrivalTime.slice(0, -3) }}
									<div v-if="daytime.journeyTime > 0" class="ml-1">
										(J+{{ daytime.journeyTime }})
									</div>
								</span>
							</span>
							<span v-else />
						</li>
					</ul>
				</div>
			</b-col>
			<b-col md="6" class="border-left-grey p-3">
				<legend>{{ $t('assign-schedules') }}</legend>
				<b-form class="child-mt-2">
					<div>
						<b-form-group :label="$t('departure')" label-cols="3" content-cols="7">
							<b-form-timepicker v-model="time.departureTime" :placeholder="$t('choose-a-time')"
								:disabled="!daysSelected.length" />
						</b-form-group>
						<div class="d-flex align-items-baseline">
							<legend for="arrivalTime" class="col-3 pl-0">
								{{ $t('arrival') }}
							</legend>
							<b-form-timepicker id="arrivalTime" v-model="time.arrivalTime"
								:placeholder="$t('choose-a-time')" :disabled="!daysSelected.length" />
							<label for="journeyTime" class="mx-2" id="label-journey-time">
								J+
							</label>
							<b-form-input type="number" id="journeyTime" v-model="time.journeyTime"
								:disabled="!daysSelected.length" />
						</div>
						<b-form-group :label="$t('sillon-code')" v-if="typology === 'RAIL'">
							<b-form-input placeholder="_" v-model="time.sillonCode"
								:disabled="!daysSelected.length" />
						</b-form-group>
					</div>
					<div class="d-flex justify-content-end">
						<b-button variant="primary" :disabled="!daysSelected.length" @click="submit"
							id="validhours">
							{{ $t('valid') }}
						</b-button>
					</div>
				</b-form>
			</b-col>
		</b-row>
		<template #modal-footer="{ cancel }">
			<b-button variant="info" @click="cancel()">
				{{ $t('cancel') }}
			</b-button>
			<b-button variant="primary" @click="save()" id="validschedules">
				{{ $t('save') }}
			</b-button>
		</template>
	</b-modal>
</template>
<script>
import DateMixins from '@/mixins/date.js';
export default {
	mixins : [DateMixins],
	props  : {
		schedules : {
			type     : Array,
			required : true,
		},
		id : {
			type     : String,
			required : true,
		},
		title : {
			type     : String,
			required : true
		},
		typology : {
			type     : String,
			required : false,
			default() {
				return '';
			}
		}

	},
	data() {
		return {
			time : {
				departureTime : '08:00:00',
				arrivalTime   : '18:00:00',
				journeyTime   : 0,
				sillonCode    : null
			},
			daysSelected : [],
			days         : [
				{
					dayOfWeek : 1,
					times         : null,
					active       : false,

				}
				,
				{
					dayOfWeek : 2,
					times         : null,
					active       : false,
				},
				{
					dayOfWeek : 3,
					times         : null,
					active       : false,
				},
				{
					dayOfWeek : 4,
					times         : null,
					active       : false,
				},
				{
					dayOfWeek : 5,
					times         : null,
					active       : false,
				},
				{
					dayOfWeek : 6,
					times         : null,
					active       : false,
				},
				{
					dayOfWeek : 0,
					times         : null,
					active       : false,
				},
			],
			times : []
		};
	},
	methods : {
		submit() {
			const t = this;
			for (let i = 0; i < t.daysSelected.length; i++) {
				const day = t.daysSelected[i];
				day.times = [ { ...this.time }];
				const find = this.times.findIndex(d=>d.dayOfWeek === day.dayOfWeek);
				if (find != -1) {
					this.times[find].times = day.times;
				} else {
					this.times.push({
						dayOfWeek : day.dayOfWeek,
						times         : day.times
					});
				}
			}
			for (let i = 0; i < t.days.length; i++) {
				const day = t.days[i];

				const find = this.times.findIndex(d=>d.dayOfWeek === day.dayOfWeek && day.active);
				if (find != -1) {
					day.times = [{ ...this.time }];
				}
			}
			this.reset();
			this.daysSelected = [];
		},
		findSchedulesByDay(dayOfWeek) {
			const find = this.times.find((x) => x.dayOfWeek == dayOfWeek);
			return find;
		},
		reset() {
			const t = this;
			t.time =  {
				departureTime : '08:00:00',
				arrivalTime   : '18:00:00',
				journeyTime   : 0
			};
			for (let a = 0; a < t.days.length; a++) {
				const day = t.days[a];
				if(day.active) day.active = false;
			}
		},
		save() {
			this.$emit('set-schedules', this.$_clone(this.times));
			this.$bvModal.hide('modal-schedules-' + this.id);
		}
	},
	mounted() {
		this.$root.$on('bv::modal::show', () => {
			if(this.schedules.length) {
				this.times = this.$_clone(this.schedules);
			}
		});
	},
	watch : {
		days : {
			handler(val) {
				for (let i = 0; i < val.length; i++) {
					const day = { ...val[i] };
					const index = this.daysSelected.findIndex((x) => x.dayOfWeek == day.dayOfWeek);
					if(day.active && index == -1) {
						this.daysSelected.push(day);
					}
					if(index != -1 && !day.active) {
						this.daysSelected.splice(index, 1);
					}
				}
			},
			deep : true
		},
		daysSelected(val) {
			if(val.length && val[0].times) {
				this.time = this.$_clone(val[0].times);
			} else {
				this.time =  {
					departureTime : '08:00:00',
					arrivalTime   : '18:00:00',
					journeyTime   : 0
				};
			}
		}
	},

};
</script>
<i18n>
{
    "fr": {
        "select-days": "Sélectionner jour(s)",
        "assign-schedules": "Assigner les horaires",
        "open-24-24": "Ouvert 24h/24"
    },
    "en": {
        "select-days": "Select day(s)",
        "assign-schedules": "Assign schedules",
        "open-24-24": "Open 24h/24"
    }
}
</i18n>
<style lang="scss" scoped>
#arrivalTime__outer_ {
	width: 100px;
}
#journeyTime {
	width: 65px;
}
#label-journey-time {
	font-weight: 600;
}
</style>