<template>
	<div>
		<b-button variant="primary" v-b-toggle="'segments'" class="btn-collapse">
			{{ $t('segments') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="segments" accordion="accordion">
			<b-card>
				<div class="list-header border-radius-top">
					<ul>
						<li>{{ $t('segment-id') }}</li>
						<li>{{ $t('origine') }}</li>
						<li />
						<li>{{ $t('destination') }}</li>
						<li v-if="$route.name != 'VisualizationContracts'">
							{{ $t('validation') }}
						</li>
						<li v-else />
					</ul>
				</div>
				<div class="list mt-0">
					<div class="container-item" v-for="(segment, index) in getUserSegments" :key="index">
						<div class="item" v-b-toggle="'item-details-' + index">
							<Informations :segment="segment.segment">
								<div class="d-flex flex-column text-initial px-4"
									v-if="$route.name != 'VisualizationContracts'">
									<div class="d-flex align-items-center" v-if="isOwner">
										<legend class="mb-0 mr-2">
											{{ $t('charger') }}
										</legend>

										<b-form-checkbox :disabled="!isOwner" v-model="segment.validatedCharger"
											class="success" switch size="lg" :id="'checkboxCharger'+index" />
									</div>
									<div class="d-flex align-items-center"
										v-if="$route.name == 'EditContracts'">
										<legend class="mb-0 mr-2">
											{{ $t('provider') }}
										</legend>
										<b-form-checkbox :disabled="isOwner" v-model="segment.validatedOperator"
											class="success" switch size="lg" :id="'checkboxProvider'+index" />
									</div>
								</div>
							</Informations>

							<HorizontalVisualization :segments="new Array(segment.segment)" class="mt-3" />
							<div class="toggle-details">
								<Icon icon="chevron-down" />
							</div>
						</div>
						<b-collapse :id="'item-details-' + index">
							<div class="p-5 d-flex justify-content-center">
								<div class="technical-informations">
									<h3>
										{{ $t('technicals-informations') }}
									</h3>
									<ul>
										<li v-if="segment.segment.volumetry">
											<span>{{ $t('number-of-transport-units') }}</span>
											<span>{{ segment.segment.volumetry.units }}</span>
										</li>
										<li v-if="segment.segment.volumetry">
											<span>{{ $t('type-of-transport-units') }}</span>
											<span>{{ $t(segment.segment.volumetry.transportUnit) }}</span>
										</li>
										<li v-if="segment.segment.volumetry">
											<span>{{ $t('number-of-transport-units') }}</span>
											<span>{{ segment.segment.volumetry.periodicity }}</span>
										</li>
										<li v-if="segment.segment.technicalConstraint">
											<span>{{ $t('number-of-km') }}</span>
											<span>{{ segment.segment.technicalConstraint.numberKm }}</span>
										</li>
										<li v-if="segment.segment.operationalConstraint">
											<span>{{ $t('number-evp-max') }}</span>
											<span>{{ segment.segment.operationalConstraint.maxEvp }}</span>
										</li>
										<li v-if="segment.segment.technicalConstraint">
											<span>{{ $t('maximum-speed') }}</span>
											<span>{{ segment.segment.technicalConstraint.maxSpeed }}</span>
										</li>
										<li v-if="segment.segment.operationalConstraint">
											<span>{{ $t('max-length') }}</span>
											<span>{{ segment.segment.operationalConstraint.maxLength }}</span>
										</li>
										<li v-if="segment.segment.operationalConstraint">
											<span>{{ $t('max-tonnage') }}</span>
											<span>{{ segment.segment.operationalConstraint.maxWeight }}</span>
										</li>
										<li v-if="segment.segment.technicalConstraint">
											<span>{{ $t('dangerous-goods') }}</span>
											<span>{{ segment.segment.technicalConstraint.allowDangerous }}</span>
										</li>
									</ul>
								</div>
							</div>
						</b-collapse>
					</div>
				</div>
			</b-card>
		</b-collapse>
	</div>
</template>
<script>
import Informations from '@/components/segments/Informations.vue';
import HorizontalVisualization from '@/components/segments/HorizontalVisualization.vue';
export default {
	props : {
		getUserSegments : {
			type     : Array,
			required : true,
		},
		isOwner : {
			type     : Boolean,
			required : false
		}
	},
	components : {
		Informations,
		HorizontalVisualization
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ .visualization.horizontal #last-destination {
	left: 100%;
}
</style>