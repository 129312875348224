export default {
	methods : {
		profileTypes(companyType) {
			switch (companyType) {
			case 'SHIPPER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'logisticien',
						text  : this._i18n.t('logisticien')
					}
				];
			case 'FREIGHT_FORWARDER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'agent',
						text  : this._i18n.t('agent')
					}
				];
			case 'RAIL_CARRIER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'driver',
						text  : this._i18n.t('driver')
					},
					{
						value : 'groundAgent',
						text  : this._i18n.t('groundAgent')
					}
				];
			case 'ROAD_CARRIER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'driver',
						text  : this._i18n.t('driver')
					}
				];
			case 'WATERWAY_CARRIER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'driver',
						text  : this._i18n.t('driver')
					},
					{
						value : 'waterway_member',
						text  : this._i18n.t('waterway_member')
					}
				];
			case 'MARITIME_CARRIER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'driver',
						text  : this._i18n.t('driver')
					},
					{
						value : 'maritime_member',
						text  : this._i18n.t('maritime_member')
					}
				];
			case 'AIR_CARRIER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					}
				];
			case 'CARRIER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'agent',
						text  : this._i18n.t('agent')
					}
				];
			case 'HANDLER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'handler',
						text  : this._i18n.t('handler')
					}
				];
			case 'RAIL_HANDLER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'handler',
						text  : this._i18n.t('handler')
					}
				];
			case 'RENTER' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'renter',
						text  : this._i18n.t('renter')
					}
				];
			case 'MAINTENANCE' :
				return [
					{
						value : 'admin',
						text  : this._i18n.t('admin')
					},
					{
						value : 'mantainer',
						text  : this._i18n.t('mantainer')
					}
				];
			}
		}
	},
};