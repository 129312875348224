<template>
	<div id="collaborators-fields">
		<b-form-group :label="$t('name')">
			<b-form-input placeholder="_" v-model="collaborator.lastname.$model"
				:state="$_validateState(collaborator.lastname)" name="lastname" :disabled="type === 'view'" />
		</b-form-group>
		<b-form-group :label="$t('firstname')">
			<b-form-input placeholder="_" v-model="collaborator.firstname.$model"
				:state="$_validateState(collaborator.firstname)" name="firstname" :disabled="type === 'view'" />
		</b-form-group>
		<b-form-group :label="$t('email')">
			<b-form-input type="email" placeholder="_" v-model="collaborator.email.$model"
				:state="$_validateState(collaborator.email)" name="email" :disabled="type === 'view'" />
		</b-form-group>
		<b-form-group :label="$t('email-confirmation')" v-if="type === 'create'">
			<b-form-input type="email" placeholder="_" v-model="collaborator.confirmEmail.$model"
				:state="$_validateState(collaborator.confirmEmail)" name="emailconfirm" :disabled="type === 'view'" />
		</b-form-group>
		<b-form-group :label="$t('password')" v-if="type === 'create'">
			<b-input placeholder="_" type="password" v-model="collaborator.password.$model"
				:state="$_validateState(collaborator.password)" name="password" autocomplete="new-password" />
			<b-form-invalid-feedback>{{ $t('password-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('password-confirmation')" v-if="type === 'create'">
			<b-input placeholder="_" type="password" v-model="collaborator.repassword.$model"
				:state="$_validateState(collaborator.repassword)" name="passwordconfirm"
				autocomplete="new-password" :disabled="type === 'view'" />
			<b-form-invalid-feedback>{{ $t('confirm-password-err') }}</b-form-invalid-feedback>
		</b-form-group>
		<b-form-group :label="$t('job')">
			<b-form-select :options="profiles" v-model="collaborator.scope.$model"
				:state="$_validateState(collaborator.scope)" name="scope"
				:disabled="type === 'view'">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
			<b-form-invalid-feedback>{{ $t('field-required-err') }}</b-form-invalid-feedback>
		</b-form-group>


		<b-form-group :label="$t('language')">
			<b-form-select v-model="collaborator.language.$model" :options="languages"
				:state="$_validateState(collaborator.language)" name="language" :disabled="type === 'view'">
				<template v-slot:first>
					<b-form-select-option :value="null" disabled>
						_
					</b-form-select-option>
				</template>
			</b-form-select>
		</b-form-group>


		<b-form-group :label="$t('phone-number')">
			<b-form-input placeholder="_" v-model="collaborator.phone.$model"
				:state="$_validateState(collaborator.phone)" name="phonenumber"
				:disabled="type === 'view'" />
		</b-form-group>

		<b-form-group :label="$t('registration')">
			<b-form-input placeholder="_" :disabled="type === 'view'" name="registration" />
		</b-form-group>

		<b-form-group :label="$t('street')">
			<b-form-input placeholder="_" v-model="collaborator.address.street.$model"
				:disabled="type === 'view'" name="street" />
		</b-form-group>
		<b-form-group :label="$t('postal-code')">
			<b-form-input placeholder="_" v-model="collaborator.address.postalCode.$model"
				:disabled="type === 'view'" name="postalCode" />
		</b-form-group>
		<b-form-group :label="$t('city')">
			<b-form-input placeholder="_" v-model="collaborator.address.city.$model"
				:disabled="type === 'view'" name="city" />
		</b-form-group>

		<b-form-group :label="$t('department-or-region')">
			<b-form-input placeholder="_" v-model="collaborator.address.state.$model"
				:disabled="type === 'view'" name="state" />
		</b-form-group>
		<b-form-group :label="$t('complement-address')">
			<b-form-input placeholder="_" v-model="collaborator.address.lieu.$model"
				:disabled="type === 'view'" name="lieu" />
		</b-form-group>


		<b-form-group>
			<MultipleSelect :options="countries" track="name" :validation="collaborator.address.country"
				:label="this._i18n.t('country')" :searchable="true" v-model="collaborator.address.country.$model"
				:disabled="type === 'view'" name="country" />
		</b-form-group>
	</div>
</template>

<script>
import countriesMixins from '@/mixins/countries.js';
import languagesMixins from '@/mixins/languages.js';
import profilsMixins  from '@/mixins/profiles.js';

export default {
	name       : 'CollaboratorsFields',
	mixins     : [countriesMixins, languagesMixins, profilsMixins],
	props      : {
		collaborator : {
			type     : Object,
			required : false,
			default() {
				return {
					address : {
						country : 'fr',
					},
				};
			}
		},
		type : {
			type     : String,
			required : true,
			default() {
				return 'create';
			}
		}
	},
	computed : {
		profiles() {
			return this.profileTypes(this.user.company.type);
		}

	}

};

</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
#collaborators-fields {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.form-group {
	margin: 0 15px;
	width: calc(50% - 30px);
}
</style>