<template>
	<b-card no-body>
		<b-card-header>{{ $t('roads') }}</b-card-header>
		<b-card-body>
			<b-form-select :value="null">
				<template v-slot:first>
					<b-form-select-option :value="null">
						{{ $t('january') }}
					</b-form-select-option>
				</template>
			</b-form-select>
			<div class="percentage-circle primary mt-">
				<h2>
					80%
				</h2>
				<h6 class="text-initial text-center mb-0">
					{{ $t('total-units-transport') }}
				</h6>
			</div>
			<div class="w-90 d-flex flex-column align-self-start">
				<div class="bar-on-bar-container" v-for="(road, index) in roads.slice(0, limit)" :key="index">
					<p style="color: #0354c1;">
						{{ road.label }}
					</p>
					<BarOnBar :road="road" :style="getStyle(road)" />
					<p>
						{{ $t('on') }} {{ road.unitsPlanned }} {{ $t('units-planned') }}
					</p>
				</div>
			</div>
			<b-button variant="primary" class="mt-3" @click="limit*=2">
				{{ $t('see-more-orders') }}
			</b-button>
		</b-card-body>
	</b-card>
</template>
<script>
import BarOnBar from './components/BarOnBar';
import * as data from '@/utils/fakeData/ERAMET/mockDashboard.json';
const RoadsMocker = data.default;
export default {
	components : {
		BarOnBar,
	},
	data() {
		return {
			limit                  : 4,
			roads                  : RoadsMocker,
			highestRoadUnitPlanned : ''
		};
	},
	created() {
		const roadsSorted = [... this.roads].sort((a, b) => (a.unitsPlanned > b.unitsPlanned) ? 1 : -1);
		this.highestRoadUnitPlanned = roadsSorted[roadsSorted.length - 1].unitsPlanned;
	},
	methods : {
		getStyle(road) {
			const width = (road.unitsPlanned / this.highestRoadUnitPlanned).toFixed(2) * 100;
			return 'width: ' + width + '%;';
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.bar-on-bar-container {
	width: 100%;
	font-weight: 600;
	display: flex;
	text-transform: initial;
	flex-direction: column;
	margin-bottom: 0.5rem;
}
.bar-on-bar-container p {
	color: var(--secondary);
	margin: 0.25rem 0;
}
</style>
<i18n>
{
    "fr": {
        "total-units-transport": "% total d'unités transportées sur l'ensemble des routes",
        "units-planned": "unités planifiées"
    },
    "en": {
        "total-units-transport": "% total units transported on all routes",
        "units-planned": "planned units"
    }
}
</i18n>