<template>
	<b-card no-body>
		<b-card-header class="d-flex justify-content-between align-items-center">
			<div>
				{{ $t('handling') }}
			</div>
			<div class="d-flex text-initial" v-if="$route.name != 'VisualizationContracts'">
				<div class="d-flex align-items-center" v-if="isOwner">
					<legend class="mb-0 mr-2">
						{{ $t('charger') }}
					</legend>
					<b-form-checkbox v-model="segmentsValidation.validatedCharger" class="success"
						switch size="lg" />
				</div>
				<div class="d-flex  align-items-center" v-if="$route.name == 'EditContracts'">
					<legend class="mb-0 ml-2 mr-2">
						{{ $t('provider') }}
					</legend>
					<b-form-checkbox :disabled="isOwner" v-model="segmentsValidation.validatedOperator" class="success"
						switch size="lg" />
				</div>
			</div>
		</b-card-header>
		<b-card-body>
			<ul>
				<li v-for="(manut, index) in getUserManut" :key="index">
					<div>
						{{ $t(manut.type) }} :
						<span v-if="manut.operationTime">{{ manut.operationTime.slice(0,2) }} h
							{{ manut.operationTime.slice(3,5) }} mn </span>
					</div>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	props : {
		getUserManut : {
			type     : Array,
			required : true,
		},
		isOwner : {
			type     : Boolean,
			required : true,
		}
	},
	data() {
		return {
			segmentsValidation : {
				validatedCharger   : false,
				validatedOperator : false,
			}
		};
	},
	created() {
		if(this.getUserManut.length) {
			if(this.getUserManut.every((x) => x.validatedCharger == true)) {
				this.segmentsValidation.validatedCharger = true;
			}
			if(this.getUserManut.every((x) => x.validatedOperator == true)) {
				this.segmentsValidation.validatedOperator = true;
			}
		}
	},
	watch : {
		segmentsValidation : {
			handler(val) {
				if(this.isOwner) {
					if(val.validatedCharger) {
						for (let i = 0; i < this.getUserManut.length; i++) {
							const segment = this.getUserManut[i];
							segment.validatedCharger = true;
						}
					} else {
						for (let i = 0; i < this.getUserManut.length; i++) {
							const segment = this.getUserManut[i];
							segment.validatedCharger = false;
						}
					}
				} else {
					if(val.validatedOperator) {
						for (let i = 0; i < this.getUserManut.length; i++) {
							const segment = this.getUserManut[i];
							segment.validatedOperator = true;
						}
					} else {
						for (let i = 0; i < this.getUserManut.length; i++) {
							const segment = this.getUserManut[i];
							segment.validatedOperator = false;
						}
					}
				}
			},
			deep : true
		},
		getUserManut : {
			handler(val) {
				if(this.isOwner) {
					for (let i = 0; i < val.length; i++) {
						const segment = val[i];
						if(!segment.validatedCharger) {
							this.segmentsValidation.validatedCharger = false;
							return;
						}
					}
					this.segmentsValidation.validatedCharger = true;
				} else {
					for (let i = 0; i < val.length; i++) {
						const segment = val[i];
						if(!segment.validatedOperator) {
							this.segmentsValidation.validatedOperator = false;
							return;
						}
					}
					this.segmentsValidation.validatedOperator = true;
				}
			},
			deep : true
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
ul {
	display: flex;
	flex-direction: column;
	font-weight: 600;
}
ul li {
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 0;
	border-bottom: 2px solid $light-grey;
}
ul li > div {
	display: flex;
	width: 50%;
	align-items: center;
}
ul li .destination {
	justify-content: flex-end;
}
</style>