<template>
	<b-row>
		<b-col lg="6">
			<b-row>
				<b-col lg="6">
					<b-form-group :label="$t('longitude')">
						<b-form-input type="number" placeholder="_" v-model="longitude" />
					</b-form-group>
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('latitude')">
						<b-form-input type="number" placeholder="_" v-model="latitude" />
					</b-form-group>
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('address')">
						<b-form-input placeholder="_" v-model="roadInformation.address.street" />
					</b-form-group>
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('city')">
						<b-form-input placeholder="_" v-model="roadInformation.address.city" />
					</b-form-group>
				</b-col>
				<b-col lg="12">
					<b-form-checkbox v-model="sameAddress">
						{{ $t('resume-the-general-address') }}
					</b-form-checkbox>
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('number-of-docks')">
						<b-form-input placeholder="_" type="number" v-model="roadInformation.platforms" />
					</b-form-group>
				</b-col>
				<b-col lg="6">
					<b-form-group :label="$t('treatment-capacity')">
						<b-form-input placeholder="_" type="number" v-model="roadInformation.capacity" />
					</b-form-group>
				</b-col>
			</b-row>
		</b-col>
		<b-col lg="6" md="12">
			<Schedules :schedules="roadInformation.openingHours"
				:title="$t('opening-hours-days')" @set-schedules="setTimetable" id="road" />
			<b-form-checkbox value="true" class="mt-3" v-model="sameTimetable">
				{{ $t('resume-the-general-timetable') }}
			</b-form-checkbox>
		</b-col>
	</b-row>
</template>
<script>
import Schedules from '@/components/common/Schedules.vue';
export default {
	components : {
		Schedules
	},
	props : {
		information : {
			required : true,
			type     : Object,
		}
	},
	data() {
		return {
			sameTimetable : false,
			longitude     : null,
			latitude      : null,
			sameAddress   : false
		};
	},
	computed : {
		roadInformation() {
			return this.setRoadInformation();
		},
		getMainTimetable() {
			return this.information.openingHours;
		},
		getMainAddress() {
			return this.information.address;
		}
	},
	watch : {
		getMainTimetable(val) {
			if(this.sameTimetable) {
				this.roadInformation.openingHours = val;
			}
		},
		sameTimetable(val) {
			if(val) {
				this.roadInformation.openingHours = this.getMainTimetable;
			} else {
				this.roadInformation.openingHours = [];
			}
		},
		sameAddress(val) {
			if(val) {
				this.roadInformation.address = this.getMainAddress;
			} else {
				this.roadInformation.address = [];
			}
		},
		longitude(val) {
			if(val) {
				if(this.roadInformation && this.roadInformation.geoLocation) {
					if(this.roadInformation.geoLocation.coordinates
					&& this.roadInformation.geoLocation.coordinates.length > 0) {
						this.roadInformation.geoLocation.coordinates[0] = val;
					} else {
						this.roadInformation.geoLocation = { coordinates : [val, null] };
					}
				} else {
					this.roadInformation.geoLocation = { coordinates : [val, null] };
				}
			}
		},
		latitude(val) {
			if(val) {
				if(this.roadInformation && this.roadInformation.geoLocation) {
					if(this.roadInformation.geoLocation.coordinates
					&& this.roadInformation.geoLocation.coordinates.length > 1) {
						this.roadInformation.geoLocation.coordinates[1] = val;
					} else {
						this.roadInformation.geoLocation = { coordinates : [null, val] };
					}
				} else {
					this.roadInformation.geoLocation = { coordinates : [null, val] };
				}
			}
		},
		'roadInformation' : {
			handler(val) {
				const index = this.information.modals.findIndex(mod=> mod.typology === 'ROAD');
				if (index != -1) {
					this.information.modals[index] = val;
				} else {
					this.information.modals.push(val);
				}
			},
			deep : true
		},
	},
	methods : {
		setTimetable(timetable) {
			this.roadInformation.openingHours = timetable;
		},
		setRoadInformation() {
			if (this.information.modals && this.information.modals.length > 0) {
				const find =  this.information.modals.find(mod=> mod.typology === 'ROAD');
				if (find) {
					if (find.geoLocation && find.geoLocation.coordinates) {
						this.longitude = find.geoLocation.coordinates[0];
						this.latitude = find.geoLocation.coordinates[1];
					} else {
						this.longitude = null;
						this.latitude = null;
					}
					return find;
				}
			}
			this.information.modals.push({
				typology     : 'ROAD',
				openingHours : [],
				address      : {},
			});
			return this.information.modals[this.information.modals.length - 1];
		}
	}
};
</script>