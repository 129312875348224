export default {
	computed : {
		typesOfRoad() {
			return [
				{
					value : 'ROAD',
					text  : this._i18n.t('road'),
					icon  : 'truck'
				},
				{
					value : 'RAIL',
					text  : this._i18n.t('rail'),
					icon  : 'train'
				},
				{
					value : 'AIR',
					text  : this._i18n.t('airplane'),
					icon  : 'airplane'
				},
				{
					value : 'SEA',
					text  : this._i18n.t('maritime'),
					icon  : 'boat'
				},
				{
					value : 'RIVER',
					text  : this._i18n.t('river'),
					icon  : 'river'
				},
			];
		},
	}
};