<template>
	<div>
		<Identification :contract="contract" :get-user-segments="getUserSegments" :get-user-locations="getUserLocations"
			:is-owner="isOwner" :get-user-routes="getUserRoutes" :get-user-manut="getUserManut" />

		<Locations :locations="contract.locations" :is-owner="isOwner" id="location" />

		<Segments :get-user-segments="getUserSegments" :is-owner="isOwner" id="segments" />

		<TransportPlan :get-user-segments="getUserSegments" :get-user-locations="getUserLocations"
			:is-owner="isOwner" :get-user-routes="getUserRoutes" id="transportPlan" />

		<Pricing :rates="getUserRate" :segments="contract.services.filter(s=>s.type=='segment').map(s=>s.segment)"
			id="pricing" />
		<div class="d-flex justify-content-end mt-3 child-mx-2">
			<b-button variant="info" @click="previous">
				{{ $t('previous') }}
			</b-button>
			<b-button variant="primary" @click="save" class="btn-icon" id="save">
				{{ $t('save') }}
				<Icon icon="save" />
			</b-button>
			<b-button variant="primary" v-if="isOwner && (getAllValidation == 100)" @click="valid" class="btn-icon"
				id="valid">
				{{ $t('valid') }}
				<Icon icon="checkmark" />
			</b-button>
		</div>
		<Modal id="modal-validation" :title="$t('validation-contract')" @confirm="valid">
			<template v-slot:content>
				<p class="text-initial">
					{{ $t('confirm-valid-contract') }}
				</p>
			</template>
		</Modal>
	</div>
</template>
<script>
import { Identification, Locations, Segments, Pricing, TransportPlan } from '../visualization/';
import ValidationMixins from '../mixins/validation.js';
export default {
	props : {
		contract : {
			required : true,
			type     : Object,
		},
		isOwner : {
			required : true,
			type     : Boolean
		}
	},
	mixins : [
		ValidationMixins
	],
	components : {
		Identification,
		Locations,
		Segments,
		TransportPlan,
		Pricing
	},
	computed : {

	},
	methods : {
		previous() {
			if(this.isOwner) {
				this.$emit('go-to-step', 'pricing-condition');
			} else {
				this.$emit('go-to-step', 'calendar');
			}
		},
		save() {
			this.$emit('submit');
		},
		valid() {
			this.$emit('valid');
		},
	},
	watch : {
		getAllValidation(val) {
			this.$emit('set-validation-percentage', val);
		}
	}
};
</script>