<template>
	<div id="information" class="w-100">
		<Typology v-if="selected.type == 'typology'" :segment="currentService.segment" :actors="actors"
			:top="top" :typology="currentService.segment.typology" :id="selected.id" />
		<Handling v-else-if="selected.type == 'handling'" :top="top" :handling="currentService" :actors="actors" />
		<Station :top="top" v-else :actors="actors" :station="currentLocation"
			:typology="currentService.segment.typology" :segment="segment" />
	</div>
</template>
<script>
import { Station, Typology, Handling } from '../../../../contracts/create/transportPlan/form/informations/';
export default {
	props : {
		selected : {
			type     : Object,
			required : true
		},
		actors : {
			type     : Array,
			required : true
		},
		currentService : {
			type     : Object,
			required : true
		},
		currentLocation : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		},
		segment : {
			type     : Object,
			required : false,
			default() {
				return {};
			}
		}
	},
	components : {
		Station,
		Handling,
		Typology
	},
	data() {
		return {
			top : 0
		};
	},
	mounted() {
		this.setTop();
	},
	methods : {
		setTop() {
			if(this.selected.id) {
				var el = document.getElementById(this.selected.id);
				if(this.selected.type != 'handling') {
					el = el.getElementsByClassName(this.selected.type)[0];
				}
				const list = document.getElementById('list-transport-plan');
				this.top = el.getBoundingClientRect().top - list.getBoundingClientRect().top;
			}
		}
	},
	computed : {
		idAndType() {
			return `${this.selected.id}|${this.selected.type}`;
		}
	},
	watch : {
		idAndType() {
			this.setTop();
		}
	}
};
</script>
<style lang="scss" scoped>
/deep/.informations {
	width: calc(100% - 355px);
	max-width: 900px;
	margin-left: 20px;
	position: relative;
	&:after {
		content: '';
		display: flex;
		height: 2px;
		width: 22px;
		background-color: var(--primary);
		position: absolute;
		top: 20px;
		left: -22px;
	}
}
/deep/.technical-informations {
	width: 100%;
}
</style>