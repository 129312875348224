<template>
	<div class="segment" :id="id">
		<Origin :locations="locations" v-model="segment.origin" :destination="segment.destination"
			:previous-destination="previousDestination" @push-location="pushLocation"
			:is-selected="selected.id == id && selected.type == 'origin'" :loading-locations="loadingLocations"
			@set-selected="setSelected" />
		<Typology v-if="segment.destination.name" v-model="segment.typology"
			:is-selected="selected.id == id && selected.type == 'typology'"
			@set-selected="setSelected" />
		<Destination v-if="segment.origin.name" :locations="locations" v-model="segment.destination"
			:can-delete-destination="canDeleteDestination" @push-location="pushLocation"
			:is-selected="selected.id == id && selected.type == 'destination'"
			@set-selected="setSelected" :origin-name="segment.origin.name" />
	</div>
</template>
<script>
import { Origin, Destination, Typology } from './segment/';
export default {
	components : {
		Origin,
		Destination,
		Typology
	},
	props : {
		segment : {
			required : true,
			type     : Object,

		},
		locations : {
			required : true,
			type     : Array
		},
		canDeleteDestination : {
			required : true,
			type     : Boolean,
		},
		previousDestination : {
			required : true,
			type     : null
		},
		id : {
			required : true,
			type     : String
		},
		selected : {
			required : true,
			type     : null
		},
		loadingLocations : {
			required : true,
			type     : Boolean,
		}
	},
	methods : {
		setOrigin(origin) {
			this.segment.origin = origin;
		},
		setDestination(destination) {
			this.segment.destination = destination;
		},
		pushLocation(name, id) {
			const location = {
				name    : name,
				handler : {
					companyName : null
				},
				address        : {},
				modals         : [],
				openingHours   : [],
				locationIdtemp : id,
			};

			this.$emit('push-location', location);
		},
		setSelected(val) {
			this.$emit('set-selected', val);
		}
	},
	computed : {
		segmentDestination() {
			return this.segment.destination.name;
		}
	},
	watch : {
		segmentDestination(destination) {
			if(!destination) {
				this.segment.typology = null;
				this.$emit('spliceServicesAfterIndex');
			} else {
				this.$emit('add-services');
			}
		}
	},
};
</script>
<style lang="scss" scoped>
.segment {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 150px;
}
</style>