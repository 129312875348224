<template>
	<b-row>
		<b-col lg="6" md="12">
			<b-form class="child-mb-3">
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('longitude')">
							<b-form-input type="number" placeholder="_" v-model="longitude" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('latitude')">
							<b-form-input type="number" placeholder="_" v-model="latitude" />
						</b-form-group>
					</b-col>
				</b-row>
				<MultipleSelect :options="[]" :label="$t('port')" v-model="riverInformation.station" />
				<MultipleSelect :options="[]" :label="$t('platforms')" v-model="riverInformation.platforms" />
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('number-of-docks')">
							<b-form-input placeholder="_" v-model="riverInformation.platforms" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('platform-length')">
							<b-form-input placeholder="_" v-model="riverInformation.length" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('treatment-capacity')">
							<b-form-input placeholder="_" v-model="riverInformation.capacity" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</b-col>
		<b-col lg="6" md="12">
			<Schedules :schedules="riverInformation.openingHours"
				:title="$t('opening-hours-days')" @set-schedules="setSchedules" id="river" />
			<b-form-checkbox value="true" class="mt-3" v-model="sameSchedules">
				{{ $t('resume-the-general-timetable') }}
			</b-form-checkbox>
		</b-col>
	</b-row>
</template>
<script>
import Schedules from '@/components/common/Schedules.vue';
export default {
	props : {
		information : {
			required : true,
			type     : Object,
		}
	},
	components : {
		Schedules,
	},
	data() {
		return {
			sameSchedules : false,
			longitude     : null,
			latitude      : null
		};
	},
	computed : {
		riverInformation() {
			return this.setRiverInformation();
		},
		getMainSchedules() {
			return this.information.schedules;
		}
	},
	watch : {
		getMainSchedules(val) {
			if(this.sameSchedules) {
				this.riverInformation.openingHours = val;
			}
		},
		sameSchedules(val) {
			if(val) {
				this.riverInformation.openingHours = this.getMainSchedules;
			} else {
				this.riverInformation.openingHours = [];
			}
		},
		longitude(val) {
			if(val) {
				if(this.riverInformation && this.riverInformation.geoLocation) {
					if(this.riverInformation.geoLocation.coordinates
					&& this.riverInformation.geoLocation.coordinates.length > 0) {
						this.riverInformation.geoLocation.coordinates[0] = val;
					} else {
						this.riverInformation.geoLocation = { coordinates : [val, null] };
					}
				} else {
					this.riverInformation.geoLocation = { coordinates : [val, null] };
				}
			}
		},
		latitude(val) {
			if(val) {
				if(this.riverInformation && this.riverInformation.geoLocation) {
					if(this.riverInformation.geoLocation.coordinates
					&& this.riverInformation.geoLocation.coordinates.length > 1) {
						this.riverInformation.geoLocation.coordinates[1] = val;
					} else {
						this.riverInformation.geoLocation = { coordinates : [null, val] };
					}
				} else {
					this.riverInformation.geoLocation = { coordinates : [null, val] };
				}
			}
		},
		'riverInformation' : {
			handler(val) {
				const index = this.information.modals.findIndex(mod=> mod.typology === 'RIVER');
				if (index != -1) {
					this.information.modals[index] = val;
				} else {
					this.information.modals.push(val);
				}
			},
			deep : true
		},
	},
	methods : {
		setSchedules(schedules) {
			this.riverInformation.openingHours = schedules;
		},
		setRiverInformation() {
			if (this.information.modals && this.information.modals.length > 0) {
				const find =  this.information.modals.find(mod=> mod.typology === 'RIVER');
				if (find) {
					if (find.geoLocation && find.geoLocation.coordinates) {
						this.longitude = find.geoLocation.coordinates[0];
						this.latitude = find.geoLocation.coordinates[1];
					}
					return find;
				}
			}
			return {
				typology           : 'RIVER',
				station            : null,
				connectingPlatform : null,
				geoLocation        : {},
				openingHours       : [],
				platforms          : null,
				capacity           : null,
				length             : null
			};
		}
	}
};
</script>