<template>
	<b-row>
		<b-col lg="6" md="12">
			<b-form class="child-mb-3">
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('longitude')">
							<b-form-input type="number" placeholder="_" v-model="longitude" name="longitude" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('latitude')">
							<b-form-input type="number" placeholder="_" v-model="latitude" name="latitude" />
						</b-form-group>
					</b-col>
				</b-row>
				<MultipleSelect :options="stations" :label="$t('station')" v-model="railInformation.station"
					track="nameUIC" :searchable="true" id="selectStation" />
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('number-of-routes')">
							<b-form-input placeholder="_" v-model="railInformation.tracks" name="roadNumber" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('number-of-docks')">
							<b-form-input type="number" placeholder="_" v-model="railInformation.platforms"
								name="dockNumber" />
						</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="6">
						<b-form-group :label="$t('treatment-capacity')">
							<b-form-input type="number" placeholder="_" v-model="railInformation.capacity"
								name="treatmentCapacity" />
						</b-form-group>
					</b-col>
					<b-col lg="6">
						<b-form-group :label="$t('platform-length')">
							<b-form-input type="number" placeholder="_" v-model="railInformation.length"
								name="platformLength" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</b-col>
		<b-col lg="6" md="12">
			<Schedules :schedules="railInformation.openingHours"
				:title="$t('opening-hours-days')" @set-schedules="setSchedules" id="rail" />
			<b-form-checkbox value="true" class="mt-3" v-model="sameTimetable" id="samegenericshours">
				{{ $t('resume-the-general-timetable') }}
			</b-form-checkbox>
		</b-col>
	</b-row>
</template>
<script>
import Schedules from '@/components/common/Schedules.vue';
import stationsMixins  from '@/mixins/list/railStations';
export default {
	props : {
		information : {
			required : true,
			type     : Object,
		}
	},
	components : {
		Schedules
	},
	mixins : [stationsMixins],

	data() {
		return {
			sameTimetable   : false,
			longitude       : null,
			latitude        : null,
			openingHours     : []
		};
	},
	computed : {
		railInformation() {
			return this.setRailInformation();
		},
		getMainTimetable() {
			return this.information.openingHours;
		},
	},

	watch : {
		getMainTimetable(val) {
			if(this.sameTimetable) {
				this.railInformation.openingHours = val;
			}
		},
		sameTimetable(val) {
			if(val) {
				this.railInformation.openingHours = this.getMainTimetable;
			} else {
				this.railInformation.openingHours = [];
			}
		},
		longitude(val) {
			if(val) {
				const longitude = Number(val);

				if(this.railInformation && this.railInformation.geoLocation) {
					if(this.railInformation.geoLocation.coordinates
					&& this.railInformation.geoLocation.coordinates.length > 0) {
						this.railInformation.geoLocation.coordinates[0] = longitude;
						this.railInformation.geoLocation.type = 'Point';
					} else {
						this.railInformation.geoLocation = { type        : 'Point',
							coordinates : [longitude, null] };
					}
				} else {
					this.railInformation.geoLocation = { type        : 'Point',
						coordinates : [longitude, null] };
				}
			}
		},
		latitude(val) {
			if(val) {
				const latitude = Number(val);
				if(this.railInformation && this.railInformation.geoLocation) {
					if(this.railInformation.geoLocation.coordinates
					&& this.railInformation.geoLocation.coordinates.length > 1) {
						this.railInformation.geoLocation.coordinates[1] = latitude;
						this.railInformation.geoLocation.type = 'Point';
					} else {
						this.railInformation.geoLocation = { type        : 'Point',
							coordinates : [null, latitude] };
					}
				} else {
					this.railInformation.geoLocation = { type        : 'Point',
						coordinates : [null, latitude] };
				}
			}
		},
		'railInformation' : {
			handler(val) {
				const index = this.information.modals.findIndex(mod=> mod.typology === 'RAIL');
				if (index != -1) {
					this.information.modals[index] = val;
				} else {
					this.information.modals.push(val);
				}
			},
			deep : true
		},
	},

	methods : {
		setSchedules(schedules) {
			this.railInformation.openingHours = schedules;
		},
		setRailInformation() {
			if (this.information.modals && this.information.modals.length > 0) {
				const find =  this.information.modals.find(mod=> mod.typology === 'RAIL');
				if (find) {
					if (find.geoLocation && find.geoLocation.coordinates) {
						this.longitude = find.geoLocation.coordinates[0];
						this.openingHours = find.openingHours;
						this.latitude = find.geoLocation.coordinates[1];
					} else {
						this.longitude = null;
						this.latitude = null;
					}
					return find;
				}
			}
			this.information.modals.push({
				typology     : 'RAIL',
				openingHours : []
			});
			return this.information.modals[this.information.modals.length - 1];
		}
	}
};
</script>