<template>
	<b-row>
		<b-col lg="12">
			<b-tabs class="light" v-model="tabIndex">
				<b-tab :title="$t('rail')" active v-if="activeIs('RAIL')">
					<Rail :information="station" />
				</b-tab>
				<b-tab :title="$t('road')" v-if="activeIs('ROAD')">
					<Road :information="station" />
				</b-tab>
				<b-tab :title="$t('airplane')" v-if="activeIs('AIR')">
					<Plane :information="station" />
				</b-tab>
				<b-tab :title="$t('maritime')" v-if="activeIs('SEA')">
					<Maritime :information="station" />
				</b-tab>
				<b-tab :title="$t('river')" v-if="activeIs('RIVER')">
					<River :information="station" />
				</b-tab>
			</b-tabs>
		</b-col>
	</b-row>
</template>
<script>
import { Rail, Road, Plane, Maritime, River } from './modals/';
export default {
	components : {
		Rail,
		Road,
		Plane,
		Maritime,
		River
	},
	props : {
		station : {
			required : true,
			type     : Object,
		},
		active : {
			type     : String,
			required : false,
			default() {
				return '';
			}
		}
	},
	data() {
		return {
			tabIndex : 0,
		};
	},
	computed : {
		stationName() {
			return this.station.name;
		}
	},
	watch : {
		stationName() {
			this.tabIndex = 0;
		}
	},
	methods : {
		activeIs(modal) {
			if(this.active) {
				return this.active == modal;
			} else {
				return true;
			}
		}
	}
};
</script>