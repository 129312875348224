<template>
	<b-row>
		<b-tabs class="light w-100" v-model="tabIndex" v-if="typology == 'RAIL'
			|| typology == 'ROAD'">
			<b-tab :title="$t('rail')" v-if="typology == 'RAIL'">
				<Rail :segment="segment" :id="id" />
			</b-tab>
			<b-tab :title="$t('road')" v-if="typology == 'ROAD'">
				<Road :segment="segment" :id="id" />
			</b-tab>
			<!-- <b-tab>
				<template #title>
						<Icon icon="add" />
				</template>
			</b-tab> -->
		</b-tabs>
	</b-row>
</template>
<script>
import { Rail, Road } from './modals/';
export default {
	components : {
		Rail,
		Road
	},
	props : {
		segment : {
			required : true,
			type     : Object,
		},
		typology : {
			required : true,
			type     : null
		},
		id : {
			required : true,
			type     : String
		}
	},
	data() {
		return {
			tabIndex : 0,
		};
	}
};
</script>