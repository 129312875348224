<template>
	<ul :class="{'light' : !order.internalCode, 'horizontal visualization' : true}">
		<li v-for="(segment, key) in segments" :key="key"
			:class="(segment.incidentIds && segment.incidentIds.length > 0)?'error':segment.operationState">
			<div class="segment-container" :class="(segment.incidentIds && segment.incidentIds.length > 0)?
				'error':segment.operationState ">
				<div class="current-position center" style="left: 50%;"
					v-if="!order || (order && !order.currentPosition)
						|| (order && order.currentPosition && Object.keys(order.currentPosition).length === 0)">
					<Statut :segment="segment" />
				</div>
				<div class="current-position center text-green" v-if="checkCurrentPosition(segment) &&
					order && segment.statut != 'error'" :style="{left :
					order.currentPosition.position ? order.currentPosition.position + '%': '0%'}">
					<Icon icon="flag" />
				</div>
				<div class="error-segment text-red" v-if="segment.incidentIds && segment.incidentIds.length > 0">
					<Icon icon="alert" />
				</div>
				<div class="position" :id="'position-' + key">
					<span v-if="key != 0 && segments.length < 5">
						{{ segment.origin.name }}
					</span>
				</div>
				<b-tooltip v-if="key != 0 && segments.length >= 5"
					:target="'position-' + key" triggers="hover" placement="top">
					{{ segment.origin.name }}
				</b-tooltip>
				<div :class="'segment ' + segment.typology">
					<div class="road-element" v-if="segment.typology == 'ROAD'" />
				</div>
				<div v-if="key == (segments.length - 1)" class="position" id="last-destination" />
			</div>
		</li>
	</ul>
</template>
<script>
import Statut from './Statut';
export default {
	name       : 'HorizontalVisualization',
	components : {
		Statut
	},
	props : {
		segments : {
			type     : Array,
			required : true,
		},
		order : {
			type     : Object,
			required : false,
			default() {
				return {
					currentPosition : {}
				};
			}
		},
	},
	methods : {
		checkCurrentPosition(segment) {
			if (this.order.currentPosition) {
				return this.order.currentPosition.segmentId && segment._id == this.order.currentPosition.segmentId;
			}
			return false;
		}
	},
	created() {
		const lastSegments = this.segments[this.segments.length - 1];
		let orderPosition = this.order.currentPosition;
		if(!orderPosition) {
			orderPosition = {};
		}
		if(lastSegments._id ==  orderPosition.segmentId && orderPosition.position == 100) {
			orderPosition.position = 97;
		}
	}

};
</script>