<template>
	<div id="update-partner">
		<div class="d-flex justify-content-between align-items-center">
			<h1 class="mb-4">
				{{ $t('form-registrations') }}
			</h1>
			<b-button variant="info" class="btn-icon" @click="$router.go(-1)">
				<Icon icon="arrow-back" />
				{{ $t('return') }}
			</b-button>
		</div>
		<b-card no-body class="mb-3">
			<b-card-header class="primary d-flex justify-content-between">
				{{ $t('information-company') }}
			</b-card-header>
			<b-card-body>
				<CompanyFields :validate="$v.registration.company" />
			</b-card-body>
		</b-card>
		<b-card no-body class="mb-3">
			<b-card-header class="primary d-flex justify-content-between">
				{{ $t('contact') }}
			</b-card-header>
			<b-card-body>
				<ContactFields :registration="registration" :validate="$v.registration.user" />
			</b-card-body>
		</b-card>
		<div class="d-flex justify-content-center child-mx-2">
			<b-button class="btn-icon" variant="danger"
				@click="$bvModal.show('modal-refuse')" id="deleteregistration">
				<Icon icon="cross" />
				{{ $t('refuse') }}
			</b-button>
			<b-button class="btn-icon" variant="info"
				@click="update()" id="updateregistration">
				<Icon icon="edit" />
				{{ $t('update') }}
			</b-button>
			<b-button class="btn-icon" variant="primary"
				@click="submit()" id="acceptregistration">
				<Icon icon="accept" />
				{{ $t('accept') }}
			</b-button>
		</div>
		<Modal id="modal-refuse" :title="$t('refuse-registration')" @confirm="refuseRegistration">
			<template v-slot:content>
				<p class="text-initial">
					{{ $t('refuse-text-modal') }} <b>{{ registration.user.lastname }}
						{{ registration.user.firstname }}</b> ?
				</p>
			</template>
		</Modal>
	</div>
</template>

<script>
import CompanyFields from './sendRegistration/CompanyFields.vue';
import ContactFields from './sendRegistration/ContactFields.vue';
import { validateRequired, validateText, validateVAT, validateNic,
	validateSiren, validatePostalCode, validateEmail, validatePhone } from '@/utils/plugins/validations/vuelidate';
import countriesMixins  from '@/mixins/countries.js';

export default {
	name       : 'SendRegistration',
	mixins     : [countriesMixins],
	components : {
		CompanyFields,
		ContactFields
	},
	validations : {
		registration : {
			company : {
				type  : validateRequired,
				name  : validateText,
				tva   : validateVAT,
				siret : {
					nic   : validateNic,
					siren : validateSiren
				},
				address : {
					street     : validateText,
					postalCode    : validatePostalCode,
					country    : validateRequired,
					city       : validateText
				}
			},
			user : {
				firstname : validateText,
				lastname  : validateText,
				email     : validateEmail,
				phone     : validatePhone,
				language  : validateRequired,
				address   : {
					street     : validateText,
					postalCode    : validatePostalCode,
					city       : validateText,
					country    : validateRequired
				}
			}
		}
	},
	async created() {
		const id = this.$route.params.id;
		if(id) {
			await this.$store.dispatch('registers/getRegister', id).then((res) => {
				this.registration = res;
				this.registration.company.address.country = this.findCountry(this.registration.company.address.country);
				this.registration.user.address.country = this.findCountry(this.registration.user.address.country);
			}).catch((err) => {
				this.$router.push('/registrations/list').then(() => {
					this.$_makeToast('danger',
						this._i18n.t(err.error),
						this._i18n.t('error')
					);
				});
			});
		}
	},
	data() {
		return {
			registration : {
				company : {
					siret   : {},
					address : {},
				},
				user : {
					phone : null
				}
			}
		};
	},
	methods : {
		submit() {
			this.$v.$touch();
			if(!this.$v.$invalid) {
				this.$store.dispatch('registers/VALIDATE', this.registration._id)
					.then(() => {
						this.$router.push('/registrations/list');
					}).catch((err) => {
						this.$_makeToast('danger',
							this._i18n.t(err),
							this._i18n.t('error')
						);
					});
			}
		},
		update() {
			this.$v.$touch();
			if(!this.$v.$invalid) {
				const registration = { ...this.registration };
				registration.user.address.country = registration.user.address.country.alpha2;
				registration.company.address.country = registration.company.address.country.alpha2;
				const phone = registration.user.phone;
				registration.user.phone = [];
				registration.user.phone[0] = phone;
				this.$store.dispatch('registers/UPDATE', registration)
					.then(() => {
						this.$router.push('/registrations/list');
					}).catch((err) => {
						this.$_makeToast('danger',
							this._i18n.t(err),
							this._i18n.t('error')
						);
					});
			}
		},
		refuseRegistration(id) {
			this.$bvModal.hide('refuse-modal');
			this.$store.dispatch('registers/DELETE', id)
				.then(() => {
					this.$router.push('/registrations/list');
				}).catch((err) => {
					this.$_makeToast('danger',
						this._i18n.t(err),
						this._i18n.t('error')
					);
				});
		},
	}
};
</script>
<i18n>
{
	"fr": {
		"err-01": "Le numéro d'inscription n'est pas référencé dans la liste des inscriptions",
		"USCR001": "Les mots de passe ne correspondent pas",
		"USCR002": "L'utilisateur existe déjà"
	},
	"en": {
		"err-01": "The registration number is not referenced in the registration list.",
		"USCR001": "Provided passwords do not match",
		"USCR002": "This user is already registered"
	}
}
</i18n>
