<template>
	<div>
		<b-form-datepicker placeholder="_" :value="value" :label-no-date-selected="$t('no-date-selected')"
			boundary="viewPort" :locale="_i18n.locale" @input="onInput" :state="state" :min="min" :max="max"
			:disabled="disabled" v-if="state != null" />
		<b-form-datepicker placeholder="_" :value="value" :label-no-date-selected="$t('no-date-selected')"
			boundary="viewPort" :locale="_i18n.locale" @input="onInput" :state="state" :min="min" :max="max"
			:disabled="disabled" v-else />
	</div>
</template>
<script>
export default {
	props : {
		value : {
			required : false,
			type     : null,
			default() {
				return null;
			}
		},
		state : {
			required : false,
			type     : null,
			default  : null
		},
		min : {
			required : false,
			type     : null,
			default  : null
		},
		max : {
			required : false,
			type     : null,
			default  : null
		},
		disabled : {
			required : false,
			type     : null,
			default  : null
		}
	},
	methods : {
		onInput(value) {
			if(this.setValue) {
				this.$emit('input', value[this.setValue]);
			} else {
				this.$emit('input', value);
			}
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.b-form-btn-label-control.form-control[aria-disabled='true'],
.b-form-btn-label-control.form-control[aria-readonly='true'] {
	background-color: $light-grey;
	opacity: 0.4;
}
</style>