<template>
	<div id="incidents">
		<Table :items="items" :fields="fields" :loading="loading" @load="load()" :pagination="pagination"
			:title="$t('incidents')">
			<template v-slot:buttons>
				<b-button variant="primary">
					{{ $t('add-incident') }}
				</b-button>
			</template>
			<template v-slot:filters>
				<Search />
				<StatusIncident />
				<TypeIncident />
			</template>
			<template v-slot:action>
				<div class="d-flex justify-content-center">
					<ul class="actions">
						<li>
							<b-button variant="primary" class="btn-round-icon">
								<Icon icon="edit" />
							</b-button>
						</li>
					</ul>
				</div>
			</template>
		</Table>
	</div>
</template>

<script>
import { Search } from '@/components/filters/components';
import TypeIncident from '@/components/filters/components/incidents/TypeIncident.vue';
import StatusIncident from '@/components/filters/components/incidents/StatusIncident.vue';
import * as data from '@/utils/fakeData/ERAMET/mockIncidents.json';
const incidentsMocker = data.default;
export default {
	name       : 'Incidents',
	components : {
		Search,
		TypeIncident,
		StatusIncident,
	},
	metaInfo()  {
		var t = this;
		return {
			title : t._i18n.t('incidents'),
		};
	},
	computed : {
		fields() {
			return [
				{
					label    : this._i18n.t('incident-type'),
					key      : 'incident.type',
					sortable : true,
				},
				{
					label    : this._i18n.t('identification'),
					key      : 'incident.identification',
					sortable : true,
				},
				{
					label    : this._i18n.t('incident-description'),
					key      : 'incident.description',
					sortable : true,
				},
				{
					label    : this._i18n.t('status'),
					key      : 'incident.status',
					sortable : true,
				},
				{
					label    : this._i18n.t('cost'),
					key      : 'incident.cost',
					sortable : true,
				},
				{
					label : this._i18n.t('action'),
					key   : 'action',
				},
			];
		}
	},
	data() {
		return {
			loading          : false,
			items            : incidentsMocker,
			pagination       : {
				perPage     : 10,
				totalRows  	: incidentsMocker.length,
				currentPage : 1,
			}
		};
	},
	methods : {
		load() {

		}
	}
};
</script>
<style lang="scss" scoped>
/deep/.action-field {
	min-width: 200px;
}
.actions {
	max-width: none;
}
</style>