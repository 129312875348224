
export default {
	computed : {
		labelDays() {
			return [
				{ value : 1,
					text  : this._i18n.t('monday') },
				{ value : 2,
					text  : this._i18n.t('tuesday') },
				{ value : 3,
					text  : this._i18n.t('wednesday') },
				{ value : 4,
					text  : this._i18n.t('thursday') },
				{ value : 5,
					text  : this._i18n.t('friday') },
				{ value : 6,
					text  : this._i18n.t('saturday') },
				{ value : 0,
					text  : this._i18n.t('sunday') },
			];
		}
	},
	methods : {
		getLabelOfDay(day) {
			const text = this.labelDays.find(d => d.value === day);
			if (text)
				return text.text;
			return '';
		}
	}
};