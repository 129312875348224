<template>
	<div class="segment" :id="id">
		<Origin :origin="segment.origin"
			:is-selected="selected.id == id && selected.type == 'origin'"
			@set-selected="setSelected" />
		<Typology :typology="segment.typology"
			:is-selected="selected.id == id && selected.type == 'typology'"
			@set-selected="setSelected" />
		<Destination :destination="segment.destination"
			:is-selected="selected.id == id && selected.type == 'destination'"
			@set-selected="setSelected" />
	</div>
</template>
<script>
import { Origin, Destination, Typology } from './segment/';
export default {
	components : {
		Origin,
		Destination,
		Typology
	},
	props : {
		segment : {
			required : true,
			type     : Object,

		},
		selected : {
			required : true,
			type     : null
		},
		id : {
			required : true,
			type     : String
		}
	},
	methods : {
		setSelected(val) {
			this.$emit('set-selected', val);
		}
	},
};
</script>
<style lang="scss" scoped>
.segment {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 150px;
}
</style>