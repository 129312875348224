<template>
	<b-card no-body>
		<b-card-header class="d-flex justify-content-between align-items-center">
			<div>
				{{ actor.name }}
			</div>
			<div class="d-flex text-initial" v-if="$route.name != 'VisualizationContracts'">
				<div class="d-flex align-items-center" v-if="isOwner">
					<legend class="mb-0 mr-2">
						{{ $t('charger') }}
					</legend>
					<b-form-checkbox :disabled="index == 0" v-model="actor.validatedCharger"
						class="success" switch size="lg" :id="'checkboxCharger'+index" />
				</div>
				<div class="d-flex ml-2  align-items-center" v-if="$route.name == 'EditContracts' && index != 0
					&& (user.company._id == actor.companyId || isOwner)">
					<legend class="mb-0 mr-2">
						{{ $t('provider') }}
					</legend>
					<b-form-checkbox :disabled="isOwner" v-model="actor.validatedOperator" class="success"
						switch size="lg" :id="'checkboxProvider'+index" />
				</div>
			</div>
		</b-card-header>
		<b-card-body>
			<ul>
				<li>
					<p>
						<span class="color-primary">{{ $t(actor.type) }} </span><br />
						{{ $t('siren') }} : {{ actor.siret.siren }} <br />
						{{ $t('nic') }} : {{ actor.siret.nic }} <br />
						{{ $t('vat') }} : {{ actor.tva }} <br />
						{{ $t('address') }} : {{ actor.address.street }},
						{{ actor.address.city }},
						{{ actor.address.postalCode }}
					</p>
				</li>
				<li v-for="(contact, i) in actor.contacts" :key="i">
					<p>
						<span class="color-primary">{{ $t('contact') }} n° {{ i + 1 }}</span> <br />
						{{ contact.firstname }}  {{ contact.lastname }} <br />
						{{ $t('phone') }} : {{ contact.phone }} <br />
						{{ $t('email') }} : {{ contact.email }} <br />
						{{ $t('address') }} : {{ contact.address.street }}, {{ contact.address.city }},
						{{ contact.address.postalCode }}
					</p>
				</li>
			</ul>
		</b-card-body>
	</b-card>
</template>
<script>
export default {
	props : {
		actor : {
			required : true,
			type     : Object
		},
		isOwner : {
			required : true,
			type     : Boolean
		},
		index : {
			required : true,
			type     : Number
		}
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
ul {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
}
ul li {
	width: 25%;
	&:not(:first-of-type):not(:nth-of-type(4n+1)) {
		padding-left: 20px;
		border-left: 2px solid $light-grey;
	}
	&:not(:first-of-type):nth-of-type(4n+1) {
		margin-top: 1.25rem;
	}
	&:first-of-type {
		align-self: flex-start;
	}
}
ul li p span:first-of-type {
	display: inline-block;
	margin-bottom: 0.25rem;
	text-transform: uppercase;
}
ul li p {
	text-transform: initial;
	font-weight: 600;
}
</style>