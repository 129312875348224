import { HTTP } from '../../http-common';

const actions = {
	async getCompanyById(context, id) {
		return await HTTP.get('/companies/' + id);
	},
	async getCompanyListBySiren(context, siren) {
		return await HTTP.get('/companies/siret/' + siren);
	},
	async getCompanyList() {
		return await HTTP.get('/companies');
	}
};
export default {
	namespaced : true,
	actions,
};