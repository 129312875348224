<template>
	<div>
		<b-button variant="primary" v-b-toggle="'locations'" class="btn-collapse">
			{{ $t('locations') }}
			<Icon icon="chevron-down" />
		</b-button>
		<b-collapse id="locations" accordion="accordion">
			<b-card>
				<ul>
					<li v-for="(location, index) in getLocations" :key="index">
						<b-card no-body>
							<b-card-header class="d-flex justify-content-between">
								{{ location.name }}
								<div class="d-flex flex-column align-items-end text-initial"
									v-if="$route.name != 'VisualizationContracts'">
									<div class="d-flex align-items-center width-content" v-if="isOwner">
										<legend class="mb-0 mr-2">
											{{ $t('charger') }}
										</legend>
										<b-form-checkbox :disabled="!isOwner" v-model="location.validatedCharger"
											class="success" switch size="lg" :id="'checkboxCharger'+index" />
									</div>
									<div class="d-flex align-items-center width-content"
										v-if="$route.name == 'EditContracts'">
										<legend class="mb-0 mr-2">
											{{ $t('provider') }}
										</legend>
										<b-form-checkbox :disabled="isOwner" v-model="location.validatedOperator"
											class="success" switch size="lg" :id="'checkboxProvider'+index" />
									</div>
								</div>
							</b-card-header>
							<b-card-body>
								<div v-if="location.address">
									<div v-if="location.address.street &&
										location.address.city">
										<b>{{ $t('address') }}</b> : {{ location.address.street }},
										{{ location.address.postalCode }}, {{ location.address.city }}
									</div>
								</div>

								<div v-if="location.handler">
									<b>{{ $t('handler') }}</b> : {{ location.handler.companyName }}
								</div>
								<div v-if="location.modals">
									<b>{{ $t('modal') }}</b> :
									<span v-for="(modal, m) in location.modals" :key="m">
										{{ $t(modal.typology) }} ,
									</span>
								</div>
								<div v-if="location.openingHours">
									<b>{{ $t('opening-time') }}</b>
									<div v-for="(schedule, i) in location.openingHours" :key="i" class="ml-2">
										{{ getLabelOfDay(schedule.dayOfWeek) }} :
										<span v-for="(hour, h) in schedule.hours" :key="h">
											{{ hour.start?hour.start.slice(0, -3) : '' }} -
											{{ hour.end?hour.end.slice(0, -3) : '' }}
										</span>
									</div>
								</div>
							</b-card-body>
						</b-card>
					</li>
				</ul>
			</b-card>
		</b-collapse>
	</div>
</template>
<script>
import DateMixins from '@/mixins/date.js';
export default {
	components : {
	},
	props : {
		locations : {
			type     : Array,
			required : true,
		},
		isOwner : {
			type     : Boolean,
			required : true,
		}
	},
	mixins : [DateMixins],

	computed : {
		getLocations() {
			const locations = [];
			for (let i = 0; i < this.locations.length; i++) {
				const location = this.locations[i];
				if(this.isOwner) {
					locations.push(location);
				} else if(location.handler && location.handler.companyId == this.user.company._id) {
					locations.push(location);
				}
			}
			return locations;
		}
	},
};
</script>
<style lang="scss" scoped>
ul {
	display: flex;
	flex-wrap: wrap;
}
ul li {
	width: 31%;
	text-transform: initial;
	font-weight: 600;
	margin: 1%;
}
</style>