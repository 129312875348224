<template>
	<div class="typology">
		<div class="container-info" :class="{'selected': isSelected}" v-if="value"
			@click="$emit('set-selected', 'typology')">
			<div class="d-flex align-items-center">
				<Icon :icon="typesOfRoad.find(x => x.text == value).icon" />
				{{ $t(value) }}
			</div>
			<div @click.stop="clear" class="d-flex">
				<Icon icon="cross" />
			</div>
		</div>
		<MultipleSelect v-else :options="typesOfRoad" :icon="true" :placeholder="$t('type')" v-model="typologyClone" />
	</div>
</template>
<script>
export default {
	name  : 'Origin',
	props : {
		value : {
			required : true,
			type     : null
		},
		isSelected : {
			required : true,
			type     : Boolean
		},
	},
	data() {
		return {
			typologyClone : this.value,
		};
	},
	computed : {
		typesOfRoad() {
			return [
				{
					text : 'ROAD',
					icon : 'truck'
				},
				{
					text : 'RAIL',
					icon : 'train'
				},
				{
					text : 'AIR',
					icon : 'airplane'
				},
				{
					text : 'SEA',
					icon : 'boat'
				},
				{
					text : 'RIVER',
					icon : 'river'
				},
			];
		}
	},
	methods : {
		clear() {
			this.$emit('set-selected', null);
			this.typologyClone = null;
		}
	},
	watch : {
		typologyClone(val) {
			if(val) {
				this.$emit('input', val.text);
			} else {
				this.$emit('input', null);
			}
		}
	}
};
</script>