<template>
	<div id="select-order">
		<b-button variant="info" class="btn-icon mb-3" @click="$router.push('/operation/location')">
			<Icon icon="arrow-back" />
			{{ $t('return') }}
		</b-button>
		<b-card no-body>
			<b-card-header>{{ $t('select-a-order') }}</b-card-header>
			<b-card-body>
				<form @submit="submit">
					<multi-select class="content" v-model="order"
						:deselect-label="$t('press-enter-to-remove')" :selected-label="$t('selected')"
						select-label="" placeholder="_" :searchable="true" :hide-selected="true"
						:options="orders" label="int_code" track-by="int_code" />
					<div class="d-flex justify-content-center mt-3">
						<b-button variant="primary" :disabled="!order" @click="submit">
							{{ $t('next') }}
						</b-button>
					</div>
				</form>
			</b-card-body>
		</b-card>
	</div>
</template>
<script>
import * as data from '@/utils/fakeData/ERAMET/mockOrder.json';
const orders = data.default;
export default {
	data() {
		return {
			orders : orders,
			order  : ''
		};
	},
	methods : {
		submit() {
			this.$store.commit('operation/setOrder', this.order);
			this.$router.push('/operation/state');
		},
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
</style>
<i18n>
{
    "fr":{
        "select-a-order": "Séléctionner une commande"
    },
    "en":{
        "select-a-order": "Select an order"
    }
}
</i18n>