<template>
	<b-card no-body>
		<b-card-header>
			{{ $t('roads') }}
		</b-card-header>
		<b-card-body class="roads" style="min-height: 20vh;">
			<b-spinner variant="primary" class="loader" v-if="loading" />
			<div v-else>
				<div class="mb-3">
					<b-form-group :label="$t('filter-per-origin') + ' :'">
						<b-form-select v-model="filter" :options="options" />
					</b-form-group>
				</div>
				<div class="list selected" v-if="selectedContract && selectedContract.state=='VALIDATED'">
					<div class="container-item" v-for=" (transportPlan, index) in transportPlans" :key="index"
						@click="selectItem(transportPlan)">
						<div class="item">
							<Informations :transport-plan="transportPlan" />
							<HorizontalVisualization :services="transportPlan.services.filter(s=>s.type=='segment')"
								class="mt-4" />
							<div class="co2">
								{{ getCO2(transportPlan) }}T CO2
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-card-body>
	</b-card>
</template>
<script>
import Informations from '@/components/segments/Informations';
import HorizontalVisualization from '@/components/transportPlan/HorizontalVisualization';
export default {
	name       : 'RoadList',
	components : {
		HorizontalVisualization,
		Informations
	},
	props : {
		order : {
			type     : Object,
			required : true
		},
		selectedContract : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			transportPlans : [],
			filter         : 'all',
			loading        : false,
		};
	},
	computed : {
		options() {
			return [
				{
					value : 'all',
					text  : this._i18n.t('all')
				}
			];
		},
		selectedContractCode() {
			return this.$store.getters['contracts/selected'];
		}
	},
	created() {
		this.transportPlans.forEach((e) => {
			const origin = e.origin;
			if(!this.options.find((opt) => opt.value == origin.origin_id)) {
				this.options.push({ value : origin.origin_id,
					text  : origin.name });
			}
		});
		this.load();
	},
	methods : {
		async load() {
			if (this.selectedContractCode) {
				this.loading = true;
				await this.$store.dispatch('contracts/loadList').then((res) => {
					const selectedContract = res.results.find(c=>c.code === this.selectedContractCode);
					this.$store.dispatch('contracts/loadRoutes', selectedContract._id).then((res) => {
						this.transportPlans = res;
					}).catch((err) => {
						this.$_makeToast('danger', err, this._i18n.t('error'));
					});
					this.loading = false;
				}).catch((err) => {
					this.$_makeToast('danger', err, this._i18n.t('error'));
					this.loading = false;
				});
			}
		},
		selectItem(road) {
			Array.from(document.getElementsByClassName('container-item')).forEach((e) => {
				e.classList.remove('active');
			});
			event.target.closest('.container-item').classList.add('active');
			// get the road details from database
			this.$store.dispatch('transportationRoutes/loadRoute', road.routeId).then((res) => {
				this.order.transportRoutes  = [res];
			}).catch((err) => {
				this.$_makeToast('danger', err, this._i18n.t('error'));
			});
		},
		getCO2(transportPlan) {
			var CO2 = 0;
			transportPlan.services.forEach((e) => {
				if(e.type == 'segment') CO2 += e.segment.co2;
			});
			return Math.round(CO2 * 100) / 100;
		}
	},
	watch : {
		filter(val) {
			this.transportPlans = [];
			if(val == 'all') {
				//this.transportPlans = RoutesMocker;
			}
			this.transportPlans.forEach((e) => {
				const origin = e.origin;
				if(origin.origin_id == val) {
					this.transportPlans.push(e);
				}
			});
		},
		selectedContractCode() {
			this.load();
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
/deep/ .card-body {
	max-height: 999px;
	overflow-y: auto;
}
/deep/ .horizontal.visualization {
	width: 55%;
}
/deep/ .list .container-item .informations > li:nth-child(2) {
	justify-content: flex-start;
}
/deep/ .list .container-item .informations li:nth-child(2) p {
	padding-left: 0;
}
/deep/ .list .container-item .informations > li:nth-child(4) {
	justify-content: flex-end;
}
/deep/ .list .container-item .informations li:nth-child(4) p {
	padding-right: 0;
}
/deep/ .list .visualization.horizontal #last-destination {
	left: 100%;
}
/deep/ .list .container-item .item {
	padding: 2rem 0;
	position: relative;
}
</style>
<i18n>
{
	"fr": {
		"filter-per-origin": "Filtrer par origine"
	},
	"en": {
		"filter-per-origin": "Filter per origin"
	}
}
</i18n>