export default {
	computed : {
		handlingUnits() {
			return [
				{
					value : 'pallets',
					text  : this._i18n.t('pallets')
				},
				{
					value : 'packages',
					text  : this._i18n.t('packages')
				},
				{
					value : 'rolls',
					text  :  this._i18n.t('rolls')
				},
				{
					value : 'bins',
					text  :  this._i18n.t('bins')
				},
				{
					value : 'bulk',
					text  :  this._i18n.t('bulk')
				},
				{
					value : 'barge',
					text  :  this._i18n.t('barge')
				},
				{
					value : 'maritime-container',
					text  : this._i18n.t('maritime-container')
				},
				{
					value : 'multimodal-container',
					text  : this._i18n.t('multimodal-container')
				},
			];
		}
	}
};